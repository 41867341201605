import { ComuneDTO } from "./comune.model";

export class Centrale {
    id: Number;
    idTim: String;
    clliAttestazione:String;
    clli:String;
    longitudine:Number;
    latitudine:Number;
    denominazione:String
    indirizzo:String;
    comune: ComuneDTO;

  }