
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
<p class="line-height-3 p-0 m-0 mb-1 mt-1">
  Seleziona file da caricare o trascinalo nell'area sottostante
 </p>
<p-fileUpload 
  multiple="false"
  maxFileSize="15000000"
  accept=".p7m"
  chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onFileUpload($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [disabled]="isFileUploaded"
  (onRemove)="onFileRemove()"
  directory
  webkitdirectory
  >
</p-fileUpload>
 <!--  -->
  <!-- <ngx-dropzone (change)="onDirUpload($event)" [processDirectoryDrop]="true" >
    <ngx-dropzone-label>Seleziona cartella da caricare o trascinala nell'area sottostante</ngx-dropzone-label>
     <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview> 
  </ngx-dropzone> -->
 <!--  <input #fileInput type="file" (change)="onDirUpload($event)" 
  webkitdirectory
  directory/> -->
  
  
<!--     -->
<!-- <ngx-file-drop
  dropZoneLabel="Trascina la cartella qui"
  [multiple]="true"
  (filesChange)="onFilesChange($event)"
  (fileOver)="fileOver($event)"
  (fileLeave)="fileLeave($event)"
>
  <div *ngFor="let file of files" class="file">{{ file.name }}</div>
</ngx-file-drop>
-->
<div  class="flex upload-file-div-box mt-2">

    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isFileUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>

</div>

