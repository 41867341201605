import { ComuneDTO } from "./comune.model";
import { Utente } from "./utente.model";

export class ScuolaDTO {
    id: Number;

    denominazione:String;
    codiceMiur:String;

    tipologia:String;
    diritto:String;
    proprietario:String;
    numeroAccessPoint:Number;
    lunghezza:Number;

    tipologiaTratta2:String;
    dirittoTratta2:String;
    proprietarioTratta2:String;
    lunghezzaTratta2:Number;

    hostnameCpe:String;
    ipAddress:String;
    localeTecnicoNome:String;
    localeTecnicoPiano:String;
    localeTecnicoStanza:String;
    cpeInStessoLocale:Boolean;

    clliSedeA:String;
    tipologiaSedeA:String;
    descrizioneSedeA:String;
    attestazioneRipartitoreA:String;
    attestazioneRipartitoreA_BU:String;

    clliSedeZ:String;
    tipologiaSedeZ:String;
    descrizioneSedeZ:String;
    attestazioneRipartitoreZ:String;
    attestazioneRipartitoreZ_BU:String;
    
    numeroTotaleCoppie:Number;
    numeroCoppieInUso:Number;
    numeroFibra:String;
    numeroFibra_BU:String;

    longitudine:Number;
    latitudine:Number;
    indirizzo:String;

    comune:ComuneDTO;
    planimetriaUploaded:boolean;
    estensionePlanimetria:string;
    wifi:Boolean;
    tipoBackup:String;
  }
  