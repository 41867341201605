<div class="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static"
    style="min-height: 80px" (notificaCambioDatiEvent)="onNotificaCambioDatiEvent($event)">
    <img src="assets/loghi/logo_H.png" alt="Image" height="40" class="mr-0 lg:mr-2 align-self-center" style="cursor: pointer;" (click)="goToHome()">
    <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden"
        leaveToClass="hidden" [hideOnOutsideClick]="true"
        (click)="openMenu()">
        <i class="pi pi-bars text-4xl"></i>
    </a>
    <div [hidden]="hideMenu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
        >
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row" 
        >
              
            <li *ngIf="((this.userService.isSuperAdmin || this.userService.isAdmin) || (this.isAdmin || this.isSuperAdmin || this.isPresidioSF)) && auth.user$ | async as user" class="lg:relative">
                <a pRipple
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    style="z-index: 9999;"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true" 
                    (click)="openMenu()">
                    <i class="pi pi-list mr-2" style="color: thistle"></i>
                    <span>Anagrafiche</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul [hidden]="hideMenu"
                    class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer"> 
                
                    <li>
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openAnagraficaCentrali()">
                            <i class="pi pi-database mr-2"></i>
                            <span class="font-medium">Centrali</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openAnagraficaComuni()">
                            <i class="pi pi-building mr-2"></i>
                            <span class="font-medium">Comuni</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openAnagraficaImprese()">
                            <i class="pi pi-cart-plus mr-2"></i>
                            <span class="font-medium">Imprese</span>
                        </a>
                    </li>
                    <li>
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openAnagraficaUtenti()">
                            <i class="pi pi-users mr-2"></i>
                            <span class="font-medium">Utenti</span>
                        </a>
                    </li>
                   
                    
                </ul>
            </li>
             <li *ngIf="auth.user$ | async as user" class="lg:relative">
                <a pRipple
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        style="z-index: 9999;"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                        leaveActiveClass="fadeout" [hideOnOutsideClick]="true" 
                        (click)="openRegistriMenu()"
                    >
                    <i class="pi pi-book mr-2" style="color: burlywood;"></i>
                    <span>Pratiche</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul [hidden]="hideScuoleMenu"
                    class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer"> 
                    <li *ngIf="checkBando1() || checkBando2()" class="relative">
                        <a pRipple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
                        (click)="openPraticheScuolaMenu()">
                            <i class="material-symbols-outlined mr-2" style="color: darkslategray"> school </i>
                            <span class="font-medium" style="color: darkslategray">Scuola</span>
                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                        </a>
                                <ul [hidden]="hidePraticheScuolaMenu"
                                class="list-none py-0 pl-3 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                <li *ngIf="checkBando1()">
                                        <a pRipple 
                                             (click)="openPraticheScuola(false)"
                                            class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                            <i class="material-symbols-outlined mr-2" style="color: cadetblue; "> school </i>
                                            <span class="font-medium" style="color:cadetblue">Bando 1</span>
                                        </a>
                                    </li>
                                    <li *ngIf="checkBando2()">
                                        <a pRipple 
                                        (click)="openPraticheScuola(true)" 
                                            class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                            <i class="material-symbols-outlined mr-2" style="color: cornflowerblue;"> school </i>
                                            <span class="font-medium;" style="color: cornflowerblue">Bando 2</span>
                                        </a>
                                    </li>
                                </ul>
                     
                    </li>
                    <li *ngIf="checkSanita()">
                        <a pRipple
                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                            (click)="openPraticheSanita()">
                            <i class="material-symbols-outlined mr-2"  style="color: darkslateblue"> local_hospital </i>
                            <span class="font-medium" style="color: darkslateblue">Sanita</span>
                        </a>
                    </li>
                   
                </ul>
            </li> 
            <li *ngIf="isAdministrator() && auth.user$ | async as user" class="lg:relative">
                <a pRipple
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        style="z-index: 9999;"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                        leaveActiveClass="fadeout" [hideOnOutsideClick]="true" 
                        (click)="openPianiTecniciMenu()"
                    >
                    <i class="material-symbols-outlined mr-2" style="color: cadetblue;"> inventory </i>
                    <span>Piani tecnici</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul [hidden]="hidePianiTecniciMenu"
                    class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer"> 
                    
                    <li *ngIf="checkSanita()">
                        <a pRipple
                        class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150"
                        (click)="openPianiTecniciSanita()">
                        <i class="material-symbols-outlined mr-2" style="color: indianred;"> local_hospital </i>
                        <span class="font-medium" style="color: indianred;">Sanita</span>
                    </a>
                    </li>
                   
                </ul>
            </li> 
            
           
        </ul>
        <div *ngIf="auth.user$ | async as user" class="flex align-items-start ml-auto">
            <p-chip *ngIf="!hideChip" >
                <div class="p-2 font-bold" [style.color]="chipColor">{{navbarChip}}</div>
            </p-chip>
        </div>
        <ul
            class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none"
            >
            
          
            <li class="border-top-1 border-gray-800 lg:border-top-none text-gray-400"
            *ngIf="(auth.isAuthenticated$ | async) === false">
            <a pRipple
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
            leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
                (click)="loginWithRedirect()">
                <i class="pi pi-sign-in text-base lg:text-2xl mr-2 lg:mr-0"></i>
                <span class="block lg:hidden font-medium">Login</span>
            </a>
        </li>
            <li *ngIf="auth.user$ | async as user" class="lg:relative">
                <a pRipple
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <img [src]="user.picture" class="mr-3 lg:mr-0 rounded-circle"
                        style="width: 28px; height: 28px; border-radius: 50%" />
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul
                class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                <li class="relative">
                    <a *ngIf="((this.userService.isSuperAdmin || this.userService.isAdmin) || (this.isAdmin || this.isSuperAdmin || this.isPresidioSF))"pRipple
                        pRipple
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                        leaveActiveClass="fadeout" [hideOnOutsideClick]="true" (click)="changeLog()">
                            <i class="pi pi-file-import mr-2"></i>
                            <span class="font-medium">Changelog</span>

                        </a>
                        <a pRipple
                        pRipple
                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                        leaveActiveClass="fadeout" [hideOnOutsideClick]="true" (click)="logout()">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span class="font-medium">Logout</span>

                        </a>
                    </li>
                </ul>
            </li>

        </ul>
    </div>
</div>