
<div class="surface-ground px-1 py-2 md:px-2 lg:px-2">
   <div class="surface-card p-4 shadow-2 border-round">
    <form [formGroup]="modificaDipendenteForm">   
       <div class="grid formgrid p-fluid">
        
           <div class="field mb-4 col-12">
               <label for="nome" class="font-medium text-900">Nome</label>
               <input id="nome" type="text" type="nome"  pInputText formControlName="nome">
           </div>
           <!-- <div class="field mb-4 col-12">
                <label for="email" class="font-medium text-900">Mail</label>
                <input id="email" type="text" type="email" formControlName="email" pInputText>
            </div> -->
           
           <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
           <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
          
               <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1" styleClass="p-button-success" 
                    [disabled]="!modificaDipendenteForm.valid"></p-button>
               <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
                styleClass="p-button-warning" ></p-button>
           </div>
        
       </div>
      </form>
 </div> 
 </div>
 