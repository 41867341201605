import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import { environment } from "../../../../environments/environment";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { AngularFirestore } from "@angular/fire/compat/firestore";

import { Subject } from "rxjs";
import {
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import * as Leaflet from "leaflet";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Centrale } from "src/app/models/centrale.models";
import { CentraliService } from "src/app/services/centrali.service";
import { PraticheSanitaService } from "src/app/services/pratiche.sanita.service";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

@Component({
  selector: "app-cambio-centrale-sanita",
  templateUrl: "./dialog-cambio-centrale-sanita.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogRichiediCambioCentralePraticaSanita implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaSanita_Firestore;

  protected centraliLoaded:boolean = false;

  protected dettagliForm: FormGroup;
  protected scuolaForm: FormGroup;
  protected centraleForm: FormGroup;

  protected centrali: Centrale[] = [];
  protected filteredCentrali: Centrale[] = [];
  protected centrale:Centrale;
  protected ref: DynamicDialogRef;

  private collection_pratiche_sanita: string = environment.firebase.collection_pratiche_sanita;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    private praticheSanitaService: PraticheSanitaService,
    private centraliService:CentraliService
  ) {
   
  }

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.logger.info(this.config.data.pratica);
    this.loadCentrali();
     }

  loadCentrali() {
    this.logger.info("loadCentrali");
    this.centraliService.get().subscribe((res) => {
      this.centrali = res;
      this.centraliLoaded = true;
      this.logger.info("* "+ this.centrali.length);
    });
  }
  filterCentrali(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.centrali.length; i++) {
      let centrale = this.centrali[i];
      if (
        centrale.clliAttestazione.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(centrale);
      }
    }
    this.filteredCentrali = filtered;
  }
  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let praticaDaModificare: PraticaScuola = new PraticaScuola();
      

      this.logger.info(this.pratica);
       this.praticheSanitaService.richiediCambioCentrale(this.pratica.id.valueOf(), this.centrale).subscribe({
        next: (v) => {},
        error: (e) => {
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            detail: "Errore in fase di COMUNICAZIONE CAMBIO CENTRALE della pratica",
          });
          this.dialogRef.close({ success: false });
        },
        complete: () => {
          this.dialogRef.close({ success: true });
        },
      }); 

      
    }
  }
}
