
        <div class="grid formgrid p-fluid">
            <div class="field mb-2 col-12">
                <label for="ragioneSociale" class="font-medium text-900">Ragione Sociale</label>
                <input id="ragioneSociale" type="text" type="chiave" [(ngModel)]="ragioneSociale" pInputText>
            </div>
        </div>
            <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

            <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
                <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
                    styleClass="p-button-success"
                    [disabled]="ragioneSociale.length==0"></p-button>
                <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
                    styleClass="p-button-warning"></p-button>
            </div>
      
