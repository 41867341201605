<div class="p-1 md:p-1 lg:p-1">

  <div *ngIf="auth.user$ | async as user ; else elseBlock" >
    <!-- <div class="spinner-overlay" *ngIf="isLoading">
      <p-progressSpinner styleClass="spinner"></p-progressSpinner>
    </div>
 -->
    <div class="surface-ground px-2 py-2 md:px-2 lg:px-2" *ngIf="!isLoading">
      <div *ngIf="isAzienda; else adminBlock">
        <div class="grid" *ngIf="!isLoading">
          <div class="col-8 p-1" style="height: 700px;">
            <p-tabView styleClass="tabview-custom" (onChange)="onTabChange($event)">
              <p-tabPanel *ngIf="hasBando1|| hasBando2">
                <ng-template pTemplate="header" style="color: #0A122A;">
                  <i class="material-symbols-outlined mr-2" style="color: #0A122A;"> school </i>
                  <div class=" font-semibold font-['Poppins']" style="color: #0A122A;">SCUOLA</div>
                </ng-template>
                <div *ngIf="hasBando1" class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round" style="height: 275px; ">
                  <div class="grid mt-1">
                    <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']" style="color: cadetblue;">
                      Pratiche scuola BANDO 1: 
                      <span class="text-2xl text-left font-bold font-['Poppins']" style="color: cadetblue;"> {{this.stats.numeroTotalePraticheScuolaBando1}}</span>
                    </div>
                      
                    <div class="col-2 mr-2 text-right">
                     
                      <button pButton pRipple type="button" icon="pi pi-external-link" pTooltip="Mostra tutte"
                        class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-2 p-3"
                        style="width:49px; height: 49px; background-color: #9ED8DB; border-color: #9ED8DB;"
                        (click)="openPraticheScuolaBando1()"></button>
                    </div>
                  </div>
                  <div class="grid mt-1 mb-1">
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                        <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheScuolaBando1('ASSEGNATA')" pTooltip="Apri Pratiche Assegnate"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left;color: #1C56AC">
                          {{this.stats.numeroPraticheScuolaAssegnate}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #1C56AC; font-weight: bold; text-align: left; font-size: 0.70em;">
                          Assegnate</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                        <i class="pi pi-user" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheScuolaBando1('IN CARICO IMPRESA')" pTooltip="Apri Pratiche in Carico"
                          [matTooltipPosition]="above"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #607520;">
                          {{this.stats.numeroPraticheScuolaInCarico}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #607520; font-weight: bold; text-align: left; font-size: 0.70em;">In
                          Carico</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #9ED8DB; text-align: right;">
                        <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheScuolaBando1('IN PROGETTAZIONE')" pTooltip="Apri Pratiche in Progettazione"
                          [matTooltipPosition]="above"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #1D3354;">
                          {{this.stats.numeroPraticheScuolaInProgettazione}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #1D3354; font-weight: bold; text-align: left; font-size: 0.70em;">In
                          Progettazione</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #91F88D; text-align: right;">
                        <i class="material-symbols-outlined" tooltipPosition="top"
                          style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando1('CONSEGNATA')"
                          pTooltip="Apri Pratiche Consegnate">markunread_mailbox</i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #30512f;">
                          {{this.stats.numeroPraticheScuolaConsegnate}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #30512f; font-weight: bold; text-align: left; font-size: 0.70em;">
                          Consegnate</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #DD614A; text-align: right;">
                        <i class="material-symbols-outlined" tooltipPosition="top"
                          style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheScuolaBando1('IN REWORK AZIENDA')"
                          pTooltip="Apri Pratiche In Rework">reset_image</i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #5F2920;">
                          {{this.stats.numeroPraticheScuolaInRework}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #5F2920; font-weight: bold; text-align: left; font-size: 0.70em;">In
                          Rework</div>
                      </div>
                    </div>
                  </div>
                </div>
            <div *ngIf="hasBando2" class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round"  style="height: 275px; ">

              <div class="grid mt-1">
                <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']"
                style="color: cornflowerblue;">Pratiche scuola BANDO 2: 
                <span class="text-2xl text-left font-bold font-['Poppins']" style="color: cornflowerblue;"> {{this.stats.numeroTotalePraticheScuolaBando2}}</span>
              </div>
                <div class="col-2 mr-2 text-right">
                  <button pButton pRipple type="button" icon="pi pi-external-link" pTooltip="Mostra tutte"
                    class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-2 p-3"
                    style="width:49px; height: 49px; background-color: #9ED8DB; border-color: #9ED8DB;"
                    (click)="openPraticheScuolaBando2()"></button>
                </div>
              </div>
              <div class="grid mt-1 mb-1">
                <div class="col p-3 ">
                  <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                    <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                      (click)="openPraticheScuolaBando2('ASSEGNATA')" pTooltip="Apri Pratiche Assegnate"></i>
                    <div class="text-4xl font-['Poppins'] mt-1"
                      style="font-weight: bold; text-align: left;color: #1C56AC">
                      {{this.stats.numeroPraticheScuolaBando2Assegnate}}</div>
                    <div class="text-1xl font-['Poppins']" style="color: #1C56AC; font-weight: bold; text-align: left; font-size: 0.70em;">
                      Assegnate</div>
                  </div>
                </div>
                <div class="col p-3 ">
                  <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                    <i class="pi pi-user" style="font-size: 1.5rem; cursor: pointer;"
                      (click)="openPraticheScuolaBando2('IN CARICO IMPRESA')" pTooltip="Apri Pratiche in Carico"
                      [matTooltipPosition]="above"></i>
                    <div class="text-4xl font-['Poppins'] mt-1"
                      style="font-weight: bold; text-align: left; color: #607520;">
                      {{this.stats.numeroPraticheScuolaBando2InCarico}}</div>
                    <div class="mt-2 font-['Poppins']" style="color: #607520; font-weight: bold; text-align: left; font-size: 0.70em;">In
                      Carico</div>
                  </div>
                </div>
                <div class="col p-3 ">
                  <div class="p-3" style="border-radius: 17px; background-color: #9ED8DB; text-align: right;">
                    <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                      (click)="openPraticheScuolaBando2('IN PROGETTAZIONE')" pTooltip="Apri Pratiche in Progettazione"
                      [matTooltipPosition]="above"></i>
                    <div class="text-4xl font-['Poppins'] mt-1"
                      style="font-weight: bold; text-align: left; color: #1D3354;">
                      {{this.stats.numeroPraticheScuolaBando2InProgettazione}}</div>
                    <div class="mt-2 font-['Poppins']" style="color: #1D3354; font-weight: bold; text-align: left; font-size: 0.70em;">In
                      Progettazione</div>
                  </div>
                </div>
                <div class="col p-3 ">
                  <div class="p-3" style="border-radius: 17px; background-color: #91F88D; text-align: right;">
                    <i class="material-symbols-outlined" tooltipPosition="top"
                      style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando2('CONSEGNATA')"
                      pTooltip="Apri Pratiche Consegnate">markunread_mailbox</i>
                    <div class="text-4xl font-['Poppins'] mt-1"
                      style="font-weight: bold; text-align: left; color: #30512f;">
                      {{this.stats.numeroPraticheScuolaBando2Consegnate}}</div>
                    <div class="mt-2 font-['Poppins']" style="color: #30512f; font-weight: bold; text-align: left; font-size: 0.70em;">
                      Consegnate</div>
                  </div>
                </div>
                <div class="col p-3 ">
                  <div class="p-3" style="border-radius: 17px; background-color: #DD614A; text-align: right;">
                    <i class="material-symbols-outlined" tooltipPosition="top"
                      style="font-size: 1.5rem; cursor: pointer;"
                      (click)="openPraticheScuolaBando2('IN REWORK AZIENDA')"
                      pTooltip="Apri Pratiche In Rework">reset_image</i>
                    <div class="text-4xl font-['Poppins'] mt-1"
                      style="font-weight: bold; text-align: left; color: #5F2920;">
                      {{this.stats.numeroPraticheScuolaBando2InRework}}</div>
                    <div class="mt-2 font-['Poppins']" style="color: #5F2920; font-weight: bold; text-align: left; font-size: 0.70em;">In
                      Rework</div>
                  </div>
                </div>
              </div> 
            </div>
              </p-tabPanel>
              <p-tabPanel *ngIf="hasSanita" header="Header II">
                <ng-template pTemplate="header">
                  <i class="material-symbols-outlined mr-2"> local_hospital </i>
                  <span>SANITA</span>
                </ng-template> 
                  <div class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round"
                    style="height: 560px;border-color:#147C6C">
                  <div class="grid mt-2 ">
                    <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']"
                    style="color: #2040a1;">Pratiche SANITA'</div>
                    <div class="col-2 mr-2 text-right">
                      <button pButton pRipple type="button" icon="pi pi-external-link"
                        pTooltip="Mostra tutte"
                        class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1"
                        style="width:49px; height: 49px; background-color: #2040a1; border-color: #2040a1;"
                        (click)="openPraticheSanita()"></button>
                    </div>
                  </div>
                  <div class="grid mt-1 mb-1">
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                        <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheSanita('ASSEGNATA')" pTooltip="Apri Pratiche Assegnate"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left;color: #1C56AC">
                          {{this.stats.numeroPraticheSanitaAssegnate}}</div>
                        <div class="text-1xl font-['Poppins']" style="color: #1C56AC; font-weight: bold; text-align: left;">
                          Assegnate</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                        <i class="pi pi-user" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheSanita('IN CARICO IMPRESA')" pTooltip="Apri Pratiche in Carico"
                          [matTooltipPosition]="above"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #607520;">
                          {{this.stats.numeroPraticheSanitaInCarico}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #607520; font-weight: bold; text-align: left;">In
                          Carico</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #9ED8DB; text-align: right;">
                        <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheSanita('IN PROGETTAZIONE')" pTooltip="Apri Pratiche in Progettazione"
                          [matTooltipPosition]="above"></i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #1D3354;">
                          {{this.stats.numeroPraticheSanitaInProgettazione}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #1D3354; font-weight: bold; text-align: left;">In
                          Progettazione</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #91F88D; text-align: right;">
                        <i class="material-symbols-outlined" tooltipPosition="top"
                          style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('CONSEGNATA')"
                          pTooltip="Apri Pratiche Consegnate">markunread_mailbox</i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #30512f;">
                          {{this.stats.numeroPraticheSanitaConsegnate}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #30512f; font-weight: bold; text-align: left;">
                          Consegnate</div>
                      </div>
                    </div>
                    <div class="col p-3 ">
                      <div class="p-3" style="border-radius: 17px; background-color: #DD614A; text-align: right;">
                        <i class="material-symbols-outlined" tooltipPosition="top"
                          style="font-size: 1.5rem; cursor: pointer;"
                          (click)="openPraticheSanita('IN REWORK AZIENDA')"
                          pTooltip="Apri Pratiche In Rework">reset_image</i>
                        <div class="text-4xl font-['Poppins'] mt-1"
                          style="font-weight: bold; text-align: left; color: #5F2920;">
                          {{this.stats.numeroPraticheSanitaInRework}}</div>
                        <div class="mt-2 font-['Poppins']" style="color: #5F2920; font-weight: bold; text-align: left;">In
                          Rework</div>
                      </div>
                    </div>
                  </div> 
                  <div class="grid mt-1">
                    <div class="col ml-3 p-3 text-1xl text-left font-semibold font-['Poppins']"
                      style="color: #2040a1;">Ultime Pratiche Aggiornate</div>
                  </div>
                  <div class="grid">
                    <div class="ml-3 col p-3">

                      <p-table #ultimePraticheSanitaDT styleClass="p-datatable-sm" [value]="lastPraticheSanitaPerAzienda"
                        [(selection)]="selectedPratica" dataKey="id" [selectionPageOnly]="true" [rowHover]="true"
                        [rows]="5" [showCurrentPageReport]="true" [loading]="isLoadingResults"
                        responsiveLayout="scroll" [paginator]="false">
                        <ng-template pTemplate="header">
                          <tr>
                            <th class="text-center" style="font-size: 10px;">
                              <div class="flex justify-content-between align-items-center">Piano Tecnico</div>
                            </th>
                            <th class="text-center" style="font-size: 10px;">
                              <div class="flex justify-content-between align-items-center">Codice INFRATEL</div>
                            </th>
                            <th class="text-center" style="font-size: 10px;">
                              <div class="flex justify-content-between align-items-center">Denominazione</div>
                            </th>                     
                            <th class="text-center" style="font-size: 10px;">
                              <div class="flex justify-content-between align-items-center">Comune</div>
                            </th>
                            <th class="text-center" style="font-size: 10px;">
                              <div class="flex justify-content-between align-items-center">Stato</div>
                            </th>
                            <th class="text-center" style="font-size: 10px;"></th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-row>
                          <tr>
                            <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.pianoTecnico}}</span> </td>
                            
                            <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.codiceInfratel}}</span> </td>
                            <td class="text-left"> <span class="text-uppercase" style="font-size: 10px;">{{row.denominazione}}</span> </td>
                            
                            <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.comune}}</span> </td>
                            <td class="text-left"> <span [ngClass]="getStatoClass(row.stato)" style="font-size: 7px;">{{row.stato}}</span>
                            </td>
                            <td class="text-left">
                              <i class="material-symbols-outlined" style="color: #467248; cursor: pointer" pTooltip="Apri dettagli"
                                tooltipPosition="top" (click)="openPraticaSanita(row)">
                                outbound
                              </i>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
                
              </p-tabPanel>
            </p-tabView>
           <!--  
            

             -->
          </div>
          <div class="col p-1" style="height: 700px;">
            <div class="surface-card shadow-2 rounded-xl border-round" style="height: 100%;">
              <div class="col text-center text-2xl font-semibold font-['Poppins']">Distribuzione Pratiche</div>
              <div id="mappaPratiche" leaflet class="map-container mb-1 mt-1 ml-1 mr-1" style="height: 90%; z-index: 1;"
                [leafletMarkerClusterOptions]="markerClusterOptions" [leafletOptions]="mapOptions"
                (leafletMapReady)="onMapReady($event)" (leafletClick)="mapClicked($event)">
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #adminBlock>
        <div *ngIf="isLoading"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.5);  display: flex; justify-content: center; align-items: center;">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="grid" *ngIf="!isLoading">
          <div class="col-8 p-1" style="height: 700px;">
            <div class="surface-card shadow-2 rounded-xl border-round" style="height: 100%;">
              <p-tabView styleClass="tabview-custom" (onChange)="onTabChange($event)">
                <p-tabPanel>
                  <ng-template pTemplate="header" style="color: #0A122A;">
                    <i class="material-symbols-outlined mr-2" style="color: #0A122A;"> school </i>
                    <div class=" font-semibold font-['Poppins']" style="color: #0A122A;">SCUOLA</div>
                  </ng-template>

                  <div class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round"
                    style="height: 270px;  border-color:#147C6C">

                    <div class="grid mt-1">
                      <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']" style="color: cadetblue;">
                        Pratiche scuola BANDO 1: 
                        <span class="text-2xl text-left font-bold font-['Poppins']" style="color: cadetblue;"> {{this.stats.numeroTotalePraticheScuolaBando1}}</span>
                      </div>
                      <div class="col-6 mr-2 text-right">
                        <button pButton pRipple type="button" pTooltip="Aggiorna Pratiche"
                          *ngIf="!impresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3"
                          style="width:49px; height: 49px; background-color: cornflowerblue; border-color: cornflowerblue;"
                          (click)="openAggiornaPraticheBando1()">
                          <i class="material-symbols-outlined"> move </i>
                        </button>
                        <button pButton pRipple type="button" pTooltip="Statistiche"
                          *ngIf="!isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3"
                          style="width:49px; height: 49px; background-color: cadetblue; border-color: cadetblue;"
                          (click)="openStatisticheScuola('1')" [disabled]="true">
                          <i class="material-symbols-outlined"> monitoring </i>
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-external-link" pTooltip="Mostra tutte"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 p-3"
                          style="width:49px; height: 49px; background-color: cadetblue; border-color: cadetblue;"
                          (click)="openPraticheScuolaBando1()"></button>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isAdmin || isSuperAdmin || isPresidioSF">
                      <div class="col p-3 ">
                        <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                          <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('DA ASSEGNARE')"
                            pTooltip="Apri Pratiche da Assegnare"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #1C56AC">
                            {{this.stats.numeroPraticheScuolaDaAssegnare}}</div>
                          <div class=" mt-2 font-['Poppins']"
                            style="color: #1C56AC; font-weight: bold; text-align: left; font-size: 0.70em;">Da Assegn.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                          <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN LAVORAZIONE')"
                            pTooltip="Apri Pratiche IN LAVORAZIONE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #607520">
                            {{this.stats.numeroPraticheScuolaInLavorazione}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #607520; font-weight: bold; text-align: left;font-size: 0.70em;">In Lavoraz.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #B5DCB6; text-align: right;">
                          <i class="pi pi-eye" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN CARICO SF')"
                            pTooltip="Apri Pratiche IN CARICO SF"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left;color: #467248">
                            {{this.stats.numeroPraticheScuolaInCaricoSF}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #467248; font-weight: bold; text-align: left;font-size: 0.70em;">In carico SF
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A2E8DD; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheScuolaInCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left;font-size: 0.70em;">Al Presidio
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #b6a9dc; text-align: right;">
                          <i class="material-symbols-outlined" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN FIRMA')"
                            pTooltip="Apri Pratiche IN FIRMA">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheScuolaInFirma}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left; font-size: 0.70em;">In Firma
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheScuolaRespinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left;font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #279446">
                            {{this.stats.numeroPraticheScuolaValidate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #279446; font-weight: bold; text-align: left;font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isPresidioTIM">

                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #D9EDFC; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #334c5f;">
                            {{this.stats.numeroPraticheScuolaInCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #334c5f; font-weight: bold; text-align: left; font-size: 0.70em;">In Carico
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9dc8f1; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche in firma VERBALE">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaInFirmaVerbale}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.68em;">Firma VERB.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #ACD7EC; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche in firma PACCHETTO">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaInFirmaPacchetto}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.61em;">Firma PACCH.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A9F1DF; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando1('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaPacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3"
                          style="border-radius: 17px; background-color: rgb(67, 176, 220); text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando1('PUBBLICATA')"
                            pTooltip="Apri Pratiche Pubblicate">forward_to_inbox</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: rgb(23, 40, 54);">
                            {{this.stats.numeroPraticheScuolaPubblicate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: rgb(23, 40, 54); font-weight: bold; text-align: left; font-size: 0.70em;">
                            Pubblicate</div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheScuolaRespinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left; font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #0a2311">
                            {{this.stats.numeroPraticheScuolaValidate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #0a2311; font-weight: bold; text-align: left; font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaVerbali">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheScuolaInFirmaVerbale}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheScuolaVerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaPacchetti">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando1('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheScuolaVerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando1('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheScuolaPacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round"
                    style="height: 270px;  border-color:#147C6C">
                    <div class="grid mt-1">
                      <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']"
                        style="color: cornflowerblue;">Pratiche scuola BANDO 2: 
                        <span class="text-2xl text-left font-bold font-['Poppins']" style="color: cornflowerblue;"> {{this.stats.numeroTotalePraticheScuolaBando2}}</span></div>
                      <div class="col-6 mr-2 text-right">
                        <button pButton pRipple type="button" pTooltip="Aggiorna Pratiche"
                          *ngIf="!impresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3"
                          style="width:49px; height: 49px; background-color: cornflowerblue; border-color: cornflowerblue;"
                          (click)="openAggiornaPraticheBando2()">
                          <i class="material-symbols-outlined"> move </i>
                        </button>
                        <button pButton pRipple type="button" pTooltip="Statistiche"
                          *ngIf="!isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3"
                          style="width:49px; height: 49px; background-color: cornflowerblue; border-color: cornflowerblue;"
                          (click)="openStatisticheScuola('2')" [disabled]="true">
                          <i class="material-symbols-outlined"> monitoring </i>
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-external-link" pTooltip="Mostra tutte"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 p-3"
                          style="width:49px; height: 49px; background-color: cornflowerblue; border-color: cornflowerblue;"
                          (click)="openPraticheScuolaBando2()"></button>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isAdmin || isSuperAdmin || isPresidioSF">
                      <div class="col p-3 ">
                        <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                          <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('DA ASSEGNARE')"
                            pTooltip="Apri Pratiche da Assegnare"></i>
                          <div class="text-4xl font-['Poppins'] text-blue-800 mt-1"
                            style="font-weight: bold; text-align: left;">
                            {{this.stats.numeroPraticheScuolaBando2DaAssegnare}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #1C56AC; font-weight: bold; text-align: left;font-size: 0.70em;">Da Assegn.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                          <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN LAVORAZIONE')"
                            pTooltip="Apri Pratiche IN LAVORAZIONE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #607520">
                            {{this.stats.numeroPraticheScuolaBando2InLavorazione}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #607520; font-weight: bold; text-align: left; font-size: 0.70em;">In Lavoraz.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #B5DCB6; text-align: right;">
                          <i class="pi pi-eye" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN CARICO SF')"
                            pTooltip="Apri Pratiche IN CARICO SF"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left;color: #467248">
                            {{this.stats.numeroPraticheScuolaBando2InCaricoSF}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #467248; font-weight: bold; text-align: left; font-size: 0.70em;">In carico SF
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A2E8DD; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheScuolaBando2InCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left; font-size: 0.70em;">Al Presidio
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #b6a9dc; text-align: right;">
                          <i class="material-symbols-outlined" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN FIRMA')"
                            pTooltip="Apri Pratiche IN FIRMA">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheScuolaBando2InFirma}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left; font-size: 0.70em;">In Firma
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheScuolaBando2Respinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left; font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #0a2311">
                            {{this.stats.numeroPraticheScuolaBando2Validate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #0a2311; font-weight: bold; text-align: left; font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isPresidioTIM">

                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #D9EDFC; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #334c5f;">
                            {{this.stats.numeroPraticheScuolaBando2InCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #334c5f; font-weight: bold; text-align: left; font-size: 0.70em;">In Carico
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9dc8f1; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche in firma VERBALE">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaBando2InFirmaVerbale }}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.68em;">Firma VERB.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #ACD7EC; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN FIRMA PACCHETTO')"
                            pTooltip="Apri Pratiche in firma PACCHETTO">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaBando2InFirmaPacchetto}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.61em;">Firma PACCH.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A9F1DF; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando2('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheScuolaBando2PacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3"
                          style="border-radius: 17px; background-color: rgb(67, 176, 220); text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando2('PUBBLICATA')"
                            pTooltip="Apri Pratiche Pubblicate">forward_to_inbox</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: rgb(23, 40, 54);">
                            {{this.stats.numeroPraticheScuolaBando2Pubblicate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: rgb(23, 40, 54); font-weight: bold; text-align: left; font-size: 0.70em;">
                            Pubblicate</div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheScuolaBando2Respinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left; font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #0a2311">
                            {{this.stats.numeroPraticheScuolaBando2Validate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #0a2311; font-weight: bold; text-align: left; font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaVerbali">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheScuolaBando2InFirmaVerbale}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheScuolaBando2VerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaPacchetti">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheScuolaBando2VerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheScuolaBando2('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheScuolaBando2PacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </p-tabPanel>
                <p-tabPanel header="Header II">
                  <ng-template pTemplate="header">
                    <i class="material-symbols-outlined mr-2"> local_hospital </i>
                    <span>SANITA </span>
                  </ng-template>
                  <div class="surface-card shadow-2 rounded-xl mt-3 border-1 border-round"
                    style="height: 560px;  border-color:#147C6C">
                    <div class="grid mt-2 ">
                      <div class="col ml-3 p-3 text-2xl text-left font-semibold font-['Poppins']"
                        style="color: #2040a1;">Pratiche SANITA'</div>
                        <div class="col-6 mr-2 text-right">
                        <button pButton pRipple type="button" pTooltip="Aggiorna Pratiche"
                          *ngIf="!impresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3"
                          style="width:49px; height: 49px; background-color: cornflowerblue; border-color: cornflowerblue;"
                          (click)="openAggiornaPraticheSanita()">
                          <i class="material-symbols-outlined"> move </i>
                        </button>
                        <button [disabled]="true" pButton pRipple type="button" pTooltip="Statistiche"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 "
                          style="width:49px; height: 49px; background-color: #C2B5CB; border-color: #C2B5CB;"
                          (click)="openStatisticheSanita()">
                          <i class="material-symbols-outlined"> monitoring </i>
                        </button>
                        <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton pRipple type="button" icon="pi pi-folder-open"
                        pTooltip="Mostra Piani Tecnici"
                        class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2"
                        style="width:49px; height: 49px; background-color: #49b38e; border-color: #10b919;"
                        (click)="openPianiTecnici()"></button>
                        
                        <button pButton pRipple type="button" icon="pi pi-external-link"
                          pTooltip="Mostra tutte"
                          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1"
                          style="width:49px; height: 49px; background-color: #2040a1; border-color: #2040a1;"
                          (click)="openPraticheSanita()"></button>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isAdmin || isSuperAdmin || isPresidioSF">
                      <div class="col p-3 ">
                        <div class="p-3" style="border-radius: 17px; background-color: #C4E4F5; text-align: right;">
                          <i class="pi pi-link" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheScuolaBando2('DA ASSEGNARE')"
                            pTooltip="Apri Pratiche da Assegnare"></i>
                          <div class="text-4xl font-['Poppins'] text-blue-800 mt-1"
                            style="font-weight: bold; text-align: left;">{{this.stats.numeroPraticheSanitaDaAssegnare}}
                          </div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #1C56AC; font-weight: bold; text-align: left;font-size: 0.70em;">Da Assegn.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #E6F8B2; text-align: right;">
                          <i class="pi pi-cog" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN LAVORAZIONE')" pTooltip="Apri Pratiche IN LAVORAZIONE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #607520">
                            {{this.stats.numeroPraticheSanitaInLavorazione}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #607520; font-weight: bold; text-align: left; font-size: 0.70em;">In Lavoraz.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #B5DCB6; text-align: right;">
                          <i class="pi pi-eye" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN CARICO SF')" pTooltip="Apri Pratiche IN CARICO SF"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left;color: #467248">
                            {{this.stats.numeroPraticheSanitaInCaricoSF}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #467248; font-weight: bold; text-align: left; font-size: 0.70em;">In carico SF
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A2E8DD; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheSanitaInCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left; font-size: 0.70em;">Al Presidio
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #b6a9dc; text-align: right;">
                          <i class="material-symbols-outlined" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN FIRMA')" pTooltip="Apri Pratiche IN FIRMA">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #147C6C;">
                            {{this.stats.numeroPraticheSanitaInFirma}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #147C6C; font-weight: bold; text-align: left; font-size: 0.70em;">In Firma
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheSanitaRespinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left; font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #0a2311">
                            {{this.stats.numeroPraticheSanitaValidate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #0a2311; font-weight: bold; text-align: left; font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isPresidioTIM">

                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #D9EDFC; text-align: right;">
                          <i class="pi pi-send" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN CARICO PRESIDIO')"
                            pTooltip="Apri Pratiche IN CARICO PRESIDIO"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #334c5f;">
                            {{this.stats.numeroPraticheSanitaInCaricoPresidio}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #334c5f; font-weight: bold; text-align: left; font-size: 0.70em;">In Carico
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9dc8f1; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche in firma VERBALE">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheSanitaInFirmaVerbale }}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.68em;">Firma VERB.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #ACD7EC; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('IN FIRMA PACCHETTO')"
                            pTooltip="Apri Pratiche in firma PACCHETTO">stylus_note</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheSanitaInFirmaPacchetto}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.61em;">Firma PACCH.
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #A9F1DF; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #144a7c;">
                            {{this.stats.numeroPraticheSanitaPacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #144a7c; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3"
                          style="border-radius: 17px; background-color: rgb(67, 176, 220); text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('PUBBLICATA')"
                            pTooltip="Apri Pratiche Pubblicate">forward_to_inbox</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: rgb(23, 40, 54);">
                            {{this.stats.numeroPraticheSanitaPubblicate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: rgb(23, 40, 54); font-weight: bold; text-align: left; font-size: 0.70em;">
                            Pubblicate</div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #EC935C; text-align: right;">
                          <i class="pi pi-times-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('RESPINTA')" pTooltip="Apri Pratiche RESPINTE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #683312">
                            {{this.stats.numeroPraticheSanitaRespinte}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #683312; font-weight: bold; text-align: left; font-size: 0.70em;">Respinte
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #AEFBC4; text-align: right;">
                          <i class="pi pi-check-circle" style="font-size: 1.5rem; cursor: pointer;"
                            (click)="openPraticheSanita('APPROVATA')" pTooltip="Apri Pratiche APPROVATE"></i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #0a2311">
                            {{this.stats.numeroPraticheSanitaValidate}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #0a2311; font-weight: bold; text-align: left; font-size: 0.70em;">Approvate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaVerbali">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('IN FIRMA VERBALE')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheSanitaInFirmaVerbale}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheSanitaVerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid mt-1 mb-1" *ngIf="isDirigenteFirmaPacchetti">
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #9AD5CA; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('VERBALE FIRMATO')"
                            pTooltip="Apri Pratiche da Firmare">draw</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #32564F;">
                            {{this.stats.numeroPraticheSanitaVerbaleFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #32564F; font-weight: bold; text-align: left; font-size: 0.70em;">Da Firmare
                          </div>
                        </div>
                      </div>
                      <div class="col p-3">
                        <div class="p-3" style="border-radius: 17px; background-color: #31cdab; text-align: right;">
                          <i class="material-symbols-outlined" tooltipPosition="top"
                            style="font-size: 1.5rem; cursor: pointer;" (click)="openPraticheSanita('FIRMATA')"
                            pTooltip="Apri Pratiche Firmate">task</i>
                          <div class="text-4xl font-['Poppins'] mt-1"
                            style="font-weight: bold; text-align: left; color: #03382C;">
                            {{this.stats.numeroPraticheSanitaPacchettoFirmato}}</div>
                          <div class="mt-2 font-['Poppins']"
                            style="color: #03382C; font-weight: bold; text-align: left; font-size: 0.70em;">Firmate
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="grid mt-1">
                      <div class="col ml-3 p-3 text-1xl text-left font-semibold font-['Poppins']"
                        style="color: #2040a1;">Ultime 5 Pratiche Aggiornate</div>
                    </div>
                    <div class="grid">
                     <div class="ml-3 col p-3">

                        <p-table #ultimePraticheSanitaDT styleClass="p-datatable-sm" [value]="lastPraticheSanita"
                          [(selection)]="selectedPratica" dataKey="id" [selectionPageOnly]="true" [rowHover]="true"
                          [rows]="5" [showCurrentPageReport]="true" [loading]="isLoadingResults"
                          responsiveLayout="scroll" [paginator]="false">
                          <ng-template pTemplate="header">
                            <tr>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center">Piano Tecnico</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center">Codice INFRATEL</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center">Denominazione</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center"> Impresa</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center"> Comune</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;">
                                <div class="flex justify-content-between align-items-center"> Stato</div>
                              </th>
                              <th class="text-center" style="font-size: 10px;"></th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-row>
                            <tr>
                              <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.pianoTecnico}}</span> </td>
                              <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.idAccessoInfratel}}</span> </td>
                              <td class="text-left"> <span class="text-uppercase" style="font-size: 10px;">{{row.denominazione}}</span> </td>
                              <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.azienda}}</span> </td>
                              <td class="text-left"> <span class="text-lowercase" style="font-size: 10px;">{{row.comune}}</span> </td>
                              <td class="text-left"> <span [ngClass]="getStatoClass(row.stato)" style="font-size: 6px;">{{row.stato}}</span>
                              </td>
                              <td class="text-left">
                                <i class="material-symbols-outlined" style="color: #467248; cursor: pointer" pTooltip="Apri dettagli"
                                  tooltipPosition="top" (click)="openPraticaSanita(row)">
                                  outbound
                                </i>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div> 
                    </div>

                  </div>

                </p-tabPanel>
              </p-tabView>
            </div>


          </div>

          <div class="col p-1" style="height: 700px;">
            <div class="surface-card shadow-2 rounded-xl border-round" style="height: 100%;">
              <div class="col text-center text-2xl font-semibold font-['Poppins']">Distribuzione Pratiche</div>
              <div id="mappaPratiche" leaflet class="map-container mb-1 mt-1 ml-1 mr-1" style="height: 90%; z-index: 1;"
                [leafletOptions]="mapOptions" [leafletMarkerClusterOptions]="markerClusterOptions"
                (leafletMapReady)="onMapReady($event)" (leafletClick)="mapClicked($event)">
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </div>
  </div>
<!-- 
  style="background: linear-gradient(to bottom, #308CBD, #15517e);" 
-->
  <ng-template #elseBlock>
    <div class="d-flex flex-column h-screen justify-between items-center px-4 py-8 md:px-6 lg:px-8" style="background: url('assets/images/background_flowdy.png');" *ngIf="!isLoggedIn">
      <div class="d-flex flex-column text-center align-items-center align-content-center">
        <img class="mx-auto w-[889px] h-[194px] mt-1 mb-1" src="assets/loghi/LOGO_HOME.png" />
        <div class="text-center mt-0 mb-7">
          <span class="text-center mr-2 text-zinc-600 text-2xl font-light font-['Inter']">by</span>
          <img src="assets/loghi/Logo_TIM.svg" />
        </div>
        <div class="text-center mt-7 text-zinc-700 text-4xl font-light font-['Inter'] ">Benvenuto!</div>
        <button type="button" pButton pRipple label="ACCEDI"
          class="mt-5 mb-1 lg:mb-0 p-button-rounded p-button-success p-button-lg font-medium"
          style="background-color: #EB566A; color: white;" (click)="loginWithRedirect()"></button>
      </div>
      <!-- <div class="text-center mb-5 text-neutral-400 text-lg font-light font-['Inter']">Powered by Vertical srl</div> -->
    </div>
    <!-- <div class=" px-4 py-8 md:px-6 lg:px-8"  *ngIf="!isLoggedIn">
      <div class="flex flex-wrap relative overflow-hidden">
        <div class="w-12 lg:w-6 p-4">
          <img class="w-[889px] h-[194px]" src="assets/loghi/LOGO_HOME.png" />
          <h1 class="text-5xl font-bold text-white mt-0 mb-3">Benvenuto in FLOWDY!</h1>

          <button type="button" pButton pRipple label="ACCEDI"
            class="block mt-7 mb-7 lg:mb-0 p-button-rounded p-button-success p-button-lg font-medium"
            (click)="loginWithRedirect()"></button>
        </div>       
      </div>
    </div> -->

  </ng-template>
</div>

<p-toast position="top-center"></p-toast>