
<div class="surface-ground px-1 py-2 md:px-2 lg:px-2">
   <div class="surface-card p-4 shadow-2 border-round">
    <form [formGroup]="modificaImpresaForm">   
       <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 md:col-12">
            <label for="azienda" class="font-medium text-900">Scegli la nuova impresa dell'utente</label>
            <p-autoComplete 
                formControlName="azienda"
                [suggestions]="filteredAziende" 
                (completeMethod)="filterAzienda($event)"
                field="ragioneSociale" placeholder="Ragione sociale"
            ></p-autoComplete>
            <!-- <p-dropdown inputId="azienda" [options]="imprese" optionLabel="ragioneSociale" [showClear]="true"
                placeholder="Scegli l'impresa" formControlName="azienda">
                <ng-template let-azienda pTemplate="item">
                    <div class="flex align-items-center">
                        <div>{{azienda.ragioneSociale}}</div>
                    </div>
                </ng-template>
            </p-dropdown> -->
        </div>
         
           <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
           <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
          
            <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1" styleClass="p-button-success" 
                 [disabled]="!modificaImpresaForm.valid"></p-button>
            <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
             styleClass="p-button-warning" ></p-button>
        </div>
        
       </div>
      </form>
 </div> 
 </div>
 