import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '@auth0/auth0-angular';

import { RoleGuard } from './auth/role.guard';
import { UnderConstructionComponent } from './pages/errors/under-construction/under-construction.component';
import { UtentiComponent } from './pages/utenti/utenti.component';
import { ImpreseComponent } from './pages/imprese/imprese.component';
import { PraticheScuolaBando2Component } from './pages/pratiche/scuola/bando2/pratiche-scuola-bando2.component';
import { PraticheScuolaBando1Component } from './pages/pratiche/scuola/bando1/pratiche-scuola-bando1.component';
import { CentraliComponent } from './pages/centrali/centrali.component';
import { PraticheSanitaComponent } from './pages/pratiche/sanita/pratiche-sanita.component';
import { ComuniComponent } from './pages/comuni/comuni.component';
import { PianiTecniciSanitaComponent } from './pages/pratiche/sanita/pianiTecnici/piani-tecnici-sanita.component';


const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'utenti',  component: UtentiComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN','SMARTFIBER']},},
  {path: 'imprese',  component: ImpreseComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN','SMARTFIBER']},},
  {path: 'comuni',  component: ComuniComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN','SMARTFIBER']},},
  {path: 'centrali',  component: CentraliComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN','SMARTFIBER']},},
  {path: 'pratiche-scuola-bando2',  component: PraticheScuolaBando2Component,  canActivate: [AuthGuard]},
  {path: 'pratiche-scuola-bando2/stato/:stato', component: PraticheScuolaBando2Component, canActivate: [AuthGuard] },
  {path: 'pratiche-scuola-bando2/pratica/:pratica', component: PraticheScuolaBando2Component, canActivate: [AuthGuard] },
  {path: 'pratiche-scuola-bando1',  component: PraticheScuolaBando1Component, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'pratiche-scuola-bando1/stato/:stato', component: PraticheScuolaBando1Component,  pathMatch: 'full', canActivate: [AuthGuard] },
  {path: 'pratiche-scuola-bando1/pratica/:pratica', component: PraticheScuolaBando1Component, canActivate: [AuthGuard] },
  {path: 'pratiche-sanita',  component: PraticheSanitaComponent,  canActivate: [AuthGuard]},
  {path: 'pratiche-sanita/stato/:stato', component: PraticheSanitaComponent, canActivate: [AuthGuard] },
  {path: 'pratiche-sanita/pt/:pianoTecnico', component: PraticheSanitaComponent, canActivate: [AuthGuard] },
  {path: 'pratiche-sanita/pratica/:pratica', component: PraticheSanitaComponent, canActivate: [AuthGuard] },
  {path: 'pratiche-sanita/piani-tecnici',  component: PianiTecniciSanitaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN','SMARTFIBER']},},

  /* {path: 'cdc',  component: CentriDiCostoComponent, canActivate: [ AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'clienti',  component: ClientiComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'comuni',  component: ComuniComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'commesse',  component: CommesseComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'tipispesa',  component: TipoSpesaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'tipipagamento',  component: TipoPagamentoComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},

  {path: 'commesse/:id',  component: CommesseComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'RESPONSABILE', 'SUPERADMIN']},},

  {path: 'registro',  component: AttivitaComponent, canActivate: [AuthGuard],},
  {path: 'registro/:meseRiferimento',  component: AttivitaComponent, canActivate: [AuthGuard],},

  {path: 'spese',  component: SpeseComponent, canActivate: [AuthGuard],},
  {path: 'registro-spese',  component: RegistroSpeseComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
  {path: 'registro-spese/:idCommessa',  component: RegistroSpeseComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'RESPONSABILE', 'SUPERADMIN']},},

  {path: 'registro-attivita/:idCommessa',  component: RegistroAttivitaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'RESPONSABILE', 'SUPERADMIN']},},
  {path: 'registro-attivita/:idCommessa/:idDipendente',  component: RegistroAttivitaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'RESPONSABILE', 'SUPERADMIN']},},

  {path: 'registro-attivita',  component: RegistroAttivitaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['CONTABILE', 'ADMIN', 'SUPERADMIN']},},
 */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy} 
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
