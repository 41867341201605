<div class="map-container"
style="height: 85%; width: 100%; display: flex; justify-content: center; align-items: center;"
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="mapClicked($event)">
</div>
<div class="mt-2" style="text-align: center; display: flex; justify-content: center; align-items: center;">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
   styleClass="p-button-success" [disabled]="!positionHasChanged"></p-button>
   <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
   styleClass="p-button-warning" ></p-button>
</div>