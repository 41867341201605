import { Impresa } from "./impresa.model";
import { RuoloDipendente } from "./utente.ruolo.model";

export class Utente {
    id: Number;
    nome: String;
    idAuth0:String;

    password:String;
    email:String;

    ruolo:RuoloDipendente;
    azienda:Impresa;

  }
  