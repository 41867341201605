
<div class="d-flex">
      <form [formGroup]="modificaStatoPraticaForm">
        <div class="grid formgrid p-fluid mt-2">
          
            <div class="field mt-2 mb-2 col-12">
              <label for="stato" class="font-medium text-900">Nuovo Stato</label>
              <p-dropdown inputId="stato" formControlName="stato" placeholder="Nuovo Stato" [options]="stati" optionLabel="denominazione"  [showClear]="true" >
                <ng-template let-stato pTemplate="item">
                  <div class="flex align-items-center">
                      <div>{{stato.denominazione}}</div>
                  </div>
              </ng-template>
              </p-dropdown>
            </div>
            
          </div>
      </form> 
    
</div>
<div class="flex gap-3 justify-content-end border-top-1 surface-border pt-5">
  <button pButton pRipple label="Chiudi" (click)="conferma(false)" class="p-button-text"></button>
  <button [disabled]="!modificaStatoPraticaForm.valid"  pButton pRipple label="Conferma" (click)="conferma(true)" class="p-button-success"></button>
</div>
