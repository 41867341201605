
<div class="d-flex position-relative">
  
    
    <form [formGroup]="completaPraticaForm">
      <div class="grid formgrid p-fluid mt-2">
        <div *ngIf="!isTipoC" class="field mt-2 mb-2 col-12">
          <label for="ordineEsecuzione" class="font-medium text-900">Ordine di Esecuzione</label>
          <input type="text" formControlName="ordineEsecuzione" pInputText >
        </div>
       <!--  <div class="field mt-2 mb-2 col-12">
          <label for="tipiVerbale" class="font-medium text-900">Tipologia di verbale da generare</label>
          <p-multiSelect inputId="tipiVerbale"  
              [options]="tipiVerbale"
              optionLabel="codice"
              placeholder="Verbali"
              formControlName="tipiVerbale" [filter]="false"
             >
              <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.codice }}</span>
                  </div>
              </ng-template>
          </p-multiSelect>
        </div> -->
        
        <div class="field mt-2 mb-2 col-12">
          <label for="fileComputoMetrico" class="font-medium text-900">Carica il <B>COMPUTO METRICO</B></label>
          <p-fileUpload name="fileComputoMetrico" 
              multiple="false"
              maxFileSize="15000000"
              chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
              [auto]="true"
              [customUpload]="true"
              (uploadHandler)="onComputoMetricoUpload($event)"
              [showUploadButton]="false"
              [showCancelButton]="false"
              (onRemove)="onComputoMetricoRemove()"
              [disabled]="isComputoMetricoUploaded || showSpinner"
              class="custom-red-button"
          >
          </p-fileUpload>
        </div>
      </div>
    </form>
  </div>
  <h1 *ngIf="!isTipoC && !isTipoD" class="m-0 mb-1 mt-1 text-center text-900 font-semibold text-xl line-height-3">La conferma scatenerà la generazione del VERBALE DI CESSIONE DEI DIRITTI SUBIRU ed il cambiamento di stato della pratica. <br>Il processo potrebbe richiedere diversi secondi.</h1>
  <h1 *ngIf="!isTipoC && isTipoD" class="m-0 mb-1 mt-1 text-center text-900 font-semibold text-xl line-height-3">La conferma scatenerà la generazione dei VEBALI DI CESSIONE DEI DIRITTI SUBIRU e di CESSIONE PROPRIETA' NUOVE INFRASTRUTTURE ed il cambiamento di stato della pratica. <br>Il processo potrebbe richiedere diversi secondi.</h1>
  <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

  <div>
    <div style="display: flex; justify-content: center; gap: 10px;">
    <p-button [disabled]="!completaPraticaForm.valid || showSpinner" icon="pi pi-check" label="Conferma" class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)"></p-button>
    <p-button [disabled]="showSpinner" icon="pi pi-times" label="Annulla" class="p-button-text" styleClass="p-button-warning" (click)="conferma(false)"></p-button> 
  </div>
</div>