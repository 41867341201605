export const environment = {
  name: "PROD",
  /* LOCAL  */
  apiUrl: "https://be-prod-jh6el3v2ta-ew.a.run.app/api/v1/",
  //apiUrl: 'https://be-prod-ilsea523oq-ew.a.run.app/api/private/',
  //apiUrl: "https://be-test-native-jh6el3v2ta-ew.a.run.app/api/v1/",
  //apiUrl: "https://be-test-jh6el3v2ta-ew.a.run.app/api/v1/",
  auth0: {
    clientId: "m8imYeELwGYRkBmnRjHVLMHYobMWdP0r",
    domain: "tim-documentale.eu.auth0.com",
    // audience: "https://tim-documentale.eu.auth0.com/api/v2/",
    audience: "https://tim-documentale-backend.map-hosting.it",
    redirectUri: window.location.origin,
  },
  /*  auth0Token: {
    url: "https://tim-documentale.eu.auth0.com/oauth/token",
    client_id: "m8imYeELwGYRkBmnRjHVLMHYobMWdP0r",
    client_secret: "sbcEQngnCUi24BT7opcbkxeA-_zGAqTeLxqafXzFJr2Gi1Co1-JIN1bZcMeZz_QP",
    audience: "https://tim-documentale.eu.auth0.com/api/v2/",
    grant_type: "client_credentials",
  }, */
  csvBox:{
    secret:'MCb0OnXiotviEw094rk6P0O1wzDtz0',
    secret_bando1:'Tsmz4kgwpRbQjw5bZnjBslSWrHzsB8',
    secret_centrali:'9M1sglLEHkuKxSh8OssckPUmS0VxgD',
    secret_aggiornaPratiche:'qloxvD8gTLvKYNTXvRDA7gY0xqJVBS',
    secret_aggiornaPraticheBando1:'QncN6nGFyPm4zUMdQp6uS27UveodXj',
    secret_importaPraticheSanita:'nAcpGBxCKILQR6mvj93hYNhRRUx9YV',
    secret_aggiornaPraticheSanita:'bmlsQBksLD32R89v0N81mLyx7nl9Oh',
    secret_importaPianiTecniciSanita:'DilS6rFba44flZN2indXZuVH2ptqkg',

    user:'default123'
  },
  firebase: {
    apiKey: "AIzaSyAqwiHO3d67BtWhgh4mBQqmp0ozFCjCE58",
    authDomain: "tim-documentale.firebaseapp.com",
    projectId: "tim-documentale",
    storageBucket: "tim-documentale.appspot.com",
    messagingSenderId: "472321957740",
    appId: "1:472321957740:web:b8f66d31c2798e912d1d49",
    measurementId: "G-G78QZVDHN2",
    collection_stats:"stats_azienda",
    collection_pratiche_scuola:"pratiche-scuola",
    collection_pratiche_sanita:"pratiche-sanita",
    collection_piani_tecnici_sanita:"piani-tecnici-sanita"
  },
  storageBucket: "tim-documentale.appspot.com",
};
