import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
  PrimeIcons,
} from "primeng/api";
import { environment } from "../../../../../environments/environment";
import { Menu } from "primeng/menu";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";
import {
  AngularFirestore,
} from "@angular/fire/compat/firestore";
import {
  onSnapshot,
  GeoPoint,
} from "firebase/firestore";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";

import { Observable, Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { Utente } from "src/app/models/utente.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { DettaglioPraticaScuolaDTO } from "src/app/models/dettaglioPratica.scuola.model";
import { ScuoleService } from "src/app/services/scuole.service";
import { ScuolaDTO } from "src/app/models/scuola.model";
import { Location } from "@angular/common";
import * as Leaflet from "leaflet";
import { StoricoPraticheService } from "src/app/services/storicoPratiche.service";
import { StoricoPraticaScuola } from "src/app/models/pratica.storico.scuola.model";
import { NotaReworkPratica } from "src/app/models/pratica.nota.scuola.model";
import { NotePraticheService } from "src/app/services/notePratiche.service";
import { NotaPraticaDTO } from "src/app/models/pratica.notaDTO.scuola.model";
import { CsvboxLoaderService } from "src/app/services/csvbox-loader.service";
import * as JSZip from "jszip";
import { ModificaPraticaDTO } from "src/app/models/modificaPratica.scuola.model";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Tipologia } from "src/app/models/tipologia.model";
import { DialogCreaPratica } from "./crea-pratica-bando2.component";
import { ComuniService } from "src/app/services/comuni.service";
import { ComuneDTO } from "src/app/models/comune.model";
import { DialogRichiediCambioCentrale } from "./cambio-centrale-bando2.component";
import { NgxFileDropEntry } from "ngx-file-drop";
import { DialogDisegnaScuola } from "./disegna-pratica.component";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";
import { DialogRichiediAggiornamentoCentrale } from "./aggiornamento-centrale-bando2.component";
import { Centrale } from "src/app/models/centrale.models";
import { DialogModificaCodiceInvioPratica } from "./cambio-codiceInvio-bando2.component";


Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

declare const CSVBoxImporter: any;
@Component({
  selector: "app-pratiche-scuola-bando2",
  templateUrl: "./pratiche-scuola-bando2.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class PraticheScuolaBando2Component implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  praticheAtFirestore: PraticaScuola_Firestore[] = [];
  selectedPraticaAtFirestore: PraticaScuola_Firestore;

  //PER MULTISELECT
  selectedPratiche!: PraticaScuola_Firestore[];
  disableSingleActionButtons: boolean = false;

  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public isPresidioSF: boolean = false;
  public isPresidioTIM: boolean = false;
  public isDirigenteFirmaVerbali: boolean = false;
  public isDirigenteFirmaPacchetti: boolean = false;
  protected hasPraticheDaFirmare:Boolean = false;

  public isImpresa: boolean = false;

  public numeroTotalePratiche: number;
  public imprese: MenuItem[] = [];

  mostraAnnullate: boolean = true;
  testoFiltroAnnullate:String='MOSTRA';

  //CSV BOX
  private csvbox_licenseKey:string=environment.csvBox.secret;
  private csvbox_licenseKey_aggiornaPratiche:string=environment.csvBox.secret_aggiornaPratiche;
  private csvbox_importer:any;
  private csvbox_importer_aggiornaPratiche:any;

  private mappaCodiciScuoleColocate: Map<string, string>;
  private mappaCodiciScuoleColocateCompletate: Map<string, string>=new Map();
  private mappaCodiciScuoleColocateInfoComplete: Map<string, string>=new Map();
  private listaScuoleColocateInfoComplete: String[]=[];
  private listaScuoleColocateComplete: String[]=[];
  private mappaCodiciScuolePrincipali: Map<string, string>;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  public statiPratica: MenuItem[] = [];
  public statoFiltroSelezionato: MenuItem;

  @ViewChild("menuAzioniAzienda", { static: true }) azioniAziendaMenu: Menu;
  protected menuAzioniAziendaItems!: MenuItem[];

  @ViewChild("menuAzioni", { static: true }) azioniMenu: Menu;
  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;
  protected menuAzioniItems!: MenuItem[];
  protected menuTieredAzioniItems!: MenuItem[];
  

  @ViewChild("menuScaricaFile", { static: true }) azioniScaricaDocMenu: Menu;
  protected menuScaricaFileItems!: MenuItem[];

  @ViewChild("menuCaricaFile", { static: true }) azioniCaricaFileMenu: Menu;
  protected menuCaricaFileItems!: MenuItem[];
  
  @ViewChild("menuRisultatoInvioInfratel", { static: true }) azioniRisultatoInvioInfratel: Menu;
  protected menuRisultatiInvioPraticaItems!: MenuItem[];

  @ViewChild("menuStati") menuStati!: Menu;
  @ViewChild("menuTipiSede") menuTipiSede!: Menu;
  @ViewChild("menuRegioni") menuRegioni!: Menu;

  @ViewChild("praticheScuolaDT", { static: true }) praticheScuolaDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  protected msgs: Message[] = [];
  protected ref: DynamicDialogRef;
  protected dialogConfig:any;

  protected filterIsSet:Boolean = false;
  protected filtroStato:String;
  protected filtroPratica:String;

  public regioniPratica: MenuItem[] = [
    {
      label: "ABRUZZO",
      command: (event) => {
        this.praticheScuolaDT.filter("ABRUZZO", "regione", "is");
      },
    },
    {
      label: "CALABRIA",
      command: (event) => {
        this.praticheScuolaDT.filter("CALABRIA", "regione", "is");
      },
    },
    {
      label: "CAMPANIA",
      command: (event) => {
        this.praticheScuolaDT.filter("CAMPANIA", "regione", "is");
      },
    },
    {
      label: "LAZIO",
      command: (event) => {
        this.praticheScuolaDT.filter("LAZIO", "regione", "is");
      },
    },
    {
      label: "LIGURIA",
      command: (event) => {
        this.praticheScuolaDT.filter("LIGURIA", "regione", "is");
      },
    },
    {
      label: "MARCHE",
      command: (event) => {
        this.praticheScuolaDT.filter("MARCHE", "regione", "is");
      },
    },
    {
      label: "MOLISE",
      command: (event) => {
        this.praticheScuolaDT.filter("MOLISE", "regione", "is");
      },
    },
    {
      label: "PIEMONTE",
      command: (event) => {
        this.praticheScuolaDT.filter("PIEMONTE", "regione", "is");
      },
    },
    {
      label: "PUGLIA",
      command: (event) => {
        this.praticheScuolaDT.filter("PIEMONTE", "regione", "is");
      },
    },
    {
      label: "SARDEGNA",
      command: (event) => {
        this.praticheScuolaDT.filter("SARDEGNA", "regione", "is");
      },
    },
    {
      label: "SICILIA",
      command: (event) => {
        this.praticheScuolaDT.filter("SICILIA", "regione", "is");
      },
    },
    {
      label: "TOSCANA",
      command: (event) => {
        this.praticheScuolaDT.filter("TOSCANA", "regione", "is");
      },
    },
    {
      label: "VALLE D'AOSTA",
      command: (event) => {
        this.praticheScuolaDT.filter("VALLE D'AOSTA", "regione", "is");
      },
    },
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    }
  ];

  public tipiSede: MenuItem[] = [
   
    {
      label: "A",
      command: (event) => {
        this.praticheScuolaDT.filter("A", "tipoSede", "is");
      },
    },
    {
      label: "B",
      command: (event) => {
        this.praticheScuolaDT.filter("B", "tipoSede", "is");
      },
    },
    {
      label: "B*",
      command: (event) => {
        this.praticheScuolaDT.filter("B*", "tipoSede", "is");
      },
    },
    {
      label: "C",
      command: (event) => {
        this.praticheScuolaDT.filter("C", "tipoSede", "is");
      },
    },
    {
      label: "D",
      command: (event) => {
        this.praticheScuolaDT.filter("D", "tipoSede", "is");
      },
    },
    {
      label: "D*",
      command: (event) => {
        this.praticheScuolaDT.filter("D*", "tipoSede", "is");
      },
    },
   /*  {
      label: "COL",
      command: (event) => {
        this.praticheScuolaDT.filter("COL", "tipoSede", "is");
      },
    }, */
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    },
  ];

  constructor(
    private logger: NGXLogger,
    private googleStorageService: GoogleStorageService,
    private confirmationService: ConfirmationService,
    private praticheScuolaService: PraticheScuolaService,
    private scuoleService: ScuoleService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService,
    private storicoPraticheService: StoricoPraticheService,
    private notePraticheService: NotePraticheService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private impreseService:ImpreseService,
    private csvboxLoaderService: CsvboxLoaderService,

  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
    this.mappaCodiciScuoleColocate = new Map<string, string>();
    this.mappaCodiciScuolePrincipali = new Map<string, string>();
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }

  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SUPERADMIN"
    ) {
      this.isSuperAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SMARTFIBER"
    ) {
      this.isPresidioSF = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "PRESIDIO"
    ) {
      this.isPresidioTIM = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "OPERATORE_AZIENDA"
    ) {
      this.isImpresa = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_VERBALI"
    ) {
      this.isDirigenteFirmaVerbali = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_PACCHETTI"
    ) {
      this.isDirigenteFirmaPacchetti = true;
    }
    const urlParamStato   = this.activatedRoute.snapshot.paramMap.get('stato');
    const urlParamPratica = this.activatedRoute.snapshot.paramMap.get('pratica');
    
    if(urlParamStato){
      this.filtroStato = urlParamStato;
      this.filterIsSet = true;
    }else if(urlParamPratica){
      this.filtroPratica = urlParamPratica;
      this.filterIsSet = true;
    }else {
      this.filterIsSet = false;
    }

    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF ) {
      this.loadImprese();
      if(this.filterIsSet){
        //this.logger.info("NG ON INIT CON FILTRO SETTATO");
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        //this.logger.info("NG ON INIT SENZA FILTRI");
        this.loadAllPraticheScuolaFromFirestore();
      }
    }else if(this.isPresidioTIM){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheAziendaScuolaFromFirestore();
    }

    this.loadMenuStatiPratica();

    this.loadCSVBox();
  }

  applyHideClosedFilter() {
    this.logger.info("--> applyHideClosedFilter "+ this.mostraAnnullate + " " + this.testoFiltroAnnullate);
    if (this.mostraAnnullate) {
      this.testoFiltroAnnullate='NASCONDI ';
      this.mostraAnnullate = false;
      this.praticheScuolaDT.clear();
      
    } else {
      this.testoFiltroAnnullate='MOSTRA ';
      this.mostraAnnullate = true;
      this.praticheScuolaDT.filter("ANNULLATA", "stato", "isNot");
    }
  }

  loadMenuStatiPratica(){
    if(this.isAdmin || this.isSuperAdmin || this.isPresidioSF || this.isPresidioTIM){
     /*  this.statiPratica.push(
        {
        label: "CARICATA",
        style: {  'font-size': '12px'},
        command: (event) => {
          this.filtraPerStato("CARICATA");
        },
      },
      {
        label: "ANNULLATA",
        style: {  'font-size': '12px'},
        command: (event) => {
          this.filtraPerStato("ANNULLATA");
        },
      }); */
    }
    this.statiPratica.push({
      label: "ASSEGNATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("ASSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO IMPRESA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN CARICO IMPRESA");
      },
    });
    this.statiPratica.push({
      label: "IN PROGETTAZIONE",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN PROGETTAZIONE");
      },
    });
    this.statiPratica.push({
      label: "CONSEGNATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("CONSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN REWORK AZIENDA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN REWORK AZIENDA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO PRESIDIO",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN CARICO PRESIDIO");
      },
    });
    this.statiPratica.push({
      label: "IN VERIFICA SMARTFIBER",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN VERIFICA SMARTFIBER");
      },
    });
    this.statiPratica.push({
      label: "IN REWORK SMARTFIBER",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN REWORK SMARTFIBER");
      },
    });
    this.statiPratica.push({
      label: "IN FIRMA VERBALE",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN FIRMA VERBALE");
      },
    });
    this.statiPratica.push({
      label: "VERBALE FIRMATO",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("VERBALE FIRMATO");
      },
    });
    this.statiPratica.push({
      label: "FIRMATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("FIRMATA");
      },
    });
    this.statiPratica.push({
      label: "PUBBLICATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("PUBBLICATA");
      },
    });
    this.statiPratica.push({
      label: "APPROVATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("APPROVATA");
      },
    });
    this.statiPratica.push({
      label: "RESPINTA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("RESPINTA");
      },
    });
    this.statiPratica.push({
      label: "TUTTE",
      style: {  'font-size': '12px', 'font-weight': 'bold'},
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    });

  }

  loadCSVBox(){
    this.csvboxLoaderService.loadScript().then(() => {
      this.csvbox_importer = new CSVBoxImporter(this.csvbox_licenseKey, {
        // Configuration options
      }, (result: boolean, data: any) => {
        if (result) {
          this.logger.info("Sheet uploaded successfully");
          this.logger.info(data.row_success + " rows uploaded");
          this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche importate con successo!', life:10000});
        } else {
          this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import delle pratiche', life:10000});
          // Custom code
        }
      });

    
      this.csvbox_importer.setUser({
        user_id: "default123"
      });
      this.csvbox_importer.language="it";

    });

    this.csvbox_importer_aggiornaPratiche = new CSVBoxImporter(this.csvbox_licenseKey_aggiornaPratiche, {
      // Configuration options
    }, (result: boolean, data: any) => {
      if (result) {
        this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche MODIFICATE con successo!', life:10000});
      } else {
        this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di MODIFICA delle pratiche', life:10000});
      }
    });

    this.csvbox_importer_aggiornaPratiche.setUser({
      user_id: "default123"
    });
    this.csvbox_importer_aggiornaPratiche.language="it";

 
  }

  loadImprese(){
    this.isLoadingResults = true;
    this.impreseService.getByBando("2").subscribe((res) => {
      this.isLoadingResults = false;

      res.forEach(impresa => {
        if(impresa.ragioneSociale!=='Admin Fake Company'){
          this.imprese.push({
            label: impresa.ragioneSociale,
            command: (event) => {
              this.praticheScuolaDT.filter(impresa.ragioneSociale, "azienda", "is");
            },
          });
        }
          
      });
      this.imprese.push({
        label: "TUTTE",
        command: (event) => {
          this.praticheScuolaDT.clear();
        },
      });
    }); 
  }
  filtraPerNome(event: any) {
    this.praticheScuolaDT.filterGlobal(event.target.value, 'contains');
  }

  filtraPerStato(stato: String) {
    this.praticheScuolaDT.filter(stato, "stato", "is");
  }

  getStatoClass(status: string): string {
    if (status === "CARICATA") {
      return "stato-pratica-caricata";
    }else if (status === "ASSEGNATA") {
      return "stato-pratica-assegnata";
    } else if (status === "IN PROGETTAZIONE") {
      return "stato-pratica-inprogettazione";
    } else if (status === "IN CARICO IMPRESA") {
      return "stato-pratica-incarico";
    } else if (status === "CONSEGNATA") {
      return "stato-pratica-consegnata";
    } else if (status === "IN REWORK AZIENDA") {
      return "stato-pratica-inrework";
    } else if (status === "IN REWORK SMARTFIBER") {
      return "stato-pratica-inrework-sf";
    }else if (status === "IN VERIFICA SMARTFIBER") {
      return "stato-pratica-inrework-presidio";
    }else if (status === "IN FIRMA") {
      return "stato-pratica-inFirma";
    }else if (status === "IN FIRMA VERBALE") {
      return "stato-pratica-inFirmaVerbale";
    }else if (status === "VERBALE FIRMATO") {
      return "stato-pratica-verbaleFirmato";
    }else if (status === "IN FIRMA PACCHETTO") {
      return "stato-pratica-inFirmaPacchetto";
    }else if (status === "FIRMATA") {
      return "stato-pratica-firmata";
    }else if (status === "IN CARICO PRESIDIO") {
      return "stato-pratica-incarico-tim";
    }else if (status === "APPROVATA") {
      return "stato-pratica-approvata";
    }else if (status === "RESPINTA") {
      return "stato-pratica-respinta";
    }else if (status === "ANNULLATA") {
      return "stato-pratica-annullata";
    }else if (status === "PUBBLICATA") {
      return "stato-pratica-pubblicata";
    }
    
    return "";
  }

 
  ngAfterViewInit() {}

  retrieveColocata(codicePrincipale:string):string
  {
    this.logger.info("> retrieveColocata su totale di  " + this.mappaCodiciScuoleColocate.size);
    this.mappaCodiciScuolePrincipali.forEach(colocata => {
      this.logger.info(colocata);

    });
    return "";  
  }

  
  getPrincipaleDiUnaColocata(pratica: PraticaScuola_Firestore):string{
    if(this.isDirigenteFirmaPacchetti){
     // this.logger.info("*** getPrincipaleDiUnaColocata PER DIRIGENTE >"+ pratica.colocataScuolaPrincipale);

      return pratica.colocataScuolaPrincipale.valueOf();
    }
   // this.logger.info("*** getPrincipaleDiUnaColocata PER ALTRI >"+ this.mappaCodiciScuoleColocate.get(pratica.codiceInfratel.valueOf()));
   return this.mappaCodiciScuoleColocate.get(pratica.codiceInfratel.valueOf());  
  }

  getCodiceInfratelDellaColocataDiUnaPrincipale(pratica: PraticaScuola_Firestore):string{
   // this.logger.info(this.mappaCodiciScuolePrincipali);
    return this.mappaCodiciScuolePrincipali.get(pratica.codiceInfratel.valueOf());  
   }

  getColocataDiUnaPrincipale(codiceInfratel: String):Observable<PraticaScuola_Firestore[]>{

    this.isLoadingResults = true;
    let praticaColocata:PraticaScuola_Firestore = null;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", codiceInfratel);
    const risultati: PraticaScuola_Firestore[] = [];
    query.get().then(qs=>{

      qs.forEach(doc =>{
        praticaColocata = doc.data() as PraticaScuola_Firestore;
        risultati.push(praticaColocata);
      });
      
      return risultati;
    });
    return new Observable<PraticaScuola_Firestore[]>;

    
  }

  getCodiceInfratelDiUnaPrincipale(codiceInfratel: String):String{
    this.logger.info("---> getCodiceInfratelDiUnaPrincipale "+codiceInfratel);

    this.isLoadingResults = true;
    let praticaColocata:PraticaScuola_Firestore = null;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", codiceInfratel);
    onSnapshot(query, (qs) => {
          this.isLoadingResults = false;
          if( qs.size==1){
            praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
            return praticaColocata.codiceInfratel;
          }else{
            return null;
          }
    });

    return null;
  }

  loadAllPraticheScuolaFromFirestore() {
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
    .where("bando", "==", "BANDO 2")
    //.where("stato", "!=", "ANNULLATA")
    .orderBy("stato");
    onSnapshot(query, (qs) => {
        this.praticheAtFirestore = [];
        this.isLoadingResults = false;
        //this.logger.info(  "--> RISULTATO LOAD PRATICHE (loadAllPraticheScuolaFromFirestore) > " + qs.docs.length );
        this.numeroTotalePratiche = qs.docs.length;
        qs.forEach((doc) => {
          let pratica = doc.data() as PraticaScuola_Firestore;
          this.praticheAtFirestore.push(pratica);
          if(pratica.colocata && pratica.colocataRuolo ==='SECONDARIA'){
            const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_scuola).doc(pratica.colocataScuolaPrincipale.valueOf());
            //this.logger.info("> LA COLOCATA "+pratica.codiceInfratel + " HA COME PRINCIPALE " +pratica.colocataScuolaPrincipale.valueOf());
            praticheScuolaRef.get().subscribe((doc) => {
              if (doc.exists) {
                const praticaPrincipale = doc.data() as PraticaScuola_Firestore;
                this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());
              } 
              
            }, (error) => {
              this.logger.info("Errore nel recupero del documento:", error);
            });
            
          }else if(pratica.colocata && pratica.colocataRuolo ==='PRINCIPALE'){
            
          }
          
          
        });
        this.praticheScuolaDT.filter("ANNULLATA", "stato", "isNot");
        if(this.filterIsSet){
          if(this.filtroStato){
            this.logger.info(  "--> al risultato applico FILTRO PER STATO > " +this.filtroStato );

            this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
          }else if(this.filtroPratica){
            this.logger.info(  "--> al risultato applico FILTRO PER CODICE PRATICA > " +this.filtroPratica );

            //let codiceInfratel = this.getCodiceInfratelDiUnaPrincipale(this.filtroPratica);
            //this.logger.info(codiceInfratel);
            //this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
            //this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "contains");

          }
          
        }
    });
  }

  openAggiornaPratiche(){
    this.logger.info("*** AGGIORNA PRATICHE BANDO 2 *****");
    this.csvbox_importer_aggiornaPratiche.openModal();
  }

  loadAllPraticheScuolaFromFirestoreWithFilter() {
    this.isLoadingResults = true;
    if(this.filtroStato){
      this.logger.info("--> LOAD PRATICHE CON FILTRO SU STATO > " + this.filtroStato);

      let filtroStato:String[] = [];
      if(this.filtroStato==="IN CARICO SF"){
        filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER"];
      }else if(this.filtroStato==="IN LAVORAZIONE"){
        filtroStato = ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "IN REWORK AZIENDA"];
      }else if(this.filtroStato==="IN CARICO PRESIDIO"){
        //filtroStato = ["IN CARICO PRESIDIO","IN FIRMA","IN FIRMA VERBALE","IN FIRMA PACCHETTO", "VERBALE FIRMATO", "FIRMATA"];
        filtroStato = ["IN CARICO PRESIDIO", "FIRMATA","PUBBLICATA"];
        //, "PUBBLICATA"
        //filtroStato = ["IN CARICO PRESIDIO", "FIRMATA"/* , "PUBBLICATA" */];
      }else if(this.filtroStato==="IN FIRMA VERBALE"){
        filtroStato = ["IN FIRMA VERBALE"];
      }else if(this.filtroStato==="IN FIRMA PACCHETTO"){
        filtroStato = ["IN FIRMA PACCHETTO","VERBALE FIRMATO"];
      }else if(this.filtroStato==="VERBALE FIRMATO"){
        filtroStato = ["VERBALE FIRMATO"];
      }else if(this.filtroStato==="DA ASSEGNARE"){
        filtroStato = ["CARICATA"];
      }else if(this.filtroStato==="RESPINTA"){
        filtroStato = ["RESPINTA"];
      }else if(this.filtroStato==="FIRMATA"){
        filtroStato = ["FIRMATA"];
      }else if(this.filtroStato==="PACCHETTO FIRMATO"){
        filtroStato = ["PACCHETTO FIRMATO"];
      }else if(this.filtroStato==="APPROVATA"){
        filtroStato = ["APPROVATA"];
      }else if(this.filtroStato==="IN FIRMA"){
        filtroStato = ["IN FIRMA VERBALE","VERBALE FIRMATO","IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="PUBBLICATA"){
        filtroStato = ["PUBBLICATA"];
      }

      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      const query = collectionRef
      .where("bando", "==", "BANDO 2")
      .where("stato", "in" , filtroStato)
      .orderBy("stato");
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.numeroTotalePratiche = qs.docs.length;
          //this.logger.info("--> LOAD PRATICHE CON FILTRO SU STATO > " + this.filtroStato + " ha dato risultato " + qs.size);

          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaScuola_Firestore;
            this.praticheAtFirestore.push(pratica);
           // this.logger.info(  "--> RISULTATO LOAD PRATICHE " + qs.docs.length );
            if(pratica.colocata && pratica.colocataRuolo ==='SECONDARIA'){
              
              const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_scuola).doc(pratica.colocataScuolaPrincipale.valueOf());
              praticheScuolaRef.get().subscribe((doc) => {
                if (doc.exists) {
                  const praticaPrincipale = doc.data() as PraticaScuola_Firestore;
                  
                  this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());

                  if(praticaPrincipale.codiceInvio!==pratica.codiceInvio){
                    pratica.colocataConCodiceInvioDifferente = true;
                  }else{
                    pratica.colocataConCodiceInvioDifferente = false;
    
                  }
                  this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());
                  /*  this.logger.info( "--> COLOCATA " + pratica.codiceMiur + " infoCompleted? "+ pratica.infoCompleted + " nt9Uploaded?" +pratica.nt9Uploaded + " fotoRouterUploaded? "
                  + pratica.fotoRouterUploaded ); */  
                  if(pratica.infoCompleted){
                    this.listaScuoleColocateInfoComplete.push(praticaPrincipale.codiceInfratel.valueOf());
                  }
                  if(pratica.nt9Uploaded && pratica.fotoRouterUploaded){

                    this.listaScuoleColocateComplete.push(praticaPrincipale.codiceInfratel.valueOf());
                  }

                } 
                
              }, (error) => {
                this.logger.info("Errore nel recupero del documento:", error);
              });
              
            }else if(pratica.colocata && pratica.colocataRuolo ==='PRINCIPALE'){
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef
              .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel.valueOf())
             ;
              onSnapshot(query, (qs) => {
                qs.forEach((doc) => {
                  let praticaColocata = doc.data() as PraticaScuola_Firestore;
                   if (doc.exists) {
                    const praticaColocata = doc.data() as PraticaScuola_Firestore;
                    //this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaColocata.codiceMiur.valueOf());

                    if(praticaColocata.codiceInvio!==pratica.codiceInvio){
                     // this.logger.info("PRATICA PRINCIPALE "+pratica.codiceInfratel +" MA CON CODICE INVIO ("+pratica.codiceInvio+") DIVERSO DALLA PRINCIPALE "+praticaColocata.codiceInvio);
                      pratica.colocataConCodiceInvioDifferente = true;
                    }else{
                     // this.logger.info("PRATICA PRINCIPALE "+pratica.codiceInfratel +" CON CODICE INVIO ("+pratica.codiceInvio+") UGUALE ALLA PRINCIPALE "+praticaColocata.codiceInvio);
                      pratica.colocataConCodiceInvioDifferente = false;
      
                    }

                } 
            
              });

              
                
              }, (error) => {
                this.logger.info("Errore nel recupero del documento:", error);
              });
              
            }else{
              pratica.colocataConCodiceInvioDifferente = false;
            }
            
            
          });
        
      });
    }else if(this.filtroPratica){
     // this.logger.info("--> LOAD PRATICHE PER ADMIN CON FILTRO SU pratica > " + this.filtroPratica);
      /* this.getColocataDiUnaPrincipale(this.filtroPratica).subscribe(res => {
        this.logger.info("---> RISULTATI RICERCA DELLE COLOCATE ");
        this.logger.info(res);
      });
 */
      
     // let codiceColocata = this.getCodiceInfratelDiUnaPrincipale(this.filtroPratica);
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      //const query = collectionRef .where("codiceInfratel", "in",[this.filtroPratica,codiceColocata?codiceColocata:'' ]) ;
      const query = collectionRef .where("codiceInfratel", "==",this.filtroPratica) ;
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaScuola_Firestore;
            this.praticheAtFirestore.push(pratica);
            if(pratica.colocata && pratica.colocataRuolo ==='PRINCIPALE'){
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              const risultati: PraticaScuola_Firestore[] = [];
              query.get().then(qs=>{

                qs.forEach(doc =>{
                  this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
                 
                });
                
                
              });
              
            }
            
          });
      });
    }
    
  }

  openCreaPraticaDialog(){
      this.ref = this.dialogService.open(DialogCreaPratica, {
        data: {
        },
        header: "Creazione Nuova Pratica ",
        width: this.isMobile ? "95%" : "85%",
        height: "85%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((infoUpdated) => {
        if (infoUpdated && infoUpdated.success) {
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Praticha creata con successo"
          });
        }
      });
  }
  esportaPratiche(){
    //this.logger.info(this.praticheScuolaDT);
    if(this.praticheScuolaDT.filteredValue){
      this.logger.info("---> ESPORTA PRATICHE FILTRATE: " +this.praticheScuolaDT.filteredValue.length + " Vs "+this.praticheAtFirestore.length);

      this.excelService.exportPraticheBando2ToExcel(this.praticheScuolaDT.filteredValue, 'pratiche_BANDO2.xlsx');
    }else{
      this.excelService.exportPraticheBando2ToExcel(this.praticheAtFirestore, 'pratiche_BANDO2.xlsx');
    }
    
  }


  
  checkIfPraticheDaFirmare(){
    this.logger.info(  "--> checkIfPraticheDaFirmare"  );
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where("stato", "in", ["VERBALE FIRMATO"])
      .where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
          if(qs.docs.length >0 ){
            this.hasPraticheDaFirmare = true;
          }
          this.hasPraticheDaFirmare = false;
    });
    
  }
  loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA PACCHETTI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where("stato", "in", ["VERBALE FIRMATO","FIRMATA"])
      .where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        let pratica : PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
        if(pratica.colocata && pratica.colocataRuolo !=='PRINCIPALE'){
          const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_scuola).doc(pratica.colocataScuolaPrincipale.valueOf());
          praticheScuolaRef.get().subscribe((doc) => {
            if (doc.exists) {
              const praticaPrincipale = doc.data() as PraticaScuola_Firestore;
              if(praticaPrincipale.codiceInvio!==pratica.codiceInvio){
                //this.logger.info("PRATICA COLOCATA "+pratica.codiceInfratel +" MA CON CODICE INVIO ("+pratica.codiceInvio+") DIVERSO DALLA PRINCIPALE "+praticaPrincipale.codiceInvio);
                pratica.colocataConCodiceInvioDifferente = true;
              }else{
                //this.logger.info("PRATICA COLOCATA "+pratica.codiceInfratel +" CON CODICE INVIO ("+pratica.codiceInvio+") UGUALE ALLA PRINCIPALE "+praticaPrincipale.codiceInvio);
                pratica.colocataConCodiceInvioDifferente = false;

              }
            }
          });
        
        }else{
          pratica.colocataConCodiceInvioDifferente = false;
        }
        //this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
        this.praticheAtFirestore.push(pratica);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
    });
   // this.checkIfPraticheDaFirmare();
  }
  loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA VERBALI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where("stato", "in", ["IN FIRMA VERBALE","VERBALE FIRMATO"])
      .where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
    });
  }

  loadPraticheScuolaPresidioTIMFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER PRESIDIO TIM "  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      //.where("stato", "in", ["IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
      .where("stato", "in", ["IN CARICO PRESIDIO", "IN FIRMA VERBALE","VERBALE FIRMATO","IN FIRMA PACCHETTO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
      .where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
      
    });
  }

  loadPraticheAziendaScuolaFromFirestore() {
    this.logger.info("*** loadPraticheAziendaScuolaFromFirestore ***");
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where(
        "idAzienda",
        "==",
        Number.parseInt(localStorage.getItem("idAzienda"))
      )
     // .where("stato", "in", ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "CONSEGNATA","IN REWORK AZIENDA","IN VERIFICA SMARTFIBER", "IN CARICO PRESIDIO","IN FIRMA VERBALE","IN FIRMA PACCHETTO","VERBALE FIRMATO", "FIRMATA", "PACCHETTO FIRMATO" ,"PUBBLICATA", "APPROVATA", "RESPINTA"])
     .where("stato", "in", ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "CONSEGNATA","IN REWORK AZIENDA","IN REWORK SMARTFIBER", "IN VERIFICA SMARTFIBER", "IN CARICO PRESIDIO","IN FIRMA VERBALE", "IN FIRMA PACCHETTO", "VERBALE FIRMATO", "PACCHETTO FIRMATO" ,"PUBBLICATA", "APPROVATA", "RESPINTA"])

     .where("bando", "==", "BANDO 2")
      .where("lavorabile", "==", true) 
      .orderBy("comune")
      .orderBy("codiceInfratel")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;

      qs.forEach((doc) => {
        let pratica: PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
        this.praticheAtFirestore.push(pratica);
        if(pratica.colocata && pratica.colocataRuolo ==='SECONDARIA'){
          const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_scuola).doc(pratica.colocataScuolaPrincipale.valueOf());
          praticheScuolaRef.get().subscribe((doc) => {
            if (doc.exists) {
              const praticaPrincipale = doc.data() as PraticaScuola_Firestore;
              
              this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());
              /*  this.logger.info( "--> COLOCATA " + pratica.codiceMiur + " infoCompleted? "+ pratica.infoCompleted + " nt9Uploaded?" +pratica.nt9Uploaded + " fotoRouterUploaded? "
              + pratica.fotoRouterUploaded ); */  
              if(pratica.infoCompleted){
                this.logger.info( "--> COLOCATA " + pratica.codiceInfratel + " della  " + praticaPrincipale.codiceInfratel + " AGGIUNTA A QUELLE CON INFO COMPLETE! ");  
                this.listaScuoleColocateInfoComplete.push(praticaPrincipale.codiceInfratel.valueOf());
              }
              if(pratica.nt9Uploaded && pratica.fotoRouterUploaded){
                this.logger.info( "--> COLOCATA " + pratica.codiceInfratel + " della  " + praticaPrincipale.codiceInfratel + " AGGIUNTA A QUELLE CON TUTTO CARICATO! ");  

                this.listaScuoleColocateComplete.push(praticaPrincipale.codiceInfratel.valueOf());
              }
            } 
            
          }, (error) => {
            this.logger.info("Errore nel recupero del documento:", error);
          });
         
        } 
        
      });
      
    /*   if(this.filtroStato){
        this.logger.info("--> LOAD PRATICHE PER AZIENDA CON FILTRO SU STATO > " + this.filtroStato);
      } */
      if(this.filterIsSet){
        if(this.filtroStato){
          if(this.filtroStato==="CONSEGNATA"){
            let filtroStato:String[] = [];
            //filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER", "IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","VERBALE FIRMATO","FIRMATA","PUBBLICATA"];
            filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER", "IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","VERBALE FIRMATO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"];
            
            this.logger.info(filtroStato);
            this.praticheScuolaDT.filter(filtroStato, "stato", "in");
          }else{
            this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
          }
          
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
       
     }
    
      
    });

   
  }

  reloadDataRemovingFilter(){
    this.location.replaceState('/pratiche-scuola-bando2');
    this.filterIsSet = false;
    this.filtroPratica = null;
    this.filtroStato = null;
    this.praticheScuolaDT.clear();
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF) {
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadAllPraticheScuolaFromFirestore();
      }
    
    }else if(this.isPresidioTIM){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
     
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheAziendaScuolaFromFirestore();
    }
  }

  calcolaTotali() {}

  openGis2Web(pratica: PraticaScuola_Firestore){
    this.logger.info("APRO GIS 2 WEB DELLA PRATICA "+pratica.codiceMiur);
   // window.open("https://nt9convergo-viewer.maphosting.it/viewer/index.php/TimScuole/tim_"+pratica.codiceMiur.toLowerCase()+"#/map/ZOOMLAYER=Scuole&ZOOMFILTER=gid$$$EQ$$$1", '_blank');
    window.open("https://nt9convergo-cloud.maphosting.it/", '_blank');
    
  }

  openNoteReworkPratica(pratica: PraticaScuola_Firestore) {
    this.isLoadingResults = true;
    
    this.notePraticheService.getById(pratica.id.toString()).subscribe(note => {
      this.isLoadingResults = false;
      this.logger.info(note);
      if(note && note.length > 0){
        this.ref = this.dialogService.open(DialogNoteRework, {
          data: {
            pratica: pratica,
            note: note
          },
          header: "Note della pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "90%",
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
      }else{
        this.messageService.add({
          severity: "info",
          summary: "Attenzione!",
          detail: "La pratica non presenta NOTE",
        });
      }
    })
  }
  openMandaInRework(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogInserisciNoteRework, {
      data: {
        pratica: pratica,
      },
      header: "Rework della pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((noteResult) => {
      if(noteResult && noteResult.success){
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica inviata all'impresa per un rework",
        });
      }
    });
  }

  openPresidioSFRiprendeInCarico(pratica: PraticaScuola_Firestore) {
   
  }
  openMandaIndietroAlPresidioSF(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogInserisciNoteRework, {
      data: {
        pratica: pratica,
        isReworkPresidio:true,
      },
      header: "Rimanda al presidio SmartFiber la pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((noteResult) => {
      if(noteResult && noteResult.success){
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica inviata al presidio SmartFiber per essere verificata",
        });
      }
    });
  }
  

  openMandaAlPresidioTIM(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> openMandaAlPresidioTIM --> " + pratica.id);
    let messaggio = `Confermi di voler procedere con l'invio della pratica <b>${pratica.codiceInfratel}</b> al PRESIDIO TIM?`;
    if(pratica.colocata && pratica.colocataRuolo ==='PRINCIPALE'){
      messaggio +=`<br>Trattandosi di pratica <b>COLOCATA</b>, verranno inviate anche tutte le pratiche associate!`; 
    }
    this.confirmationService.confirm({
      message: messaggio,
      header: "Invia al PRESIDIO TIM",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaInviareAlPresidio: PraticaScuola = new PraticaScuola();
        praticaDaInviareAlPresidio.id = pratica.id;
        this.logger.info(praticaDaInviareAlPresidio);
        this.praticheScuolaService.inviaAlPresidio(praticaDaInviareAlPresidio).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di invio al presidio TIM della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica inviata con successo al presidio TIM",
            });
          },
        }); 
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openStoricoPratica(pratica: PraticaScuola_Firestore) {
    this.isLoadingResults = true;
    this.storicoPraticheService.getById(pratica.id.toString()).subscribe(storicoPratica=>{
      this.isLoadingResults = false;
        if(storicoPratica && storicoPratica.length > 0){
          this.ref = this.dialogService.open(DialogStoricoPratica, {
            data: {
              pratica: pratica,
              storicoPratica: storicoPratica
            },
            header: "Storico della pratica "+pratica.codiceInfratel,
            width: this.isMobile ? "95%" : "90%",
            height: "35%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
        }else{
          this.messageService.add({
            severity: "info",
            summary: "Attenzione!",
            detail: "La pratica non presenta STORICO",
          });
        }
    });
  }

  

  onPraticaSelection(pratica: PraticaScuola_Firestore) {
    if (this.selectedPratiche && this.selectedPratiche.length > 1) {
      this.disableSingleActionButtons = true;
    } else {
      this.disableSingleActionButtons = false;
    }
  }
  isDaAssegnare(pratica: PraticaScuola_Firestore): boolean {
   
    if (pratica.stato === "CARICATA") {
      return true;
    }
    return false;
  }
  iAssegnata(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA") {
      return true;
    }
    return false;
  }
  isInCaricoPresidioTIM(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO PRESIDIO") {
      return true;
    }
    return false;
  }
  isDaPrendereInCarico(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA" ) {
      return true;
    }
    return false;
  }

  isDaProgettare(pratica:PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }


  isInCarico(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }

  isInProgettazione(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN PROGETTAZIONE") {
      return true;
    }
    return false;
  }

  isDeliverable(pratica: PraticaScuola_Firestore): boolean {

    if ( pratica.stato === "IN PROGETTAZIONE" &&  pratica.fotoRouterUploaded &&  pratica.nt9Uploaded && pratica.infoCompleted )  {
      if(pratica.tipoSedeEffettiva==='D'){
        this.logger.info("check isDeliverable su PRATICA  D >>> " + pratica.codiceInfratel );

        if(!pratica.colocata){
          if(pratica.planimetriaUploaded){
            return true;
          }else{
            return false;
          }
        }else{
          if(pratica.colocataRuolo === 'PRINCIPALE'){
            if(this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) > -1){
              return true;
             }
       
          }
        }
      
      }else{

        if(!pratica.colocata){
          this.logger.info("check isDeliverable su PRATICA NON D E SINGOLA >>> " + pratica.codiceInfratel );

          return true;
        }else{
          if(pratica.colocataRuolo === 'PRINCIPALE'){
            this.logger.info("check isDeliverable su PRATICA NON D COLOCATA PRINCIPALE >>> " + pratica.codiceInfratel + ": NELLA LISTA COMPLETE? " + this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) + " E NELLA LISTA INFO COMPLETE? " +this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel) );

            //if(this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) > -1){
              //this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) > -1 && 
              if(this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel) != -1){
                this.logger.info("ENTRO DOVE NON DOVREI");
                return true;
              }
       
          }
        }
      }
      
    }

    return false;
  }

  areDocsCreatable(pratica: PraticaScuola_Firestore): boolean {
    if (
      (pratica.stato === "IN PROGETTAZIONE" || pratica.stato === "IN REWORK AZIENDA") && 
      pratica.fotoRouterUploaded &&
      pratica.infoCompleted
    ) {
      if(!pratica.colocata){
        return true;
      }else{
        if(pratica.colocataRuolo === 'PRINCIPALE'){
          if(this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) > -1 && this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel) > -1){
            return true;
           }
     
        }
      }
     
    }
    return false;
  }

  

  isDelivered(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "CONSEGNATA") {
      return true;
    }
    return false;
  }
  isInRework(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN REWORK AZIENDA") {
      return true;
    }
    return false;
  }
  isInReworkSF(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN REWORK SMARTFIBER") {
      return true;
    }
    return false;
  }

  

  isSignable(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO PRESIDIO" && pratica.ordineEsecuzione && pratica.computoMetricoUploaded ) {
      return true;
    }
    return false;
  }

  isSigned(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "FIRMATA" ) {
      return true;
    }
    return false;
  }

  isPublished(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "PUBBLICATA" ) {
      return true;
    }
    return false;
  }

  isApproved(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "APPROVATA") {
      return true;
    }
    return false;
  }
  isRejected(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "RESPINTA") {
      return true;
    }
    return false;
  }
  isInVerbalSignState(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN FIRMA VERBALE") {
      return true;
    }
    return false;
  }
  isInSigningPackageState(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO") {
      return true;
    }
    return false;
  }

  isColocataWithDifferentCodiceInvio(pratica: PraticaScuola_Firestore): boolean {
    if(pratica.colocata && pratica.colocataRuolo!=='PRINCIPALE'){
      return true;
    }
    
   
    return false;
  }
  
  hasSignedVerbal(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO") {
      return true;
    }
    return false;
  }
  hasSignedPackage(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "FIRMATA") {
      return true;
    }
    return false;
  }

  
  hasRejectionFile(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.noteRespingimentoFile &&  pratica.noteRespingimentoFile.length > 0) {
      return true;
    }
    return false;
  }
  hasReworkFile(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.noteReworkFile &&  pratica.noteReworkFile.length > 0) {
      return true;
    }
    return false;
  }
  
  isInCaricoPresidioSF(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN VERIFICA SMARTFIBER") {
      return true;
    }
    return false;
  }

  isVoidable(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA" ||  pratica.stato === "CARICATA" || pratica.stato === "IN CARICO IMPRESA" || pratica.stato === "IN VERIFICA SMARTFIBER") {
      return true;
    }
    return false;
  }

 

  
 

  hasCoordinates(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.coordinateScuola) {
      return true;
    }

    return false;
  }

  caricaPraticheScuola() {
    this.csvbox_importer.openModal();
  }

  loadAzioniScaricaFile() {
    this.menuScaricaFileItems = [
      {
        label: "RET",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("RET"),
      },
      {
        label: "PSI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("PSI"),
      },
      {
        label: "ALL.SUBIRU",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("IRU"),
      },
      {
        label: "TUTTI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("ALL"),
      },
    ];
  }
  loadAzioniRisultatoInvioPratica(pratica:PraticaScuola_Firestore){
    this.menuRisultatiInvioPraticaItems = [
      {
        label: "APPROVATA",
        icon: PrimeIcons.CHECK,
        styleClass: "valid-menu-item",
        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
      },
      {
        label: "RESPINTA",
        icon: PrimeIcons.TIMES,
        styleClass: "reject-menu-item",

        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
      },
    ];
  }


  openRisultatoAzioniConsegnaPratica(pratica:PraticaScuola_Firestore, isApprovata:boolean){
    if(isApprovata){
      this.isLoadingResults = true;
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.praticheScuolaService.comunicaApprovazionePratica(pratica).subscribe(res=>{
          this.isLoadingResults = false;
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Pratica aggiornata con successo",
          });
        });
      });
      
    }else {
      this.isLoadingResults = true;
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.ref = this.dialogService.open(DialogInserisciNoteRespingimentoPratica, {
          data: {
            pratica: pratica,
          },
          header: "Respingimento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica aggiornata con successo",
            });
          }
        });
        })
      
    }

  }
  

  loadAzioni(event:any, pratica:PraticaScuola_Firestore) {
    this.menuAzioniItems = [];
    //TUTTI TRANE I DIRIGENTI ===================================================================================================================
    if(!this.isDirigenteFirmaVerbali && !this.isDirigenteFirmaPacchetti){
      this.menuAzioniItems.push(
        {
          label: "Storico",
          icon: PrimeIcons.HISTORY,
          style: {'font-size': '13px'},
          command: () => this.openStoricoPratica(pratica),
        },
       {
          label: "Posizione",
          icon: PrimeIcons.MAP_MARKER,
          style: {'font-size': '13px'},
          command: () => this.openMappaConScuola(pratica),
        }  );
    }
    
    //IMPRESA ===================================================================================================================
    if(this.isImpresa ){ 
      if(this.isDaPrendereInCarico(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {  'font-weight': 'bold'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
      if(this.isDaProgettare(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Progetta",
            icon: PrimeIcons.FILE_EDIT,

            command: () => this.openIniziaProgettazione(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,

            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );

        if(pratica.utente !=  localStorage.getItem("idUtente")){
          this.menuAzioniItems.push(
            {
              label: "Cambio Assegnaz.",
              icon: PrimeIcons.USER_EDIT,
              command: () => this.openRichiediCambioAssegnatario(pratica),
            },
          );
        }
       
      }
      if(this.isInProgettazione(pratica) || this.isInRework(pratica)  || this.isInReworkSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: pratica.infoCompleted? "Modifica Info": "Inserisci Info",
            icon: PrimeIcons.SERVER,
            style: {  'font-weight': 'bold'},
            command: () => this.openInserisciDettagli(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,

            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Apri Gis2Web",
            icon: PrimeIcons.MAP_MARKER,
            command: () => this.openGis2Web(pratica),
          },
        );
  
        
        if(pratica.tipoSede === 'D' ){
          this.menuAzioniItems.push(
            {
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: {  'font-weight': 'bold'},
              items: [
                {
                  label: "Foto Router",
                  icon: PrimeIcons.CAMERA,
                  command: () => this.openCaricaFotoRouter(pratica),
                } ,
            
                {
                  label: "Pacchetto NT9",
                  icon: PrimeIcons.BUILDING,
                  command: () => this.openCaricaPacchettoNT9(pratica),
                }, 
                 {
                  label: "Foto Planimetria",
                  icon: PrimeIcons.IMAGE,
                  command: () => this.openCaricaPlanimetria(pratica),
                }, 
                
              ],
            }
          );
        }else{
          this.menuAzioniItems.push(
            {
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: {  'font-weight': 'bold'},
              items: [
                {
                  label: "Foto Router",
                  icon: PrimeIcons.CAMERA,
                  command: () => this.openCaricaFotoRouter(pratica),
                } ,
            
                {
                  label: "Pacchetto NT9",
                  icon: PrimeIcons.BUILDING,
                  command: () => this.openCaricaPacchettoNT9(pratica),
                }
              ],
            }
          );
        }
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.areDocsCreatable(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Genera Docs",
            icon: PrimeIcons.EYE,
            command: () => this.openPreviewDocs(pratica),
          },
        );
      }

      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && (this.isDeliverable(pratica) || this.isInRework(pratica))){
        this.menuAzioniItems.push(
          {
            label: "Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {  'font-weight': 'bold'},
            command: () => this.openConsegnaPratica(pratica),
          },
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && ((this.isDelivered(pratica) && this.isImpresa) ||  this.isInCaricoPresidioSF(pratica)) ){
        this.menuAzioniItems.push(
          {
            label: "Annulla Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            command: () => this.openAnnullaConsegnaPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica) && this.hasReworkFile(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica all. rework",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }

      if(this.isDelivered(pratica) || this.isInCaricoPresidioSF(pratica)){
        const items = [
          {
              label: "NT9",
              icon: PrimeIcons.MAP,
              styleClass: "RETstyle",
              command: () => this.scaricaNT9(),
          },
          {
              label: "RET",
              icon: PrimeIcons.FILE_WORD,
              styleClass: "RETstyle",
              command: () => this.scaricaDocs("RET"),
          },
          {
              label: "PSI",
              icon: PrimeIcons.FILE_WORD,
              styleClass: "RETstyle",
              command: () => this.scaricaDocs("PSI"),
          },
          {
              label: "TUTTI",
              icon: PrimeIcons.REPLAY,
              styleClass: "RETstyle",
              command: () => this.scaricaDocs("ALL"),
          }
      ];
       
        if (pratica.tipoSede !== 'C' && pratica.tipoSedeEffettiva !== 'C') {
          items.splice(3, 0, {
              label: "ALL.SUBIRU",
              icon: PrimeIcons.FILE_WORD,
              styleClass: "RETstyle",
              command: () => this.scaricaDocs("IRU"),
          });
        }
        this.menuAzioniItems.push({
          label: "Scarica File",
          icon: PrimeIcons.CLOUD_DOWNLOAD,
          style: { 'font-size': '12.7px', 'font-weight': 'bold' },
          items: items
      });
        
      }
      
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.isApproved(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica pacchetto",
            icon: PrimeIcons.CLOUD_DOWNLOAD,
            style: {  'font-size':'12.5px', 'font-weight': 'bold'},
            command: () => this.scaricaPacchettoPraticaApprovata(pratica),
          },
        );
      }
      
      //PRESIDIO SF ===================================================================================================================
    }else if(this.isPresidioSF){
      if(this.isDaPrendereInCarico(pratica)){ //DA PRENDERE IN CARICO
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {   'font-size': '12.7px', 'font-weight': 'bold'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
      if(this.isDaProgettare(pratica)){ //DA PROGETTARE
        this.menuAzioniItems.push(
          {
            label: "Annulla Assegnazione",
            icon: PrimeIcons.USER_MINUS,
            style: {  'font-size': '12.7px', 'font-weight': 'bold'},
            command: () => this.annullaAssegnazione(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Progetta",
            icon: PrimeIcons.FILE_EDIT,
            style: {  'font-size': '12.7px'},
            command: () => this.openIniziaProgettazione(pratica),
          },
          
        )
        if(pratica.tipoSede === 'D' ){
            this.menuAzioniItems.push(
            {
              label: "Scarica Planimetria",
              icon: PrimeIcons.IMAGE,
              style: {  'font-size': '12.7px', 'font-weight': 'bold'},
              command: () => this.scaricaPlanimetria(pratica),
            },
        );
        }
        
      }
      if(this.isInProgettazione(pratica) || this.isInRework(pratica) ){ //IN PROGETTAZIONE O IN REWORK
        this.menuAzioniItems.push(
          {
            label: "Inserisci Info",
            icon: PrimeIcons.SERVER,
            style: {  'font-size': '12.7px'},
            command: () => this.openInserisciDettagli(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Apri Gis2Web",
            icon: PrimeIcons.MAP_MARKER,
            style: {  'font-size': '12.7px'},
            command: () => this.openGis2Web(pratica),
          },
        );
         
        if(pratica.tipoSedeEffettiva === 'D' ){
          this.menuAzioniItems.push(
            {
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: {  'font-size': '12.7px'},
              items: [
                {
                  label: "Foto Router",
                  icon: PrimeIcons.CAMERA,
                  command: () => this.openCaricaFotoRouter(pratica),
                } ,
            
                {
                  label: "Pacchetto NT9",
                  icon: PrimeIcons.BUILDING,
                  command: () => this.openCaricaPacchettoNT9(pratica),
                }, 
                {
                  label: "Planimetria",
                  icon: PrimeIcons.IMAGE,
                  command: () => this.openCaricaPlanimetria(pratica),
                }
                
              ],
            }
          );
        }else{
          this.menuAzioniItems.push(
            {
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: {  'font-size': '12.7px'},
              items: [
                {
                  label: "Foto Router",
                  icon: PrimeIcons.CAMERA,
                  command: () => this.openCaricaFotoRouter(pratica),
                } ,
            
                {
                  label: "Pacchetto NT9",
                  icon: PrimeIcons.BUILDING,
                  command: () => this.openCaricaPacchettoNT9(pratica),
                }
               
                
              ],
            }
          );
        }
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && this.areDocsCreatable(pratica)){ //IN DOC CREABILI
        this.menuAzioniItems.push(
          {
            label: "Genera Docs",
            icon: PrimeIcons.EYE,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openPreviewDocs(pratica),
          },
        );
      }

      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && (this.isDeliverable(pratica) || this.isInRework(pratica) )){//CONSEGNABILE O IN REWORK
        this.menuAzioniItems.push(
          {
            label: "Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openConsegnaPratica(pratica),
          },
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && (this.isDelivered(pratica) ||  this.isInCaricoPresidioSF(pratica) ||  this.isInCaricoPresidioTIM(pratica) ) ){ //CONSEGNATA O IN CARICO PRESIDIO SF O IN CARICO PRESIDIO TM
              //18 aprile 2024: PRESIDIO SF PUO' RIGENERARE TUTTA LA DOCS DI UNA PRATICA
        
        this.menuAzioniItems.push(
          
          {
            label: "Rigenera Docs",
            icon: PrimeIcons.REPLAY,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.rigeneraDocs(pratica),
          },
         
         
          
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && this.isInCaricoPresidioSF(pratica) || this.isDelivered(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Resetta",
            icon: PrimeIcons.REFRESH,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.resettaPratica(pratica),
          },
          {
            label: "Annulla Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openAnnullaConsegnaPratica(pratica),
          },
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake))){
        this.menuAzioniItems.push(
          {
            label: "Cambia Stato",
            icon: PrimeIcons.DIRECTIONS,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.forzaStatoPratica(pratica),
          }
        );
      }
      
      //18 aprile 2024: PRESIDIO SF PUO' SCARICARE IL PACCHETTO DELLA PRATICA FIRMATA/APPROVATA/RESPINTA
      if( (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && (this.isInSigningPackageState(pratica) )   ){

        this.menuAzioniItems.push(
              {
                label: "Rigenera Docs",
                icon: PrimeIcons.REPLAY,
                style: {  'font-weight': 'bold','font-size': '12.7px'},
                command: () => this.rigeneraDocs(pratica),
              },
              {
                label: "Scarica Pacchetto",
                icon: PrimeIcons.DOWNLOAD,
                style: {  'font-weight': 'bold','font-size': '12.7px'},
                command: () => this.scaricaPacchettoPratica(pratica, false),
              });
        } 
        
  

      if(this.isInRework(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
          {
            label: "Rigenera Docs",
            icon: PrimeIcons.REPLAY,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            
            command: () => this.rigeneraDocs(pratica),
          },
        );
      }
      if(this.isInReworkSF(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          }
          
        );
      }

      if((this.isInRework(pratica) || this.isInReworkSF(pratica) ) && this.hasReworkFile(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Scarica allegato rework",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }

      if(this.isDelivered(pratica) || this.isInCaricoPresidioSF(pratica)){
        let tipiScaricamento:MenuItem[]=[];
        tipiScaricamento.push({
          label: "NT9",
          icon: PrimeIcons.MAP,
          style: {  'font-size': '12.7px'},
          command: () => this.scaricaNT9(),
        },
        {
          label: "RET",
          icon: PrimeIcons.FILE_WORD,
          style: {  'font-size': '12.7px'},
          command: () => this.scaricaDocs("RET"),
        },
        {
          label: "PSI",
          icon: PrimeIcons.FILE_WORD,
          style: {  'font-size': '12.7px'},
          command: () => this.scaricaDocs("PSI"),
        },
        /*  */);
        if(pratica.tipoSedeEffettiva!=='C'){
          tipiScaricamento.push( 
            {
              label: "ALL.SUBIRU",
              icon: PrimeIcons.FILE_WORD,
              style: {  'font-size': '12.7px'},
              command: () => this.scaricaDocs("IRU"),
            }
          );
        }
        if(pratica.tipoSedeEffettiva==='D'){
          tipiScaricamento.push( {
            label: "PLANIMETRIA",
            icon: PrimeIcons.IMAGE,
            style: {  'font-weight': 'bold', 'font-size': '12.7px'},
            command: () => this.scaricaPlanimetria(pratica),
          });
        }else{
          if(pratica.tipoSedeEffettiva==null && pratica.tipoSede === 'D')
            tipiScaricamento.push( {
              label: "PLANIMETRIA",
              icon: PrimeIcons.IMAGE,
              style: {  'font-weight': 'bold', 'font-size': '12.7px'},
              command: () => this.scaricaPlanimetria(pratica),
            });
        }
          tipiScaricamento.push( 
            {
              label: "TUTTI",
              icon: PrimeIcons.REPLAY,
              styleClass: "RETstyle",
              style: {  'font-weight': 'bold', 'font-size': '12.7px'},
              command: () => this.scaricaDocs("ALL"),
            
          });
          if(pratica.tipoSedeEffettiva==='D'){
            this.menuAzioniItems.push(
              {
                label: "Carica File",
                icon: PrimeIcons.CLOUD_UPLOAD,
                style: {  'font-weight': 'bold', 'font-size': '12.7px'},
                items: [
                  {
                    
                    label: "Foto Router",
                    icon: PrimeIcons.CAMERA,
                    command: () => this.openCaricaFotoRouter(pratica),
                  } ,
              
                  {
                    label: "Pacchetto NT9",
                    icon: PrimeIcons.BUILDING,
                    command: () => this.openCaricaPacchettoNT9(pratica),
                  }, 
                  {
                    label: "Planimetria",
                    icon: PrimeIcons.IMAGE,
                    command: () => this.openCaricaPlanimetria(pratica),
                  },
                  
                ],
              });
          }else{
            this.menuAzioniItems.push(
              {
                label: "Carica File",
                icon: PrimeIcons.CLOUD_UPLOAD,
                style: {  'font-weight': 'bold', 'font-size': '12.7px'},
                items: [
                  {
                    
                    label: "Foto Router",
                    icon: PrimeIcons.CAMERA,
                    command: () => this.openCaricaFotoRouter(pratica),
                  } ,
              
                  {
                    label: "Pacchetto NT9",
                    icon: PrimeIcons.BUILDING,
                    command: () => this.openCaricaPacchettoNT9(pratica),
                  }
                  
                ],
              });
          }
          this.menuAzioniItems.push(
          {
            label: "Scarica File",
            icon: PrimeIcons.CLOUD_DOWNLOAD,
            style: {   'font-size': '12.7px', 'font-weight': 'bold'},
            items: tipiScaricamento
          },
          { //3 maggio 2024: PRESIDIO SF PUO' SCARICARE IL PACCHETTO DELLA PRATICA FIRMATA/APPROVATA/RESPINTA
            label: "Scarica Pacchetto",
            icon: PrimeIcons.DOWNLOAD,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.presidioSFScaricaPacchettoPratica(pratica),
          }
        );
      }

      if(this.isInCaricoPresidioSF(pratica) ){ //|| this.isDelivered(pratica)
        this.menuAzioniItems.push(
          {
            label: "Info Fase 3",
            icon: PrimeIcons.SERVER,
            style: { 'font-size': '12.7px'},
            command: () => this.openInserisciDettagli(pratica),
          },
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Manda in Rework",
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            icon: PrimeIcons.CHEVRON_CIRCLE_LEFT,
            command: () => this.openMandaInRework(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Invia al Presidio",
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            icon: PrimeIcons.CHEVRON_CIRCLE_RIGHT,
            command: () => this.openMandaAlPresidioTIM(pratica),
          },
        );
      }


      if((this.isDelivered(pratica) || this.isRejected(pratica) || this.isInReworkSF(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },

        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) && (this.isRejected(pratica) || this.isSigned(pratica) ) ){
        this.menuAzioniItems.push( 
          {
          
            label: "Scarica Pacchetto",
            icon: PrimeIcons.DOWNLOAD,
            style: {  'font-weight': 'bold','font-size': '11.7px'},
            command: () => this.scaricaPacchettoPratica(pratica,false),
          },
          {
  
          label: "Scarica Pacch. Firmato",
          icon: PrimeIcons.DOWNLOAD,
          style: {  'font-weight': 'bold','font-size': '11.7px'},
          command: () => this.presidioSFScaricaPacchettoFirmato(pratica),
        });
      }

      if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Assegna",
            icon: PrimeIcons.PAPERCLIP,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openAssegnaAdImpresa(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            style: {  'font-size': '12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica File Respingimento",
            style: {  'font-size': '12.7px'},
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      if(this.isVoidable(pratica)){ 
        this.menuAzioniItems.push(
          {
            label: "Modifica",
            icon: PrimeIcons.PENCIL,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.openModificaPraticaDialog(pratica),
          },
        );
      }
        
     //PRESIDIO TIM ===================================================================================================================
    }else if(this.isPresidioTIM){
      if(!this.isApproved(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Nome Invio",
            style: { 'font-weight': 'bold', 'font-size': '13px'},
            icon: PrimeIcons.PENCIL,
            command: () => this.openCambiaCodiceInvio(pratica),
          },
        );
      }
      
      if(this.isInCaricoPresidioTIM(pratica) && !this.disableSingleActionButtons){
        if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || (pratica.colocata && pratica.colocataFake)) ){
          this.menuAzioniItems.push(
            { //8 maggio 2024: PRESIDIO TIM PUO' SCARICARE IL PACCHETTO DELLA PRATICA IN CARICO
              label: "Scarica Pacchetto",
              icon: PrimeIcons.DOWNLOAD,
              style: {  'font-size': '13px'},
              command: () => this.presidioTIMScaricaPacchettoPratica(pratica),
            },
             
          
        );
        if(pratica.codiceInvio!=null && pratica.codiceInvio.length>0){
          this.menuAzioniItems.push(
          {
            label: "Completa Pratica",
            style: {  'font-weight': 'bold','font-size': '13px'},
            icon: PrimeIcons.FILE_EDIT,
            command: () => this.openCompletaPraticaPresidioTIM(pratica),
          },
        );
        }
      }
        this.menuAzioniItems.push(
         /* 
          {
            label: "Completa Pratica",
            style: {  'font-weight': 'bold','font-size': '13px'},
            icon: PrimeIcons.FILE_EDIT,
            command: () => this.openCompletaPraticaPresidioTIM(pratica),
          }, */
          {
            label: "Rimanda a SmartFiber",
            style: {  'font-weight': 'bold','font-size': '12px'},
            icon: PrimeIcons.ARROW_CIRCLE_LEFT,
            command: () => this.openMandaIndietroAlPresidioSF(pratica),
          },
        );
       
       
        
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || (pratica.colocata && pratica.colocataConCodiceInvioDifferente) ) && this.isInVerbalSignState(pratica)){
        this.logger.info("--DEVO FAR TORNARE INDIETRO LA PRATICA!!!!");
      /*   this.menuAzioniItems.push(
         
          {
            label: "Riprendi in carico",
            style: {  'font-weight': 'bold','font-size': '12px'},
            icon: PrimeIcons.ARROW_LEFT,
            command: () => this.openPresidioSFRiprendeInCarico(pratica),
          },
        ); */
      }

      if( (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || (pratica.colocata && pratica.colocataConCodiceInvioDifferente)) && (this.isSigned(pratica) || this.isPublished(pratica) || this.isApproved(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica Pacchetto",
            icon: PrimeIcons.DOWNLOAD,
            style: {  'font-weight': 'bold','font-size': '13px'},
            command: () => this.scaricaPacchettoFirmato(pratica),
          });//,
        } 
        if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.isPublished(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            { 
                label: "Risultato Invio",
                style: {  'font-weight': 'bold','font-size': '13px'},
                icon: PrimeIcons.INBOX,
                items: [
                  {
                    label: "APPROVATA",
                    icon: PrimeIcons.CHECK,
                    styleClass: "valid-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
                  },
                  {
                    label: "RESPINTA",
                    icon: PrimeIcons.TIMES,
                    styleClass: "reject-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
                  },
                ]
        
            });
        }
          /* ); */
      
      

    }else if(this.isAdmin || this.isSuperAdmin){
      this.menuAzioniItems.push(
        {
          label: "Modifica",
          icon: PrimeIcons.PENCIL,
          command: () => this.openModificaPraticaDialog(pratica),
        },
      );
      if(this.isVoidable(pratica)){
        if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            {
              label: "Assegna",
              icon: PrimeIcons.PAPERCLIP,
              style: {  'font-weight': 'bold'},
              command: () => this.openAssegnaAdImpresa(pratica),
            },
          );
        }
        this.menuAzioniItems.push(
          {
            label: "Annulla",
            icon: PrimeIcons.TIMES,
            style: {  'font-weight': 'bold'},
            command: () => this.openAnnullaPratica(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: {  'font-weight': 'bold'},
            command: () => this.openEliminaPratica(pratica),
          },
        );

      }
     
      if(this.isApproved(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaPacchettoPratica(pratica, false),
          },
        );
      }
      if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica File Respingimento",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      if(this.isRejected(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },
        );
      }
    
    }else if(this.isDirigenteFirmaVerbali ){ //DIRIGENTE FIRMA VERBALI
     // this.logger.info(">>> isDirigenteFirmaVerbali su pratica "+pratica.colocata?"colocata ":"non colocata "+pratica.colocataRuolo?" con ruolo "+pratica.colocataRuolo:" "+" CODICE INVIO: " + pratica.codiceInvio);

      if(this.isInVerbalSignState(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaVerbale(pratica, false),
          },
          {
            label: "Carica Firmato",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, false),
          }
        );
      }
      if(this.hasSignedVerbal(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }else if (this.isDirigenteFirmaPacchetti){
      !pratica.colocataConCodiceInvioDifferente
      this.logger.info(">>> isDirigenteFirmaPacchetti su pratica "+pratica.codiceInfratel + " FLAG "+pratica.colocataConCodiceInvioDifferente);

      
      if(this.isInSigningPackageState(pratica) && (!pratica.colocata || pratica.colocataRuolo=='PRINCIPALE' || (pratica.colocata && pratica.colocataConCodiceInvioDifferente))){
        this.menuAzioniItems.push(
          {
                label: "Scarica Pacchetto",
                icon: PrimeIcons.DOWNLOAD,
                command: () => this.scaricaPacchettoPratica(pratica, false),
              } ,
              {
                label: "Carica Firmato",
                icon: PrimeIcons.UPLOAD,
                command: () => this.caricaPacchettoFirmato(pratica, false),
              } ,
            
          
        );
      }
      if(this.hasSignedPackage(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }
    
    
    /* 
    this.menuCaricaFileItems = [
      {
        label: "FOTO ROUTER",
        icon: PrimeIcons.CAMERA,
        command: () => this.openCaricaFotoRouter(pratica),
      },
      {
        label: "PLANIMETRIA",
        icon: PrimeIcons.IMAGE,
        command: () => this.openCaricaPlanimetria(pratica),
      },
      {
        label: "NT9",
        icon: PrimeIcons.BUILDING,
        command: () => this.openCaricaPacchettoNT9(pratica),
      }
    ]; */
  }

  loadAzioniCaricaFile(pratica:PraticaScuola_Firestore) {
    this.menuCaricaFileItems = [
      {
        label: "FOTO ROUTER",
        icon: PrimeIcons.CAMERA,
        command: () => this.openCaricaFotoRouter(pratica),
      },
      {
        label: "PLANIMETRIA",
        icon: PrimeIcons.IMAGE,
        command: () => this.openCaricaPlanimetria(pratica),
      },
      {
        label: "NT9",
        icon: PrimeIcons.BUILDING,
        command: () => this.openCaricaPacchettoNT9(pratica),
      }
    ];
  }

  showPulsante(pratica:PraticaScuola_Firestore):boolean{
    if(this.isDirigenteFirmaPacchetti && (pratica.colocataRuolo==='SECONDARIA' && !pratica.colocataConCodiceInvioDifferente)){
      return false;
    }
    return true;
  }

  showDocs(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.infoCompleted && pratica.fotoRouterUploaded){

      if(!pratica.colocata){
        /* if(pratica.codiceMiur==='AVPS02101T'){
          this.logger.info("* showDocs pratica "+ pratica.codiceInfratel + " DI PRATICA _NON-COLOCATA IN PROGETTAZIONE, CON INFO COMPLETE E FOTO DEL ROUTER UPLOADATA");

        } */

        return true;
      }else{
        //this.logger.info("* showDocs pratica "+ pratica.codiceInfratel + " DI PRATICA _COLOCATA PRINCIPALE_ IN PROGETTAZIONE "+ this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel) + " "+ this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel));

        
        if( pratica.colocataRuolo==='PRINCIPALE' && this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel)>-1 && this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel)>-1){
          return true;
        }
      }
      
  
    }
    return false;
  }
  showInfoFase3Complete(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.infoCompleted ) {
      return true;
    }
    return false;
  }
  showPlanimetriaUploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.planimetriaUploaded  ){ //&& (!pratica.infoCompleted && !pratica.fotoRouterUploaded)
      return true;
    }
    return false;
  }
  showPacchettoNT9Uploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.nt9Uploaded ){ //&& (!pratica.infoCompleted && !pratica.fotoRouterUploaded) 
      return true;
    }
    return false;
  }
  showFotoRouterUploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.fotoRouterUploaded){ // && (!pratica.infoCompleted && !pratica.fotoRouterUploaded)  
      return true;
    }
    return false;
  }

  showDeliverable(pratica:PraticaScuola_Firestore):boolean{
    
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE'){ //&& (pratica.tipoSede==='D' && pratica.pdfPlanimetriaUploaded)
      if(!pratica.colocata && pratica.infoCompleted && pratica.nt9Uploaded && pratica.fotoRouterUploaded ){
        return true;
      }else if(pratica.colocata && pratica.colocataRuolo==='PRINCIPALE' && pratica.infoCompleted && pratica.nt9Uploaded && pratica.fotoRouterUploaded  ){
       // this.logger.info("---> showDeliverable: INFO COMPLETE " +this.listaScuoleColocateInfoComplete.length);
        if(this.listaScuoleColocateComplete.indexOf(pratica.codiceInfratel)>-1 && this.listaScuoleColocateInfoComplete.indexOf(pratica.codiceInfratel)>-1){
          return true;
        }
       
         
      }
    } 
     
    return false;
  }
  async scaricaNT9() {
    const storage = getStorage();
      const pathReferenceNT9 = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/IN/nt9.zip"
      );

      const blobNT9 = await getBlob(pathReferenceNT9);
      saveAs(blobNT9, "nt9.zip");

  }

  async scaricaPlanimetria(pratica:PraticaScuola_Firestore){
    
    const storage = getStorage();
    this.isLoadingResults = true;
    this.praticheScuolaService.getById(this.selectedPraticaAtFirestore.id.toString()).subscribe(praticadDTO =>{
      this.scuoleService.getById(praticadDTO.scuola.id).subscribe(async scuola =>{

        if(scuola.estensionePlanimetria){
          const planimetriaPathVecchio= "pratiche/scuola/" +this.selectedPraticaAtFirestore.codiceInfratel +"/IN/planimetria"+"." +scuola.estensionePlanimetria;
          const planimetriaPathNuovo= "pratiche/scuola/" +this.selectedPraticaAtFirestore.codiceInfratel +"/IN/PLANIMETRIA_"+this.selectedPraticaAtFirestore.codiceInfratel+"." +scuola.estensionePlanimetria;
          this.googleStorageService.checkIfFileExists(planimetriaPathVecchio).subscribe(async risultatoCheckSuStorage =>{
            this.logger.info(">>> RISULTATO CHECK SU STORAGE "+risultatoCheckSuStorage);
            if(risultatoCheckSuStorage){
              const pathReferencePlanimetria = ref( storage,planimetriaPathVecchio);
              const blobPlanimetria = await getBlob(pathReferencePlanimetria);
              saveAs(blobPlanimetria, "Planimetria_"+this.selectedPraticaAtFirestore.codiceInfratel+"."+scuola.estensionePlanimetria);
              this.isLoadingResults = false;
            }else{
              this.googleStorageService.checkIfFileExists(planimetriaPathNuovo).subscribe(async risultatoCheckSuStorage =>{
                if(risultatoCheckSuStorage){
                  const pathReferencePlanimetria = ref( storage,planimetriaPathNuovo);
                  const blobPlanimetria = await getBlob(pathReferencePlanimetria);
                  saveAs(blobPlanimetria, "Planimetria_"+this.selectedPraticaAtFirestore.codiceInfratel+"."+scuola.estensionePlanimetria);
                  this.isLoadingResults = false;
                }else{
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "PLANIMETRIA NON PRESENTE SU STORAGE",
                  });
                }
              });
             
              this.isLoadingResults = false;
            }
          });
  
  
          
        }else{
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "PLANIMETRIA NON CARICATA DALL'IMPRESA",
          });
          this.isLoadingResults = false;
        }
       
      });
    });
   
  }
  async scaricaDocs(action: string) {
    const storage = getStorage();
    if (action === "RET") {
      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE' ||  ( this.selectedPraticaAtFirestore.colocataRuolo ==='SECONDARIA' && this.selectedPraticaAtFirestore.colocataFake)){
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }else{
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }
      
    } else if (action === "PSI") {
      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE' || ( this.selectedPraticaAtFirestore.colocataRuolo ==='SECONDARIA' && this.selectedPraticaAtFirestore.colocataFake)){
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }else{
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }
     
    } else if (action === "IRU") {

      const pathReferencePSI = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/ALLEGATO CONTRATTO IRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );

      const blobPSI = await getBlob(pathReferencePSI);
      saveAs(
        blobPSI,
        "ALLEGATO CONTRATTO SUBIRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );
    } else if (action === "ALL") {
      if(this.selectedPraticaAtFirestore.tipoSedeEffettiva==='D'){
        this.scaricaPlanimetria(this.selectedPraticaAtFirestore);
      }
      

      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE' || ( this.selectedPraticaAtFirestore.colocataRuolo ==='SECONDARIA' && this.selectedPraticaAtFirestore.colocataFake)){
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }else{
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }

      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE' || ( this.selectedPraticaAtFirestore.colocataRuolo ==='SECONDARIA' && this.selectedPraticaAtFirestore.colocataFake)){
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }else{
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }
      if(this.selectedPraticaAtFirestore.tipoSedeEffettiva!=='C'){
        const pathReferenceAllegatoIRU = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/ALLEGATO CONTRATTO IRU_" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            ".docx"
        );
        const blobAllegatoIRU = await getBlob(pathReferenceAllegatoIRU);
        saveAs(
          blobAllegatoIRU,
          "ALLEGATO CONTRATTO SUBIRU_" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            ".docx"
        );
      }
      

      const pathReferenceNT9 = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/IN/nt9.zip"
      );

      const blobNT9 = await getBlob(pathReferenceNT9);
      saveAs(blobNT9, "nt9.zip");

      
    }
  }
  openCaricaPacchettoFirmato(pratica:PraticaScuola_Firestore){
    this.logger.info("--carica pacchetto");
    /**
      1) APRIRE LA MODALE
      2) SE CARICAMENTO OK > MODIFICO DATO SU FIRESTORE E/O CHIAMO ENDPOINT
      3) CAMBIO LO STATO ?
    */
  }

  getColocataFontClass(pratica: PraticaScuola_Firestore): string {
    if(!pratica.colocata){
      return '';
    }else{
      if(pratica.colocataRuolo==='PRINCIPALE'){
        return 'colocata-principale-text';
      }
    }
    return 'colocata-secondaria-text';
  }
  
  getColocataClass(pratica: PraticaScuola_Firestore):string{
    if(!pratica.colocata){
      return '';
    }else{
      if(pratica.colocataRuolo==='PRINCIPALE'){
        return 'text-blu-900 pi-check';
      }
    }
    return 'text-green-700 pi-check';
    /*
    'text-green-700 pi-check': (row.colocata && row.colocataRuolo == 'PRINCIPALE'),
              'text-red-700 pi-check': (row.colocata && row.colocataRuolo == 'SECONDARIA'),
              '': !row.colocata
    */
     

  }
  openMandaInFirma(pratica: PraticaScuola_Firestore) {}

  async scaricaFileRespingimentoPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO File RESPINGIMENTO DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const pathReferenceFileRespingimento                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteRespingimentoFile);
    const blob = await getBlob(pathReferenceFileRespingimento);
    saveAs(blob, pratica.noteRespingimentoFile);
  }

  async scaricaVerbale(pratica: PraticaScuola_Firestore, isBulk:boolean) {
    this.logger.info("-scaricaVerbale per pratica "+ pratica.codiceInfratel);
    if(!isBulk){
      this.confirmationService.confirm({
        message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
        header: "ATTENZIONE!",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => {
          const storage = getStorage();
          const pathReferenceFileVerbale    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
         
          try {
            const blob = await getBlob(pathReferenceFileVerbale);
            saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");

            if(pratica.tipoSedeEffettiva==='D' && pratica.ha2Tratte ){
              if(!pratica.colocata || pratica.colocataRuolo==='PRINCIPALE'){
                this.logger.info("-PRATICA TIPO DI: SCARICO ANCHE IL VERBALE NUOVE PROPRIETA: Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf" );
                const pathReferenceFileVerbaleTratta2    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
                const blobTratta2 = await getBlob(pathReferenceFileVerbaleTratta2);
                saveAs(blobTratta2, "Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
              }else if(pratica.colocata && pratica.colocataRuolo==='SECONDARIA'){
                this.praticheScuolaService
                .getByCodiceInfratel(pratica.colocataScuolaPrincipale.toString())
                .subscribe(async (praticaDaDB) => {
                  let praticaDaVerificare:PraticaScuola = praticaDaDB as PraticaScuola;
                  if(!praticaDaVerificare.scuola.cpeInStessoLocale){
                    this.logger.info("-PRATICA TIPO DI D COLOCATA CON CPE IN STANZA DIVERSA: SCARICO ANCHE IL VERBALE NUOVE PROPRIETA: Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf" );
                    const pathReferenceFileVerbaleTratta2    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
                    const blobTratta2 = await getBlob(pathReferenceFileVerbaleTratta2);
                    saveAs(blobTratta2, "Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
                  }else{
                    this.logger.info("-PRATICA TIPO DI D COLOCATA CON CPE IN STESSO LOCALE: NON DEVO SCARICARE  IL VERBALE NUOVE PROPRIETA!");

                  }
                  
          
                });
              }
             
              
            }
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Verbale cessione IRU NON esistente",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
        },
        reject: () => {
        },
      });
    }else{
      const storage = getStorage();
          const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
          try {
            const blob = await getBlob(pathReferenceFileVerbale);
            saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
            if(pratica.tipoSedeEffettiva==='D' && pratica.ha2Tratte){
              const pathReferenceFileVerbaleTratta2    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
              const blobTratta2 = await getBlob(pathReferenceFileVerbaleTratta2);
              saveAs(blobTratta2, "Contratto_Cessione_Proprietà_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
            }
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Verbale cessione IRU NON esistente",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
    }
    
    

  }

  async scaricaShape(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO SHAPE DA FIRMARE DELLA PRATICA "+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length));
    const storage = getStorage();
    const pathReferencePacchetto                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
    try {
      const blob = await getBlob(pathReferencePacchetto);
      saveAs(blob, "AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip");
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        this.logger.info('Il file non esiste:', error.message);
        this.messageService.add({
          severity: "info",
         summary: "Errore" , life:10000,
          detail: "File nt9 NON esistente",
        });
      } else {
        // Gestisci altri tipi di errori
        this.logger.info('Errore sconosciuto:', error);
      }
    }
   
    
    
  }
  async scaricaPacchetto(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO PACCHETTO DA FIRMARE DELLA PRATICA "+pratica.codiceInfratel);
    this.confirmationService.confirm({
      message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
      header: "ATTENZIONE!",
      icon: "pi pi-exclamation-circle",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        const storage = getStorage();
        const pathReferencePacchetto                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
        try {
          const blob = await getBlob(pathReferencePacchetto);
          saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInvio+".pdf");
        }catch (error) {
          if (error.code === 'storage/object-not-found') {
            this.logger.info('Il file non esiste:', error.message);
            /* this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Verbale cessione IRU NON esistente",
            }); */
          } else {
            // Gestisci altri tipi di errori
            this.logger.info('Errore sconosciuto:', error);
          }
        }
        
      },
      reject: () => {
      },
    });

    

  }

  async scaricaFileReworkPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO File REWORK DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const pathReferenceFileRework                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteReworkFile);
    const blob = await getBlob(pathReferenceFileRework);
    saveAs(blob, pratica.noteReworkFile);
  }

  forzaStatoPratica(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogModificaStatoPratica, {
      data: {
        pratica: pratica,
      },
      header: "Cambio stato della Pratica "+pratica.codiceInfratel,
      width: "55%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((statoUpdated) => {
      if (statoUpdated && statoUpdated.success) {
        this.isLoadingResults = false;
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Stato della pratica "+ pratica.codiceInfratel+" aggiornato con successo",
        });
      }
    });

  }

  resettaPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> RESETTO LA PRTICA "+pratica.codiceInfratel);
    let messsaggio:string = "Confermi di voler <strong>RESETTARE</strong> la pratica <strong>" + pratica.codiceInfratel + "</strong> ?";
    if(pratica.colocata){
      messsaggio = "Confermi di voler <strong>RESETTARE</strong> la pratica <strong>" + pratica.codiceInfratel + "</strong> e <strong>TUTTE</strong> le sue colocate?";
    }
    this.confirmationService.confirm({
      message: messsaggio,
      header: "ATTENZIONE!",
      icon: "pi pi-refresh",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
       this.praticheScuolaService.resetta(pratica.id.valueOf()).subscribe(risultatoRigenerazione=>{
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Reset della pratica "+pratica.codiceInfratel+" avvenuto con successo",
          });
         });
      },
      reject: () => {
      },
    });
  }

  async rigeneraDocs(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> RIGENERO TUTTA LA DOCUMENTAZIONE DELLA PRATICA "+pratica.codiceInfratel);

        this.confirmationService.confirm({
          message: `Confermi di voler RIGENERARE tutta la documentazione della pratica ${pratica.codiceInfratel} ?`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
            this.praticheScuolaService.rigeneraTuttaLaDocumentazione(pratica.id.valueOf()).subscribe(risultatoRigenerazione=>{
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Rigenerazione della documentazione della pratica "+pratica.codiceInfratel+" completata con successo",
              });
             });
          },
          reject: () => {
          },
        });
   
  }

  async scaricaPacchettoPraticaApprovata(pratica: PraticaScuola_Firestore){
    if(!pratica.colocata || (pratica.colocata && pratica.colocataConCodiceInvioDifferente)){
      this.scaricaPacchettoPraticaSingolaApprovata(pratica);
    }else{

      this.scaricaPacchettoPraticaColocataApprovata(pratica);
    }
  }

  async scaricaPacchettoPraticaColocataApprovata(pratica: PraticaScuola_Firestore) {
    if(pratica.tipoSedeEffettiva === 'D'){
      this.logger.info("---> SONO TIPO D");
      this.scaricaPacchettoPraticaD_COLOCATA(pratica);
    }else{
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaDaDB) => {
        let praticaScuola:PraticaScuola = praticaDaDB as PraticaScuola;
        this.isLoadingResults = true;
              
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              //  this.logger.info("---> risultato della get colocate? "+ qs.size);
              if( qs.size==1){
                this.logger.info("---> COLOCATA NON D E NORMALE (#"+ qs.size+")");
                let praticaColocata:PraticaScuola_Firestore = null;
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                
                const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );

                //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    pathReferencePDFPlanimetriaPrincipale,
                    pathReferenceVerbaleCessioneProprietaPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                    pathReferenceVerbaleCessioneIRUPrincipale,
                    pathReferenceVerbaleCessioneIRUSecondaria
                  ];

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      }
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;
              }else if(qs.size==2){
                this.logger.info("---> COLOCATA NON D E TRIPLA (#"+ qs.size+")");
                let praticaColocataUNO = qs.docs[0].data() as PraticaScuola_Firestore;
                let praticaColocataDUE = qs.docs[1].data() as PraticaScuola_Firestore;

                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Terziaria                    = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip");
                const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataUNO.codiceInfratel+".pdf");
                const pathReferencePDFPlanimetriaTerziaria         = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataDUE.codiceInfratel+".pdf");
                
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUTerziaria            = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf" );
                
                const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUTerziaria     = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m" );
                
               // const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
              // const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m" );
              //  const pathReferenceVerbaleCessioneProprietaTerziaria  = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m" );

                //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    pathReferenceNT9Terziaria,
                    //pathReferencePDFPlanimetriaPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,

                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                    pathReferenceAllegatoIRUTerziaria,

                //    pathReferenceVerbaleCessioneProprietaPrincipale,
                //    pathReferenceVerbaleCessioneProprietaSecondaria,
                //    pathReferenceVerbaleCessioneProprietaTerziaria,

                    pathReferenceVerbaleCessioneIRUPrincipale,
                    pathReferenceVerbaleCessioneIRUSecondaria,
                    pathReferenceVerbaleCessioneIRUTerziaria
                  ];

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  //this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                       // NT9 INIZIO
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataUNO.codiceInfratel.substring(praticaColocataUNO.codiceInfratel.length-15,praticaColocataUNO.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataDUE.codiceInfratel.substring(praticaColocataDUE.codiceInfratel.length-15,praticaColocataDUE.codiceInfratel.length)+".zip", blob);
                      // NT9 FINE - INIZIO ALLEGATO CONTRATTO IRU
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf"){
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf"){
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }
                       // ALLEGATO CONTRATTO IRU FINE
                       else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m"){
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m"){
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      /* }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      } */
                      }/* else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocataUNO.codiceInfratel+".pdf.p7m"){
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocataUNO.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocataDUE.codiceInfratel+".pdf.p7m"){
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocataDUE.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      } *//* else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      } */
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");

                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  
                 
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;


              }else{
                this.isLoadingResults = false;
              }
            
           });
      });
    }
  }

  async scaricaPacchettoFirmato(pratica: PraticaScuola_Firestore){

    if(!pratica.colocata){

      this.openScaricaPacchettoFirmatoPratica(pratica);
    }else{

      this.openScaricaPacchettoFirmatoPratica(pratica);
    }
  }

  async scaricaPacchettoPratica(pratica: PraticaScuola_Firestore, isBulk:boolean){
    if(!pratica.colocata || (pratica.colocata && pratica.colocataConCodiceInvioDifferente)){
      this.openScaricaPacchettoPratica(pratica, isBulk);
    }else{

      this.openScaricaPacchettoPraticaColocata(pratica, isBulk);
    }
  }

  async scaricaPreviewDocsPraticaColocata(pratica: PraticaScuola_Firestore){
    this.logger.info("---> SCARICO LA PREVIEW DEI DOCS DELLA PRATICA COLOCATA "+pratica.codiceInfratel + " con codiceInvio "+ pratica.codiceInvio);
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
    onSnapshot(query, async (qs) => {
          this.logger.info(  "--> RISULTATO GET COLOCATA " + qs.docs.length );
          if( qs.size==1){
            let praticaColocata:PraticaScuola_Firestore = null;

            praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
            this.logger.info("---> HA SECONDARIA "+praticaColocata.codiceInfratel);
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_RET_01_0.docx");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_PSI_02_0.docx");
            let filesToDownload = [
              pathReferenceRET,
              pathReferencePSI];

            if(praticaColocata.tipoSedeEffettiva!=='C'){
              for (let index = 0; index < qs.size; index++) {
                praticaColocata = qs.docs[index].data() as PraticaScuola_Firestore;
                const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
                const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx" );
                filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
                filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
           
                
              }
              
            }
            
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
              const blob = await getBlob(pathRef);
               
              if (blob) {
                zip.file(pathRef.name, blob);
                
                downloadPromises.push(Promise.resolve());
                
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }  
            }
            
            Promise.all(downloadPromises)
            .then(() => {
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il File zip
                  this.isLoadingResults = false;
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
            this.isLoadingResults = false;
          }else if( qs.size==2){
            let praticaColocataUno:PraticaScuola_Firestore = null;
            let praticaColocataDue:PraticaScuola_Firestore = null;

            praticaColocataUno = qs.docs[0].data() as PraticaScuola_Firestore;
            praticaColocataDue = qs.docs[1].data() as PraticaScuola_Firestore;
            this.logger.info("---> HA SECONDARIA 1 "+praticaColocataUno.codiceInfratel);
            this.logger.info("---> HA SECONDARIA 2 "+praticaColocataDue.codiceInfratel);
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_RET_01_0.docx");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_PSI_02_0.docx");
            let filesToDownload = [
              pathReferenceRET,
              pathReferencePSI];

            if(pratica.tipoSedeEffettiva!=='C'){
              const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
              const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUno.codiceInfratel + ".docx" );
              const pathReferenceAllegatoIRUTerziaria            = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDue.codiceInfratel + ".docx" );

              filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
              filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
         
            
              
            }
            
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
              const blob = await getBlob(pathRef);
               
              if (blob) {
                zip.file(pathRef.name, blob);
                
                downloadPromises.push(Promise.resolve());
                
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }  
            }
            
            Promise.all(downloadPromises)
            .then(() => {
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il File zip
                  this.isLoadingResults = false;
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
            this.isLoadingResults = false;
          }
        
    });
  }

  async scaricaPacchettoPraticaD_COLOCATA(pratica: PraticaScuola_Firestore){
    this.logger.info("scaricaPacchettoPraticaD_COLOCATA");
     this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
      this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{

        this.confirmationService.confirm({
          message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
              this.isLoadingResults = true;
              let praticaColocata:PraticaScuola_Firestore = null;
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              
              
              if( qs.size==1){
                this.logger.info(  "--> SONO UNA COLOCATA NORMALE (#" + qs.docs.length + ")");
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                //const pathReferencePDFPlanimetriaPrincipale                 = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaSecondaria                 = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaSecondariaCompleta    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaPrincipale            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaSecondaria            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                
                const pathReferenceRET                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                const pathReferenceAllegatoIRUPrincipale                    = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUSecondaria                    = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                const pathReferenceVerbaleCessioneIRUPrincipale             = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUSecondaria             = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                
                const pathReferenceVerbaleCessioneProprietaPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprietàPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                
                const pathReferenceVerbaleCessioneProprietaSecondaria       = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprietàSecondaria       = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );

                //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                   // pathReferencePDFPlanimetriaPrincipale,
                    pathReferenceVerbaleCessioneProprietaPrincipale,
                    pathReferenceVerbaleCessioneProprietàPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                    pathReferenceVerbaleCessioneIRUPrincipale,
                    pathReferenceVerbaleCessioneIRUSecondaria,
                    pathReferenceVerbaleCessioneProprietaSecondaria,
                    pathReferenceVerbaleCessioneProprietàSecondaria
                  ];

                  if(pratica.pdfPlanimetriaUploaded ){
                   // filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                  }else{
                    filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                    filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                       
                  }  
                  if(!scuola.cpeInStessoLocale){
                    filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
                    filesToDownload.push(pathReferenceVerbaleCessioneProprietàSecondaria);
                    if(praticaColocata.pdfPlanimetriaUploaded){
                     // filesToDownload.push(pathReferencePDFPlanimetriaSecondaria);
                    }else{
                      filesToDownload.push(pathReferenceImmaginePlanimetriaSecondaria);    
                      filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    
                    }
                   
                  }
                  filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                  filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    

                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  
                  try {
                    
                    const stringaPath = pathRef.fullPath;
                    const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                    const match = regex.exec(stringaPath);
                    const codiceInfratel = match ? match[1] : null;
                    this.logger.info("CERCO IL FILE: " + pathRef.name + " @ " + pathRef.fullPath + " > codiceInfratel " + codiceInfratel);
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                          //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocata.codiceInfratel + ".pdf";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);  
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                        //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".pdf.p7m";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                      
                        zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                       }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        this.logger.info("+TROVATO CONTRATTO CESSIONE PROPRIETA COLOCATA - SENZA ACCENTO");
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        this.logger.info("+TROVATO CONTRATTO CESSIONE PROPRIETA COLOCATA - CON ACCENTO");
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      } /* else if(pathRef.name.startsWith("planimetria") ){
                        this.logger.info(">>> QUI SONO GIUSTO, PLANIMETRIA E CODICE INFRATEL >> "+  codiceInfratel);
                        zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }  */
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                      /* this.messageService.add({
                        severity: "info",
                       summary: "Errore" , life:10000,
                        detail: "Il file "+ error.message+" non esiste",
                      }); */
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;



              }else if(qs.size==2){
                this.logger.info(  "--> SONO UNA COLOCATA TRIPLA (#" + qs.docs.length + ")");
                let praticaColocataUno:PraticaScuola_Firestore = null;
                let praticaColocataDue:PraticaScuola_Firestore = null;
                    praticaColocataUno = qs.docs[0].data() as PraticaScuola_Firestore;
                    praticaColocataDue = qs.docs[1].data() as PraticaScuola_Firestore;
                    const storage = getStorage();
                    const zip = new JSZip();
                    const pathReferenceNT9Principale                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferenceNT9SecondariaUNO                         = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferenceNT9SecondariaDUE                          = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/nt9.zip");
                    //const pathReferencePDFPlanimetriaPrincipale                 = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                    //const pathReferencePDFPlanimetriaSecondariaUNO                 = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf");
                   // const pathReferencePDFPlanimetriaSecondariaDUE                 = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf");
                    const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaCompletaUNO    = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaCompletaDUE    = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaPrincipale            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaUNO            = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaDUE            = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                    
                    const pathReferenceRET                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                    const pathReferencePSI                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                    const pathReferenceCMT                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
           
                    const pathReferenceAllegatoIRUPrincipale                    = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondariaUNO                    = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUno.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondariaDUE                    = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDue.codiceInfratel + ".pdf" );
                    const pathReferenceVerbaleCessioneIRUPrincipale             = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondariaUNO             = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondariaDUE             = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m" );
                    
                    const pathReferenceVerbaleCessioneProprietaPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneProprietaSecondariaUNO       = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocataUno.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneProprietaSecondariaDUE       = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocataDue.codiceInfratel + ".pdf.p7m" );
    
                    //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
        
                    
                    let filesToDownload = [
                        pathReferenceNT9Principale, 
                        pathReferenceNT9SecondariaUNO, 
                        pathReferenceNT9SecondariaDUE,
                        //pathReferencePDFPlanimetriaPrincipale,
                        pathReferenceVerbaleCessioneProprietaPrincipale,
                        pathReferenceRET,
                        pathReferencePSI,
                        pathReferenceCMT,
                        pathReferenceAllegatoIRUPrincipale, 
                        pathReferenceAllegatoIRUSecondariaUNO, 
                        pathReferenceAllegatoIRUSecondariaDUE,
                        pathReferenceVerbaleCessioneIRUPrincipale,
                        pathReferenceVerbaleCessioneIRUSecondariaUNO,
                        pathReferenceVerbaleCessioneIRUSecondariaDUE,
                        pathReferenceVerbaleCessioneProprietaPrincipale
                      ];
                      
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 

                     /*  if(pratica.pdfPlanimetriaUploaded ){
                       // filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                      }else{
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                           
                      }   */
                      this.logger.info(pratica.codiceInfratel + " ha colocate in stesso cpe? " + scuola.cpeInStessoLocale);

                      if(!scuola.cpeInStessoLocale){
                        filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondariaUNO);
                        filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondariaDUE);

                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaUNO);    
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaDUE);   
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaDUE);    
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaUNO);   

                       /*  if(praticaColocata.pdfPlanimetriaUploaded){
                          filesToDownload.push(pathReferencePDFPlanimetriaSecondariaUNO);
                          filesToDownload.push(pathReferencePDFPlanimetriaSecondariaDUE);
                        }else{
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaUNO);    
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaDUE);    

                        } */
                       
                      }
                     
                    const downloadPromises = [];
                    for (const pathRef of filesToDownload) {
                      
                      try {
                        
                        const stringaPath = pathRef.fullPath;
                        const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                        const match = regex.exec(stringaPath);
                        const codiceInfratel = match ? match[1] : null;
                        const blob = await getBlob(pathRef);
                        if (blob) {
                          if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                            zip.file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                            this.logger.info(pratica.codiceInfratel + " NT9  COLOCATA OK!");

                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/nt9.zip"){
                            

                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataUno.codiceInfratel.substring(praticaColocataUno.codiceInfratel.length-15,praticaColocataUno.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/nt9.zip"){
                            
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataDue.codiceInfratel.substring(praticaColocataDue.codiceInfratel.length-15,praticaColocataDue.codiceInfratel.length)+".zip", blob);
                          } else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                             // zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUno.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocataUno.codiceInfratel + ".pdf";
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                           //zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDue.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocataDue.codiceInfratel + ".pdf";
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            //zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                            //  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                            let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m"){
                            let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m";
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            //zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m"){
                            let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m";
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            
                            //zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(pratica.codiceInfratel + " PLANIMETRIA PRINCIPALE OK!");
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(praticaColocataUno.codiceInfratel + " PLANIMETRIA PRINCIPALE COLOCATA UNO!");
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(praticaColocataDue.codiceInfratel + " PLANIMETRIA PRINCIPALE COLOCATA DUE!");
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocataUno.codiceInfratel+".pdf.p7m"){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocataDue.codiceInfratel+".pdf.p7m"){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf"){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf"){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                          } 
                         
                          downloadPromises.push(Promise.resolve());
                          
                        } else {
                          this.logger.info(`Errore durante il download di ${pathRef.name}`);
                        }  
                      } catch (error) {
                        this.isLoadingResults = false;
                        if (error.code === 'storage/object-not-found') {
                          this.logger.error('Il file non esiste:', error.message);
                         
                        } else {
                          // Gestisci altri tipi di errori
                          this.logger.error('Errore sconosciuto:', error);
                        }
                      }
        
                    }
                    
                    Promise.all(downloadPromises)
                    .then(() => {
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      // Crea il File zip e avvia il download:
                      zip.generateAsync({ type: 'blob' })
                        .then((blob) => {
                          saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                        })
                        .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                    })
                    .catch(error => this.logger.info('Errore durante il download dei File:', error));
                    this.isLoadingResults = false;

               
              }else{
                this.isLoadingResults = false;
              }
            
           });
            
          },
          reject: () => {
          },
        });
      });
    });
  }

  async scaricaPacchettoPraticaApprovata_D_COLOCATA(pratica: PraticaScuola_Firestore){
    this.logger.info("scaricaPacchettoPraticaD_COLOCATA");
     this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
      this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{


              this.isLoadingResults = true;
              let praticaColocata:PraticaScuola_Firestore = null;
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              
              
              if( qs.size==1){
                this.logger.info(  "--> SONO UNA COLOCATA NORMALE (#" + qs.docs.length + ")");
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                //const pathReferencePDFPlanimetriaPrincipale                 = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaSecondaria                 = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaSecondariaCompleta    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaPrincipale            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetriaSecondaria            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                
                const pathReferenceRET                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                const pathReferenceAllegatoIRUPrincipale                    = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUSecondaria                    = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                const pathReferenceVerbaleCessioneIRUPrincipale             = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUSecondaria             = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                
                const pathReferenceVerbaleCessioneProprietaPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprietàPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                
                const pathReferenceVerbaleCessioneProprietaSecondaria       = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprietàSecondaria       = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );

                //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                   // pathReferencePDFPlanimetriaPrincipale,
                    pathReferenceVerbaleCessioneProprietaPrincipale,
                    pathReferenceVerbaleCessioneProprietàPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                    pathReferenceVerbaleCessioneIRUPrincipale,
                    pathReferenceVerbaleCessioneIRUSecondaria,
                    pathReferenceVerbaleCessioneProprietaSecondaria,
                    pathReferenceVerbaleCessioneProprietàSecondaria
                  ];

                  if(pratica.pdfPlanimetriaUploaded ){
                   // filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                  }else{
                    filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                    filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                       
                  }  
                  if(!scuola.cpeInStessoLocale){
                    filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
                    filesToDownload.push(pathReferenceVerbaleCessioneProprietàSecondaria);
                    if(praticaColocata.pdfPlanimetriaUploaded){
                     // filesToDownload.push(pathReferencePDFPlanimetriaSecondaria);
                    }else{
                      filesToDownload.push(pathReferenceImmaginePlanimetriaSecondaria);    
                      filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    
                    }
                   
                  }
                  filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                  filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    

                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  
                  try {
                    
                    const stringaPath = pathRef.fullPath;
                    const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                    const match = regex.exec(stringaPath);
                    const codiceInfratel = match ? match[1] : null;
                    this.logger.info("CERCO IL FILE: " + pathRef.name + " @ " + pathRef.fullPath + " > codiceInfratel " + codiceInfratel);
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                       // zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                     
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocata.codiceInfratel + ".pdf";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                      
                        //  zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                        // zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".pdf.p7m";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                       
                       // zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                        zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                       }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        this.logger.info("+TROVATO CONTRATTO CESSIONE PROPRIETA COLOCATA - SENZA ACCENTO");
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        this.logger.info("+TROVATO CONTRATTO CESSIONE PROPRIETA COLOCATA - CON ACCENTO");
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      } /* else if(pathRef.name.startsWith("planimetria") ){
                        this.logger.info(">>> QUI SONO GIUSTO, PLANIMETRIA E CODICE INFRATEL >> "+  codiceInfratel);
                        zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }  */
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                      /* this.messageService.add({
                        severity: "info",
                       summary: "Errore" , life:10000,
                        detail: "Il file "+ error.message+" non esiste",
                      }); */
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;



              }else if(qs.size==2){
                this.logger.info(  "--> SONO UNA COLOCATA TRIPLA (#" + qs.docs.length + ")");
                let praticaColocataUno:PraticaScuola_Firestore = null;
                let praticaColocataDue:PraticaScuola_Firestore = null;
                    praticaColocataUno = qs.docs[0].data() as PraticaScuola_Firestore;
                    praticaColocataDue = qs.docs[1].data() as PraticaScuola_Firestore;
                    const storage = getStorage();
                    const zip = new JSZip();
                    const pathReferenceNT9Principale                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferenceNT9SecondariaUNO                         = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferenceNT9SecondariaDUE                          = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/nt9.zip");
                    //const pathReferencePDFPlanimetriaPrincipale                 = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                    //const pathReferencePDFPlanimetriaSecondariaUNO                 = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf");
                   // const pathReferencePDFPlanimetriaSecondariaDUE                 = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf");
                    const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaCompletaUNO    = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaCompletaDUE    = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaPrincipale            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaUNO            = ref( storage, "pratiche/scuola/" + praticaColocataUno.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaDUE            = ref( storage, "pratiche/scuola/" + praticaColocataDue.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                    
                    const pathReferenceRET                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                    const pathReferencePSI                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                    const pathReferenceCMT                                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
           
                    const pathReferenceAllegatoIRUPrincipale                    = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondariaUNO                    = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUno.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondariaDUE                    = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDue.codiceInfratel + ".pdf" );
                    const pathReferenceVerbaleCessioneIRUPrincipale             = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondariaUNO             = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondariaDUE             = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m" );
                    
                    const pathReferenceVerbaleCessioneProprietaPrincipale       = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneProprietaSecondariaUNO       = ref( storage, "pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocataUno.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneProprietaSecondariaDUE       = ref( storage, "pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocataDue.codiceInfratel + ".pdf.p7m" );
    
                    //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
        
                    
                    let filesToDownload = [
                        pathReferenceNT9Principale, 
                        pathReferenceNT9SecondariaUNO, 
                        pathReferenceNT9SecondariaDUE,
                        //pathReferencePDFPlanimetriaPrincipale,
                        pathReferenceVerbaleCessioneProprietaPrincipale,
                        pathReferenceRET,
                        pathReferencePSI,
                        pathReferenceCMT,
                        pathReferenceAllegatoIRUPrincipale, 
                        pathReferenceAllegatoIRUSecondariaUNO, 
                        pathReferenceAllegatoIRUSecondariaDUE,
                        pathReferenceVerbaleCessioneIRUPrincipale,
                        pathReferenceVerbaleCessioneIRUSecondariaUNO,
                        pathReferenceVerbaleCessioneIRUSecondariaDUE,
                        pathReferenceVerbaleCessioneProprietaPrincipale
                      ];
                      
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 

                     /*  if(pratica.pdfPlanimetriaUploaded ){
                       // filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                      }else{
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                           
                      }   */
                      this.logger.info(pratica.codiceInfratel + " ha colocate in stesso cpe? " + scuola.cpeInStessoLocale);

                      if(!scuola.cpeInStessoLocale){
                        filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondariaUNO);
                        filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondariaDUE);

                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaUNO);    
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaDUE);   
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaDUE);    
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaUNO);   

                       /*  if(praticaColocata.pdfPlanimetriaUploaded){
                          filesToDownload.push(pathReferencePDFPlanimetriaSecondariaUNO);
                          filesToDownload.push(pathReferencePDFPlanimetriaSecondariaDUE);
                        }else{
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompletaUNO);    
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaDUE);    

                        } */
                       
                      }
                     
                    const downloadPromises = [];
                    for (const pathRef of filesToDownload) {
                      
                      try {
                        
                        const stringaPath = pathRef.fullPath;
                        const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                        const match = regex.exec(stringaPath);
                        const codiceInfratel = match ? match[1] : null;
                        const blob = await getBlob(pathRef);
                        if (blob) {
                          if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                            zip.file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                            this.logger.info(pratica.codiceInfratel + " NT9  COLOCATA OK!");

                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataUno.codiceInfratel +  "/IN/nt9.zip"){
                            

                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataUno.codiceInfratel.substring(praticaColocataUno.codiceInfratel.length-15,praticaColocataUno.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataDue.codiceInfratel +  "/IN/nt9.zip"){
                            
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataDue.codiceInfratel.substring(praticaColocataDue.codiceInfratel.length-15,praticaColocataDue.codiceInfratel.length)+".zip", blob);
                          } else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUno.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocataUno.codiceInfratel + ".pdf";
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            //zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDue.codiceInfratel + ".pdf"){
                            let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocataDue.codiceInfratel + ".pdf";
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                            //zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                            //zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);

                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                              //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                              let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m"){
                            //zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                            let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocataUno.codiceInfratel + ".pdf.p7m";
                              zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m"){
                            //zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                            let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocataDue.codiceInfratel + ".pdf.p7m";
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUno.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDue.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(pratica.codiceInfratel + " PLANIMETRIA PRINCIPALE OK!");
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(praticaColocataUno.codiceInfratel + " PLANIMETRIA PRINCIPALE COLOCATA UNO!");
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria){
                            this.logger.info(praticaColocataDue.codiceInfratel + " PLANIMETRIA PRINCIPALE COLOCATA DUE!");
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocataUno.codiceInfratel+".pdf.p7m"){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocataDue.codiceInfratel+".pdf.p7m"){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf"){
                            zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataUno.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf"){
                            zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocataDue.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                          } 
                         
                          downloadPromises.push(Promise.resolve());
                          
                        } else {
                          this.logger.info(`Errore durante il download di ${pathRef.name}`);
                        }  
                      } catch (error) {
                        this.isLoadingResults = false;
                        if (error.code === 'storage/object-not-found') {
                          this.logger.error('Il file non esiste:', error.message);
                         
                        } else {
                          // Gestisci altri tipi di errori
                          this.logger.error('Errore sconosciuto:', error);
                        }
                      }
        
                    }
                    
                    Promise.all(downloadPromises)
                    .then(() => {
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(praticaColocataUno.codiceInfratel+"/"+praticaColocataUno.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(praticaColocataDue.codiceInfratel+"/"+praticaColocataDue.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      // Crea il File zip e avvia il download:
                      zip.generateAsync({ type: 'blob' })
                        .then((blob) => {
                          saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                        })
                        .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                    })
                    .catch(error => this.logger.info('Errore durante il download dei File:', error));
                    this.isLoadingResults = false;

               
              }else{
                this.isLoadingResults = false;
              }
            
           });
            
        
      });
    });
  }


  async openScaricaPacchettoPraticaColocata(pratica: PraticaScuola_Firestore, isBulk:boolean) {
    this.logger.info("---> SCARICO TUTTO IL PACCHETTO DELLA PRATICA COLOCATA "+pratica.codiceInfratel + " con codiceInvio "+ pratica.codiceInvio);

    if(!isBulk){
      this.logger.info("---> NON SONO BULK");

      if(pratica.tipoSedeEffettiva === 'D'){
        this.logger.info("---> SONO TIPO D");
        this.scaricaPacchettoPraticaD_COLOCATA(pratica);
      }else{
        this.logger.info("---> NON SONO TIPO D");
        this.praticheScuolaService
        .getById(pratica.id.toString())
        .subscribe((praticaDaDB) => {
        let praticaScuola:PraticaScuola = praticaDaDB as PraticaScuola;
        this.confirmationService.confirm({
          message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
              this.isLoadingResults = true;
              
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              //  this.logger.info("---> risultato della get colocate? "+ qs.size);
              if( qs.size==1){
                this.logger.info("---> COLOCATA NON D E NORMALE (#"+ qs.size+")");
                let praticaColocata:PraticaScuola_Firestore = null;
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                //const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                
                //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );
    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    //pathReferencePDFPlanimetriaPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT
                   
                   
                    
                  ];
                if(pratica.tipoSedeEffettiva!=='C'){
                  const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                  const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                  const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                  const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                
                  const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                  const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );

                  filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
                  filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
                  filesToDownload.push(pathReferenceVerbaleCessioneIRUPrincipale);
                  filesToDownload.push(pathReferenceVerbaleCessioneIRUSecondaria);
                  filesToDownload.push(pathReferenceVerbaleCessioneProprietaPrincipale);
                  filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
                 
                }

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);

                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocata.codiceInfratel + ".pdf";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                        //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                        
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".pdf.p7m";
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                        
                        //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      }
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;
              }else if(qs.size==2){
                this.logger.info("---> COLOCATA NON D E TRIPLA (#"+ qs.size+")");
                let praticaColocataUNO = qs.docs[0].data() as PraticaScuola_Firestore;
                let praticaColocataDUE = qs.docs[1].data() as PraticaScuola_Firestore;

                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Terziaria                    = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip");
                //const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataUNO.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaTerziaria         = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataDUE.codiceInfratel+".pdf");
                
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
               
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    pathReferenceNT9Terziaria,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT
                    
                  ];
                  if(pratica.tipoSedeEffettiva!=='C'){
                    const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUTerziaria            = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf" );
                    
                    const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUTerziaria     = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m" );
              
      
                    filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
                    filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
                    filesToDownload.push(pathReferenceAllegatoIRUTerziaria);
                    filesToDownload.push(pathReferenceVerbaleCessioneIRUPrincipale);
                    filesToDownload.push(pathReferenceVerbaleCessioneIRUSecondaria);
                    filesToDownload.push(pathReferenceVerbaleCessioneIRUTerziaria);
                  }

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  //this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                       // NT9 INIZIO
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataUNO.codiceInfratel.substring(praticaColocataUNO.codiceInfratel.length-15,praticaColocataUNO.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataDUE.codiceInfratel.substring(praticaColocataDUE.codiceInfratel.length-15,praticaColocataDUE.codiceInfratel.length)+".zip", blob);
                      // NT9 FINE - INIZIO ALLEGATO CONTRATTO IRU
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocataUNO.codiceInfratel + ".pdf";
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                        //zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf"){
                        let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocataDUE.codiceInfratel + ".pdf";
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                        //zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +praticaColocataUNO.codiceInfratel + ".pdf";
                        zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                       // zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m"){
                        let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +praticaColocataDUE.codiceInfratel + ".pdf";
                        zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                    
                      }
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");

                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  
                 
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;


              }else{
                this.isLoadingResults = false;
              }
            
           });
            
          },
          reject: () => {
          },
        });
      });
      }
      
    
  }else{
    this.logger.info(pratica.codiceInfratel + " SCARICAMENTO IN BULK");
    if(pratica.tipoSedeEffettiva !== 'D'){
      this.isLoadingResults = true;
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaDaDB) => {
        let praticaScuola:PraticaScuola = praticaDaDB as PraticaScuola;
      let praticaColocata:PraticaScuola_Firestore = null;
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
      onSnapshot(query, async (qs) => {
      
      if( qs.size==1){
        this.logger.info(pratica.codiceInfratel + " HA 1 SOLA COLOCATA");

        praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
        const storage = getStorage();
        const zip = new JSZip();
        const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
        const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
        const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
        const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
        const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
        const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
        const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                
        const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
        const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
        const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
        const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
        
        const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
        const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );

        
        let filesToDownload = [
            pathReferenceNT9Principale, 
            pathReferenceNT9Secondaria, 
            pathReferenceRET,
            pathReferencePSI,
            pathReferenceCMT,
            
          ];
          if(pratica.tipoSedeEffettiva != 'C'){
            filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
            filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
            filesToDownload.push(pathReferenceVerbaleCessioneIRUPrincipale);
            filesToDownload.push(pathReferenceVerbaleCessioneIRUSecondaria);
          }
          if(pratica.tipoSedeEffettiva === 'D'){
            filesToDownload.push(pathReferenceVerbaleCessioneProprietaPrincipale);
            filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);
            if(!praticaScuola.scuola.cpeInStessoLocale){
              filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
              filesToDownload.push(pathReferencePDFPlanimetriaSecondaria);
            }
          } 

       
    
        const downloadPromises = [];
        for (const pathRef of filesToDownload) {
          //this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
          try {
            const blob = await getBlob(pathRef);
            if (blob) {
              if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                zip.file(pathRef.name, blob);
              }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
              }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
              }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +pratica.codiceInfratel + ".pdf";
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                     
                //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
              }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocata.codiceInfratel + ".pdf";
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                
                //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
              }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                let fileNameSUBIRU:string = "Contratto_Cessione_IRU_" +praticaColocata.codiceInfratel + ".pdf.p7m";
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                 
                //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
             
              }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                let fileNameSUBIRU:string = "Contratto_Cessione_IRU_" +praticaColocata.codiceInfratel + ".pdf.p7m";
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                
                //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
              }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
              }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
              }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
              }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
              }
              
              downloadPromises.push(Promise.resolve());
              
            } else {
              this.logger.info(`Errore durante il download di ${pathRef.name}`);
            }  
          } catch (error) {
            this.isLoadingResults = false;
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
             
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }

        }
        
        Promise.all(downloadPromises)
        .then(() => {
          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
          // Crea il File zip e avvia il download:
          zip.generateAsync({ type: 'blob' })
            .then((blob) => {
              saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
            })
            .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
        })
        .catch(error => this.logger.info('Errore durante il download dei File:', error));
        this.isLoadingResults = false;
      }else{
        this.logger.info(pratica.codiceInfratel + " E' TRIPLA COLOCATA!");
       
          let praticaColocataUNO = qs.docs[0].data() as PraticaScuola_Firestore;
          let praticaColocataDUE = qs.docs[1].data() as PraticaScuola_Firestore;

          const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceNT9Terziaria                    = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip");
          //const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
          //const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataUNO.codiceInfratel+".pdf");
          //const pathReferencePDFPlanimetriaTerziaria         = ref( storage, "pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocataDUE.codiceInfratel+".pdf");
          
          const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
          const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
          const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
 
         
          let filesToDownload = [
              pathReferenceNT9Principale, 
              pathReferenceNT9Secondaria, 
              pathReferenceNT9Terziaria,
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceCMT
              
            ];
            if(pratica.tipoSedeEffettiva!=='C'){
              const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
              const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf" );
              const pathReferenceAllegatoIRUTerziaria            = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf" );
              
              const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
              const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m" );
              const pathReferenceVerbaleCessioneIRUTerziaria     = ref( storage, "pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m" );
        

              filesToDownload.push(pathReferenceAllegatoIRUPrincipale);
              filesToDownload.push(pathReferenceAllegatoIRUSecondaria);
              filesToDownload.push(pathReferenceAllegatoIRUTerziaria);
              filesToDownload.push(pathReferenceVerbaleCessioneIRUPrincipale);
              filesToDownload.push(pathReferenceVerbaleCessioneIRUSecondaria);
              filesToDownload.push(pathReferenceVerbaleCessioneIRUTerziaria);
            }

         
          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            //this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
              
                if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                  zip.file(pathRef.name, blob);
                 // NT9 INIZIO
                }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataUNO.codiceInfratel +  "/IN/nt9.zip"){
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataUNO.codiceInfratel.substring(praticaColocataUNO.codiceInfratel.length-15,praticaColocataUNO.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocataDUE.codiceInfratel +  "/IN/nt9.zip"){
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocataDUE.codiceInfratel.substring(praticaColocataDUE.codiceInfratel.length-15,praticaColocataDUE.codiceInfratel.length)+".zip", blob);
                // NT9 FINE - INIZIO ALLEGATO CONTRATTO IRU
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                  let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +pratica.codiceInfratel + ".pdf";
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataUNO.codiceInfratel + ".pdf"){
                  let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocataUNO.codiceInfratel + ".pdf";
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
                  //zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocataDUE.codiceInfratel + ".pdf"){
                  let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" +praticaColocataDUE.codiceInfratel + ".pdf";
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
                  //zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                  let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +pratica.codiceInfratel + ".pdf";
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataUNO.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataUNO.codiceInfratel + ".pdf.p7m"){
                  let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +praticaColocataUNO.codiceInfratel + ".pdf";
                  zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
                 // zip.folder(praticaColocataUNO.codiceInfratel+"/"+praticaColocataUNO.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                
                }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocataDUE.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocataDUE.codiceInfratel + ".pdf.p7m"){
                  let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU__" +praticaColocataDUE.codiceInfratel + ".pdf";
                  zip.folder(praticaColocataDUE.codiceInfratel+"/"+praticaColocataDUE.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
               
              
                }
                downloadPromises.push(Promise.resolve());
                
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }  
            } catch (error) {
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.error('Il file non esiste:', error.message);
               
              } else {
                // Gestisci altri tipi di errori
                this.logger.error('Errore sconosciuto:', error);
              }
            }

          }
          this.logger.debug(pratica.codiceInfratel + ' FATTO TUTTO ');
          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(praticaColocataUNO.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");

            zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(praticaColocataDUE.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            
           
            // Crea il File zip e avvia il download:
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                this.logger.debug(pratica.codiceInfratel + ' SCARICATO TUTTO ');

              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error));
          this.isLoadingResults = false;

      }
    });
    });
    }else{
      this.logger.info(  "--> DENTRO ELSE " + pratica.codiceInfratel );

      this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
        this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{

                this.isLoadingResults = true;
                let praticaColocata:PraticaScuola_Firestore = null;
                const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
                const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
                onSnapshot(query, async (qs) => {
                
                if( qs.size==1){
                  praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                  const storage = getStorage();
                  const zip = new JSZip();
                  const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                  const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                  const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                  const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                  const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                  const pathReferenceImmaginePlanimetriaSecondariaCompleta    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria);
                  const pathReferenceImmaginePlanimetriaPrincipale    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                  const pathReferenceImmaginePlanimetriaSecondaria    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                  
                  const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                  const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                  const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
         
                  const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                  const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                  const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                  const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                  
                  const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                  const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
        
                  
                  let filesToDownload = [
                      pathReferenceNT9Principale, 
                      pathReferenceNT9Secondaria, 
                      pathReferencePDFPlanimetriaPrincipale,
                      pathReferenceVerbaleCessioneProprietaPrincipale,
                      pathReferenceRET,
                      pathReferencePSI,
                      pathReferenceCMT,
                      pathReferenceAllegatoIRUPrincipale, 
                      pathReferenceAllegatoIRUSecondaria, 
                      pathReferenceVerbaleCessioneIRUPrincipale,
                      pathReferenceVerbaleCessioneIRUSecondaria,
                      pathReferenceVerbaleCessioneProprietaPrincipale
                    ];
  
                    if(pratica.pdfPlanimetriaUploaded ){
                      filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                    }else{
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                      filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                         
                    }  
                    if(!scuola.cpeInStessoLocale){
                      filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
                      if(praticaColocata.pdfPlanimetriaUploaded){
                        filesToDownload.push(pathReferencePDFPlanimetriaSecondaria);
                      }else{
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondaria);    
                        filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    
                      }
                     
                    }
                   
                  const downloadPromises = [];
                  for (const pathRef of filesToDownload) {
                    
                    try {
                      
                      const stringaPath = pathRef.fullPath;
                      const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                      const match = regex.exec(stringaPath);
                      const codiceInfratel = match ? match[1] : null;
                      //this.logger.info("CERCO IL FILE: " + pathRef.name + " @ " + pathRef.fullPath + " > codiceInfratel " + codiceInfratel);
                      const blob = await getBlob(pathRef);
                      if (blob) {
                      
                        if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                          zip.file(pathRef.name, blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                          let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          
                          //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                          let fileNameSUBIRU:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocata.codiceInfratel + ".pdf";
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          
                          //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                          let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                          
                          //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        
                        }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                          let fileNameSUBIRU:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".pdf.p7m";
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(fileNameSUBIRU, blob);
                         
                         // zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                        } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                        }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                        }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                        }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                        }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                        }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                        }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                        } 
                        
                       
                        downloadPromises.push(Promise.resolve());
                        
                      } else {
                        this.logger.info(`Errore durante il download di ${pathRef.name}`);
                      }  
                    } catch (error) {
                      this.isLoadingResults = false;
                      if (error.code === 'storage/object-not-found') {
                        this.logger.error('Il file non esiste:', error.message);
                        
                      } else {
                        // Gestisci altri tipi di errori
                        this.logger.error('Errore sconosciuto:', error);
                      }
                    }
      
                  }
                  
                  Promise.all(downloadPromises)
                  .then(() => {
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                    zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                    zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                    zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                    // Crea il File zip e avvia il download:
                    zip.generateAsync({ type: 'blob' })
                      .then((blob) => {
                        saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                      })
                      .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                  })
                  .catch(error => this.logger.info('Errore durante il download dei File:', error));
                  this.isLoadingResults = false;
                }else{
                  this.isLoadingResults = false;
                }
              
             });
              
         
        });
      });
    } 
      
  }
    

  }

  async openScaricaPacchettoFirmatoPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO IL PACCHETTO FIRMATO DELLA PRATICA "+pratica.codiceInfratel);
    if(!this.isApproved(pratica)){
      this.confirmationService.confirm({
        message: `Confermi di voler procedere con lo scaricamento del pacchetto della pratica <b>${pratica.codiceInfratel}</b>? L'operazione indicherà che <u>la pratica è stata inoltrata al cliente</u>!`,
        header: "Scarica pacchetto firmato",
        icon: "pi pi-file-import",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => {
          this.isLoadingResults = true;
          const storage = getStorage();
          let nomePacchetto:string = pratica.codiceInvio.startsWith(pratica.codiceRegione.valueOf()+"-")?  pratica.codiceInvio.valueOf():pratica.codiceRegione+"-"+pratica.codiceInvio;
  
          // const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+pratica.codiceInvio+".zip.p7m");
          const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+nomePacchetto+".zip.p7m");
            
          let filesToDownload = [
            pathReferenceSignedZip,  
            ];
            
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                this.logger.info(pathRef.name); 
                saveAs(blob, pathRef.name); 
                this.praticheScuolaService.pubblicazionePratica(pratica.id).subscribe(res=>{});
                this.isLoadingResults = false;
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
    }else{
      this.isLoadingResults = true;
          const storage = getStorage();
          this.logger.info("REGIONE del pacchetto da scaricare > "+ pratica.codiceRegione + " Vs "+ pratica.codiceInvio);
          let nomePacchetto:string = pratica.codiceInvio.startsWith(pratica.codiceRegione.valueOf()+"-")?  pratica.codiceInvio.valueOf():pratica.codiceRegione+"-"+pratica.codiceInvio;
          this.logger.info("scarico il pacchetto chiamato > "+ nomePacchetto);
  
          // const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+pratica.codiceInvio+".zip.p7m");
          const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+nomePacchetto+".zip.p7m");
            
          let filesToDownload = [
            pathReferenceSignedZip,  
            ];
            
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                this.logger.info(pathRef.name); 
                saveAs(blob, pathRef.name); 
                this.praticheScuolaService.pubblicazionePratica(pratica.id).subscribe(res=>{
                });
                this.isLoadingResults = false;
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
    }
    
         

  }

  async presidioSFScaricaPacchettoFirmato(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO IL PACCHETTO FIRMATO DELLA PRATICA "+pratica.codiceInfratel);
  
          this.isLoadingResults = true;
          const storage = getStorage();
          let nomePacchetto:string = pratica.codiceInvio.startsWith(pratica.codiceRegione.valueOf()+"-")?  pratica.codiceInvio.valueOf():pratica.codiceRegione+"-"+pratica.codiceInvio;
  
          const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+nomePacchetto+".zip.p7m");
            
          let filesToDownload = [
            pathReferenceSignedZip,  
            ];
            
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                this.logger.info(pathRef.name); 
                saveAs(blob, pathRef.name); 
                this.isLoadingResults = false;
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }

  }
  async scaricaPacchettoPraticaApprovata_D_SINGOLA(pratica: PraticaScuola_Firestore){
    this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
      this.scuoleService.getById(praticadDTO.scuola.id).subscribe(async scuola =>{
        this.logger.info("scaricaPacchettoPraticaD > ESTENSIONE PLANIMETRIA @ SCUOLA: "+ scuola.estensionePlanimetria);
       
            this.isLoadingResults = true;
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
            const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
            const pathReferenceImmaginePlanimetriaCompleta       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria);
            const pathReferenceImmaginePlanimetria       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria"+"."+scuola.estensionePlanimetria);
  
            const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
            const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
            const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            const pathReferenceVerbaleCessioneProprieta2  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            
            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceCMT,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
                pathReferenceVerbaleCessioneProprieta,
                pathReferenceVerbaleCessioneProprieta2
              ];
              
            
             if(pratica.pdfPlanimetriaUploaded){
                filesToDownload.push(pathReferencePDFPlanimetria);
              }else{
                filesToDownload.push(pathReferenceImmaginePlanimetria);
              }
              filesToDownload.push(pathReferenceImmaginePlanimetriaCompleta);
           
  
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  if(pathRef.name==='nt9.zip'){ 
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                  }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                    || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  ){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                  } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
                } else {
                  this.logger.info(`Errore durante il download di ${pathRef.name}`);
                }
              }catch(error){
                this.isLoadingResults = false;
                if (error.code === 'storage/object-not-found') {
                  this.logger.error('Il file non esiste:', error.message);
                  /* this.messageService.add({
                    severity: "info",
                  summary: "Errore" , life:10000,
                    detail: "Il file "+ error.message+" non esiste",
                  }); */
                } else {
                  // Gestisci altri tipi di errori
                  this.logger.error('Errore sconosciuto:', error);
                }
              }
            }
    
            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              // Crea il File zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
    
            this.isLoadingResults = false;
            
       
      });
    });
  }


  async scaricaPacchettoPraticaD_SINGOLA(pratica: PraticaScuola_Firestore){
    this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
      this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{
        this.logger.info("scaricaPacchettoPraticaD > ESTENSIONE PLANIMETRIA @ SCUOLA: "+ scuola.estensionePlanimetria);
        this.confirmationService.confirm({
          message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
            this.isLoadingResults = true;
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
            const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
            const pathReferenceImmaginePlanimetriaCompleta       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria);
            const pathReferenceImmaginePlanimetria       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria"+"."+scuola.estensionePlanimetria);
  
            const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
            const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
            const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            const pathReferenceVerbaleCessioneProprieta2  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            
            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceCMT,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
                pathReferenceVerbaleCessioneProprieta,
                pathReferenceVerbaleCessioneProprieta2
              ];
              
            
             if(pratica.pdfPlanimetriaUploaded){
                filesToDownload.push(pathReferencePDFPlanimetria);
              }else{
                filesToDownload.push(pathReferenceImmaginePlanimetria);
              }
              filesToDownload.push(pathReferenceImmaginePlanimetriaCompleta);
           
  
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  if(pathRef.name==='nt9.zip'){ 
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                  }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                    || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  ){
                    if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                      let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                      let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }
                    //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                  } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
                } else {
                  this.logger.info(`Errore durante il download di ${pathRef.name}`);
                }
              }catch(error){
                this.isLoadingResults = false;
                if (error.code === 'storage/object-not-found') {
                  this.logger.error('Il file non esiste:', error.message);
                  /* this.messageService.add({
                    severity: "info",
                  summary: "Errore" , life:10000,
                    detail: "Il file "+ error.message+" non esiste",
                  }); */
                } else {
                  // Gestisci altri tipi di errori
                  this.logger.error('Errore sconosciuto:', error);
                }
              }
            }
    
            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              // Crea il File zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
    
            this.isLoadingResults = false;
            
          },
          reject: () => {
          },
        });
      });
    });
  }


  async presidioSFScaricaPacchettoPratica(pratica: PraticaScuola_Firestore) {

    if(!pratica.colocata || (pratica.colocata && pratica.colocataConCodiceInvioDifferente)){
      if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
        this.logger.info("---> PRESIDIO SCARICA PACCHETTO DELLA PRATICA DI TIPO D SINGOLA: "+pratica.codiceInfratel);

        this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
          this.scuoleService.getById(praticadDTO.scuola.id).subscribe(async scuola =>{
            this.isLoadingResults = true;
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
            const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
            const pathReferenceImmaginePlanimetriaCompleta   = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria);
            const pathReferenceImmaginePlanimetria           = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria"+"."+scuola.estensionePlanimetria);
  
            const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
            const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
            const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            const pathReferenceVerbaleCessioneProprieta2  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
            
            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceCMT,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
                pathReferenceVerbaleCessioneProprieta,
                pathReferenceVerbaleCessioneProprieta2,
                pathReferenceImmaginePlanimetriaCompleta
              ];
              
            
             if(pratica.pdfPlanimetriaUploaded){
                filesToDownload.push(pathReferencePDFPlanimetria);
              }else{
                filesToDownload.push(pathReferenceImmaginePlanimetria);
              }
  
           
  
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  if(pathRef.name==='nt9.zip'){ 
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                  }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                    || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  ){
                    if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                      let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                      let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }
                    //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                  }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                  } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
                } else {
                  this.logger.info(`Errore durante il download di ${pathRef.name}`);
                }
              }catch(error){
                this.isLoadingResults = false;
                if (error.code === 'storage/object-not-found') {
                  this.logger.error('Il file non esiste:', error.message);
                 
                } else {
                  this.logger.error('Errore sconosciuto:', error);
                }
              }
            }
    
            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
    
            this.isLoadingResults = false;
          });
        });
    }else{
      this.logger.info("---> PRESIDIO SCARICA PACCHETTO DELLA PRATICA NON DI TIPO D SINGOLA: "+pratica.codiceInfratel);

      this.isLoadingResults = true;
      const storage = getStorage();
      const zip = new JSZip();
      const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
      const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
      const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");

      const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
      const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
      const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
      let filesToDownload = [
          pathReferenceNT9, 
          pathReferenceRET,
          pathReferencePSI,
          pathReferenceCMT,
          pathReferenceAllegatoIRU, 
          pathReferenceVerbaleCessioneIRU
        ];
        
      const downloadPromises = [];
      for (const pathRef of filesToDownload) {
        try {
          const blob = await getBlob(pathRef);
          if (blob) {
            if(pathRef.name==='nt9.zip'){ 
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
            }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
              || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
            ){
              if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
              }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
              }
              //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
            }else if(pathRef.name ==='Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
            } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
            } else {
              zip.file(pathRef.name, blob);
            }
            
            downloadPromises.push(Promise.resolve());
          } else {
            this.logger.info(`Errore durante il download di ${pathRef.name}`);
          }
        }catch(error){
          this.isLoadingResults = false;
          if (error.code === 'storage/object-not-found') {
            this.logger.error('Il file non esiste:', error.message);
          } else {
            this.logger.error('Errore sconosciuto:', error);
          }
        }
      }

      Promise.all(downloadPromises)
      .then(() => {
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
        zip.generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
          })
          .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
      })
      .catch(error => this.logger.info('Errore durante il download dei File:', error));

      this.isLoadingResults = false;
    }
    }else{
      if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
        this.logger.info("---> PRESIDIO SCARICA PACCHETTO DELLA PRATICA COLOCATA DI TIPO D: "+pratica.codiceInfratel);
        this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
          this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{
          this.isLoadingResults = true;
                  let praticaColocata:PraticaScuola_Firestore = null;
                  const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
                  const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
                  onSnapshot(query, async (qs) => {
                  
                  if( qs.size==1){
                    praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                    const storage = getStorage();
                    const zip = new JSZip();
                    const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                    const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                    const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                    const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondariaCompleta    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaPrincipale    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                    const pathReferenceImmaginePlanimetriaSecondaria    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                    
                    const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                    const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                    const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
           
                    const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                    const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                    const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                    
                    const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                    const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
          
              
                    let filesToDownload = [
                        pathReferenceNT9Principale, 
                        pathReferenceNT9Secondaria, 
                        pathReferencePDFPlanimetriaPrincipale,
                        pathReferenceVerbaleCessioneProprietaPrincipale,
                        pathReferenceRET,
                        pathReferencePSI,
                        pathReferenceCMT,
                        pathReferenceAllegatoIRUPrincipale, 
                        pathReferenceAllegatoIRUSecondaria, 
                        pathReferenceVerbaleCessioneIRUPrincipale,
                        pathReferenceVerbaleCessioneIRUSecondaria,
                        pathReferenceVerbaleCessioneProprietaPrincipale
                      ];
    
                      if(pratica.pdfPlanimetriaUploaded ){
                        filesToDownload.push(pathReferencePDFPlanimetriaPrincipale);    
                      }else{
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipale); 
                        filesToDownload.push(pathReferenceImmaginePlanimetriaPrincipaleCompleta); 
                           
                      }  
                      if(!scuola.cpeInStessoLocale){
                        filesToDownload.push(pathReferenceVerbaleCessioneProprietaSecondaria);
                        if(praticaColocata.pdfPlanimetriaUploaded){
                          filesToDownload.push(pathReferencePDFPlanimetriaSecondaria);
                        }else{
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondaria);    
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    
                        }
                       
                      }
                     
                    const downloadPromises = [];
                    for (const pathRef of filesToDownload) {
                      
                      try {
                        
                        const stringaPath = pathRef.fullPath;
                        const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                        const match = regex.exec(stringaPath);
                        const codiceInfratel = match ? match[1] : null;
                        const blob = await getBlob(pathRef);
                        if (blob) {
                        
                          if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                            zip.file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                              let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                              //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                              let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+praticaColocata.codiceInfratel+'.pdf';
                              zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                              //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                              let subIRUFileName:string = "Contratto_Cessione_SUBIRU_"+pratica.codiceInfratel+ ".pdf.p7m";
                              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                              let subIRUFileName:string = "Contratto_Cessione_SUBIRU_"+praticaColocata.codiceInfratel+ ".pdf.p7m";
                              zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                              //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                            zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                          }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                            zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                          } 
                          downloadPromises.push(Promise.resolve());
                          
                        } else {
                          this.logger.info(`Errore durante il download di ${pathRef.name}`);
                        }  
                      } catch (error) {
                        this.isLoadingResults = false;
                        if (error.code === 'storage/object-not-found') {
                          this.logger.error('Il file non esiste:', error.message);
                        
                        } else {
                          // Gestisci altri tipi di errori
                          this.logger.error('Errore sconosciuto:', error);
                        }
                      }
        
                    }
                    
                    Promise.all(downloadPromises)
                    .then(() => {
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                      zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                      zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                      // Crea il File zip e avvia il download:
                      zip.generateAsync({ type: 'blob' })
                        .then((blob) => {
                          saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                        })
                        .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                    })
                    .catch(error => this.logger.info('Errore durante il download dei File:', error));
                    this.isLoadingResults = false;
                  }else{
                    this.isLoadingResults = false;
                  }
                
               });
           });
          });
      }else{
        this.logger.info("---> PRESIDIO SCARICA PACCHETTO DELLA PRATICA COLOCATA DI TIPO NON D: "+pratica.codiceInfratel);

        this.praticheScuolaService
        .getById(pratica.id.toString())
        .subscribe((praticaDaDB) => {
          let praticaScuola:PraticaScuola = praticaDaDB as PraticaScuola;
          this.isLoadingResults = true;
              let praticaColocata:PraticaScuola_Firestore = null;
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              
              if( qs.size==1){
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                //const pathReferencePDFPlanimetriaPrincipale         = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                //const pathReferencePDFPlanimetriaSecondaria         = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf");
                
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       
                const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
                const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
                
                //const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                //const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".pdf.p7m" );    
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    //pathReferencePDFPlanimetriaPrincipale,
                   // pathReferenceVerbaleCessioneProprietaPrincipale,
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                    pathReferenceVerbaleCessioneIRUPrincipale,
                    pathReferenceVerbaleCessioneIRUSecondaria
                  ];

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                        //  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                        let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                         // zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocata.codiceInfratel + ".pdf";
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                       
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                        let subIRUFileName:string = "Contratto_Cessione_IRU_" + pratica.codiceInfratel  + ".pdf.p7m";
  
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                      //  zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      let subIRUFileName:string = "Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel  + ".pdf.p7m";

                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".pdf.p7m"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".pdf", blob);
                      }
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;
              }else{
                this.isLoadingResults = false;
                this.logger.info("Pratica MULTICOLOCATA!");
              }
            
           });
        });



      }
      


      //this.openScaricaPacchettoPraticaColocata(pratica, isBulk);
    }


    
  }

  async presidioTIMScaricaPacchettoPratica(pratica: PraticaScuola_Firestore) {
    this.confirmationService.confirm({
      message: `Stai per scaricare una versione incompleta del pacchetto (senza il computo metrico ed i contratti di cessione IRU e proprietà) e con tutti i documenti in formato DOCX!`,
      header: "ATTENZIONE!",
      icon: "pi pi-exclamation-circle",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        if(!pratica.colocata || (pratica.colocata && pratica.colocataFake)){
          if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
            this.logger.info("---> PRESIDIO TIM SCARICA PACCHETTO DELLA PRATICA DI TIPO D SINGOLA: "+pratica.codiceInfratel);

            this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
              this.scuoleService.getById(praticadDTO.scuola.id).subscribe(async scuola =>{
                this.isLoadingResults = true;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceRET                           = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.docx");
                const pathReferencePSI                           = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.docx");
                const pathReferenceImmaginePlanimetriaCompleta   = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetria           = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria"+"."+scuola.estensionePlanimetria);
      
                const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
               // const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx" );  
               // const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".docx" );
               // const pathReferenceVerbaleCessioneProprieta2  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".docx" );
                
                let filesToDownload = [
                    pathReferenceNT9, 
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceAllegatoIRU, 
               //     pathReferenceVerbaleCessioneIRU,
               //     pathReferenceVerbaleCessioneProprieta,
                //    pathReferenceVerbaleCessioneProprieta2,
                    pathReferenceImmaginePlanimetriaCompleta,
                    pathReferenceImmaginePlanimetria
                  ];
                  
               
      
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                      if(pathRef.name==='nt9.zip'){ 
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.docx' 
                        || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".docx"
                      ){
                        if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                          let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                          let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        }
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".docx"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.docx', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".docx"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.docx', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".docx"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".docx", blob);
                      } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else {
                        zip.file(pathRef.name, blob);
                      }
                      
                      downloadPromises.push(Promise.resolve());
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }
                  }catch(error){
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
                }
        
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
        
                this.isLoadingResults = false;
              });
            });
          }else{
            this.logger.info("---> PRESIDIO TIM SCARICA PACCHETTO DELLA PRATICA NON DI TIPO D SINGOLA: "+pratica.codiceInfratel);

          this.isLoadingResults = true;
          const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.docx");
          const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.docx");

          const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
        //  const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx" );  
          let filesToDownload = [
              pathReferenceNT9, 
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceAllegatoIRU, 
        //      pathReferenceVerbaleCessioneIRU
            ];
            
          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                if(pathRef.name==='nt9.zip'){ 
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.docx'   || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".docx"){
                  if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                    let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                  }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                    let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                  }
                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else if(pathRef.name ==='Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_'+pratica.codiceInfratel+".docx"){
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.docx', blob);
                } else {
                  zip.file(pathRef.name, blob);
                }
                
                downloadPromises.push(Promise.resolve());
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.error('Il file non esiste:', error.message);
              } else {
                this.logger.error('Errore sconosciuto:', error);
              }
            }
          }

          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error));

          this.isLoadingResults = false;
          }
        }else{
          if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
            this.logger.info("---> PRESIDIO TIM SCARICA PACCHETTO DELLA PRATICA COLOCATA DI TIPO D: "+pratica.codiceInfratel);
            this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
              this.scuoleService.getById(praticadDTO.scuola.id).subscribe(scuola =>{
              this.isLoadingResults = true;
                      let praticaColocata:PraticaScuola_Firestore = null;
                      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
                      const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
                      onSnapshot(query, async (qs) => {
                      
                      if( qs.size==1){
                        praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                        const storage = getStorage();
                        const zip = new JSZip();
                        const pathReferenceNT9Principale                            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                        const pathReferenceNT9Secondaria                            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                       // const pathReferencePDFPlanimetriaPrincipale                 = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".docx");
                       // const pathReferencePDFPlanimetriaSecondaria                 = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+".docx");
                        const pathReferenceImmaginePlanimetriaPrincipaleCompleta    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"." +scuola.estensionePlanimetria);
                        const pathReferenceImmaginePlanimetriaSecondariaCompleta    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria);
                        const pathReferenceImmaginePlanimetriaPrincipale            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria." +scuola.estensionePlanimetria);
                        const pathReferenceImmaginePlanimetriaSecondaria            = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel  +  "/IN/planimetria."+scuola.estensionePlanimetria);
                        
                        const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.docx");
                        const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.docx");
               
                        const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
                        const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx" );
                      //  const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx" );
                     //   const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".docx" );
                        
                    //    const pathReferenceVerbaleCessioneProprietaPrincipale  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".docx" );
                    //    const pathReferenceVerbaleCessioneProprietaSecondaria  = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + praticaColocata.codiceInfratel + ".docx" );
              
                  
                        let filesToDownload = [
                            pathReferenceNT9Principale, 
                            pathReferenceNT9Secondaria, 
                       //     pathReferencePDFPlanimetriaPrincipale,
                       //     pathReferenceVerbaleCessioneProprietaPrincipale,
                            pathReferenceRET,
                            pathReferencePSI,
                            pathReferenceAllegatoIRUPrincipale, 
                            pathReferenceAllegatoIRUSecondaria, 
                            pathReferenceImmaginePlanimetriaPrincipale,
                            pathReferenceImmaginePlanimetriaPrincipaleCompleta,
                            pathReferenceImmaginePlanimetriaSecondaria,
                            pathReferenceImmaginePlanimetriaSecondariaCompleta
                       //     pathReferenceVerbaleCessioneIRUPrincipale,
                       //     pathReferenceVerbaleCessioneIRUSecondaria,
                       //     pathReferenceVerbaleCessioneProprietaPrincipale
                          ];
        
                         
                        if(!scuola.cpeInStessoLocale){
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondaria);    
                          filesToDownload.push(pathReferenceImmaginePlanimetriaSecondariaCompleta);    
                        }
                         
                        const downloadPromises = [];
                        for (const pathRef of filesToDownload) {
                          
                          try {
                            
                            const stringaPath = pathRef.fullPath;
                            const regex = /\/([^/]+?)\/[^/]+?\/[^/]+$/;
                            const match = regex.exec(stringaPath);
                            const codiceInfratel = match ? match[1] : null;
                            const blob = await getBlob(pathRef);
                            if (blob) {
                            
                              if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.docx' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.docx' ){
                                zip.file(pathRef.name, blob);
                              }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                              }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                              }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx"){
                                let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".docx";
  
                                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      
                                //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                              }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx"){
                                  //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                                  let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_" + praticaColocata.codiceInfratel + ".docx";
  
                                  zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        
                              }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx"){
                                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                                  let subIRUFileName:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".docx";
  
                                  zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        
                                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);

                              }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".docx"){
                                //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                                let subIRUFileName:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".docx";

                                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                              
                              }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/IN/planimetria." + scuola.estensionePlanimetria){
                                zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                              } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                              }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                              }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".docx"){
                                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.docx', blob);
                              }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".docx"){
                                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.docx', blob);
                              }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+"."+scuola.estensionePlanimetria){
                                zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".docx", blob);
                              } 
                              downloadPromises.push(Promise.resolve());
                              
                            } else {
                              this.logger.info(`Errore durante il download di ${pathRef.name}`);
                            }  
                          } catch (error) {
                            this.isLoadingResults = false;
                            if (error.code === 'storage/object-not-found') {
                              this.logger.error('Il file non esiste:', error.message);
                            
                            } else {
                              // Gestisci altri tipi di errori
                              this.logger.error('Errore sconosciuto:', error);
                            }
                          }
            
                        }
                        
                        Promise.all(downloadPromises)
                        .then(() => {
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                          zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                          // Crea il File zip e avvia il download:
                          zip.generateAsync({ type: 'blob' })
                            .then((blob) => {
                              saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                            })
                            .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                        })
                        .catch(error => this.logger.info('Errore durante il download dei File:', error));
                        this.isLoadingResults = false;
                      }else{
                        this.isLoadingResults = false;
                      }
                    
                   });
               });
              });
          }else{
            this.logger.info("---> PRESIDIO TIM SCARICA PACCHETTO DELLA PRATICA COLOCATA DI TIPO NON D: "+pratica.codiceInfratel);

            this.praticheScuolaService
            .getById(pratica.id.toString())
            .subscribe((praticaDaDB) => {

              let praticaScuola:PraticaScuola = praticaDaDB as PraticaScuola;
              this.isLoadingResults = true;
              let praticaColocata:PraticaScuola_Firestore = null;
              const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
              onSnapshot(query, async (qs) => {
              if( qs.size==1){
                praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
                 
                const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.docx");
                const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.docx");
       
                const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
                const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx" );
                //const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx" );
                //const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".docx" );
                
                
                let filesToDownload = [
                    pathReferenceNT9Principale, 
                    pathReferenceNT9Secondaria, 
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceAllegatoIRUPrincipale, 
                    pathReferenceAllegatoIRUSecondaria, 
                //    pathReferenceVerbaleCessioneIRUPrincipale,
                 //   pathReferenceVerbaleCessioneIRUSecondaria
                  ];

               
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                    
                      if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.docx' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.docx' ){
                        zip.file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file("AB_"+praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx"){
                          
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx"){
                          zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".docx"){
                        let subIRUFileName:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".docx";

                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".docx"){
                        let subIRUFileName:string = "Contratto_Cessione_SUBIRU_" + praticaColocata.codiceInfratel + ".docx";

                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                       //zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".docx"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".docx", blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+".docx"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+praticaColocata.codiceInfratel+'.docx', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".docx"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.docx', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+praticaColocata.codiceInfratel+".docx"){
                        zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+praticaColocata.codiceInfratel+".docx", blob);
                      }
                      
                     
                      downloadPromises.push(Promise.resolve());
                      
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }  
                  } catch (error) {
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
    
                }
                
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error));
                this.isLoadingResults = false;
              }else{
                this.isLoadingResults = false;
              }
            
           });
        });
          }
        }
      },reject: () => {
      }
    }
    );

    
    
  }
  async scaricaPacchettoPraticaSingolaApprovata(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SINGOLO SCARICAMENTO DELLA PACCHETTO PRATICA NON COLOCATA "+pratica.codiceInfratel);
    if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
      this.scaricaPacchettoPraticaApprovata_D_SINGOLA(pratica);
    }else{
      this.isLoadingResults = true;
      const storage = getStorage();
      const zip = new JSZip();
      const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
      const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
      const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
      //const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
      const pathReferenceImmaginePlanimetria       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria.png");

      const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
      const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
      const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
      const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
      let filesToDownload = [
          pathReferenceNT9, 
          pathReferenceRET,
          pathReferencePSI,
          pathReferenceCMT,
          pathReferenceAllegatoIRU, 
          pathReferenceVerbaleCessioneIRU
         // ,pathReferenceVerbaleCessioneProprieta
        ];
        
      
       if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
          filesToDownload.push(pathReferenceVerbaleCessioneProprieta);
          filesToDownload.push(pathReferenceImmaginePlanimetria);

        } 

        this.logger.info(filesToDownload);

      const downloadPromises = [];
      for (const pathRef of filesToDownload) {
        try {
          const blob = await getBlob(pathRef);
          if (blob) {
            this.logger.info(pathRef.name); 
            if(pathRef.name==='nt9.zip'){ 
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
            }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
              || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
            ){
              if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                let subIRUFileName:string = "Contratto_Cessione_SUBIRU_" + pratica.codiceInfratel + ".pdf.p7m";

                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
              
              }else{
                let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_" + pratica.codiceInfratel + ".pdf";

                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
              }
             //                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);

            }else if(pathRef.name ==='Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
            } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
            } else {
              zip.file(pathRef.name, blob);
            }
            
            downloadPromises.push(Promise.resolve());
          } else {
            this.logger.info(`Errore durante il download di ${pathRef.name}`);
          }
        }catch(error){
          this.isLoadingResults = false;
          if (error.code === 'storage/object-not-found') {
            this.logger.error('Il file non esiste:', error.message);
            
          } else {
            // Gestisci altri tipi di errori
            this.logger.error('Errore sconosciuto:', error);
          }
        }
      }

      Promise.all(downloadPromises)
      .then(() => {
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
        // Crea il File zip e avvia il download:
        zip.generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
          })
          .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
      })
      .catch(error => this.logger.info('Errore durante il download dei File:', error));

      this.isLoadingResults = false;
    }
  }
  async openScaricaPacchettoPratica(pratica: PraticaScuola_Firestore, isBulk:boolean) {
    //this.logger.info("---> openScaricaPacchettoPratica "+pratica.codiceInfratel);

    if(!isBulk){
      this.logger.info("---> SINGOLO SCARICAMENTO DELLA PACCHETTO PRATICA NON COLOCATA "+pratica.codiceInfratel);
      if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
        this.scaricaPacchettoPraticaD_SINGOLA(pratica);
      } else{
          this.confirmationService.confirm({
            message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
            header: "ATTENZIONE!",
            icon: "pi pi-exclamation-circle",
            acceptLabel: "PROCEDI",
            acceptButtonStyleClass: "p-button-success",
            rejectLabel: "ANNULLA",
            rejectButtonStyleClass: "p-button-warning",
            accept: async () => {
              this.isLoadingResults = true;
              if(pratica.tipoSedeEffettiva === 'C') {
                this.logger.info("---> SCARICAMENTO IN BULK DEL PACCHETTO PRATICA NON COLOCATA DI TIPO C "+pratica.codiceInfratel);
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceRET                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferenceCMT                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
                
                let filesToDownload = [
                    pathReferenceNT9, 
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                  ];
                   
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                      if(pathRef.name==='nt9.zip'){ 
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                        || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                        || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                          if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                            let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                          }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                            let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                          }else {
                            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                          }
                        //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                      }else {
                        zip.file(pathRef.name, blob);
                      }
                      
                      downloadPromises.push(Promise.resolve());
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }
                  }catch(error){
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.info('Il file non esiste:', error.message);
                      
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.info('Errore sconosciuto:', error);
                    }
                  }
                }
        
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error)); 
        
                this.isLoadingResults = false;
              }else {

              
              const storage = getStorage();
              const zip = new JSZip();
              const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
              const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
              const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
              //const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
              const pathReferenceImmaginePlanimetria       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria.png");
    
              const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
              const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
              const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
              const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
              let filesToDownload = [
                  pathReferenceNT9, 
                  pathReferenceRET,
                  pathReferencePSI,
                  pathReferenceCMT,
                  pathReferenceAllegatoIRU, 
                  pathReferenceVerbaleCessioneIRU
                 // ,pathReferenceVerbaleCessioneProprieta
                ];
                
              
               if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
                  filesToDownload.push(pathReferenceVerbaleCessioneProprieta);
                  filesToDownload.push(pathReferenceImmaginePlanimetria);
    
                } 
    
                this.logger.info(filesToDownload);
    
              const downloadPromises = [];
              for (const pathRef of filesToDownload) {
                try {
                  const blob = await getBlob(pathRef);
                  if (blob) {
                    this.logger.info(pathRef.name); 
                    if(pathRef.name==='nt9.zip'){ 
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                    }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                      || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                    ){
                      if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                        let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      }else{
                        let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                      }
                    }else if(pathRef.name ==='Contratto_Cessione_Propriet%C3%A0_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                    } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                    } else {
                      zip.file(pathRef.name, blob);
                    }
                    
                    downloadPromises.push(Promise.resolve());
                  } else {
                    this.logger.info(`Errore durante il download di ${pathRef.name}`);
                  }
                }catch(error){
                  this.isLoadingResults = false;
                  if (error.code === 'storage/object-not-found') {
                    this.logger.error('Il file non esiste:', error.message);
                    
                  } else {
                    // Gestisci altri tipi di errori
                    this.logger.error('Errore sconosciuto:', error);
                  }
                }
              }
      
              Promise.all(downloadPromises)
              .then(() => {
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                // Crea il File zip e avvia il download:
                zip.generateAsync({ type: 'blob' })
                  .then((blob) => {
                    saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                  })
                  .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
              })
              .catch(error => this.logger.info('Errore durante il download dei File:', error));
      
              this.isLoadingResults = false;
            }
            },
            reject: () => {
            },
          });
       }
      
    }else{
      //this.logger.info("---> SCARICAMENTO IN BULK DELLA PACCHETTO PRATICA NON COLOCATA "+pratica.codiceInfratel);

      this.isLoadingResults = true;
      if(pratica.tipoSedeEffettiva === 'D' && pratica.ha2Tratte){
        this.logger.info("---> SCARICAMENTO IN BULK DELLA PACCHETTO PRATICA NON COLOCATA DI TIPO D "+pratica.codiceInfratel);

        this.praticheScuolaService.getById(pratica.id.toString()).subscribe(praticadDTO =>{
          this.scuoleService.getById(praticadDTO.scuola.id).subscribe(async scuola =>{
            this.isLoadingResults = true;
                const storage = getStorage();
                const zip = new JSZip();
                const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
                const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
                const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
                const pathReferencePDFPlanimetria            = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+".pdf");
                const pathReferenceImmaginePlanimetriaCompleta       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria);
                const pathReferenceImmaginePlanimetria       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/planimetria"+"."+scuola.estensionePlanimetria);
      
                const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
                const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
                const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
                const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                const pathReferenceVerbaleCessioneProprieta2  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
                
                let filesToDownload = [
                    pathReferenceNT9, 
                    pathReferenceRET,
                    pathReferencePSI,
                    pathReferenceCMT,
                    pathReferenceAllegatoIRU, 
                    pathReferenceVerbaleCessioneIRU,
                    pathReferenceVerbaleCessioneProprieta,
                    pathReferenceVerbaleCessioneProprieta2
                  ];
                  
                
                 if(pratica.pdfPlanimetriaUploaded){
                    filesToDownload.push(pathReferencePDFPlanimetria);
                  }else{
                    filesToDownload.push(pathReferenceImmaginePlanimetria);
                  }
      
               
      
                const downloadPromises = [];
                for (const pathRef of filesToDownload) {
                  try {
                    const blob = await getBlob(pathRef);
                    if (blob) {
                      if(pathRef.name==='nt9.zip'){ 
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                      }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                        || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                      ){
                        if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                          let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        }else{
                          let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                          zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                        }
                        
                      }else if(pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name ==='Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file('Contratto_Cessione_Proprietà_Nuove_Infrastrutture_'+pratica.codiceInfratel+'.pdf.p7m', blob);
                      }else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+".pdf"){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+".pdf", blob);
                      } else if(pathRef.name==="PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else if(pathRef.name==="planimetria."+scuola.estensionePlanimetria){
                        zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica").file("PLANIMETRIA_"+pratica.codiceInfratel+"."+scuola.estensionePlanimetria, blob);
                      }else {
                        zip.file(pathRef.name, blob);
                      }
                      
                      downloadPromises.push(Promise.resolve());
                    } else {
                      this.logger.info(`Errore durante il download di ${pathRef.name}`);
                    }
                  }catch(error){
                    this.isLoadingResults = false;
                    if (error.code === 'storage/object-not-found') {
                      this.logger.error('Il file non esiste:', error.message);
                     
                    } else {
                      // Gestisci altri tipi di errori
                      this.logger.error('Errore sconosciuto:', error);
                    }
                  }
                }
        
                Promise.all(downloadPromises)
                .then(() => {
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
                  // Crea il File zip e avvia il download:
                  zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                      saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                    })
                    .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
                })
                .catch(error => this.logger.info('Errore durante il download dei File:', error)); 
        
                this.isLoadingResults = false;
              
          });
        });
        }else if(pratica.tipoSedeEffettiva === 'C') {
          this.logger.info("---> SCARICAMENTO IN BULK DEL PACCHETTO PRATICA NON COLOCATA DI TIPO C "+pratica.codiceInfratel);
          const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceRET                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
          const pathReferencePSI                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
          const pathReferenceCMT                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
          
          let filesToDownload = [
              pathReferenceNT9, 
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceCMT,
            ];
             
          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                if(pathRef.name==='nt9.zip'){ 
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                  || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                      let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                      let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else {
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                    }
                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else {
                  zip.file(pathRef.name, blob);
                }
                
                downloadPromises.push(Promise.resolve());
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
  
          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            // Crea il File zip e avvia il download:
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error)); 
  
          this.isLoadingResults = false;
        }else{
          this.logger.info("---> SCARICAMENTO IN BULK DELLA PACCHETTO PRATICA NON COLOCATA DI TIPO NON D "+pratica.codiceInfratel);
           const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceRET                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
          const pathReferencePSI                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
          const pathReferenceCMT                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
          const pathReferenceAllegatoIRU              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
          const pathReferenceVerbaleCessioneIRU       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
          const pathReferenceVerbaleCessioneProprieta = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
          
          let filesToDownload = [
              pathReferenceNT9, 
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceCMT,
              pathReferenceAllegatoIRU, 
              pathReferenceVerbaleCessioneIRU,
            // pathReferenceVerbaleCessioneProprieta
            ];
              if(pratica.tipoSedeEffettiva === 'D'){
              filesToDownload.push(pathReferenceVerbaleCessioneProprieta);
            }  
          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                if(pathRef.name==='nt9.zip'){ 
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                  || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                    if(pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf'){
                      let subIRUFileName:string = "ALLEGATO CONTRATTO SUBIRU_"+pratica.codiceInfratel+'.pdf';
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else if(pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"){
                      let subIRUFileName:string = 'Contratto_Cessione_SUBIRU_'+pratica.codiceInfratel+".pdf.p7m";
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(subIRUFileName, blob);
                    }else {
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                    }
                  //zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else {
                  zip.file(pathRef.name, blob);
                }
                
                downloadPromises.push(Promise.resolve());
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
  
          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            // Crea il File zip e avvia il download:
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error)); 
  
          this.isLoadingResults = false;
          
    }
  }


    
    
  }

  openCaricaDocumentazionePratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> CARICO DOCUMENTAZIONE E SOVRASCRIVO");
  }
  async openScaricaDocumentazionePratica(
    event: any,
    pratica: PraticaScuola_Firestore
  ): Promise<void> {
    this.logger.info("---> SCARICO DOCUMENTAZIONE PRODOTTA");
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniScaricaFile();
    this.azioniScaricaDocMenu.toggle(event);
  }

  async openCaricaFilePratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.logger.info("---> CARICO File PRATICA");
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniCaricaFile(pratica);
    this.azioniCaricaFileMenu.toggle(event);
  }
  async openCaricaAzioniPratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioni(event, pratica);
    this.azioniMenu.toggle(event);
  }

  async openCaricaAzioniTieredPratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioni(event, pratica);
    this.azioniMenuTiered.toggle(event);
  }

  

  async loadRisultatiInvioPratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniRisultatoInvioPratica(pratica);
    this.azioniRisultatoInvioInfratel.toggle(event); 
  }


  openModificaInformazioniPratica(pratica: PraticaScuola_Firestore): void {
  }

  openCompletaPraticaPresidioTIM(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> presidio completa la pratica");
    this.logger.info(pratica);

    let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
    this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaDaDB) => {
        praticaDaAggiornare = praticaDaDB;

        this.ref = this.dialogService.open(DialogCompletaPratica, {
          data: {
            pratica: praticaDaAggiornare,
            praticaFirestore: pratica,
          },
          header: "Completamento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Praticha completata con successo",
            });
          }
        });

      });

  }

  openCambiaCodiceInvio(pratica:PraticaScuola_Firestore){
    this.ref = this.dialogService.open(DialogModificaCodiceInvioPratica, {
      data: {
        pratica: pratica,
        
      },
      header: "Modifica NOME INVIO della Pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "65%",
      height: "45%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((infoUpdated) => {
      if (infoUpdated && infoUpdated.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Codice INVIO modificato con successo",
        });
      }
    });

  }
  openModificaPraticaDialog(praticaFromFirestore: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
   
    this.praticheScuolaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.scuoleService
          .getById(praticaDaAggiornare.scuola.id.toString())
          .subscribe((scuola) => {
            scuolaDaAggiornare = scuola;
            this.ref = this.dialogService.open(DialogModificaPratica, {
              data: {
                pratica: praticaDaAggiornare,
                praticaFromFirestore: praticaFromFirestore,
                scuola: scuolaDaAggiornare,
              },
              header: "Dettagli Pratica "+pratica.codiceInfratel,
              width: this.isMobile ? "95%" : "85%",
              height: "85%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((infoUpdated) => {
              if (infoUpdated && infoUpdated.success) {
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Praticha modificata con successo",
                });
              }
            });
          });
      });
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }


  openInserisciDettagli(praticaFromFirestore: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
    
    //TODO: RECUPERO DA BE LA PRATICA CON TUTTE LE INFO!
    this.praticheScuolaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.scuoleService
          .getById(praticaDaAggiornare.scuola.id.toString())
          .subscribe((scuola) => {
            scuolaDaAggiornare = scuola;
            this.ref = this.dialogService.open(DialogSalvaInformazioni, {
              data: {
                pratica: praticaDaAggiornare,
                praticaFromFirestore: praticaFromFirestore,
                scuola: scuolaDaAggiornare,
              },
              header: "Dettagli Pratica "+pratica.codiceInfratel,
              width: this.isMobile ? "95%" : "85%",
              height: "85%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((infoUpdated) => {
              this.logger.info("FINITO AGGIORNAMENTO DETTAGLI PRATICA");
              this.logger.info(infoUpdated);
              if (infoUpdated && infoUpdated.success) {
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Pratica aggiornata con successo",
                });
              }
            });
          });
      });
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }

  openMappaConScuola(pratica: PraticaScuola_Firestore) {
    this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaCompleta) => {
          this.ref = this.dialogService.open(DialogPosizioneScuola, {
            data: {
              pratica: pratica,
              praticaCompleta: praticaCompleta
            },
            header: "Posizione della scuola e della centrale assegnata",
            width: this.isMobile ? "95%" : "70%",
            height: "75%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
    });
  }
  openEliminaPratica(pratica: PraticaScuola_Firestore){
    this.confirmationService.confirm({
      message: `Confermi di voler ELIMINARE la pratica ${pratica.codiceInfratel}?`,
      header: "ELIMINAZIONE",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaScuola = new PraticaScuola();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheScuolaService.elimina(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di eliminazione della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica Eliminata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }


  scaricaPacchettiInBulk(){
    this.logger.info(  "-> scaricaPacchettiInBulk " );
    let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
   
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
        const query = collectionRef
          .where("bando", "==", "BANDO 2")
          .where("stato", "==", tipoFirma);
          query.get().then(qs => {
            if (qs.docs && qs.docs.length > 0) {
              this.confirmationService.confirm({
                message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
                header: "ATTENZIONE!",
                icon: "pi pi-exclamation-circle",
                acceptLabel: "PROCEDI",
                acceptButtonStyleClass: "p-button-success",
                rejectLabel: "ANNULLA",
                rejectButtonStyleClass: "p-button-warning",
                accept: async () => {
                  this.isLoadingResults = true;
                  const promises: Promise<void>[] = [];
    
                  qs.docs.forEach(doc =>{
                    let pratica = doc.data() as PraticaScuola_Firestore;
                     this.logger.info(  "-> SCARICO FILE PER PRATICA " +pratica.codiceInfratel);
    
                    if(this.isDirigenteFirmaPacchetti){
                       if(!pratica.colocata || (pratica.colocata && pratica.colocataRuolo==='PRINCIPALE')){
                          promises.push(this.scaricaPacchettoPratica(pratica, true));
                    
                      } /* else{
                        this.logger.info(  pratica.codiceInfratel + "  COLOCATA O PRINCIPALE");
                      } */
                    }else{
                      promises.push(this.scaricaVerbale(pratica, true));
                    }
             
                    });
                    Promise.all(promises)
                    .then(() => {
                      this.isLoadingResults = false;
                    })
                    .catch(error => {
                      // Gestisci eventuali errori
                      this.logger.info("Errore durante le chiamate asincrone:", error);
                      this.isLoadingResults = false; // Assicurati di impostare isLoadingResults a false anche in caso di errore
                    });
                    
                },
                reject: () => {
                },
              });
             
              
            }else{
              this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
            }
        });
        
     

    
    
  }

  caricaPacchettiFirmatiInBulk(){
    this.logger.info(  "-> caricaPacchettiInBulk " );
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
    const query = collectionRef
      .where("bando", "==", "BANDO 2")
      .where("stato", "==", tipoFirma);
      query.get().then(qs => {
        if (qs.docs && qs.docs.length > 0) {
          this.isLoadingResults = true;
          if(this.isDirigenteFirmaVerbali){
            //
            this.ref = this.dialogService.open(DialogCaricaVerbaliFirmati, {
              data: {
               
              },
              header: "Carica Verbali Firmati",
              width: "90%",
              height: "50%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              this.isLoadingResults = false;

              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " verbal* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
                  styleClass: 'custom-toast-pacchetti',
                });
              }
            });
          }else if(this.isDirigenteFirmaPacchetti) {
            this.ref = this.dialogService.open(DialogCaricaPacchettiFirmati, {
              data: {
               
              },
              header: "Carica "+this.isDirigenteFirmaVerbali?"Verbali":"Pacchetti"+" Firmati",
              width: "90%",
              height: "50%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              this.isLoadingResults = false;
              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " pacchett* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
            
                  styleClass: 'custom-toast-pacchetti',
                });
              }else{
                /* this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento pacchetti firmati!",
                }); */
              }
            });
          }
         
          
        }else{
          this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
        }
    });
    
    /**
     * USO ALTRO COMPONENTE
     */
  }
  assegnaPraticheInBulk() {
    this.logger.info(  "-> assegnaPraticheInBulk " + this.selectedPratiche.length );
    let praticheDaAssegnare:PraticaScuola[]=[];
    this.selectedPratiche.forEach(pratica =>{
      let praticaDaAssegnare: PraticaScuola = new PraticaScuola();
      praticaDaAssegnare.codiceInfratel = pratica.codiceInfratel;
      praticaDaAssegnare.id = pratica.id;
      praticheDaAssegnare.push(praticaDaAssegnare);
    });
    this.ref = this.dialogService.open(DialogAssegnaPratica, {
      data: {
        pratiche: praticheDaAssegnare,
      },
      header: "Assegna pratiche in bulk",
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratiche assegnata con successo",
        });
      }
    });
    this.selectedPratiche = [];
  }

  openDisegnaPratica(pratica: PraticaScuola_Firestore) {
    this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaCompleta) => {
          this.ref = this.dialogService.open(DialogDisegnaScuola, {
            data: {
              pratica: pratica,
              praticaCompleta: praticaCompleta
            },
            header: "Disegna la pratica "+ pratica.codiceInfratel,
            width: "100%",
            height: "100%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
    });
  }


  openAssegnaAdImpresa(pratica: PraticaScuola_Firestore) {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    this.ref = this.dialogService.open(DialogAssegnaPratica, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Assegna pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 1,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica assegnata con successo",
        });
      }
    });
  }

  openCaricaFotoRouter(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaFotoRouter, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Fotografia del Router della pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { fotoRouterUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo fotoRouterUploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO foto_router_uploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoFotoRouter(
            praticaDaAggiornare,
            uploadResult.fileExtension
          )
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO FOTO ROUTER");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Fotografia del router caricata con successo",
        });
      }
    });
  }


  openCaricaPDFPlanimetria(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaPDFPlanimetria, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica PDF DELLA PLANIMETRIA per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { pdfPlanimetriaUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log(
              "Campo pdfPlanimetriaUploaded aggiornato con successo"
            );
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO pdfPlanimetriaUploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoPDFPlanimetria(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PDF PLANIMETRIA");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Planimetria caricata con successo",
        });
      }
    });
  }

  openCaricaPlanimetria(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaPlanimetria, {
      data: {
        pratica: praticaDaAggiornare,
        praticaFirestore:pratica
      },
      header: "Carica Planimetria per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { planimetriaUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log(
              "Campo foto_router_uploaded aggiornato con successo"
            );
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO foto_router_uploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoPlanimetria(praticaDaAggiornare, uploadResult.fileExtension)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PLANIMETRIA");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Planimetria caricata con successo",
        });
      }
    });
  }

  openCaricaPacchettoNT9(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaNT9, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto NT9 per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { nt9Uploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo nt9_uploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO nt9_uploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoNT9(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO REPORT NT9");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Report NT9 caricato con successo",
        });
      }
    });
  }
  caricaShapeFirmato(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaNT9Firmato, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Shape Firmato",
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        
         /*  this.praticheScuolaService
          .confermaCaricamentoVerbale(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
          });
        } 
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Verbale caricato con successo",
        }); */
       
      
    }
    });
  }
  caricaPacchettoFirmato(pratica: PraticaScuola_Firestore, isRicarica:boolean): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaPacchettoFirmato, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto Firmato per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        if(!isRicarica){
          this.praticheScuolaService
          .confermaCaricamentoPacchetto(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pacchetto firmato caricato con successo",
            }); 
          });
        } 

      }
    
    });
  }

  caricaVerbaleFirmato(pratica: PraticaScuola_Firestore, isRicarica:boolean): void {
    this.logger.info("--> caricando il verbale firmato della pratica "+ pratica.codiceInfratel);
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.praticheScuolaService
    .getById(pratica.id.toString()).subscribe(praticaDaDb =>{
      this.ref = this.dialogService.open(DialogCaricaVerbaleFirmato, {
        data: {
          pratica: praticaDaDb,
          pratica_firestore: pratica,
        },
        header: "Carica Verbale Firmato della Pratica "+pratica.codiceInfratel,
        width: this.isMobile ? "95%" : "70%",
        height: "55%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((uploadResult) => {
        if (uploadResult && uploadResult.success) {
          if(!isRicarica){
            this.praticheScuolaService
            .confermaCaricamentoVerbale(praticaDaAggiornare)
            .subscribe((res) => {
              this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO VERBALE");
            });
          }
         
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Verbale caricato con successo",
          }); 
        }
      });
    });

    
  }

  openVisualizzaDettagli(pratica: PraticaScuola_Firestore) {}
  openModificaDettagli(pratica: PraticaScuola_Firestore) {} 
  openAnnullaPratica(pratica: PraticaScuola_Firestore) {
    this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la pratica ${pratica.codiceInfratel}?`,
      header: "ANNULLAMENTO",
      icon: "pi pi-times",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaScuola = new PraticaScuola();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheScuolaService.annulla(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openPreviewDocs(pratica: PraticaScuola_Firestore){
    this.confirmationService.confirm({
      message: `Confermi di voler procedere con la GENERAZIONE ed il DOWNLOAD dei documenti della pratica <strong>${pratica.codiceInfratel}</strong>?`,
      header: "Generazione documenti",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheScuolaService.generaDocs(praticaDaConsegnare).subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di generazione dei docs della pratica",
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Generazione documentazione avvenuta, attendere il download!",
            });
            //scarica tutto!
            this.isLoadingResults = false;
            if(pratica.colocata){
              this.scaricaPreviewDocsPraticaColocata(pratica);
            }else {
              this.scaricaPreviewDocsPratica(pratica);
            }
            

            
            
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  async scaricaPreviewDocsPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO LA PREVIEW DEI DOCS DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const zip = new JSZip();
    const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_RET_01_0.docx");
    const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_PSI_02_0.docx");
    let filesToDownload = [
        pathReferenceRET,
        pathReferencePSI,
        
      ];
      if(pratica.tipoSedeEffettiva!=='C'){
        const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
        filesToDownload.push(pathReferenceAllegatoIRU, );
      }
    const downloadPromises = [];
    for (const pathRef of filesToDownload) {
      const blob = await getBlob(pathRef);
      if (blob) {
        this.logger.info("PRATICA " +pratica+" SCARICO PREVIEW DEL FILE: " +pathRef.name);
        zip.file(pathRef.name, blob);
        
        downloadPromises.push(Promise.resolve());
      } else {
        this.logger.info(`Errore durante il download di ${pathRef.name}`);
      }
    }
    Promise.all(downloadPromises)
    .then(() => {
      // Crea il File zip e avvia il download:
      zip.generateAsync({ type: 'blob' })
        .then((blob) => {
          saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il File zip
          this.isLoadingResults = false;
        })
        .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
    })
    .catch(error => this.logger.info('Errore durante il download dei File:', error));
    
  }

  openConsegnaPratica(pratica: PraticaScuola_Firestore): void {
    let messaggioColocata = "";
    if(pratica.colocata){
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel.valueOf());
      const risultati: PraticaScuola_Firestore[] = [];
      query.get().then(qs=>{
        let praticaColocata = qs.docs[0].data() as PraticaScuola_Firestore;
        if(qs.docs.length ==1){
          messaggioColocata = "Confermi di voler procedere con la CONSEGNA  della pratica <b>" + pratica.codiceInfratel + "</b> e della sua colocata <b>" + praticaColocata.codiceInfratel +"</b>?"
        }else{
          messaggioColocata = "Confermi di voler procedere con la CONSEGNA  della pratica <b>" + pratica.codiceInfratel + "</b> e delle sue <b>"+qs.docs.length+"</b> colocate ?"

        }
         
          this.confirmationService.confirm({
            message: messaggioColocata,
            header: "Consegna",
            icon: "pi pi-File-import",
            acceptLabel: "CONFERMA",
            acceptButtonStyleClass: "p-button-success",
            rejectLabel: "ANNULLA",
            rejectButtonStyleClass: "p-button-warning",
            accept: () => {
              this.isLoadingResults = true;
              let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
              praticaDaConsegnare.id = pratica.id;
              this.praticheScuolaService.consegna(praticaDaConsegnare).subscribe({
                /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
                next: (v) => {},
                error: (e) => {
                  this.isLoadingResults = false;
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "Errore in fase di consegna della pratica",
                  });
                },
                complete: () => {
                  this.isLoadingResults = false;
                  this.messageService.add({
                    severity: "success",
                    summary: "" , life:10000,
                    detail: "Pratica consegnata",
                  });
                },
              });
            },
            reject: () => {
              //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
            },
          });
        }
      )
      
    }else {
      this.confirmationService.confirm({
        message: !pratica.colocata?`Confermi di voler procedere con la CONSEGNA  della pratica <b>${pratica.codiceInfratel}</b>?`:messaggioColocata,
        header: "Consegna",
        icon: "pi pi-File-import",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          this.isLoadingResults = true;
          let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
          praticaDaConsegnare.id = pratica.id;
          this.praticheScuolaService.consegna(praticaDaConsegnare).subscribe({
            /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di consegna della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica consegnata",
              });
            },
          });
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
    }
    
  }

  openAnnullaConsegnaPratica(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> ANNULLA CONSEGNA PRATICA");
    this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la CONSEGNA  della pratica ${pratica.codiceInfratel}?`,
      header: "Consegna",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheScuolaService.annullaConsegna(praticaDaConsegnare).subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della consegna della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Consegna della pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }
  openInCaricoPresidio(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> PRESIDIO PRENDE IN CARICO PRATICA");

    this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaPrendereInCarico: PraticaScuola = new PraticaScuola();
        praticaDaPrendereInCarico.id = pratica.id;
        this.logger.info(praticaDaPrendereInCarico);
        this.praticheScuolaService
          .presidioPrendeInCarico(praticaDaPrendereInCarico)
          .subscribe({
            /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }
  openPrendiInCarico(pratica: PraticaScuola_Firestore): void {
    this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaModificare: PraticaScuola = new PraticaScuola();
        let utenteInCarico: Utente = new Utente();
        utenteInCarico.id = Number.parseInt(localStorage.getItem("idUtente"));
        praticaDaModificare.id = pratica.id;
        praticaDaModificare.utenteAssegnato = utenteInCarico;
        this.logger.info(praticaDaModificare);
        this.praticheScuolaService
          .prendiInCarico(praticaDaModificare)
          .subscribe({
            /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openRichiediCambioAssegnatario(pratica: PraticaScuola_Firestore): void {
    this.logger.info("--->  RICHIEDI CAMBIO CENTRALE DELLA PRATICA " + pratica.codiceInfratel);
    this.confirmationService.confirm({
      message: `Confermi di voler richiedere l'assegnazione della pratica ${pratica.codiceInfratel}?`,
      header: "Cambio Assegnatario",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.praticheScuolaService.richiediCambioAssegnazione(pratica.id.valueOf(), localStorage.getItem("idUtente"))
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di invio richiesta di cambio assegnatario",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Richiesta di cambio assegnatario inviata correttamente",
            });
          },
        });
       
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
         

  }

  openRichiediModificaCentrale(pratica: PraticaScuola_Firestore): void {
    this.logger.info("--->  RICHIEDI CAMBIO CENTRALE DELLA PRATICA " + pratica.codiceInfratel);
    
          this.ref = this.dialogService.open(DialogRichiediCambioCentrale, {
            data: {
              pratica: pratica,
             
            },
            header: "Richiesta cambio centrale ",
            width: this.isMobile ? "95%" : "85%",
            height: "50%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((changeRequested) => {
            if (changeRequested && changeRequested.success) {
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Richiesta inviata con successo",
              });
            }
          });
  
  }

  annullaAssegnazione(pratica: PraticaScuola_Firestore): void {
    this.confirmationService.confirm({
      message: `Confermi di voler annullare l'assegnazione della pratica ${pratica.codiceInfratel}?`,
      header: "Annullamento Assegnazione Pratica",
      icon: "pi pi-file-edit",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.logger.info(localStorage.getItem("idUtente"));
        this.isLoadingResults = true;
       
        this.praticheScuolaService
          .annullaAssegnazione(pratica.id.valueOf())
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di annullamento assegnazione della pratica",
              });
              this.isLoadingResults = false;
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Annullamento assegnazione avvenuto con successo",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openIniziaProgettazione(pratica: PraticaScuola_Firestore): void {
    this.logger.info("--->  INIZIA PROGETTAZIONE PRATICA");
    if(!pratica.colocata || pratica.colocataRuolo==='SECONDARIA'){
      this.confirmationService.confirm({
        message: `Confermi di voler iniziare la progettazione della pratica ${pratica.codiceInfratel}?`,
        header: "Inizio Progettazione",
        icon: "pi pi-file-edit",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          this.logger.info(localStorage.getItem("idUtente"));
          this.isLoadingResults = true;
          let praticaDaModificare: PraticaScuola = new PraticaScuola();
  
          praticaDaModificare.id = pratica.id;
          this.logger.info(praticaDaModificare);
          this.praticheScuolaService
            .iniziaProgettazione(praticaDaModificare)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di inizio progettazione della pratica",
                });
                this.isLoadingResults = false;
              },
              complete: () => {
                this.isLoadingResults = false;
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Inizio progettazione avvenuto con successo",
                });
              },
            });
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
    }else{
      this.confirmationService.confirm({
        message: `Confermi di voler iniziare la progettazione della pratica ${pratica.codiceInfratel} e della sua colocata?`,
        header: "Inizio Progettazione",
        icon: "pi pi-file-edit",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          this.logger.info(localStorage.getItem("idUtente"));
          this.isLoadingResults = true;
          let praticaDaModificare: PraticaScuola = new PraticaScuola();
  
          praticaDaModificare.id = pratica.id;
          this.logger.info(praticaDaModificare);
          this.praticheScuolaService
            .iniziaProgettazione(praticaDaModificare)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di inizio progettazione della pratica",
                });
                this.isLoadingResults = false;
              },
              complete: () => {

                this.isLoadingResults = false;
                     
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Inizio progettazione avvenuto con successo",
                });
                /* this.getColocataDiUnaPrincipale(praticaDaModificare.codiceInfratel).subscribe(res => {
                  let praticaColocataDaModificare: PraticaScuola = new PraticaScuola();
  
                  praticaColocataDaModificare.id = res[0].id;
                  this.praticheScuolaService
                  .iniziaProgettazione(praticaColocataDaModificare)
                  .subscribe({
                    next: (v) => {},
                    error: (e) => {
                      this.messageService.add({
                        severity: "info",
                      summary: "Errore" , life:10000,
                        detail: "Errore in fase di inizio progettazione della pratica",
                      });
                      this.isLoadingResults = false;
                    },
                    complete: () => {
                      this.isLoadingResults = false;
                     
                        this.messageService.add({
                          severity: "success",
                          summary: "" , life:10000,
                          detail: "Inizio progettazione avvenuto con successo",
                        });
  
              },
            });

                  this.messageService.add({
                    severity: "success",
                    summary: "" , life:10000,
                    detail: "Inizio progettazione avvenuto con successo",
                  });
                }); */
                
              },
            });
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
    }
    
   
  }



  openDeletePraticaDialog(pratica: PraticaScuola_Firestore): void {
    /* this.logger.info(impresa);
     this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'impresa ${impresa.ragioneSociale}?`,
      header: "Eliminazione Impresa",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.impreseService.elimina(impresa["idAzienda"]).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di eliminazione impresa",
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Impresa eliminata",
            });
            //this.loadImprese();
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        // console.log("ANNULLO");
      },
    });  */
  }
}

@Component({
  selector: "app-inserisci-informazioni-pratica-bando2",
  templateUrl: "./dialog-inserisci-informazioni-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogSalvaInformazioni implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;

  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  hasBackup: boolean = false;

  protected pagineInserisciInformazioni: MenuItem[];
  protected activeIndex: number = 0;
  protected isBandoD:boolean = false;
  protected isColocata:boolean = false;
  protected isTipoC:boolean = false;

  protected tipologie:Tipologia[]   = [];
  protected diritti:Tipologia[]     = [];
  protected proprietari:Tipologia[] = [];
  protected tipologieCavi:Tipologia[]   = [];


  protected reteAccessoForm: FormGroup;
  protected ubicazioneRouterForm: FormGroup;
  protected identificazioneFibraForm: FormGroup;
  protected localizzazioniForm: FormGroup;
  protected dettagliForm: FormGroup;
  private scuola: ScuolaDTO;

  protected ref: DynamicDialogRef;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;



  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private praticheScuolaService: PraticheScuolaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private firestoreService:AngularFirestore
  ) {}

  ngOnInit(): void {
    this.pratica    = this.config.data.pratica;
    this.isBandoD   = //this.pratica.tipoSede.codice==='D' || 
      this.pratica.tipoSedeEffettiva.codice==='D'?true:false;
    this.isTipoC =  this.pratica.tipoSedeEffettiva.codice==='C'?true:false;

    this.praticaFromFirestore = this.config.data.praticaFromFirestore;
    
    this.scuola = this.config.data.scuola;
    this.hasBackup = this.praticaFromFirestore.backup.valueOf();
    if(this.isBandoD && this.praticaFromFirestore.colocata){
      this.isColocata = true;
    }
    this.logger.info(this.praticaFromFirestore);
    this.logger.info(this.pratica);
    this.logger.info(this.scuola);
    this.inizializzaListe();
    this.inizializzaFormGroups();
    this.inizializzaSteps();
    
  }

  inizializzaListe(){
    let tipologiaScavo:Tipologia = new Tipologia();
    let tipologiaFibra:Tipologia = new Tipologia();
    let tipologiaMinitubo:Tipologia = new Tipologia();

    tipologiaScavo.codice = "Scavo";
    tipologiaFibra.codice = "Fibra";
    tipologiaMinitubo.codice = "Minitubo";
    this.tipologie.push(tipologiaFibra);
    this.tipologie.push(tipologiaScavo);
    this.tipologie.push(tipologiaMinitubo);
    let tipologiaCavo12:Tipologia = new Tipologia();
    let tipologiaCavo24:Tipologia = new Tipologia();
    let tipologiaCavo48:Tipologia = new Tipologia();
    tipologiaCavo12.codice = "Cavo 12 Fibre";
    tipologiaCavo24.codice = "Cavo 24 Fibre";
    tipologiaCavo48.codice = "Cavo 48 Fibre";
    this.tipologieCavi.push(tipologiaCavo12);
    this.tipologieCavi.push(tipologiaCavo24);
    this.tipologieCavi.push(tipologiaCavo48);

    let dirittoProprieta:Tipologia = new Tipologia();
    let dirittoIRU:Tipologia = new Tipologia();
    let dirittoSubIRU:Tipologia = new Tipologia();
    
    dirittoProprieta.codice = "Proprietà";
    dirittoIRU.codice = "IRU";
    dirittoSubIRU.codice = "SubIRU";
    this.diritti.push(dirittoProprieta);
    this.diritti.push(dirittoIRU);
    this.diritti.push(dirittoSubIRU);

    let proprietarioTIM:Tipologia = new Tipologia();
    let proprietarioINFRATEL:Tipologia = new Tipologia();
    let proprietarioFastweb:Tipologia = new Tipologia();
    proprietarioTIM.codice = "TIM";
    proprietarioINFRATEL.codice = "Infratel";
    proprietarioFastweb.codice = "Fastweb";
    
    this.proprietari.push(proprietarioTIM);
    this.proprietari.push(proprietarioINFRATEL);
    this.proprietari.push(proprietarioFastweb);

    
  }
  getTipologia(tipologia:String):number{
    switch (tipologia) {
      case "SCAVO":
        return 1;
      case "Scavo":
        return 1;
      case "SCAVO":
        return 1;
      case "FIBRA":
        return 0;
      case "Fibra":
        return 0;
      case "MINITUBO":
        return 2;
      case "Minitubo":
        return 2;
      default:
        return null;
    }
  }
  getTipologiaCavo(tipologia:String):number{
    this.logger.info("-> getTipologiaCavo " + tipologia);
    switch (tipologia) {
      case "12 fibre TOL6D 12 1(12 SM) T/M":
        return 0;
      case "24 fibre TOL6D 24 2(12 SM) T/M":
        return 1;
      case "48 fibre TOL6D 48 4(12 SM) T/M":
        return 2;
      default:
        return null;
    }
  }

  getCodiceTipologiaCavo(tipologia:String):string{

    switch (tipologia) {
      case "Cavo 12 Fibre":
        return "12 fibre TOL6D 12 1(12 SM) T/M";
      case "Cavo 24 Fibre":
        return "24 fibre TOL6D 24 2(12 SM) T/M";
      case "Cavo 48 Fibre":
        return "48 fibre TOL6D 48 4(12 SM) T/M";
      default:
        return null;
    }
  }
  getDiritto (diritto:String):number{
    switch (diritto) {
      case "Proprietà":
        return 0;
      case "IRU":
        return 1;
      default:
        return 2;
    }
  }
  getProprietario(proprietario:String):number{
    switch (proprietario) {
      case "TIM":
        return 0;
        case "INFRATEL":
          return 1;
      default:
        return 2;
    }
  }
  inizializzaFormGroups() {
    this.reteAccessoForm = this._formBuilder.group({
      tipologia: [this.scuola.tipologia?this.tipologie[this.getTipologia(this.scuola.tipologia)]:null, !this.isTipoC?[Validators.required]:null],
      diritto: [this.scuola.diritto?this.diritti[this.getDiritto(this.scuola.diritto)]:null, !this.isTipoC?[Validators.required]:null],
      proprietario: [this.scuola.proprietario?this.proprietari[this.getProprietario(this.scuola.proprietario)]:null, !this.isTipoC?[Validators.required]:null],
      lunghezza: [this.scuola.lunghezza, !this.isTipoC?[Validators.required]:null],
      hostnameCPE: [this.scuola.hostnameCpe, [Validators.required]],
      lunghezzaTratta2:[this.scuola.lunghezzaTratta2?this.scuola.lunghezzaTratta2:null],
      //tipologiaTratta2:[this.scuola.tipologiaTratta2?this.tipologie[this.getTipologia(this.scuola.tipologiaTratta2)]:null],
      tipologiaTratta2:[this.scuola.tipologiaTratta2?this.tipologieCavi[this.getTipologiaCavo(this.scuola.tipologiaTratta2)]:null],
      dirittoTratta2:[this.scuola.dirittoTratta2?this.diritti[this.getDiritto(this.scuola.dirittoTratta2)]:null],
      proprietarioTratta2:[this.scuola.proprietarioTratta2?this.proprietari[this.getProprietario(this.scuola.proprietarioTratta2.toUpperCase())]:null],
      localeTecnico: [this.scuola.localeTecnicoNome, [Validators.required]],
      piano: [this.scuola.localeTecnicoPiano, [Validators.required]],
      stanza: [this.scuola.localeTecnicoStanza, [Validators.required]],
      flagCPEinStessoLocale: [this.scuola.cpeInStessoLocale],
    });
  /*   this.ubicazioneRouterForm = this._formBuilder.group({
      hostnameCPE: [this.scuola.hostnameCpe, [Validators.required]],
      localeTecnico: [this.scuola.localeTecnicoNome, [Validators.required]],
      piano: [this.scuola.localeTecnicoPiano, [Validators.required]],
      stanza: [this.scuola.localeTecnicoStanza, [Validators.required]],
    }); */
    this.identificazioneFibraForm = this._formBuilder.group({
      clliSedeA: [this.scuola.clliSedeA, [Validators.required]],
      //clliSedeA: new FormControl({value: this.scuola.codiceMiur, disabled: true}, Validators.required), 
      tipologiaSedeA: [this.scuola.tipologiaSedeA, [Validators.required]],
      descrizioneSedeA: [this.scuola.descrizioneSedeA, [Validators.required]],
      attestazioneAlRipartitoreA: [ this.scuola.attestazioneRipartitoreA,  [Validators.required],],
      attestazioneAlRipartitoreA_BU: [ this.scuola.attestazioneRipartitoreA_BU,  this.hasBackup?[Validators.required]:null,],
      clliSedeB: new FormControl({value: this.isBandoD?this.scuola.clliSedeA:null, disabled: true}, this.isBandoD?Validators.required:null),
      tipologiaSedeB: new FormControl({value: this.isBandoD?"Giunto Fittizio":null, disabled: true}, this.isBandoD?Validators.required:null),
      //tipologiaSedeB: [this.isBandoD?"Giunto Fittizio":null, this.isBandoD?[Validators.required]:null],
      descrizioneSedeB: new FormControl({value: this.isBandoD?"Giunto Fittizio":null, disabled: true}, this.isBandoD?Validators.required:null),
      //descrizioneSedeB: [this.isBandoD?"Giunto Fittizio":null, this.isBandoD?[Validators.required]:null],
      attestazioneAlRipartitoreB: new FormControl({value: this.isBandoD?this.scuola.attestazioneRipartitoreA:null, disabled: true}, this.isBandoD?Validators.required:null),
   //   attestazioneAlRipartitoreB: [ this.scuola.attestazioneRipartitoreA,  this.isBandoD?[Validators.required]:null,],
      attestazioneAlRipartitoreB_BU: new FormControl({value: this.isBandoD?this.scuola.attestazioneRipartitoreA_BU:null, disabled: true}, this.isBandoD?Validators.required:null),
      clliSedeZ:           new FormControl({value: this.pratica.nomeCentrale, disabled: true}, Validators.required), 
      //clliSedeZ: [this.scuola.clliSedeZ, [Validators.required]],
      tipologiaSedeZ: [this.scuola.tipologiaSedeZ, [Validators.required]],
      descrizioneSedeZ: [this.scuola.descrizioneSedeZ, [Validators.required]],
      attestazioneAlRipartitoreZ: [  this.scuola.attestazioneRipartitoreZ, [Validators.required], ],
      attestazioneAlRipartitoreZ_BU: [  this.scuola.attestazioneRipartitoreZ_BU, this.hasBackup?[Validators.required]:null, ],
      
      numeroTotaleCoppie: [
        this.scuola.numeroTotaleCoppie,
        [Validators.required],
      ],
      numeroCoppieInUso: [this.scuola.numeroCoppieInUso, [Validators.required]],
      fibra: [this.scuola.numeroFibra, [Validators.required]],
      fibra_BU: [this.scuola.numeroFibra_BU, this.hasBackup?[Validators.required]:null],
    });
    this.localizzazioniForm = this._formBuilder.group({
      indirizzoScuola: [this.scuola.indirizzo, [Validators.required]],
      comuneScuola: new FormControl({value: this.scuola.comune.denominazione, disabled: true}, Validators.required), 
      latitudineScuola: [this.praticaFromFirestore.coordinateScuola.latitude, [Validators.required]],
      longitudineScuola: [this.praticaFromFirestore.coordinateScuola.longitude, [Validators.required]],
      //indirizzoCentrale: [ this.pratica.indirizzoCentrale, [Validators.required], ],
      indirizzoCentrale: new FormControl({value: this.pratica.indirizzoCentrale, disabled: true}, Validators.required),
      comuneCentrale: new FormControl({value: this.pratica.comuneCentrale, disabled: true}, Validators.required),

      //latitudineCentrale: [ this.praticaFromFirestore.coordinateCentrale.latitude,[Validators.required],],
      latitudineCentrale:  new FormControl({value: this.praticaFromFirestore.coordinateCentrale.latitude, disabled: true}, Validators.required),
      longitudineCentrale:  new FormControl({value: this.praticaFromFirestore.coordinateCentrale.longitude, disabled: true}, Validators.required),
      //longitudineCentrale: [ this.praticaFromFirestore.coordinateCentrale.longitude, [Validators.required],],
      latitudineNodoFittizio: [this.isBandoD?this.pratica.latitudineNodoFittizio:null, this.isBandoD?[Validators.required]:null],
      longitudineNodoFittizio: [this.isBandoD?this.pratica.longitudineNodoFittizio:null, this.isBandoD?[Validators.required]:null],
    });
   
    this.dettagliForm = this._formBuilder.group({
      redattore: [this.pratica.redattore, [Validators.required]],
      verificatore: [this.pratica.verificatore, [Validators.required]],
    });
  }
  valoreInput: string = '';

  aggiornaAttestazioneB(event:any){
    if(this.isBandoD){
      this.identificazioneFibraForm.get("attestazioneAlRipartitoreB").setValue(this.identificazioneFibraForm.value.attestazioneAlRipartitoreA);
    }
  }

  aggiornaAttestazioneB_BU(event:any){
    if(this.isBandoD){
      this.identificazioneFibraForm.get("attestazioneAlRipartitoreB_BU").setValue(this.identificazioneFibraForm.value.attestazioneAlRipartitoreA_BU);
    }
  }

  aggiornaCLLISedeB(event:any){
    if(this.isBandoD){
      this.identificazioneFibraForm.get("clliSedeB").setValue(this.identificazioneFibraForm.value.clliSedeA);
    }
  }

  inizializzaSteps() {
    if(!this.isTipoC){
      this.pagineInserisciInformazioni = [
        {
          label: "Collegamento e Router",
        },
         {
          label: "Dettagli fisici",
        },
        {
          label: "Localizzazione",
        },
        {
          label: "Dettagli documenti",
        },
      ];
    }else{
      this.pagineInserisciInformazioni = [
       
         {
          label: "Dettagli fisici",
        },
        {
          label: "Localizzazione",
        },
        {
          label: "Dettagli documenti",
        },
      ];
    }
    
    this.activeIndex = 0;
  }

  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if(this.isTipoC){
      if (this.activeIndex === 0 && !this.reteAccessoForm.valid) {
        disableNextStepButton = true;
      }else if (this.activeIndex === 1 && !this.localizzazioniForm.valid) {
        disableNextStepButton = true;
      }else if (this.activeIndex === 2 && !this.dettagliForm.valid) {
        disableNextStepButton = true;
      }
      /* if (this.activeIndex === this.pagineInserisciInformazioni.length - 1) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 1 && !this.reteAccessoForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 0 && !this.identificazioneFibraForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 2 && !this.localizzazioniForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 3 && !this.dettagliForm.valid) {
        disableNextStepButton = true;
      } */
  
    }else{ 
      if (this.activeIndex === this.pagineInserisciInformazioni.length - 1) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 1 && !this.reteAccessoForm.valid) {
        disableNextStepButton = true;
      } /* else if (this.activeIndex === 1 && !this.ubicazioneRouterForm.valid) {
        disableNextStepButton = true;
      } */ else if (this.activeIndex === 0 && !this.identificazioneFibraForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 2 && !this.localizzazioniForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 3 && !this.dettagliForm.valid) {
        disableNextStepButton = true;
      }
  
    }
   
    return disableNextStepButton;
  }

  changePage() {
    this.activeIndex = this.activeIndex + 1;
  }
  openMappaScuola(){
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:true,
      },
      header: "Posizione della scuola",
      width: "80%",
      height: "95%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.localizzazioniForm.get("latitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.latitude);
        this.localizzazioniForm.get("longitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.longitude);
      });
    });
  }
  openMappaCentrale(){
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:false,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.localizzazioniForm.get("latitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.latitude);
        this.localizzazioniForm.get("longitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.longitude);
      });
    });
  }

  openRichiediAggiornamentoDatientrale(){
      //todo
      let centrale:Centrale = new Centrale();
      let comune:ComuneDTO = new ComuneDTO();
      comune.denominazione = this.pratica.comuneCentrale;
      centrale.clli = this.pratica.idCentrale;
      centrale.indirizzo = this.pratica.indirizzoCentrale;
      centrale.comune = comune;
      centrale.latitudine = this.pratica.latitudineCentrale;
      centrale.longitudine = this.pratica.longitudineCentrale;

        this.ref = this.dialogService.open(DialogRichiediAggiornamentoCentrale, {
          data: {
            centrale: centrale,
            impresa:this.pratica.azienda.ragioneSociale,
      },
        header: 'Richiedi aggiornamento Centrale ' + this.pratica.idCentrale.toUpperCase(),//+impresa.ragioneSociale.toUpperCase(),
        width: '50%',
        height: '70%',
        baseZIndex: 10000,
        closable:true,
        draggable:false,
      });
      this.ref.onClose.subscribe(confirmModificaCliente => {

      });
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let dettaglioPraticaDTO: DettaglioPraticaScuolaDTO =  new DettaglioPraticaScuolaDTO();
      dettaglioPraticaDTO.id = this.pratica.id;
      dettaglioPraticaDTO.codiceInfratel = this.pratica.codiceInfratel;
      if(!this.isTipoC){
        dettaglioPraticaDTO.lunghezza     = this.reteAccessoForm.value.lunghezza;
        dettaglioPraticaDTO.tipologia     = this.reteAccessoForm.value.tipologia.codice;
        dettaglioPraticaDTO.diritto       = this.reteAccessoForm.value.diritto.codice;
        dettaglioPraticaDTO.proprietario  =  this.reteAccessoForm.value.proprietario.codice;
  
      }
     
      if(this.isBandoD 
        && this.reteAccessoForm.value.lunghezzaTratta2 
        && this.reteAccessoForm.value.tipologiaTratta2 
        && this.reteAccessoForm.value.dirittoTratta2
        && this.reteAccessoForm.value.proprietarioTratta2){
        dettaglioPraticaDTO.lunghezzaTratta2     = this.reteAccessoForm.value.lunghezzaTratta2;

        //dettaglioPraticaDTO.tipologiaTratta2     = this.reteAccessoForm.value.tipologiaTratta2.codice;
        dettaglioPraticaDTO.tipologiaTratta2     = this.getCodiceTipologiaCavo(this.reteAccessoForm.value.tipologiaTratta2.codice);

        dettaglioPraticaDTO.dirittoTratta2       = this.reteAccessoForm.value.dirittoTratta2.codice;
        dettaglioPraticaDTO.proprietarioTratta2  =  this.reteAccessoForm.value.proprietarioTratta2.codice;
        dettaglioPraticaDTO.latitudineNodoFittizio  = this.localizzazioniForm.value.latitudineNodoFittizio;
        dettaglioPraticaDTO.longitudineNodoFittizio = this.localizzazioniForm.value.longitudineNodoFittizio;
      }

      if(this.isBandoD && this.isColocata ){
      }//&& this.reteAccessoForm.value.flagCPEinStessoLocale != this.scuola.flagCPEinStessoLocale
      if(this.isBandoD && this.isColocata ){
        dettaglioPraticaDTO.cpeInStessoLocale = this.reteAccessoForm.value.flagCPEinStessoLocale;
      }
      
    
      //dettaglioPraticaDTO.numeroAccessPoints = this.reteAccessoForm.value.numeroAccessPoints;

      dettaglioPraticaDTO.hostnameCPE   =  this.reteAccessoForm.value.hostnameCPE;
      dettaglioPraticaDTO.localeTecnico = this.reteAccessoForm.value.localeTecnico;
      dettaglioPraticaDTO.piano = this.reteAccessoForm.value.piano;
      dettaglioPraticaDTO.stanza = this.reteAccessoForm.value.stanza;

      dettaglioPraticaDTO.clliSedeA = this.identificazioneFibraForm.value.clliSedeA;
      dettaglioPraticaDTO.tipologiaSedeA = this.identificazioneFibraForm.value.tipologiaSedeA;
      dettaglioPraticaDTO.descrizioneSedeA = this.identificazioneFibraForm.value.descrizioneSedeA;
      dettaglioPraticaDTO.attestazioneAlRipartitoreA = this.identificazioneFibraForm.value.attestazioneAlRipartitoreA;

      dettaglioPraticaDTO.clliSedeZ                   =   this.identificazioneFibraForm.value.clliSedeZ;
      dettaglioPraticaDTO.tipologiaSedeZ              =   this.identificazioneFibraForm.value.tipologiaSedeZ;
      dettaglioPraticaDTO.descrizioneSedeZ            =   this.identificazioneFibraForm.value.descrizioneSedeZ;
      dettaglioPraticaDTO.attestazioneAlRipartitoreZ  =   this.identificazioneFibraForm.value.attestazioneAlRipartitoreZ;
      
      dettaglioPraticaDTO.numeroTotaleCoppie =
        this.identificazioneFibraForm.value.numeroTotaleCoppie;
      dettaglioPraticaDTO.numeroCoppieInUso =
        this.identificazioneFibraForm.value.numeroCoppieInUso;
      dettaglioPraticaDTO.fibra = this.identificazioneFibraForm.value.fibra;

      if(this.hasBackup){
        dettaglioPraticaDTO.attestazioneAlRipartitoreA_BU = this.identificazioneFibraForm.value.attestazioneAlRipartitoreA_BU;
        dettaglioPraticaDTO.attestazioneAlRipartitoreZ_BU = this.identificazioneFibraForm.value.attestazioneAlRipartitoreZ_BU;
        dettaglioPraticaDTO.fibra_BU                      = this.identificazioneFibraForm.value.fibra_BU;
      }

      dettaglioPraticaDTO.indirizzoScuola =
        this.localizzazioniForm.value.indirizzoScuola;
      dettaglioPraticaDTO.latitudineScuola =
        this.localizzazioniForm.value.latitudineScuola;
      dettaglioPraticaDTO.longitudineScuola =
        this.localizzazioniForm.value.longitudineScuola;

     /*  dettaglioPraticaDTO.indirizzoCentrale =
        this.localizzazioniForm.value.indirizzoCentrale;
      dettaglioPraticaDTO.latitudineCentrale =
        this.localizzazioniForm.value.latitudineCentrale;
      dettaglioPraticaDTO.longitudineCentrale =
        this.localizzazioniForm.value.longitudineCentrale; */

      dettaglioPraticaDTO.redattore = this.dettagliForm.value.redattore;
      dettaglioPraticaDTO.verificatore = this.dettagliForm.value.verificatore;
      this.logger.info(dettaglioPraticaDTO);
      
      this.praticheScuolaService
        .caricaDettagliPratica(dettaglioPraticaDTO)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di AGGIORNAMENTO DETTAGLI della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            //se ho aggiornato le info di una pratica COLOCATA, aggiorno campo su PRINCIPALE!
            /*
            const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
            const documentRef = collectionRef.doc(this.praticaFromFirestore.colocataScuolaPrincipale.valueOf());
            let updatedData:any= { colocataInfoComplete: true };
            
            documentRef
              .update(updatedData)
              .then(() => {
                this.logger.info( "OK AGGIORNAMENTO PRATICA PRINCIPALE SU FIRESTORE");
                this.dialogRef.close({ success: true });
              })
              .catch((error) => {
                this.logger.error(
                  "ERRORE AGGIORNATO PRATICA PRINCIPALE SU FIRESTORE"
                );
              });
                  this.dialogRef.close({ success: true });
                
                },
              }); 
            */
            this.dialogRef.close({ success: true });
           
          },
        }); 

    
    }
  }
}

interface TipoSede {
  id: number;
  codice: string;
}

@Component({
  selector: "app-modifica-pratica-bando2",
  templateUrl: "./dialog-modifica-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogModificaPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  private tipiSede:TipoSede[];
  protected pagineModificaPratica: MenuItem[];
  protected activeIndex: number = 0;

  protected dettagliForm: FormGroup;
  protected scuolaForm: FormGroup;
  protected centraleForm: FormGroup;

  protected localizzazioniForm: FormGroup;
  private scuola: ScuolaDTO;

  protected ref: DynamicDialogRef;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  protected comuniLoaded: boolean = false;
  protected comuni: ComuneDTO[] = [];
  protected filteredComuni: any[] = [];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private praticheScuolaService: PraticheScuolaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private firestoreService:AngularFirestore,
    private comuniService:ComuniService,
  ) {
    this.tipiSede = [
      { id: 1, codice: 'A' },
      { id: 2, codice: 'B' },
      { id: 3, codice: 'B*' },
      { id: 4, codice: 'C' },
      { id: 5, codice: 'D' },
      { id: 6, codice: 'COL' },
    ];
  }

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.praticaFromFirestore = this.config.data.praticaFromFirestore;

    this.scuola = this.config.data.scuola;
    this.loadComuni();
    this.inizializzaFormGroups();
    this.inizializzaSteps();
  }
  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      //this.filteredComuni = res;
      this.comuniLoaded = true;
    });
  }
  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
      let comune = this.comuni[i];
      if (
        comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(comune);
      }
    }
    this.filteredComuni = filtered;
  }
  inizializzaFormGroups() {
    this.dettagliForm = this._formBuilder.group({
      codiceInvio: [this.pratica.codiceInvio, [Validators.required]],
      codiceInfratel: new FormControl({value: this.pratica.codiceInfratel, disabled: true}, Validators.required), 
      tipoSede: [this.pratica.tipoSede, [Validators.required]],
      tipoSedeEffettiva: [this.pratica.tipoSedeEffettiva, [Validators.required]],
      //colocata: [null, [Validators.required]],
      backup: [ this.praticaFromFirestore.backup?true:false, [Validators.required]],
    //  wifi: [this.praticaFromFirestore.wifi?true:false, [Validators.required]],
      lavorabile: [this.pratica.lavorabile, [Validators.required]],

      azienda: new FormControl({value: this.pratica.azienda, disabled: true}, Validators.required), //[this.pratica.azienda],

    });
    
    this.scuolaForm = this._formBuilder.group({
      denominazioneScuola: [this.scuola.denominazione],
      codiceMiur: [this.scuola.codiceMiur],
      indirizzoScuola: [this.scuola.indirizzo, [Validators.required]],
      comuneScuola: [this.scuola.comune, [Validators.required]],
      //regioneScuola: new FormControl({value: this.scuola.comune.regione.denominazione, disabled: true}, Validators.required),

      latitudineScuola: [this.praticaFromFirestore.coordinateScuola.latitude, [Validators.required]],
      longitudineScuola: [this.praticaFromFirestore.coordinateScuola.longitude, [Validators.required]],
      
    });
    this.centraleForm = this._formBuilder.group({
      idCentrale: [
        this.pratica.idCentrale,
        [Validators.required],
      ],
      nomeCentrale: [
        this.pratica.nomeCentrale,
        [Validators.required],
      ],
      indirizzoCentrale: [
        this.pratica.indirizzoCentrale,
        [Validators.required],
      ],
      comuneCentrale: [this.scuola.comune, [Validators.required]],
   //   regioneCentrale: [this.scuola.comune.regione.denominazione, [Validators.required]],
      latitudineCentrale: [
        this.praticaFromFirestore.coordinateCentrale.latitude,
        [Validators.required],
      ],
      longitudineCentrale: [
        this.praticaFromFirestore.coordinateCentrale.longitude,
        [Validators.required],
      ],
    })
  }
  inizializzaSteps() {
    this.pagineModificaPratica = [
      {
        label: "Dettagli",
      },
      {
        label: "Scuola",
      },
      {
        label: "Centrale",
      }
    ];
    this.activeIndex = 0;
  }

  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineModificaPratica.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 0 && !this.dettagliForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 1 && !this.scuolaForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 2 && !this.centraleForm.valid) {
      disableNextStepButton = true;
    } 

    return disableNextStepButton;
  }

  changePage() {
    this.activeIndex = this.activeIndex + 1;
  }
 

  openMappaScuola(){
    this.logger.info("---OPENMAPPA SCUOLA----");
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:true,
      },
      header: "Posizione della scuola",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.scuolaForm.get("latitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.latitude);
        this.scuolaForm.get("longitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.longitude);
      });
    });
  }
  openMappaCentrale(){
    this.logger.info("---OPENMAPPA CENTRALE----");
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:false,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.centraleForm.get("latitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.latitude);
        this.centraleForm.get("longitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.longitude);
      });
    });
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let praticaDaModificare: ModificaPraticaDTO = new ModificaPraticaDTO();
      let scuolaDaModificare: ScuolaDTO = this.scuola;
          praticaDaModificare.id                  = this.pratica.id;
          praticaDaModificare.codiceInfratel      = this.dettagliForm.value.codiceInfratel;
          praticaDaModificare.codiceInvio         = this.dettagliForm.value.codiceInvio;
          praticaDaModificare.tipoSede            = this.dettagliForm.value.tipoSede;
          praticaDaModificare.tipoSedeEffettiva           = this.dettagliForm.value.tipoSedeEffettiva;
          praticaDaModificare.lavorabile          = this.dettagliForm.value.lavorabile;
          praticaDaModificare.wifi                = this.dettagliForm.value.wifi;
          praticaDaModificare.backup              = this.dettagliForm.value.backup;

          praticaDaModificare.idScuola            = this.scuola.id;
          praticaDaModificare.denominazioneScuola = this.scuolaForm.value.denominazioneScuola;
          praticaDaModificare.indirizzoScuola     = this.scuolaForm.value.indirizzoScuola;
          praticaDaModificare.codiceMiur          = this.scuolaForm.value.codiceMiur;
          praticaDaModificare.comuneScuola        = this.scuolaForm.value.comuneScuola.denominazione;
          praticaDaModificare.latitudineScuola    = this.scuolaForm.value.latitudineScuola;
          praticaDaModificare.longitudineScuola   = this.scuolaForm.value.longitudineScuola;
       
          praticaDaModificare.idCentrale          = this.centraleForm.value.idCentrale;
          praticaDaModificare.nomeCentrale        = this.centraleForm.value.nomeCentrale;
          praticaDaModificare.indirizzoCentrale   = this.centraleForm.value.indirizzoCentrale;
          praticaDaModificare.comuneCentrale      = this.centraleForm.value.comuneCentrale.denominazione;
          praticaDaModificare.latitudineCentrale  = this.centraleForm.value.latitudineCentrale;
          praticaDaModificare.longitudineCentrale = this.centraleForm.value.longitudineCentrale;

          this.logger.info(praticaDaModificare);
          this.praticheScuolaService
            .modifica(praticaDaModificare)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di MODIFICA della pratica",
                });
                this.dialogRef.close({ success: false });
              },
              complete: () => {
                this.logger.info("MODIFICA PRATICA OK!");
                this.dialogRef.close({ success: true });
              
              },
            });

    
    }
  }
}

@Component({
  selector: "app-fotoRouter-bando2",
  templateUrl: "./dialog-carica-fotoRouter.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaFotoRouter implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "router." + fileExtension;
      this.googleStorageService
        //.uploadFileScuolaToGoogleStorage(this.selectedFile, this.pratica.codiceInfratel + "/IN/" + this.selectedFile.name)
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-pdfPlanimetria-bando2",
  templateUrl: "./dialog-carica-pdfPlanimetria.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaPDFPlanimetria implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "PLANIMETRIA_" + this.pratica.codiceInfratel+"."+fileExtension;
      this.googleStorageService
        //.uploadFileScuolaToGoogleStorage(this.selectedFile, this.pratica.codiceInfratel + "/IN/" + this.selectedFile.name)
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}


@Component({
  selector: "app-storico-pratica-bando2",
  templateUrl: "./dialog-storico-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogStoricoPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola_Firestore;
  public storico: StoricoPraticaScuola[]=[];

  public dataAssegnazione:Date;
  public dataInizioInCarico:Date;
  public dataInizioProgettazione:Date;
  public dataConsegna:Date;
  public dataRework:Date;
  public dataApprovazione:Date;
  public dataRespingimento:Date;

  public isAssegnata:boolean=false;
  public isInCarico:boolean=false;
  public isAnnullata:boolean=false;
  public isInProgettazione:boolean=false;
  public isConsegnata:boolean=false;
  public isInRework:boolean=false;
  public isApprovata:boolean=false;
  public isRespinta:boolean=false;

  
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.storico = this.config.data.storicoPratica;
    this.logger.info("---> STORICO CONSTA DI " + this.storico.length+ " RIGHE");
    
    this.processStoricoPratica();
    
  }

  processStoricoPratica(){
    if(this.pratica.stato ==='ASSEGNATA'){
      this.isAssegnata = true;
    }else if(this.pratica.stato ==='IN CARICO IMPRESA'){
      this.isInCarico = true;
    }else if(this.pratica.stato ==='ANNULLATA'){
      this.isAnnullata = true;
    }else if(this.pratica.stato ==='IN PROGETTAZIONE'){
      this.isInProgettazione = true;
    }else if(this.pratica.stato ==='CONSEGNATA'){
      this.isConsegnata = true;
    }else if(this.pratica.stato ==='IN REWORK AZIENDA'){
      this.isInRework = true;
    }else if(this.pratica.stato ==='APPROVATA'){
      this.isApprovata = true;
    }else if(this.pratica.stato ==='RESPINTA'){
      this.isRespinta = true;
    }
    this.storico.forEach(storicoPratica =>{
      this.logger.info("---> RIGA STORICO " + storicoPratica.stato.codice);

      if(storicoPratica.stato.codice === "ASSEGNATA"){
        this.dataAssegnazione = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "IN CARICO IMPRESA"){
        this.dataInizioInCarico = storicoPratica.iniziato;
      } else if(storicoPratica.stato.codice === "IN PROGETTAZIONE"){
        this.dataInizioProgettazione = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "CONSEGNATA"){
        this.dataConsegna = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "IN REWORK AZIENDA"){
        this.dataRework = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "APPROVATA"){
        this.dataApprovazione = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "RESPINTA"){
        this.dataRespingimento = storicoPratica.iniziato;
      }
    });
   
    //this.dataAssegnazione =  new Date();
  }
  
}


@Component({
  selector: "app-posizione-bando2",
  templateUrl: "./dialog-posizione-scuola.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogPosizioneScuola implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola_Firestore;
  public praticaCompleta: PraticaScuola;
  protected positionHasChanged:boolean = false;
  
  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica          = this.config.data.pratica;
    this.praticaCompleta  = this.config.data.praticaCompleta;
  }
  initMarkers() {
    const initialMarkers = [
      {
        position: {
          lat: this.pratica.coordinateScuola.latitude.valueOf(),
          lng: this.pratica.coordinateScuola.longitude.valueOf(),
        },
        draggable: false,
      },
    
    ];
    const dataScuola = initialMarkers[0];
    const markerScuola = this.generateMarker(dataScuola, 0, true);
    let popupScuola = "SCUOLA <b> "+ this.pratica.denominazione + "</b><br>" +
                      this.pratica.indirizzo + "<br>"+
                      "<b>" + this.pratica.comune + "</b><br>";
    
 
    markerScuola.addTo(this.map).bindPopup(popupScuola);
    this.markers.push(markerScuola);

    if(this.pratica.coordinateCentrale && this.pratica.coordinateCentrale!=null){

      initialMarkers.push(  {
        position: {
          lat: this.pratica.coordinateCentrale.latitude.valueOf(),
          lng: this.pratica.coordinateCentrale.longitude.valueOf(),
        },
        draggable: false,
      },);
      const dataCentrale = initialMarkers[1];
      const markerCentrale = this.generateMarker(dataCentrale, 0, false);
      let popupCentrale = "CENTRALE <b> "+ this.praticaCompleta.nomeCentrale + "</b><br>" +
      this.praticaCompleta.indirizzoCentrale + "<br>"+
      "<b>" + this.praticaCompleta.comuneCentrale + "</b><br>";
      markerCentrale.addTo(this.map).bindPopup(popupCentrale);
      this.markers.push(markerCentrale);
    }
    
    this.map.panTo(dataScuola.position);

  }

  markerOver(event: any, index: number){
    const marker = event.target;
    marker.openPopup();
  }
  markerOut(event, index) {
    const marker = event.target;
    marker.closePopup();
  }
  generateMarker(data: any, index: number, isScuola:boolean) {
    let markerIconScuola = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-bando2.png',
      //iconSize: [40, 25],
      iconSize: [25, 41],
    });
    let markerIconCentrale= new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-centrale.png',
    iconSize: [25, 41],
    });
    if(isScuola){
      return Leaflet.marker(data.position, { draggable: data.draggable, icon:markerIconScuola })
        //.on("click", (event) => this.markerClicked(event, index))
        .on("mouseover", (event) => this.markerOver(event, index))
        .on("mouseout", (event) => this.markerOut(event, index))
        .on("dragend", (event) => this.markerDragEnd(event, index));
    }else{
      return Leaflet.marker(data.position, { draggable: data.draggable, icon:markerIconCentrale })
     // .on("click", (event) => this.markerClicked(event, index))
      .on("mouseover", (event) => this.markerOver(event, index))
      .on("mouseout", (event) => this.markerOut(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
    }
    
  }
  markerClicked($event: any, index: number) {}

  markerDragEnd($event: any, index: number) {}
  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

//import 'leaflet-draw';

@Component({
  selector: "app-modifica-posizione-bando2",
  templateUrl: "./dialog-modifica-posizione.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogModificaPosizione implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected isScuola:boolean = true;
  public pratica: PraticaScuola_Firestore;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;
  protected initialLat: number;
  protected initialLng: number;
  protected finalLat: number;
  protected finalLng: number;
  protected positionHasChanged:boolean = false;

  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.isScuola = this.config.data.isScuola;
  }
  initMarkers() {
    let initialMarkers = [];
    if(this.isScuola){
       initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateScuola.latitude.valueOf(),
            lng: this.pratica.coordinateScuola.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }else {
      initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateCentrale.latitude.valueOf(),
            lng: this.pratica.coordinateCentrale.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }
  
    const data = initialMarkers[0];
    const marker = this.generateMarker(data, 0);
    marker
      .addTo(this.map)
      .bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
    this.map.panTo(data.position);
    this.markers.push(marker);
  }

  generateMarker(data: any, index: number) {
    return Leaflet.marker(data.position, { draggable: data.draggable })
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
  }
  markerClicked($event: any, index: number) {}

 

  markerDragEnd($event: any, index: number) {
    this.logger.info("NUOVE COORDINATE " + $event.target._latlng.lat + " "+ $event.target._latlng.lng);

    if(this.initialLat!==$event.target._latlng.lat && this.initialLng!==$event.target._latlng.lng){
      this.positionHasChanged = true;
      this.finalLat = $event.target._latlng.lat;
      this.finalLng = $event.target._latlng.lng;
    }
    
    
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let nuoveCoordinate:GeoPoint = new GeoPoint( this.finalLat ,this.finalLng );
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      let updatedData:any ;// { coordinateScuola: nuoveCoordinate };
      if(!this.isScuola){
        updatedData  = { coordinateCentrale : nuoveCoordinate };
      }else {
        updatedData  = { coordinateScuola : nuoveCoordinate };
      }
   
      documentRef
        .update(updatedData)
        .then(() => {
          this.logger.info( "OK AGGIORNAMENTO COORDINATE SU FIRESTORE");
          this.dialogRef.close({ success: true });
        })
        .catch((error) => {
          this.logger.error(
            "ERRORE AGGIORNATO COORDINATE SU FIRESTORE"
          );
        });
    }
  }
    

  

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

@Component({
  selector: "app-planimetria-bando2",
  templateUrl: "./dialog-carica-planimetria.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaPlanimetria implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFirestore: PraticaScuola_Firestore;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.praticaFirestore = this.config.data.praticaFirestore;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      let fileName:string="";
      this.logger.info(this.praticaFirestore);
      if(this.praticaFirestore.tipoSedeEffettiva==='D'){
         fileName =
        //this.pratica.codiceInfratel + "/IN/PLANIMETRIA_"+ this.pratica.codiceInfratel + fileExtension;
        
        this.pratica.codiceInfratel + "/IN/PLANIMETRIA_"+ this.praticaFirestore.codiceInfratel + "."+fileExtension;
      }else{
         fileName = this.praticaFirestore.codiceInfratel + "/IN/planimetria."+ fileExtension;
        //this.pratica.codiceInfratel + "/IN/PLANIMETRIA_"+ this.pratica.codiceInfratel + fileExtension;

      }
      

      this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
          //this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-nt9-bando2",
  templateUrl: "./dialog-carica-nt9.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaNT9 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "nt9." + fileExtension;
      this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-verbale-firmato-bando2",
  templateUrl: "./dialog-carica-verbale-firmato.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaVerbaleFirmato implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public pratica_firestore: PraticaScuola_Firestore;
  selectedFiles: File[] = [];
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.pratica_firestore = this.config.data.pratica_firestore;
    
   // this.logger.info("> ? " + this.pratica_firestore.tipoSede + " - "+ this.pratica_firestore.tipoSedeEffettiva);
  }
  onFileUpload($event) {
    this.logger.info("> ? " + this.pratica_firestore.tipoSede + " - "+ this.pratica_firestore.tipoSedeEffettiva);
    //this.pratica_firestore.tipoSede ==='D' || 
    if((this.pratica_firestore.tipoSedeEffettiva && this.pratica_firestore.tipoSedeEffettiva ==='D') ){
      //this.logger.info("> TIPO D "+ this.pratica_firestore.colocata + " - "+ this.pratica_firestore.colocataRuolo==='SECONDARIA' + " -> "+ this.pratica.scuola.cpeInStessoLocale);

      if(this.pratica_firestore.colocata && this.pratica_firestore.colocataRuolo==='SECONDARIA' && this.pratica.scuola.cpeInStessoLocale){
        this.isFileUploaded = true;
        this.selectedFiles = $event.files;
      }
      if($event.files.length ==2){
        this.isFileUploaded = true;
        this.selectedFiles = $event.files;

      }
    }else{

      this.isFileUploaded = true;
      this.selectedFiles = $event.files;
    }
    //this.isFileUploaded = true;
    //this.selectedFiles = $event.files;
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if( this.selectedFiles.length >1){
        const promises: Promise<void>[] = [];
        const promise = new Promise<void>((resolve, reject) => {
          this.selectedFiles.forEach(file =>{
            const fileExtension = file.name.substring(
              file.name.lastIndexOf(".") + 1
            );
            

            const fileName = this.pratica.codiceInfratel + "/OUT/SIGNED/"+file.name;
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(file, fileName)
              .then((uploadRes) => {

                resolve();
                promises.push(promise); 
              })
              .catch((errore) => {
                
                reject();
                promises.push(promise); 
              });
              
          });
        });
        Promise.all(promises).then(() => {
          this.dialogRef.close({ success: true});
        }).catch((error) => {
          this.logger.error("Errore durante l'esecuzione delle promesse:", error);
          this.messageService.add({
                        severity: "info",
                       summary: "Errore" , life:10000,
                        detail: "Errore in fase di caricamento File!",
                      });
          this.dialogRef.close({ success: false});
        
        });

      }else{
          let selectedFile:File = this.selectedFiles[0];
          const fileExtension = selectedFile.name.substring(
            selectedFile.name.lastIndexOf(".") + 1
          );
          const fileName = this.pratica.codiceInfratel + "/OUT/SIGNED/"+selectedFile.name;
          this.logger.info("--> fileName "+ fileName);
            this.googleStorageService
            .uploadFileScuolaToGoogleStorage(selectedFile, fileName)
            .then((uploadRes) => {
              this.dialogRef.close({ success: true });
            })
            .catch((errore) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di caricamento File!",
              });
    
              this.dialogRef.close({ success: false });
            });
    
      }

      
      }
    }
  
}



@Component({
  selector: "app-pacchetto-firmato-bando2",
  templateUrl: "./dialog-carica-pacchetto-firmato.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaPacchettoFirmato implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    
  }
  
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.logger.info(this.selectedFile.name);
      /* const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      ); */
      const fileName =
        this.pratica.codiceInfratel + "/OUT/SIGNED/"+this.selectedFile.name;
        this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        }); 
    }
  }
}

@Component({
  selector: "app-verbali-firmati-bando2",
  templateUrl: "./dialog-carica-pacchetti-firmati.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaVerbaliFirmati implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected files: NgxFileDropEntry[] = [];
  protected dropZoneLabel="Trascina qui i verbali firmati";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
    private praticheScuolaService: PraticheScuolaService,
    
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.logger.info("NGINIT @ CARICA VERBALI");
  }
  public onFileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    
    if(files.length > 0){
      this.dropZoneLabel = "Sono stati caricati " + files.length+ " verbali. Procedi con la conferma!"
      this.isFileUploaded = true;
    }else{
      this.dropZoneLabel = "Trascina qui i verbali firmati!"
      this.isFileUploaded = false;
    }
    
  }

  onFileUpload($event) {
    this.logger.info($event.files);
    this.logger.info(this.pratica.tipoSedeEffettiva.codice);
    if(this.pratica.tipoSedeEffettiva.codice ==='D' ){
      if($event.files.length ==2){
        this.isFileUploaded = true;
      }
    }else{
      this.isFileUploaded = true;
      this.selectedFile = $event.files[0];
    }
    
    
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let fileCaricati: number = 0;
    let fileErrore: number = 0;
    const promises: Promise<void>[] = [];

    for (const droppedFile of this.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const promise = new Promise<void>((resolve, reject) => {
          let listaPraticheTipoDConIRUCaricata:String[]=[];
          fileEntry.file((file: File) => {
            this.logger.info("DOVREI CARICARE IL file " + file.name );
            let codiceInfratel=null;
            if(file.name.startsWith("Contratto_Cessione_Proprietà_Nuove_Infrastrutture")){
              this.logger.info("CODICE INFRATEL = " + file.name.substring(51, file.name.indexOf(".")));
              codiceInfratel = file.name.substring(51, file.name.indexOf("."));
            }else if(file.name.startsWith("Contratto_Cessione_IRU")){
              this.logger.info("CODICE INFRATEL = " + file.name.substring(file.name.indexOf("I-"), file.name.indexOf(".")) );
              codiceInfratel = file.name.substring(file.name.indexOf("I-"), file.name.indexOf("."));
            }
            
            //this.logger.info("DOVREI CARICARE IL file " + file.name.substring(0, file.name.indexOf(".")) + " > " + file.size);
            

            if (codiceInfratel) {
              this.logger.info("codiceInfratel OTTENUTO DAL NOME DEL FILE ---> " + codiceInfratel);
            

              //SE LA PRATICA è DI TIPO D >>> VERIFICO SE C'E' GIA' L'ALTRO...
              
              const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola).ref;
              const query = collectionRef
              .where("codiceInfratel", "==", codiceInfratel)
              .where("stato", "==", "IN FIRMA VERBALE");
              query.get().then(qs => {
                if (qs.docs && qs.docs.length > 0) {
                  
                  const pratica = qs.docs[0].data() as PraticaScuola_Firestore;
                  const fileName = pratica.codiceInfratel + "/OUT/SIGNED/" + file.name;
                  if(pratica.tipoSede!='D'){
                    this.logger.info("PRATICA NON E' DEL TIPO D ED IL FILE E " + file.name);

                    //SE LA PRATICA NON E' DI TIPO D > CONTINUO COME SEMPRE
                    
                    let praticaDaAggiornare:PraticaScuola = new PraticaScuola();
                    praticaDaAggiornare.id = pratica.id;
                     this.googleStorageService
                      .uploadFileScuolaToGoogleStorage(file, fileName)
                      .then((uploadRes) => {
                        this.praticheScuolaService
                        .confermaCaricamentoVerbale(praticaDaAggiornare)
                        .subscribe((res) => {
                          this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO VERBALE");
                          fileCaricati = fileCaricati + 1;

                          resolve();
                        }); 
                      
                      })
                      .catch((errore) => {
                        fileErrore = fileErrore + 1;
                        resolve();
                      }); 
                  }else{
                    this.logger.info("PRATICA E' DEL TIPO D ED IL FILE E " + file.name);
                    let praticaDaAggiornare:PraticaScuola = new PraticaScuola();
                      praticaDaAggiornare.id = pratica.id;
                      this.logger.info("codiceInfratel?  " + codiceInfratel);
                      if(file.name.startsWith("Contratto_Cessione_Proprietà_Nuove") ){
                         this.googleStorageService
                          .uploadFileScuolaToGoogleStorage(file, fileName)
                          .then((uploadRes) => {
                            this.praticheScuolaService
                            .confermaCaricamentoVerbale(praticaDaAggiornare)
                            .subscribe((res) => {
                              this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO VERBALE");
                              fileCaricati = fileCaricati + 1;
                              resolve();
                            }); 
                          
                          })
                          .catch((errore) => {
                            fileErrore = fileErrore + 1;
                            resolve();
                          });  
                      }else{
                       this.googleStorageService
                        .uploadFileScuolaToGoogleStorage(file, fileName)
                        .then((uploadRes) => {
                          
                          fileCaricati = fileCaricati + 1;
                          resolve();
                         
                    
                        })
                        .catch((errore) => {
                          fileErrore = fileErrore + 1;
                          resolve();
                        });  
                    }
                    
                  }
                  
                } else {
                  fileErrore = fileErrore + 1;
                  this.logger.info("FILE "+ file.name + " RELATIVO A PRATICA NON ESISTENTE O NON IN STATO IN FIRMA VERBALE");
                  resolve();
                }
              });
            } else {
              this.logger.error("IMPOSSIBILE RECUPERARE LA PRATICA DEL FILE " + file.name);
              fileErrore = fileErrore + 1;
              resolve();
            } 
          });
        });

        promises.push(promise);
      }
    }

    // Esegui il codice alla fine del ciclo principale
    Promise.all(promises).then(() => {
      this.logger.info("--> ALLA FINE DEI CARICAMENTI --> OK " + fileCaricati + " VS KO " + fileErrore);
      this.dialogRef.close({ success: true, numeroPacchettiCaricati: fileCaricati, numeroPacchettiNonCaricati: fileErrore});
    }).catch((error) => {
      this.logger.error("Errore durante l'esecuzione delle promesse:", error);
      this.dialogRef.close({ success: false, numeroPacchettiCaricati: 0, numeroPacchettiNonCaricati: 0});

    });

  }  
  }
}

@Component({
  selector: "app-pacchetti-firmati-bando2",
  templateUrl: "./dialog-carica-pacchetti-firmati.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaPacchettiFirmati implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected files: NgxFileDropEntry[] = [];
  protected dropZoneLabel="Trascina qui i pacchetti firmati";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
    private praticheScuolaService: PraticheScuolaService,
    
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    
  }
  public onFileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    
    if(files.length > 0){
      this.dropZoneLabel = "Sono stati caricati " + files.length+ " pacchetti. Procedi con la conferma!"
      this.isFileUploaded = true;
    }else{
      this.dropZoneLabel = "Trascina qui i pacchetti firmati!"
      this.isFileUploaded = false;
    }
    
  }

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let fileCaricati: number = 0;
let fileErrore: number = 0;
const promises: Promise<void>[] = [];

for (const droppedFile of this.files) {
  if (droppedFile.fileEntry.isFile) {
    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    const promise = new Promise<void>((resolve, reject) => {
      fileEntry.file((file: File) => {
        this.logger.info("DOVREI CARICARE IL file " + file.name.substring(0, file.name.indexOf(".")) + " > " + file.size);
        const codiceInvio = file.name.substring(0, file.name.indexOf("."));

        if (codiceInvio) {
          this.logger.info("codiceInvio OTTENUTO DAL NOME DEL FILE ---> " + codiceInvio);

          const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola).ref;
          const query = collectionRef.where("codiceInvio", "==", codiceInvio);
          query.get().then(qs => {
            if (qs.docs && qs.docs.length > 0) {
              const pratica = qs.docs[0].data() as PraticaScuola_Firestore;
              const fileName = pratica.codiceInfratel + "/OUT/SIGNED/" + file.name;
              let praticaDaAggiornare:PraticaScuola = new PraticaScuola();
              praticaDaAggiornare.id = pratica.id;
              this.googleStorageService
                .uploadFileScuolaToGoogleStorage(file, fileName)
                .then((uploadRes) => {
                  this.praticheScuolaService
                  .confermaCaricamentoPacchetto(praticaDaAggiornare)
                  .subscribe((res) => {
                    this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
                    fileCaricati = fileCaricati + 1;

                    resolve();
                  });
                 
                })
                .catch((errore) => {
                  fileErrore = fileErrore + 1;
                  resolve();
                });
            } else {
              fileErrore = fileErrore + 1;
              resolve();
            }
          });
        } else {
          this.logger.error("IMPOSSIBILE RECUPERARE LA PRATICA DEL FILE " + file.name);
          fileErrore = fileErrore + 1;
          resolve();
        }
      });
    });

    promises.push(promise);
  }
}

// Esegui il codice alla fine del ciclo principale
Promise.all(promises).then(() => {
  this.logger.info("--> ALLA FINE DEI CARICAMENTI --> OK " + fileCaricati + " VS KO " + fileErrore);
  this.dialogRef.close({ success: true, numeroPacchettiCaricati: fileCaricati, numeroPacchettiNonCaricati: fileErrore});
}).catch((error) => {
  this.logger.error("Errore durante l'esecuzione delle promesse:", error);
  this.dialogRef.close({ success: false, numeroPacchettiCaricati: 0, numeroPacchettiNonCaricati: 0});

});

    }  
  }
}


@Component({
  selector: "app-carica-nt9-firmato-bando2",
  templateUrl: "./dialog-carica-nt9-firmato.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCaricaNT9Firmato implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  public files: File[] = [];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.logger.info("PRATICA PASSATA ALLA MASCHERA");
    this.logger.info(this.pratica);
  }
  
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName = 
        this.pratica.codiceInfratel + "/OUT/AB_"+this.pratica.codiceInfratel.substring(10,this.pratica.codiceInfratel.length)+"."+ fileExtension;
        this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-note-rework-bando2",
  templateUrl: "./dialog-note-rework-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogNoteRework implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public noteRework:NotaReworkPratica[]=[];
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.noteRework = this.config.data.note;
    this.logger.info(this.noteRework)
  }
  
  conferma(conferma: boolean) {
    
  }
}


@Component({
  selector: "app-note-respingimento-bando2",
  templateUrl: "./dialog-inserisci-note-respingimento-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogInserisciNoteRespingimentoPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public note:String="";

  noteRespigimentoFile: File = null;
  estensioneFile:String="";

  isNoteRespingimentoFileUploaded: boolean = false;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;


  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private googleStorageService:GoogleStorageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  modificoNote(){

  }

  onNoteRespingimentoUpload($event) {
    this.isNoteRespingimentoFileUploaded = true;
    this.noteRespigimentoFile = $event.files[0]; 
    this.estensioneFile = this.noteRespigimentoFile.name.slice(((this.noteRespigimentoFile.name.lastIndexOf(".") - 1) >>> 0) + 2);

  }
  onNoteRespingimentoRemove() {
    this.isNoteRespingimentoFileUploaded = false; 
    this.estensioneFile ="";

  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let noteRespingimento:NotaPraticaDTO = new NotaPraticaDTO();
      noteRespingimento.note = this.note;
      this.logger.info(noteRespingimento);
       this.praticheScuolaService
        .comunicaRespingimentoPratica(this.pratica,noteRespingimento)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di COMUNICAZIONE RESPINGIMENTO della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            //carico il File!
           

            if(this.isNoteRespingimentoFileUploaded){
              const fileName = this.pratica.codiceInfratel + "/IN/" + "NOTE_RESPINGIMENTO_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile ;
              this.logger.info("STO CERCANDO DI CARICARE IL File " + fileName);
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(this.noteRespigimentoFile, fileName)
              .then((uploadRes) => { 
                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
                const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
                const updatedData = { noteRespingimentoFile: "NOTE_RESPINGIMENTO_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile };
                documentRef
                  .update(updatedData)
                  .then(() => {
                    this.dialogRef.close({ success: true });
                  })
                  .catch((error) => {
                    this.logger.error(
                      "ERRORE AGGIORNATO CAMPO noteRespingimentoUploaded SU FIRESTORE"
                    );
                  });
              })
              .catch((errore) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento del File NOTE RESPINGIMENTO!",
                });
                this.dialogRef.close({ success: false });
              });
            }else{
              this.dialogRef.close({ success: true });
            }

          },
        }); 
    }
  }
}

@Component({
  selector: "app-inserisci-note-rework-bando2",
  templateUrl: "./dialog-inserisci-note-rework-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogInserisciNoteRework implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public note:String="";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;
  private isReworkPresidio:Boolean = false;

  noteReworkFile: File = null;
  estensioneFile:String="";
  isNoteReworkFileUploaded: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private googleStorageService:GoogleStorageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.isReworkPresidio = this.config.data.isReworkPresidio?true:false;
  }
  modificoNote(){

  }
  onNoteReworkUpload($event) {
    this.isNoteReworkFileUploaded = true;
    this.noteReworkFile = $event.files[0]; 
    this.estensioneFile = this.noteReworkFile.name.slice(((this.noteReworkFile.name.lastIndexOf(".") - 1) >>> 0) + 2);

  }
  onNoteReworkRemove() {
    this.isNoteReworkFileUploaded = false; 
    this.estensioneFile ="";

  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let noteRework:NotaPraticaDTO = new NotaPraticaDTO();
      noteRework.note = this.note;
      if(this.isReworkPresidio){
        this.praticheScuolaService
        .mandaInReworkPresidio(this.pratica,noteRework)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore" , life:10000,
              detail: !this.isReworkPresidio?"Errore in fase di REINVIO ALL'IMPRESA della pratica":"Errore in fase di REINVIO della pratica al presidio Smart Fiber",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            if(this.isNoteReworkFileUploaded){
              const fileName = this.pratica.codiceInfratel + "/IN/" + "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile ;
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(this.noteReworkFile, fileName)
              .then((uploadRes) => { 
                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
                const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
                const updatedData = { noteReworkFile: "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile };
                documentRef
                  .update(updatedData)
                  .then(() => {
                    this.dialogRef.close({ success: true });
                  })
                  .catch((error) => {
                    this.logger.error(
                      "ERRORE AGGIORNATO CAMPO noteReworkFile SU FIRESTORE"
                    );
                  });
              })
              .catch((errore) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento del File NOTE REWORK!",
                });
                this.dialogRef.close({ success: false });
              });
            }else{
              this.dialogRef.close({ success: true });
            }

          },
        });
      }else {
        this.praticheScuolaService
        .mandaInRework(this.pratica,noteRework)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore" , life:10000,
              detail: !this.isReworkPresidio?"Errore in fase di REINVIO ALL'IMPRESA della pratica":"Errore in fase di REINVIO della pratica al presidio Smart Fiber",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            if(this.isNoteReworkFileUploaded){
              const fileName = this.pratica.codiceInfratel + "/IN/" + "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile ;
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(this.noteReworkFile, fileName)
              .then((uploadRes) => { 
                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
                const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
                const updatedData = { noteReworkFile: "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile };
                documentRef
                  .update(updatedData)
                  .then(() => {
                    this.dialogRef.close({ success: true });
                  })
                  .catch((error) => {
                    this.logger.error(
                      "ERRORE AGGIORNATO CAMPO noteReworkFile SU FIRESTORE"
                    );
                  });
              })
              .catch((errore) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento del File NOTE REWORK!",
                });
                this.dialogRef.close({ success: false });
              });
            }else{
              this.dialogRef.close({ success: true });
            }

          },
        });
      }
      
    }
  }
}

@Component({
  selector: "app-assegna-pratica-bando2",
  templateUrl: "./dialog-assegna-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogAssegnaPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public pratiche: PraticaScuola[];

  public azienda:Impresa;
  public aziende:Impresa[]=[];
  protected filteredAziende:Impresa[]=[];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private aziendeService:ImpreseService,
  ) {}

  ngOnInit(): void {
    if(this.config.data.pratica){
      this.pratica = this.config.data.pratica;
    }else if(this.config.data.pratiche){
      this.pratiche = this.config.data.pratiche;
    }
    
    this.loadImprese();
  }

  loadImprese(){
    this.aziendeService.getByBando("2").subscribe(aziende =>{
      this.aziende = aziende;
    });
  }
  filterAzienda(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.aziende.length; i++) {
        let comune = this.aziende[i];
        if (comune.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredAziende = filtered;
  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let impresaSelezionata:Impresa = this.azienda;
      if(this.pratica){
        this.pratica.azienda = impresaSelezionata;
        this.praticheScuolaService
          .assegna(this.pratica)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di assegnazione della pratica",
              });
              this.dialogRef.close({ success: false });
            },
            complete: () => {
              this.dialogRef.close({ success: true });

            },
          });
      } else if(this.pratiche){
        this.pratiche.forEach(pratica =>{
          pratica.azienda = impresaSelezionata;

          this.praticheScuolaService
            .assegna(pratica)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di assegnazione della pratica",
                });
                this.dialogRef.close({ success: false });
              },
              complete: () => {
              

              },
            });
          });
        this.dialogRef.close({ success: true });
      }
      
    }
  }
}

interface TipoVerbale {
  tipoVerbale: string;
}

@Component({
  selector: "app-completa-pratica-bando2",
  templateUrl: "./dialog-completa-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCompletaPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  protected pagineCompletaPratica: MenuItem[];
  protected activeIndex: number = 0;
  protected showSpinner: boolean = false; 
  protected completaPraticaForm: FormGroup;
  protected isTipoD:boolean = false;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;
  protected tipiVerbale:Tipologia[]=[
    {
      codice:"Cessione IRU",
      denominazione:"Cessione IRU"
    },
    {
      codice:"Cessione Proprietà Nuove Infrastrutture",
      denominazione:"Cessione Proprietà Nuove Infrastrutture"
    },
    {
      codice:"Cessione SUB-IRU",
      denominazione:"Cessione SUB-IRU"
    },
  ]
/*   protected cronoprogrammaForm: FormGroup;
  protected computoMetricoForm: FormGroup; */

  /* cronoprogrammaFile: File = null;
  isCronoprogrammaUploaded: boolean = false; */
  computoMetricoFile: File = null;
  isComputoMetricoUploaded: boolean = false;

  isLoadingResults: boolean = false;
  isTipoC: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private _formBuilder: FormBuilder,
    private messageService:MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private firestoreService:AngularFirestore,

  ) {}

  ngOnInit(): void {
    this.pratica              = this.config.data.pratica;
    this.isTipoD = this.pratica.tipoSede.codice==='D'?true:false;
    this.isTipoC = this.pratica.tipoSedeEffettiva.codice==='C'?true:false;

    this.praticaFromFirestore = this.config.data.praticaFirestore;
    this.logger.info(this.praticaFromFirestore);
    this.inizializzaFormGroups();
    //this.inizializzaSteps();
  }

  inizializzaFormGroups() {
    this.completaPraticaForm = this._formBuilder.group({
      ordineEsecuzione: [this.pratica.ordineEsecuzione, !this.isTipoC?[Validators.required]:null],
      //tipiVerbale: [[], [Validators.required]],
    });
   
   
  }

  addTipologiaVerbaleToList(event:any){
    this.logger.info("-> TIPI VERBALE NELLA LISTA "+ this.completaPraticaForm.value.tipiVerbale.length);
  
  }

  onComputoMetricoUpload($event) {
    this.isComputoMetricoUploaded = true;
    this.computoMetricoFile = $event.files[0]; 
    this.logger.info(" onComputoMetricoUpload" + this.computoMetricoFile.name);

  }
  onComputoMetricoRemove() {
    this.isComputoMetricoUploaded = false; 
  }

 /*  onCronoprogrammaUpload($event) {
    this.isCronoprogrammaUploaded = true;
    this.cronoprogrammaFile = $event.files[0];
    this.logger.info(" onCronoprogrammaUpload" + this.cronoprogrammaFile.name);
  }
  onCronoprogrammaRemove() {
    this.isCronoprogrammaUploaded = false;
  }
  */
  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      //1) salvo codice a db
      this.pratica.ordineEsecuzione = this.completaPraticaForm.value.ordineEsecuzione;
      this.showSpinner = true;
      if(this.isTipoC){
          this.praticheScuolaService.completaPratica(this.pratica.id.valueOf()).subscribe(res =>{
        
            if(this.isComputoMetricoUploaded){
           
             const fileName = this.pratica.codiceInfratel + "/OUT/" + this.praticaFromFirestore.codiceRegione+"_F_CMT_03_0.xlsx" ;
 
             this.googleStorageService
             .uploadFileScuolaToGoogleStorage(this.computoMetricoFile, fileName)
             .then((uploadRes) => { 
               const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
               const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
               const updatedData = { computoMetricoUploaded: true };
               documentRef
                 .update(updatedData)
                 .then(() => {
                  
                 })
                 .catch((error) => {
                   this.showSpinner = false;
                   this.logger.error(
                     "ERRORE AGGIORNATO CAMPO computoMetricoUploaded SU FIRESTORE"
                   );
                 });
             })
             .catch((errore) => {
               this.messageService.add({
                 severity: "info",
                summary: "Errore" , life:10000,
                 detail: "Errore in fase di caricamento del computo metrico!",
               });
               this.showSpinner = false;
               this.dialogRef.close({ success: false });
             });
           } 
   
           this.dialogRef.close({ success: true });
           
         }, error =>{
           this.showSpinner = false;
           this.messageService.add({
             severity: "info",
            summary: "Errore" , life:10000,
             detail: "Errore in fase di COMUNICAZIONE NUMERO ORDINE della pratica!",
           });
         })
        
      }else {
      this.praticheScuolaService.comunicaOrdineEsecuzione(this.pratica).subscribe(res =>{
     
        this.praticheScuolaService.completaPratica(this.pratica.id.valueOf()).subscribe(res =>{
        
           if(this.isComputoMetricoUploaded){
          
            const fileName = this.pratica.codiceInfratel + "/OUT/" + this.praticaFromFirestore.codiceRegione+"_F_CMT_03_0.xlsx" ;

            this.googleStorageService
            .uploadFileScuolaToGoogleStorage(this.computoMetricoFile, fileName)
            .then((uploadRes) => { 
              const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
              const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
              const updatedData = { computoMetricoUploaded: true };
              documentRef
                .update(updatedData)
                .then(() => {
                 
                })
                .catch((error) => {
                  this.showSpinner = false;
                  this.logger.error(
                    "ERRORE AGGIORNATO CAMPO computoMetricoUploaded SU FIRESTORE"
                  );
                });
            })
            .catch((errore) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di caricamento del computo metrico!",
              });
              this.showSpinner = false;
              this.dialogRef.close({ success: false });
            });
          } 
  
          this.dialogRef.close({ success: true });
          
        }, error =>{
          this.showSpinner = false;
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di COMUNICAZIONE NUMERO ORDINE della pratica!",
          });
        })
      }, error =>{
        this.showSpinner = false;
        this.messageService.add({
          severity: "info",
         summary: "Errore" , life:10000,
          detail: "Errore in fase di completamento della pratica!",
        });
      });
    }

    }
  }
}

@Component({
  selector: "app-modifica-stato-pratica-bando2",
  templateUrl: "./dialog-modifica-stato-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogModificaStatoPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  protected modificaStatoPraticaForm: FormGroup;

  protected stati:Tipologia[]=[
    {
      codice:"1",
      denominazione:"CARICATA"
    },
    {
      codice:"2",
      denominazione:"ASSEGNATA"
    },
    {
      codice:"3",
      denominazione:"IN CARICO IMPRESA"
    },
    {
      codice:"15",
      denominazione:"IN PROGETTAZIONE"
    },
    {
      codice:"4",
      denominazione:"CONSEGNATA"
    },
    {
      codice:"5",
      denominazione:"IN VERIFICA SMARTFIBER"
    },
    {
      codice:"6",
      denominazione:"IN CARICO PRESIDIO"
    },
    {
      codice:"17",
      denominazione:"IN FIRMA VERBALE"
    },
    {
      codice:"18",
      denominazione:"IN FIRMA PACCHETTO"
    },
    {
      codice:"8",
      denominazione:"FIRMATA"
    },
    {
      codice:"9",
      denominazione:"PUBBLICATA"
    },
    {
      codice:"10",
      denominazione:"APPROVATA"
    },
    {
      codice:"11",
      denominazione:"RESPINTA"
    },
  ]

  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private messageService:MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private confirmationService: ConfirmationService,

  ) {}

  ngOnInit(): void {
    this.praticaFromFirestore = this.config.data.pratica;
    
    this.inizializzaFormGroups();
    //this.inizializzaSteps();
  }

  inizializzaFormGroups() {
    this.modificaStatoPraticaForm = this._formBuilder.group({
      stato: [this.praticaFromFirestore.stato, [Validators.required]],
      //tipiVerbale: [[], [Validators.required]],
    });
   
   
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.confirmationService.confirm({
        message: "Confermi di voler forzare lo stato della pratica a <strong>" + this.modificaStatoPraticaForm.value.stato.denominazione+ "</strong> ?",
        header: "ATTENZIONE! Operazione NON reversibile",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => { 
           this.praticheScuolaService.modificaStatoPratica(this.praticaFromFirestore.id.toString(),this.modificaStatoPraticaForm.value.stato.codice).subscribe(res =>{
            this.dialogRef.close({ success: true });
            
            }, error =>{
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di completamento della pratica!",
              });
              this.dialogRef.close({ success: false });
            }); 
        },
        reject: () => {
        },
      });
     
    }
  }
}

