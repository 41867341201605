 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" 
    (click)="loadComuni()" [disabled]="isLoadingResults"></button> 
  </div> 
  
  <div class="flex justify-center">
    <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">COMUNI</span>
  </div>
  
  <div class="flex justify-end md:justify-end">
    
    </div>
  </div>
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
          (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
      </span>
        
    </div>

  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table #comuniDT styleClass="p-datatable-sm" [value]="comuni" dataKey="id" 
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','denominazione','regione.denominazione']" [sortField]="'denominazione'" [sortOrder]="'1'">
     
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="denominazione">
            <div class="flex justify-content-between align-items-center">
              DENOMINAZIONE
              <p-sortIcon field="denominazione"></p-sortIcon>
           </div>
          </th>
          <th pSortableColumn="codiceAlfanumerico">
            <div class="flex justify-content-between align-items-center">
              CODICE ISTAT
              <p-sortIcon field="codiceAlfanumerico"></p-sortIcon>
           </div>
          </th>
          <!-- <th pSortableColumn="regione.denominazione">
            <div class="flex justify-content-between align-items-center">
              Regione
              <p-sortIcon field="regione.denominazione"></p-sortIcon>
            </div>
          </th> -->
        
      
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
        
          <td> <span class="text-lowercase">{{row.denominazione}}</span>
          <td> <span class="text-lowercase">{{row.codiceAlfanumerico}}</span>
<!--           <td> <span class="text-lowercase">{{row.regione.denominazione}}</span>
 -->        
        
         
        </tr>
      </ng-template>
     <!--  <ng-template pTemplate="footer">
        <tr>
         <td class="text-left" style="font-size: 14px;">TOTALI:</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInRework}}</td>

         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInRework}}</td> 
         <td></td>
         
       </tr>
     
   </ng-template> -->
    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
