
<p-steps [model]="pagineInserisciInformazioni" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">

  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngIf="!isTipoC; else elseBlock">
      <ng-container *ngSwitchCase="1">
        <form [formGroup]="reteAccessoForm">
          <div class="grid formgrid p-fluid mt-2">
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="lunghezza" class="font-medium text-900">Lunghezza</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezza" placeholder="Lunghezza della Fibra in metri" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="tipologia" class="font-medium text-900">Tipologia collegamento</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-dropdown inputId="tipologia" [options]="tipologie" optionLabel="codice"
                      [showClear]="true" placeholder="Tipo di collegamento" formControlName="tipologia">
                      <ng-template let-tipo pTemplate="item">
                          <div class="flex align-items-center">
                              <div>{{tipo.codice}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="diritto" class="font-medium text-900">Diritto</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-dropdown inputId="diritto" [options]="diritti" optionLabel="codice"
                  [showClear]="true" placeholder="Tipo di diritto" formControlName="diritto">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="proprietario" class="font-medium text-900">Proprietario</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              
              <p-dropdown inputId="proprietario" [options]="proprietari" optionLabel="codice"
                  [showClear]="true" placeholder="Proprietario" formControlName="proprietario">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
        
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="lunghezzaTratta2" class="font-medium text-900">Lunghezza TRATTA A-B</label>
              <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezzaTratta2" placeholder="Lunghezza della seconda tratta" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD"  class="field mt-2 mb-2 md:col-3 col-12">
              <label for="tipologia" class="font-medium text-900">Tipologia colleg. TRATTA A-B</label>
              <p-dropdown inputId="tipologiaTratta2" [options]="tipologieCavi" optionLabel="codice"
                      [showClear]="true" placeholder="Tipo di collegamento" formControlName="tipologiaTratta2">
                      <ng-template let-tipo pTemplate="item">
                          <div class="flex align-items-center">
                              <div>{{tipo.codice}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="dirittoTratta2" class="font-medium text-900">Diritto TRATTA A-B</label>
              <p-dropdown inputId="dirittoTratta2" [options]="diritti" optionLabel="codice"
                  [showClear]="true" placeholder="Tipo di diritto" formControlName="dirittoTratta2">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="proprietarioTratta2" class="font-medium text-900">Proprietario TRATTA A-B</label>
              <p-dropdown inputId="proprietarioTratta2" [options]="proprietari" optionLabel="codice"
                  [showClear]="true" placeholder="Proprietario" formControlName="proprietarioTratta2">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="hostnameCPE" class="font-medium text-900">Hostname CPE</label>
              <input type="text" formControlName="hostnameCPE" placeholder="Hostname CPS" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="localeTecnico" class="font-medium text-900">Locale Tecnico</label>
              <input type="text" formControlName="localeTecnico" placeholder="Locale Tecnico" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="piano" class="font-medium text-900">Piano</label>
              <input type="text" formControlName="piano" placeholder="Piano" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="stanza" class="font-medium text-900">Stanza</label>
              <input type="text" formControlName="stanza" placeholder="Stanza" pInputText>
            </div>
            <div *ngIf="isColocata" class="field mt-2 mb-2 col">
              <label for="flagCPEinStessoLocale" class="font-medium text-900" style="font-size: smaller;">CPE in stesso locale?</label>
              <div class="flex align-items-center mt-2">
                <p-inputSwitch inputId="flagCPEinStessoLocale" formControlName="flagCPEinStessoLocale"></p-inputSwitch>
              </div>
            </div>
          </div>
        </form> 
      </ng-container>

    
      <ng-container *ngSwitchCase="0">
        <form [formGroup]="identificazioneFibraForm">
        <div class="grid formgrid p-fluid mt-2" >
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="clliSedeA" class="font-medium text-900">CLLI Sede A</label>
            <input type="text" formControlName="clliSedeA" placeholder="Clli della SEDE A" (change)="aggiornaCLLISedeB($event)" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="tipologiaSedeA" class="font-medium text-900">Tipo Sede A</label>
            <input type="text" formControlName="tipologiaSedeA" placeholder="Tipo della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="descrizioneSedeA" class="font-medium text-900">Descrizione Sede A</label>
            <input type="text" formControlName="descrizioneSedeA" placeholder="Descrizione della SEDE A" pInputText>
          </div>
          <div [ngClass]="{'md:col-3': hasBackup, 'md:col-6': !hasBackup}" class="field mt-2 mb-2 col-12">
            <label for="attestazioneAlRipartitoreA" class="font-medium text-900">Attestazione Sede A</label>
            <input type="text" formControlName="attestazioneAlRipartitoreA" placeholder="Attestazione della SEDE A" (change)="aggiornaAttestazioneB($event)"  pInputText>
          </div>
          <div *ngIf="hasBackup" class="field mt-2 mb-2 md:col-3 col-12">
            <label for="attestazioneAlRipartitoreA_BU" class="font-medium text-900">Attest. Sede A - BACKUP</label>
            <input type="text" formControlName="attestazioneAlRipartitoreA_BU" placeholder="Attestazione della SEDE A di BU" (change)="aggiornaAttestazioneB_BU($event)"  pInputText>
          </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-2 col-12">
              <label for="clliSedeB" class="font-medium text-900">CLLI Sede B</label>
              <input type="text" formControlName="clliSedeB" placeholder="Clli della SEDE B" pInputText>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-2 col-12">
              <label for="tipologiaSedeB" class="font-medium text-900">Tipo Sede B</label>
              <input type="text" formControlName="tipologiaSedeB" placeholder="Tipo della SEDE B" pInputText>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-2 col-12">
              <label for="descrizioneSedeb" class="font-medium text-900">Descrizione Sede B</label>
              <input type="text" formControlName="descrizioneSedeB" placeholder="Descrizione della SEDE B" pInputText>
            </div>
            <div *ngIf="isBandoD" [ngClass]="{'md:col-3': hasBackup, 'md:col-6': !hasBackup}" class="field mt-2 mb-2 col-12">
              <label for="attestazioneAlRipartitoreB" class="font-medium text-900">Attestazione Sede B</label>
              <input type="text" formControlName="attestazioneAlRipartitoreB" placeholder="Attestazione della SEDE B" pInputText>
            </div>
            <div *ngIf="isBandoD && hasBackup" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="attestazioneAlRipartitoreB_BU" class="font-medium text-900">Attest. Sede B - BACKUP</label>
              <input type="text" formControlName="attestazioneAlRipartitoreB_BU" placeholder="Attestazione della SEDE B di BU" pInputText>
            </div> 
        <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="clliSedeZ" class="font-medium text-900">CLLI Sede Z</label>
            <input type="text" formControlName="clliSedeZ" placeholder="CLLI della SEDE Z" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="tipologiaSedeZ" class="font-medium text-900">Tipo Sede Z</label>
            <input type="text" formControlName="tipologiaSedeZ" placeholder="Tipo della SEDE Z" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="descrizioneSedeZ" class="font-medium text-900">Descrizione Sede Z</label>
            <input type="text" formControlName="descrizioneSedeZ" placeholder="Descrizione della SEDE A" pInputText>
          </div>
          <div [ngClass]="{'md:col-3': hasBackup, 'md:col-6': !hasBackup}"  class="field mt-2 mb-2 col-12">
            <label for="attestazioneAlRipartitoreZ" class="font-medium text-900">Attestazione Sede Z</label>
            <input type="text" formControlName="attestazioneAlRipartitoreZ" placeholder="Attestazione della SEDE Z" pInputText>
          </div>
          <div *ngIf="hasBackup" class="field mt-2 mb-2 md:col-3 col-12">
            <label for="attestazioneAlRipartitoreZ_BU" class="font-medium text-900">Attest. Sede Z - BACKUP</label>
            <input type="text" formControlName="attestazioneAlRipartitoreZ_BU" placeholder="Attestazione della SEDE Z di BU" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="numeroTotaleCoppie" class="font-medium text-900">Numero Totale Coppie</label>
            <input type="number" formControlName="numeroTotaleCoppie" placeholder="0" min="0" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="numeroCoppieInUso" class="font-medium text-900">Numero Coppie in uso</label>
            <input type="number" formControlName="numeroCoppieInUso" placeholder="0" min="0" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="fibra" class="font-medium text-900">Fibra</label>
            <input type="number" formControlName="fibra" placeholder="0" min="0" pInputText>
          </div>
          <div *ngIf="hasBackup" class="field mt-2 mb-2 md:col-4 col-12">
            <label for="fibra_BU" class="font-medium text-900">Fibra BACKUP</label>
            <input type="number" formControlName="fibra_BU" placeholder="0" min="0" pInputText>
          </div>

          </div>
        </form> 
      
    </ng-container>

      <ng-container *ngSwitchCase="2">
        <form [formGroup]="localizzazioniForm">
        <div class="grid formgrid p-fluid mt-2">
            <div class="field mt-2 mb-2 md:col-5 col-12">
              <label for="indirizzoScuola" class="font-medium text-900">Indirizzo Scuola</label>
              <input type="text" formControlName="indirizzoScuola" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="comuneScuola" class="font-medium text-900" >Comune Scuola</label>
              <input type="text" formControlName="comuneScuola" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineScuola" class="font-medium text-900">Latitudine Scuola</label>
              <p-inputNumber formControlName="latitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineScuola" class="font-medium text-900">Longitudine Scuola</label>
              <p-inputNumber formControlName="longitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Scuola"
                (click)="openMappaScuola()">
              </button>
            </div> 
            <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
              <label for="latitudineNodoFittizio" class="font-medium text-900" >Latitudine Giunto Derivato</label>
              <p-inputNumber formControlName="latitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
              <label for="longitudineNodoFittizio" class="font-medium text-900">Longitudine Giunto Derivato</label>
              <p-inputNumber formControlName="longitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>

            <div class="field mt-2 mb-2 md:col-5 col-12">
              <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
              <input type="text" formControlName="indirizzoCentrale" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="comuneCentrale" class="font-medium text-900" >Comune Centrale</label>
              <input type="text" formControlName="comuneCentrale" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
              <p-inputNumber formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
              <p-inputNumber formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#4b255f;" icon="pi pi-envelope"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Richiedi Aggiornamento Dati Centrale"
                (click)="openRichiediAggiornamentoDatientrale()">
              </button>
            </div>  
          

          </div> 
        </form>
    
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <form [formGroup]="dettagliForm">
        <div class="grid formgrid p-fluid mt-2">
          <div class="field mb-2 md:col-6 col-12">
            <label for="redattore" class="font-medium text-900">Redattore</label>
            <input type="text" formControlName="redattore" pInputText>
          </div>
          <div class="field mb-2 md:col-6 col-12">
            <label for="verificatore" class="font-medium text-900">Verificatore</label>
            <input type="text" formControlName="verificatore" pInputText>
          </div>
        </div>
      </form> 
      </ng-container>

    </ng-container>
    <ng-template #elseBlock>
      <ng-container *ngSwitchCase="0">
        <form [formGroup]="reteAccessoForm">
          <div class="grid formgrid p-fluid mt-2">
            <!-- <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="lunghezza" class="font-medium text-900">Lunghezza</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezza" placeholder="Lunghezza della Fibra in metri" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="tipologia" class="font-medium text-900">Tipologia collegamento</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-dropdown inputId="tipologia" [options]="tipologie" optionLabel="codice"
                      [showClear]="true" placeholder="Tipo di collegamento" formControlName="tipologia">
                      <ng-template let-tipo pTemplate="item">
                          <div class="flex align-items-center">
                              <div>{{tipo.codice}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="diritto" class="font-medium text-900">Diritto</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              <p-dropdown inputId="diritto" [options]="diritti" optionLabel="codice"
                  [showClear]="true" placeholder="Tipo di diritto" formControlName="diritto">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="proprietario" class="font-medium text-900">Proprietario</label><span class="font-medium text-900" *ngIf="isBandoD"> TRATTA B-Z</span>
              
              <p-dropdown inputId="proprietario" [options]="proprietari" optionLabel="codice"
                  [showClear]="true" placeholder="Proprietario" formControlName="proprietario">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
        
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="lunghezzaTratta2" class="font-medium text-900">Lunghezza TRATTA A-B</label>
              <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezzaTratta2" placeholder="Lunghezza della seconda tratta" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD"  class="field mt-2 mb-2 md:col-3 col-12">
              <label for="tipologia" class="font-medium text-900">Tipologia colleg. TRATTA A-B</label>
              <p-dropdown inputId="tipologiaTratta2" [options]="tipologieCavi" optionLabel="codice"
                      [showClear]="true" placeholder="Tipo di collegamento" formControlName="tipologiaTratta2">
                      <ng-template let-tipo pTemplate="item">
                          <div class="flex align-items-center">
                              <div>{{tipo.codice}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="dirittoTratta2" class="font-medium text-900">Diritto TRATTA A-B</label>
              <p-dropdown inputId="dirittoTratta2" [options]="diritti" optionLabel="codice"
                  [showClear]="true" placeholder="Tipo di diritto" formControlName="dirittoTratta2">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 md:col-3 col-12">
              <label for="proprietarioTratta2" class="font-medium text-900">Proprietario TRATTA A-B</label>
              <p-dropdown inputId="proprietarioTratta2" [options]="proprietari" optionLabel="codice"
                  [showClear]="true" placeholder="Proprietario" formControlName="proprietarioTratta2">
                  <ng-template let-tipo pTemplate="item">
                      <div class="flex align-items-center">
                          <div>{{tipo.codice}}</div>
                      </div>
                  </ng-template>
              </p-dropdown>
            </div> -->
            <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="hostnameCPE" class="font-medium text-900">Hostname CPE</label>
              <input type="text" formControlName="hostnameCPE" placeholder="Hostname CPS" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="localeTecnico" class="font-medium text-900">Locale Tecnico</label>
              <input type="text" formControlName="localeTecnico" placeholder="Locale Tecnico" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="piano" class="font-medium text-900">Piano</label>
              <input type="text" formControlName="piano" placeholder="Piano" pInputText>
            </div>
            <div class="field mt-2 mb-2 col">
              <label for="stanza" class="font-medium text-900">Stanza</label>
              <input type="text" formControlName="stanza" placeholder="Stanza" pInputText>
            </div>
            <div *ngIf="isColocata" class="field mt-2 mb-2 col">
              <label for="flagCPEinStessoLocale" class="font-medium text-900" style="font-size: smaller;">CPE in stesso locale?</label>
              <div class="flex align-items-center mt-2">
                <p-inputSwitch inputId="flagCPEinStessoLocale" formControlName="flagCPEinStessoLocale"></p-inputSwitch>
              </div>
            </div>
          </div>
        </form> 
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <form [formGroup]="localizzazioniForm">
        <div class="grid formgrid p-fluid mt-2">
            <div class="field mt-2 mb-2 md:col-5 col-12">
              <label for="indirizzoScuola" class="font-medium text-900">Indirizzo Scuola</label>
              <input type="text" formControlName="indirizzoScuola" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="comuneScuola" class="font-medium text-900" >Comune Scuola</label>
              <input type="text" formControlName="comuneScuola" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineScuola" class="font-medium text-900">Latitudine Scuola</label>
              <p-inputNumber formControlName="latitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineScuola" class="font-medium text-900">Longitudine Scuola</label>
              <p-inputNumber formControlName="longitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Scuola"
                (click)="openMappaScuola()">
              </button>
            </div> 
            <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
              <label for="latitudineNodoFittizio" class="font-medium text-900" >Latitudine Giunto Derivato</label>
              <p-inputNumber formControlName="latitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
              <label for="longitudineNodoFittizio" class="font-medium text-900">Longitudine Giunto Derivato</label>
              <p-inputNumber formControlName="longitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>

            <div class="field mt-2 mb-2 md:col-5 col-12">
              <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
              <input type="text" formControlName="indirizzoCentrale" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="comuneCentrale" class="font-medium text-900" >Comune Centrale</label>
              <input type="text" formControlName="comuneCentrale" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
              <p-inputNumber formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
              <p-inputNumber formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#4b255f;" icon="pi pi-envelope"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Richiedi Aggiornamento Dati Centrale"
                (click)="openRichiediAggiornamentoDatientrale()">
              </button>
            </div>  
          

          </div> 
        </form>
    
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <form [formGroup]="dettagliForm">
        <div class="grid formgrid p-fluid mt-2">
          <div class="field mb-2 md:col-6 col-12">
            <label for="redattore" class="font-medium text-900">Redattore</label>
            <input type="text" formControlName="redattore" pInputText>
          </div>
          <div class="field mb-2 md:col-6 col-12">
            <label for="verificatore" class="font-medium text-900">Verificatore</label>
            <input type="text" formControlName="verificatore" pInputText>
          </div>
        </div>
      </form> 
      </ng-container>
    </ng-template>
</ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mt-1 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0"></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>


  <p-button *ngIf="activeIndex === pagineInserisciInformazioni.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="!this.dettagliForm.valid"></p-button>
  <!-- <p-button icon="pi pi-times" label="Annulla" class="p-button-text" styleClass="p-button-warning" (click)="conferma(false)"></p-button> -->
  <!--    <div class="d-flex"> </div>   <div class="justify-content-end"> </div> -->
</div>
