import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";

import { NavBarComponent } from "./components/navbar/navbar.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";

import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import {FileUploadModule} from 'primeng/fileupload';

import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MyMissingTranslationHandler } from "./translation/MyMissingTranslationHandler";
import { CommonModule } from "@angular/common";

import { CSVBoxAngularModule, CSVBoxMethods } from "@csvbox/angular";

import { environment } from '../environments/environment';

//APPLICAZIONE
import { HomeComponent, StatisticheSanita, StatisticheScuola } from "./pages/home/home.component";


//PRIME NG MODULES
import { StyleClassModule } from "primeng/styleclass";
import { ToastModule } from "primeng/toast";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TooltipModule } from "primeng/tooltip";
import { InputSwitchModule } from "primeng/inputswitch";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { StepsModule } from 'primeng/steps';
import { ChartModule } from 'primeng/chart';
import { KnobModule } from 'primeng/knob';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge'; 
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerticalAuthService } from "./auth/auth.service";
import { PageNotFoundComponent } from './pages/errors/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './pages/errors/server-error/server-error.component';
import { UnderConstructionComponent } from './pages/errors/under-construction/under-construction.component';
import { TestEnvironmentFooterComponent } from './components/test-environment-footer/test-environment-footer.component';
import { RemoveCommaPipe } from "./pipes/removeCommaPipe";

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { DialogCreaUtente, DialogModificaAziendaUtente, DialogModificaRuoloUtente, DialogModificaUtente, UtentiComponent } from "./pages/utenti/utenti.component";
import { DialogCreaImpresa, DialogModificaImpresa, ImpreseComponent } from "./pages/imprese/imprese.component";
import { DialogCreaCentrale, DialogModificaCentrale, CentraliComponent, DialogPosizioneCentrale } from "./pages/centrali/centrali.component";
import { DialogCaricaFotoRouter, DialogNoteRework, DialogCaricaPlanimetria,DialogPosizioneScuola, DialogSalvaInformazioni, DialogStoricoPratica, PraticheScuolaBando2Component, DialogCaricaNT9, DialogInserisciNoteRework, DialogAssegnaPratica, DialogCompletaPratica, DialogInserisciNoteRespingimentoPratica, DialogModificaPosizione, DialogModificaPratica, 
   DialogCaricaVerbaleFirmato, DialogCaricaPacchettoFirmato, DialogCaricaNT9Firmato, DialogCaricaPacchettiFirmati, DialogCaricaVerbaliFirmati, DialogCaricaPDFPlanimetria, 
   DialogModificaStatoPratica} from "./pages/pratiche/scuola/bando2/pratiche-scuola-bando2.component";
import { DialogCaricaFotoRouterBando1, DialogNoteReworkBando1, DialogCaricaPiantinaBando1,DialogPosizioneScuolaBando1, DialogSalvaInformazioniBando1, DialogStoricoPraticaBando1, PraticheScuolaBando1Component, DialogCaricaNT9Bando1, DialogInserisciNoteReworkBando1, DialogAssegnaPraticaBando1, DialogCompletaPraticaBando1, DialogInserisciNoteRespingimentoPraticaBando1, DialogModificaPosizioneBando1, DialogModificaPraticaBando1, DialogCaricaVerbaleFirmatoBando1, DialogCaricaPacchettoFirmatoBando1, DialogCaricaVerbaliFirmatiBando1, DialogCaricaPacchettiFirmatiBando1, DialogModificaStatoPraticaBando1 } from "./pages/pratiche/scuola/bando1/pratiche-scuola-bando1.component";


import { MenuModule } from "primeng/menu";
import { ChipModule } from 'primeng/chip';

import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
//import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';

import { DialogCreaPratica } from "./pages/pratiche/scuola/bando2/crea-pratica-bando2.component";
import { DialogCreaPraticaBando1 } from "./pages/pratiche/scuola/bando1/crea-pratica-bando1.component";
import { DialogRichiediCambioCentrale } from "./pages/pratiche/scuola/bando2/cambio-centrale-bando2.component";
import { NgxFileDropModule } from 'ngx-file-drop';
import { DialogRichiediCambioCentraleBando1 } from "./pages/pratiche/scuola/bando1/cambio-centrale-bando1.component";
import { DialogDisegnaScuola } from "./pages/pratiche/scuola/bando2/disegna-pratica.component";
import { DialogCaricaFotoRouterSanita, DialogPosizioneAccesso, PraticheSanitaComponent } from "./pages/pratiche/sanita/pratiche-sanita.component";
import { DialogAssegnaPraticaSanita } from "./pages/pratiche/sanita/assegna-pratica-sanita";
import { ComuniComponent } from "./pages/comuni/comuni.component";
import { DialogRichiediAggiornamentoCentrale } from "./pages/pratiche/scuola/bando2/aggiornamento-centrale-bando2.component";
import { DialogCreaLavoroPraticaSanita, DialogInserisciInformazioniPraticaSanita, DialogModificaLavoroPraticaSanita, DialogModificaPosizioneAccesso } from "./pages/pratiche/sanita/inserisci-informazioni-pratica-sanita";
import { DialogModificaCodiceInvioPratica } from "./pages/pratiche/scuola/bando2/cambio-codiceInvio-bando2.component";
import { DialogStoricoPraticaSanita } from "./pages/pratiche/sanita/storico-pratica-sanita";
import {  DialogCaricaCMTPianoTecnicoSanita, DialogCreaPianoTecnicoSanita, DialogModificaPianoTecnicoSanita, PianiTecniciSanitaComponent } from "./pages/pratiche/sanita/pianiTecnici/piani-tecnici-sanita.component";
import { DialogRichiediCambioCentralePraticaSanita } from "./pages/pratiche/sanita/cambio-centrale-sanita.component";
import { DialogModificaCodiceInvioPraticaBando1 } from "./pages/pratiche/scuola/bando1/cambio-codiceInvio-bando1.component";



@NgModule({
  declarations: [
    AppComponent,
    //COMPONENTI APPLICAZIONE
    NavBarComponent,
    HomeComponent,
    StatisticheScuola,
    StatisticheSanita,
    UtentiComponent,
    DialogModificaUtente,
    DialogModificaRuoloUtente,
    DialogModificaAziendaUtente,
    DialogCreaUtente,
    ImpreseComponent,
    DialogCreaCentrale, 
    DialogModificaCentrale, 
    CentraliComponent,
    ComuniComponent,
    DialogPosizioneCentrale,
    DialogCreaImpresa,
    DialogModificaImpresa,
    PraticheScuolaBando2Component,
    PraticheScuolaBando1Component,
    PianiTecniciSanitaComponent,
    DialogCreaPianoTecnicoSanita,
    DialogModificaPianoTecnicoSanita,
    PraticheSanitaComponent,
    DialogModificaPratica,
    DialogModificaPraticaBando1,
    DialogSalvaInformazioni,
    DialogSalvaInformazioniBando1,
    DialogCaricaFotoRouter,
    DialogCaricaFotoRouterBando1,
    DialogCaricaPDFPlanimetria,
    DialogRichiediAggiornamentoCentrale,
    DialogCaricaPlanimetria,
    DialogCaricaPiantinaBando1,
    DialogNoteRework,
    DialogNoteReworkBando1,
    DialogPosizioneScuola,
    DialogPosizioneScuolaBando1,
    DialogStoricoPratica,
    DialogStoricoPraticaBando1,
    DialogNoteRework,
    DialogNoteReworkBando1,
    DialogCaricaNT9,
    DialogCaricaNT9Bando1,
    DialogInserisciNoteRework,
    DialogInserisciNoteReworkBando1,
    DialogAssegnaPratica,
    DialogAssegnaPraticaBando1,
    DialogCompletaPratica,
    DialogCompletaPraticaBando1,
    DialogModificaPosizione,
    DialogModificaPosizioneBando1,
    DialogCreaPratica,
    DialogCreaPraticaBando1,
    DialogInserisciNoteRespingimentoPratica,
    DialogInserisciNoteRespingimentoPraticaBando1,
    DialogDisegnaScuola,
    DialogRichiediCambioCentrale,
    DialogCaricaVerbaleFirmato,
    DialogCaricaPacchettoFirmato,
    DialogCaricaPacchettiFirmati,
    DialogRichiediCambioCentraleBando1,
    DialogCaricaVerbaleFirmatoBando1,
    DialogCaricaPacchettoFirmatoBando1,
    DialogCaricaNT9Firmato,
    DialogCaricaVerbaliFirmati,
    DialogCaricaVerbaliFirmatiBando1,
    DialogCaricaPacchettiFirmatiBando1,
    DialogAssegnaPraticaSanita,
    DialogInserisciInformazioniPraticaSanita,
    DialogModificaPosizioneAccesso,
    DialogModificaCodiceInvioPratica,
    DialogPosizioneAccesso,
    DialogCaricaFotoRouterSanita,
    DialogStoricoPraticaSanita,
    DialogCreaLavoroPraticaSanita,
    DialogModificaLavoroPraticaSanita,
    DialogCaricaCMTPianoTecnicoSanita,
    DialogRichiediCambioCentralePraticaSanita,
    DialogModificaCodiceInvioPraticaBando1,
    PageNotFoundComponent,
    ServerErrorComponent,
    UnderConstructionComponent,
    TestEnvironmentFooterComponent,
    DialogModificaStatoPratica,
    DialogModificaStatoPraticaBando1,
    RemoveCommaPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StyleClassModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    //LEAFLET
    LeafletModule,
    LeafletMarkerClusterModule,
 //  LeafletDrawModule,
    //MODULI PRIMENG
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    InputSwitchModule,
    InputTextModule,
    DropdownModule,
    PasswordModule,
    CalendarModule,
    InputNumberModule,
    DialogModule,
    InputTextareaModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    StepsModule,
    ChartModule,
    KnobModule,
    BadgeModule,
    ChipModule,
    MenuModule,
    TabViewModule,
    TieredMenuModule,
    HttpClientModule,
    CommonModule,
    TagModule,
    MultiSelectModule,
    AutoCompleteModule,
    CSVBoxAngularModule,
    AngularFireModule.initializeApp(environment.firebase),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      },
       httpInterceptor: {
        allowedList: [
          `${environment.apiUrl}`+"utente",
          `${environment.apiUrl}`+"utente/*", 
          `${environment.apiUrl}`+"azienda",
          `${environment.apiUrl}`+"azienda/*",
          `${environment.apiUrl}`+"comune",
          `${environment.apiUrl}`+"centrale",
          `${environment.apiUrl}`+"centrale/*", 
          `${environment.apiUrl}`+"pratica-scuola",
          `${environment.apiUrl}`+"pratica-scuola/*",
          `${environment.apiUrl}`+"scuola",
          `${environment.apiUrl}`+"scuola/*",
          `${environment.apiUrl}`+"storico-pratica-scuola",
          `${environment.apiUrl}`+"storico-pratica-scuola/*",
          `${environment.apiUrl}`+"nota-pratica-scuola",
          `${environment.apiUrl}`+"nota-pratica-scuola/*",
          `${environment.apiUrl}`+"pratica-sanita",
          `${environment.apiUrl}`+"pratica-sanita/*",
          `${environment.apiUrl}`+"storico-pratica-sanita",
          `${environment.apiUrl}`+"lavori-pratica-sanita",
          `${environment.apiUrl}`+"storico-pratica-sanita/*",
          `${environment.apiUrl}`+"lavori-pratica-sanita/*",
          `${environment.apiUrl}`+"doc/*",
          `${environment.apiUrl}`+"piano-tecnico-sanita",
          `${environment.apiUrl}`+"piano-tecnico-sanita/*",
          /* `${environment.apiUrl}`+"imprese/*",  
          `${environment.apiUrl}`+"pratiche-scuola/*",
          `${environment.apiUrl}`+"pratiche-sanita/*" */
        ], //, `${environment.apiUrl}`+"utente/auth0/*
      } 
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    
    MessageService,
    DialogService,
    VerticalAuthService,
     {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    }, 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}
