
<p-steps [model]="pagineModificaPratica" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngSwitchCase="0">
      <form [formGroup]="dettagliForm">
        <div class="grid formgrid p-fluid mt-2">
          
            <div class="field mt-2 mb-2 col-3">
              <label for="codiceInfratel" class="font-medium text-900">Codice INFRATEL</label>
              <input type="text" formControlName="codiceInfratel" placeholder="Codice INFRATEL" pInputText>
            </div>
            <div class="field mt-2 mb-2 col-2">
              <label for="codiceInvio" class="font-medium text-900">Codice Invio</label>
              <input type="text" formControlName="codiceInvio" placeholder="Codice Invio" pInputText>
            </div>
  
           
            <div class="field mt-2 mb-2 col-2">
              <label for="tipoSede" class="font-medium text-900">Sede</label>
              <p-dropdown inputId="tipoSede" formControlName="tipoSede" placeholder="Sede" [options]="tipiSede" optionLabel="codice"  [showClear]="true" >
                <ng-template let-tipiSede pTemplate="item">
                  <div class="flex align-items-center">
                      <div>{{tipiSede.codice}}</div>
                  </div>
              </ng-template>
              </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 col-2">
              <label for="tipoSedeEffettiva" class="font-medium text-900">Sede Effettiva</label>
              <p-dropdown inputId="tipoSedeEffettiva" formControlName="tipoSedeEffettiva" placeholder="Sede" [options]="tipiSede" optionLabel="codice"  [showClear]="true" >
                <ng-template let-tipiSede pTemplate="item">
                  <div class="flex align-items-center">
                      <div>{{tipiSede.codice}}</div>
                  </div>
              </ng-template>
              </p-dropdown>
            </div>
            <div class="field mt-2 mb-2 col-2">
              <label for="comuneScuola" class="font-medium text-900" >Impresa</label>
              <p-autoComplete 
                formControlName="azienda" 
                [suggestions]="filteredAziende" 
                (completeMethod)="filterAzienda($event)"
                field="ragioneSociale" placeholder="Seleziona un'impresa"
                ></p-autoComplete>
            </div>
           
            <div class="field mt-2 mb-2 col-1">
              <label for="backup" class="font-medium text-900 text-orange-600">BACKUP</label>
              <div class="flex align-items-center mt-2">
                <p-inputSwitch inputId="backup" formControlName="backup"></p-inputSwitch>
              </div>
            </div>
           
            
          </div>
      </form> 
    </ng-container>
    
    <ng-container *ngSwitchCase="1">
      <form [formGroup]="scuolaForm">
        <div class="grid formgrid p-fluid mt-2">
          <div class="field mt-2 mb-2  col-9">
            <label for="denominazioneScuola" class="font-medium text-900">Denominazione Scuola</label>
            <input type="text" formControlName="denominazioneScuola" placeholder="Denominazione" pInputText>
          </div>
          <div class="field mt-2 mb-2  col-3">
            <label for="codiceMiur" class="font-medium text-900">Codice MIUR</label>
            <input type="text" formControlName="codiceMiur" placeholder="Codice MIUR" pInputText>
          </div>
            <div class="field mt-2 mb-2 md:col-4 col-12">
              <label for="indirizzoScuola" class="font-medium text-900">Indirizzo Scuola</label>
              <input type="text" formControlName="indirizzoScuola" placeholder="Indirizzo Scuola" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="comuneScuola" class="font-medium text-900" >Comune Scuola</label>
              <p-autoComplete 
                  formControlName="comuneScuola" 
                  [suggestions]="filteredComuni" 
                  (completeMethod)="filterComune($event)"
                  field="denominazione" placeholder="Cerca un comune"
                  ></p-autoComplete>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineScuola" class="font-medium text-900">Latitudine Scuola</label>
              <p-inputNumber type="text" formControlName="latitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineScuola" class="font-medium text-900">Longitudine Scuola</label>
              <p-inputNumber type="text" formControlName="longitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
              <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Modifica Posizione Scuola"
                (click)="openMappaScuola()">
              </button>
            </div>  
          </div> 
       <!-- <div class="grid formgrid p-fluid mt-2">
        <div class="field mt-2 mb-2  col-9">
          <label for="denominazioneScuola" class="font-medium text-900">Denominazione Scuola</label>
          <input type="text" formControlName="denominazioneScuola" pInputText>
        </div>
        <div class="field mt-2 mb-2  col-3">
          <label for="codiceMiur" class="font-medium text-900">Codice MIUR</label>
          <input type="text" formControlName="codiceMiur" pInputText>
        </div>
          <div class="field mt-2 mb-2 md:col-6 col-12">
            <label for="indirizzoScuola" class="font-medium text-900">Indirizzo Scuola</label>
            <input type="text" formControlName="indirizzoScuola" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="comuneScuola" class="font-medium text-900">Comune Scuola</label>
            <input  type="text" formControlName="comuneScuola" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="regioneScuola" class="font-medium text-900">Regione Scuola</label>
            <input type="text" formControlName="regioneScuola" pInputText>
          </div> 
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="latitudineScuola" class="font-medium text-900">Latitudine Scuola</label>
            <p-inputNumber type="text" formControlName="latitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="longitudineScuola" class="font-medium text-900">Longitudine Scuola</label>
            <p-inputNumber type="text" formControlName="longitudineScuola" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
           <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Scuola"
              (click)="openMappaScuola()">
            </button>
          </div> 
        </div>  -->
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <form [formGroup]="centraleForm">
        <div class="grid formgrid p-fluid mt-2">
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="idCentrale" class="font-medium text-900">ID Centrale</label>
            <input type="text" formControlName="idCentrale" placeholder="ID TIM" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="nomeCentrale" class="font-medium text-900">CLLI Centrale</label>
            <input type="text" formControlName="nomeCentrale" placeholder="CLLI della Centrale" pInputText>
          </div>
            <div class="field mt-2 mb-2 md:col-5 col-12">
              <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
              <input type="text" formControlName="indirizzoCentrale" placeholder="Indirizzo della Centrale" pInputText>
            </div>
            <div class="field mt-2 mb-2 md:col-3 col-12">
              <label for="comuneCentrale" class="font-medium text-900">Comune Centrale</label>
              <p-autoComplete 
                formControlName="comuneCentrale" 
                [suggestions]="filteredComuni" 
                (completeMethod)="filterComune($event)"
                field="denominazione" placeholder="Cerca un comune"
              ></p-autoComplete>
            </div>
          
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
              <p-inputNumber type="text" formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
            <div class="field mt-2 mb-2 md:col-2 col-12">
              <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
              <p-inputNumber type="text" formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
            </div>
           <!--  <div class="md:col-1 col-12">
              <button  pButton type="button" style="color:#0c096e;" icon="pi pi-map"
                class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Centrale"
                (click)="openMappaCentrale()">
              </button>
            </div>   -->
          </div> 
       <!-- <div class="grid formgrid p-fluid mt-2">
        <div class="field mt-2 mb-2 md:col-6 col-12">
          <label for="idCentrale" class="font-medium text-900">ID Centrale</label>
          <input type="text" formControlName="idCentrale" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-6 col-12">
          <label for="nomeCentrale" class="font-medium text-900">Denominazione Centrale</label>
          <input type="text" formControlName="nomeCentrale" pInputText>
        </div>
          <div class="field mt-2 mb-2 md:col-6 col-12">
            <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
            <input type="text" formControlName="indirizzoCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="comuneCentrale" class="font-medium text-900">Comune Centrale</label>
            <input type="text" formControlName="comuneCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="regioneCentrale" class="font-medium text-900">Regione Centrale</label>
            <input type="text" formControlName="regioneCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
            <p-inputNumber type="text" formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
            <p-inputNumber type="text" formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#0c096e;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Centrale"
              (click)="openMappaCentrale()">
            </button>
          </div> 
        </div>  -->
      </form>
    </ng-container>
  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">
  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0"></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>
  <p-button *ngIf="activeIndex === pagineModificaPratica.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)"></p-button>
</div>
