import { Bando } from "./bando.model";
import { Impresa } from "./impresa.model";
import { PCN } from "./pratica.pcn.scuola.model";
import { TipoSede } from "./pratica.tipoSede.model";
import { ScuolaDTO } from "./scuola.model";
import { Utente } from "./utente.model";

export class PraticaScuola {
    id: Number;
    codiceInfratel:String;
    codiceInvio:String;
    codiceProgetto:String;
    ordineEsecuzione:String;
    nomeInvio:String;
    redattore:String;
    verificatore:String;
    utenteAssegnato: Utente;
    scuola:ScuolaDTO;
    comuneCentrale:String;
    idCentrale:String;
    nomeCentrale:String;
    indirizzoCentrale:String;
    latitudineCentrale:Number;
    longitudineCentrale:Number;
    azienda:Impresa;
    lavorabile:Boolean;
    wifi:Boolean;
    backup:Boolean;
    tipoSede:TipoSede;
    tipoSedeEffettiva:TipoSede;
    bando:Bando;
    latitudineNodoFittizio:Number;
    longitudineNodoFittizio:Number;
    pcn:PCN;
    estensionePlanimetria:string;
    tipoBSharp:boolean;
    annoAttivazione:Number;

  }
  