import { TipoConnettivitaMPLS } from "./pratica.tipoConnettiviaMPLS.model";
import { Utente } from "./utente.model";

export class DettaglioPraticaSanitaDTO {
    id: Number;
    codiceInfratel:String;

    tipologia:String;
    diritto:String;
    proprietario:String;
    numeroAccessPoints:Number;
    lunghezza:Number;


    idPDC:String;
    hostnameCPE:String;
    ipAddress:String;
    localeTecnico:String;
    piano:String;
    stanza:String;


    clliSedeA:String;
    tipologiaSedeA:String;
    descrizioneSedeA:String;
    attestazioneAlRipartitoreA:String;

    clliSedeZ:String;
    tipologiaSedeZ:String;
    descrizioneSedeZ:String;
    attestazioneAlRipartitoreZ:String;
    
    numeroTotaleCoppie:Number;
    numeroCoppieInUso:Number;
    fibra:String;

    centroStella:boolean;
    tipoConnettivitaMPLS:TipoConnettivitaMPLS;

    longitudineAccesso:Number;
    latitudineAccesso:Number;
    indirizzoAccesso:String;
    comuneAccesso:String;
    longitudineCentrale:Number;
    latitudineCentrale:Number;
    indirizzoCentrale:String;
    comuneCentrale:String;

    redattore:String;
    verificatore:String;


  }
  