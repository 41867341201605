<div class="surface-ground px-1 py-2 md:px-2 lg:px-2">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="grid formgrid p-fluid">
      <div class="field mb-2 col-12 ">
        <label for="centrale" class="font-medium text-900">Richiedi che la pratica venga associata alla centrale:</label>
        <p-autoComplete 
        [(ngModel)]="centrale"
        [suggestions]="filteredCentrali" 
        (completeMethod)="filterCentrali($event)"
        field="clliAttestazione" placeholder="CLLI CENTRALE"
        ></p-autoComplete>
      </div>
        <div class="surface-border border-top-1 opacity-50 mb-2 mt-2 col-12"></div>
        <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
          <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
            styleClass="p-button-success" [disabled]="!centrale"></p-button>
          <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
            styleClass="p-button-warning"></p-button>
        </div>
    </div>
    </div>
  </div>