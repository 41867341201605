import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import { environment } from "../../../../../environments/environment";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { AngularFirestore } from "@angular/fire/compat/firestore";

import { Subject } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import * as Leaflet from "leaflet";
import { Centrale } from "src/app/models/centrale.models";
import { CentraliService } from "src/app/services/centrali.service";
import { RichiestaAggiornamentoCentrale } from "src/app/models/richiestaAggiornamentoCentrale.models";


@Component({
  selector: "app-aggiorna-centrale-bando2",
  templateUrl: "./dialog-aggiornamento-centrale-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogRichiediAggiornamentoCentrale implements OnInit {
  protected _onDestroy = new Subject<void>();
  
  protected centraleForm: FormGroup;
  protected centraleSelezionata : Centrale;
  protected impresa : String;
  protected nomeCentrale:string;
  protected ref: DynamicDialogRef;


  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    private centraliService:CentraliService
  ) {
   
  }

  ngOnInit(): void {
    this.centraleSelezionata = this.config.data.centrale;
    this.impresa = this.config.data.impresa;
    this.nomeCentrale = this.centraleSelezionata.denominazione + " - "+this.centraleSelezionata.clli;
    this.centraleForm = this._formBuilder.group({
      indirizzo:        [this.centraleSelezionata.indirizzo, [Validators.required]],
      comune:        new FormControl({value: this.centraleSelezionata.comune.denominazione, disabled: true}), 
      latitudine:       [this.centraleSelezionata.latitudine, [Validators.required]],
      longitudine:      [this.centraleSelezionata.longitudine, [Validators.required]],

    });
   }


  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let richiestaAggiornamentoCentrale:RichiestaAggiornamentoCentrale = new RichiestaAggiornamentoCentrale();
      richiestaAggiornamentoCentrale.clli    = this.centraleSelezionata.clli
      richiestaAggiornamentoCentrale.nuovoIndirizzo    = this.centraleForm.value.indirizzo;
      richiestaAggiornamentoCentrale.nuovaLatitudine   = this.centraleForm.value.latitudine.toString().substring(0,9) ;
      richiestaAggiornamentoCentrale.nuovaLongitudine  = this.centraleForm.value.longitudine.toString().substring(0,9);
      richiestaAggiornamentoCentrale.impresa  = this.impresa;

     // let praticaDaModificare: PraticaScuola = new PraticaScuola();
      

     // this.logger.info(praticaDaModificare);
        this.centraliService.richiediCambiamentoIndirizzo(this.centraleSelezionata.clli.valueOf(),richiestaAggiornamentoCentrale).subscribe({
        next: (v) => {},
        error: (e) => {
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            detail: "Errore in fase di RICHIESTA CAMBIO INDIRIZZO CENTRALE",
          });
          this.dialogRef.close({ success: false });
        },
        complete: () => {
          this.messageService.add({severity:'success', summary:'', detail: 'Richiesta di aggiornamento centrale inviata!', life:10000});

          this.dialogRef.close({ success: true });
        },
      }); 

      
    }
  }
}
