import { Injectable } from '@angular/core';
import { Utente } from '../models/utente.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userData: Utente;
  public isSuperAdmin:boolean   = false;
  public isAdmin:boolean        = false;
  public isSF:boolean           = false;
  public isDirigenteVerbali:boolean           = false;
  public isDirigentePacchetti:boolean           = false;
  public isPresidio:boolean     = false;
  public isAzienda:boolean      = false;

  constructor() {
    // Inizializza i dati dell'utente se l'utente è già autenticato
    this.userData = null;
  }

  setUser(user: Utente) {
    this.userData = user;
    this.setRole();
  }
  
  private setRole(){
    
    if(this.userData.ruolo.codice==='SUPERADMIN'){
        this.isSuperAdmin = true;
    }else if(this.userData.ruolo.codice==='ADMIN'){
        this.isAdmin = true;
    }else if(this.userData.ruolo.codice==='PRESIDIO'){
        this.isPresidio = true;
    }else if(this.userData.ruolo.codice==='SMARTFIBER'){
        this.isSF = true;
    }else if(this.userData.ruolo.codice==='DIRIGENTE_FIRMA_VERBALI'){
      this.isDirigenteVerbali = true;
    }else if(this.userData.ruolo.codice==='DIRIGENTE_FIRMA_PACCHETTI'){
      this.isDirigentePacchetti = true;
    }else{
        this.isAzienda = true;
    }
  }

  getUser(): Utente {
    return this.userData;
  }
}
