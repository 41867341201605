<!-- <p-steps [model]="pagineCompletaPratica" [readonly]="true" [activeIndex]="activeIndex"></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngSwitchCase="0">
      <form [formGroup]="ordineEsecuzioneForm">
        <div class="grid formgrid p-fluid mt-2">
          <div class="field mt-2 mb-2 md:col-4 col-12">
            <label for="ordineEsecuzione" class="font-medium text-900">Ordine di Esecuzione</label>
            <input type="text" formControlName="ordineEsecuzione" pInputText>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="1">
      <form [formGroup]="cronoprogrammaForm">
          <div style="margin-top: 10px;">
            <p class="line-height-3 p-0 m-0 mb-2">
                Seleziona file del CRONOPROGRAMMA e del COMPUTO METRICO o trascinalo nell'area sottostante
            </p>
            <p-fileUpload name="fileCronoprogramma" class="mb-1" 
                multiple="true"
                maxFileSize="15000000"
                chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
                [auto]="true"
                [customUpload]="true"
                (uploadHandler)="onCronoprogrammaUpload($event)"
                [showUploadButton]="false"
                [showCancelButton]="false"
                
                (onRemove)="onCronoprogrammaRemove()"
            >
            </p-fileUpload>
          </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <form [formGroup]="computoMetricoForm">
        <div style="margin-top: 10px;">
          <p class="line-height-3 p-0 m-0 mb-2">
              Seleziona file del COMPUTO METRICO o trascinalo nell'area sottostante
          </p>
          <p-fileUpload name="fileComputoMetrico" class="mb-1" 
              multiple="false"
              maxFileSize="15000000"
              chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
              [auto]="true"
              [customUpload]="true"
              (uploadHandler)="onComputoMetricoUpload($event)"
              [showUploadButton]="false"
              [showCancelButton]="false"
              [disabled]="isComputoMetricoUploaded"
              (onRemove)="onComputoMetricoRemove()"
          >
          </p-fileUpload>
        </div>
      </form>
    </ng-container>

  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0"></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()" [disabled]="checkNextStep()"></p-button> -->
  
  <div class="d-flex">

    <form [formGroup]="completaPraticaForm">
      <div class="grid formgrid p-fluid mt-2">
        <div class="field mt-2 mb-2 col-6">
          <label for="ordineEsecuzione" class="font-medium text-900">Ordine di Esecuzione</label>
          <input type="text" formControlName="ordineEsecuzione" pInputText>
        </div>
        <div class="field mt-2 mb-2 col-6">
          <label for="nomePerInvio" class="font-medium text-900">Nome per invio</label>
          <input type="text" formControlName="nomePerInvio" pInputText>
        </div>
        
        <!--  <div class="field mt-2 mb-2 col-12">
          <label for="fileComputoMetrico" class="font-medium text-900">Carica il <B>COMPUTO METRICO</B></label>
          <p-fileUpload name="fileComputoMetrico" 
              multiple="false"
              maxFileSize="15000000"
              chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
              [auto]="true"
              [customUpload]="true"
              (uploadHandler)="onComputoMetricoUpload($event)"
              [showUploadButton]="false"
              [showCancelButton]="false"
              (onRemove)="onComputoMetricoRemove()"
              [disabled]="isComputoMetricoUploaded || showSpinner"
              class="custom-red-button"
          >
          </p-fileUpload>
        </div>  -->
      </div>
    </form>
  
  </div>
  <h1 class="m-0 mb-1 mt-1 text-center text-900 font-semibold text-xl line-height-3">La conferma scatenerà la generazione del VERBALE DI CESSIONE DIRITTI IRU</h1>
  <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

  <div>
    <div style="display: flex; justify-content: center; gap: 10px;">
    <p-button [disabled]="!completaPraticaForm.valid || showSpinner" icon="pi pi-check" label="Conferma" class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)"></p-button>
    <p-button icon="pi pi-times" label="Annulla" class="p-button-text" styleClass="p-button-warning" (click)="conferma(false)"></p-button> 
  </div>

</div>
