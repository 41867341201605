import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { Impresa } from "src/app/models/impresa.model";
import { PraticaSanita } from "src/app/models/pratica.sanita.model";
import { StoricoPraticaSanita } from "src/app/models/pratica.storico.sanita.model";
import { StoricoPraticaScuola } from "src/app/models/pratica.storico.scuola.model";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";
import { ImpreseService } from "src/app/services/imprese.service";
import { PraticheSanitaService } from "src/app/services/pratiche.sanita.service";

@Component({
    selector: "app-storico-pratica-sanita",
    templateUrl: "./dialog-storico-pratica.component.html",
    styleUrls: ["./pratiche-sanita.component.scss"],
  })
  export class DialogStoricoPraticaSanita implements OnInit {
    protected _onDestroy = new Subject<void>();
    public pratica: PraticaSanita_Firestore;
    public storico: StoricoPraticaSanita[]=[];
  
    public dataAssegnazione:Date;
    public dataInizioInCarico:Date;
    public dataInizioProgettazione:Date;
    public dataConsegna:Date;
    public dataRework:Date;
  
    public isAssegnata:boolean=false;
    public isInCarico:boolean=false;
    public isAnnullata:boolean=false;
    public isInProgettazione:boolean=false;
    public isConsegnata:boolean=false;
    public isInRework:boolean=false;
    public isApprovata:boolean=false;
    public isRespinta:boolean=false;
  
    
    constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService
    ) {}
  
    ngOnInit(): void {
      this.pratica = this.config.data.pratica;
      this.storico = this.config.data.storicoPratica;
      
      this.processStoricoPratica();
      
    }
  
    processStoricoPratica(){
      if(this.pratica.stato ==='ASSEGNATA'){
        this.isAssegnata = true;
      }else if(this.pratica.stato ==='IN CARICO IMPRESA'){
        this.isInCarico = true;
      }else if(this.pratica.stato ==='ANNULLATA'){
        this.isAnnullata = true;
      }else if(this.pratica.stato ==='IN PROGETTAZIONE'){
        this.isInProgettazione = true;
      }else if(this.pratica.stato ==='CONSEGNATA'){
        this.isConsegnata = true;
      }else if(this.pratica.stato ==='IN REWORK AZIENDA'){
        this.isInRework = true;
      }else if(this.pratica.stato ==='APPROVATA'){
        this.isApprovata = true;
      }else if(this.pratica.stato ==='RESPINTA'){
        this.isRespinta = true;
      }
      this.storico.forEach(storicoPratica =>{
  
        if(storicoPratica.stato.codice === "ASSEGNATA"){
          this.dataAssegnazione = storicoPratica.iniziato;
        }else if(storicoPratica.stato.codice === "IN CARICO IMPRESA"){
          this.dataInizioInCarico = storicoPratica.iniziato;
        } else if(storicoPratica.stato.codice === "IN PROGETTAZIONE"){
          this.dataInizioProgettazione = storicoPratica.iniziato;
        }else if(storicoPratica.stato.codice === "CONSEGNATA"){
          this.dataConsegna = storicoPratica.iniziato;
        }else if(storicoPratica.stato.codice === "IN REWORK AZIENDA"){
          this.dataRework = storicoPratica.iniziato;
        }else if(storicoPratica.stato.codice === "APPROVATA"){
          this.dataRework = storicoPratica.iniziato;
        }else if(storicoPratica.stato.codice === "RESPINTA"){
          this.dataRework = storicoPratica.iniziato;
        }
      });
     
      //this.dataAssegnazione =  new Date();
    }
    
  }