import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as L from 'leaflet';
import { getBlob, getStorage, ref } from 'firebase/storage';
import { PraticaScuola } from '../models/pratica.scuola.model';

@Injectable({
  providedIn: 'root',
})
export class GeojsonService {
  constructor() {}

  getEditableGeoJSON(pratica: PraticaScuola): Observable<L.FeatureGroup> {
    const featureGroup = new L.FeatureGroup();

    return new Observable((observer) => {
      // Ottieni il riferimento al file in storage
      const storage = getStorage();
      const pathReferenceRET = ref(
        storage,
        "pratiche/sanita/" +  pratica.codiceInfratel  + "/ntx/ntx.geojson"
      );
      getBlob(pathReferenceRET).then(
        data =>{
          const blob = data as Blob;
          const reader = new FileReader();
          reader.onload = (event) => {
            const geoJSONData = JSON.parse(event.target?.result as string);
            const geoJSONLayer = L.geoJSON(geoJSONData, {onEachFeature: (feature, layer) =>{
                console.log(feature);
                console.log(layer);
            }
            });
            //return featureGroup;
             observer.next(featureGroup);
             observer.complete(); 
          };
        
          reader.readAsText(blob);
          
        }).catch(errore =>{
            console.error('Errore nel caricamento del file GeoJSON:', errore);
            observer.error(errore);
      });
      
    });
  }
}
