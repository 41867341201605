import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService } from "primeng/api";
import { environment } from '../../../environments/environment';


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { ImpreseService } from "src/app/services/imprese.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { Stats } from "src/app/models/stats.model";
import { onSnapshot } from "firebase/firestore";
import { CentraliService } from "src/app/services/centrali.service";
import { Centrale } from "src/app/models/centrale.models";

declare const CSVBoxImporter: any;
@Component({
  selector: "app-centrali",
  templateUrl: "./centrali.component.html",
  styleUrls: ["./centrali.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class CentraliComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  centrali: Centrale[] = [];
  selectedCentrale: Centrale;
  
  public isAdmin:boolean = false;
  public isSuperAdmin  :boolean = false;
  public isPresidioSF: boolean = false;
  
   //CSV BOX
   private csvbox_licenseKey:string=environment.csvBox.secret_centrali;
   private csvbox_user:string=environment.csvBox.user;
   private csvbox_importer:any;
   dialogConfig;

  @ViewChild("centraliDT", { static: true }) centraliDT: any;
  @ViewChild("menuRegioni") menuRegioni!: Menu;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;
  public regioniCentrale: MenuItem[] = [
    {
      label: "ABRUZZO",
      command: (event) => {
        this.centraliDT.filter("ABRUZZO", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "CALABRIA",
      command: (event) => {
        this.centraliDT.filter("CALABRIA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "CAMPANIA",
      command: (event) => {
        this.centraliDT.filter("CAMPANIA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "LAZIO",
      command: (event) => {
        this.centraliDT.filter("LAZIO", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "LIGURIA",
      command: (event) => {
        this.centraliDT.filter("LIGURIA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "LOMBARDIA",
      command: (event) => {
        this.centraliDT.filter("LOMBARDIA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "MARCHE",
      command: (event) => {
        this.centraliDT.filter("MARCHE", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "MOLISE",
      command: (event) => {
        this.centraliDT.filter("MOLISE", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "PIEMONTE",
      command: (event) => {
        this.centraliDT.filter("PIEMONTE", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "PUGLIA",
      command: (event) => {
        this.centraliDT.filter("PUGLIA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "TOSCANA",
      command: (event) => {
        this.centraliDT.filter("TOSCANA", "comune.regione.denominazione", "is");
      },
    },
    {
      label: "VENETO",
      command: (event) => {
        this.centraliDT.filter("VENETO", "regione", "is");
      },
    },
    {
      label: "TUTTE",
      command: (event) => {
        this.centraliDT.clear();
      },
    }
  ];

  constructor(
    private logger: NGXLogger,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private centraliService: CentraliService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService,
    private csvboxLoaderService: CsvboxLoaderService,
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    this.loadCSVBox();
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SUPERADMIN'){
      this.isSuperAdmin = true;
    
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SMARTFIBER'){
      this.isPresidioSF = true;
    }
    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadCentrali();
  }

  esportaCentrali(){
    //this.logger.info(this.praticheScuolaDT);
    if(this.centraliDT.filteredValue){
      this.logger.info("---> ESPORTA CENTRALI FILTRATE: " +this.centraliDT.filteredValue.length + " Vs "+this.centrali.length);

      this.excelService.exportCentraliToExcel(this.centraliDT.filteredValue, 'CENTRALI.xlsx');
    }else{
      this.excelService.exportCentraliToExcel(this.centrali, 'CENTRALI.xlsx');
    }
    
  }

  loadCSVBox(){
    this.csvboxLoaderService.loadScript().then(() => {
      this.csvbox_importer = new CSVBoxImporter(this.csvbox_licenseKey, {
        // Configuration options
      }, (result: boolean, data: any) => {
        if (result) {
         this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' CENTRALI importate con successo!', life:10000});
         this.loadCentrali();
        } else {
          this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import delle centrali', life:10000});
        }
      });
    
      this.csvbox_importer.setUser({
        user_id: "default123"
      });
      this.csvbox_importer.language="it";

    });
  }

  ngAfterViewInit() {}

  hasCoordinates(centrale: Centrale):boolean{
    if(centrale.latitudine && centrale.longitudine){
      return true;
    }
    return false;
  }


  openMappaConCentrale(centrale: Centrale) {
    this.ref = this.dialogService.open(DialogPosizioneCentrale, {
      data: {
        centrale: centrale,
      },
      header: "Posizione della centrale",
      width: this.isMobile ? "95%" : "80%",
      height: "95%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changePositionResult) => {
      this.logger.info(changePositionResult);
      if(changePositionResult && changePositionResult.success){
        this.messageService.add({severity:'success', summary:'', detail:'Posizione centrale modificata!', life:10000});
        this.loadCentrali();
      }
    });
  }

  filtraPerNome(event: any){
    this.centraliDT.filterGlobal(event.target.value, 'contains')
  }

  importaCentrali(){
    this.csvbox_importer.openModal();
  }

  loadCentrali() {
    this.isLoadingResults = true;
    this.centraliService.get().subscribe(centrali =>{
      this.centrali = centrali;
      this.isLoadingResults = false;
      this.logger.info("LOAD CENTRALI RESTITUISCE: "+ centrali.length);
    });
   /*  const collectionRef =  this.firestore.collection(environment.firebase.collection_stats).ref;
    const query = collectionRef
      .where(
        "ragioneSociale",
        "!=",
       'Admin Fake Company'
      )
      .orderBy("ragioneSociale");
      onSnapshot(query, (qs) => {
        this.centrali = [];
        qs.forEach((doc) => {
          this.centrali.push(doc.data() as Stats);
        });
        this.calcolaTotali();
        this.isLoadingResults = false;
       
      }); */
      
 
  }

  openCreaCentraleDialog() {
     this.ref = this.dialogService.open(DialogCreaCentrale, {
      header: "Inserisci Nuova Centrale",
      width: this.isMobile ? "90%" : "70%",
      height: this.isMobile ? "90%" : "60%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewCentrale) => {
       console.log("AFTER CLOSED > "+ confirmNewCentrale.success);
      if (confirmNewCentrale && confirmNewCentrale.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Centrale creata con successo!', life:10000});
        this.loadCentrali();
      }
    }); 
  }

  openModificaCentraleDialog(centrale: Centrale): void {
    this.ref = this.dialogService.open(DialogModificaCentrale, {
      data: {
        centrale: centrale,
   },
     header: 'Modifica Centrale ',//+impresa.ragioneSociale.toUpperCase(),
     width: this.isMobile? '95%':'50%',
     height: '70%',
    baseZIndex: 10000,
     closable:true,
     draggable:false,
 });
 this.ref.onClose.subscribe(confirmModificaCliente => {
   if(confirmModificaCliente && confirmModificaCliente.success){
    this.messageService.add({severity:'success', summary: '', detail: 'Centrale modificatta correttamente!', life:10000});
    this.loadCentrali();
    /* if(!this.isMobile){
      this.messageService.add({severity:'success', summary:'', detail:'Cliente modificato!'});
    }
     this.loadClienti(); */
   }
 });
  }
 

  openDeleteCentraleDialog(centrale: Centrale): void {
    this.logger.info(centrale);
     this.confirmationService.confirm({
     // message: `Confermi l\'eliminazione della centrale ${centrale.ragioneSociale}?`,
      message: `Confermi l\'eliminazione della centrale ?`,
      header: "Eliminazione Centrale",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {

        this.centraliService.elimina(centrale.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione centrale", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Centrale eliminata", life:10000
            });
            this.loadCentrali();
          },
        }); 
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        // console.log("ANNULLO");
      },
    }); 
  }
}

import * as Leaflet from "leaflet";
import { ComuneDTO } from "src/app/models/comune.model";
import { ComuniService } from "src/app/services/comuni.service";
import { Menu } from "primeng/menu";
import { CsvboxLoaderService } from "src/app/services/csvbox-loader.service";
Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";
@Component({
  selector: "app-posizione-centrale",
  templateUrl: "./dialog-posizione-centrale.component.html",
  styleUrls: ["./centrali.component.css"],
})
export class DialogPosizioneCentrale implements OnInit {
  protected _onDestroy = new Subject<void>();
  public centrale: Centrale;
  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected positionHasChanged:boolean = false;

  protected mapOptions = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 14,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private centraliService: CentraliService,
  ) {}

  ngOnInit(): void {
    this.centrale = this.config.data.centrale;
    if(!this.centrale.id){
      this.positionHasChanged = true;
    }
    this.initialLat = this.centrale.latitudine.valueOf();
    this.initialLng = this.centrale.longitudine.valueOf();

  }
  protected initialLat: number;
  protected initialLng: number;
  protected finalLat: number;
  protected finalLng: number;

  initMarkers() {
     const initialMarkers = [
      {
        position: {
          lat: this.centrale.latitudine.valueOf(),
          lng: this.centrale.longitudine.valueOf(),
        },
        draggable: true,
      },
    ];
    const data = initialMarkers[0];
    const marker = this.generateMarker(data, 0);
    marker
      .addTo(this.map)
      .bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
    this.map.panTo(data.position);
    this.markers.push(marker); 
  }

  generateMarker(data: any, index: number) {
    let markerIconCentrale= new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-centrale.png',
    iconSize: [25, 41],
    });
    return Leaflet.marker(data.position, { draggable: data.draggable, icon:markerIconCentrale })
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
  }
  markerClicked($event: any, index: number) {}
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.centrale.latitudine = this.finalLat;
      this.centrale.longitudine = this.finalLng;
      if(this.centrale.id != null){
        this.centraliService.changeAddress(this.centrale).subscribe(res =>{
          this.dialogRef.close({ success: true });
        });
      }else {
        

        this.dialogRef.close({ 
          success: true, 
          lat:this.finalLat?this.finalLat.valueOf():this.initialLat, 
          lng:this.finalLng? this.finalLng.valueOf():this.initialLng });
      }
     
    }
  }
  markerDragEnd($event: any, index: number) {
    this.logger.info("---> markerDragEnd ");
    this.logger.info($event.target);
    if(this.initialLat!==$event.target._latlng.lat && this.initialLng!==$event.target._latlng.lng){
      this.positionHasChanged = true;
      this.finalLat = $event.target._latlng.lat;
      this.finalLng = $event.target._latlng.lng;
    }
    this.logger.info(">> POSIZIONE CAMBIATA? " + this.positionHasChanged);

  }
  onMapReady($event: Leaflet.Map) {
    this.logger.info("--onMapReady ");
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  templateUrl: './dialog-crea-centrale.component.html',
  styleUrls: ['./centrali.component.css']
})
export class DialogCreaCentrale implements OnInit {

  protected _onDestroy = new Subject<void>();
  protected creaCentraleForm: FormGroup;
  protected comuni:ComuneDTO[]=[];
  protected filteredComuni: any[] =[];
  protected selectedComune: ComuneDTO ;

  protected comuniLoaded:boolean=false;
  protected ref: DynamicDialogRef;
  protected centrale:Centrale;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private centraliService:CentraliService,
      private messageService:MessageService,
      private comuniService: ComuniService,
      public dialogService: DialogService,

    ) {
  }

  ngOnInit(): void {
    this.loadComuni();
    this.centrale = new Centrale();
    this.creaCentraleForm = this._formBuilder.group({
      idTIM:            [null, [Validators.required]],
      clli:            [null, [Validators.required]],
      clliAttestazione: [null, [Validators.required]],
      denominazione:        [null, ],
      indirizzo:        [null, ],
      comune:           new FormControl({value: null, disabled: this.comuniLoaded}, Validators.required), 
      latitudine:       [null, [Validators.required]],
      longitudine:      [null, [Validators.required]],
    
    });
    
   
  }

  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      //this.filteredComuni = res;
      this.comuniLoaded = true;
    });
  }
  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
        let comune = this.comuni[i];
        if (comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredComuni = filtered;
  }

  checkLatAndLng():boolean{
    if(this.creaCentraleForm.get("latitudine").value!=null &&  this.creaCentraleForm.get("longitudine").value != null){
      return false;
    }
    return true;
  }

  openMappaCentrale(){
    this.centrale.latitudine  = this.creaCentraleForm.value.latitudine;
    this.centrale.longitudine = this.creaCentraleForm.value.longitudine;
    this.ref = this.dialogService.open(DialogPosizioneCentrale, {
      data: {
        centrale: this.centrale,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      if(changeResult && (changeResult.lat && changeResult.lng)){
        this.creaCentraleForm.get("latitudine").setValue(changeResult.lat);
        this.creaCentraleForm.get("longitudine").setValue(changeResult.lng);
      }
    });
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if(this.creaCentraleForm.valid){
        let nuovaCentrale:Centrale = new Centrale();
        let comune:ComuneDTO = this.creaCentraleForm.value.comune;

            nuovaCentrale.idTim              = this.creaCentraleForm.value.idTIM;
            nuovaCentrale.clli              = this.creaCentraleForm.value.clli;
            nuovaCentrale.clliAttestazione  = this.creaCentraleForm.value.clliAttestazione;
            nuovaCentrale.indirizzo         = this.creaCentraleForm.value.indirizzo;
            nuovaCentrale.denominazione     = this.creaCentraleForm.value.denominazione;
            nuovaCentrale.comune            = comune;
            
            nuovaCentrale.latitudine        = this.creaCentraleForm.value.latitudine;
            nuovaCentrale.longitudine       = this.creaCentraleForm.value.longitudine;
            this.centraliService.crea(nuovaCentrale).subscribe(res => {
              this.dialogRef.close({ success: true});
            },
            error => {
              console.error('ERRORE CREAZIONE CENTRALE' + error);
              this.messageService.add({severity:'error', summary: 'ATTENZIONE', detail: 'Errore in fase di creazione della centrale!' , life:10000});
              this.dialogRef.close({ success: false });
            });

      }
      
    }
  }
}



@Component({
  templateUrl: './dialog-modifica-centrale.component.html',
  styleUrls: ['./centrali.component.css']
})
export class DialogModificaCentrale implements OnInit {

  @ViewChild('supportNgForm', {static: true}) supportNgForm: NgForm;

  protected _onDestroy = new Subject<void>();
  public centraleSelezionata : Centrale;
  protected comuni:ComuneDTO[]=[];
  protected filteredComuni: any[] =[];
  protected selectedComune: ComuneDTO ;
  public modificaCentraleForm: FormGroup;
  protected comuniLoaded:boolean=false;

  constructor(
      private logger: NGXLogger,
      private _formBuilder: FormBuilder,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private centraliService:CentraliService,
      private comuniService:ComuniService,
      
      ) {
       
  }

  ngOnInit(): void {
      this.loadComuni();
      this.centraleSelezionata = this.config.data.centrale;
      this.modificaCentraleForm = this._formBuilder.group({
        clli:            [this.centraleSelezionata.clli, [Validators.required]],
        clliAttestazione: [this.centraleSelezionata.clliAttestazione, [Validators.required]],
        denominazione: [this.centraleSelezionata.denominazione, []],
        indirizzo:        [this.centraleSelezionata.indirizzo, [Validators.required]],
        comune:         [this.centraleSelezionata.comune, [Validators.required]],
        latitudine:         [this.centraleSelezionata.latitudine, [Validators.required]],
        longitudine:         [this.centraleSelezionata.longitudine, [Validators.required]],

      });
  }

  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      //this.filteredComuni = res;
      this.comuniLoaded = true;
    });
  }
 
  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
        let comune = this.comuni[i];
        if (comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredComuni = filtered;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if(this.modificaCentraleForm.valid){
         let centraleDaModificare:Centrale =  new Centrale();
        centraleDaModificare.id     =  this.centraleSelezionata.id;
        centraleDaModificare.clli  = this.modificaCentraleForm.value.clli;
        centraleDaModificare.denominazione  = this.modificaCentraleForm.value.denominazione;
        centraleDaModificare.clliAttestazione  = this.modificaCentraleForm.value.clliAttestazione;
        centraleDaModificare.indirizzo = this.modificaCentraleForm.value.indirizzo;
        centraleDaModificare.comune = this.modificaCentraleForm.value.comune;
        centraleDaModificare.latitudine = this.modificaCentraleForm.value.latitudine;
        centraleDaModificare.longitudine = this.modificaCentraleForm.value.longitudine;
        this.centraliService.modifica(centraleDaModificare).subscribe(res => {
          this.dialogRef.close({ success: true});
          //this.messageService.add({severity:'success', summary: '', detail: 'Centrale modificatta correttamente!'});
           
        },
        error => {
          console.error('ERRORE MODIFICA CENTRALE' + error);
          this.messageService.add({severity:'error', summary: 'ATTENZIONE', detail: 'Errore in fase di modifica centrale', life:10000});
        }); 
      }


    }
  }
}