import { Utente } from "./utente.model";

export class DettaglioPraticaScuolaDTO {
    id: Number;
    codiceInfratel:String;

    tipologia:String;
    diritto:String;
    proprietario:String;
    numeroAccessPoints:Number;
    lunghezza:Number;

    tipologiaTratta2:String;
    dirittoTratta2:String;
    proprietarioTratta2:String;
    lunghezzaTratta2:Number;

    hostnameCPE:String;
    ipAddress:String;
    localeTecnico:String;
    piano:String;
    stanza:String;

    cpeInStessoLocale:Boolean;

    clliSedeA:String;
    tipologiaSedeA:String;
    descrizioneSedeA:String;
    attestazioneAlRipartitoreA:String;
    attestazioneAlRipartitoreA_BU:String;

    clliSedeZ:String;
    tipologiaSedeZ:String;
    descrizioneSedeZ:String;
    attestazioneAlRipartitoreZ:String;
    attestazioneAlRipartitoreZ_BU:String;
    
    numeroTotaleCoppie:Number;
    numeroCoppieInUso:Number;
    fibra:String;
    fibra_BU:String;

    longitudineScuola:Number;
    latitudineScuola:Number;
    indirizzoScuola:String;
    comuneScuola:String;

    longitudineNodoFittizio:Number;
    latitudineNodoFittizio:Number;

    longitudineCentrale:Number;
    latitudineCentrale:Number;
    indirizzoCentrale:String;
    comuneCentrale:String;

    redattore:String;
    verificatore:String;

    idPCN:String;
    nomePCN:String;
    indirizzoPCN:String;
    latitudinePCN:Number;
    longitudinePCN:Number;

    backup:String;
    tipoBackup:String;
    
    wifi:Boolean;
    tipoBSharp:Boolean;
    annoAttivazione:Number;
  }
  