import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { id } from 'date-fns/locale';


@Injectable({
  providedIn: 'root',
})
export class SupportService{
  apiUrl: string = `${environment.apiUrl}`+"support";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  getStats(idDipendente:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}`+"support/stats";
    if(idDipendente!=null){
      this.apiUrl +="?idDipendente="+idDipendente;
    }
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  async waitForStorageValue(key: string): Promise<any> {
    //this.logger.info("*** waitForStorageValue per " + key);

    return new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        const storedValue = localStorage.getItem(key);

        if (storedValue !== null) {
          clearInterval(checkInterval);
          resolve(storedValue);
        }
      }, 500); // Intervallo di verifica (100ms, puoi regolarlo a seconda delle tue esigenze)
    });
  }
  

  
}
