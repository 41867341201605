
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label for="nome" class="font-medium text-900">Nome</label>
                <input id="nome" type="text" type="nome" [(ngModel)]="nome" pInputText>
            </div>
            <div class="field mb-4 col-12">
                <label for="email" class="font-medium text-900">Mail</label>
                <input id="email" type="text" type="nome" [(ngModel)]="email" pInputText>
            </div>
            <div class="field mb-4 col-12">
                <label for="password" class="font-medium text-900">Password</label>
                <p-password id="password" [(ngModel)]="password" [toggleMask]="true"></p-password>
            </div>
            <div class="field mb-4 col-12 md:col-12"> 
                <label for="ruolo" class="font-medium text-900">Ruolo</label>
                <p-dropdown inputId="ruolo" [options]="ruoli" optionLabel="codice" [showClear]="true"
                placeholder="Scegli il ruolo" [(ngModel)]="ruolo">
                <ng-template let-ruolo pTemplate="item">
                    <div class="flex align-items-center">
                        <div>{{ruolo.codice}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            </div>
            <div class="field mb-4 col-12 md:col-12"> 
                <label for="azienda" class="font-medium text-900">Impresa</label>
                <p-autoComplete 
                    [(ngModel)]         =   "azienda"
                    [suggestions]       =   "filteredAziende" 
                    (completeMethod)    =   "filterAzienda($event)"
                    field="ragioneSociale" placeholder="Ragione sociale"
                ></p-autoComplete>
                <!-- <p-dropdown inputId="azienda" [options]="imprese" optionLabel="ragioneSociale" [showClear]="true"
                    placeholder="Scegli l'impresa" [(ngModel)]="azienda">
                    <ng-template let-azienda pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{azienda.ragioneSociale}}</div>
                        </div>
                    </ng-template>
                </p-dropdown> -->
            </div>
        </div>
            <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

            <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
                <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
                    styleClass="p-button-success"
                    [disabled]="nome.length==0 || email.length==0 || password.length==0 || !ruolo || !azienda"></p-button>
                <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
                    styleClass="p-button-warning"></p-button>
            </div>
      
