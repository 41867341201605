 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <button pButton type="button" icon="pi pi-download" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(51, 137, 213); color: white;" tooltipPosition="top" pTooltip="Scarica Report"
      (click)="esportaImprese()" [disabled]="isLoadingResults">
</button>
   <!--  <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" 
    (click)="loadImprese()"></button> -->
  </div> 
  
  <div class="flex justify-center">
    <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">IMPRESE</span>
  </div>
  
  <div class="flex justify-end md:justify-end">
    <button  pButton type="button" icon="pi pi-upload"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(117, 189, 145); color: white;"
        tooltipPosition="top" pTooltip="Importa Imprese" (click)="caricaImprese()" > </button>

      <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuova Impresa"
      (click)="openCreaImpresaDialog()">
    </button>
    </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table #impreseDT styleClass="p-datatable-sm" [value]="imprese" dataKey="id" [(selection)]="selectedImpresa"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','ragioneSociale']">
     
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="ragioneSociale">
            <div class="flex justify-content-between align-items-center">
              Ragione Sociale
              <p-sortIcon field="ragioneSociale"></p-sortIcon>
              <p-columnFilter type="text" field="ragioneSociale" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
         <!--  <th pSortableColumn="numeroPraticheScuolaAssegnate">
            <div class="flex justify-content-between align-items-center">
              Scuole Assegnate
              <p-sortIcon field="numeroPraticheScuolaAssegnate"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheScuolaInCarico">
            <div class="flex justify-content-between align-items-center">
              Scuole In Carico
              <p-sortIcon field="numeroPraticheScuolaInCarico"></p-sortIcon>
            </div>
          </th>
         
          <th pSortableColumn="numeroPraticheScuolaConsegnate">
            <div class="flex justify-content-between align-items-center">
              Scuole Consegnate
              <p-sortIcon field="numeroPraticheScuolaConsegnate"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheScuolaInRework">
            <div class="flex justify-content-between align-items-center">
              Scuole Rework
              <p-sortIcon field="numeroPraticheScuolaInRework"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheSanitaAssegnate">
            <div class="flex justify-content-between align-items-center">
              Sanita Assegnate
              <p-sortIcon field="numeroPraticheSanitaAssegnate"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheSanitaInCarico">
            <div class="flex justify-content-between align-items-center">
              Sanita In Carico
              <p-sortIcon field="numeroPraticheSanitaInCarico"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheSanitaConsegnate">
            <div class="flex justify-content-between align-items-center">
              Sanita Consegnate
              <p-sortIcon field="numeroPraticheSanitaConsegnate"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="numeroPraticheSanitaInRework">
            <div class="flex justify-content-between align-items-center">
              Sanita Rework
              <p-sortIcon field="numeroPraticheSanitaInRework"></p-sortIcon>
            </div>
          </th> -->
          
      
         <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
          <td> <span class="text-lowercase">{{row.ragioneSociale}}</span> </td>
         <!--  <td> <span class="text-lowercase">{{row.numeroPraticheScuolaAssegnate}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheScuolaInCarico}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheScuolaConsegnate}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheScuolaInRework}}</span> </td>

          <td> <span class="text-lowercase">{{row.numeroPraticheSanitaAssegnate}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheSanitaInCarico}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheSanitaConsegnate}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroPraticheSanitaInRework}}</span> </td>
 -->
        
          <td class="text-center">
            
            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" style="color:rgb(153, 208, 57);" icon="pi pi-paperclip" class="p-button-rounded p-button-text" 
            tooltipPosition="top" pTooltip="Assegna Pratiche ad Impresa"
              (click)="openAssegnaAdImpresaDialog(row)">
            </button>

            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" 
            tooltipPosition="top" pTooltip="Modifica Impresa"
              (click)="openModificaImpresaDialog(row)">
            </button>
        
            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-text" (click)="openDeleteImpresaDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Impresa">
            </button> 

          </td>
        </tr>
      </ng-template>
     <!--  <ng-template pTemplate="footer">
        <tr>
         <td class="text-left" style="font-size: 14px;">TOTALI:</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInRework}}</td>

         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInRework}}</td> 
         <td></td>
         
       </tr>
     
   </ng-template> -->
    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
