import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';
import { PraticaScuola } from '../models/pratica.scuola.model';
import { DettaglioPraticaScuolaDTO } from '../models/dettaglioPratica.scuola.model';


@Injectable({
  providedIn: 'root',
})
export class NotePraticheService{
  apiUrl: string = `${environment.apiUrl}`+"nota-pratica-scuola";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    getById(idPratica:string): Observable<any> {
      let url = this.apiUrl+"/"+idPratica;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }


 
 

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}
