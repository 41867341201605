export class Stats {
  idAzienda: number;
  ragioneSociale: string;
  numeroPraticheScuolaAssegnate: number;
  numeroPraticheScuolaBando2Assegnate: number;
  numeroPraticheSanitaAssegnate: number;


  numeroPraticheScuolaInCarico: number;
  numeroPraticheScuolaBando2InCarico: number;
  numeroPraticheSanitaInCarico: number;


  numeroPraticheScuolaInProgettazione: number;
  numeroPraticheScuolaBando2InProgettazione: number;
  numeroPraticheSanitaInProgettazione: number;

  
  numeroPraticheScuolaConsegnate: number;

  numeroPraticheScuolaInRework: number;
  numeroPraticheScuolaInFirmaVerbale: number;
  numeroPraticheScuolaInFirmaPacchetto: number;
  numeroPraticheScuolaVerbaleFirmato: number;
  numeroPraticheScuolaPacchettoFirmato: number;
  numeroPraticheScuolaInFirma: number;
  numeroPraticheScuolaValidate: number = 0;
  numeroPraticheScuolaRespinte: number = 0;
  numeroPraticheScuolaDaAssegnare: number = 0;
  numeroPraticheScuolaInCaricoSF: number = 0;
  numeroPraticheScuolaInCaricoPresidio: number= 0;
  numeroPraticheScuolaInLavorazione: number= 0;
  numeroPraticheScuolaPubblicate: number;


  numeroPraticheScuolaBando2Validate: number = 0;
  numeroPraticheScuolaBando2Respinte: number = 0;
  numeroPraticheScuolaBando2PacchettoFirmato: number;
  numeroPraticheScuolaBando2DaAssegnare: number = 0;
  numeroPraticheScuolaBando2InCaricoSF: number = 0;
  numeroPraticheScuolaBando2InCaricoPresidio: number= 0;
  numeroPraticheScuolaBando2InLavorazione: number= 0;
  numeroPraticheScuolaBando2InFirma: number;
  numeroPraticheScuolaBando2VerbaleFirmato: number;
  numeroPraticheScuolaBando2InRework: number;
  numeroPraticheScuolaBando2Consegnate: number;
  numeroPraticheScuolaBando2InFirmaVerbale: number;
  numeroPraticheScuolaBando2InFirmaPacchetto: number;
  numeroPraticheScuolaBando2Pubblicate: number;


  numeroPraticheSanitaValidate: number = 0;
  numeroPraticheSanitaRespinte: number = 0;
  numeroPraticheSanitaPacchettoFirmato: number;
  numeroPraticheSanitaDaAssegnare: number = 0;
  numeroPraticheSanitaInCaricoSF: number = 0;
  numeroPraticheSanitaInCaricoPresidio: number= 0;
  numeroPraticheSanitaInLavorazione: number= 0;
  numeroPraticheSanitaInFirma: number;
  numeroPraticheSanitaVerbaleFirmato: number;
  numeroPraticheSanitaInRework: number;
  numeroPraticheSanitaConsegnate: number;
  numeroPraticheSanitaInFirmaVerbale: number;
  numeroPraticheSanitaInFirmaPacchetto: number;
  numeroPraticheSanitaPubblicate: number;
  
  numeroTotalePraticheSanita: number;
  numeroTotalePraticheScuolaBando1: number;
  numeroTotalePraticheScuolaBando2: number;
}


