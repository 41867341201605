<div leaflet 
  class="map-container mb-1 mt-1 ml-1 mr-1" style="height: 90%; width: 95%; z-index: 9999;"
  [leafletOptions]="mapOptions"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="mapClicked($event)">
</div>
<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
   styleClass="p-button-success" [disabled]="!positionHasChanged"></p-button>
   <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
   styleClass="p-button-warning" ></p-button>
</div>