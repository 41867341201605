<form [formGroup]="modificaPianoTecnicoSanitaForm">

  <div class="grid mt-2 formgrid p-fluid">
      <div class="field mt-2 mb-2 col-3">
        <label for="id" class="font-medium text-900">IDENTIFICATIVO</label>
        <input id="id" type="text" pInputText formControlName="id" placeholder="Identificativo" >
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="pdc" class="font-medium text-900">PDC</label>
        <input id="pdc" type="text" pInputText formControlName="pdc" placeholder="PDC">
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="cig" class="font-medium text-900">CIG</label>
        <input id="cig" type="text" pInputText formControlName="cig" placeholder="CIG">
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="comune" class="font-medium text-900">Comune</label>
        <p-autoComplete 
            formControlName="comune" 
            [suggestions]="filteredComuni" 
            (completeMethod)="filterComune($event)"
            field="denominazione" placeholder="Cerca un comune"
            ></p-autoComplete>
    </div>
     
      
  </div>
</form>

  <div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>

  <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
      <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
          styleClass="p-button-success"
          [disabled]="!modificaPianoTecnicoSanitaForm.valid"></p-button>
      <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
          styleClass="p-button-warning"></p-button>
  </div>