<form [formGroup]="creaLavoroPraticaSanitaForm">

  <div class="grid mt-2 formgrid p-fluid">
      <div class="field mt-2 mb-2 col-6">
        <label for="lunghezzaCivile" class="font-medium text-900">Lunghezza Civile</label>
        <input id="lunghezzaCivile" type="text" pInputText formControlName="lunghezzaCivile" placeholder="Lunghezza Civile (m)">
      </div>
      <div class="field mt-2 mb-2 col-6">
        <label for="categoriaInfrastruttura" class="font-medium text-900">Categoria Infrastruttura</label>
        <input id="categoriaInfrastruttura" type="text" pInputText formControlName="categoriaInfrastruttura" placeholder="Categoria Infrastruttura">
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="tipoInfrastruttura" class="font-medium text-900">Tipo Infrastruttura</label>
        <input id="tipoInfrastruttura" type="text" pInputText formControlName="tipoInfrastruttura" placeholder="Tipo Infrastruttura">
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="percorso" class="font-medium text-900">Percorso</label>
        <input id="percorso" type="text" pInputText formControlName="percorso" placeholder="Percorso">
      </div>
      <div class="field mt-2 mb-2 col-3">
        <label for="competenzaRilascioConcessione" class="font-medium text-900">Competenza Rilascio Concessione</label>
        <input id="competenzaRilascioConcessione" type="text" pInputText formControlName="competenzaRilascioConcessione" placeholder="Competenza Rilascio Concessione">
      </div>
      
  </div>
</form>

  <div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>

  <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
      <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
          styleClass="p-button-success"
          [disabled]="!creaLavoroPraticaSanitaForm.valid"></p-button>
      <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
          styleClass="p-button-warning"></p-button>
  </div>