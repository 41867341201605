import { Impresa } from "./impresa.model";
import { TipoSede } from "./pratica.tipoSede.model";
import { ScuolaDTO } from "./scuola.model";
import { Utente } from "./utente.model";

export class ModificaPraticaDTO {
    id: Number;
    codiceInfratel:String;
    codiceInvio:String;
    nomeInvio:String;
    
    idScuola:Number;
    codiceMiur:String;
    denominazioneScuola:String;
    comuneScuola:String;
    indirizzoScuola:String;
    latitudineScuola:Number;
    longitudineScuola:Number;

    idCentrale:String;
    comuneCentrale:String;
    nomeCentrale:String;
    indirizzoCentrale:String;
    latitudineCentrale:Number;
    longitudineCentrale:Number;

    
    lavorabile:Boolean;
    wifi:Boolean;
    backup:Boolean;
    tipoBackup:String;

    tipoSede:TipoSede;
    tipoSedeEffettiva:TipoSede;
  }
  