
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
<!-- <p class="line-height-3 p-0 m-0 mb-1 mt-1">
  Seleziona file da caricare o trascinalo nell'area sottostante
 </p> -->

 <ngx-file-drop
  [dropZoneLabel]="dropZoneLabel"
  [multiple]="true" accept=".p7m"
  (onFileDrop)="onFileDrop($event)"
  
>
  
</ngx-file-drop>

<div  class="flex upload-file-div-box mt-2">

    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isFileUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>

</div>

