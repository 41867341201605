import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MenuItem, MessageService } from "primeng/api";
import { environment } from "../../../../../environments/environment";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { AngularFirestore } from "@angular/fire/compat/firestore";

import { Subject } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import { ScuolaDTO } from "src/app/models/scuola.model";
import * as Leaflet from "leaflet";
import { ModificaPraticaDTO } from "src/app/models/modificaPratica.scuola.model";
import { TipoSede } from "src/app/models/pratica.tipoSede.model";
import { DialogModificaPosizione } from "./pratiche-scuola-bando2.component";
import { Bando } from "src/app/models/bando.model";
import { ComuneDTO } from "src/app/models/comune.model";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

@Component({
  selector: "app-crea-pratica-bando2",
  templateUrl: "./dialog-crea-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogCreaPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  private tipiSede: TipoSede[];
  protected pagineModificaPratica: MenuItem[];
  protected activeIndex: number = 0;

  protected dettagliForm: FormGroup;
  protected scuolaForm: FormGroup;
  protected centraleForm: FormGroup;

  protected comuniLoaded: boolean = false;

  protected localizzazioniForm: FormGroup;
  private scuola: ScuolaDTO;

  protected comuni: ComuneDTO[] = [];
  protected aziende: Impresa[] = [];
  protected filteredComuni: any[] = [];
  protected filteredAziende: any[] = [];
  protected selectedComune: ComuneDTO;

  protected ref: DynamicDialogRef;

  private collection_pratiche_scuola: string =
    environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private praticheScuolaService: PraticheScuolaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private firestoreService: AngularFirestore,
    private comuniService: ComuniService,
    private aziendeService: ImpreseService
  ) {
    this.tipiSede = [
      { id: 1, codice: "A" },
      { id: 2, codice: "B" },
      { id: 3, codice: "B*" },
      { id: 4, codice: "C" },
      { id: 5, codice: "D" },
      { id: 15, codice: "D*" },
      { id: 6, codice: "COL" },
      
    ];
  }

  ngOnInit(): void {
    /* this.pratica = this.config.data.pratica;
      this.praticaFromFirestore = this.config.data.praticaFromFirestore;
  
      this.scuola = this.config.data.scuola; */
    this.loadComuni();
    this.loadImprese();
    this.inizializzaFormGroups();
    this.inizializzaSteps();
  }

  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      //this.filteredComuni = res;
      this.comuniLoaded = true;
    });
  }
  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
      let comune = this.comuni[i];
      if (
        comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(comune);
      }
    }
    this.filteredComuni = filtered;
  }
  loadImprese() {
    this.aziendeService.getByBando("2").subscribe((aziende) => {
      this.aziende = aziende;
    });
  }
  filterAzienda(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.aziende.length; i++) {
      let azienda = this.aziende[i];
      if (
        azienda.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(azienda);
      }
    }
    this.filteredAziende = filtered;
  }

  inizializzaFormGroups() {
    this.dettagliForm = this._formBuilder.group({
      codiceInvio: [null, [Validators.required]],
      codiceInfratel: [, [Validators.required]],
      tipoSede: [null, [Validators.required]],
      tipoSedeEffettiva: [null, [Validators.required]],
      //codiceProgetto: [null],

      //colocata: [null, [Validators.required]],
      backup: [null],
      wifi: [null],
      lavorabile: [null],
      azienda: [null],
    });

    this.scuolaForm = this._formBuilder.group({
      denominazioneScuola: [null],
      codiceMiur: [null, [Validators.required]],
      indirizzoScuola: [null, [Validators.required]],
      comuneScuola: [null, [Validators.required]],
      // regioneScuola: [null, [Validators.required]],

      latitudineScuola: [null, [Validators.required]],
      longitudineScuola: [null, [Validators.required]],
    });
    this.centraleForm = this._formBuilder.group({
      idCentrale: [null, [Validators.required]],
      nomeCentrale: [null, [Validators.required]],
      indirizzoCentrale: [null, [Validators.required]],
      comuneCentrale: [null, [Validators.required]],
      regioneCentrale: [null, [Validators.required]],
      latitudineCentrale: [null, [Validators.required]],
      longitudineCentrale: [null, [Validators.required]],
    });
  }
  inizializzaSteps() {
    this.pagineModificaPratica = [
      {
        label: "Dettagli",
      },
      {
        label: "Scuola",
      },
      {
        label: "Centrale",
      },
    ];
    this.activeIndex = 0;
  }

  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineModificaPratica.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 0 && !this.dettagliForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 1 && !this.scuolaForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 2 && !this.centraleForm.valid) {
      disableNextStepButton = true;
    }

    return disableNextStepButton;
  }

  changePage() {
    this.activeIndex = this.activeIndex + 1;
  }
  openMappaScuola() {
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola: true,
      },
      header: "Posizione della scuola",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA " + changeResult);
      /*  const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
        const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
        documentRef.get().subscribe(res => {
          this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
          this.scuolaForm.get("latitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.latitude);
          this.scuolaForm.get("longitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.longitude);
        }); */
    });
  }
  openMappaCentrale() {
    this.logger.info("---OPENMAPPA CENTRALE----");
    this.ref = this.dialogService.open(DialogModificaPosizione, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola: false,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA " + changeResult);
      /*  const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
        const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
        documentRef.get().subscribe(res => {
          this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
          this.centraleForm.get("latitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.latitude);
          this.centraleForm.get("longitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.longitude);
        }); */
    });
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let praticaDaCreare: PraticaScuola = new PraticaScuola();
      let scuolaDaCreare: ScuolaDTO = new ScuolaDTO();
      let bando: Bando = new Bando();
      bando.id = 2;
      // praticaDaCreare.id                  = this.pratica.id;
      praticaDaCreare.codiceInfratel    = this.dettagliForm.value.codiceInfratel;
      praticaDaCreare.codiceInvio       = this.dettagliForm.value.codiceInvio;
      praticaDaCreare.tipoSede          = this.dettagliForm.value.tipoSede;
      praticaDaCreare.tipoSedeEffettiva = this.dettagliForm.value.tipoSedeEffettiva;
      //praticaDaCreare.codiceProgetto      = this.dettagliForm.value.codiceProgetto;
      //praticaDaCreare.lavorabile = this.dettagliForm.value.lavorabile  ? this.dettagliForm.value.lavorabile : false;
      praticaDaCreare.lavorabile = true;
      //praticaDaCreare.wifi = this.dettagliForm.value.wifi ? this.dettagliForm.value.wifi : false;
      praticaDaCreare.wifi =false;
      praticaDaCreare.backup = this.dettagliForm.value.backup
        ? this.dettagliForm.value.backup
        : false;
      praticaDaCreare.bando = bando;
      praticaDaCreare.azienda = this.dettagliForm.value.azienda
        ? this.dettagliForm.value.azienda
        : null;
      scuolaDaCreare.denominazione = this.scuolaForm.value.denominazioneScuola;
      scuolaDaCreare.indirizzo = this.scuolaForm.value.indirizzoScuola;
      scuolaDaCreare.codiceMiur = this.scuolaForm.value.codiceMiur;
      scuolaDaCreare.comune = this.scuolaForm.value.comuneScuola;
      scuolaDaCreare.latitudine = this.scuolaForm.value.latitudineScuola;
      scuolaDaCreare.longitudine = this.scuolaForm.value.longitudineScuola;
      praticaDaCreare.scuola = scuolaDaCreare;

      praticaDaCreare.idCentrale = this.centraleForm.value.idCentrale;
      praticaDaCreare.nomeCentrale = this.centraleForm.value.nomeCentrale;
      praticaDaCreare.indirizzoCentrale =
        this.centraleForm.value.indirizzoCentrale;
      praticaDaCreare.comuneCentrale =
        this.centraleForm.value.comuneCentrale.denominazione;
      praticaDaCreare.latitudineCentrale =
        this.centraleForm.value.latitudineCentrale;
      praticaDaCreare.longitudineCentrale =
        this.centraleForm.value.longitudineCentrale;

      this.logger.info(praticaDaCreare);
       this.praticheScuolaService.crea(praticaDaCreare).subscribe({
        next: (v) => {},
        error: (e) => {
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            detail: "Errore in fase di CREAZIONE della pratica",
          });
          this.dialogRef.close({ success: false });
        },
        complete: () => {
          this.logger.info("CREAZIONE PRATICA OK!");
          this.dialogRef.close({ success: true });
        },
      }); 

      /* dettaglioPraticaDTO.codiceInfratel = this.pratica.codiceInfratel;
  
        dettaglioPraticaDTO.tipologia = this.reteAccessoForm.value.tipologia;
        dettaglioPraticaDTO.diritto = this.reteAccessoForm.value.diritto;
        dettaglioPraticaDTO.proprietario =
          this.reteAccessoForm.value.proprietario;
        dettaglioPraticaDTO.numeroAccessPoints =
          this.reteAccessoForm.value.numeroAccessPoints;
        dettaglioPraticaDTO.lunghezza = this.reteAccessoForm.value.lunghezza;
  
        dettaglioPraticaDTO.hostnameCPE =
          this.ubicazioneRouterForm.value.hostnameCPE;
        dettaglioPraticaDTO.localeTecnico =
          this.ubicazioneRouterForm.value.localeTecnico;
        dettaglioPraticaDTO.piano = this.ubicazioneRouterForm.value.piano;
        dettaglioPraticaDTO.stanza = this.ubicazioneRouterForm.value.stanza;
  
        dettaglioPraticaDTO.clliSedeA =
          this.identificazioneFibraForm.value.clliSedeA;
        dettaglioPraticaDTO.tipologiaSedeA =
          this.identificazioneFibraForm.value.tipologiaSedeA;
        dettaglioPraticaDTO.descrizioneSedeA =
          this.identificazioneFibraForm.value.descrizioneSedeA;
        dettaglioPraticaDTO.attestazioneAlRipartitoreA =
          this.identificazioneFibraForm.value.attestazioneAlRipartitoreA;
  
        dettaglioPraticaDTO.clliSedeZ =
          this.identificazioneFibraForm.value.clliSedeZ;
        dettaglioPraticaDTO.tipologiaSedeZ =
          this.identificazioneFibraForm.value.tipologiaSedeZ;
        dettaglioPraticaDTO.descrizioneSedeZ =
          this.identificazioneFibraForm.value.descrizioneSedeZ;
        dettaglioPraticaDTO.attestazioneAlRipartitoreZ =
          this.identificazioneFibraForm.value.attestazioneAlRipartitoreZ;
  
        dettaglioPraticaDTO.numeroTotaleCoppie =
          this.identificazioneFibraForm.value.numeroTotaleCoppie;
        dettaglioPraticaDTO.numeroCoppieInUso =
          this.identificazioneFibraForm.value.numeroCoppieInUso;
        dettaglioPraticaDTO.fibra = this.identificazioneFibraForm.value.fibra;
  
        dettaglioPraticaDTO.indirizzoScuola =
          this.localizzazioniForm.value.indirizzoScuola;
        dettaglioPraticaDTO.latitudineScuola =
          this.localizzazioniForm.value.latitudineScuola;
        dettaglioPraticaDTO.longitudineScuola =
          this.localizzazioniForm.value.longitudineScuola;
        dettaglioPraticaDTO.indirizzoCentrale =
          this.localizzazioniForm.value.indirizzoCentrale;
        dettaglioPraticaDTO.latitudineCentrale =
          this.localizzazioniForm.value.latitudineCentrale;
        dettaglioPraticaDTO.longitudineCentrale =
          this.localizzazioniForm.value.longitudineCentrale;
  
        dettaglioPraticaDTO.redattore = this.dettagliForm.value.redattore;
        dettaglioPraticaDTO.verificatore = this.dettagliForm.value.verificatore; */

      /*   */
    }
  }
}
