import { StrutturaSanitariaDTO } from "./strutturaSanitaria.model";
import { Bando } from "./bando.model";
import { Centrale } from "./centrale.models";
import { Impresa } from "./impresa.model";
import { PianoTecnicoSanita } from "./pianoTecnicoSanita.model";
import { PCN } from "./pratica.pcn.scuola.model";
import { StatoPratica } from "./pratica.stato.scuola.model";
import { TipoSede } from "./pratica.tipoSede.model";
import { ScuolaDTO } from "./scuola.model";
import { Utente } from "./utente.model";
import { TipoConnettivitaMPLS } from "./pratica.tipoConnettiviaMPLS.model";

export class PraticaSanita {
    id: Number;
    codiceInfratel:String;
    strutturaSanitaria:StrutturaSanitariaDTO;
    pianoTecnico:PianoTecnicoSanita;
    
    codiceInvio:String;
    nomeInvio:String;
    idPDC:String;

    stato:StatoPratica;

    codiceProgetto:String;
    //ordineEsecuzione:String;
   
    redattore:String;
    verificatore:String;
    utenteAssegnato: Utente;
    centrale:Centrale;
   
    
    lavorabile:Boolean;
    wifi:Boolean;
    backup:Boolean;
    centroStella:Boolean;

    tipoSede:TipoSede;
    tipoSedeEffettiva:TipoSede;
    tipoConnettivitaMPLS:TipoConnettivitaMPLS;
    
    azienda:Impresa;
    bando:Bando;
  
  }
  