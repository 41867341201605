import { RegioneDTO } from "./regione.model";
import { Utente } from "./utente.model";

export class ComuneDTO {
    id: Number;
    codiceAlfanumerico:String;
    codiceCatastale:String;
    codiceNumerico:Number;
    denominazione:String;
    provincia:String;
    provinciaSigla:String;
    ripartizioneGeografica:String;
    regione:RegioneDTO;

   

  }
  