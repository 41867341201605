import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { DettaglioPraticaSanitaDTO } from "src/app/models/dettaglioPratica.sanita.model";
import { DettaglioPraticaScuolaDTO } from "src/app/models/dettaglioPratica.scuola.model";
import { Impresa } from "src/app/models/impresa.model";
import { PraticaSanita } from "src/app/models/pratica.sanita.model";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";
import { Tipologia } from "src/app/models/tipologia.model";
import { ImpreseService } from "src/app/services/imprese.service";
import { PraticheSanitaService } from "src/app/services/pratiche.sanita.service";

@Component({
    selector: "app-inserisci-info-pratica-sanita",
    templateUrl: "./dialog-inserisci-informazioni-pratica.component.html",
    styleUrls: ["./pratiche-sanita.component.scss"],
  })
  export class DialogInserisciInformazioniPraticaSanita implements OnInit {
    protected _onDestroy = new Subject<void>();
    public pratica: PraticaSanita;
    public praticaFromFirestore: PraticaSanita_Firestore;
    private collection_pratiche_sanita:string = environment.firebase.collection_pratiche_sanita;

    protected pagineInserisciInformazioni: MenuItem[];
    protected activeIndex: number = 0;
    protected lavoriPratica:LavoriPraticheSanitaService[]   = [];
    protected selectedLavoroPratica:LavoriPraticheSanitaService ;
    protected tipologie:Tipologia[]   = [];
    protected diritti:Tipologia[]     = [];
    protected proprietari:Tipologia[] = [];
    protected tipologieCavi:Tipologia[]   = [];
    protected tipologieConnettivitaMPLS:TipoConnettivitaMPLS[]   = [];
    public azienda:Impresa;
    public aziende:Impresa[]=[];
    protected filteredAziende:Impresa[]=[];

    protected reteAccessoForm: FormGroup;
    protected ubicazioneRouterForm: FormGroup;
    protected identificazioneFibraForm: FormGroup;
    protected localizzazioniForm: FormGroup;
    protected dettagliForm: FormGroup;
    protected ref: DynamicDialogRef;
    constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private praticheSanitaService: PraticheSanitaService,
      private _formBuilder: FormBuilder,
      public dialogService: DialogService,
      private lavoriPraticaService:LavoriPraticheSanitaService,
      private confirmationService: ConfirmationService,
      private firestoreService:AngularFirestore
    ) {}
  
    ngOnInit(): void {
        this.pratica = this.config.data.pratica;
        this.praticaFromFirestore = this.config.data.praticaFromFirestore;
        this.loadLavoriPratica();
        this.inizializzaListe();
        this.inizializzaSteps();
        
        this.inizializzaFormGroups();
        
    }

    loadLavoriPratica(){
      this.lavoriPraticaService.getByPratica(this.pratica.id.valueOf()).subscribe(lavori =>{
        this.lavoriPratica = lavori;
      });
    }

    openRichiediModificaCentrale(pratica: PraticaSanita_Firestore): void {
      this.logger.info("*** openRichiediModificaCentrale ");
      this.logger.info(this.pratica);
      this.ref = this.dialogService.open(DialogRichiediCambioCentralePraticaSanita, {
       data: {
         pratica: this.pratica,
        
       },
       header: "Richiesta cambio centrale ",
       width:  "85%",
       height: "50%",
       baseZIndex: 10000,
       closable: true,
       draggable: false,
     });
     this.ref.onClose.subscribe((changeRequested) => {
       if (changeRequested && changeRequested.success) {
         this.messageService.add({
           severity: "success",
           summary: "" , life:10000,
           detail: "Richiesta inviata con successo",
         });
       }
     }); 

}
    checkNextStep(): boolean {
      let disableNextStepButton = false;
      if (this.activeIndex === this.pagineInserisciInformazioni.length - 1) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 0 && !this.identificazioneFibraForm.valid) {
        disableNextStepButton = true;
      }else if (this.activeIndex === 1 && !this.reteAccessoForm.valid) {
        disableNextStepButton = true;
      } else if (this.activeIndex === 2 && !this.localizzazioniForm.valid) {
        disableNextStepButton = true;
      }/* else if (this.activeIndex === 4 && !this.dettagliForm.valid) {
        disableNextStepButton = true;
      } *//* else if (this.activeIndex === 3 ) {
        disableNextStepButton = true;
      } */
      return disableNextStepButton;
    }
    inizializzaListe(){
      let tipologiaScavo:Tipologia = new Tipologia();
      let tipologiaFibra:Tipologia = new Tipologia();
      let tipologiaMinitubo:Tipologia = new Tipologia();
      
      let tipologiaConnettivitaMPLS_1:TipoConnettivitaMPLS = new TipoConnettivitaMPLS();
      let tipologiaConnettivitaMPLS_2:TipoConnettivitaMPLS = new TipoConnettivitaMPLS();
      let tipologiaConnettivitaMPLS_3:TipoConnettivitaMPLS = new TipoConnettivitaMPLS();
      tipologiaConnettivitaMPLS_1.codice = "1";
      tipologiaConnettivitaMPLS_1.id = 1;
      tipologiaConnettivitaMPLS_1.descrizione = "doppio link diversificato";

      tipologiaConnettivitaMPLS_2.id = 2;
      tipologiaConnettivitaMPLS_2.codice = "2";
      tipologiaConnettivitaMPLS_2.descrizione = "doppio link schiacciato";
      tipologiaConnettivitaMPLS_3.codice = "3";
      tipologiaConnettivitaMPLS_3.id = 3;
      tipologiaConnettivitaMPLS_3.descrizione = "singolo link";

      this.tipologieConnettivitaMPLS.push(tipologiaConnettivitaMPLS_1);
      this.tipologieConnettivitaMPLS.push(tipologiaConnettivitaMPLS_2);
      this.tipologieConnettivitaMPLS.push(tipologiaConnettivitaMPLS_3);

      tipologiaScavo.codice = "Scavo";
      tipologiaFibra.codice = "Fibra";
      tipologiaMinitubo.codice = "Minitubo";
      this.tipologie.push(tipologiaFibra);
      this.tipologie.push(tipologiaScavo);
      this.tipologie.push(tipologiaMinitubo);
      let tipologiaCavo12:Tipologia = new Tipologia();
      let tipologiaCavo24:Tipologia = new Tipologia();
      tipologiaCavo12.codice = "Cavo 12";
      tipologiaCavo24.codice = "Cavo 24";
      this.tipologieCavi.push(tipologiaCavo12);
      this.tipologieCavi.push(tipologiaCavo24);
  
      let dirittoProprieta:Tipologia = new Tipologia();
      let dirittoIRU:Tipologia = new Tipologia();
      let dirittoSubIRU:Tipologia = new Tipologia();
      
      dirittoProprieta.codice = "Proprietà";
      dirittoIRU.codice = "IRU";
      dirittoSubIRU.codice = "SubIRU";
      this.diritti.push(dirittoProprieta);
      this.diritti.push(dirittoIRU);
      this.diritti.push(dirittoSubIRU);
  
      let proprietarioTIM:Tipologia = new Tipologia();
      let proprietarioINFRATEL:Tipologia = new Tipologia();
      let proprietarioFastweb:Tipologia = new Tipologia();
      proprietarioTIM.codice = "TIM";
      proprietarioINFRATEL.codice = "Infratel";
      proprietarioFastweb.codice = "Fastweb";
      
      this.proprietari.push(proprietarioTIM);
      this.proprietari.push(proprietarioINFRATEL);
      this.proprietari.push(proprietarioFastweb);
  
      
    }
    getTipologiaConnettivitaMPL(tipologia:String):number{
      switch (tipologia) {
        case "1":
          return 0;
        case "2":
          return 1;
        case "3":
          return 2;
        default:
          return null;
      }
    }
    getTipologia(tipologia:String):number{
      switch (tipologia) {
        case "SCAVO":
          return 1;
        case "Scavo":
          return 1;
        case "SCAVO":
          return 1;
        case "FIBRA":
          return 0;
        case "Fibra":
          return 0;
        case "MINITUBO":
          return 2;
        case "Minitubo":
          return 2;
        default:
          return null;
      }
    }
    getTipologiaCavo(tipologia:String):number{
  
      switch (tipologia) {
        case "Cavo 12":
          return 0;
        case "Cavo 24":
          return 1;
        default:
          return null;
      }
    }
    getDiritto (diritto:String):number{
      switch (diritto) {
        case "Proprietà":
          return 0;
        case "IRU":
          return 1;
        default:
          return 2;
      }
    }
    getProprietario(proprietario:String):number{
      switch (proprietario) {
        case "TIM":
          return 0;
          case "INFRATEL":
            return 1;
        default:
          return 2;
      }
    }
    inizializzaFormGroups() {
      this.logger.info(this.pratica);
      this.identificazioneFibraForm = this._formBuilder.group({
        clliSedeA: new FormControl({value: this.pratica.strutturaSanitaria.codiceAccessoInfratel, disabled: true}, Validators.required), 
        tipologiaSedeA: [this.pratica.strutturaSanitaria.tipologiaSedeA, [Validators.required]],
        descrizioneSedeA: [this.pratica.strutturaSanitaria.descrizioneSedeA, [Validators.required]],
        attestazioneAlRipartitoreA: [ this.pratica.strutturaSanitaria.attestazioneRipartitoreA,  [Validators.required],],
        clliSedeZ:           new FormControl({value: this.pratica.centrale.clli, disabled: true}, Validators.required), 
        tipologiaSedeZ: [this.pratica.strutturaSanitaria.tipologiaSedeZ, [Validators.required]],
        descrizioneSedeZ: [this.pratica.strutturaSanitaria.descrizioneSedeZ, [Validators.required]],
        attestazioneAlRipartitoreZ: [  this.pratica.strutturaSanitaria.attestazioneRipartitoreZ, [Validators.required], ],
        
        numeroTotaleCoppie: [
          this.pratica.strutturaSanitaria.numeroTotaleCoppie,
          [Validators.required],
        ],
        numeroCoppieInUso: [this.pratica.strutturaSanitaria.numeroCoppieInUso, [Validators.required]],
        fibra: [this.pratica.strutturaSanitaria.numeroFibra, [Validators.required]],
        centroStella: [this.pratica.centroStella, [Validators.required]],
        tipoConnettivitaMPLS: [this.pratica.tipoConnettivitaMPLS?this.tipologieConnettivitaMPLS[this.getTipologiaConnettivitaMPL(this.pratica.tipoConnettivitaMPLS.codice)]:null, [Validators.required]],

      });
       this.reteAccessoForm = this._formBuilder.group({
        tipologia: [this.pratica.strutturaSanitaria.tipologia?this.tipologie[this.getTipologia(this.pratica.strutturaSanitaria.tipologia)]:null, [Validators.required]],
        diritto: [this.pratica.strutturaSanitaria.diritto?this.diritti[this.getDiritto(this.pratica.strutturaSanitaria.diritto)]:null, [Validators.required]],
        proprietario: [this.pratica.strutturaSanitaria.proprietario?this.proprietari[this.getProprietario(this.pratica.strutturaSanitaria.proprietario)]:null, [Validators.required]],
        lunghezza: [this.pratica.strutturaSanitaria.lunghezza, [Validators.required]],
      //  idPDC: [this.pratica.idPDC, [Validators.required]],
        hostnameCPE: [this.pratica.strutturaSanitaria.hostnameCpe, [Validators.required]],
        indirizzoIP: [this.pratica.strutturaSanitaria.ipAddress, [Validators.required]],
        localeTecnico: [this.pratica.strutturaSanitaria.localeTecnicoNome, [Validators.required]],
        piano: [this.pratica.strutturaSanitaria.localeTecnicoPiano, [Validators.required]],
        stanza: [this.pratica.strutturaSanitaria.localeTecnicoStanza, [Validators.required]],
      });
      this.localizzazioniForm = this._formBuilder.group({
        indirizzoAccesso: [this.pratica.strutturaSanitaria.indirizzo, [Validators.required]],
        comuneAccesso: new FormControl({value: this.pratica.strutturaSanitaria.comune.denominazione, disabled: true}, Validators.required), 
        latitudineAccesso: [this.pratica.strutturaSanitaria.latitudine, [Validators.required]],
        longitudineAccesso: [this.pratica.strutturaSanitaria.longitudine, [Validators.required]],
        //indirizzoCentrale: [ this.pratica.indirizzoCentrale, [Validators.required], ],
        indirizzoCentrale: new FormControl({value: this.pratica.centrale.indirizzo, disabled: true}, Validators.required),
        comuneCentrale: new FormControl({value: this.pratica.centrale.comune.denominazione, disabled: true}, Validators.required),
  
        //latitudineCentrale: [ this.praticaFromFirestore.coordinateCentrale.latitude,[Validators.required],],
        latitudineCentrale:  new FormControl({value: this.pratica.centrale.latitudine, disabled: true}, Validators.required),
        longitudineCentrale:  new FormControl({value: this.pratica.centrale.longitudine, disabled: true}, Validators.required),
        //latitudineNodoFittizio: [this.isBandoD?this.pratica.latitudineNodoFittizio:null, this.isBandoD?[Validators.required]:null],
        //longitudineNodoFittizio: [this.isBandoD?this.pratica.longitudineNodoFittizio:null, this.isBandoD?[Validators.required]:null],
      });
     
      this.dettagliForm = this._formBuilder.group({
        redattore: [this.pratica.redattore, [Validators.required]],
        verificatore: [this.pratica.verificatore, [Validators.required]],
      }); 
    }
  
    changePage() {
      this.activeIndex = this.activeIndex + 1;
    }

    openModificaDettaglioLavori(dettaglioLavori:LavoroPraticaSanita){
      this.ref = this.dialogService.open(DialogModificaLavoroPraticaSanita, {
        data: {
          pratica: this.pratica,
          lavoro:dettaglioLavori,
        
        },
        header: "Modifica Lavoro della Pratica "+this.pratica.codiceInfratel,
        width:  "85%",
        height: "85%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((infoUpdated) => {
        this.logger.info("FINITO MODIFICA LAVORO");
        if (infoUpdated && infoUpdated.success) {
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Lavoro modificato con successo",
          });
          this.loadLavoriPratica();
        }
      });
    }

    openDeleteDettaglioLavori(lavoro:LavoroPraticaSanita)
    {
      this.confirmationService.confirm({
        // message: `Confermi l\'eliminazione della centrale ${centrale.ragioneSociale}?`,
         message: `Confermi l\'eliminazione del lavoro?`,
         header: "Eliminazione LAVORO",
         icon: "pi pi-info-circle",
         acceptLabel: "CONFERMA",
         acceptButtonStyleClass: "p-button-success",
         rejectLabel: "ANNULLA",
         rejectButtonStyleClass: "p-button-warning",
         accept: () => {
   
           this.lavoriPraticaService.elimina(lavoro).subscribe({
             next: (v) => {},
             error: (e) => {
               this.messageService.add({
                 severity: "info",
                 summary: "Errore",
                 detail: "Errore in fase di eliminazione lavoro", life:10000
               });
             },
             complete: () => {
               this.messageService.add({
                 severity: "success",
                 summary: "",
                 detail: "LAVORO eliminato con successo", life:10000
               });
               this.loadLavoriPratica();
             },
           }); 
         },
         reject: () => {
          
         },
       }); 


    }
    openCreaDettaglioLavoro(){
      this.ref = this.dialogService.open(DialogCreaLavoroPraticaSanita, {
        data: {
          pratica: this.pratica,
        
        },
        header: "Inserimento Lavoro per la Pratica "+this.pratica.codiceInfratel,
        width:  "85%",
        height: "85%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((infoUpdated) => {
        this.logger.info("FINITO INSERIMENTO LAVORO");
        if (infoUpdated && infoUpdated.success) {
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Lavoro creato  con successo",
          });
          this.loadLavoriPratica();
        }
      });
      
    }

    inizializzaSteps() {
      this.pagineInserisciInformazioni = [
        {
          label: "Collegamento e Router",
        },
        {
          label: "Dettagli fisici",
        },
        {
          label: "Localizzazione",
        },
        {
          label: "Lavori",
        }/* ,
        {
          label: "Dettagli documenti",
        }, */
      ];
      this.activeIndex = 0;
    }

    openMappaAccesso(){
      this.logger.info("---OPENMAPPA ACCESSO----");
      this.ref = this.dialogService.open(DialogModificaPosizioneAccesso, {
        data: {
          pratica: this.praticaFromFirestore,
          isScuola:true,
        },
        header: "Posizione della scuola",
        width: "70%",
        height: "75%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((changeResult) => {
        this.logger.info("CHIUDO MAPPA ");
        this.logger.info(changeResult.coordinate._lat);          
        this.logger.info(changeResult.coordinate._long);
        this.localizzazioniForm.get("latitudineAccesso").setValue(changeResult.coordinate._lat);
        this.localizzazioniForm.get("longitudineAccesso").setValue(changeResult.coordinate._long);
       /*  const collectionRef = this.firestoreService.collection(this.collection_pratiche_sanita);
        const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
        documentRef.get().subscribe(res => {
          this.praticaFromFirestore =  res.data() as PraticaSanita_Firestore;
          this.localizzazioniForm.get("latitudineAccesso").setValue(this.praticaFromFirestore.coordinateAccesso.latitude);
          this.localizzazioniForm.get("longitudineAccesso").setValue(this.praticaFromFirestore.coordinateAccesso.longitude);
        }); */
      });
    }
  
   
    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {
        
        let dettaglioPraticaDTO: DettaglioPraticaSanitaDTO =  new DettaglioPraticaSanitaDTO();
        dettaglioPraticaDTO.id = this.pratica.id;
        dettaglioPraticaDTO.codiceInfratel = this.pratica.codiceInfratel;
  
        dettaglioPraticaDTO.lunghezza     = this.reteAccessoForm.value.lunghezza;
        dettaglioPraticaDTO.tipologia     = this.reteAccessoForm.value.tipologia.codice;
        dettaglioPraticaDTO.diritto       = this.reteAccessoForm.value.diritto.codice;
        dettaglioPraticaDTO.proprietario  =  this.reteAccessoForm.value.proprietario.codice;
  
       
      //  dettaglioPraticaDTO.idPDC   =  this.reteAccessoForm.value.idPDC;
        dettaglioPraticaDTO.hostnameCPE   =  this.reteAccessoForm.value.hostnameCPE;
        dettaglioPraticaDTO.ipAddress   =  this.reteAccessoForm.value.indirizzoIP;
        dettaglioPraticaDTO.localeTecnico = this.reteAccessoForm.value.localeTecnico;
        dettaglioPraticaDTO.piano = this.reteAccessoForm.value.piano;
        dettaglioPraticaDTO.stanza = this.reteAccessoForm.value.stanza;
  
        dettaglioPraticaDTO.clliSedeA                   = this.identificazioneFibraForm.value.clliSedeA;
        dettaglioPraticaDTO.tipologiaSedeA              = this.identificazioneFibraForm.value.tipologiaSedeA;
        dettaglioPraticaDTO.descrizioneSedeA            = this.identificazioneFibraForm.value.descrizioneSedeA;
        dettaglioPraticaDTO.attestazioneAlRipartitoreA  = this.identificazioneFibraForm.value.attestazioneAlRipartitoreA;
  
        dettaglioPraticaDTO.clliSedeZ                   =   this.identificazioneFibraForm.value.clliSedeZ;
        dettaglioPraticaDTO.tipologiaSedeZ              =   this.identificazioneFibraForm.value.tipologiaSedeZ;
        dettaglioPraticaDTO.descrizioneSedeZ            =   this.identificazioneFibraForm.value.descrizioneSedeZ;
        dettaglioPraticaDTO.attestazioneAlRipartitoreZ  =   this.identificazioneFibraForm.value.attestazioneAlRipartitoreZ;
        
        dettaglioPraticaDTO.numeroTotaleCoppie =
          this.identificazioneFibraForm.value.numeroTotaleCoppie;
        dettaglioPraticaDTO.numeroCoppieInUso =
          this.identificazioneFibraForm.value.numeroCoppieInUso;
        dettaglioPraticaDTO.fibra = this.identificazioneFibraForm.value.fibra;
  
       
        dettaglioPraticaDTO.centroStella          = this.identificazioneFibraForm.value.centroStella;
        dettaglioPraticaDTO.tipoConnettivitaMPLS  = this.identificazioneFibraForm.value.tipoConnettivitaMPLS;
        dettaglioPraticaDTO.indirizzoAccesso     =  this.localizzazioniForm.value.indirizzoAccesso;
        dettaglioPraticaDTO.latitudineAccesso    =  this.localizzazioniForm.value.latitudineAccesso;
        dettaglioPraticaDTO.longitudineAccesso   =  this.localizzazioniForm.value.longitudineAccesso;
  

  
        dettaglioPraticaDTO.redattore = this.dettagliForm.value.redattore;
        dettaglioPraticaDTO.verificatore = this.dettagliForm.value.verificatore;
        this.logger.info(dettaglioPraticaDTO);
        this.praticheSanitaService
          .caricaDettagliPratica(dettaglioPraticaDTO)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di AGGIORNAMENTO DETTAGLI della pratica",
              });
              this.dialogRef.close({ success: false });
            },
            complete: () => {
            
              this.dialogRef.close({ success: true });
             
            },
          }); 
         
        
    }
  }
}

import { environment } from "../../../../environments/environment";

import * as Leaflet from "leaflet";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { GeoPoint } from "firebase/firestore";
import { TipoConnettivitaMPLS } from "src/app/models/pratica.tipoConnettiviaMPLS.model";
import { LavoriPraticheSanitaService } from "src/app/services/lavoriPraticheSanita.service";
import { LavoroPraticaSanita } from "src/app/models/pratica.lavoro.sanita.model";
import { DialogRichiediCambioCentralePraticaSanita } from "./cambio-centrale-sanita.component";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

@Component({
  selector: "app-modifica-posizione-sanita",
  templateUrl: "./dialog-modifica-posizione-accesso.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogModificaPosizioneAccesso implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected isScuola:boolean = true;
  public pratica: PraticaSanita_Firestore;
  private collection_pratiche_sanita:string = environment.firebase.collection_pratiche_sanita;
  protected initialLat: number;
  protected initialLng: number;
  protected finalLat: number;
  protected finalLng: number;
  protected positionHasChanged:boolean = false;

  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.isScuola = this.config.data.isScuola;
  }
  initMarkers() {
    let initialMarkers = [];
    if(this.isScuola){
       initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateAccesso.latitude.valueOf(),
            lng: this.pratica.coordinateAccesso.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }else {
      initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateCentrale.latitude.valueOf(),
            lng: this.pratica.coordinateCentrale.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }
  
    const data = initialMarkers[0];
    const marker = this.generateMarker(data, 0);
    marker
      .addTo(this.map)
      .bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
    this.map.panTo(data.position);
    this.markers.push(marker);
  }

  generateMarker(data: any, index: number) {
    return Leaflet.marker(data.position, { draggable: data.draggable })
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
  }
  markerClicked($event: any, index: number) {}

 

  markerDragEnd($event: any, index: number) {
    this.logger.info("NUOVE COORDINATE " + $event.target._latlng.lat + " "+ $event.target._latlng.lng);

    if(this.initialLat!==$event.target._latlng.lat && this.initialLng!==$event.target._latlng.lng){
      this.positionHasChanged = true;
      this.finalLat = $event.target._latlng.lat;
      this.finalLng = $event.target._latlng.lng;
    }
    
    
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let nuoveCoordinate:GeoPoint = new GeoPoint( this.finalLat ,this.finalLng );
      this.dialogRef.close({ success: true, coordinate: nuoveCoordinate });

      /* const collectionRef = this.firestoreService.collection(this.collection_pratiche_sanita);
      const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      let updatedData:any ;// { coordinateScuola: nuoveCoordinate };
      if(!this.isScuola){
        updatedData  = { coordinateCentrale : nuoveCoordinate };
      }else {
        updatedData  = { coordinateAccesso : nuoveCoordinate };
      }
   
      documentRef
        .update(updatedData)
        .then(() => {
          this.logger.info( "OK AGGIORNAMENTO COORDINATE SU FIRESTORE");
          this.dialogRef.close({ success: true, coordinate: nuoveCoordinate });
        })
        .catch((error) => {
          this.logger.error(
            "ERRORE AGGIORNATO COORDINATE SU FIRESTORE"
          );
        }); */
    }
  }
    

  

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

@Component({
  selector: "app-crea-lavoro-sanita",
  templateUrl: "./dialog-crea-lavoro-pratica.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogCreaLavoroPraticaSanita implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaSanita;
  
  protected creaLavoroPraticaSanitaForm: FormGroup;

  isLoadingResults: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private lavoriPraticaService:LavoriPraticheSanitaService,

  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.creaLavoroPraticaSanitaForm = this._formBuilder.group({
      lunghezzaCivile:         [null, [Validators.required]],
      categoriaInfrastruttura:    [null, [Validators.required]],
      tipoInfrastruttura:         [null, [Validators.required]],
      percorso:     [null],
      competenzaRilascioConcessione:     [null]
    });
  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.logger.info("CREO NUOVO LAVORO");
      let nuovoLavoro:LavoroPraticaSanita = new LavoroPraticaSanita();
          nuovoLavoro.categoriaInfrastruttura = this.creaLavoroPraticaSanitaForm.get('categoriaInfrastruttura').value;
          nuovoLavoro.tipoInfrastruttura = this.creaLavoroPraticaSanitaForm.get('tipoInfrastruttura').value;
          nuovoLavoro.lunghezzaCivile = this.creaLavoroPraticaSanitaForm.get('lunghezzaCivile').value;
          nuovoLavoro.percorso= this.creaLavoroPraticaSanitaForm.get('percorso').value;
          nuovoLavoro.competenzaRilascioConcessione= this.creaLavoroPraticaSanitaForm.get('competenzaRilascioConcessione').value;
          nuovoLavoro.pratica = this.pratica;
          this.isLoadingResults = true;
          this.lavoriPraticaService.crea(nuovoLavoro).subscribe(result => {
              this.isLoadingResults = false;
              this.dialogRef.close({ success: true, lavoro: nuovoLavoro });
          },
          error => {
            this.isLoadingResults = false;
            this.dialogRef.close({ success: false, lavoro: null });
          }
          );
    }
  }
}


@Component({
  selector: "app-modifica-lavoro-sanita",
  templateUrl: "./dialog-modifica-lavoro-pratica.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogModificaLavoroPraticaSanita implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaSanita;
  public lavoro: LavoroPraticaSanita;
  
  protected modificaDettaglioLavoroForm: FormGroup;

  isLoadingResults: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private lavoriPraticaService:LavoriPraticheSanitaService,

  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.lavoro = this.config.data.lavoro;

    this.modificaDettaglioLavoroForm = this._formBuilder.group({
      lunghezzaCivile:         [this.lavoro.lunghezzaCivile, [Validators.required]],
      categoriaInfrastruttura:    [this.lavoro.categoriaInfrastruttura, [Validators.required]],
      tipoInfrastruttura:         [this.lavoro.tipoInfrastruttura, [Validators.required]],
      percorso:     [this.lavoro.percorso],
      competenzaRilascioConcessione:     [this.lavoro.competenzaRilascioConcessione]
    });
  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.logger.info("CREO NUOVO LAVORO");
      //let nuovoLavoro:LavoroPraticaSanita = new LavoroPraticaSanita();
          this.lavoro.categoriaInfrastruttura       = this.modificaDettaglioLavoroForm.get('categoriaInfrastruttura').value;
          this.lavoro.tipoInfrastruttura            = this.modificaDettaglioLavoroForm.get('tipoInfrastruttura').value;
          this.lavoro.lunghezzaCivile               = this.modificaDettaglioLavoroForm.get('lunghezzaCivile').value;
          this.lavoro.percorso                      = this.modificaDettaglioLavoroForm.get('percorso').value;
          this.lavoro.competenzaRilascioConcessione = this.modificaDettaglioLavoroForm.get('competenzaRilascioConcessione').value;
          //this.lavoro.pratica = this.pratica;
          this.isLoadingResults = true;
          this.lavoriPraticaService.modifica(this.lavoro).subscribe(result => {
              this.isLoadingResults = false;
              this.dialogRef.close({ success: true, lavoro: this.lavoro });
          },
          error => {
            this.isLoadingResults = false;
            this.dialogRef.close({ success: false, lavoro: null });
          }
          );
    }
  }
}