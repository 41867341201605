import { PraticaSanita } from "./pratica.sanita.model";
import { StatoPratica } from "./pratica.stato.scuola.model";


export class LavoroPraticaSanita {
    id: Number;
    pratica:PraticaSanita;
    lunghezzaCivile:number;
    categoriaInfrastruttura:string;
    tipoInfrastruttura:string;
    percorso:string;
    competenzaRilascioConcessione:string;
    creato:Date;
    modificato:Date;

  }
  