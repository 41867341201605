import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, HostBinding, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { MessageService } from "primeng/api";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Observable, Subject, map } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { Stats } from "src/app/models/stats.model";


import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { StatsService } from "src/app/services/notification.service";
import { ImpreseService } from "src/app/services/imprese.service";

export let applicationVersionFromHome: string = "";

import * as Leaflet from "leaflet";

import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { onSnapshot } from "firebase/firestore";
import { TabViewChangeEvent } from "primeng/tabview";
import { environment } from "src/environments/environment";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

declare const CSVBoxImporter: any;

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  ruolo: string = "";
  nome: string = "";
  isLoading: boolean = true;
  isAdmin:      boolean = false;
  isSuperAdmin: boolean = false;
  
  isDipendente: boolean = true;
  hasBando1: boolean = false;
  hasBando2: boolean = false;
  hasSanita: boolean = false;
  
  isAzienda : boolean = false;
  isPresidioTIM : boolean = false;
  isPresidioSF : boolean = false;
  isDirigenteFirmaVerbali: boolean = false;
  isDirigenteFirmaPacchetti: boolean = false;

  ragioneSocialeAzienda: string = "";

  protected lastPraticheSanita:PraticaScuola_Firestore[]=[];
  protected lastPraticheSanitaPerAzienda:PraticaScuola_Firestore[]=[];

  protected selectedPratica:PraticaScuola_Firestore;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;
  private collection_pratiche_sanita:string = environment.firebase.collection_pratiche_sanita;

  private selectedTabID:number =0; 

  @ViewChild("ultimePraticheSanitaDT", { static: true }) ultimePraticheSanitaDT: any;


  isLoggedIn: boolean = false;
  scuoleLoaded: boolean = false;
  sanitaLoaded: boolean = false;
  private stats:Stats = new Stats;

  applicationVersion: string = "";
  contatore: number = 0;
  ref: DynamicDialogRef;

  //CSV BOX
  private csvbox_licenseKey_aggiornaPratiche:string=environment.csvBox.secret_aggiornaPratiche;
  private csvbox_licenseKey_aggiornaPraticheBando1:string=environment.csvBox.secret_aggiornaPraticheBando1;
  private csvbox_licenseKey_aggiornaPraticheSanita:string=environment.csvBox.secret_aggiornaPraticheSanita;
  private csvbox_user:string=environment.csvBox.user;
  private csvbox_importer_aggiornaPratiche:any;
  private csvbox_importer_aggiornaPraticheBando1:any;
  private csvbox_importer_aggiornaPraticheSanita:any;

  @Output() notificaCambioDatiEvent = new EventEmitter<string>();

  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];

  protected mapOptions: L.MapOptions = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 5.5,
    center: { lat: 41.814, lng: 12.122 },
  };
  protected markerClusterGroup: Leaflet.MarkerClusterGroup;
  protected markerClusterData : Leaflet.Marker[] = [];

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private messageService: MessageService,
    private dipendenteService: UtentiService,
    private impreseService: ImpreseService,

    private userService: UserService,
    public dialogService: DialogService,
    private logger: NGXLogger,
    private router: Router,
    public firestore: AngularFirestore,
    private statsService: StatsService,
    
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {

    this.auth.user$.subscribe((user) => {
      
    

      this.markerClusterGroup = Leaflet.markerClusterGroup({removeOutsideVisibleBounds: true});
      this.initCSVBox();
      if (user != null) {
        if(!localStorage.getItem("token") || localStorage.getItem("token")=='null') {
          this.logger.info("--> CHIAMO TOKEN");
            this.auth.getAccessTokenSilently().subscribe((token) => {
              localStorage.setItem("token", token);
          });
        }
        //this.dipendenteService.get().subscribe(utente => {
        if(!this.userService.getUser()){
          this.dipendenteService.getByIdAuth0(user.sub.slice(user.sub.indexOf('|')+1)).subscribe(utente => {
            this.userService.setUser(utente);
            this.ragioneSocialeAzienda = utente.azienda.ragioneSociale;
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("ruolo", this.userService.getUser().ruolo.codice.toString());
            localStorage.setItem("idAzienda", this.userService.getUser().azienda.id.toString());
            localStorage.setItem("ragioneSocialeAzienda", utente.azienda.ragioneSociale);
            localStorage.setItem("idUtente", utente.id);
            if(this.userService.isAzienda){
              localStorage.setItem("isAzienda",  "true");

              this.isAzienda = true;
              this.impreseService.getBandi(utente.azienda.id).subscribe(bandi =>{
                bandi.forEach(bando =>{
                  if(bando.denominazione ==='BANDO 1'){
                    localStorage.setItem("BANDO_1", "true");
                    this.hasBando1 = true;
                    this.loadStatsBando1PerAzienda(localStorage.getItem("idAzienda"));
                  }else if(bando.denominazione ==='BANDO 2'){
                    localStorage.setItem("BANDO_2", "true");
                    this.hasBando2 = true;
                    this.loadStatsBando2PerAzienda(localStorage.getItem("idAzienda"));
                  }else if(bando.denominazione ==='BANDO SANITA'){
                    localStorage.setItem("BANDO_SANITA", "true");
                    this.hasSanita = true;
                    this.loadStatsSanitaPerAzienda(localStorage.getItem("idAzienda"));

                  }
                });
              });

            }else{
              
              this.isAzienda = false;
              if(localStorage.getItem("ruolo")==='ADMIN'){

                this.isAdmin = true;
              }else if(localStorage.getItem("ruolo")==='SUPERADMIN'){
                this.isSuperAdmin = true;
              }else if(localStorage.getItem("ruolo")==='PRESIDIO'){
                this.isPresidioTIM= true;
              }else if(localStorage.getItem("ruolo")==='SMARTFIBER'){
                this.isPresidioSF= true;
              }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_VERBALI'){
                this.isDirigenteFirmaVerbali= true;
              }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_PACCHETTI'){
                this.isDirigenteFirmaPacchetti= true;
              }
              localStorage.setItem("isAzienda",  "false");
            }
            this.logger.info("--> RUOLO @ HOME > "+ localStorage.getItem("ruolo"));
            this.logger.info("--> is azienda > "+ this.isAzienda);
            if(this.userService.isAzienda){
             // this.loadStatsPerAzienda(this.userService.getUser().azienda.id.toString());
              if(this.selectedTabID===1){
                this.loadStatsSanitaPerAzienda(this.userService.getUser().azienda.id.toString());
                this.loadLast5PraticheSanitaPerAzienda();
                this.loadMapSanitaPerAzienda(this.userService.getUser().azienda.id);
              }else{
                this.loadMapPerAzienda(this.userService.getUser().azienda.id);
              }
              
              
            }else{
             
              //this.configureMap();
             // this.loadStats();
              //if(this.isAdmin || this.isSuperAdmin){
               
              //}
                
              if(this.selectedTabID===1){
                this.loadAdminSanitaStats();
                this.loadLast5PraticheSanita();
    
                this.loadMapSanita();
               }else{
                this.loadAdminStats();
                this.loadMap();
               }
              
            }
            
          }); 
        }else{
         
          if(localStorage.getItem("isAzienda")==='true'){
            this.isAzienda = true;
              this.impreseService.getBandi(localStorage.getItem("idAzienda")).subscribe(bandi =>{

                bandi.forEach(bando =>{

                  if(bando.denominazione ==='BANDO 1'){
                    localStorage.setItem("BANDO_1", "true");
                    this.hasBando1 = true;
                    this.loadStatsBando1PerAzienda(localStorage.getItem("idAzienda"));
                  }else if(bando.denominazione ==='BANDO 2'){
                    localStorage.setItem("BANDO_2", "true");
                    this.hasBando2 = true;
                    this.loadStatsBando2PerAzienda(localStorage.getItem("idAzienda"));
                  }else if(bando.denominazione ==='BANDO SANITA'){
                    localStorage.setItem("BANDO_SANITA", "true");
                    this.loadStatsSanitaPerAzienda(localStorage.getItem("idAzienda"));
                    this.hasSanita = true;
                  }
                });
              });
            this.ragioneSocialeAzienda = localStorage.getItem("ragioneSocialeAzienda");
            this.loadStatsPerAzienda(localStorage.getItem("idAzienda"));
            this.loadMapPerAzienda(this.userService.getUser().azienda.id);
          }else{
            this.isAzienda = false;
            if(localStorage.getItem("ruolo")==='ADMIN'){
              
              this.isAdmin = true;
            }else if(localStorage.getItem("ruolo")==='SUPERADMIN'){
              this.isSuperAdmin = true;
            }else if(localStorage.getItem("ruolo")==='PRESIDIO'){
              this.isPresidioTIM= true;
            }else if(localStorage.getItem("ruolo")==='SMARTFIBER'){
              this.isPresidioSF= true;
            }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_VERBALI'){
              this.isDirigenteFirmaVerbali= true;
            }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_PACCHETTI'){
              this.isDirigenteFirmaPacchetti= true;
            }
            localStorage.setItem("isAzienda",  "false");
            


            this.loadStats();
           // if(this.isAdmin || this.isSuperAdmin){
              this.loadAdminStats();
            //}
            this.loadMap();
          }
        }
        this.isLoading = false;
  
        //this.isLoading = false;
        this.isLoggedIn = true;
     
        this.nome = user.name;
        //
        if (
          this.ruolo == "SUPERADMIN" ||
          this.ruolo == "ADMIN" ||
          this.ruolo == "CONTABILE" ||
          this.ruolo == "RESPONSABILE"
        ) {
      
        } else {
          //
          
        } 
        
      }else{
        console.log(' userService getUser NON NULLO');
        if(this.userService.isAzienda){
          this.loadStatsPerAzienda(this.userService.getUser().azienda.id.toString());
        }else{
          this.initializeMap();
          this.loadStats();
         // if(this.isAdmin || this.isSuperAdmin || this.){
          this.loadAdminStats();
         // }
          this.loadMap();
        }
      }
     
    });
  }
  
  onTabChange(event: TabViewChangeEvent) {
    const selectedTabId = event.index; // Otteniamo l'ID del tab selezionato
    this.selectedTabID = selectedTabId;
    if(selectedTabId==1){
      this.cleanMarkers();
      
      if(this.isAzienda){
        this.loadStatsSanitaPerAzienda(localStorage.getItem("idAzienda"));
        this.cleanMarkers();
        //this.loadMap();
        this.loadMapSanitaPerAzienda(parseInt(localStorage.getItem("idAzienda")));
        this.loadLast5PraticheSanitaPerAzienda();
      }else{
        this.loadAdminSanitaStats();
        this.loadLast5PraticheSanita();
        this.loadMapSanita();
      }
     
      
     
    }else {
      this.cleanMarkers();
      if(this.isAzienda){
        this.loadMapPerAzienda(this.userService.getUser().azienda.id);
      }else{
        this.loadMap();
      }
      
      
    }
  
}

initCSVBox(){
  this.csvbox_importer_aggiornaPratiche = new CSVBoxImporter(this.csvbox_licenseKey_aggiornaPratiche, {
  }, (result: boolean, data: any) => {
    if (result) {
      this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche AGGIORNATE con successo!', life:10000});
    } else {
      this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di AGGIORNAMENTO delle pratiche', life:10000});
    }
  });

  this.csvbox_importer_aggiornaPratiche.setUser({
    user_id: "default123"
  });
  this.csvbox_importer_aggiornaPratiche.language="it";

  this.csvbox_importer_aggiornaPraticheSanita = new CSVBoxImporter(this.csvbox_licenseKey_aggiornaPraticheSanita, {
    // Configuration options
  }, (result: boolean, data: any) => {
    if (result) {
      this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche AGGIORNATE  con successo!', life:10000});
    } else {
      this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di MODIFICA delle pratiche', life:10000});
      // Custom code
    }
  });

  this.csvbox_importer_aggiornaPraticheSanita.setUser({
    user_id: "default123"
  });
  this.csvbox_importer_aggiornaPraticheSanita.language="it"; 

  this.csvbox_importer_aggiornaPraticheBando1 = new CSVBoxImporter(this.csvbox_licenseKey_aggiornaPraticheBando1, {
  }, (result: boolean, data: any) => {
    if (result) {
      this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche AGGIORNATE con successo!', life:10000});
    } else {
      this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di AGGIORNAMENTO delle pratiche', life:10000});
    }
  });

  this.csvbox_importer_aggiornaPraticheBando1.setUser({
    user_id: "default123"
  });
  this.csvbox_importer_aggiornaPraticheBando1.language="it";

}

loadLast5PraticheSanita(){
  this.firestore.collection(this.collection_pratiche_sanita, (ref)=> ref.orderBy('dataUltimaModifica', 'desc').limit(5)).valueChanges().subscribe((data) => {
    this.lastPraticheSanita = data as PraticaScuola_Firestore[];
  });
  
}

loadLast5PraticheSanitaPerAzienda(){
  this.firestore.collection(this.collection_pratiche_sanita, (ref)=> 
    ref
    .where(
      "idAzienda",
      "==",
      Number.parseInt(localStorage.getItem("idAzienda"))
    )
    .orderBy('dataUltimaModifica', 'desc')
    .limit(5)).valueChanges().subscribe((data) => {

    this.lastPraticheSanitaPerAzienda = data as PraticaScuola_Firestore[];
  });
  
}



getStatoClass(status: string): string {
  if (status === "CARICATA") {
    return "stato-pratica-caricata";
  }else if (status === "ASSEGNATA") {
    return "stato-pratica-assegnata";
  } else if (status === "IN PROGETTAZIONE") {
    return "stato-pratica-inprogettazione";
  } else if (status === "IN CARICO IMPRESA") {
    return "stato-pratica-incarico";
  } else if (status === "CONSEGNATA") {
    return "stato-pratica-consegnata";
  } else if (status === "IN REWORK AZIENDA") {
    return "stato-pratica-inrework";
  }else if (status === "IN VERIFICA SMARTFIBER") {
    return "stato-pratica-inrework-presidio";
  }else if (status === "IN FIRMA") {
    return "stato-pratica-inFirma";
  }else if (status === "IN CARICO PRESIDIO") {
    return "stato-pratica-incarico-tim";
  }else if (status === "APPROVATA") {
    return "stato-pratica-approvata";
  }else if (status === "RESPINTA") {
    return "stato-pratica-respinta";
  }else if (status === "ANNULLATA") {
    return "stato-pratica-annullata";
  }
  return "";
}

openStatisticheSanita(){

}


  openStatisticheScuola(bando:String){
    if(bando==="1"){
      this.ref = this.dialogService.open(StatisticheScuola, {
        data: {
          bando: 1,
     },
       header: 'Statistiche Scuole Bando '+bando,
       width: '90%',
       height: '75%',
      baseZIndex: 10000,
       closable:true,
       draggable:false,
   });
    }else{
      this.ref = this.dialogService.open(StatisticheScuola, {
        data: {
          bando: 2,
     },
       header: 'Statistiche Scuole Bando '+bando,
       width: '90%',
       height: '75%',
      baseZIndex: 10000,
       closable:true,
       draggable:false,
   });
    }

  }
  ngAfterViewInit() {
    this.initializeMap();
  }

  initializeMap(){
   // this.logger.info("--INIZIALIZZAZIONE MAPPA---");
    /* if(this.map==null){
      this.logger.info("--> MAPPA nulla");

      const mapElement = document.getElementById('mappaPratiche');
      this.logger.info("--> mapElement");
      this.logger.info(mapElement);

      this.map = Leaflet.map(mapElement).setView([51.505, -0.09], 13);

      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map); 

    }else{
      this.logger.info("--> MAPPA OK");
    } */
  }
  cleanMarkers(){

     this.markers.forEach(marker => {
      if(marker && marker !== undefined){
        this.markerClusterGroup.removeLayer(marker);
      }
      
    }); 
    this.markers = [];  
  }

  openPagina(){
    this.logger.info("--- openPagina ---");
  }
  openPraticaSanita(pratica:PraticaSanita_Firestore){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-sanita/pratica/'+pratica.codiceInfratel]));
  }
  
  addSanitaMarker(pratica:PraticaSanita_Firestore) {    
    //this.logger.info("-> addSanitaMarker per pratica "+ pratica.codiceInfratel + " > " + pratica.coordinateAccesso.latitude + ","+pratica.coordinateAccesso.longitude);
     const sanitaMarkers = [
      {
        position: {
          lat: pratica.coordinateAccesso.latitude.valueOf(),
          lng: pratica.coordinateAccesso.longitude.valueOf(),
        },
        draggable: false
     
      },
    ]; 
    const data    = sanitaMarkers[0];
    
    let urlBando = "pratiche-sanita";
    let marker:any;    
      if(!pratica.colocata){

        marker  = this.generateSanitaMarker(data, 0, false);
        let linkMsg=!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":"";

        marker.bindPopup(
          "Accesso: <b> "+ pratica.denominazione + "</b><br>" +
          "Comune: <b> "+pratica.comune + "</b><br>"+
          "Indirizzo: <b> "+pratica.indirizzo + "</b><br>"+
          "Stato: <b>"+pratica.stato.toUpperCase()+ "</b><br>"+
          "Tipologia: <b>SINGOLA</b><br>"+
          linkMsg
         // `<button (click)='${this.openPagina()}>VAI ALLA PAGINA</button>`
          
          );
         //
        this.markerClusterGroup.addLayer(marker);
      }else {
        //this.logger.info("-> addSanitaMarker per pratica COLOCATA");
        /* if(pratica.colocataRuolo === 'PRINCIPALE'){
          marker  = this.generateMarker(data, 0, isBando1, true);
          let linkMsg=!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":"";
      
          marker.bindPopup(
            "Scuola: <b> "+ pratica.denominazione + "</b><br>" +
            "Comune: <b> "+pratica.comune + "</b><br>"+
            "Indirizzo: <b> "+pratica.indirizzo + "</b><br>"+
            "Stato: <b>"+pratica.stato.toUpperCase()+ "</b><br>"+
            "Tipologia: <b>COLOCATA</b><br>"+
            "Bando: <b>2</b><br>"+
            linkMsg
            //`<button (click)=${this.openPagina()}>VAI ALLA PAGINA</button>`
           );
          this.markerClusterGroup.addLayer(marker);
        }
      } */
    }
     
    this.markers.push(marker);  
 
    
  }

  addScuolaMarker(pratica:PraticaScuola_Firestore) {    
    if(pratica.coordinateScuola){
      const scuolaMarkers = [
        {
          position: {
            lat: pratica.coordinateScuola.latitude.valueOf(),
            lng: pratica.coordinateScuola.longitude.valueOf(),
          },
          draggable: false
       
        },
      ]; 
      const data    = scuolaMarkers[0];
      
      let urlBando = "pratiche-scuola-bando1";
      let isBando1:boolean = true;
      let marker:any;
      if(pratica.bando ==='BANDO 2'){
        urlBando = "pratiche-scuola-bando2";
        isBando1 = false;
        
        if(!pratica.colocata){
          marker  = this.generateScuolaMarker(data, 0, isBando1, false);
          let linkMsg=!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":"";
  
          marker.bindPopup(
            "Scuola: <b> "+ pratica.denominazione + "</b><br>" +
            "Comune: <b> "+pratica.comune + "</b><br>"+
            "Indirizzo: <b> "+pratica.indirizzo + "</b><br>"+
            "Stato: <b>"+pratica.stato.toUpperCase()+ "</b><br>"+
            "Tipologia: <b>SINGOLA</b><br>"+
            "Bando: <b>2</b><br>"+
            linkMsg
           // `<button (click)='${this.openPagina()}>VAI ALLA PAGINA</button>`
            
            );
           //
          this.markerClusterGroup.addLayer(marker);
        }else {
  
          if(pratica.colocataRuolo === 'PRINCIPALE'){
            marker  = this.generateScuolaMarker(data, 0, isBando1, true);
            let linkMsg=!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":"";
        
            marker.bindPopup(
              "Scuola: <b> "+ pratica.denominazione + "</b><br>" +
              "Comune: <b> "+pratica.comune + "</b><br>"+
              "Indirizzo: <b> "+pratica.indirizzo + "</b><br>"+
              "Stato: <b>"+pratica.stato.toUpperCase()+ "</b><br>"+
              "Tipologia: <b>COLOCATA</b><br>"+
              "Bando: <b>2</b><br>"+
              linkMsg
              //`<button (click)=${this.openPagina()}>VAI ALLA PAGINA</button>`
             );
            this.markerClusterGroup.addLayer(marker);
          }
        }
      }else{
        marker  = this.generateScuolaMarker(data, 0, isBando1, false);
        let linkMsg=!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":"";
        marker.bindPopup(
          "Scuola: <b> "+ pratica.denominazione + "</b><br>" +
          "Comune: <b> "+pratica.comune + "</b><br>"+
          "Indirizzo: <b> "+pratica.indirizzo + "</b><br>"+
          "Stato: <b>"+pratica.stato.toUpperCase()+ "</b><br>"+
          "Tipologia: <b>SINGOLA</b><br>"+
          "Bando: <b>1</b><br>"+
          linkMsg 
          //!this.isDirigenteFirmaPacchetti&&!this.isDirigenteFirmaVerbali?"<a href='/"+urlBando+"/pratica/"+pratica.codiceInfratel+"'>APRI DETTAGLI</a>":""
          
          );
         this.markerClusterGroup.addLayer(marker);
      }
       
      this.markers.push(marker);  
    }
    

    
  }

  onMarkerClick(pratica:PraticaScuola_Firestore):any{
    return `<b></b>`
  }

  generateSanitaMarker(data: any, index: number, isColocata:boolean) {
 
    let markerIconSanita = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-sanita.png',
      iconSize: [30, 49],
    });
  
    let markerIconSanitaColocata = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-sanita-colocata.png',
      iconSize: [30, 41],
    });
    if(!isColocata){
      return Leaflet.marker(
        data.position, { 
          draggable: data.draggable ,
          icon: markerIconSanita
        } )
        .on("click", (event) => this.markerClicked(event, index))
        .on("dragend", (event) => this.markerDragEnd(event, index));
    }
    return Leaflet.marker(
      data.position, { 
        draggable: data.draggable ,
        icon: markerIconSanitaColocata
      } )
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
   
  }

  generateScuolaMarker(data: any, index: number, isBando1:boolean, isColocata:boolean) {
    let markerIconBando1 = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-bando1.png',
      iconSize: [30, 41],
    });
    let markerIconBando2 = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-bando2.png',
      iconSize: [30, 41],
    });

    let markerIconColocata = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-bando2-colocata.png',
      iconSize: [30, 41],
    });
    if(isBando1){
      return Leaflet.marker(
        data.position, { 
          draggable: data.draggable ,
          icon: markerIconBando1
        } )
        .on("click", (event) => this.markerClicked(event, index))
        .on("dragend", (event) => this.markerDragEnd(event, index));
    }else if(!isColocata){
      return Leaflet.marker(
        data.position, { 
          draggable: data.draggable ,
          icon: markerIconBando2
        } )
        .on("click", (event) => this.markerClicked(event, index))
        .on("dragend", (event) => this.markerDragEnd(event, index));
    }
    return Leaflet.marker(
      data.position, { 
        draggable: data.draggable ,
        icon: markerIconColocata
      } )
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
   
  }
  markerClicked($event: any, index: number) {
    //this.logger.info("---> markerClicked");
  }

  markerDragEnd($event: any, index: number) {}
  onMapReady($event: Leaflet.Map) {
    //this.logger.info("*** on map ready @ home");
    this.map = $event;
  }

  mapClicked(event: any) {
  }

 
  loadStatsBando1PerAzienda(idAzienda:string ){
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      this.stats.numeroPraticheScuolaAssegnate          = 0;
      this.stats.numeroPraticheScuolaInCarico           = 0;
      this.stats.numeroPraticheScuolaInProgettazione    = 0;
      this.stats.numeroPraticheScuolaConsegnate         = 0;
      this.stats.numeroPraticheScuolaInRework           = 0;
      this.stats.numeroTotalePraticheScuolaBando1       = 0;
      const queryBando1 = collectionRef
      .where( "idAzienda", "==",  Number.parseInt(idAzienda))
      .where("bando", "==", "BANDO 1");
      onSnapshot(queryBando1, (qs) => {
        qs.forEach((doc) => {
          

          let praticaDaFirestore: PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
          if (praticaDaFirestore.stato==='ASSEGNATA' ) {
            this.stats.numeroPraticheScuolaAssegnate          += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
            this.stats.numeroPraticheScuolaInCarico           += 1;
          }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
            this.stats.numeroPraticheScuolaInProgettazione    += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA' 
              || praticaDaFirestore.stato==='IN VERIFICA SMARTFIBER' 
              || praticaDaFirestore.stato==='IN CARICO PRESIDIO'
              || praticaDaFirestore.stato==='IN FIRMA VERBALE'
              || praticaDaFirestore.stato==='IN FIRMA PACCHETTO'
              || praticaDaFirestore.stato==='VERBALE FIRMATO'
              || praticaDaFirestore.stato==='FIRMATA'
              || praticaDaFirestore.stato==='PUBBLICATA'
              || praticaDaFirestore.stato==='APPROVATA'
              || praticaDaFirestore.stato==='RESPINTA'
             
            ) {
            this.stats.numeroPraticheScuolaConsegnate         += 1;
          }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheScuolaInRework           += 1;
          }
          this.stats.numeroTotalePraticheScuolaBando1       += 1;
        });

      });
  }
  
  loadStatsBando2PerAzienda(idAzienda:string ){
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    this.stats.numeroPraticheScuolaBando2Assegnate          = 0;
    this.stats.numeroPraticheScuolaBando2InCarico           = 0;
    this.stats.numeroPraticheScuolaBando2InProgettazione    = 0;
    this.stats.numeroPraticheScuolaBando2Consegnate         = 0;
    this.stats.numeroPraticheScuolaBando2InRework           = 0;
    this.stats.numeroTotalePraticheScuolaBando2       = 0;
    const queryBando2 = collectionRef
    .where( "idAzienda", "==",  Number.parseInt(idAzienda))
    .where("bando", "==", "BANDO 2")
    onSnapshot(queryBando2, (qs) => {

      qs.forEach((doc) => {
        let praticaDaFirestore: PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
        if (praticaDaFirestore.stato==='ASSEGNATA' ) {
          this.stats.numeroPraticheScuolaBando2Assegnate        += 1;
          //this.logger.info("---> pratica assegnata ad azienda "+ praticaDaFirestore.codiceInfratel);
        }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
          this.stats.numeroPraticheScuolaBando2InCarico         += 1;
        }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
          this.stats.numeroPraticheScuolaBando2InProgettazione   += 1;
        }else if (praticaDaFirestore.stato==='CONSEGNATA' 
                  || praticaDaFirestore.stato==='IN VERIFICA SMARTFIBER' 
                  || praticaDaFirestore.stato==='IN REWORK SMARTFIBER' 
                  || praticaDaFirestore.stato==='IN CARICO PRESIDIO' 
                  || praticaDaFirestore.stato==='IN FIRMA VERBALE' 
                  || praticaDaFirestore.stato==='IN FIRMA PACCHETTO'
                  || praticaDaFirestore.stato==='VERBALE FIRMATO' 
                  || praticaDaFirestore.stato==='FIRMATA' 
                  || praticaDaFirestore.stato==='PUBBLICATA'
                  || praticaDaFirestore.stato==='APPROVATA'
                  || praticaDaFirestore.stato==='RESPINTA'
                ) {
          this.stats.numeroPraticheScuolaBando2Consegnate   += 1;
        }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
          this.stats.numeroPraticheScuolaBando2InRework           += 1;
        }
        this.stats.numeroTotalePraticheScuolaBando2       += 1;
      });
      
    });
  }
  loadStatsSanitaPerAzienda(idAzienda:string ){
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      this.stats.numeroPraticheSanitaAssegnate          = 0;
      this.stats.numeroPraticheSanitaInCarico           = 0;
      this.stats.numeroPraticheSanitaInProgettazione    = 0;
      this.stats.numeroPraticheSanitaConsegnate         = 0;
      this.stats.numeroPraticheSanitaInRework           = 0;
      const queryBando1 = collectionRef
      .where( "idAzienda", "==",  Number.parseInt(idAzienda))
      onSnapshot(queryBando1, (qs) => {
        qs.forEach((doc) => {
          

          let praticaDaFirestore: PraticaSanita_Firestore = doc.data() as PraticaSanita_Firestore;
          if (praticaDaFirestore.stato==='ASSEGNATA' ) {
            this.stats.numeroPraticheSanitaAssegnate          += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
            this.stats.numeroPraticheSanitaInCarico           += 1;
          }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
            this.stats.numeroPraticheSanitaInProgettazione    += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA') {
            this.stats.numeroPraticheSanitaConsegnate         += 1;
          }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheSanitaInRework           += 1;
          }
        });

      });
  }
  loadStatsPerAzienda(idAzienda:string){
    console.log(' *** loadStatsPerAzienda su collection '+this.collection_pratiche_scuola);
    if(this.hasBando2){
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      this.stats.numeroPraticheScuolaBando2Assegnate          = 0;
      this.stats.numeroPraticheScuolaBando2InCarico           = 0;
      this.stats.numeroPraticheScuolaBando2InProgettazione    = 0;
      this.stats.numeroPraticheScuolaBando2Consegnate         = 0;
      this.stats.numeroPraticheScuolaBando2InRework           = 0;
      this.stats.numeroTotalePraticheScuolaBando2       = 0;

      const queryBando2 = collectionRef
      .where( "idAzienda", "==",  Number.parseInt(idAzienda))
      .where("bando", "==", "BANDO 2")
      onSnapshot(queryBando2, (qs) => {

        qs.forEach((doc) => {
          let praticaDaFirestore: PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
          if (praticaDaFirestore.stato==='ASSEGNATA' ) {
            
            this.stats.numeroPraticheScuolaBando2Assegnate        += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
            this.stats.numeroPraticheScuolaBando2InCarico         += 1;
          }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
            this.stats.numeroPraticheScuolaBando2InProgettazione   += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA') {
            this.stats.numeroPraticheScuolaBando2Consegnate   += 1;
          }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheScuolaBando2InRework           += 1;
          }
          this.stats.numeroTotalePraticheScuolaBando2       += 1;
        });
        
       
        
      });
    }

    if(this.hasBando1){
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      this.stats.numeroPraticheScuolaAssegnate          = 0;
      this.stats.numeroPraticheScuolaInCarico           = 0;
      this.stats.numeroPraticheScuolaInProgettazione    = 0;
      this.stats.numeroPraticheScuolaConsegnate         = 0;
      this.stats.numeroPraticheScuolaInRework           = 0;
      this.stats.numeroTotalePraticheScuolaBando1       = 0;
      const queryBando1 = collectionRef
      .where( "idAzienda", "==",  Number.parseInt(idAzienda))
      .where("bando", "==", "BANDO 1");
      onSnapshot(queryBando1, (qs) => {
        qs.forEach((doc) => {
          

          let praticaDaFirestore: PraticaScuola_Firestore = doc.data() as PraticaScuola_Firestore;
          if (praticaDaFirestore.stato==='ASSEGNATA' ) {
            this.stats.numeroPraticheScuolaAssegnate          += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
            this.stats.numeroPraticheScuolaInCarico           += 1;
          }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
            this.stats.numeroPraticheScuolaInProgettazione    += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA') {
            this.stats.numeroPraticheScuolaConsegnate         += 1;
          }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheScuolaInRework           += 1;
          }
          this.stats.numeroTotalePraticheScuolaBando1       += 1;
        });

      });
    }

    //this.logger.info(">>>> loadStatsPerAzienda "+ this.hasSanita);
    if(this.hasSanita){
      const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      this.stats.numeroPraticheSanitaAssegnate          = 0;
      this.stats.numeroPraticheSanitaInCarico           = 0;
      this.stats.numeroPraticheSanitaInProgettazione    = 0;
      this.stats.numeroPraticheSanitaConsegnate         = 0;
      this.stats.numeroPraticheSanitaInRework           = 0;
      const queryBando1 = collectionRef
      .where( "idAzienda", "==",  Number.parseInt(idAzienda))
      onSnapshot(queryBando1, (qs) => {
        qs.forEach((doc) => {
          

          let praticaDaFirestore: PraticaSanita_Firestore = doc.data() as PraticaSanita_Firestore;
          if (praticaDaFirestore.stato==='ASSEGNATA' ) {
            this.stats.numeroPraticheSanitaAssegnate          += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO IMPRESA' ) {
            this.stats.numeroPraticheSanitaInCarico           += 1;
          }else if (praticaDaFirestore.stato==='IN PROGETTAZIONE') {
            this.stats.numeroPraticheSanitaInProgettazione    += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA') {
            this.stats.numeroPraticheSanitaConsegnate         += 1;
          }else if (praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheSanitaInRework           += 1;
          }
        });

      });
    }
  
  }

  loadMapPerAzienda(idAzienda:Number){
   // this.initializeMap();
   this.firestore.collection(this.collection_pratiche_scuola, ref =>
      ref.where('idAzienda', '==', Number.parseInt(idAzienda.toString()))
    ).valueChanges().subscribe(pratiche => {
      this.cleanMarkers();
        pratiche.forEach(pratica=>{

          this.addScuolaMarker(pratica as PraticaScuola_Firestore);
        }); 
        this.scuoleLoaded = true;
        this.map.addLayer(this.markerClusterGroup);
      }
    );
  }

  openAggiornaPraticheBando1(){
    this.csvbox_importer_aggiornaPraticheBando1.openModal();
  }
  openAggiornaPraticheBando2(){
    this.csvbox_importer_aggiornaPratiche.openModal();
  }

  openAggiornaPraticheSanita(){
    this.csvbox_importer_aggiornaPraticheSanita.openModal();
  }

  loadMap(){
   // this.initializeMap();
    this.firestore.collection(this.collection_pratiche_scuola).valueChanges().subscribe((data) => {
      if (data) {
        this.cleanMarkers();
         data.forEach(pratica=>{
          this.addScuolaMarker(pratica as PraticaScuola_Firestore);
        }); 
        this.scuoleLoaded = true;
        if(this.map){
          this.map.addLayer(this.markerClusterGroup);
        }
       

      }
    });
  }

  loadMapSanitaPerAzienda(idAzienda:Number){
      // this.initializeMap();
      this.firestore.collection(this.collection_pratiche_sanita, ref =>
         ref.where('idAzienda', '==', Number.parseInt(idAzienda.toString()))
       ).valueChanges().subscribe(pratiche => {
         this.cleanMarkers();
           pratiche.forEach(pratica=>{
   
             this.addSanitaMarker(pratica as PraticaSanita_Firestore);
           }); 
           this.scuoleLoaded = true;
           this.map.addLayer(this.markerClusterGroup);
         }
       );
     }
  

  loadMapSanita(){
    this.logger.info("*** LOAD MAP SANITA *****");
    //this.cleanMarkers();
    this.firestore.collection(this.collection_pratiche_sanita).valueChanges().subscribe((data) => {
      if (data) {
        this.cleanMarkers();
         data.forEach(pratica=>{
          this.addSanitaMarker(pratica as PraticaSanita_Firestore);
        }); 
        this.sanitaLoaded = true;
        if(this.map){
          this.map.addLayer(this.markerClusterGroup);
        }
        

      }
    });
   // this.initializeMap();
    /* this.firestore.collection(this.collection_pratiche_scuola).valueChanges().subscribe((data) => {
      if (data) {
        this.logger.info("---LOADING MAP OK!");
         data.forEach(pratica=>{
          this.addMarkers(pratica as PraticaScuola_Firestore);
        }); 
        this.scuoleLoaded = true;

      }
    }); */
  }

  loadAdminSanitaStats(){
    this.firestore.collection(this.collection_pratiche_sanita).valueChanges().subscribe((data) => {
      if (data) {
        this.stats.numeroPraticheSanitaValidate                 = 0;
        this.stats.numeroPraticheSanitaRespinte                 = 0;
        this.stats.numeroPraticheSanitaDaAssegnare              = 0;
        this.stats.numeroPraticheSanitaInCaricoSF               = 0;
        this.stats.numeroPraticheSanitaInCaricoPresidio         = 0;
        this.stats.numeroPraticheSanitaInLavorazione            = 0;
        this.stats.numeroPraticheSanitaInFirma                  = 0;
        this.stats.numeroPraticheSanitaInFirmaVerbale           = 0;
        this.stats.numeroPraticheSanitaInFirmaPacchetto         = 0;
        this.stats.numeroPraticheSanitaVerbaleFirmato           = 0;
        this.stats.numeroPraticheSanitaPacchettoFirmato         = 0;
        this.stats.numeroPraticheSanitaPubblicate               = 0;
        data.forEach(pratica =>{
          let praticaDaFirestore: PraticaSanita_Firestore = pratica as PraticaSanita_Firestore;
          if(praticaDaFirestore.stato==='CARICATA'){
            this.stats.numeroPraticheSanitaDaAssegnare       += 1;
          }else if (praticaDaFirestore.stato==='ASSEGNATA' || praticaDaFirestore.stato==='IN PROGETTAZIONE' || praticaDaFirestore.stato==='IN CARICO IMPRESA' || praticaDaFirestore.stato==='IN REWORK AZIENDA') {
            this.stats.numeroPraticheSanitaInLavorazione      += 1;
          }else if (praticaDaFirestore.stato==='CONSEGNATA' || praticaDaFirestore.stato==='IN VERIFICA SMARTFIBER' ||  praticaDaFirestore.stato==='IN REWORK SMARTFIBER' ) {
            this.stats.numeroPraticheSanitaInCaricoSF         += 1;
          }else if (praticaDaFirestore.stato==='IN CARICO PRESIDIO') {
            this.stats.numeroPraticheSanitaInCaricoPresidio   += 1;
          }else if (praticaDaFirestore.stato==='APPROVATA') {
            this.stats.numeroPraticheSanitaValidate           += 1;
          }else if (praticaDaFirestore.stato==='RESPINTA') {
            this.stats.numeroPraticheSanitaRespinte           += 1;
          }else if (praticaDaFirestore.stato==='IN FIRMA VERBALE') {
            this.stats.numeroPraticheSanitaInFirmaVerbale     += 1;
            this.stats.numeroPraticheSanitaInFirma            += 1;
          }else if (praticaDaFirestore.stato==='IN FIRMA PACCHETTO') {
            this.stats.numeroPraticheSanitaInFirmaPacchetto     += 1;
            this.stats.numeroPraticheSanitaInFirma              += 1;
          }else if (praticaDaFirestore.stato==='VERBALE FIRMATO') {
            this.stats.numeroPraticheSanitaVerbaleFirmato     += 1;
            this.stats.numeroPraticheSanitaInFirma            += 1;
          }else if (praticaDaFirestore.stato==='FIRMATA') {
            this.stats.numeroPraticheSanitaPacchettoFirmato     += 1;
            this.stats.numeroPraticheSanitaInCaricoPresidio   += 1;
          }else if (praticaDaFirestore.stato==='PUBBLICATA') {
            this.stats.numeroPraticheSanitaPubblicate   += 1;
          }
        });
      }else{
        this.logger.info("---> NO DATA");
      }

    });
  }
  loadAdminStats(){
    this.logger.info("*** LOAD ADMIN STATS *****");
    this.firestore.collection(this.collection_pratiche_scuola).valueChanges().subscribe((data) => {
      if (data) {
        this.stats.numeroTotalePraticheScuolaBando1             = 0;
        this.stats.numeroPraticheScuolaValidate                 = 0;
        this.stats.numeroPraticheScuolaRespinte                 = 0;
        this.stats.numeroPraticheScuolaDaAssegnare              = 0;
        this.stats.numeroPraticheScuolaInCaricoSF               = 0;
        this.stats.numeroPraticheScuolaInCaricoPresidio         = 0;
        this.stats.numeroPraticheScuolaInLavorazione            = 0;
        this.stats.numeroPraticheScuolaInFirma                  = 0;
        this.stats.numeroPraticheScuolaInFirmaVerbale           = 0;
        this.stats.numeroPraticheScuolaInFirmaPacchetto         = 0;
        this.stats.numeroPraticheScuolaVerbaleFirmato           = 0;
        this.stats.numeroPraticheScuolaPacchettoFirmato         = 0;
        this.stats.numeroPraticheScuolaPubblicate               = 0;

        this.stats.numeroTotalePraticheScuolaBando2             = 0;
        this.stats.numeroPraticheScuolaBando2PacchettoFirmato   = 0;
        this.stats.numeroPraticheScuolaBando2InFirma            = 0;
        this.stats.numeroPraticheScuolaBando2Validate           = 0;
        this.stats.numeroPraticheScuolaBando2Respinte           = 0;
        this.stats.numeroPraticheScuolaBando2DaAssegnare        = 0;
        this.stats.numeroPraticheScuolaBando2InCaricoSF         = 0;
        this.stats.numeroPraticheScuolaBando2InCaricoPresidio   = 0;
        this.stats.numeroPraticheScuolaBando2InLavorazione      = 0;
        this.stats.numeroPraticheScuolaBando2VerbaleFirmato     = 0;
        this.stats.numeroPraticheScuolaBando2InFirmaPacchetto   = 0;
        this.stats.numeroPraticheScuolaBando2InFirmaVerbale     = 0;
        this.stats.numeroPraticheScuolaBando2Pubblicate         = 0;
      //  this.logger.info("-> INIZIO "+ this.stats.numeroPraticheScuolaBando2InCaricoSF );
        data.forEach(pratica =>{
          let praticaDaFirestore: PraticaScuola_Firestore = pratica as PraticaScuola_Firestore;

          if(praticaDaFirestore.bando ==='BANDO 1'){  
            if(praticaDaFirestore.stato==='CARICATA'){
              this.stats.numeroPraticheScuolaDaAssegnare       += 1;
            }else if (praticaDaFirestore.stato==='ASSEGNATA' || praticaDaFirestore.stato==='IN PROGETTAZIONE' || praticaDaFirestore.stato==='IN CARICO IMPRESA' || praticaDaFirestore.stato==='IN REWORK AZIENDA') {
              this.stats.numeroPraticheScuolaInLavorazione      += 1;
            }else if (praticaDaFirestore.stato==='CONSEGNATA' || praticaDaFirestore.stato==='IN VERIFICA SMARTFIBER' ||  praticaDaFirestore.stato==='IN REWORK SMARTFIBER' ) {
              this.stats.numeroPraticheScuolaInCaricoSF         += 1;
            }else if (praticaDaFirestore.stato==='IN CARICO PRESIDIO') {
              this.stats.numeroPraticheScuolaInCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='APPROVATA') {
              this.stats.numeroPraticheScuolaValidate           += 1;
            }else if (praticaDaFirestore.stato==='RESPINTA') {
              this.stats.numeroPraticheScuolaRespinte           += 1;
            }else if (praticaDaFirestore.stato==='IN FIRMA VERBALE') {
             // this.logger.info("UNA PRATICA IN FIRMA VERBALE");
              this.stats.numeroPraticheScuolaInFirmaVerbale     += 1;
              this.stats.numeroPraticheScuolaInFirma            += 1;
            }else if (praticaDaFirestore.stato==='IN FIRMA PACCHETTO') {
             // this.logger.info("UNA PRATICA IN FIRMA PACCHETTO");
              this.stats.numeroPraticheScuolaInFirmaPacchetto     += 1;
              this.stats.numeroPraticheScuolaInFirma              += 1;
            }else if (praticaDaFirestore.stato==='VERBALE FIRMATO') {
             // this.logger.info("UNA PRATICA VERBALE FIRMATO");
              this.stats.numeroPraticheScuolaVerbaleFirmato     += 1;
              this.stats.numeroPraticheScuolaInFirma            += 1;
              this.stats.numeroPraticheScuolaInFirmaPacchetto     += 1;
            }else if (praticaDaFirestore.stato==='FIRMATA') {
              this.stats.numeroPraticheScuolaPacchettoFirmato     += 1;
              this.stats.numeroPraticheScuolaInCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='PUBBLICATA') {
              this.stats.numeroPraticheScuolaPubblicate   += 1;
            }
            this.stats.numeroTotalePraticheScuolaBando1        += 1;
          }else{
            if(praticaDaFirestore.stato==='CARICATA'){

              this.stats.numeroPraticheScuolaBando2DaAssegnare   += 1;
            }else if (praticaDaFirestore.stato==='ASSEGNATA' || praticaDaFirestore.stato==='IN PROGETTAZIONE' || praticaDaFirestore.stato==='IN CARICO IMPRESA' || praticaDaFirestore.stato==='IN REWORK AZIENDA') {
              //this.logger.info("---> pratica in lavorazione "+ praticaDaFirestore.codiceInfratel +  "("+ this.stats.numeroPraticheScuolaBando2InLavorazione +")");
              if(praticaDaFirestore.codiceInfratel!=null && praticaDaFirestore.codiceInfratel!=undefined){
                this.stats.numeroPraticheScuolaBando2InLavorazione   += 1;
              }
              
            }else if (praticaDaFirestore.stato==='CONSEGNATA' || praticaDaFirestore.stato==='IN VERIFICA SMARTFIBER' ||  praticaDaFirestore.stato==='IN REWORK SMARTFIBER' ) {
              this.stats.numeroPraticheScuolaBando2InCaricoSF +=1;
              
            }else if (praticaDaFirestore.stato==='IN CARICO PRESIDIO') {
              this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='APPROVATA') {
              this.stats.numeroPraticheScuolaBando2Validate           += 1;
            }else if (praticaDaFirestore.stato==='RESPINTA') {
              this.stats.numeroPraticheScuolaBando2Respinte           += 1;
            }else if (praticaDaFirestore.stato==='IN FIRMA VERBALE') {
              this.stats.numeroPraticheScuolaBando2InFirmaVerbale     += 1;
              this.stats.numeroPraticheScuolaBando2InFirma            += 1;
              //this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='IN FIRMA PACCHETTO') {
              this.stats.numeroPraticheScuolaBando2InFirmaPacchetto     += 1;
              this.stats.numeroPraticheScuolaBando2InFirma            += 1;
             // this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='VERBALE FIRMATO') {
              
              
              this.stats.numeroPraticheScuolaBando2VerbaleFirmato     += 1;
              this.stats.numeroPraticheScuolaBando2InFirmaPacchetto     += 1;
              this.stats.numeroPraticheScuolaBando2InFirma            += 1;
            //  this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='FIRMATA') {
              this.stats.numeroPraticheScuolaBando2PacchettoFirmato     += 1;
              this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
             // this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }else if (praticaDaFirestore.stato==='PUBBLICATA') {
              this.stats.numeroPraticheScuolaBando2Pubblicate   += 1;
              this.stats.numeroPraticheScuolaBando2InCaricoPresidio   += 1;
            }
            this.stats.numeroTotalePraticheScuolaBando2        += 1;
          }
        });
      }else{
        this.logger.info("---> NO DATA");
      }
     // this.logger.info("-> FINE "+ this.stats.numeroPraticheScuolaBando2InCaricoSF );
    });
  }

  loadStats(){
    /* this.firestore.collection(environment.firebase.collection_stats).valueChanges().subscribe((data) => {
      if (data) {
        this.stats.numeroPraticheSanitaAssegnate  = 0;
        this.stats.numeroPraticheSanitaConsegnate = 0;
        this.stats.numeroPraticheSanitaInProgettazione = 0;
        this.stats.numeroPraticheSanitaInCarico = 0;
        this.stats.numeroPraticheSanitaInRework = 0;

        this.stats.numeroPraticheScuolaAssegnate        = 0;
        this.stats.numeroPraticheScuolaInProgettazione  = 0;
        this.stats.numeroPraticheScuolaConsegnate       = 0;
        this.stats.numeroPraticheScuolaInCarico         = 0;
        this.stats.numeroPraticheScuolaInRework         = 0;

        this.stats.numeroPraticheScuolaValidate         = 0;
        this.stats.numeroPraticheScuolaRespinte        = 0;
        this.stats.numeroPraticheScuolaDaAssegnare      = 0;
        this.stats.numeroPraticheScuolaInCaricoSF       = 0;
        this.stats.numeroPraticheScuolaInCaricoPresidio = 0;
        this.stats.numeroPraticheScuolaInLavorazione    = 0;

        this.stats.numeroPraticheScuolaBando2Validate           = 0;
        this.stats.numeroPraticheScuolaBando2Respinte           = 0;
        this.stats.numeroPraticheScuolaBando2DaAssegnare        = 0;
        this.stats.numeroPraticheScuolaBando2InCaricoSF        = 0;
        this.stats.numeroPraticheScuolaBando2InCaricoPresidio   = 0;
        this.stats.numeroPraticheScuolaBando2InLavorazione      = 0;


        data.forEach(stat =>{
          
          this.stats.numeroPraticheScuolaAssegnate += stat["numeroPraticheScuolaAssegnate"];
          if(stat["numeroPraticheScuolaInProgettazione"]){
            this.stats.numeroPraticheScuolaInProgettazione += stat["numeroPraticheScuolaInProgettazione"];
          }
          
          this.stats.numeroPraticheScuolaConsegnate += stat["numeroPraticheScuolaConsegnate"];
          this.stats.numeroPraticheScuolaInCarico += stat["numeroPraticheScuolaInCarico"];
          this.stats.numeroPraticheScuolaInRework += stat["numeroPraticheScuolaInRework"]; 
         
        });
        this.statsService.hideNotificationBadge = false;
        //this.notificaCambioDatiEvent.emit('I dati sono cambiati!');
        
       
      } else {
        this.logger.error('Le statistiche complessive non sono state trovate');
      }
    }); */
  
  }
 
  openImportBando2(){
    
  }
  openPraticheScuole(stato:string ){
    if(stato){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando2/stato/'+stato]));
    }else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando2']));
    }
  }

  openPraticheScuolaBando2(stato:string ){
    this.logger.info(">> openPraticheScuolaBando2 per stato " +stato);
    if(stato){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando2/stato/'+stato]));
    }else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando2']));

    }
  }
  openPraticheSanita(stato:string){
    if(stato){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-sanita/stato/'+stato]));
    }else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-sanita']));
    }
  }

  openPianiTecnici(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-sanita/piani-tecnici']));
  }

  openPraticheScuolaBando1(stato:string ){
    if(stato){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando1/stato/'+stato]));
    }else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['pratiche-scuola-bando1']));

    }
  }
 


  getUserInfo() {
   /*  this.dipendenteService
      .getByIdAuth0(localStorage.getItem("userId"))
      .subscribe((res) => {
        //  this.logger.info("--> OTTENGO IL DIPENDENTE DA DB: "+ res.ultimaRegistrazione);
        if (res.ultimaRegistrazione) {
          const formatter = new Intl.DateTimeFormat("it-IT", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          const datavecchia: Date = new Date("2023-06-10");
          this.lastRegistrazione = res.ultimaRegistrazione;
          var oggi: Date = new Date();
          var differenzaMillisecondi = Math.abs(
            this.lastRegistrazione.getTime() - oggi.getTime()
          );
          var differenzaGiorni = Math.ceil(
            differenzaMillisecondi / (1000 * 60 * 60 * 24)
          );

          this.numeroGiorniUltimaRegistrazione = differenzaGiorni;

          this.lastRegistrazioneFormattata = formatter.format(
            new Date(this.lastRegistrazione)
          );
        }
      }); */
  }

  goToRegistro() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/registro"]));
  }

  /* goToRegistroWithMeseDiRiferimento(meseRiferimento: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() =>
        this.router.navigate(["/registro/meseRiferimento=" + meseRiferimento])
      );
  }
  goToRegistroAttivita() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/registro-attivita"]));
  }
  goToDipendenti() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/dipendenti"]));
  }
  goToCommesse() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/commesse"]));
  }
  goToClienti() {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/clienti"]));
  } */

  viewBlocks(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  isAdministrator(): boolean {
    return (
      localStorage.getItem("ruolo") === "ADMIN" ||
      localStorage.getItem("ruolo") === "CONTABILE"
    );
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe((res) => {});
  }
}

@Component({
  selector: "app-statistiche-sanita",
  templateUrl: './statistiche-sanita.component.html',
  styleUrls: ['./home.component.css']
})
export class StatisticheSanita implements OnInit {

  protected _onDestroy = new Subject<void>();

  
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService
      ) {
     
  }

  ngOnInit(): void {
   
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

    }
  }
}

@Component({
  selector: "app-statistiche-scuola",
  templateUrl: './statistiche-scuola.component.html',
  styleUrls: ['./home.component.css']
})
export class StatisticheScuola implements OnInit {

  protected _onDestroy = new Subject<void>();

  
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService
      ) {
     
  }

  ngOnInit(): void {
   
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

    }
  }
}
