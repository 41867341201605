import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { NGXLogger } from "ngx-logger";
import { MenuItem, MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { StyleClassModule } from "primeng/styleclass";
import { Ripple } from "primeng/ripple";
import { UserService } from "src/app/services/user.service";
import { StatsService } from "src/app/services/notification.service";
import { SupportService } from "src/app/services/support.service";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";
interface Release {
  name: string;
  code: string;
}

@Component({
  selector: "app-nav-bar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavBarComponent implements OnInit {
  displayInfoModal: boolean = false;
  showConfigPanel: boolean = false;
  hideMenu: boolean = false;
  hideScuoleMenu: boolean = false;
  hidePraticheScuolaMenu: boolean = false;
  hideTipologieMenu: boolean = true;
  hidePianiTecniciMenu: boolean = true;
  showBadge = true;
  public isAdmin:boolean = false;
  public isSuperAdmin  :boolean = false;
  public isPresidioSF  :boolean = false;
  protected navbarChip: string = "";
  private hideChip:boolean = true;
  private chipColor:String = '';


  constructor(
    public auth: AuthService,
    private messageService: MessageService,
    public dialogService: DialogService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger,
    public userService: UserService,
    private statsService: StatsService,
    private supportService:SupportService,
  ) {}

  ngOnInit() {
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
      this.navbarChip = "AMMINISTRATORE";
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SUPERADMIN'){
      this.isSuperAdmin = true;
      this.navbarChip = "SUPERADMIN";
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='PRESIDIO SF'){
      this.navbarChip = "PRESIDIO SF";
      this.isPresidioSF = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SMARTFIBER'){
      this.navbarChip = "PRESIDIO SF";
      this.isPresidioSF = true;
    } else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='PRESIDIO TIM'){
      this.navbarChip = "PRESIDIO TIM";
    }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_VERBALI'){
      this.navbarChip = "DIRIGENTE VERBALI";
    }else if(localStorage.getItem("ruolo")==='DIRIGENTE_FIRMA_PACCHETTI'){
      this.navbarChip = "DIRIGENTE PACCHETTI";
    }else{
      this.navbarChip = localStorage.getItem("ragioneSocialeAzienda");
    }
    this.getChipContent();
  }

 
  async getChipContent(){
    this.supportService.waitForStorageValue("ruolo").then(ruolo => {
      if(ruolo==='ADMIN'){
        this.navbarChip = "AMMINISTRATORE";
        this.chipColor = '#6FB4FF';
        this.hideChip = false;
      }else if(ruolo==='SUPERADMIN'){
        this.navbarChip = "SUPERADMIN";
        this.chipColor = '#564D80';
        this.hideChip = false;
      }else if(ruolo==='SMARTFIBER'){
        this.navbarChip = "PRESIDIO SF";
        this.chipColor = '#ECC983';
        this.hideChip = false;
      }else if(ruolo==='PRESIDIO'){
        this.navbarChip = "PRESIDIO TIM";
        this.chipColor = '#005E7C';
        this.hideChip = false;
      }else if(ruolo==='DIRIGENTE_FIRMA_VERBALI' ){
        this.navbarChip = "DIRIGENTE FIRMA VERBALI";
        this.chipColor = '#478978';
        this.hideChip = false;
      }else if(ruolo==='DIRIGENTE_FIRMA_PACCHETTI' ){
        this.navbarChip = "DIRIGENTE FIRMA PACCHETTI";
        this.chipColor = '#1B5299';
        this.hideChip = false;
      }
      else{
        this.supportService.waitForStorageValue("ragioneSocialeAzienda").then(res => {
          this.navbarChip = res;
          this.chipColor = '#104547';
          this.hideChip = false;
        });
      }
      //this.navbarChip = res;
    });
   


   /*  if( this.navbarChip == null){
      this.logger.info("*** this.navbarChip  NULLO RICHIAMO waitForStorageValue ---> ");

      this.navbarChip = await this.supportService.waitForStorageValue("ragioneSocialeAzienda");
    } */
  }

  onNotificaCambioDatiEvent(data: string) {
    this.logger.info("onNotificaCambioDatiEvent " + data);

  }

  goToHome() {
    this.router.navigate(["/"]);
    this.closeMenu();
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe();
  }
  closeMenu() {
    this.hideMenu = true;
    this.hideTipologieMenu = true;
    this.hideScuoleMenu = true;
    this.hidePianiTecniciMenu = true;
    this.hidePraticheScuolaMenu = true;
  }

  openPraticheScuolaMenu() {
    this.hidePraticheScuolaMenu = false;
  }
  openMenu(){
    this.hideMenu = false;
  }

  openPianiTecniciMenu(){
    this.hidePianiTecniciMenu = false;
  }
  openRegistriMenu(){
    this.hideScuoleMenu = false;
  }
  openTipologieMenu(){
    this.hideTipologieMenu = false;
  }

  openProfileModule() {}


  openAnagraficaImprese() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/imprese']));
    this.closeMenu();
  }

  openAnagraficaUtenti() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/utenti']));
    this.closeMenu();
  }
  openAnagraficaCentrali() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/centrali']));
    this.closeMenu();
  }
  openAnagraficaComuni() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/comuni']));
    this.closeMenu();
  }

  openPianiTecniciSanita() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/pratiche-sanita/piani-tecnici']));
    this.closeMenu();
  }

  

  openPraticheScuola(isBando2:boolean) {
    if(isBando2){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/pratiche-scuola-bando2']));
    }else{
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/pratiche-scuola-bando1']));
    }
   
    this.closeMenu();
  }
  openPraticheSanita(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/pratiche-sanita']));
    this.closeMenu();
  }

  async changeLog(){
    const storage = getStorage();
      const pathReferenceChangeLog = ref(
        storage,
          "CHANGELOG_FLOWDY.md"
      );

    const blobChangeLog = await getBlob(pathReferenceChangeLog);
    saveAs(blobChangeLog,"CHANGELOG_FLOWDY.md");
  }
  
  logout() {
    
    /* 
    localStorage.setItem("email", null);
    localStorage.setItem("user.sub", null); */
    localStorage.setItem("idUtente", null);
    localStorage.setItem("utente", null);
    localStorage.setItem("ruolo", null);
    localStorage.setItem("isLoggedIn", "false");
    localStorage.setItem("idAzienda", null);
    localStorage.setItem("isAzienda",  null);
    localStorage.setItem("ragioneSocialeAzienda",  null);
    localStorage.setItem("BANDO_1",null);
    localStorage.setItem("BANDO_2", null);
    localStorage.setItem("BANDO_SANITA", null);
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  isAdministrator() {
    return (
      localStorage.getItem("ruolo") === "PRESIDIO" ||
      localStorage.getItem("ruolo") === "SMARTFIBER" ||
      localStorage.getItem("ruolo") === "ADMIN" || 
      localStorage.getItem("ruolo") === "SUPERADMIN" ||
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_VERBALI" ||
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_PACCHETTI" 
    );
  }

  checkBando1():boolean{
    if(!this.isAdministrator() && localStorage.getItem('BANDO_1')!=='true'){

      return false;
    }
    return true;

  }

  checkBando2(){
    if(!this.isAdministrator() && localStorage.getItem('BANDO_2')!=='true'){

      return false;
    }

    return true;
  }

  checkSanita(){
    if(!this.isAdministrator() && localStorage.getItem('BANDO_SANITA')!=='true'){

      return false;
    }

    return true;
  }


  
  
}
