<form [formGroup]="codiceInvioForm">

  <div class="grid formgrid p-fluid">
    
      <div class="field mb-1 col-6">
        <label for="codiceInvio" class="font-medium text-900">Nome INVIO</label>
        <input id="codiceInvio" pInputText formControlName="codiceInvio">
      </div>
    </div>    
      </form>

      <div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>
      
      <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
        <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
            styleClass="p-button-success"
            [disabled]="!codiceInvioForm.valid"></p-button>
        <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
            styleClass="p-button-warning"></p-button>
      </div>
          
