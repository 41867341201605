import { Injectable } from '@angular/core';
import { getStorage, ref, getBlob, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import 'firebase/storage';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class GoogleStorageService {
  private readonly bucketName = 'allegati-commesse';
  //private readonly credentialsPath = path.join(__dirname, 'credentials', 'coopamico-evo-dd9091182bce.json');
  //private readonly credentialsPath = `./assets/credentials/coopamico-evo-dd9091182bce.json`;

  async uploadFileSanitaToGoogleStorage(file: File, fileName: string): Promise<void> {
    const storage = getStorage();
    
    const pathReference = ref(
        storage,
        'pratiche/sanita/'+fileName
      );
    const uploadTask =  await uploadBytes(pathReference,file).then(ok => {
      

    }).catch(error => {
      console.log(">>>> KO FILE");
    });
  }

  checkIfFileExists(path: string): Observable<boolean> {
    console.log(">>>> CONTROLLO SE FILE ESISTE SU STORAGE: "+path);
    // Ottieni il riferimento al file sullo storage
    const storage = getStorage();
    const fileRef = ref(
      storage,
      path
    );
    return new Observable<boolean>(observer => {
      getDownloadURL(fileRef)
      .then(url => {
        console.log(">>>> FILE ESISTE! ");

        observer.next(true);
        observer.complete();
      },
      error => {
        console.log(">>>> FILE NON ESISTE! ");
        if (error.code === 'storage/object-not-found') {
          // Il file non esiste
          observer.next(false);
          observer.complete();
        } else {
          // Gestione degli altri errori
          observer.error(error);
        }
      }
    );
    });
    
    
   

}

  async uploadFileCMTPianoTecnicoSanitaToGoogleStorage(file: File, fileName: string): Promise<void> {
    const storage = getStorage();
    
    const pathReference = ref(
        storage,
        'pratiche/sanita/CMT/'+fileName
      );
    const uploadTask =  await uploadBytes(pathReference,file).then(ok => {
      console.log(">>>> OK SALVATAGGIO FILE "+fileName);

    }).catch(error => {
      console.log(">>>> KO FILE");
    });
  }

  async uploadFileScuolaToGoogleStorage(file: File, fileName: string): Promise<void> {
    const storage = getStorage();
    
    const pathReference = ref(
        storage,
        'pratiche/scuola/'+fileName
      );
    const uploadTask =  await uploadBytes(pathReference,file).then(ok => {
      console.log(">>>> OK SALVATAGGIO FILE "+fileName);

    }).catch(error => {
      console.log(">>>> KO FILE");
    });
  }

  async uploadNTXToGoogleStorage(geoJson: string, codiceUnivocoInfratel: string): Promise<void> {
    const storage = getStorage();
    console.log("---> uploadNTXToGoogleStorage "+ codiceUnivocoInfratel);

    const pathReference = ref(
        storage,
        'pratiche/sanita/'+codiceUnivocoInfratel+"/ntx/ntxMODIFICATO.json"
      );
      console.log("---> pathReference");
      console.log(geoJson);
      try {
        const blob = new Blob([geoJson], { type: 'application/json' });
        const arrayBuffer = await new Response(blob).arrayBuffer();
        const bytes = new Uint8Array(arrayBuffer);

       // const bytes = new Uint8Array(Buffer.from(geoJson));
      //console.log(bytes); 

      const uploadTask =  await uploadBytes(pathReference,bytes).then(ok => {
        console.log(">>>> OK SALVATAGGIO FILE");

      }).catch(error => {
        console.log(">>>> KO FILE");
      });
      } catch (error) {
        console.log(error);
      }
      
  }

  
  async downloadFileFromGoogleStorage(codice_univoco_infratel: String, fileName: String): Promise<void> {
    const storage = getStorage();
    
    const pathReference = ref(
        storage,
        'pratiche/scuola/'+codice_univoco_infratel+"/"+fileName
      );
    getBlob(pathReference).then(ok => {
      console.log(">>>> OK DOWNLOAD FILE");
    
      return ok;
    }).catch(error => {
      console.log(">>>> KO DOWNLOAD FILE FILE");
    });
  }

  async downloadGeoJSONSanitaFromGoogleStorage(codice_univoco_infratel: String): Promise<void> {
    const storage = getStorage();
    
    const pathReference = ref(
        storage,
        'pratiche/sanita/'+codice_univoco_infratel+"/ntx/ntx.json"
      );
    getBlob(pathReference).then(ok => {
      console.log(">>>> OK DOWNLOAD GEO JSON PRATICA " + codice_univoco_infratel);

      return ok;
    }).catch(error => {
      console.log(">>>> KO DOWNLOAD GEO JSON PRATICA " + codice_univoco_infratel);
    });
  }
}
