<form [formGroup]="creaCentraleForm">
    <div class="grid formgrid p-fluid">
        <div class="field mb-1 col-3">
            <label for="idTIM" class="font-medium text-900">Codice ACL</label>
            <input id="idTIM" type="text" pInputText formControlName="idTIM" placeholder="Codice ACL">
         
        </div>
        <div class="field mb-1 col-3">
            <label for="clli" class="font-medium text-900">CLLI</label>
            <input id="clli" type="text" pInputText formControlName="clli" placeholder="ID TIM">
         
        </div>
        <div class="field mb-1 col-3">
            <label for="clliAttestazione" class="font-medium text-900">CLLI Attestazione</label>
            <input id="clliAttestazione" pInputText formControlName="clliAttestazione" placeholder="CLLI Attestazione">
         
        </div>
        <div class="field mb-1 col-3">
            <label for="denominazione"class="font-medium text-900">Denominazione</label>
            <input id="denominazione" pInputText formControlName="denominazione" placeholder="Denominazione">
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-3 mt-3 col-12"></div>
        
        <div class="field mb-1 col-4">
            <label for="indirizzo" class="font-medium text-900">Indirizzo</label>
            <input id="indirizzo" pInputText formControlName="indirizzo" placeholder="Indirizzo">
        </div>
        <div class="field mb-1 col-3">
            <label for="comune" class="font-medium text-900">Comune</label>
            <p-autoComplete 
                formControlName="comune" 
                [suggestions]="filteredComuni" 
                (completeMethod)="filterComune($event)"
                field="denominazione" placeholder="Cerca un comune"
                ></p-autoComplete>

            <!-- <p-dropdown inputId="comune" [options]="comuni" optionLabel="denominazione" [filter]="true" filterBy="denominazione"
                [showClear]="true" placeholder="Comune" formControlName="comune" 
                [virtualScroll]="true" [virtualScrollItemSize]="10">
                <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center">
                        <div>{{comune.denominazione}}</div>
                    </div>
                </ng-template>
            </p-dropdown> -->
        </div>
     
        <div class="field col-2">
            <label for="latitudine" class="font-medium text-900">Latitudine</label>
            <p-inputNumber formControlName="latitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field col-2">
            <label for="longitudine" class="font-medium text-900">Longitudine</label>
            <p-inputNumber formControlName="longitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="col-1">
            <button  pButton type="button" style="color:#0c096e;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-4" tooltipPosition="top" pTooltip="Posizione Centrale"
              [disabled]="checkLatAndLng()"
              (click)="openMappaCentrale()">
            </button>
          </div> 
        
    </div>
</form>

<div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaCentraleForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
      
