import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

import { saveAs } from 'file-saver';
import { NGXLogger } from 'ngx-logger';
import { Stats } from '../models/stats.model';
import { PraticaScuola_Firestore } from '../models/praticaScuolaFirestore.model';
import { Centrale } from '../models/centrale.models';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private logger: NGXLogger) {}

  exportPraticheBando2ToExcel(data: PraticaScuola_Firestore[], filename: string): void {

    const customHeaders = [
      'CODICE INFRATEL',
      'NOME INVIO',
      'IMPRESA',
      'REGIONE',
      'COMUNE',
      'INDIRIZZO',
      'TIPO SEDE',
      'TIPO SEDE EFFETTIVA',
      'COLOCATA',
      'BACKUP',
      'STATO'
    ];
    const customData = data.map(pratica => ({
       'CODICE INFRATEL': pratica.codiceInfratel,
       'NOME INVIO': pratica.nomeInvio,
       'IMPRESA': pratica.azienda,
       'REGIONE': pratica.regione,
       'COMUNE': pratica.comune, 
       'INDIRIZZO': pratica.indirizzo,
       'TIPO SEDE': pratica.tipoSede,
       'TIPO SEDE EFFETTIVA': pratica.tipoSedeEffettiva? pratica.tipoSedeEffettiva:"",
       'COLOCATA': pratica.colocata?'SI':'NO',
       'BACKUP': pratica.backup?'SI':'NO',
       'STATO': pratica.stato
    }));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customData, {
      header: []
     ,
    });
    worksheet['A1'] = { v: customHeaders[0] };
    worksheet['B1'] = { v: customHeaders[1] };
    worksheet['C1'] = { v: customHeaders[2] };
    worksheet['D1'] = { v: customHeaders[3] };
    worksheet['E1'] = { v: customHeaders[4] };

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Salva il file Excel
    saveAs(blob, filename);
  }

  exportImpreseToExcel(data: Stats[], filename: string): void {

    const customHeaders = [
      'RAGIONE SOCIALE',
      'SCUOLE ASSEGNATE',
      'SCUOLE IN CARICO',
      'SCUOLE CONSEGNATE',
      'SCUOLE IN REWORK',
    ];
    const customData = data.map(item => ({
      'RAGIONE SOCIALE': item.ragioneSociale,
      'SCUOLE ASSEGNATE': item.numeroPraticheScuolaAssegnate,
      'SCUOLE IN CARICO': item.numeroPraticheScuolaInCarico,
      'SCUOLE CONSEGNATE': item.numeroPraticheScuolaConsegnate,
      'SCUOLE IN REWORK': item.numeroPraticheScuolaInRework,
    }));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customData, {
      header: []
     ,
    });
    worksheet['A1'] = { v: customHeaders[0] };
    worksheet['B1'] = { v: customHeaders[1] };
    worksheet['C1'] = { v: customHeaders[2] };
    worksheet['D1'] = { v: customHeaders[3] };
    worksheet['E1'] = { v: customHeaders[4] };

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Salva il file Excel
    saveAs(blob, filename);
  }

  exportCentraliToExcel(data: Centrale[], filename: string): void {

    const customHeaders = [
      'CODICE ACL',
      'CLLI',
      'CLLI ATTESTAZIONE',
      'DENOMINAZIONE',
      'INDIRIZZO',
      'COMUNE',
      'LATITUDINE',
      'LONGITUDINE',
    ];
    const customData = data.map(item => ({
      'CODICE ACL': item.idTim,
      'CLLI': item.clli,
      'CLLI ATTESTAZIONE': item.clliAttestazione,
      'DENOMINAZIONE': item.denominazione,
      'INDIRIZZO': item.indirizzo,
      'COMUNE': item.comune.denominazione,
      'LATITUDINE': item.latitudine,
      'LONGITUDINE': item.longitudine,
    }));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customData, {
      header: []
     ,
    });
    worksheet['A1'] = { v: customHeaders[0] };
    worksheet['B1'] = { v: customHeaders[1] };
    worksheet['C1'] = { v: customHeaders[2] };
    worksheet['D1'] = { v: customHeaders[3] };
    worksheet['E1'] = { v: customHeaders[4] };
    worksheet['F1'] = { v: customHeaders[5] };
    worksheet['G1'] = { v: customHeaders[6] };
    worksheet['H1'] = { v: customHeaders[7] };



    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, filename);
  }

 

}
