
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
  <p class="line-height-3 p-0 mt-1">
   Seleziona il file o trascinalo nell'area sottostante.
  </p>
   <p-fileUpload name="fileComputoMetrico" 
        multiple="false"
        maxFileSize="15000000"
        chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
        [auto]="true"
        [customUpload]="true"
        (uploadHandler)="onComputoMetricoUpload($event)"
        [showUploadButton]="false"
        [showCancelButton]="false"
        (onRemove)="onComputoMetricoRemove()"
        [disabled]="isComputoMetricoUploaded"
        class="custom-red-button"
        accept=".xls, .xlsx"
    >
    </p-fileUpload>
 

<div  class="flex upload-file-div-box box mt-2">

    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isComputoMetricoUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>

</div>

