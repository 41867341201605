import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';
import { PraticaScuola } from '../models/pratica.scuola.model';
import { DettaglioPraticaScuolaDTO } from '../models/dettaglioPratica.scuola.model';
import { FileCaricatoDTO } from '../models/fileCaricato.model';
import { NotaReworkPratica } from '../models/pratica.nota.scuola.model';
import { NotaPraticaDTO } from '../models/pratica.notaDTO.scuola.model';
import { ModificaPraticaDTO } from '../models/modificaPratica.scuola.model';
import { Centrale } from '../models/centrale.models';


@Injectable({
  providedIn: 'root',
})
export class PraticheScuolaService{
  apiUrl: string = `${environment.apiUrl}`+"pratica-scuola";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    getById(idPratica:string): Observable<any> {
      let url = this.apiUrl+"/"+idPratica;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    getByCodiceInfratel(codiceinfratel:string): Observable<any> {
      let url = this.apiUrl+"/codiceinfratel/"+codiceinfratel;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    assegna(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/assegna"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
   
    }

    prendiInCarico(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/in-carico"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
   
    }

    presidioPrendeInCarico(praticaDaPrendereInCarico:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaPrendereInCarico.id+"/in-carico-presidio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    consegna(praticaDaConsegnare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaConsegnare.id+"/consegna"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaConsegnare, httpOptions)
   
    }

    generaDocs(praticaDaGenerareDocs:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaGenerareDocs.id+"/genera-docs"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }


    
    annulla(praticaDaAnnullare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaAnnullare.id+"/annulla"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }
    elimina(praticaDaEliminare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaEliminare.id;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.delete(urlFinale, httpOptions)
   
    }

    

    annullaConsegna(praticaDaAnnullareConsegnare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaAnnullareConsegnare.id+"/annulla-consegna"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    mandaInRework(praticaDaMandareInRework:PraticaScuola, nota: NotaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaMandareInRework.id+"/rework"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, nota, httpOptions)
    }
    mandaInReworkPresidio(praticaDaMandareInRework:PraticaScuola, nota: NotaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaMandareInRework.id+"/rework-presidio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, nota, httpOptions)
    }
    

    inviaAlPresidio(praticaDaConsegnare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaConsegnare.id+"/invia-presidio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaConsegnare, httpOptions)
   
    }

    iniziaProgettazione(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/inizia-progettazione"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    
    }

    confermaCaricamentoFotoRouter(praticaDaModificare:PraticaScuola, estensioneFile: String): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/foto-router";
      let fileDTO: FileCaricatoDTO = new FileCaricatoDTO();
      fileDTO.estensione = estensioneFile;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, fileDTO, httpOptions);
    }

    confermaCaricamentoPlanimetria(praticaDaModificare:PraticaScuola, estensioneFile: String): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/planimetria";
      let fileDTO: FileCaricatoDTO = new FileCaricatoDTO();
        fileDTO.estensione = estensioneFile;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, fileDTO, httpOptions)
    }

    confermaCaricamentoPDFPlanimetria(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/pdf-planimetria";
      let fileDTO: FileCaricatoDTO = new FileCaricatoDTO();
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, fileDTO, httpOptions)
    }
    

    confermaCaricamentoNT9(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/nt9"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }
    confermaCaricamentoVerbale(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/verbale"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    confermaCaricamentoPacchetto(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/pacchetto"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    



    caricaDettagliPratica(dettaglioPraticaDTO: DettaglioPraticaScuolaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+dettaglioPraticaDTO.id+"/dettagli";
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, dettaglioPraticaDTO, httpOptions)
    }

    comunicaOrdineEsecuzione(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/ordine-esecuzione"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    modificaCodiceInvio(praticaDaModificare:ModificaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/modifica-codice-invio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    modificaStatoPratica(idPratica:String, idNuovoStato:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+idPratica+"/modifica-stato/"+idNuovoStato;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
    }

    

    completaPratica(idPratica:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+idPratica+"/completa";
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, httpOptions)
    }



    pubblicazionePratica(idPraticaFirmata:any): Observable<any> {
      const urlFinale = this.apiUrl+"/"+idPraticaFirmata+"/pubblicata"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
    }

    comunicaApprovazionePratica(praticaApprovata:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaApprovata.id+"/approvata"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
    }
    comunicaRespingimentoPratica(praticaRespinta:PraticaScuola, nota: NotaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaRespinta.id+"/respinta"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      this.logger.info(nota);
      return this.http.post(urlFinale, nota, httpOptions)
    }
    annullaAssegnazione(praticaDaModificare:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare+"/annulla-assegnazione"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    richiediCambioCentrale(praticaDaModificare:number, centrale:Centrale): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare+"/cambio-centrale"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, centrale, httpOptions)
   
    }
    richiediCambioAssegnazione(praticaDaModificare:number, idUtente:string): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare+"/cambio-assegnazione/"+idUtente;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    resetta(praticaDaResettare:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaResettare+"/reset";
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    rigeneraTuttaLaDocumentazione(praticaDaModificare:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare+"/rigenera-docs";
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }
    
    modifica(praticaDaModificare:ModificaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.put(urlFinale, praticaDaModificare, httpOptions)
   
    }

    crea(praticaDaCreare:PraticaScuola): Observable<any> {
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post( this.apiUrl, praticaDaCreare, httpOptions)
    }

 
 

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}
