import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
  PrimeIcons,
} from "primeng/api";
import { environment } from "../../../../../environments/environment";
import { Menu } from "primeng/menu";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";
import {
  AngularFirestore,
  QueryDocumentSnapshot,
} from "@angular/fire/compat/firestore";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  Firestore,
  GeoPoint,
} from "firebase/firestore";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";

import { Observable, Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { Stats } from "src/app/models/stats.model";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { Utente } from "src/app/models/utente.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { DettaglioPraticaScuolaDTO } from "src/app/models/dettaglioPratica.scuola.model";
import { ScuoleService } from "src/app/services/scuole.service";
import { ScuolaDTO } from "src/app/models/scuola.model";
import { Location } from "@angular/common";

declare const CSVBoxImporter: any;
@Component({
  selector: "app-pratiche-scuola-bando1",
  templateUrl: "./pratiche-scuola-bando1.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class PraticheScuolaBando1Component implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  praticheAtFirestore: PraticaScuola_Firestore[] = [];
  selectedPraticaAtFirestore: PraticaScuola_Firestore;

  //PER MULTISELECT
  selectedPratiche!: PraticaScuola_Firestore[];
  disableSingleActionButtons: boolean = false;

  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public isPresidioSF: boolean = false;
  public isPresidioTIM: boolean = false;
  public isDirigenteFirmaVerbali: boolean = false;
  public isDirigenteFirmaPacchetti: boolean = false;
  public isImpresa: boolean = false;

  public numeroTotalePratiche: number;
  public imprese: MenuItem[] = [];

  mostraAnnullate: boolean = true;
  testoFiltroAnnullate:String='MOSTRA';

  //CSV BOX
  private csvbox_licenseKey_importa:string=environment.csvBox.secret_bando1;
  private csvbox_licenseKey_aggiorna:string=environment.csvBox.secret_aggiornaPraticheBando1;
  private csvbox_user:string=environment.csvBox.user;
  private csvbox_importer:any;
  private csvbox_importer_aggiornaPratiche:any;

  public collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;
  
  public regioniPratica: MenuItem[] = [
    {
      label: "ABRUZZO",
      command: (event) => {
        this.praticheScuolaDT.filter("ABRUZZO", "regione", "is");
      },
    },
    {
      label: "CALABRIA",
      command: (event) => {
        this.praticheScuolaDT.filter("CALABRIA", "regione", "is");
      },
    },
    {
      label: "CAMPANIA",
      command: (event) => {
        this.praticheScuolaDT.filter("CAMPANIA", "regione", "is");
      },
    },
    {
      label: "LAZIO",
      command: (event) => {
        this.praticheScuolaDT.filter("LAZIO", "regione", "is");
      },
    },
    {
      label: "MARCHE",
      command: (event) => {
        this.praticheScuolaDT.filter("MARCHE", "regione", "is");
      },
    },
    {
      label: "MOLISE",
      command: (event) => {
        this.praticheScuolaDT.filter("MOLISE", "regione", "is");
      },
    },
    {
      label: "PIEMONTE",
      command: (event) => {
        this.praticheScuolaDT.filter("PIEMONTE", "regione", "is");
      },
    },
    {
      label: "PUGLIA",
      command: (event) => {
        this.praticheScuolaDT.filter("PUGLIA", "regione", "is");
      },
    },
    {
      label: "TOSCANA",
      command: (event) => {
        this.praticheScuolaDT.filter("TOSCANA", "regione", "is");
      },
    },
    {
      label: "VENETO",
      command: (event) => {
        this.praticheScuolaDT.filter("VENETO", "regione", "is");
      },
    },
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    }
  ];

  public tipiSede: MenuItem[] = [
   
    {
      label: "A",
      command: (event) => {
        this.praticheScuolaDT.filter("Mandatoria A", "tipoSede", "is");
      },
    },
    {
      label: "B",
      command: (event) => {
        this.praticheScuolaDT.filter("Mandatoria B", "tipoSede", "is");
      },
    },
   
    {
      label: "C",
      command: (event) => {
        this.praticheScuolaDT.filter("Mandatoria C", "tipoSede", "is");
      },
    },
   
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    },
  ];

  public statiPratica: MenuItem[] = [];
  public statoFiltroSelezionato: MenuItem;

  @ViewChild("menuScaricaFile", { static: true }) azioniScaricaDocMenu: Menu;
  @ViewChild("menuCaricaFile", { static: true }) azioniCaricaFileMenu: Menu;
  protected menuScaricaFileItems!: MenuItem[];
  protected menuCaricaFileItems!: MenuItem[];
  
  @ViewChild("menuRisultatoInvioInfratel", { static: true }) azioniRisultatoInvioInfratel: Menu;
  protected menuRisultatiInvioPraticaItems!: MenuItem[];

  @ViewChild("menuAzioni", { static: true }) azioniMenu: Menu;
  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;
  protected menuAzioniItems!: MenuItem[];
  protected menuTieredAzioniItems!: MenuItem[];
  @ViewChild("menuAzioniAzienda", { static: true }) azioniAziendaMenu: Menu;
  protected menuAzioniAziendaItems!: MenuItem[];

  @ViewChild("menuStati") menuStati!: Menu;
  @ViewChild("menuTipiSede") menuTipiSede!: Menu;
  @ViewChild("menuRegioni") menuRegioni!: Menu;

  @ViewChild("praticheScuolaDT", { static: true }) praticheScuolaDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  protected msgs: Message[] = [];
  protected ref: DynamicDialogRef;
  protected dialogConfig:any;

  protected filterIsSet:Boolean = true;
  protected filtroStato:String;
  protected filtroPratica:String;

  constructor(
    private logger: NGXLogger,
    private googleStorageService: GoogleStorageService,
    private confirmationService: ConfirmationService,
    private praticheScuolaService: PraticheScuolaService,
    private scuoleService: ScuoleService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService,
    private storicoPraticheService: StoricoPraticheService,
    private notePraticheService: NotePraticheService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private impreseService:ImpreseService,
    private csvboxLoaderService: CsvboxLoaderService,

  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }

  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SUPERADMIN"
    ) {
      this.isSuperAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SMARTFIBER"
    ) {
      this.isPresidioSF = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "PRESIDIO"
    ) {
      this.isPresidioTIM = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "OPERATORE_AZIENDA"
    ) {
      this.isImpresa = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_VERBALI"
    ) {
      this.isDirigenteFirmaVerbali = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_PACCHETTI"
    ) {
      this.isDirigenteFirmaPacchetti = true;
    }
    const urlParamStato  = this.activatedRoute.snapshot.paramMap.get('stato');
    const urlParamPratica = this.activatedRoute.snapshot.paramMap.get('pratica');
    if(urlParamStato){
      this.filtroStato = urlParamStato;
      this.filterIsSet = true;
    }else if(urlParamPratica){
      this.filtroPratica = urlParamPratica;
      this.filterIsSet = true;
    }else {
      this.filterIsSet = false;
    }

    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF) {
      this.loadImprese();
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadAllPraticheScuolaFromFirestore();
      }
    } else if(this.isPresidioTIM){
      this.logger.info("---> this.isPresidioTIM "+ this.filterIsSet);
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      this.logger.info("---> DIRIGENTE FIRMA PACCHETTI ");
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheAziendaScuolaFromFirestore();
    }
    this.loadMenuStatiPratica();

    this.loadCSVBox();
  }

  openAggiornaPratiche(){
    this.logger.info("*** AGGIORNA PRATICHE BANDO 2 *****");
    this.csvbox_importer_aggiornaPratiche.openModal();
  }

  applyHideClosedFilter() {
    this.logger.info("--> applyHideClosedFilter "+ this.mostraAnnullate + " " + this.testoFiltroAnnullate);
    if (this.mostraAnnullate) {
      this.testoFiltroAnnullate='NASCONDI ';
      this.mostraAnnullate = false;
      this.praticheScuolaDT.clear();
      
    } else {
      this.testoFiltroAnnullate='MOSTRA ';
      this.mostraAnnullate = true;
      this.praticheScuolaDT.filter("ANNULLATA", "stato", "isNot");
    }
  }
  showPulsante(pratica:PraticaScuola_Firestore):boolean{
    if(this.isDirigenteFirmaPacchetti && pratica.colocataRuolo==='SECONDARIA'){
      return false;
    }
    return true;
  }
  async openCaricaAzioniTieredPratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioni(event, pratica);
    this.azioniMenuTiered.toggle(event);
  }

  loadAzioni(event:any, pratica:PraticaScuola_Firestore) {
    this.menuAzioniItems = [];
    if(!this.isDirigenteFirmaVerbali && !this.isDirigenteFirmaPacchetti){
      this.menuAzioniItems.push(
        {
          label: "Storico",
          icon: PrimeIcons.HISTORY,
          style: {  'font-size':'12.7px'},
          command: () => this.openStoricoPratica(pratica),
        },
       {
          label: "Posizione",
          icon: PrimeIcons.MAP_MARKER,
          style: {  'font-size':'12.7px'},
          command: () => this.openMappaConScuola(pratica),
        }  );
    }
    
    //IMPRESA ===================================================================================================================
    if(this.isImpresa ){ 
      if(this.isDaPrendereInCarico(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
      if(this.isDaProgettare(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Progetta",
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            icon: PrimeIcons.FILE_EDIT,

            command: () => this.openIniziaProgettazione(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,
            style: {  'font-size':'12.7px'},
            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );
      }
      if(this.isInProgettazione(pratica) || this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: pratica.infoCompleted? "Modifica Info": "Inserisci Info",
            icon: PrimeIcons.SERVER,
            style: {  'font-size':'12.7px', 'font-weight': 'bold', 'color':'green' },
            command: () => this.openInserisciDettagli(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,
            style: {  'font-size':'12.7px'},
            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Apri Gis2Web",
            icon: PrimeIcons.MAP_MARKER,
            style: {  'font-size':'12.7px'},
            command: () => this.openGis2Web(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: {  'font-size':'12.7px', 'font-weight': 'bold', 'color':'green' },
            items: [
              {
                label: "Foto Router",
                icon: PrimeIcons.CAMERA,
                style: {  'font-size':'12.7px'},
                command: () => this.openCaricaFotoRouter(pratica),
              } ,
          
              {
                label: "Pacchetto NT9",
                icon: PrimeIcons.BUILDING,
                style: {  'font-size':'12.7px'},
                command: () => this.openCaricaPacchettoNT9(pratica),
              }, 
              {
                label: "Piantina",
                icon: PrimeIcons.MAP,
                style: {  'font-size':'12.7px'},
                command: () => this.openCaricaPiantina(pratica),
              }, 
            ],
          }
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.areDocsCreatable(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Genera Docs",
            icon: PrimeIcons.EYE,
            style: {  'font-size':'12.7px'},
            command: () => this.openPreviewDocs(pratica),
          },
        );
      }

      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && (this.isDeliverable(pratica) || this.isInRework(pratica))){
        this.menuAzioniItems.push(
          {
            label: "Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {   'font-size':'12.7px', 'font-weight': 'bold', 'color':'green' },
            command: () => this.openConsegnaPratica(pratica),
          },
        );
      }
      if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && ((this.isDelivered(pratica) && this.isImpresa) ||  this.isInCaricoPresidioSF(pratica)) ){
        this.menuAzioniItems.push(
          {
            label: "Annulla Consegna",
            icon: PrimeIcons.TIMES_CIRCLE,
            style: {   'font-size':'12.7px', 'font-weight': 'bold'},
            command: () => this.openAnnullaConsegnaPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            style: {   'font-size':'12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica) && this.hasReworkFile(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica all. rework",
            style: {   'font-size':'12.7px'},
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }

      if(this.isDelivered(pratica) || this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica File",
            icon: PrimeIcons.CLOUD_DOWNLOAD,
            style: {   'font-size':'12.7px'},
            items: [
              {
                label: "NT9",
                icon: PrimeIcons.MAP,
                styleClass: "RETstyle",
                style: {   'font-size':'12.7px'},
                command: () => this.scaricaNT9(),
              },
              {
                label: "RET",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                style: {   'font-size':'12.7px'},
                command: () => this.scaricaDocs("RET"),
              },
              {
                label: "PSI",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                style: {   'font-size':'12.7px'},
                command: () => this.scaricaDocs("PSI"),
              },
              {
                label: "ALL.IRU",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                style: {   'font-size':'12.7px'},
                command: () => this.scaricaDocs("IRU"),
              },
              {
                label: "TUTTI",
                icon: PrimeIcons.REPLAY,
                styleClass: "RETstyle",
                style: {   'font-size':'12.7px'},
                command: () => this.scaricaDocs("ALL"),
              },
            ]
           // command: () => this.openScaricaDocumentazionePratica(pratica),
          },
        );
      }
      if(this.isApproved(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Scarica pacchetto",
            icon: PrimeIcons.CLOUD_DOWNLOAD,
            style: {  'font-size':'12.5px', 'font-weight': 'bold'},
            command: () => this.scaricaPacchettoPraticaApprovata(pratica),
          },
        );
      }
      //PRESIDIO SF ===================================================================================================================
    }else if(this.isPresidioSF){
      //if(this.isVoidable(pratica)){ 
        this.menuAzioniItems.push(
          {
            label: "Modifica",
            icon: PrimeIcons.PENCIL,
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            command: () => this.openModificaPraticaDialog(pratica),
          },
        );
     //}
      if(this.isDaPrendereInCarico(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {  'font-size': '13px','font-weight': 'bold'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
     /*  if(this.isDaProgettare(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Progetta",
            icon: PrimeIcons.FILE_EDIT,
            command: () => this.openIniziaProgettazione(pratica),
          },
        );
      } */
      if(this.isInProgettazione(pratica) || this.isInRework(pratica) || this.isInReworkSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: pratica.infoCompleted? "Modifica Info": "Inserisci Info",
            style: {  'font-size':'12.7px'},
            icon: PrimeIcons.SERVER,
            command: () => this.openInserisciDettagli(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Apri Gis2Web",
            style: {  'font-size':'12.7px'},
            icon: PrimeIcons.MAP_MARKER,
            command: () => this.openGis2Web(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Carica",
            style: {  'font-size':'12.7px'},
            icon: PrimeIcons.CLOUD_UPLOAD,
            items: [
              {
                label: "Foto Router",
                icon: PrimeIcons.CAMERA,
                command: () => this.openCaricaFotoRouter(pratica),
              } ,
          
              {
                label: "Pacchetto NT9",
                icon: PrimeIcons.BUILDING,
                command: () => this.openCaricaPacchettoNT9(pratica),
              }, 
            /*   {
                label: "Planimetria",
                icon: PrimeIcons.IMAGE,
                command: () => this.openCaricaPlanimetria(pratica),
              }, */
            ],
          }
        );
      }
      if(this.areDocsCreatable(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Genera Docs",
            icon: PrimeIcons.EYE,
            style: {  'font-weight': 'bold', 'font-size':'12.7px'},
            command: () => this.openPreviewDocs(pratica),
          },
        );
      }

      if( this.isDeliverable(pratica) || this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {  'font-size':'12.7px','font-weight': 'bold'},
            command: () => this.openConsegnaPratica(pratica),
          },
        );
      }
      if(this.isDelivered(pratica) ||  this.isInCaricoPresidioSF(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Annulla Consegna",
            icon: PrimeIcons.TIMES_CIRCLE,
            style: {  'font-weight': 'bold', 'font-size':'12.7px'},
            command: () => this.openAnnullaConsegnaPratica(pratica),
          },
          {
            label: "Rigenera Docs",
            icon: PrimeIcons.REPLAY,
            style: {  'font-weight': 'bold','font-size': '13px'},
            
            command: () => this.rigeneraDocs(pratica),
          },
        );
      }
      if(this.isInCaricoPresidioSF(pratica) || this.isDelivered(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Resetta",
            icon: PrimeIcons.REFRESH,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.resettaPratica(pratica),
          }
        );
      }
     // if(this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Cambia Stato",
            icon: PrimeIcons.DIRECTIONS,
            style: {  'font-weight': 'bold','font-size': '12.7px'},
            command: () => this.forzaStatoPratica(pratica),
          }
        );
    //  }
      if(this.isInRework(pratica) || this.isInReworkSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            style: {  'font-size':'12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

    

      if((this.isInRework(pratica) || this.isInReworkSF(pratica) ) && this.hasReworkFile(pratica) ){
        this.menuAzioniItems.push(
          {
            label: "Scarica all. rework",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            style: {  'font-size':'12.7px'},
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }

      if(this.isDelivered(pratica) || this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica File",
            style: {  'font-size':'12.7px'},
            icon: PrimeIcons.CLOUD_DOWNLOAD,

            items: [
              {
                label: "NT9",
                icon: PrimeIcons.MAP,
                styleClass: "RETstyle",
        
                command: () => this.scaricaNT9(),
              },
              {
                label: "RET",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
        
                command: () => this.scaricaDocs("RET"),
              },
              {
                label: "PSI",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
        
                command: () => this.scaricaDocs("PSI"),
              },
              {
                label: "ALL.IRU",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                command: () => this.scaricaDocs("IRU"),
              },
              {
                label: "TUTTI",
                icon: PrimeIcons.REPLAY,
                styleClass: "RETstyle",
                command: () => this.scaricaDocs("ALL"),
              },
            ]
           // command: () => this.openScaricaDocumentazionePratica(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) ){ //|| this.isDelivered(pratica)
        this.menuAzioniItems.push(
          {
            label: "Info Fase 3",
            icon: PrimeIcons.SERVER,
            style: {  'font-size':'12.7px'},
            command: () => this.openInserisciDettagli(pratica),
          },
          {
            label: "Manda in Rework",
            icon: PrimeIcons.CHEVRON_CIRCLE_LEFT,
            style: {  'font-weight': 'bold', 'font-size':'12.7px'},
            command: () => this.openMandaInRework(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Invia al Presidio",
            style: {  'font-weight': 'bold' , 'font-size':'12.7px'},
            icon: PrimeIcons.CHEVRON_CIRCLE_RIGHT,
            command: () => this.openMandaAlPresidioTIM(pratica),
          },
        );
      }
   

      if((this.isDelivered(pratica) || this.isRejected(pratica) || this.isInReworkSF(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            style: {  'font-weight': 'bold', 'font-size':'12.7px'},
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },
        );
      }

      if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Assegna",
            icon: PrimeIcons.PAPERCLIP,
            command: () => this.openAssegnaAdImpresa(pratica),
          },
        );
      }
      //(!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE' || ( pratica.colocataRuolo ==='SECONDARIA' && pratica.colocataFake)) &&
      if(  (this.isPackageDownloadable(pratica) )   ){
        this.menuAzioniItems.push({
          label: "Scarica Pacchetto",
          icon: PrimeIcons.DOWNLOAD,
          style: {  'font-weight': 'bold','font-size': '12.7px'},
          command: () => this.scaricaPacchettoPratica(pratica, false),
        });
      }
      if(  (this.isInSigningPackageState(pratica) )   ){

        this.menuAzioniItems.push(
              {
                label: "Rigenera Docs",
                icon: PrimeIcons.REPLAY,
                style: {  'font-weight': 'bold','font-size': '12.7px'},
                command: () => this.rigeneraDocs(pratica),
              }/* ,
              {
                label: "Scarica Pacchetto",
                icon: PrimeIcons.DOWNLOAD,
                style: {  'font-weight': 'bold','font-size': '12.7px'},
                command: () => this.scaricaPacchettoPratica(pratica, false),
              } */);
        } 
        

      /* if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      } */

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            style: {  'font-size':'12.7px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
          {
            label: "Scarica File Respingimento",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            style: {  'font-size':'12.7px'},
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      
        
     //PRESIDIO TIM ===================================================================================================================
    }else if(this.isPresidioTIM){
      if(!this.isApproved(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Modifica Nome Invio",
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            icon: PrimeIcons.PENCIL,
            command: () => this.openCambiaCodiceInvio(pratica),
          },
        );
      }
      if(this.isInCaricoPresidioTIM(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Completa Pratica",
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            icon: PrimeIcons.FILE_EDIT,
            command: () => this.openCompletaPraticaPresidioTIM(pratica),
          },
          
          {
            label: "Rimanda a SmartFiber",
            style: {  'font-weight': 'bold','font-size': '12px'},
            icon: PrimeIcons.ARROW_CIRCLE_LEFT,
            command: () => this.openMandaIndietroAlPresidioSF(pratica),
          },
        );
      }
      if((this.isSigned(pratica) || this.isPublished(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica Pacchetto",
            style: {  'font-size':'12.7px', 'font-weight': 'bold'},
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaPacchettoFirmato(pratica),
          });//,
        } 
        if(this.isPublished(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            { 
                label: "Risultato Invio",
                style: {  'font-size':'12.7px', 'font-weight': 'bold'},
                icon: PrimeIcons.INBOX,
                items: [
                  {
                    label: "APPROVATA",
                    icon: PrimeIcons.CHECK,
                    styleClass: "valid-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
                  },
                  {
                    label: "RESPINTA",
                    icon: PrimeIcons.TIMES,
                    styleClass: "reject-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
                  },
                ]
        
            });
        }
      

    }else if(this.isAdmin || this.isSuperAdmin){
      this.menuAzioniItems.push(
        {
          label: "Modifica",
          icon: PrimeIcons.PENCIL,
          command: () => this.openModificaPraticaDialog(pratica),
        },
      );
      if(this.isVoidable(pratica)){
        if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            {
              label: "Assegna",
              icon: PrimeIcons.PAPERCLIP,
              style: {  'font-weight': 'bold'},
              command: () => this.openAssegnaAdImpresa(pratica),
            },
          );
        }
        this.menuAzioniItems.push(
          {
            label: "Annulla",
            icon: PrimeIcons.TIMES,
            style: {  'font-weight': 'bold'},
            command: () => this.openAnnullaPratica(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: {  'font-weight': 'bold'},
            command: () => this.openEliminaPratica(pratica),
          },
        );

      }
     
      if(this.isApproved(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaPacchettoPratica(pratica,false),
          },
        );
      }
      if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica File Respingimento",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      if(this.isRejected(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },
        );
      }
    
    }else if(this.isDirigenteFirmaVerbali ){
      
      if(this.isInVerbalSignState(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            style: {  'font-weight': 'bold'},
            command: () => this.scaricaVerbale(pratica, false),
          },
          {
            label: "Carica Firmato",
            icon: PrimeIcons.UPLOAD,
            style: {  'font-weight': 'bold'},
            command: () => this.caricaVerbaleFirmato(pratica, false),
          }
        );
      }
      if(this.hasSignedVerbal(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }else if (this.isDirigenteFirmaPacchetti){
      
      if(this.isInSigningPackageState(pratica) && (!pratica.colocata || pratica.colocataRuolo=='PRINCIPALE')){
        this.menuAzioniItems.push(
          {
                label: "Scarica Pacchetto",
                icon: PrimeIcons.DOWNLOAD,
                command: () => this.scaricaPacchettoPratica(pratica, false),
              } ,
              {
                label: "Carica Firmato",
                icon: PrimeIcons.UPLOAD,
                command: () => this.caricaPacchettoFirmato(pratica, false),
              } ,
            
          
        );
      }
      if(this.hasSignedPackage(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }

  }

  async scaricaNT9() {
    const storage = getStorage();
      const pathReferenceNT9 = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/IN/nt9.zip"
      );

      const blobNT9 = await getBlob(pathReferenceNT9);
      saveAs(blobNT9, "nt9.zip");

  }

  openCambiaCodiceInvio(pratica:PraticaScuola_Firestore){
    this.ref = this.dialogService.open(DialogModificaCodiceInvioPraticaBando1, {
      data: {
        pratica: pratica,
        
      },
      header: "Modifica NOME INVIO della Pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "65%",
      height: "45%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((infoUpdated) => {
      if (infoUpdated && infoUpdated.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Codice INVIO modificato con successo",
        });
      }
    });

  }

  praticaCompletata(pratica:PraticaScuola_Firestore):boolean{
    if(pratica.stato==='IN PROGETTAZIONE' && pratica.infoCompleted && pratica.fotoRouterUploaded && pratica.nt9Uploaded){
      return true;
    }
    return false;
  }

  showInfoFase3Complete(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.infoCompleted ) {
      return true;
    }
    return false;
  }
  showPiantinaUploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.planimetriaUploaded ){
      return true;
    }
    return false;
  }
  showPacchettoNT9Uploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' &&  pratica.nt9Uploaded){
      return true;
    }
    return false;
  }
  showFotoRouterUploaded(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && (!pratica.infoCompleted && !pratica.nt9Uploaded) && pratica.fotoRouterUploaded){
      return true;
    }
    return false;
  }

  showDocs(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && pratica.stato==='IN PROGETTAZIONE' && pratica.infoCompleted && pratica.fotoRouterUploaded){
        return true;
    }
    return false;
  }

  showDeliverable(pratica:PraticaScuola_Firestore):boolean{
    if((this.isImpresa || this.isPresidioSF) && this.praticaCompletata(pratica)){
      return true;
    }
    return false;
   /*  if(this.isImpresa && pratica.stato==='IN PROGETTAZIONE'){ 
      if(pratica.infoCompleted && pratica.nt9Uploaded && pratica.fotoRouterUploaded ){
        return true;
      }
    }
    return false; */
  }
  
  async scaricaVerbale(pratica: PraticaScuola_Firestore, isBulk:boolean) {
    this.logger.info("---> SCARICO VERBALE DA FIRMARE DELLA PRATICA "+pratica.codiceInfratel);
    if(!isBulk){
      this.logger.info("---> non bulk ");

      this.confirmationService.confirm({
        message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
        header: "ATTENZIONE!",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => {
          const storage = getStorage();
          const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf");
          //const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceInfratel.toUpperCase()+".pdf");
          try {
            const blob = await getBlob(pathReferenceFileVerbale);
            saveAs(blob, "Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf");
            //saveAs(blob, "Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf");
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Verbale cessione IRU NON esistente",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
          
        },
        reject: () => {
        },
      });
    }else{
      const storage = getStorage();
      const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf");
      //const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceInfratel.toUpperCase()+".pdf");

      try {
        const blob = await getBlob(pathReferenceFileVerbale);
        saveAs(blob, "Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf");
       //saveAs(blob, pratica.codiceInfratel.toUpperCase()+".pdf");
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          this.logger.info('Il file non esiste:', error.message);
          this.messageService.add({
            severity: "info",
          summary: "Errore" , life:10000,
            detail: "Verbale cessione IRU NON esistente",
          });
        } else {
          // Gestisci altri tipi di errori
          this.logger.info('Errore sconosciuto:', error);
        }
      }
      
    }
    
    

  }

  caricaPacchettoFirmato(pratica: PraticaScuola_Firestore, isRicarica:boolean): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaPacchettoFirmatoBando1, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto Firmato per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        if(!isRicarica){
          this.praticheScuolaService
          .confermaCaricamentoPacchetto(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pacchetto firmato caricato con successo",
            }); 
          });
        } 

      }
    
    });
  }


  loadMenuStatiPratica(){
    if(this.isAdmin || this.isSuperAdmin || this.isPresidioSF || this.isPresidioTIM){
      this.statiPratica.push({
        label: "CARICATA",
        style: {  'font-size': '12px'},
        command: (event) => {
          this.filtraPerStato("CARICATA");
        },
      },
      {
        label: "ANNULLATA",
        style: {  'font-size': '12px'},
        command: (event) => {
          this.filtraPerStato("ANNULLATA");
        },
      });
    }
    this.statiPratica.push({
      label: "ASSEGNATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("ASSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO IMPRESA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN CARICO IMPRESA");
      },
    });
    this.statiPratica.push({
      label: "IN PROGETTAZIONE",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN PROGETTAZIONE");
      },
    });
    this.statiPratica.push({
      label: "CONSEGNATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("CONSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO PRESIDIO",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN CARICO PRESIDIO");
      },
    });
   
    this.statiPratica.push({
      label: "IN REWORK AZIENDA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN REWORK AZIENDA");
      },
    });
   
    this.statiPratica.push({
      label: "IN VERIFICA SMARTFIBER",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN VERIFICA SMARTFIBER");
      },
    });
    this.statiPratica.push({
      label: "IN REWORK SMARTFIBER",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN REWORK SMARTFIBER");
      },
    });
    this.statiPratica.push({
      label: "IN FIRMA VERBALE",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("IN FIRMA VERBALE");
      },
    });
    this.statiPratica.push({
      label: "VERBALE FIRMATO",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("VERBALE FIRMATO");
      },
    });
    this.statiPratica.push({
      label: "FIRMATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("FIRMATA");
      },
    });
   
    this.statiPratica.push({
      label: "APPROVATA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("APPROVATA");
      },
    });
    this.statiPratica.push({
      label: "RESPINTA",
      style: {  'font-size': '12px'},
      command: (event) => {
        this.filtraPerStato("RESPINTA");
      },
    });

    this.statiPratica.push({
      label: "TUTTE",
      style: {  'font-size': '12px', 'font-weight': 'bold'},
      command: (event) => {
        this.praticheScuolaDT.clear();
      },
    });

  }
  
  caricaVerbaleFirmato(pratica: PraticaScuola_Firestore, isRicarica:boolean): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaVerbaleFirmatoBando1, {
      data: {
        pratica: praticaDaAggiornare,
        pratica_firestore: pratica,
      },
      header: "Carica Verbale Firmato della Pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        if(!isRicarica){
          this.praticheScuolaService
          .confermaCaricamentoVerbale(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO VERBALE");
          });
        }
       
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Verbale caricato con successo",
        }); 
      }
    });
  }

  openRichiediModificaCentrale(pratica: PraticaScuola_Firestore): void {
    this.logger.info("--->  RICHIEDI CAMBIO CENTRALE DELLA PRATICA " + pratica.codiceInfratel);
    
          this.ref = this.dialogService.open(DialogRichiediCambioCentraleBando1, {
            data: {
              pratica: pratica,
             
            },
            header: "Richiesta cambio centrale ",
            width: this.isMobile ? "95%" : "85%",
            height: "50%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((changeRequested) => {
            if (changeRequested && changeRequested.success) {
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Richiesta inviata con successo",
              });
            }
          });
  
  }

  loadCSVBox(){
    
    this.csvboxLoaderService.loadScript().then(() => {

      this.csvbox_importer = new CSVBoxImporter(this.csvbox_licenseKey_importa, {
        // Configuration options
      }, (result: boolean, data: any) => {
        if (result) {
          this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche importate con successo!'});
        
        } else {
          this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import delle pratiche'});
          // Custom code
        }
      });
    
      this.csvbox_importer.setUser({
        user_id: "default123"
      });
      this.csvbox_importer.language="it";

       this.csvbox_importer_aggiornaPratiche = new CSVBoxImporter(this.csvbox_licenseKey_aggiorna, {
      // Configuration options
    }, (result: boolean, data: any) => {
      if (result) {
         this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche MODIFICATE con successo!', life:10000});
      } else {
        this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di MODIFICA delle pratiche', life:10000});
        // Custom code
      }
    });

    this.csvbox_importer_aggiornaPratiche.setUser({
      user_id: "default123"
    });
    this.csvbox_importer_aggiornaPratiche.language="it";

    });
  }

  async scaricaPacchettoFirmato(pratica: PraticaScuola_Firestore){
    this.openScaricaPacchettoFirmatoPratica(pratica);
  }

  async scaricaPacchettoPratica(pratica: PraticaScuola_Firestore, isBulk:boolean){
    this.openScaricaPacchettoPratica(pratica, isBulk);
   
  }
  async openScaricaPacchettoFirmatoPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO IL PACCHETTO FIRMATO DELLA PRATICA "+pratica.codiceInfratel);
    this.confirmationService.confirm({
      message: `Confermi di voler procedere con lo scaricamento del pacchetto della pratica ${pratica.codiceInfratel}? L'operazione indicherà che la pratica è stata inoltrata al cliente!`,
      header: "Scarica pacchetto firmato",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        this.isLoadingResults = true;
        const storage = getStorage();
        const pathReferenceSignedNT9Zip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+pratica.codiceInvio+".zip.p7m");
        const pathReferenceSignedPraticaZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+pratica.codiceInfratel+".zip.p7m");
           
        let filesToDownload = [
          pathReferenceSignedNT9Zip,  
          pathReferenceSignedPraticaZip
          ];
          
        for (const pathRef of filesToDownload) {
          try {
            const blob = await getBlob(pathRef);
            if (blob) {
              this.logger.info(pathRef.name); 
              saveAs(blob, pathRef.name); 
              this.praticheScuolaService.pubblicazionePratica(pratica.id).subscribe(res=>{});
              this.isLoadingResults = false;
            } else {
              this.logger.info(`Errore durante il download di ${pathRef.name}`);
            }
          }catch(error){
            this.isLoadingResults = false;
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Il file "+ error.message+" non esiste",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
        }
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
    
  }

  loadImprese(){
    this.isLoadingResults = true;
    this.impreseService.getByBando("1").subscribe((res) => {
      this.isLoadingResults = false;

      res.forEach(impresa => {
        if(impresa.ragioneSociale!=='Admin Fake Company'){
          this.imprese.push({
            label: impresa.ragioneSociale,
            command: (event) => {
              this.praticheScuolaDT.filter(impresa.ragioneSociale, "azienda", "is");
            },
          });
        }
          
      });
      this.imprese.push({
        label: "TUTTE",
        command: (event) => {
          this.praticheScuolaDT.clear();
        },
      });
    }); 
  }
  filtraPerNome(event: any) {
    this.praticheScuolaDT.filterGlobal(event.target.value, 'contains')
  }

  filtraPerStato(stato: String) {
    this.praticheScuolaDT.filter(stato, "stato", "is");
  }

  getStatoClass(status: string): string {
    if (status === "CARICATA") {
      return "stato-pratica-caricata";
    }else if (status === "ASSEGNATA") {
      return "stato-pratica-assegnata";
    } else if (status === "IN PROGETTAZIONE") {
      return "stato-pratica-inprogettazione";
    } else if (status === "IN CARICO IMPRESA") {
      return "stato-pratica-incarico";
    } else if (status === "CONSEGNATA") {
      return "stato-pratica-consegnata";
    } else if (status === "IN REWORK AZIENDA") {
      return "stato-pratica-inrework";
    }else if (status === "IN VERIFICA SMARTFIBER") {
      return "stato-pratica-inrework-presidio";
    }else if (status === "IN FIRMA") {
      return "stato-pratica-inFirma";
    }else if (status === "IN FIRMA VERBALE") {
      return "stato-pratica-inFirmaVerbale";
    }else if (status === "VERBALE FIRMATO") {
      return "stato-pratica-verbaleFirmato";
    }else if (status === "IN FIRMA PACCHETTO") {
      return "stato-pratica-inFirmaPacchetto";
    }else if (status === "FIRMATA") {
      return "stato-pratica-firmata";
    }else if (status === "IN CARICO PRESIDIO") {
      return "stato-pratica-incarico-tim";
    }else if (status === "APPROVATA") {
      return "stato-pratica-approvata";
    }else if (status === "RESPINTA") {
      return "stato-pratica-respinta";
    }else if (status === "ANNULLATA") {
      return "stato-pratica-annullata";
    }else if (status === "PUBBLICATA") {
      return "stato-pratica-pubblicata";
    } else if (status === "IN REWORK SMARTFIBER") {
      return "stato-pratica-inrework-sf";
    }
  
    return "";
  }

  ngAfterViewInit() {}

  loadAllPraticheScuolaFromFirestoreWithFilter(){
    this.isLoadingResults = true;
    if(this.filtroStato){
      this.logger.info("---> loadAllPraticheScuolaFromFirestoreWithFilter "+ this.filtroStato);
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      let filtroStato:String[] = [];
      if(this.filtroStato==="IN CARICO SF"){
        filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER"];
      }else if(this.filtroStato==="IN LAVORAZIONE"){
        filtroStato = ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "IN REWORK AZIENDA"];
      }else if(this.filtroStato==="IN CARICO PRESIDIO"){
       // filtroStato = ["IN CARICO PRESIDIO","IN FIRMA","IN FIRMA VERBALE","IN FIRMA PACCHETTO", "FIRMATA", "CARICATA"];
       filtroStato = ["IN CARICO PRESIDIO", "FIRMATA", "PUBBLICATA"];
      }else if(this.filtroStato==="IN FIRMA VERBALE"){
        filtroStato = ["IN FIRMA VERBALE"];
      }else if(this.filtroStato==="IN FIRMA PACCHETTO"){
        filtroStato = ["IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="VERBALE FIRMATO"){
        filtroStato = ["VERBALE FIRMATO"];
      }else if(this.filtroStato==="DA ASSEGNARE"){
        filtroStato = ["CARICATA"];
      }else if(this.filtroStato==="RESPINTA"){
        filtroStato = ["RESPINTA"];
      }else if(this.filtroStato==="FIRMATA"){
        filtroStato = ["FIRMATA"];
      }else if(this.filtroStato==="PACCHETTO FIRMATO"){
        filtroStato = ["PACCHETTO FIRMATO"];
      }else if(this.filtroStato==="APPROVATA"){
        filtroStato = ["APPROVATA"];
      }else if(this.filtroStato==="IN FIRMA PACCHETTO"){
        filtroStato = ["IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="IN FIRMA VERBALE"){
        filtroStato = ["IN FIRMA VERBALE"];
      }
      else if(this.filtroStato==="IN FIRMA"){
        filtroStato = ["IN FIRMA VERBALE","VERBALE FIRMATO","IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="PUBBLICATA"){
        filtroStato = ["PUBBLICATA"];
      }

      this.logger.info(filtroStato);
      const query = collectionRef
      .where("bando", "==", "BANDO 1")
      .where("stato", "in" , filtroStato)
      .orderBy("stato");
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.logger.info(  "--> RISULTATO LOAD PRATICHE " + qs.docs.length );
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaScuola_Firestore;
            this.praticheAtFirestore.push(pratica);
            
          });
         
      });
    }else if(this.filtroPratica){
      const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
      const query = collectionRef .where("codiceInfratel", "==", this.filtroPratica) ;
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          //this.logger.info(  "--> RISULTATO LOAD PRATICHE PER ADMIN " + qs.docs.length );
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaScuola_Firestore;
            this.praticheAtFirestore.push(pratica);
            
          });
      });
    }else{
      this.logger.info("---> LOAD ALL PRATICHE MA DAL PRESIDIO TIM... "+ this.filtroStato);
    }
   
  }
  loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA PACCHETTI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
    .where("stato", "in", ["VERBALE FIRMATO","FIRMATA"])
    .where("bando", "==", "BANDO 1")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
      
    });
  }
  loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA VERBALI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where("stato", "in", ["IN FIRMA VERBALE","VERBALE FIRMATO"])
      .where("bando", "==", "BANDO 1")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
    });
  }
  loadAllPraticheScuolaFromFirestore() {
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
    .where("bando", "==", "BANDO 1")
    //.where("stato", "!=", "ANNULLATA")
    .orderBy("stato");
    onSnapshot(query, (qs) => {
        this.praticheAtFirestore = [];
        this.isLoadingResults = false;
        //this.logger.info(  "--> RISULTATO LOAD PRATICHE PER ADMIN " + qs.docs.length );
        this.numeroTotalePratiche = qs.docs.length;
        qs.forEach((doc) => {
          let pratica = doc.data() as PraticaScuola_Firestore;
          this.praticheAtFirestore.push(pratica);
          
        });
        this.praticheScuolaDT.filter("ANNULLATA", "stato", "isNot");
        if(this.filterIsSet){
          this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }
    });
    if(this.filterIsSet){
      if(this.filtroStato){
        this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
      }else if(this.filtroPratica){
        this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
      }
      
    }
  }

  openCreaPraticaDialog(){
    this.ref = this.dialogService.open(DialogCreaPraticaBando1, {
      data: {
      },
      header: "Creazione Nuova Pratica ",
      width: this.isMobile ? "95%" : "85%",
      height: "85%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((infoUpdated) => {
      if (infoUpdated && infoUpdated.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Praticha creata con successo",
        });
      }
    });
}

isPublished(pratica: PraticaScuola_Firestore): boolean {
  if (pratica.stato === "PUBBLICATA" ) {
    return true;
  }
  return false;
}

  loadPraticheScuolaPresidioTIMFromFirestore(){
    //this.logger.info(  "--> LOAD PRATICHE PER PRESIDIO TIM "  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
    //.where("stato", "in", ["IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
    .where("stato", "in", ["IN CARICO PRESIDIO", "IN FIRMA VERBALE","VERBALE FIRMATO","IN FIRMA PACCHETTO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
    .where("bando", "==", "BANDO 1")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      //this.logger.info(  "--> RISULTATO LOAD PRATICHE PER PRESIDIO " + qs.docs.length );
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
      }
      
    });
  }

  loadPraticheAziendaScuolaFromFirestore() {
    
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    const query = collectionRef
      .where(
        "idAzienda",
        "==",
        Number.parseInt(localStorage.getItem("idAzienda"))
      )
      //.where("stato", "in", ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "CONSEGNATA","IN REWORK AZIENDA","IN VERIFICA SMARTFIBER", "IN CARICO PRESIDIO","VERBALE FIRMATO", "PACCHETTO FIRMATO" ,"APPROVATA", "RESPINTA"])
      .where("stato", "in", ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "CONSEGNATA","IN REWORK AZIENDA","IN REWORK SMARTFIBER", "IN VERIFICA SMARTFIBER", "IN CARICO PRESIDIO","IN FIRMA VERBALE", "IN FIRMA PACCHETTO", "VERBALE FIRMATO", "PACCHETTO FIRMATO" ,"PUBBLICATA", "APPROVATA", "RESPINTA"])
      .where("bando", "==", "BANDO 1")
      .where("lavorabile", "==", true)
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
     
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaScuola_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          if(this.filtroStato==="CONSEGNATA"){
            let filtroStato:String[] = [];
            filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER", "IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","VERBALE FIRMATO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"];

            this.praticheScuolaDT.filter(filtroStato, "stato", "in");
          }else{
            this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
          }
         // this.praticheScuolaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
      
    });
  }

  reloadDataRemovingFilter(){
    this.location.replaceState('/pratiche-scuola-bando1');
    this.filterIsSet = false;
    this.filtroPratica = null;
    this.filtroStato = null;
    this.praticheScuolaDT.clear();
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF) {
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadAllPraticheScuolaFromFirestore();
      }
     
    } else if(this.isPresidioTIM){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
     
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      if(this.filterIsSet){
        this.loadAllPraticheScuolaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheAziendaScuolaFromFirestore();
    }

  }

  calcolaTotali() {}

  openGis2Web(pratica: PraticaScuola_Firestore){
    this.logger.info("APRO GIS 2 WEB DELLA PRATICA "+pratica.codiceMiur);
   // window.open("https://nt9convergo-viewer.maphosting.it/viewer/index.php/TimScuole/tim_"+pratica.codiceMiur.toLowerCase()+"#/map/ZOOMLAYER=Scuole&ZOOMFILTER=gid$$$EQ$$$1", '_blank');
    window.open("https://nt9convergo-cloud.maphosting.it/", '_blank');
    
  }

  openNoteReworkPratica(pratica: PraticaScuola_Firestore) {
    this.isLoadingResults = true;

    this.notePraticheService.getById(pratica.id.toString()).subscribe(note => {
      this.isLoadingResults = false;
      if(note && note.length > 0){
        this.ref = this.dialogService.open(DialogNoteReworkBando1, {
          data: {
            pratica: pratica,
            note: note
          },
          header: "Note della pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "90%",
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
      }else{
        this.messageService.add({
          severity: "info",
          summary: "Attenzione!",
          detail: "La pratica non presenta NOTE",
        });
      }
    })
  }
  openMandaInRework(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogInserisciNoteReworkBando1, {
      data: {
        pratica: pratica,
      },
      header: "Rework della pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((noteResult) => {

      if(noteResult && noteResult.success){
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica inviata all'impresa per un rework",
        });
      }
    });


  }
  openMandaAlPresidioTIM(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> openMandaAlPresidioTIM --> " + pratica.id);
    this.confirmationService.confirm({
      message: `Confermi di voler procedere con l'invio della pratica ${pratica.codiceInfratel} al PRESIDIO TIM?`,
      header: "Invia al PRESIDIO TIM",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaInviareAlPresidio: PraticaScuola = new PraticaScuola();
        praticaDaInviareAlPresidio.id = pratica.id;
        this.praticheScuolaService.inviaAlPresidio(praticaDaInviareAlPresidio).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di invio al presidio TIM della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.logger.info("---> INVIATA CON SUCCESSO AL PRESIDIO... DOVREI APRIRE POPUPS");
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica inviata con successo al presidio TIM",
            });
          },
        }); 
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openStoricoPratica(pratica: PraticaScuola_Firestore) {
    this.isLoadingResults = true;
    this.storicoPraticheService.getById(pratica.id.toString()).subscribe(storicoPratica=>{
      this.isLoadingResults = false;
        if(storicoPratica && storicoPratica.length > 0){
          this.ref = this.dialogService.open(DialogStoricoPraticaBando1, {
            data: {
              pratica: pratica,
              storicoPratica: storicoPratica
            },
            header: "Storico della pratica "+pratica.codiceInfratel,
            width: this.isMobile ? "95%" : "90%",
            height: "35%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
        }else{
          this.messageService.add({
            severity: "info",
            summary: "Attenzione!",
            detail: "La pratica non presenta STORICO",
          });
        }
    });
  }

  onPraticaSelection(pratica: PraticaScuola_Firestore) {
    if (this.selectedPratiche && this.selectedPratiche.length > 1) {
      this.disableSingleActionButtons = true;
    } else {
      this.disableSingleActionButtons = false;
    }
  }
  isDaAssegnare(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "CARICATA") {
      return true;
    }
    return false;
  }
  iAssegnata(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA") {
      return true;
    }
    return false;
  }
  isInCaricoPresidioTIM(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO PRESIDIO") {
      return true;
    }
    return false;
  }
  isDaPrendereInCarico(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA") {
      return true;
    }
    return false;
  }
  isDaProgettare(pratica:PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }
  isSigned(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "FIRMATA" ) {
      return true;
    }
    return false;
  }

  isInCarico(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }

  isInProgettazione(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN PROGETTAZIONE") {
      return true;
    }
    return false;
  }

  isDeliverable(pratica: PraticaScuola_Firestore): boolean {
    if (
      pratica.stato === "IN PROGETTAZIONE" &&
      pratica.fotoRouterUploaded &&
      pratica.nt9Uploaded &&
      pratica.infoCompleted
    ) {
      return true;
    }
    return false;
  }

  areDocsCreatable(pratica: PraticaScuola_Firestore): boolean {
    if (
      pratica.stato === "IN PROGETTAZIONE" &&
      pratica.fotoRouterUploaded &&
      pratica.infoCompleted
    ) {
      return true;
    }
    return false;
  }

  

  isDelivered(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "CONSEGNATA") {
      return true;
    }
    return false;
  }

  isInCaricoPresidioSF(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN VERIFICA SMARTFIBER") {
      return true;
    }
    return false;
  }

  isInRework(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN REWORK AZIENDA") {
      return true;
    }
    return false;
  }
  isInReworkSF(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN REWORK SMARTFIBER") {
      return true;
    }
    return false;
  }

  isSignable(pratica: PraticaScuola_Firestore): boolean {
    // && pratica.computoMetricoUploaded && pratica.cronoProgrammaUploaded
    if (pratica.stato === "IN CARICO PRESIDIO" && pratica.ordineEsecuzione) {
      return true;
    }
    return false;
  }

  isApproved(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "APPROVATA") {
      return true;
    }
    return false;
  }

  isInVerbalSignState(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN FIRMA VERBALE") {
      return true;
    }
    return false;
  }
  isInSigningPackageState(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO" ) {
      return true;
    }
    return false;
  }

  isPackageDownloadable(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO" || pratica.stato === "FIRMATA" || pratica.stato === "RESPINTA" || pratica.stato === "APPROVATA" || pratica.stato === "PUBBLICATA") {
      return true;
    }
    return false;
  }

  
  hasSignedVerbal(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO") {
      return true;
    }
    return false;
  }
  hasSignedPackage(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "FIRMATA") {
      return true;
    }
    return false;
  }

  isRejected(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "RESPINTA") {
      return true;
    }
    return false;
  }
  hasRejectionFile(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.noteRespingimentoFile &&  pratica.noteRespingimentoFile.length > 0) {
      return true;
    }
    return false;
  }
  hasReworkFile(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.noteReworkFile &&  pratica.noteReworkFile.length > 0) {
      return true;
    }
    return false;
  }

  
  
  isReworkPresidio(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "IN VERIFICA SMARTFIBER") {
      return true;
    }
    return false;
  }

  isVoidable(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA" ||  pratica.stato === "CARICATA" || pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }

 

  
 

  hasCoordinates(pratica: PraticaScuola_Firestore): boolean {
    if (pratica.coordinateScuola) {
      return true;
    }

    return false;
  }

  caricaPraticheScuola() {
    this.csvbox_importer.openModal();
  }

  loadAzioniScaricaFile() {
    this.menuScaricaFileItems = [
      {
        label: "RET",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("RET"),
      },
      {
        label: "PSI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("PSI"),
      },
      {
        label: "ALL.IRU",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("IRU"),
      },
      {
        label: "TUTTI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("ALL"),
      },
    ];
  }
  loadAzioniRisultatoInvioPratica(pratica:PraticaScuola_Firestore){
    this.menuRisultatiInvioPraticaItems = [
      {
        label: "APPROVATA",
        icon: PrimeIcons.CHECK,
        styleClass: "valid-menu-item",
        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
      },
      {
        label: "RESPINTA",
        icon: PrimeIcons.TIMES,
        styleClass: "reject-menu-item",

        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
      },
    ];
  }

  openRisultatoAzioniConsegnaPratica(pratica:PraticaScuola_Firestore, isApprovata:boolean){
    if(isApprovata){
      this.logger.info("--> PRATICA VALIDATA");
      this.isLoadingResults = true;
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.praticheScuolaService.comunicaApprovazionePratica(pratica).subscribe(res=>{
          this.isLoadingResults = false;
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Pratica aggiornata con successo",
          });
        });
      });
      
    }else {
      this.logger.info("--> PRATICA RESPINTA");
      this.isLoadingResults = true;
      this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.ref = this.dialogService.open(DialogInserisciNoteRespingimentoPraticaBando1, {
          data: {
            pratica: pratica,
          },
          header: "Respingimento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
          }
        });
        })
      
    }

  }

  loadAzioniCaricaFile(pratica:PraticaScuola_Firestore) {
    this.menuCaricaFileItems = [
      {
        label: "FOTO ROUTER",
        icon: PrimeIcons.CAMERA,
        command: () => this.openCaricaFotoRouter(pratica),
      },
     /*  {
        label: "PLANIMETRIA",
        icon: PrimeIcons.IMAGE,
        command: () => this.openCaricaPlanimetria(pratica),
      }, */
      {
        label: "NT9",
        icon: PrimeIcons.BUILDING,
        command: () => this.openCaricaPacchettoNT9(pratica),
      }
    ];
  }

  async scaricaDocs(action: string) {
    const storage = getStorage();
    if (action === "RET") {
      const pathReferenceRET = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/"+ this.selectedPraticaAtFirestore.codiceRegione + "_F_RET_01_0.docx"
      );

      const blobRET = await getBlob(pathReferenceRET);
      saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione +"_F_RET_01_0.docx");
    } else if (action === "PSI") {
      const pathReferencePSI = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/"+ this.selectedPraticaAtFirestore.codiceRegione +"_F_PSI_03_0.docx"
      );

      const blobPSI = await getBlob(pathReferencePSI);
      saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione +"_F_PSI_03_0.docx");
    } else if (action === "IRU") {
      const pathReferencePSI = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/" + this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );

      const blobPSI = await getBlob(pathReferencePSI);
      saveAs(
        blobPSI,
        this.selectedPraticaAtFirestore.codiceInfratel +".docx"
      );
    } else if (action === "ALL") {
      const pathReferenceRET = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/"+ this.selectedPraticaAtFirestore.codiceRegione +"_F_RET_01_0.docx"
      );

      const blobRET = await getBlob(pathReferenceRET);
      saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione +"_F_RET_01_0.docx");
      const pathReferencePSI = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione +"_F_PSI_03_0.docx"
      );

      const blobPSI = await getBlob(pathReferencePSI);
      saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione +"_F_PSI_03_0.docx");

      const pathReferenceAllegatoIRU = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/" +this.selectedPraticaAtFirestore.codiceInfratel +".docx"
      );

      const blobAllegatoIRU = await getBlob(pathReferenceAllegatoIRU);
      saveAs(
        blobAllegatoIRU,
        this.selectedPraticaAtFirestore.codiceInfratel +".docx"
      );
    }
  }
  openCaricaPacchettoFirmato(pratica:PraticaScuola_Firestore){
    this.logger.info("--carica pacchetto");
    /**
      1) APRIRE LA MODALE
      2) SE CARICAMENTO OK > MODIFICO DATO SU FIRESTORE E/O CHIAMO ENDPOINT
      3) CAMBIO LO STATO ?
    */
  }
  
  openMandaInFirma(pratica: PraticaScuola_Firestore) {}

  async scaricaFileRespingimentoPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO FILE RESPINGIMENTO DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const pathReferenceFileRespingimento                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteRespingimentoFile);
    const blob = await getBlob(pathReferenceFileRespingimento);
    saveAs(blob, pratica.noteRespingimentoFile);
  }

  async rigeneraDocs(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> RIGENERO TUTTA LA DOCUMENTAZIONE DELLA PRATICA "+pratica.codiceInfratel);

        this.confirmationService.confirm({
          message: `Confermi di voler RIGENERARE tutta la documentazione della pratica ${pratica.codiceInfratel} ?`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
            this.praticheScuolaService.rigeneraTuttaLaDocumentazione(pratica.id.valueOf()).subscribe(risultatoRigenerazione=>{
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Rigenerazione della documentazione della pratica "+pratica.codiceInfratel+" completata con successo",
              });
             });
          },
          reject: () => {
          },
        });
   
  }

  async scaricaFileReworkPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO FILE REWORK DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const pathReferenceFileRework                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteReworkFile);
    const blob = await getBlob(pathReferenceFileRework);
    saveAs(blob, pratica.noteReworkFile);
  }

  caricaPacchettiFirmatiInBulk(){
    this.logger.info(  "-> caricaPacchettiInBulk " );
    const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
    let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
    const query = collectionRef
      .where("bando", "==", "BANDO 1")
      .where("stato", "==", tipoFirma);
      query.get().then(qs => {
        if (qs.docs && qs.docs.length > 0) {
          this.isLoadingResults = true;
          if(this.isDirigenteFirmaPacchetti){

          
            this.ref = this.dialogService.open(DialogCaricaPacchettiFirmatiBando1, {
              data: {
              
              },
              header: "Carica Pacchetti Firmati del BANDO 1",
              width: "70%",
              height: "60%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " pacchett* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
                
                  styleClass: 'custom-toast-pacchetti',
                });
              }
              this.isLoadingResults = false;
            });
          }else{
            this.ref = this.dialogService.open(DialogCaricaVerbaliFirmatiBando1, {
              data: {
               
              },
              header: "Carica Verbali Firmati del BANDO 1",
              width: "90%",
              height: "50%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              this.isLoadingResults = false;

              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " verbal* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
                  styleClass: 'custom-toast-pacchetti',
                });
              }else{
                /* this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento pacchetti firmati!",
                }); */
              }
            });
            this.isLoadingResults = false;
          }
        }else{
          this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
        }
    });
   
    /**
     * USO ALTRO COMPONENTE
     */
  }

  scaricaPacchettiInBulk(){
    this.logger.info(  "-> scaricaPacchettiInBulk " );
    let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
   
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola).ref;
        const query = collectionRef
          .where("bando", "==", "BANDO 1")
          .where("stato", "==", tipoFirma);
          query.get().then(qs => {
            if (qs.docs && qs.docs.length > 0) {

              this.confirmationService.confirm({
                message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
                header: "ATTENZIONE!",
                icon: "pi pi-exclamation-circle",
                acceptLabel: "PROCEDI",
                acceptButtonStyleClass: "p-button-success",
                rejectLabel: "ANNULLA",
                rejectButtonStyleClass: "p-button-warning",
                accept: async () => {
                  this.isLoadingResults = true;
                  const promises: Promise<void>[] = [];

                  qs.docs.forEach(doc =>{
                    let pratica = doc.data() as PraticaScuola_Firestore;
                    if(this.isDirigenteFirmaPacchetti){
                      promises.push(this.scaricaPacchettoPratica(pratica, true));
                    }
                    else {
                      promises.push(this.scaricaVerbale(pratica, true));
                    }
                  
                  });
                  Promise.all(promises)
                  .then(() => {
                   // this.logger.info(  "-> HA FINITO! " );
                    this.isLoadingResults = false;
                  })
                  .catch(error => {
                    // Gestisci eventuali errori
                    this.logger.info("Errore durante le chiamate asincrone:", error);
                    this.isLoadingResults = false; // Assicurati di impostare isLoadingResults a false anche in caso di errore
                  });
                      
                },
                reject: () => {
                },
              });
              
              
            }else{
              this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
            }
        });
        
     
    
    
  }

  esportaPratiche(){
    //this.logger.info(this.praticheScuolaDT);
    if(this.praticheScuolaDT.filteredValue){
      this.logger.info("---> ESPORTA PRATICHE FILTRATE: " +this.praticheScuolaDT.filteredValue.length + " Vs "+this.praticheAtFirestore.length);

      this.excelService.exportPraticheBando2ToExcel(this.praticheScuolaDT.filteredValue, 'pratiche_BANDO1.xlsx');
    }else{
      this.excelService.exportPraticheBando2ToExcel(this.praticheAtFirestore, 'pratiche_BANDO1.xlsx');
    }
    
  }
  async scaricaPacchettoPraticaApprovata(pratica: PraticaScuola_Firestore){
    this.logger.info("---> SCARICO TUTTO IL PACCHETTO DELLA PRATICA APPROVATA "+pratica.codiceInfratel);
    this.isLoadingResults = true;
    const storage = getStorage();
            const zipNT9 = new JSZip();
            const zip = new JSZip();
            const pathReferenceNT9                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_03_0.pdf");
            const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/"+ pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m" );
           
            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
              ];

            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  if(pathRef.name==='nt9.zip'){
                    zipNT9.file(pratica.codiceInfratel+".zip", blob);
                  }else if( pathRef.name ===pratica.codiceInfratel+'.pdf'  ){
                    let nomeAllegato:string = pratica.codiceInfratel+".pdf";
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Verbale_SUBIRU").file(nomeAllegato, blob);
                  }else if( pathRef.name ==="Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m"){
                    //let nomeVerbale:string = "Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf.p7m";
                    let nomeVerbale:string = "Verbale_Cessione_SUBIRU_"+pratica.nomeInvio+".pdf.p7m";

                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Verbale_SUBIRU").file(nomeVerbale, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_PSI_03_0.pdf"){
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_PSI_03_0").file(pathRef.name, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_RET_01_0.pdf"){
                    zip.file(pratica.nomeInvio+"/"+pratica.codiceRegione+"_F_RET_01_0/"+pathRef.name, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
              } else {
                this.logger.error(`Errore durante il download di ${pathRef.name}`);
              }
              } catch (error) {
                this.isLoadingResults = false;
                this.logger.info(pathRef.name);
                if(pathRef.name.endsWith(".xlsx")){

                }else{
                  if (error.code === 'storage/object-not-found') {
                    this.logger.error('Il file non esiste:', error.message);
                    this.messageService.add({
                      severity: "info",
                    summary: "Errore" , life:10000,
                      detail: "Il file "+ error.message+" non esiste",
                    });
                  } else {
                    // Gestisci altri tipi di errori
                    this.logger.info('Errore sconosciuto:', error);
                  }
                }

                
              }
              
            }

            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Altro");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Documentazione_fotografica");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Istanze_Autorizzazioni");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Misure_Collaudo");
              // Crea il file zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.nomeInvio+'.zip'); // Scarica il file zip
                  
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error));
              zipNT9.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il file zip
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error));
                
            })
            .catch(error => console.error('Errore durante il download dei file:', error));
            this.isLoadingResults = false;
  }

  async openScaricaPacchettoPratica(pratica: PraticaScuola_Firestore, isBulk:boolean) {
    this.logger.info("---> SCARICO TUTTO IL PACCHETTO DELLA PRATICA "+pratica.codiceInfratel);
    if(!isBulk){
        this.confirmationService.confirm({
          message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
          header: "ATTENZIONE!",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "PROCEDI",
          acceptButtonStyleClass: "p-button-success",
          rejectLabel: "ANNULLA",
          rejectButtonStyleClass: "p-button-warning",
          accept: async () => {
            const storage = getStorage();
            const zipNT9 = new JSZip();
            const zip = new JSZip();
            const pathReferenceNT9                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_03_0.pdf");
            const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/"+ pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m" );
            const pathReferenceCMT                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.comune.toUpperCase()+"_cmt_"+pratica.nomeInvio.toUpperCase()+".xlsx");
           
            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
          //      pathReferenceCMT
              ];

            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  //this.logger.info("SCARICANDO FILE " + pathRef.name);
                  if(pathRef.name==='nt9.zip'){
                   // this.logger.info("TROVATO NT9 " + pathRef.name);

                    //zip.file(pratica.codiceInfratel+".zip", blob);
                    zipNT9.file(pratica.codiceInfratel+".zip", blob);
                  }else if( pathRef.name ===pratica.codiceInfratel+'.pdf'  ){
                    //let nomeAllegato:string = pratica.codiceInfratel+"_allegato_verb_cessione_IRU.pdf";
                    let nomeAllegato:string = pratica.codiceInfratel+".pdf";
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Verbale_SUBIRU").file(nomeAllegato, blob);
                  }else if( pathRef.name ==="Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m"){
                    //let nomeVerbale:string = "Verbale_Cessione_IRU_"+pratica.nomeInvio+".pdf.p7m";
                    let nomeVerbale:string = "Verbale_Cessione_SUBIRU_"+pratica.nomeInvio+".pdf.p7m";

                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Verbale_SUBIRU").file(nomeVerbale, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_PSI_03_0.pdf"){
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_PSI_03_0").file(pathRef.name, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_RET_01_0.pdf"){
                    zip.file(pratica.nomeInvio+"/"+pratica.codiceRegione+"_F_RET_01_0/"+pathRef.name, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
              } else {
                this.logger.error(`Errore durante il download di ${pathRef.name}`);
              }
              } catch (error) {
                this.isLoadingResults = false;
                this.logger.info(pathRef.name);
                if(pathRef.name.endsWith(".xlsx")){

                }else{
                  if (error.code === 'storage/object-not-found') {
                    this.logger.error('Il file non esiste:', error.message);
                    this.messageService.add({
                      severity: "info",
                    summary: "Errore" , life:10000,
                      detail: "Il file "+ error.message+" non esiste",
                    });
                  } else {
                    // Gestisci altri tipi di errori
                    this.logger.info('Errore sconosciuto:', error);
                  }
                }

                
              }
              
            }

            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Altro");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Documentazione_fotografica");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Istanze_Autorizzazioni");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Misure_Collaudo");
              // Crea il file zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.nomeInvio+'.zip'); // Scarica il file zip
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error));
              zipNT9.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il file zip
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error));
                
            })
            .catch(error => console.error('Errore durante il download dei file:', error));
                
          },
          reject: () => {
          },
        });
      }else{
        const storage = getStorage();
            const zip = new JSZip();
            const zipNT9 = new JSZip();
            const pathReferenceNT9                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_03_0.pdf");
            const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/"+ pratica.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m" );
            const pathReferenceCMT                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.comune.toUpperCase()+"_cmt_"+pratica.nomeInvio.toUpperCase()+".xlsx");

            let filesToDownload = [
                pathReferenceNT9, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceAllegatoIRU, 
                pathReferenceVerbaleCessioneIRU,
             //   pathReferenceCMT
              ];

            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                  //this.logger.info(pathRef.name);
                  if(pathRef.name==='nt9.zip'){
                    zip.file(pratica.codiceInfratel+".zip", blob);
                    //zipNT9.file("AB_"+pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                    //zipNT9.file(pratica.codiceInfratel+".zip", blob);
                  }else if( pathRef.name ===pratica.codiceInfratel+'.pdf' || pathRef.name ==="Verbale_Cessione_IRU_" + pratica.nomeInvio + ".pdf.p7m"){
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Verbale_SUBIRU").file(pathRef.name, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_PSI_03_0.pdf"){
                    zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_PSI_03_0").file(pathRef.name, blob);
                  }else if( pathRef.name ===pratica.codiceRegione+"_F_RET_01_0.pdf"){
                    zip.file(pratica.nomeInvio+"/"+pratica.codiceRegione+"_F_RET_01_0/"+pathRef.name, blob);
                  }else {
                    zip.file(pathRef.name, blob);
                  }
                  
                  downloadPromises.push(Promise.resolve());
              } else {
                this.logger.error(`Errore durante il download di ${pathRef.name}`);
              }
              } catch (error) {
                this.isLoadingResults = false;
                if(pathRef.name.endsWith(".xlsx")){

                }else{
                if (error.code === 'storage/object-not-found') {
                  this.logger.error('Il file non esiste:', error.message);
                  this.messageService.add({
                    severity: "info",
                  summary: "Errore" , life:10000,
                    detail: "Il file "+ error.message+" non esiste",
                  });
                } else {
                  // Gestisci altri tipi di errori
                  this.logger.info('Errore sconosciuto:', error);
                }

              }
            }              
            }

            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Altro");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Documentazione_fotografica");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Istanze_Autorizzazioni");
              zip.folder(pratica.nomeInvio+"/"+pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_07_0/Misure_Collaudo");
              // Crea il file zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  this.logger.info('STO SALVANDO IL PACCHETTO DELLA PRATICA '+ pratica.codiceInfratel+' E NOME INVIO ', pratica.nomeInvio);
                  saveAs(blob, pratica.nomeInvio+'.zip'); // Scarica il file zip
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error));
              /* zipNT9.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il file zip
                })
                .catch(error => console.error('Errore durante la creazione dello zip:', error)); */
            })
            .catch(error => console.error('Errore durante il download dei file:', error));
      }
    
  }

  openCaricaDocumentazionePratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> CARICO DOCUMENTAZIONE E SOVRASCRIVO");
  }
  async openScaricaDocumentazionePratica(
    event: any,
    pratica: PraticaScuola_Firestore
  ): Promise<void> {
    this.logger.info("---> SCARICO DOCUMENTAZIONE PRODOTTA");
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniScaricaFile();
    this.azioniScaricaDocMenu.toggle(event);
  }

  async openCaricaFilePratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.logger.info("---> CARICO FILE PRATICA");
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniCaricaFile(pratica);
    this.azioniCaricaFileMenu.toggle(event);
  }

  async loadRisultatiInvioPratica(  event: any, pratica: PraticaScuola_Firestore ): Promise<void> {
    this.logger.info("---> RISULTATI INVIO PRATICA");
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniRisultatoInvioPratica(pratica);
    this.azioniRisultatoInvioInfratel.toggle(event); 
  }


  openModificaInformazioniPratica(pratica: PraticaScuola_Firestore): void {
  }
  openMandaIndietroAlPresidioSF(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogInserisciNoteReworkBando1, {
      data: {
        pratica: pratica,
        isReworkPresidio:true,
      },
      header: "Rimanda al presidio SmartFiber la pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((noteResult) => {
      if(noteResult && noteResult.success){
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica inviata al presidio SmartFiber per essere verificata",
        });
      }
    });
  }
  
  openCompletaPraticaPresidioTIM(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> presidio completa la pratica");
    let praticaDaAggiornare: PraticaScuola;
    this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaDaDB) => {
        praticaDaAggiornare = praticaDaDB;
        this.ref = this.dialogService.open(DialogCompletaPraticaBando1, {
          data: {
            pratica: praticaDaAggiornare,
            praticaFirestore: pratica,
          },
          header: "Completamento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Praticha completata con successo",
            });
          }
        });

      });

  }
  openModificaPraticaDialog(praticaFromFirestore: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
    this.praticheScuolaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.scuoleService
          .getById(praticaDaAggiornare.scuola.id.toString())
          .subscribe((scuola) => {
            scuolaDaAggiornare = scuola;
            this.ref = this.dialogService.open(DialogModificaPraticaBando1, {
              data: {
                pratica: praticaDaAggiornare,
                praticaFromFirestore: praticaFromFirestore,
                scuola: scuolaDaAggiornare,
              },
              header: "Dettagli Pratica "+pratica.codiceInfratel,
              width: this.isMobile ? "95%" : "85%",
              height: "85%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((infoUpdated) => {
              if (infoUpdated && infoUpdated.success) {
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Praticha modificata con successo",
                });
              }
            });
          });
      });
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }


  openInserisciDettagli(praticaFromFirestore: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
    
    this.praticheScuolaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.scuoleService
          .getById(praticaDaAggiornare.scuola.id.toString())
          .subscribe((scuola) => {
            scuolaDaAggiornare = scuola;
            this.ref = this.dialogService.open(DialogSalvaInformazioniBando1, {
              data: {
                pratica: praticaDaAggiornare,
                praticaFromFirestore: praticaFromFirestore,
                scuola: scuolaDaAggiornare,
              },
              header: "Dettagli Pratica "+pratica.codiceInfratel,
              width: this.isMobile ? "95%" : "85%",
              height: "85%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((infoUpdated) => {
              if (infoUpdated && infoUpdated.success) {
              }
            });
          });
      });
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }
  openEliminaPratica(pratica: PraticaScuola_Firestore){
    this.confirmationService.confirm({
      message: `Confermi di voler ELIMINARE la pratica ${pratica.codiceInfratel}?`,
      header: "ELIMINAZIONE",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaScuola = new PraticaScuola();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheScuolaService.elimina(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di eliminazione della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica Eliminata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }
  openMappaConScuola(pratica: PraticaScuola_Firestore) {
    this.praticheScuolaService
      .getById(pratica.id.toString())
      .subscribe((praticaCompleta) => {
          this.ref = this.dialogService.open(DialogPosizioneScuola, {
            data: {
              pratica: pratica,
              praticaCompleta: praticaCompleta
            },
            header: "Posizione della scuola e della centrale assegnata",
            width: this.isMobile ? "95%" : "70%",
            height: "75%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
    });
  }
  assegnaPraticheInBulk() {
    this.logger.info(  "-> assegnaPraticheInBulk " + this.selectedPratiche.length );
    let praticheDaAssegnare:PraticaScuola[]=[];
    this.selectedPratiche.forEach(pratica =>{
      let praticaDaAssegnare: PraticaScuola = new PraticaScuola();
      praticaDaAssegnare.codiceInfratel = pratica.codiceInfratel;
      praticaDaAssegnare.id = pratica.id;
      praticheDaAssegnare.push(praticaDaAssegnare);
    });
    this.ref = this.dialogService.open(DialogAssegnaPraticaBando1, {
      data: {
        pratiche: praticheDaAssegnare,
      },
      header: "Assegna pratiche in bulk",
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratiche assegnata con successo",
        });
      }
    });
    this.selectedPratiche = [];
  }

  openAssegnaAdImpresa(pratica: PraticaScuola_Firestore) {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    this.ref = this.dialogService.open(DialogAssegnaPraticaBando1, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Assegna pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica assegnata con successo",
        });
      }
    });
  }

  openCaricaFotoRouter(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaFotoRouterBando1, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Fotografia del Router",
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { fotoRouterUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo fotoRouterUploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO foto_router_uploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoFotoRouter(
            praticaDaAggiornare,
            uploadResult.fileExtension
          )
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO FOTO ROUTER");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Fotografia del router caricata con successo",
        });
      }
    });
  }

  openCaricaPiantina(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaPiantinaBando1, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Planimetria",
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { planimetriaUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log(
              "Campo planimetriaUploaded aggiornato con successo"
            );
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO planimetriaUploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoPlanimetria(praticaDaAggiornare, uploadResult.fileExtension)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PLANIMETRIA");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Planimetria caricata con successo",
        });
      }
    });
  }

  openCaricaPacchettoNT9(pratica: PraticaScuola_Firestore): void {
    let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaNT9Bando1, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto NT9",
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_scuola);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { nt9Uploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo nt9_uploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO nt9_uploaded SU FIRESTORE"
            );
          });
        this.praticheScuolaService
          .confermaCaricamentoNT9(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO REPORT NT9");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Report NT9 caricato con successo",
        });
      }
    });
  }
  openVisualizzaDettagli(pratica: PraticaScuola_Firestore) {}
  openModificaDettagli(pratica: PraticaScuola_Firestore) {} 
  openAnnullaPratica(pratica: PraticaScuola_Firestore) {
    this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la pratica ${pratica.codiceInfratel}?`,
      header: "ANNULLAMENTO",
      icon: "pi pi-times",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaScuola = new PraticaScuola();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheScuolaService.annulla(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openPreviewDocs(pratica: PraticaScuola_Firestore){
    this.confirmationService.confirm({
      message: `Confermi di voler procedere con la GENERAZIONE ed il DOWNLOAD dei documenti della pratica ${pratica.codiceInfratel}?`,
      header: "Generazione documenti",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheScuolaService.generaDocs(praticaDaConsegnare).subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di generazione dei docs della pratica",
            });
          },
          complete: () => {
            //scarica tutto!
            this.scaricaPreviewDocsPratica(pratica);
            

            
            
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  async scaricaPreviewDocsPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> SCARICO LA PREVIEW DEI DOCS DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const zip = new JSZip();
    const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.docx");
    const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/" +pratica.codiceRegione+"_F_PSI_03_0.docx");
    const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/"+ pratica.codiceInfratel + ".docx" );
    let filesToDownload = [
        pathReferenceRET,
        pathReferencePSI,
        pathReferenceAllegatoIRU, 
      ];

    const downloadPromises = [];
    for (const pathRef of filesToDownload) {
      const blob = await getBlob(pathRef);
      if (blob) {
        this.logger.info(pathRef.name);
        zip.file(pathRef.name, blob);
        
        downloadPromises.push(Promise.resolve());
      } else {
        console.error(`Errore durante il download di ${pathRef.name}`);
      }
    }
    Promise.all(downloadPromises)
    .then(() => {
      // Crea il file zip e avvia il download:
      zip.generateAsync({ type: 'blob' })
        .then((blob) => {
          saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il file zip
          this.isLoadingResults = false;
        })
        .catch(error => console.error('Errore durante la creazione dello zip:', error));
    })
    .catch(error => console.error('Errore durante il download dei file:', error));
    
  }

  openConsegnaPratica(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> CONSEGNA PRATICA");
    this.confirmationService.confirm({
      message: `Confermi di voler procedere con la CONSEGNA  della pratica ${pratica.codiceInfratel}?`,
      header: "Consegna",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheScuolaService.consegna(praticaDaConsegnare).subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di consegna della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica consegnata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openAnnullaConsegnaPratica(pratica: PraticaScuola_Firestore): void {
    this.logger.info("---> ANNULLA CONSEGNA PRATICA");
    this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la CONSEGNA  della pratica ${pratica.codiceInfratel}?`,
      header: "Consegna",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheScuolaService.annullaConsegna(praticaDaConsegnare).subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della consegna della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Consegna della pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }
  openInCaricoPresidio(pratica: PraticaScuola_Firestore): void {
    
    this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaPrendereInCarico: PraticaScuola = new PraticaScuola();
        praticaDaPrendereInCarico.id = pratica.id;
        this.logger.info(praticaDaPrendereInCarico);
        this.praticheScuolaService
          .presidioPrendeInCarico(praticaDaPrendereInCarico)
          .subscribe({
            /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }
  openPrendiInCarico(pratica: PraticaScuola_Firestore): void {
    this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaModificare: PraticaScuola = new PraticaScuola();
        let utenteInCarico: Utente = new Utente();
        utenteInCarico.id = Number.parseInt(localStorage.getItem("idUtente"));
        praticaDaModificare.id = pratica.id;
        praticaDaModificare.utenteAssegnato = utenteInCarico;
        this.logger.info(praticaDaModificare);
        this.praticheScuolaService
          .prendiInCarico(praticaDaModificare)
          .subscribe({
            /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }

  openIniziaProgettazione(pratica: PraticaScuola_Firestore): void {
    this.logger.info("--->  INIZIA PROGETTAZIONE PRATICA");
    this.confirmationService.confirm({
      message: `Confermi di voler iniziare la progettazione della pratica ${pratica.codiceInfratel}?`,
      header: "Inizio Progettazione",
      icon: "pi pi-file-edit",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.logger.info(localStorage.getItem("idUtente"));
        this.isLoadingResults = true;
        let praticaDaModificare: PraticaScuola = new PraticaScuola();

        praticaDaModificare.id = pratica.id;
        this.logger.info(praticaDaModificare);
        this.praticheScuolaService
          .iniziaProgettazione(praticaDaModificare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di inizio progettazione della pratica",
              });
              this.isLoadingResults = false;
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Inizio progettazione avvenuto con successo",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    });
  }


  resettaPratica(pratica: PraticaScuola_Firestore) {
    this.logger.info("---> RESETTO LA PRTICA "+pratica.codiceInfratel);
    this.confirmationService.confirm({
      message: 'Confermi di voler <strong>RESETTARE</strong> la pratica <strong>' + pratica.codiceInfratel + '</strong> ?',
      header: "ATTENZIONE!",
      icon: "pi pi-refresh",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
       this.praticheScuolaService.resetta(pratica.id.valueOf()).subscribe(risultatoRigenerazione=>{
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Reset della pratica "+pratica.codiceInfratel+" avvenuto con successo",
          });
         });
      },
      reject: () => {
      },
    });
  }

  forzaStatoPratica(pratica: PraticaScuola_Firestore) {
    this.ref = this.dialogService.open(DialogModificaStatoPraticaBando1, {
      data: {
        pratica: pratica,
      },
      header: "Cambio stato della Pratica "+pratica.codiceInfratel,
      width: "55%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((statoUpdated) => {
      if (statoUpdated && statoUpdated.success) {
        this.isLoadingResults = false;
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Stato della pratica "+ pratica.codiceInfratel+" aggiornato con successo",
        });
      }
    });
  }

  openDeletePraticaDialog(pratica: PraticaScuola_Firestore): void {
    /* this.logger.info(impresa);
     this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'impresa ${impresa.ragioneSociale}?`,
      header: "Eliminazione Impresa",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.impreseService.elimina(impresa["idAzienda"]).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di eliminazione impresa",
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Impresa eliminata",
            });
            //this.loadImprese();
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        // console.log("ANNULLO");
      },
    });  */
  }
}

/* interface TipoBackup {
  id: number;
  codice: string;
} */

@Component({
  selector: "app-inserisci-informazioni-pratica-bando1",
  templateUrl: "./dialog-inserisci-informazioni-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogSalvaInformazioniBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected pagineInserisciInformazioni: MenuItem[];
  protected activeIndex: number = 0;

  protected reteAccessoForm: FormGroup;
  protected ubicazioneRouterForm: FormGroup;
  protected identificazioneFibraFormB: FormGroup;
  protected identificazioneFibraFormAC: FormGroup;
  protected localizzazioniForm: FormGroup;
  protected dettagliForm: FormGroup;
  private scuola: ScuolaDTO;
  private isTipoB:Boolean=false;
  private isTipoAC:Boolean=false;
  private isTipoBFromC:Boolean = false;
  protected ref: DynamicDialogRef;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  private tipiBackup:TipoBackup[];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private praticheScuolaService: PraticheScuolaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private firestoreService:AngularFirestore
  ) {
    this.tipiBackup = [
      { id: 1, codice: 'FO' },
      { id: 2, codice: 'LTE' },
      { id: 3, codice: 'NESSUNO' }
     
    ];
  }

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.logger.info(this.pratica);
    this.praticaFromFirestore = this.config.data.praticaFromFirestore;
    if(this.praticaFromFirestore.tipoSedeEffettiva.endsWith('C') || this.praticaFromFirestore.tipoSedeEffettiva.endsWith('A')){
      this.isTipoAC = true;
    }else if(this.praticaFromFirestore.tipoSedeEffettiva.endsWith('B') ){
      this.isTipoB = true;
      if(this.pratica.tipoSede.codice.endsWith('C')){
        this.isTipoBFromC = true;
      }

      
    }
    
    if(this.isTipoAC){
      this.logger.info("ON INIT @ DETTAGLI DI UNA PRATICA AC ");
    }else if(this.isTipoB){
      this.logger.info("ON INIT @ DETTAGLI DI UNA PRATICA B ");
      if(this.isTipoBFromC){
        this.logger.info("ON INIT @ DETTAGLI DI UNA PRATICA B FROM C ");
      }
    
    }
    this.scuola = this.config.data.scuola;
    this.inizializzaFormGroups();
    this.inizializzaSteps();
  }

  getTipoBackup(tipologia:String):number{
    switch (tipologia) {
      case "FO":
        return 0;
      case "LTE":
        return 1;
      case "NESSUNO":
        return 2;
      default:
        return null;
    }
  }

  inizializzaFormGroups() {
   /*  this.reteAccessoForm = this._formBuilder.group({
      tipologia: [this.scuola.tipologia, [Validators.required]],
      diritto: [this.scuola.proprietario, [Validators.required]],
      proprietario: [this.scuola.proprietario, [Validators.required]],
      numeroAccessPoints: [
        this.scuola.numeroAccessPoint,
        [Validators.required],
      ],
    }); */
    this.dettagliForm = this._formBuilder.group({
      annoAttivazione:      [ this.pratica.annoAttivazione, [Validators.required]],
      hostnameCPE:      [ this.scuola.hostnameCpe, [Validators.required]],
      ipAddress:        [ this.scuola.ipAddress, [Validators.required]],
      localeTecnico:    [ this.scuola.localeTecnicoNome, [Validators.required]],
      piano:            [ this.scuola.localeTecnicoPiano, [Validators.required]],
      stanza:           [ this.scuola.localeTecnicoStanza, [Validators.required]],
      redattore:        [ this.pratica.redattore, [Validators.required]],
      verificatore:     [ this.pratica.verificatore, [Validators.required]],
   //   backup:           [ this.pratica.backup, [Validators.required]],
    //  wifi:             [ this.pratica.wifi, [Validators.required]],

    });
    if(!this.isTipoAC){
      this.logger.info("inizializzaFormGroups DI UNA PRATICA B "+ this.pratica.tipoBSharp + " con tipo bu "+ this.scuola.tipoBackup);
      this.identificazioneFibraFormB = this._formBuilder.group({
        clliSedeA:                  [ this.scuola.clliSedeA, [Validators.required]],
        tipologiaSedeA:             [ this.scuola.tipologiaSedeA, [Validators.required]],
        descrizioneSedeA:           [ this.scuola.descrizioneSedeA, [Validators.required]],
        attestazioneAlRipartitoreA: [ this.scuola.attestazioneRipartitoreA,  [Validators.required], ],
  
        clliSedeZ:                  new FormControl({value: this.pratica.nomeCentrale, disabled: true}, Validators.required),
        tipologiaSedeZ:             [ this.scuola.tipologiaSedeZ, [Validators.required]],
        descrizioneSedeZ:           [ this.scuola.descrizioneSedeZ, [Validators.required]],
        attestazioneAlRipartitoreZ: [ this.scuola.attestazioneRipartitoreZ,[Validators.required], ],
  
        numeroTotaleCoppie: [ this.scuola.numeroTotaleCoppie, [Validators.required],],
        numeroCoppieInUso:  [ this.scuola.numeroCoppieInUso, [Validators.required]],
        fibra:              [ this.scuola.numeroFibra, [Validators.required]],
        lunghezza:         [ this.scuola.lunghezza, [Validators.required]],
        tipoBackup: [ this.scuola.tipoBackup?this.tipiBackup[this.getTipoBackup(this.scuola.tipoBackup)]:this.tipiBackup[this.getTipoBackup("NESSUNO")], [Validators.required]],
        tipoBSharp:       [ this.pratica.tipoBSharp?this.pratica.tipoBSharp:false, [Validators.required]],});
        
      /* if(!this.isTipoB){
        this.identificazioneFibraFormAB = this._formBuilder.group({
          clliSedeA:                  [ this.scuola.clliSedeA, [Validators.required]],
          tipologiaSedeA:             [ this.scuola.tipologiaSedeA, [Validators.required]],
          descrizioneSedeA:           [ this.scuola.descrizioneSedeA, [Validators.required]],
          attestazioneAlRipartitoreA: [ this.scuola.attestazioneRipartitoreA,  [Validators.required], ],
    
          clliSedeZ:                  new FormControl({value: this.scuola.clliSedeZ?this.scuola.clliSedeZ:this.pratica.nomeCentrale, disabled: true}, Validators.required),
          tipologiaSedeZ:             [ this.scuola.tipologiaSedeZ, [Validators.required]],
          descrizioneSedeZ:           [ this.scuola.descrizioneSedeZ, [Validators.required]],
          attestazioneAlRipartitoreZ: [ this.scuola.attestazioneRipartitoreZ,[Validators.required], ],
    
          numeroTotaleCoppie: [ this.scuola.numeroTotaleCoppie, [Validators.required],],
          numeroCoppieInUso:  [ this.scuola.numeroCoppieInUso, [Validators.required]],
          fibra:              [ this.scuola.numeroFibra, [Validators.required]],
          });
      }else{
        this.identificazioneFibraFormAB = this._formBuilder.group({
          clliSedeA:                  [ this.scuola.clliSedeA, [Validators.required]],
          tipologiaSedeA:             [ this.scuola.tipologiaSedeA, [Validators.required]],
          descrizioneSedeA:           [ this.scuola.descrizioneSedeA, [Validators.required]],
          attestazioneAlRipartitoreA: [ this.scuola.attestazioneRipartitoreA,  [Validators.required], ],
    
          clliSedeZ:                  new FormControl({value: this.scuola.clliSedeZ?this.scuola.clliSedeZ:this.pratica.nomeCentrale, disabled: true}, Validators.required),
          tipologiaSedeZ:             [ this.scuola.tipologiaSedeZ, [Validators.required]],
          descrizioneSedeZ:           [ this.scuola.descrizioneSedeZ, [Validators.required]],
          attestazioneAlRipartitoreZ: [ this.scuola.attestazioneRipartitoreZ,[Validators.required], ],
    
          numeroTotaleCoppie: [ this.scuola.numeroTotaleCoppie, [Validators.required],],
          numeroCoppieInUso:  [ this.scuola.numeroCoppieInUso, [Validators.required]],
          fibra:              [ this.scuola.numeroFibra, [Validators.required]],
          lunghezza:          [ this.scuola.lunghezza, [Validators.required]],});
      } */
     
      
    }else {
      this.logger.info("inizializzaFormGroups DI UNA PRATICA AC con tipo bu "+ this.scuola.tipoBackup);
      this.identificazioneFibraFormAC = this._formBuilder.group({
       
        attestazioneAlRipartitoreA: [ this.scuola.attestazioneRipartitoreA,  [Validators.required], ],
        attestazioneAlRipartitoreZ: [ this.scuola.attestazioneRipartitoreZ,[Validators.required], ],
        fibra:                      [ this.scuola.numeroFibra, [Validators.required]],
        clliSedeZ:                  new FormControl({value: this.scuola.clliSedeZ?this.scuola.clliSedeZ:this.pratica.nomeCentrale, disabled: true}, Validators.required),
        idPCN:                      [ this.pratica.pcn?this.pratica.pcn.codice:null,         [Validators.required]],
        indirizzoPCN:               [ this.pratica.pcn?this.pratica.pcn.indirizzo:null,         [Validators.required]],
        nomePCN :                   [ this.pratica.pcn?this.pratica.pcn.nome:null,       [Validators.required]],
        latitudinePCN :             [ this.pratica.pcn?this.pratica.pcn.latitudine:null, [Validators.required]],
        longitudinePCN:             [ this.pratica.pcn?this.pratica.pcn.longitudine:null,[Validators.required]],
        lunghezza:          [ this.scuola.lunghezza, [Validators.required]],
        tipoBackup: [ this.scuola.tipoBackup?this.tipiBackup[this.getTipoBackup(this.scuola.tipoBackup)]:this.tipiBackup[this.getTipoBackup("NESSUNO")], [Validators.required]],
      });
    }
   
    
    this.localizzazioniForm = this._formBuilder.group({
      indirizzoScuola:      [ this.scuola.indirizzo, [Validators.required]],
      comuneScuola:         [ this.scuola.comune.denominazione, [Validators.required]],
      latitudineScuola:     [ this.praticaFromFirestore.coordinateScuola?this.praticaFromFirestore.coordinateScuola.latitude:null, [Validators.required]],
      longitudineScuola:    [ this.praticaFromFirestore.coordinateScuola? this.praticaFromFirestore.coordinateScuola.longitude:null, [Validators.required]],
     
       indirizzoCentrale: new FormControl({value: this.pratica.indirizzoCentrale, disabled: true}, Validators.required),
       latitudineCentrale:  new FormControl({value: this.praticaFromFirestore.coordinateCentrale.latitude, disabled: true}, Validators.required),
       longitudineCentrale:  new FormControl({value: this.praticaFromFirestore.coordinateCentrale.longitude, disabled: true}, Validators.required),
      //comuneCentrale:       [ this.pratica.comuneCentrale,[Validators.required]],
      comuneCentrale:       new FormControl({value: this.pratica.comuneCentrale, disabled: true}, Validators.required),
     /*  indirizzoCentrale:    [ this.pratica.indirizzoCentrale,[Validators.required]],
      latitudineCentrale:   [ this.praticaFromFirestore.coordinateCentrale?this.praticaFromFirestore.coordinateCentrale.latitude:null,[Validators.required],],
      longitudineCentrale:  [ this.praticaFromFirestore.coordinateCentrale?this.praticaFromFirestore.coordinateCentrale.longitude:null,[Validators.required],  ],
      */
    });
    
  }
  inizializzaSteps() {
    this.pagineInserisciInformazioni = [
      /* 
      {
        label: "Router",
      },
      */
      {
        label: "Dettagli",
      },
      
      {
        label: "Identificazione Fibra",
      },
      {
        label: "Localizzazioni",
      }/* , 
      {
        label: "Dettagli",
      }, */
    ];
    this.activeIndex = 0;
  }

  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineInserisciInformazioni.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 1 && !this.dettagliForm.valid) {
      disableNextStepButton = true;
    }/*  else if (this.activeIndex === 1 && !this.ubicazioneRouterForm.valid) {
      disableNextStepButton = true;
    } */ else if (this.activeIndex === 0 ) {
      if(!this.isTipoAC && !this.identificazioneFibraFormB.valid ){
        disableNextStepButton = true;
      }else if(this.isTipoAC && !this.identificazioneFibraFormAC.valid){
        disableNextStepButton = true;
      }
      
    } else if (this.activeIndex === 2 && !this.localizzazioniForm.valid) {
      disableNextStepButton = true;
    } /* else if (this.activeIndex === 3 && !this.dettagliForm.valid) {
      disableNextStepButton = true;
    } */

    return disableNextStepButton;
  }

  changePage() {
    this.activeIndex = this.activeIndex + 1;
  }
  openMappaScuola(){
    this.logger.info("---OPENMAPPA SCUOLA----");
    this.ref = this.dialogService.open(DialogModificaPosizioneBando1, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:true,
      },
      header: "Posizione della scuola",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.localizzazioniForm.get("latitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.latitude);
        this.localizzazioniForm.get("longitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.longitude);
      });
    });
  }
  openMappaCentrale(){
    this.logger.info("---OPENMAPPA CENTRALE----");
    this.ref = this.dialogService.open(DialogModificaPosizioneBando1, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:false,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.localizzazioniForm.get("latitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.latitude);
        this.localizzazioniForm.get("longitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.longitude);
      });
    });
  }

  openRichiediAggiornamentoDatiCentrale(){
    //todo
    let centrale:Centrale = new Centrale();
    let comune:ComuneDTO = new ComuneDTO();
    comune.denominazione = this.pratica.comuneCentrale;
    centrale.clli = this.pratica.idCentrale;
    centrale.indirizzo = this.pratica.indirizzoCentrale;
    centrale.comune = comune;
    centrale.latitudine = this.pratica.latitudineCentrale;
    centrale.longitudine = this.pratica.longitudineCentrale;

      this.ref = this.dialogService.open(DialogRichiediAggiornamentoCentrale, {
        data: {
          centrale: centrale,
          impresa:this.pratica.azienda.ragioneSociale,
    },
      header: 'Richiedi aggiornamento Centrale ' + this.pratica.idCentrale.toUpperCase(),//+impresa.ragioneSociale.toUpperCase(),
      width: '50%',
      height: '70%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
    });
    this.ref.onClose.subscribe(confirmModificaCliente => {

    });
}
validateYearInput(event: Event): void {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  // Rimuove qualsiasi carattere che non sia un numero
  value = value.replace(/[^0-9]/g, '');

  // Limita il valore a 4 cifre
  if (value.length > 4) {
    value = value.slice(0, 4);
  }

  input.value = value;
  this.dettagliForm.get('annoAttivazione')?.setValue(value);
}

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let dettaglioPraticaDTO: DettaglioPraticaScuolaDTO = new DettaglioPraticaScuolaDTO();
      dettaglioPraticaDTO.id = this.pratica.id;
      dettaglioPraticaDTO.codiceInfratel = this.pratica.codiceInfratel;

      //dettaglioPraticaDTO.tipologia = this.reteAccessoForm.value.tipologia;
      //dettaglioPraticaDTO.diritto = this.reteAccessoForm.value.diritto;
      //dettaglioPraticaDTO.proprietario =this.reteAccessoForm.value.proprietario;
      //dettaglioPraticaDTO.numeroAccessPoints =this.reteAccessoForm.value.numeroAccessPoints;
      

      dettaglioPraticaDTO.hostnameCPE                 = this.dettagliForm.value.hostnameCPE;
      dettaglioPraticaDTO.annoAttivazione             = this.dettagliForm.value.annoAttivazione;
      dettaglioPraticaDTO.ipAddress                   = this.dettagliForm.value.ipAddress;

      dettaglioPraticaDTO.localeTecnico               = this.dettagliForm.value.localeTecnico;
      dettaglioPraticaDTO.piano                       = this.dettagliForm.value.piano;
      dettaglioPraticaDTO.stanza                      = this.dettagliForm.value.stanza;
      dettaglioPraticaDTO.redattore                   = this.dettagliForm.value.redattore;
      dettaglioPraticaDTO.verificatore                = this.dettagliForm.value.verificatore;
      dettaglioPraticaDTO.wifi                        = this.dettagliForm.value.wifi;
      //dettaglioPraticaDTO.backup                      = this.dettagliForm.value.backup;

      if(!this.isTipoAC){
        this.logger.info("CONFERMO TIPO B");
        dettaglioPraticaDTO.clliSedeA                   = this.identificazioneFibraFormB.value.clliSedeA;
        dettaglioPraticaDTO.tipologiaSedeA              =  this.identificazioneFibraFormB.value.tipologiaSedeA;
        dettaglioPraticaDTO.descrizioneSedeA            =  this.identificazioneFibraFormB.value.descrizioneSedeA;
        dettaglioPraticaDTO.attestazioneAlRipartitoreA  =  this.identificazioneFibraFormB.value.attestazioneAlRipartitoreA;
        
        dettaglioPraticaDTO.clliSedeZ                   =  this.identificazioneFibraFormB.value.clliSedeZ;
        dettaglioPraticaDTO.tipologiaSedeZ              = this.identificazioneFibraFormB.value.tipologiaSedeZ;
        dettaglioPraticaDTO.descrizioneSedeZ            = this.identificazioneFibraFormB.value.descrizioneSedeZ;
        dettaglioPraticaDTO.attestazioneAlRipartitoreZ  = this.identificazioneFibraFormB.value.attestazioneAlRipartitoreZ;

        dettaglioPraticaDTO.numeroTotaleCoppie          = this.identificazioneFibraFormB.value.numeroTotaleCoppie;
        dettaglioPraticaDTO.numeroCoppieInUso           = this.identificazioneFibraFormB.value.numeroCoppieInUso;
        dettaglioPraticaDTO.fibra                       = this.identificazioneFibraFormB.value.fibra;
        //if(this.isTipoB){
        dettaglioPraticaDTO.lunghezza                   = this.identificazioneFibraFormB.value.lunghezza;
        if(this.identificazioneFibraFormB.value.tipoBackup && this.identificazioneFibraFormB.value.tipoBackup.codice!="NESSUNO"){
          dettaglioPraticaDTO.tipoBackup      = this.identificazioneFibraFormB.value.tipoBackup.codice;
          dettaglioPraticaDTO.backup          = "true"; 
        }else{
          dettaglioPraticaDTO.tipoBackup      = "";
          dettaglioPraticaDTO.backup          = "false";
        }
        if(this.isTipoBFromC){
          dettaglioPraticaDTO.tipoBSharp  = this.identificazioneFibraFormB.value.tipoBSharp;
        }
       // }
        //
      }else{
        this.logger.info("CONFERMO TIPO AC");
        dettaglioPraticaDTO.attestazioneAlRipartitoreA  =  this.identificazioneFibraFormAC.value.attestazioneAlRipartitoreA;
        dettaglioPraticaDTO.attestazioneAlRipartitoreZ  = this.identificazioneFibraFormAC.value.attestazioneAlRipartitoreZ;
        dettaglioPraticaDTO.fibra                       = this.identificazioneFibraFormAC.value.fibra;
        dettaglioPraticaDTO.clliSedeZ                   =  this.identificazioneFibraFormAC.value.clliSedeZ;
        dettaglioPraticaDTO.idPCN                       = this.identificazioneFibraFormAC.value.idPCN;
        dettaglioPraticaDTO.indirizzoPCN                = this.identificazioneFibraFormAC.value.indirizzoPCN;
        dettaglioPraticaDTO.nomePCN                     = this.identificazioneFibraFormAC.value.nomePCN;
        dettaglioPraticaDTO.latitudinePCN               = this.identificazioneFibraFormAC.value.latitudinePCN;
        dettaglioPraticaDTO.longitudinePCN              = this.identificazioneFibraFormAC.value.longitudinePCN;
        dettaglioPraticaDTO.lunghezza                   = this.identificazioneFibraFormAC.value.lunghezza;
        if(this.identificazioneFibraFormAC.value.tipoBackup && this.identificazioneFibraFormAC.value.tipoBackup.codice!="NESSUNO"){
          dettaglioPraticaDTO.tipoBackup      = this.identificazioneFibraFormAC.value.tipoBackup.codice;
          dettaglioPraticaDTO.backup          = "true"; 
        }else{
          dettaglioPraticaDTO.tipoBackup      = "";
          dettaglioPraticaDTO.backup          = "false";
        }
      }
      
      

      dettaglioPraticaDTO.indirizzoScuola   = this.localizzazioniForm.value.indirizzoScuola;
      dettaglioPraticaDTO.comuneScuola      = this.localizzazioniForm.value.comuneScuola;
      dettaglioPraticaDTO.latitudineScuola  =  this.localizzazioniForm.value.latitudineScuola;
      dettaglioPraticaDTO.longitudineScuola =  this.localizzazioniForm.value.longitudineScuola;

      dettaglioPraticaDTO.indirizzoCentrale =  this.localizzazioniForm.value.indirizzoCentrale;
      dettaglioPraticaDTO.comuneCentrale    = this.localizzazioniForm.value.comuneCentrale;
      dettaglioPraticaDTO.latitudineCentrale = this.localizzazioniForm.value.latitudineCentrale;
      dettaglioPraticaDTO.longitudineCentrale =  this.localizzazioniForm.value.longitudineCentrale;
     
      this.logger.info(dettaglioPraticaDTO);

      this.praticheScuolaService
        .caricaDettagliPratica(dettaglioPraticaDTO)
        .subscribe({
          /* this.impreseService.elimina(impresa["idAzienda"]).subscribe({*/
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di AGGIORNAMENTO DELLE INFORMAZIONI FASE 3 della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            this.dialogRef.close({ success: true });
             this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "INFORMAZIONI FASE 3 della pratica aggiornate con successo",
              }); 
          },
        });

      /*   
            res =>{
            this.logger.info("INVIO DETTAGLIO PRATICA OK!");
            this.dialogRef.close({ success: true});
          }); */
    }
  }
}

interface TipoSede {
  id: number;
  codice: string;
}

interface TipoBackup {
  id: number;
  codice: string;
}


@Component({
  selector: "app-modifica-pratica-bando1",
  templateUrl: "./dialog-modifica-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogModificaPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  private tipiSede:TipoSede[];
  private tipiBackup:TipoBackup[];

  protected pagineModificaPratica: MenuItem[];
  protected activeIndex: number = 0;

  protected dettagliForm: FormGroup;
  protected scuolaForm: FormGroup;
  protected centraleForm: FormGroup;

  protected localizzazioniForm: FormGroup;
  private scuola: ScuolaDTO;

  protected ref: DynamicDialogRef;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  protected comuni:ComuneDTO[]=[];
    protected aziende:Impresa[]=[];
    protected filteredComuni: any[] =[];  
    protected filteredAziende: any[] =[];  
    protected comuniLoaded:boolean=false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private praticheScuolaService: PraticheScuolaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private firestoreService:AngularFirestore,
    private comuniService: ComuniService,
    private aziendeService:ImpreseService,
  ) {

    this.tipiSede = [
      { id: 7, codice: 'Mandatoria A' },
      { id: 8, codice: 'Mandatoria B' },
      { id: 9, codice: 'Mandatoria C' },
      { id: 10, codice: 'Mandatoria D' },
      { id: 11, codice: 'Aggiuntiva A' },
      { id: 12, codice: 'Aggiuntiva B' },
      { id: 13, codice: 'Aggiuntiva C' },
      { id: 14, codice: 'Aggiuntiva D' }
    ];

    this.tipiBackup = [
      { id: 1, codice: 'FO' },
      { id: 2, codice: 'LTE' },
      { id: 3, codice: 'NESSUNO' }
     
    ];
  }

  getTipoBackup(tipologia:String):number{
    switch (tipologia) {
      case "FO":
        return 0;
      case "LTE":
        return 1;
      case "NESSUNO":
        return 2;
      default:
        return null;
    }
  }

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.praticaFromFirestore = this.config.data.praticaFromFirestore;
   
    this.scuola = this.config.data.scuola;
    this.loadComuni();
      this.loadImprese();
    this.inizializzaFormGroups();
    this.inizializzaSteps();
  }

  inizializzaFormGroups() {
    this.dettagliForm = this._formBuilder.group({
      codiceInvio: [this.pratica.codiceInvio],
      codiceInfratel: new FormControl({value: this.pratica.codiceInfratel, disabled: true}, Validators.required),
      tipoSede: [this.pratica.tipoSede, [Validators.required]],
      tipoSedeEffettiva: [this.pratica.tipoSedeEffettiva, [Validators.required]],
      //colocata: [null, [Validators.required]],
      azienda: [this.pratica.azienda?this.pratica.azienda:null],
      tipoBackup: [ this.scuola.tipoBackup?this.tipiBackup[this.getTipoBackup(this.scuola.tipoBackup)]:this.tipiBackup[this.getTipoBackup("NESSUNO")], [Validators.required]],
      wifi: [this.scuola.wifi?true:false, [Validators.required]], 
      lavorabile: [this.pratica.lavorabile, [Validators.required]],

    });
    let comuneCentrale:ComuneDTO = new ComuneDTO();
    comuneCentrale.denominazione = this.pratica.comuneCentrale;

    this.scuolaForm = this._formBuilder.group({
      denominazioneScuola: [this.scuola.denominazione],
      codiceMiur: [this.scuola.codiceMiur, [Validators.required]],
      indirizzoScuola: [this.scuola.indirizzo, [Validators.required]],
      comuneScuola: [this.scuola.comune, [Validators.required]],
      latitudineScuola: [this.praticaFromFirestore.coordinateScuola.latitude, [Validators.required]],
      longitudineScuola: [this.praticaFromFirestore.coordinateScuola.longitude, [Validators.required]],
      
    });
    
    this.centraleForm = this._formBuilder.group({
      idCentrale: [
        this.pratica.idCentrale,
        [Validators.required],
      ],
      nomeCentrale: [
        this.pratica.nomeCentrale,
        [Validators.required],
      ],
      indirizzoCentrale: [
        this.pratica.indirizzoCentrale,
        [Validators.required],
      ],
      comuneCentrale: [comuneCentrale, [Validators.required]],
      latitudineCentrale: [
        this.praticaFromFirestore.coordinateCentrale?this.praticaFromFirestore.coordinateCentrale.latitude:null,
        [Validators.required],
      ],
      longitudineCentrale: [
        this.praticaFromFirestore.coordinateCentrale?this.praticaFromFirestore.coordinateCentrale.longitude:null,
        [Validators.required],
      ],
    })
  }
  inizializzaSteps() {
    this.pagineModificaPratica = [
      {
        label: "Dettagli",
      },
      {
        label: "Scuola",
      },
      {
        label: "Centrale",
      }
    ];
    this.activeIndex = 0;
  }

  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineModificaPratica.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 0 && !this.dettagliForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 1 && !this.scuolaForm.valid) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 2 && !this.centraleForm.valid) {
      disableNextStepButton = true;
    } 

    return disableNextStepButton;
  }

  changePage() {
    this.activeIndex = this.activeIndex + 1;
  }
  openMappaScuola(){
    this.logger.info("---OPENMAPPA SCUOLA----");
    this.ref = this.dialogService.open(DialogModificaPosizioneBando1, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:true,
      },
      header: "Posizione della scuola",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.scuolaForm.get("latitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.latitude);
        this.scuolaForm.get("longitudineScuola").setValue(this.praticaFromFirestore.coordinateScuola.longitude);
      });
    });
  }
  openMappaCentrale(){
    this.logger.info("---OPENMAPPA CENTRALE----");
    this.ref = this.dialogService.open(DialogModificaPosizioneBando1, {
      data: {
        pratica: this.praticaFromFirestore,
        isScuola:false,
      },
      header: "Posizione della centrale",
      width: "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((changeResult) => {
      this.logger.info("CHIUDO MAPPA "+ changeResult);
      const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
      const documentRef   = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
      documentRef.get().subscribe(res => {
        this.praticaFromFirestore =  res.data() as PraticaScuola_Firestore;
        this.centraleForm.get("latitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.latitude);
        this.centraleForm.get("longitudineCentrale").setValue(this.praticaFromFirestore.coordinateCentrale.longitude);
      });
    });
  }
  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      //this.filteredComuni = res;
      this.comuniLoaded = true;
    });
  }
  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
        let comune = this.comuni[i];
        if (comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredComuni = filtered;
  }
    loadImprese(){
    this.aziendeService.getByBando("1").subscribe(aziende =>{
        this.aziende = aziende;
    });
    }
    filterAzienda(event: AutoCompleteCompleteEvent) {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < this.aziende.length; i++) {
            let azienda = this.aziende[i];
            if (azienda.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(azienda);
            }
        }
        this.filteredAziende = filtered;
    }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let praticaDaModificare: ModificaPraticaDTO = new ModificaPraticaDTO();
      praticaDaModificare.id                  = this.pratica.id;
      praticaDaModificare.codiceInvio         = this.dettagliForm.value.codiceInvio;
      praticaDaModificare.tipoSede            = this.dettagliForm.value.tipoSede;
      praticaDaModificare.tipoSedeEffettiva   = this.dettagliForm.value.tipoSedeEffettiva;
      praticaDaModificare.lavorabile          = this.dettagliForm.value.lavorabile;
      praticaDaModificare.wifi                = this.dettagliForm.value.wifi;
     
      if(this.dettagliForm.value.tipoBackup && this.dettagliForm.value.tipoBackup!="NO"){
        praticaDaModificare.tipoBackup      = this.dettagliForm.value.tipoBackup.codice;
        praticaDaModificare.backup          = true; 
      }else{
        praticaDaModificare.tipoBackup      = "";
        praticaDaModificare.backup          = false;
      }
      praticaDaModificare.idScuola            = this.scuola.id;
      praticaDaModificare.denominazioneScuola = this.scuolaForm.value.denominazioneScuola;
      praticaDaModificare.indirizzoScuola     = this.scuolaForm.value.indirizzoScuola;
      praticaDaModificare.codiceMiur          = this.scuolaForm.value.codiceMiur;
      praticaDaModificare.comuneScuola        = this.scuolaForm.value.comuneScuola.denominazione;
      praticaDaModificare.latitudineScuola    = this.scuolaForm.value.latitudineScuola;
      praticaDaModificare.longitudineScuola   = this.scuolaForm.value.longitudineScuola;
   
      praticaDaModificare.idCentrale          = this.centraleForm.value.idCentrale;
      praticaDaModificare.nomeCentrale        = this.centraleForm.value.nomeCentrale;
      praticaDaModificare.indirizzoCentrale   = this.centraleForm.value.indirizzoCentrale;
      praticaDaModificare.comuneCentrale      = this.centraleForm.value.comuneCentrale.denominazione;
      praticaDaModificare.latitudineCentrale  = this.centraleForm.value.latitudineCentrale;
      praticaDaModificare.longitudineCentrale = this.centraleForm.value.longitudineCentrale;

      this.logger.info(praticaDaModificare);
      this.praticheScuolaService
        .modifica(praticaDaModificare)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di MODIFICA della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            this.logger.info("MODIFICA PRATICA OK!");
            this.dialogRef.close({ success: true });
          
          },
        });

      /* dettaglioPraticaDTO.codiceInfratel = this.pratica.codiceInfratel;

      dettaglioPraticaDTO.tipologia = this.reteAccessoForm.value.tipologia;
      dettaglioPraticaDTO.diritto = this.reteAccessoForm.value.diritto;
      dettaglioPraticaDTO.proprietario =
        this.reteAccessoForm.value.proprietario;
      dettaglioPraticaDTO.numeroAccessPoints =
        this.reteAccessoForm.value.numeroAccessPoints;
      dettaglioPraticaDTO.lunghezza = this.reteAccessoForm.value.lunghezza;

      dettaglioPraticaDTO.hostnameCPE =
        this.ubicazioneRouterForm.value.hostnameCPE;
      dettaglioPraticaDTO.localeTecnico =
        this.ubicazioneRouterForm.value.localeTecnico;
      dettaglioPraticaDTO.piano = this.ubicazioneRouterForm.value.piano;
      dettaglioPraticaDTO.stanza = this.ubicazioneRouterForm.value.stanza;

      dettaglioPraticaDTO.clliSedeA =
        this.identificazioneFibraForm.value.clliSedeA;
      dettaglioPraticaDTO.tipologiaSedeA =
        this.identificazioneFibraForm.value.tipologiaSedeA;
      dettaglioPraticaDTO.descrizioneSedeA =
        this.identificazioneFibraForm.value.descrizioneSedeA;
      dettaglioPraticaDTO.attestazioneAlRipartitoreA =
        this.identificazioneFibraForm.value.attestazioneAlRipartitoreA;

      dettaglioPraticaDTO.clliSedeZ =
        this.identificazioneFibraForm.value.clliSedeZ;
      dettaglioPraticaDTO.tipologiaSedeZ =
        this.identificazioneFibraForm.value.tipologiaSedeZ;
      dettaglioPraticaDTO.descrizioneSedeZ =
        this.identificazioneFibraForm.value.descrizioneSedeZ;
      dettaglioPraticaDTO.attestazioneAlRipartitoreZ =
        this.identificazioneFibraForm.value.attestazioneAlRipartitoreZ;

      dettaglioPraticaDTO.numeroTotaleCoppie =
        this.identificazioneFibraForm.value.numeroTotaleCoppie;
      dettaglioPraticaDTO.numeroCoppieInUso =
        this.identificazioneFibraForm.value.numeroCoppieInUso;
      dettaglioPraticaDTO.fibra = this.identificazioneFibraForm.value.fibra;

      dettaglioPraticaDTO.indirizzoScuola =
        this.localizzazioniForm.value.indirizzoScuola;
      dettaglioPraticaDTO.latitudineScuola =
        this.localizzazioniForm.value.latitudineScuola;
      dettaglioPraticaDTO.longitudineScuola =
        this.localizzazioniForm.value.longitudineScuola;
      dettaglioPraticaDTO.indirizzoCentrale =
        this.localizzazioniForm.value.indirizzoCentrale;
      dettaglioPraticaDTO.latitudineCentrale =
        this.localizzazioniForm.value.latitudineCentrale;
      dettaglioPraticaDTO.longitudineCentrale =
        this.localizzazioniForm.value.longitudineCentrale;

      dettaglioPraticaDTO.redattore = this.dettagliForm.value.redattore;
      dettaglioPraticaDTO.verificatore = this.dettagliForm.value.verificatore; */

     /*   */

     
    }
  }
}

@Component({
  selector: "app-fotoRouter-bando1",
  templateUrl: "./dialog-carica-fotoRouter.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaFotoRouterBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "router." + fileExtension;
      this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento file!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

import { format } from 'date-fns';

@Component({
  selector: "app-storico-pratica-bando1",
  templateUrl: "./dialog-storico-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogStoricoPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola_Firestore;
  public storico: StoricoPraticaScuola[]=[];

  public dataAssegnazione:Date;
  public dataInizioInCarico:Date;
  public dataInizioProgettazione:Date;
  public dataConsegna:Date;
  public dataRework:Date;

  public isAssegnata:boolean=false;
  public isInCarico:boolean=false;
  public isAnnullata:boolean=false;
  public isInProgettazione:boolean=false;
  public isConsegnata:boolean=false;
  public isInRework:boolean=false;
  public isApprovata:boolean=false;
  public isRespinta:boolean=false;

  
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.storico = this.config.data.storicoPratica;
    
    this.processStoricoPratica();
    
  }

  processStoricoPratica(){
    if(this.pratica.stato ==='ASSEGNATA'){
      this.isAssegnata = true;
    }else if(this.pratica.stato ==='IN CARICO IMPRESA'){
      this.isInCarico = true;
    }else if(this.pratica.stato ==='ANNULLATA'){
      this.isAnnullata = true;
    }else if(this.pratica.stato ==='IN PROGETTAZIONE'){
      this.isInProgettazione = true;
    }else if(this.pratica.stato ==='CONSEGNATA'){
      this.isConsegnata = true;
    }else if(this.pratica.stato ==='IN REWORK AZIENDA'){
      this.isInRework = true;
    }else if(this.pratica.stato ==='APPROVATA'){
      this.isApprovata = true;
    }else if(this.pratica.stato ==='RESPINTA'){
      this.isRespinta = true;
    }
    this.storico.forEach(storicoPratica =>{
      this.logger.info("---> RIGA STORICO " + storicoPratica.stato.codice);

      if(storicoPratica.stato.codice === "ASSEGNATA"){
        this.dataAssegnazione = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "IN CARICO IMPRESA"){
        this.dataInizioInCarico = storicoPratica.iniziato;
      } else if(storicoPratica.stato.codice === "IN PROGETTAZIONE"){
        this.dataInizioProgettazione = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "CONSEGNATA"){
        this.dataConsegna = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "IN REWORK AZIENDA"){
        this.dataRework = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "APPROVATA"){
        this.dataRework = storicoPratica.iniziato;
      }else if(storicoPratica.stato.codice === "RESPINTA"){
        this.dataRework = storicoPratica.iniziato;
      }
    });
   
    //this.dataAssegnazione =  new Date();
  }
  
}

import * as Leaflet from "leaflet";
import { StoricoPraticheService } from "src/app/services/storicoPratiche.service";
import { StoricoPraticaScuola } from "src/app/models/pratica.storico.scuola.model";
import { NotaReworkPratica } from "src/app/models/pratica.nota.scuola.model";
import { NotePraticheService } from "src/app/services/notePratiche.service";
import { NotaPraticaDTO } from "src/app/models/pratica.notaDTO.scuola.model";
import { CsvboxLoaderService } from "src/app/services/csvbox-loader.service";
import * as JSZip from "jszip";
import { ModificaPraticaDTO } from "src/app/models/modificaPratica.scuola.model";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { DialogCreaPraticaBando1 } from "./crea-pratica-bando1.component";
import { ComuniService } from "src/app/services/comuni.service";
import { ComuneDTO } from "src/app/models/comune.model";
import { DialogCaricaPacchettiFirmati, DialogPosizioneScuola } from "../bando2/pratiche-scuola-bando2.component";
import { DialogRichiediCambioCentraleBando1 } from "./cambio-centrale-bando1.component";
import { Tipologia } from "src/app/models/tipologia.model";
import { NgxFileDropEntry } from "ngx-file-drop";
import { DialogModificaCodiceInvioPratica } from "../bando2/cambio-codiceInvio-bando2.component";
import { DialogModificaCodiceInvioPraticaBando1 } from "./cambio-codiceInvio-bando1.component";
import { Centrale } from "src/app/models/centrale.models";
import { DialogRichiediAggiornamentoCentrale } from "../bando2/aggiornamento-centrale-bando2.component";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";
@Component({
  selector: "app-posizione-bando1",
  templateUrl: "./dialog-posizione-scuola.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogPosizioneScuolaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola_Firestore;
  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  initMarkers() {
    const initialMarkers = [
      {
        position: {
          lat: this.pratica.coordinateScuola.latitude.valueOf(),
          lng: this.pratica.coordinateScuola.longitude.valueOf(),
        },
        draggable: false,
      },
    ];
    const data = initialMarkers[0];
    const marker = this.generateMarker(data, 0);
    marker
      .addTo(this.map)
      .bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
    this.map.panTo(data.position);
    this.markers.push(marker);
  }

  generateMarker(data: any, index: number) {
    return Leaflet.marker(data.position, { draggable: data.draggable })
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
  }
  markerClicked($event: any, index: number) {}

  markerDragEnd($event: any, index: number) {}
  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

@Component({
  selector: "app-modifica-posizione-bando1",
  templateUrl: "./dialog-modifica-posizione.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogModificaPosizioneBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected isScuola:boolean = true;
  public pratica: PraticaScuola_Firestore;
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.isScuola = this.config.data.isScuola;
  }
  initMarkers() {
    let initialMarkers = [];
    if(this.isScuola){
      
       initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateScuola.latitude.valueOf(),
            lng: this.pratica.coordinateScuola.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }else {
      initialMarkers = [
        {
          position: {
            lat: this.pratica.coordinateCentrale? this.pratica.coordinateCentrale.latitude.valueOf():this.pratica.coordinateScuola.latitude.valueOf() ,
            lng: this.pratica.coordinateCentrale? this.pratica.coordinateCentrale.longitude.valueOf(): this.pratica.coordinateScuola.longitude.valueOf(),
          },
          draggable: true,
        },
      ];
    }
  
    const data = initialMarkers[0];
    const marker = this.generateMarker(data, 0);
    marker
      .addTo(this.map)
      .bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
    this.map.panTo(data.position);
    this.markers.push(marker);
  }

  generateMarker(data: any, index: number) {
    return Leaflet.marker(data.position, { draggable: data.draggable })
      .on("click", (event) => this.markerClicked(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
  }
  markerClicked($event: any, index: number) {}

  markerDragEnd($event: any, index: number) {
    this.logger.info("NUOVE COORDINATE " + $event.target._latlng.lat + " "+ $event.target._latlng.lng);
    let nuoveCoordinate:GeoPoint = new GeoPoint($event.target._latlng.lat,$event.target._latlng.lng );
    const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
    const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
    let updatedData:any ;// { coordinateScuola: nuoveCoordinate };
    if(!this.isScuola){
      updatedData  = { coordinateCentrale : nuoveCoordinate };
    }else {
      updatedData  = { coordinateScuola : nuoveCoordinate };
    }
 
    documentRef
      .update(updatedData)
      .then(() => {
        this.logger.info( "OK AGGIORNAMENTO COORDINATE SU FIRESTORE");
      })
      .catch((error) => {
        this.logger.error(
          "ERRORE AGGIORNATO COORDINATE SU FIRESTORE"
        );
      });

    
  }
  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}

@Component({
  selector: "app-piantina-bando1",
  templateUrl: "./dialog-carica-piantina.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaPiantinaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "piantina." + fileExtension;
      this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
          //this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento file!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-nt9-bando1",
  templateUrl: "./dialog-carica-nt9.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaNT9Bando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName =
        this.pratica.codiceInfratel + "/IN/" + "nt9." + fileExtension;
      this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento file!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}

@Component({
  selector: "app-note-rework-bando1",
  templateUrl: "./dialog-note-rework-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogNoteReworkBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public noteRework:NotaReworkPratica[]=[];
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.noteRework = this.config.data.note;
    this.logger.info(this.noteRework)
  }
  
  conferma(conferma: boolean) {
    
  }
}


@Component({
  selector: "app-note-respingimento-bando1",
  templateUrl: "./dialog-inserisci-note-respingimento-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogInserisciNoteRespingimentoPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public note:String="";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  noteRespigimentoFile: File = null;
  estensioneFile:String="";

  isNoteRespingimentoFileUploaded: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private googleStorageService:GoogleStorageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  modificoNote(){

  }

  onNoteRespingimentoUpload($event) {
    this.isNoteRespingimentoFileUploaded = true;
    this.noteRespigimentoFile = $event.files[0]; 
    this.estensioneFile = this.noteRespigimentoFile.name.slice(((this.noteRespigimentoFile.name.lastIndexOf(".") - 1) >>> 0) + 2);

  }
  onNoteRespingimentoRemove() {
    this.isNoteRespingimentoFileUploaded = false; 
    this.estensioneFile ="";

  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let noteRespingimento:NotaPraticaDTO = new NotaPraticaDTO();
      noteRespingimento.note = this.note;
      this.praticheScuolaService
        .comunicaRespingimentoPratica(this.pratica,noteRespingimento)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di COMUNICAZIONE RESPINGIMENTO della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            //carico il file!
           

            if(this.isNoteRespingimentoFileUploaded){
              const fileName = this.pratica.codiceInfratel + "/IN/" + "NOTE_RESPINGIMENTO_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile ;
              this.logger.info("STO CERCANDO DI CARICARE IL FILE " + fileName);
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(this.noteRespigimentoFile, fileName)
              .then((uploadRes) => { 
                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
                const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
                const updatedData = { noteRespingimentoFile: "NOTE_RESPINGIMENTO_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile };
                documentRef
                  .update(updatedData)
                  .then(() => {
                    this.dialogRef.close({ success: true });
                  })
                  .catch((error) => {
                    this.logger.error(
                      "ERRORE AGGIORNATO CAMPO noteRespingimentoUploaded SU FIRESTORE"
                    );
                  });
              })
              .catch((errore) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento del FILE NOTE RESPINGIMENTO!",
                });
                this.dialogRef.close({ success: false });
              });
            }else{
              this.dialogRef.close({ success: true });
            }
            
            

          },
        });
    }
  }
}

@Component({
  selector: "app-inserisci-note-rework-bando1",
  templateUrl: "./dialog-inserisci-note-rework-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogInserisciNoteReworkBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public note:String="";

  noteReworkFile: File = null;
  estensioneFile:String="";
  isNoteReworkFileUploaded: boolean = false;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private googleStorageService:GoogleStorageService,
    private firestoreService:AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  modificoNote(){

  }
  onNoteReworkUpload($event) {
    this.isNoteReworkFileUploaded = true;
    this.noteReworkFile = $event.files[0]; 
    this.estensioneFile = this.noteReworkFile.name.slice(((this.noteReworkFile.name.lastIndexOf(".") - 1) >>> 0) + 2);

  }
  onNoteReworkRemove() {
    this.isNoteReworkFileUploaded = false; 
    this.estensioneFile ="";

  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let noteRework:NotaPraticaDTO = new NotaPraticaDTO();
      noteRework.note = this.note;
      this.praticheScuolaService
        .mandaInRework(this.pratica,noteRework)
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
             detail: "Errore in fase di REINVIO ALL'IMPRESA della pratica",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            if(this.isNoteReworkFileUploaded){
              const fileName = this.pratica.codiceInfratel + "/IN/" + "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile ;
              this.logger.info("STO CERCANDO DI CARICARE IL FILE " + fileName);
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(this.noteReworkFile, fileName)
              .then((uploadRes) => { 
                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
                const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
                const updatedData = { noteReworkFile: "NOTE_REWORK_"+ this.pratica.codiceInfratel.valueOf()+ "."+this.estensioneFile };
                documentRef
                  .update(updatedData)
                  .then(() => {
                    this.dialogRef.close({ success: true });
                  })
                  .catch((error) => {
                    this.logger.error(
                      "ERRORE AGGIORNATO CAMPO noteReworkFile SU FIRESTORE"
                    );
                  });
              })
              .catch((errore) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di caricamento del FILE NOTE REWORK!",
                });
                this.dialogRef.close({ success: false });
              });
            }else{
              this.dialogRef.close({ success: true });
            }

          },
        });
    }
  }
}

@Component({
  selector: "app-assegna-pratica-bando1",
  templateUrl: "./dialog-assegna-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogAssegnaPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public pratiche: PraticaScuola[];
 
  public azienda:Impresa;
  public aziende:Impresa[]=[];
  protected filteredAziende:Impresa[]=[];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private aziendeService:ImpreseService,
  ) {}

  ngOnInit(): void {
    if(this.config.data.pratica){
      this.pratica = this.config.data.pratica;
    }else if(this.config.data.pratiche){
      this.pratiche = this.config.data.pratiche;
    }
    
    this.loadImprese();
  }

  filterAzienda(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.aziende.length; i++) {
        let comune = this.aziende[i];
        if (comune.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredAziende = filtered;
  }

  loadImprese(){
    this.aziendeService.getByBando("1").subscribe(aziende =>{
      this.aziende = aziende;
    });
  }
 
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let impresaSelezionata:Impresa = this.azienda;
      if(this.pratica){
        this.pratica.azienda = impresaSelezionata;
        this.praticheScuolaService
          .assegna(this.pratica)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di assegnazione della pratica",
              });
              this.dialogRef.close({ success: false });
            },
            complete: () => {
              this.dialogRef.close({ success: true });

            },
          });
      } else if(this.pratiche){
        this.pratiche.forEach(pratica =>{
          pratica.azienda = impresaSelezionata;

          this.praticheScuolaService
            .assegna(pratica)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di assegnazione della pratica",
                });
                this.dialogRef.close({ success: false });
              },
              complete: () => {
              

              },
            });
          });
        this.dialogRef.close({ success: true });
      }
      
    }
  }
}

@Component({
  selector: "app-completa-pratica-bando1",
  templateUrl: "./dialog-completa-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCompletaPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  protected pagineCompletaPratica: MenuItem[];
  protected activeIndex: number = 0;
  protected showSpinner: boolean = false; 
  public praticaFromFirestore: PraticaScuola_Firestore;
  protected completaPraticaForm: FormGroup;

  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;


  computoMetricoFile: File = null;
  isComputoMetricoUploaded: boolean = false;

  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private _formBuilder: FormBuilder,
    private messageService:MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private firestoreService:AngularFirestore,

  ) {}

  ngOnInit(): void {
    this.pratica              = this.config.data.pratica;
    this.logger.info(this.pratica);
    this.praticaFromFirestore = this.config.data.praticaFirestore;
    this.inizializzaFormGroups();
    //this.inizializzaSteps();
  }

  inizializzaFormGroups() {
    this.completaPraticaForm = this._formBuilder.group({
      ordineEsecuzione: [this.pratica.ordineEsecuzione, [Validators.required]],
      nomePerInvio: [this.pratica.nomeInvio, [Validators.required]],

    });
   
   
  }

  onComputoMetricoUpload($event) {
    this.isComputoMetricoUploaded = true;
    this.computoMetricoFile = $event.files[0]; 
    this.logger.info(" onComputoMetricoUpload" + this.computoMetricoFile.name);

  }
  onComputoMetricoRemove() {
    this.isComputoMetricoUploaded = false; 
  }

 /*  onCronoprogrammaUpload($event) {
    this.isCronoprogrammaUploaded = true;
    this.cronoprogrammaFile = $event.files[0];
    this.logger.info(" onCronoprogrammaUpload" + this.cronoprogrammaFile.name);
  }
  onCronoprogrammaRemove() {
    this.isCronoprogrammaUploaded = false;
  }
  */
 

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      //1) salvo codice a db
      this.showSpinner = true;
      this.pratica.ordineEsecuzione = this.completaPraticaForm.value.ordineEsecuzione;
      this.pratica.nomeInvio    = this.completaPraticaForm.value.nomePerInvio;
      this.pratica.codiceInvio  = this.completaPraticaForm.value.nomePerInvio;
      this.praticheScuolaService.comunicaOrdineEsecuzione(this.pratica).subscribe(res =>{
      
        this.logger.info("OK SALVATAGGIO ORDINE ESECUZIONE");
        //2) carico i file se sono stati passati
        this.praticheScuolaService.completaPratica(this.pratica.id.valueOf()).subscribe(res =>{
          this.dialogRef.close({ success: true });
          /*  if(this.isComputoMetricoUploaded){
             this.logger.info("SALVO COMPUTO METRICO @ ");

            const fileName = this.pratica.codiceInfratel + "/OUT/" +this.praticaFromFirestore.comune.toUpperCase()+"_cmt_"+ this.praticaFromFirestore.nomeInvio.toUpperCase()+".xlsx" ;
            this.logger.info("SALVO COMPUTO METRICO @ " + fileName);

            this.googleStorageService
            .uploadFileScuolaToGoogleStorage(this.computoMetricoFile, fileName)
            .then((uploadRes) => { 
              const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola);
              const documentRef = collectionRef.doc(this.pratica.codiceInfratel.valueOf());
              const updatedData = { computoMetricoUploaded: true };
              documentRef
                .update(updatedData)
                .then(() => {
                 
                })
                .catch((error) => {
                  this.showSpinner = false;
                  this.logger.error(
                    "ERRORE AGGIORNATO CAMPO computoMetricoUploaded SU FIRESTORE"
                  );
                });
            })
            .catch((errore) => {
              this.showSpinner = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di caricamento del computo metrico!",
              });
              this.dialogRef.close({ success: false });
            });
            this.dialogRef.close({ success: true }); 
          }else{
            this.dialogRef.close({ success: true });
          }   */
        });
   
        });

    }
  }
}


@Component({
  selector: "app-verbale-firmato-bando1",
  templateUrl: "./dialog-carica-verbale-firmato.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaVerbaleFirmatoBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public pratica_firestore: PraticaScuola_Firestore;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.pratica_firestore = this.config.data.pratica_firestore;
 
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
     
      const fileExtension = this.selectedFile.name.substring( this.selectedFile.name.lastIndexOf(".") + 1);
      const fileName = this.pratica.codiceInfratel + "/OUT/SIGNED/"+this.selectedFile.name;
   /*    const fileName =
        this.pratica.codiceInfratel + "/OUT/Verbale_Cessione_IRU_" + this.pratica.nomeInvio+".pdf." + fileExtension; */
        this.googleStorageService
        .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}



@Component({
  selector: "app-pacchetto-firmato-bando1",
  templateUrl: "./dialog-carica-pacchetto-firmato.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaPacchettoFirmatoBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  selectedFiles: File[] = [];
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    
  }
  
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFiles = $event.files;
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if( this.selectedFiles.length >1){
        const promises: Promise<void>[] = [];
        const promise = new Promise<void>((resolve, reject) => {
          this.selectedFiles.forEach(file =>{
            
            const fileExtension = file.name.substring(
              file.name.lastIndexOf(".") + 1
            );
            const fileName = this.pratica.codiceInfratel + "/OUT/SIGNED/"+file.name;
              this.googleStorageService
              .uploadFileScuolaToGoogleStorage(file, fileName)
              .then((uploadRes) => {

                resolve();
                promises.push(promise); 
              })
              .catch((errore) => {
                
                reject();
                promises.push(promise); 
              });
              
          });
        });
        Promise.all(promises).then(() => {
          this.dialogRef.close({ success: true});
        }).catch((error) => {
          this.logger.error("Errore durante l'esecuzione delle promesse:", error);
          this.messageService.add({
                        severity: "info",
                       summary: "Errore" , life:10000,
                        detail: "Errore in fase di caricamento File!",
                      });
          this.dialogRef.close({ success: false});
        
        });
      }else{
        let selectedFile:File = this.selectedFiles[0];
        this.logger.info(selectedFile);
      /* const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      ); */
      const fileName =
        this.pratica.codiceInfratel + "/OUT/SIGNED/"+selectedFile.name;
        this.googleStorageService
        .uploadFileScuolaToGoogleStorage(selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        }); 
      }
      
    }
  }
}

@Component({
  selector: "app-verbali-firmati-bando1",
  templateUrl: "./dialog-carica-verbali-firmati.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaVerbaliFirmatiBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected files: NgxFileDropEntry[] = [];
  protected dropZoneLabel="Trascina qui i verbali firmati";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
    private praticheScuolaService: PraticheScuolaService,
    
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    
  }
  public onFileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    
    if(files.length > 0){
      this.dropZoneLabel = "Sono stati caricati " + files.length+ " verbali. Procedi con la conferma!"
      this.isFileUploaded = true;
    }else{
      this.dropZoneLabel = "Trascina qui i verbali firmati!"
      this.isFileUploaded = false;
    }
    
  }

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let fileCaricati: number = 0;
      let fileErrore: number = 0;
      const promises: Promise<void>[] = [];

      for (const droppedFile of this.files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const promise = new Promise<void>((resolve, reject) => {
            fileEntry.file((file: File) => {
              this.logger.info("DOVREI CARICARE IL file " + file.name );
              //const codiceInvio = file.name.substring(file.name.lastIndexOf("_")+1, file.name.indexOf("."));
              const codiceInvioTmp = file.name.replace(/^Verbale_Cessione_IRU_/, '');
              const codiceInvio =  codiceInvioTmp.replace(".pdf.p7m", '');
              if (codiceInvio) {
                this.logger.info("codiceInvio OTTENUTO DAL NOME DEL FILE ---> " + codiceInvio );
      
               const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola).ref;
                const query = 
                collectionRef.where("nomeInvio", "==", codiceInvio);
                query.get().then(qs => {
                  if (qs.docs && qs.docs.length > 0) {
                    const pratica = qs.docs[0].data() as PraticaScuola_Firestore;
                    const fileName = pratica.codiceInfratel + "/OUT/SIGNED/" + file.name;
                    let praticaDaAggiornare:PraticaScuola = new PraticaScuola();
                    praticaDaAggiornare.id = pratica.id;
                    this.googleStorageService
                      .uploadFileScuolaToGoogleStorage(this.selectedFile, fileName)
                      .then((uploadRes) => {
                        this.praticheScuolaService
                        .confermaCaricamentoVerbale(praticaDaAggiornare)
                        .subscribe((res) => {
                          this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
                          fileCaricati = fileCaricati + 1;
      
                          resolve();
                        });
                       
                      })
                      .catch((errore) => {
                        fileErrore = fileErrore + 1;
                        resolve();
                      });
                  } else {
                    fileErrore = fileErrore + 1;
                    resolve();
                  }
                }); 
              } else {
                  this.logger.error("IMPOSSIBILE RECUPERARE LA PRATICA DEL FILE " + file.name);
                  fileErrore = fileErrore + 1;
                  resolve();
                }
              });
            });
        
            promises.push(promise);
          }
        }
             
        Promise.all(promises).then(() => {
          this.logger.info("--> ALLA FINE DEI CARICAMENTI --> OK " + fileCaricati + " VS KO " + fileErrore);
          this.dialogRef.close({ success: true, numeroPacchettiCaricati: fileCaricati, numeroPacchettiNonCaricati: fileErrore});
        }).catch((error) => {
          this.logger.error("Errore durante l'esecuzione delle promesse:", error);
          this.dialogRef.close({ success: false, numeroPacchettiCaricati: 0, numeroPacchettiNonCaricati: 0});

        });

      }  
  }
}

@Component({
  selector: "app-pacchetti-firmati-bando1",
  templateUrl: "./dialog-carica-pacchetti-firmati.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogCaricaPacchettiFirmatiBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected pratica: PraticaScuola;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected files: NgxFileDropEntry[] = [];
  protected dropZoneLabel="Trascina qui i pacchetti firmati";
  private collection_pratiche_scuola:string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService,
    private firestoreService:AngularFirestore,
    private praticheScuolaService: PraticheScuolaService,
    
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    
  }
  public onFileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    
    if(files.length > 0){
      this.dropZoneLabel = "Sono stati caricati " + files.length+ " pacchetti. Procedi con la conferma!"
      this.isFileUploaded = true;
    }else{
      this.dropZoneLabel = "Trascina qui i pacchetti firmati!"
      this.isFileUploaded = false;
    }
    
  }

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let fileCaricati: number = 0;
      let fileErrore: number = 0;
      const promises: Promise<void>[] = [];

      for (const droppedFile of this.files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const promise = new Promise<void>((resolve, reject) => {
            fileEntry.file((file: File) => {
              this.logger.info("DOVREI CARICARE IL file " + file.name + " > " + file.size);
              const codiceInfratel = file.name.substring(0, file.name.indexOf("."));

              if (codiceInfratel) {
                this.logger.info("codiceInfratel OTTENUTO DAL NOME DEL FILE ---> " + codiceInfratel);

                const collectionRef = this.firestoreService.collection(this.collection_pratiche_scuola).ref;
                const query = collectionRef.where("codiceInfratel", "==", codiceInfratel);
                query.get().then(qs => {
                  if (qs.docs && qs.docs.length > 0) {
                    const pratica = qs.docs[0].data() as PraticaScuola_Firestore;
                    
                    const zip = new JSZip();
      
                    zip.loadAsync(file).then(zipContent => {
                      
                      zipContent.forEach((relativePath, zipEntry) => {
                        if (!zipEntry.dir && !relativePath.startsWith('__MACOSX/')) {
                        
                        zipEntry.async('uint8array').then(content => {
                          const blob = new Blob([content]);
                          const zipFile = new File([blob], zipEntry.name);
                          const fileName = pratica.codiceInfratel + "/OUT/SIGNED/" + zipEntry.name;
                          let praticaDaAggiornare:PraticaScuola = new PraticaScuola();
                          praticaDaAggiornare.id = pratica.id;
                          this.googleStorageService
                            .uploadFileScuolaToGoogleStorage(zipFile, fileName)
                            .then((uploadRes) => {
                              this.logger.info("--> OK CARICAMENTO FILE "+fileName);

                               this.praticheScuolaService
                              .confermaCaricamentoPacchetto(praticaDaAggiornare)
                              .subscribe((res) => {
                                this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
                                resolve();
                                
                              }); 
                            
                            })
                            .catch((errore) => {
                              fileErrore = fileErrore + 1;
                              resolve();
                            });
                        });
                      }
                      });
                    });
                    fileCaricati = fileCaricati + 1;
                   /*  const fileName = this.pratica.codiceInfratel + "/OUT/SIGNED/"+file.name;
                    this.googleStorageService
                    .uploadFileScuolaToGoogleStorage(file, fileName)
                      .then((uploadRes) => {
      
                        resolve();
                        promises.push(promise); 
                      })
                      .catch((errore) => {
                        
                        reject();
                        promises.push(promise); 
                      }); 
                  }*/
                 
                  
                    



                   
                  } else {
                    fileErrore = fileErrore + 1;
                    resolve();
                  }
                });
              } else {
                this.logger.error("IMPOSSIBILE RECUPERARE LA PRATICA DEL FILE " + file.name);
                fileErrore = fileErrore + 1;
                resolve();
              }
            });
          }); 

          promises.push(promise);
          
        }
}

// Esegui il codice alla fine del ciclo principale
Promise.all(promises).then(() => {
  this.logger.info("--> ALLA FINE DEI CARICAMENTI --> OK " + fileCaricati + " VS KO " + fileErrore);
  this.dialogRef.close({ success: true, numeroPacchettiCaricati: fileCaricati, numeroPacchettiNonCaricati: fileErrore});
}).catch((error) => {
  this.logger.error("Errore durante l'esecuzione delle promesse:", error);
  this.dialogRef.close({ success: false, numeroPacchettiCaricati: 0, numeroPacchettiNonCaricati: 0});

});

    }  
  }
}

@Component({
  selector: "app-modifica-stato-pratica-bando1",
  templateUrl: "./dialog-modifica-stato-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando1.component.css"],
})
export class DialogModificaStatoPraticaBando1 implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola;
  public praticaFromFirestore: PraticaScuola_Firestore;
  protected modificaStatoPraticaForm: FormGroup;

  protected stati:Tipologia[]=[
    {
      codice:"1",
      denominazione:"CARICATA"
    },
    {
      codice:"2",
      denominazione:"ASSEGNATA"
    },
    {
      codice:"3",
      denominazione:"IN CARICO IMPRESA"
    },
    {
      codice:"15",
      denominazione:"IN PROGETTAZIONE"
    },
    {
      codice:"4",
      denominazione:"CONSEGNATA"
    },
    {
      codice:"5",
      denominazione:"IN VERIFICA SMARTFIBER"
    },
    {
      codice:"6",
      denominazione:"IN CARICO PRESIDIO"
    },
    {
      codice:"17",
      denominazione:"IN FIRMA VERBALE"
    },
    {
      codice:"18",
      denominazione:"IN FIRMA PACCHETTO"
    },
    {
      codice:"8",
      denominazione:"FIRMATA"
    },
    {
      codice:"9",
      denominazione:"PUBBLICATA"
    },
    {
      codice:"10",
      denominazione:"APPROVATA"
    },
    {
      codice:"11",
      denominazione:"RESPINTA"
    },
  ]

  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private messageService:MessageService,
    private praticheScuolaService: PraticheScuolaService,
    private confirmationService: ConfirmationService,

  ) {}

  ngOnInit(): void {
    this.praticaFromFirestore = this.config.data.pratica;
    
    this.inizializzaFormGroups();
    //this.inizializzaSteps();
  }

  inizializzaFormGroups() {
    this.modificaStatoPraticaForm = this._formBuilder.group({
      stato: [this.praticaFromFirestore.stato, [Validators.required]],
      //tipiVerbale: [[], [Validators.required]],
    });
   
   
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.confirmationService.confirm({
        message: "Confermi di voler forzare lo stato della pratica a <strong>" + this.modificaStatoPraticaForm.value.stato.denominazione+ "</strong> ?",
        header: "ATTENZIONE! Operazione NON reversibile",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => { 
           this.praticheScuolaService.modificaStatoPratica(this.praticaFromFirestore.id.toString(),this.modificaStatoPraticaForm.value.stato.codice).subscribe(res =>{
            this.dialogRef.close({ success: true });
            
            }, error =>{
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di completamento della pratica!",
              });
              this.dialogRef.close({ success: false });
            }); 
        },
        reject: () => {
        },
      });
     
    }
  }
}