
<p-steps [model]="pagineInserisciInformazioni" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    <ng-container *ngSwitchCase="1">
      <form [formGroup]="reteAccessoForm">
        <div class="grid formgrid p-fluid mt-2">
          
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="lunghezza" class="font-medium text-900">Lunghezza</label>
            <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezza" placeholder="Lunghezza della Fibra in metri" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="tipologia" class="font-medium text-900">Tipologia collegamento</label>
            <p-dropdown inputId="tipologia" [options]="tipologie" optionLabel="codice"
                    [showClear]="true" placeholder="Tipo di collegamento" formControlName="tipologia">
                    <ng-template let-tipo pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{tipo.codice}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="diritto" class="font-medium text-900">Diritto</label>
            <p-dropdown inputId="diritto" [options]="diritti" optionLabel="codice"
                [showClear]="true" placeholder="Tipo di diritto" formControlName="diritto">
                <ng-template let-tipo pTemplate="item">
                    <div class="flex align-items-center">
                        <div>{{tipo.codice}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="proprietario" class="font-medium text-900">Proprietario</label>
            <p-dropdown inputId="proprietario" [options]="proprietari" optionLabel="codice"
                [showClear]="true" placeholder="Proprietario" formControlName="proprietario">
                <ng-template let-tipo pTemplate="item">
                    <div class="flex align-items-center">
                        <div>{{tipo.codice}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
          </div>
       
         
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <!-- <div class="field mt-2 mb-2 col">
            <label for="idPDC" class="font-medium text-900">id PDC</label>
            <input type="text" formControlName="idPDC" placeholder="PDC" pInputText>
          </div> -->
          <div class="field mt-2 mb-2 col">
            <label for="hostnameCPE" class="font-medium text-900">Hostname CPE</label>
            <input type="text" formControlName="hostnameCPE" placeholder="Hostname CPS" pInputText>
          </div>
          <div class="field mt-2 mb-2 col">
            <label for="indirizzoIP" class="font-medium text-900">Indirizzo IP</label>
            <input type="text" formControlName="indirizzoIP" placeholder="Indirizzo IP" pInputText>
          </div>
          <div class="field mt-2 mb-2 col">
            <label for="localeTecnico" class="font-medium text-900">Locale Tecnico</label>
            <input type="text" formControlName="localeTecnico" placeholder="Locale Tecnico" pInputText>
          </div>
          <div class="field mt-2 mb-2 col">
            <label for="piano" class="font-medium text-900">Piano</label>
            <input type="text" formControlName="piano" placeholder="Piano" pInputText>
          </div>
          <div class="field mt-2 mb-2 col">
            <label for="stanza" class="font-medium text-900">Stanza</label>
            <input type="text" formControlName="stanza" placeholder="Stanza" pInputText>
          </div>
         <!--  <div *ngIf="isColocata" class="field mt-2 mb-2 col">
            <label for="flagCPEinStessoLocale" class="font-medium text-900" style="font-size: smaller;">CPE in stesso locale?</label>
            <div class="flex align-items-center mt-2">
              <p-inputSwitch inputId="flagCPEinStessoLocale" formControlName="flagCPEinStessoLocale"></p-inputSwitch>
            </div>
          </div> -->
          <!-- <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="numeroAccessPoints" class="font-medium text-900">Numero Access Points</label>
            <input type="number" formControlName="numeroAccessPoints" placeholder="0" pInputText>
          </div> -->
        
    
        </div>
      </form> 
    </ng-container>

    <!-- <ng-container *ngSwitchCase="1">
       <form [formGroup]="ubicazioneRouterForm">
        <div class="grid formgrid p-fluid mt-2" >
          <div class="field mt-2 mb-2 md:col-4 col-12">
            <label for="hostnameCPE" class="font-medium text-900">Hostname CPE</label>
            <input type="text" formControlName="hostnameCPE" placeholder="Hostname CPS" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="localeTecnico" class="font-medium text-900">Locale Tecnico</label>
            <input type="text" formControlName="localeTecnico" placeholder="Locale Tecnico" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="piano" class="font-medium text-900">Piano</label>
            <input type="text" formControlName="piano" placeholder="Piano" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="stanza" class="font-medium text-900">Stanza</label>
            <input type="text" formControlName="stanza" placeholder="Stanza" pInputText>
          </div>
      
        </div>
      </form> 
    </ng-container> -->
    <ng-container *ngSwitchCase="0">
       <form [formGroup]="identificazioneFibraForm">
       <div class="grid formgrid p-fluid mt-2" >
        <div class="field mt-2 mb-2 md:col-2 col-3">
          <label for="clliSedeA" class="font-medium text-900">CLLI Sede A</label>
          <input type="text" formControlName="clliSedeA" placeholder="Clli della SEDE A" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-4">
          <label for="tipologiaSedeA" class="font-medium text-900">Tipo Sede A</label>
          <input type="text" formControlName="tipologiaSedeA" placeholder="Tipo della SEDE A" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-5">
          <label for="descrizioneSedeA" class="font-medium text-900">Descrizione Sede A</label>
          <input type="text" formControlName="descrizioneSedeA" placeholder="Descrizione della SEDE A" pInputText>
        </div>
        <div class="field mt-2 mb-2 col-5">
          <label for="attestazioneAlRipartitoreA" class="font-medium text-900">Attestazione Sede A</label>
          <input type="text" formControlName="attestazioneAlRipartitoreA" placeholder="Attestazione della SEDE A" pInputText>
        </div>
       
        <div class="field mt-2 mb-2 md:col-2 col-3">
          <label for="clliSedeZ" class="font-medium text-900">CLLI Sede Z</label>
          <input type="text" formControlName="clliSedeZ" placeholder="CLLI della SEDE Z" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-4">
          <label for="tipologiaSedeZ" class="font-medium text-900">Tipo Sede Z</label>
          <input type="text" formControlName="tipologiaSedeZ" placeholder="Tipo della SEDE Z" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-5">
          <label for="descrizioneSedeZ" class="font-medium text-900">Descrizione Sede Z</label>
          <input type="text" formControlName="descrizioneSedeZ" placeholder="Descrizione della SEDE Z" pInputText>
        </div>
        <div class="field mt-2 mb-2 col-5">
          <label for="attestazioneAlRipartitoreZ" class="font-medium text-900">Attestazione Sede Z</label>
          <input type="text" formControlName="attestazioneAlRipartitoreZ" placeholder="Attestazione della SEDE Z" pInputText>
        </div>
       
        <div class="field mt-2 mb-2 md:col-2 col-3">
          <label for="numeroTotaleCoppie" class="font-medium text-900">Numero Totale Coppie</label>
          <input type="number" formControlName="numeroTotaleCoppie" placeholder="0" min="0" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-3">
          <label for="numeroCoppieInUso" class="font-medium text-900">Numero Coppie in uso</label>
          <input type="number" formControlName="numeroCoppieInUso" placeholder="0" min="0" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-2 col-3">
          <label for="fibra" class="font-medium text-900">Fibra</label>
          <input type="number" formControlName="fibra" placeholder="0" min="0" pInputText>
        </div>
        <div class="field mt-2 mb-2 md:col-3 col-3">
          <label for="tipoConnettivitaMPLS" class="font-medium text-900">Connettivia MPLS</label>
          <p-dropdown inputId="tipoConnettivitaMPLS" [options]="tipologieConnettivitaMPLS" optionLabel="descrizione"
              [showClear]="true" placeholder="tipoConnettivitaMPLS" formControlName="tipoConnettivitaMPLS">
              <ng-template let-tipo pTemplate="item">
                  <div class="flex align-items-center">
                      <div>{{tipo.descrizione}}</div>
                  </div>
              </ng-template>
          </p-dropdown>
        </div>
        <div class="field mt-2 mb-2 col-2">
          <label for="centroStella" class="font-medium text-900" style="font-size: smaller;">Centro Stella?</label>
          <div class="flex align-items-center mt-2">
            <p-inputSwitch inputId="centroStella" formControlName="centroStella"></p-inputSwitch>
          </div>
        </div>
       
        </div>
      </form> 
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <form [formGroup]="localizzazioniForm">
       <div class="grid formgrid p-fluid mt-2">
          <div class="field mt-2 mb-2 md:col-5 col-12">
            <label for="indirizzoAccesso" class="font-medium text-900">Indirizzo Struttura</label>
            <input type="text" formControlName="indirizzoAccesso" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="comuneAccesso" class="font-medium text-900" >Comune Struttura</label>
            <input type="text" formControlName="comuneAccesso" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="latitudineAccesso" class="font-medium text-900">Latitudine Struttura</label>
            <p-inputNumber formControlName="latitudineAccesso" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="longitudineAccesso" class="font-medium text-900">Longitudine Struttura</label>
            <p-inputNumber formControlName="longitudineAccesso" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
           <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Accesso"
              (click)="openMappaAccesso()">
            </button>
          </div> 
          <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
            <label for="latitudineNodoFittizio" class="font-medium text-900" >Latitudine Nodo Derivato</label>
            <p-inputNumber formControlName="latitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
          <div *ngIf="isBandoD" class="field mt-2 mb-2 col-3">
            <label for="longitudineNodoFittizio" class="font-medium text-900">Longitudine Nodo Derivato</label>
            <p-inputNumber formControlName="longitudineNodoFittizio" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
          <div *ngIf="isBandoD" class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>

          <div class="field mt-2 mb-2 md:col-5 col-12">
            <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
            <input type="text" formControlName="indirizzoCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="comuneCentrale" class="font-medium text-900" >Comune Centrale</label>
            <input type="text" formControlName="comuneCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
            <p-inputNumber formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
            <p-inputNumber formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
          </div>
          <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#4b255f;" icon="pi pi-envelope"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Richiedi Aggiornamento Dati Centrale"
              (click)="openRichiediModificaCentrale()">
            </button>
          </div>  
         

        </div> 
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <div class="grid formgrid p-fluid mt-2">
        <div class="flex justify-begin md:justify-begin">

          <button  pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 mt-2 mb-1 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;" 
           tooltipPosition="top" pTooltip="Aggiungi Dettaglio Lavoro" 
            (click)="openCreaDettaglioLavoro()" [disabled]="isLoadingResults">
          </button>
        </div>
      <p-table #dettagliLavoriDT styleClass="p-datatable-sm" class="mt-2 col-12"
        [value]="lavoriPratica" dataKey="id" [(selection)]="selectedDettaglioLavori"
      [selectionPageOnly]="true" [rowHover]="true" 
      [sortField]="'id'" [sortOrder]="'1'">
     
      <ng-template pTemplate="header">
        <tr>
          <th style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center ">
              Lunghezza Civile
             </div>
          </th>
          <th  style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Categoria Infrastruttura
             </div>
          </th>
          <th style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Tipo Infrastruttura
             </div>
          </th>
          <th style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Percorso
             </div>
          </th>
          <th style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Competenza Rilascio Concessione
             </div>
          </th>
         <th class="text-center"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
            <td> <span class="text-uppercase" >{{row.lunghezzaCivile}}</span>
            <td> <span class="text-uppercase" >{{row.categoriaInfrastruttura}}</span>
            <td> <span class="text-lowercase" >{{row.tipoInfrastruttura}}</span>
            <td> <span class="text-lowercase" >{{row.percorso}}</span>
            <td> <span class="text-lowercase" >{{row.competenzaRilascioConcessione}}</span>
         
          
          <td class="text-center">
            <button pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" 
            tooltipPosition="top" pTooltip="Modifica Dettaglio Lavori"
              (click)="openModificaDettaglioLavori(row)">
            </button>
          
            <button pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-text" (click)="openDeleteDettaglioLavori(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Dettaglio Lavori">
            </button>
        

          </td>
        </tr>
      </ng-template>
    
    </p-table>
  </div>
     <!--  <form [formGroup]="dettagliLavoriForm"></form> 
      <div class="field mb-2 md:col-6 col-12">
        <label for="redattore" class="font-medium text-900">Lunghezz</label>
        <input type="text" formControlName="redattore" pInputText>
      </div>
      <div class="field mb-2 md:col-6 col-12">
        <label for="verificatore" class="font-medium text-900">Verificatore</label>
        <input type="text" formControlName="verificatore" pInputText>
      </div> -->
      <div class="grid formgrid p-fluid mt-2">
        
      </div>
    
    </ng-container>
    <!-- <ng-container *ngSwitchCase="4">
      <form [formGroup]="dettagliForm">
      <div class="grid formgrid p-fluid mt-2">
        <div class="field mb-2 md:col-6 col-12">
          <label for="redattore" class="font-medium text-900">Redattore</label>
          <input type="text" formControlName="redattore" pInputText>
        </div>
        <div class="field mb-2 md:col-6 col-12">
          <label for="verificatore" class="font-medium text-900">Verificatore</label>
          <input type="text" formControlName="verificatore" pInputText>
        </div>
      </div>
    </form> 
    </ng-container> -->

  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-50 mt-1 mb-3 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0"></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>


  <p-button *ngIf="activeIndex === pagineInserisciInformazioni.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)"></p-button>
  <!-- <p-button icon="pi pi-times" label="Annulla" class="p-button-text" styleClass="p-button-warning" (click)="conferma(false)"></p-button> -->
  <!--    <div class="d-flex"> </div>   <div class="justify-content-end"> </div> -->
</div>
