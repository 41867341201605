import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { Impresa } from "src/app/models/impresa.model";
import { PraticaSanita } from "src/app/models/pratica.sanita.model";
import { ImpreseService } from "src/app/services/imprese.service";
import { PraticheSanitaService } from "src/app/services/pratiche.sanita.service";

@Component({
    selector: "app-assegna-pratica-sanita",
    templateUrl: "./dialog-assegna-pratica.component.html",
    styleUrls: ["./pratiche-sanita.component.scss"],
  })
  export class DialogAssegnaPraticaSanita implements OnInit {
    protected _onDestroy = new Subject<void>();
    public pratica: PraticaSanita;
    public pratiche: PraticaSanita[];
  
    public azienda:Impresa;
    public aziende:Impresa[]=[];
    protected filteredAziende:Impresa[]=[];
  
    constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private praticheSanitaService: PraticheSanitaService,
      private aziendeService:ImpreseService,
    ) {}
  
    ngOnInit(): void {
      if(this.config.data.pratica){
        this.pratica = this.config.data.pratica;
      }else if(this.config.data.pratiche){
        this.pratiche = this.config.data.pratiche;
      }
      
      this.loadImprese();
    }
  
    loadImprese(){
      this.aziendeService.getByBando("SANITA").subscribe(aziende =>{
        this.logger.info(aziende);
        this.aziende = aziende;
      });
    }
    filterAzienda(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;
  
      for (let i = 0; i < this.aziende.length; i++) {
          let comune = this.aziende[i];
          if (comune.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(comune);
          }
      }
      this.filteredAziende = filtered;
    }
   
    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {
        let impresaSelezionata:Impresa = this.azienda;
        if(this.pratica){
          this.pratica.azienda = impresaSelezionata;
          this.praticheSanitaService
            .assegna(this.pratica)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di assegnazione della pratica",
                });
                this.dialogRef.close({ success: false });
              },
              complete: () => {
                this.dialogRef.close({ success: true });
  
              },
            });
        } else if(this.pratiche){
          this.pratiche.forEach(pratica =>{
            pratica.azienda = impresaSelezionata;
  
            this.praticheSanitaService
              .assegna(pratica)
              .subscribe({
                next: (v) => {},
                error: (e) => {
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "Errore in fase di assegnazione della pratica",
                  });
                  this.dialogRef.close({ success: false });
                },
                complete: () => {
                
  
                },
              });
            });
          this.dialogRef.close({ success: true });
        }
        
      }
    }
  }
  
  