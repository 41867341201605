import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { environment } from '../../../environments/environment';

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { Stats } from "src/app/models/stats.model";
import { onSnapshot } from "firebase/firestore";

@Component({
  selector: "app-imprese",
  templateUrl: "./imprese.component.html",
  styleUrls: ["./imprese.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class ImpreseComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  imprese: Stats[] = [];
  selectedImpresa: Impresa;
  
  numeroTotaleScuoleAssegnate:number    = 0;
  numeroTotaleScuoleInCarico:number     = 0;
  numeroTotaleScuoleConsegnate:number   = 0;
  numeroTotaleScuoleInRework:number     = 0;

  numeroTotaleSanitaAssegnate:number    = 0;
  numeroTotaleSanitaInCarico:number     = 0;
  numeroTotaleSanitaConsegnate:number   = 0;
  numeroTotaleSanitaInRework:number     = 0;

  public isAdmin:boolean = false;
  public isSuperAdmin:boolean = false;
  public isPresidioSF  :boolean = false;

  dialogConfig;

  @ViewChild("impreseDT", { static: true }) dipendentiDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    //private utentiService: UtentiService,
    private impreseService: ImpreseService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SUPERADMIN'){
      this.isSuperAdmin = true;
    
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SMARTFIBER'){
      this.isPresidioSF = true;
    }
    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadImprese();
  }

  ngAfterViewInit() {}


  loadImprese() {
   /* const collectionRef =  this.firestore.collection(environment.firebase.collection_stats).ref;
    const query = collectionRef
      .where(
        "ragioneSociale",
        "!=",
       'Admin Fake Company'
      )
      .orderBy("ragioneSociale");
      onSnapshot(query, (qs) => {
        this.imprese = [];
        qs.forEach((doc) => {
          this.imprese.push(doc.data() as Stats);
        });
        this.calcolaTotali();
        this.isLoadingResults = false;
       
      });
      */
     this.isLoadingResults = true;
      this.impreseService.get().subscribe((res) => {
        this.imprese = [];
      res.forEach((impresa) => {
        if(impresa.ragioneSociale !=='Admin Fake Company'){
          this.imprese.push(impresa);
        }
       
      });
     
      this.isLoadingResults = false;
    }); 
  }

  calcolaTotali() {
    this.numeroTotaleScuoleAssegnate    = 0;
    this.numeroTotaleScuoleInCarico     = 0;
    this.numeroTotaleScuoleConsegnate   = 0;
    this.numeroTotaleScuoleInRework     = 0;
    this.numeroTotaleSanitaAssegnate    = 0;
    this.numeroTotaleSanitaInCarico     = 0;
    this.numeroTotaleSanitaConsegnate   = 0;
    this.numeroTotaleSanitaInRework     = 0;

    this.imprese.forEach((stat)=>{
      this.numeroTotaleScuoleAssegnate    += stat.numeroPraticheScuolaAssegnate;
      this.numeroTotaleScuoleInCarico     += stat.numeroPraticheScuolaInCarico;
      this.numeroTotaleScuoleConsegnate   += stat.numeroPraticheScuolaConsegnate;
      this.numeroTotaleScuoleInRework     += stat.numeroPraticheScuolaInRework;

     /*  this.numeroTotaleSanitaAssegnate    += stat.numeroPraticheSanitaAssegnate;
      this.numeroTotaleSanitaInCarico     += stat.numeroPraticheSanitaInCarico;
      this.numeroTotaleSanitaConsegnate   += stat.numeroPraticheSanitaConsegnate;
      this.numeroTotaleSanitaInRework     += stat.numeroPraticheSanitaInRework; */

      
    });
  }

  caricaImprese(){}
  esportaImprese(){
    let selectedDate:Date = new Date();

    const day =  selectedDate.getDate().toString().padStart(2, '0');
    const month = ( selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const year =  selectedDate.getFullYear().toString();
    const giornoRiferimento = `${day}-${month}-${year}`;
    this.excelService.exportImpreseToExcel(this.imprese, "imprese_"+giornoRiferimento+".xlsx");


  }
  
  openCreaImpresaDialog() {
     this.ref = this.dialogService.open(DialogCreaImpresa, {
      header: "Crea Impresa",
      width: this.isMobile ? "90%" : "40%",
      height: this.isMobile ? "90%" : "37%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewImpresa) => {
       console.log("AFTER CLOSED > "+ confirmNewImpresa.success);
      if (confirmNewImpresa && confirmNewImpresa.success) {
       // this.loadDipendenti();
       this.loadImprese();
      }
    }); 
  }

  openModificaImpresaDialog(impresa: Impresa): void {
    this.ref = this.dialogService.open(DialogModificaImpresa, {
      data: {
        impresa: impresa,
   },
     header: 'Modifica Impresa '+impresa.ragioneSociale.toUpperCase(),
     width: this.isMobile? '95%':'50%',
     height: '45%',
    baseZIndex: 10000,
     closable:true,
     draggable:false,
 });
 this.ref.onClose.subscribe(confirmModificaImpresa => {
  this.logger.info("OK MODIFICA IMPRESA");
  this.logger.info(confirmModificaImpresa);
  this.logger.info(confirmModificaImpresa.success);
   if(confirmModificaImpresa){
    this.logger.info("FA GIRO OK");
    this.loadImprese();
    /* if(!this.isMobile){
      this.messageService.add({severity:'success', summary:'', detail:'Cliente modificato!'});
    }
     this.loadClienti(); */
   }else{
    this.logger.info("FA GIRO FUORI");
   }
 });
  }
 

  openDeleteImpresaDialog(impresa: Impresa): void {
    this.logger.info(impresa);
     this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'impresa ${impresa.ragioneSociale}?`,
      header: "Eliminazione Impresa",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.impreseService.elimina(impresa.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione impresa",
              life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Impresa eliminata",
              life:10000
            });
            this.loadImprese();
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        // console.log("ANNULLO");
      },
    }); 
  }
}


@Component({
  templateUrl: './dialog-crea-impresa.component.html',
  styleUrls: ['./imprese.component.css']
})
export class DialogCreaImpresa implements OnInit {

  protected _onDestroy = new Subject<void>();

  public ragioneSociale:String="";
  
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private impreseService: ImpreseService,
      private messageService: MessageService
      ) {
     
  }

  ngOnInit(): void {
   
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

      let aziendaDaCreare:Impresa = new Impresa();
         aziendaDaCreare.ragioneSociale = this.ragioneSociale;
         
        this.impreseService.crea(aziendaDaCreare).subscribe(res => {
          this.messageService.add({severity:'success', summary: '', detail: 'Impresa creata correttamente!' ,  life:10000});

          this.dialogRef.close({ success: true});
      },
      error => {
        console.error('ERRORE CREAZIONE IMPRESA' + error);
        this.messageService.add({severity:'error', summary: 'ATTENZIONE', detail: 'Errore in fase di creazione cliente!',  life:10000});
      });

    }
  }
}



@Component({
  templateUrl: './dialog-modifica-impresa.component.html',
  styleUrls: ['./imprese.component.css']
})
export class DialogModificaImpresa implements OnInit {

  @ViewChild('supportNgForm', {static: true}) supportNgForm: NgForm;

  protected _onDestroy = new Subject<void>();
  public impresaSelezionata : Impresa;

  public modificaImpresaForm: FormGroup;

  public ragioneSociale:String="";


  constructor(
      private logger: NGXLogger,
      private _formBuilder: FormBuilder,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private impreseService: ImpreseService,
      private messageService: MessageService
      ) {
       
  }

  ngOnInit(): void {
      this.impresaSelezionata = this.config.data.impresa;
      this.logger.info(this.impresaSelezionata);
      this.modificaImpresaForm = this._formBuilder.group({
        ragioneSociale  : [this.impresaSelezionata.ragioneSociale, [Validators.required]],
      });
  }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if(this.modificaImpresaForm.valid){
        let impresaDaModificare:Impresa =  new Impresa();
        impresaDaModificare.id              =  this.impresaSelezionata.id;
        impresaDaModificare.ragioneSociale  = this.modificaImpresaForm.value.ragioneSociale;
        this.logger.info(impresaDaModificare);
        this.impreseService.modifica(impresaDaModificare).subscribe(res => {

          this.messageService.add({severity:'success', summary: '', detail: 'Impresa modificatta correttamente!', life:10000});
          this.dialogRef.close({ success: true});
        },
        error => {
          console.error('ERRORE MODIFICA IMPRESA' + error);
          this.messageService.add({severity:'error', summary: 'ATTENZIONE', detail: 'Errore in fase di modifica impresa',  life:10000});
        });
      }


    }
  }
}