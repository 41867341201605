 <div class="surface-card p-4 shadow-2 border-round">
    <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
      <div class="flex justify-start md:justify-start">
      <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadUtenti()"></button>
    </div>
    <div class="flex justify-center">
      <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">UTENTI</span>
    </div>
    <div class="flex justify-end md:justify-end">
        <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" icon="pi pi-user-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Utente"
          (click)="openCreaUtenteDialog()">
        </button>
    </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table #utentiDT styleClass="p-datatable-sm" [value]="utenti" dataKey="nome" [(selection)]="selectedUtente"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="15" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['nome','bloccato']">
     
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="nome">
            <div class="flex justify-content-between align-items-center">
              Nome
              <p-sortIcon field="nome"></p-sortIcon>
              <p-columnFilter type="text" field="nome" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="email">
            <div class="flex justify-content-between align-items-center">
              Mail      
              <p-sortIcon field="nome"></p-sortIcon>
              <p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="impresa">
            <div class="flex justify-content-between align-items-center">
              Impresa      
              <p-sortIcon field="azienda.ragioneSociale"></p-sortIcon>
              <p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="ruolo.codice">
            <div class="flex justify-content-between align-items-center">
              Ruolo
              <p-sortIcon field="ruolo.codice"></p-sortIcon>
              <p-columnFilter type="text" field="ruolo.codice" display="menu"></p-columnFilter>
            </div>
          </th>
      
         <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

          <td> <span class="text-lowercase">{{row.nome}}</span> </td>
          <td> <span class="text-lowercase">{{row.email}}</span> </td>
          <td> <span class="text-lowercase">{{row.azienda?row.azienda.ragioneSociale:''}}</span> </td>
          <td> <span class="text-lowercase">{{row.ruolo?row.ruolo.codice:''}}</span> </td>
         
          <td class="text-center">
          
           <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-user-edit" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Utente"
              (click)="openModificaUtenteDialog(row)">
            </button>
          
            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" style="color:rgb(202, 180, 90);" icon="pi pi-id-card" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Ruolo"
            (click)="openModificaRuoloUtenteDialog(row)">
            </button>

            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" style="color:rgb(150, 109, 203);" icon="pi pi-shopping-cart" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Cambia Impresa"
            (click)="openModificaImpresaUtenteDialog(row)">
            </button>
            
           
            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton pRipple type="button" icon="pi pi-user-minus"
              class="p-button-rounded p-button-text" (click)="openDeleteUtenteDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Utente">
            </button>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>
</div>
<p-toast position="top-center"></p-toast>