 import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';
import { BehaviorSubject, of } from 'rxjs';

Injectable({
  providedIn: 'root'
})
export class VerticalAuthService {
  isLogin = false;
  roleAs: string;
  private authToken: string | null = null;

  constructor() { }
 
  isLoggedIn() {
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem("ruolo");
    return this.roleAs;
  }
  setToken(token: string): void {
    this.authToken = token;
  }

  getToken(): string | null {
    return this.authToken;
  }
}
 