import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';
import { PraticaScuola } from '../models/pratica.scuola.model';
import { DettaglioPraticaScuolaDTO } from '../models/dettaglioPratica.scuola.model';
import { FileCaricatoDTO } from '../models/fileCaricato.model';
import { NotaReworkPratica } from '../models/pratica.nota.scuola.model';
import { NotaPraticaDTO } from '../models/pratica.notaDTO.scuola.model';
import { ModificaPraticaDTO } from '../models/modificaPratica.scuola.model';
import { Centrale } from '../models/centrale.models';
import { PraticaSanita } from '../models/pratica.sanita.model';
import { PraticaSanita_Firestore } from '../models/praticaSanitaFirestore.model';
import { DettaglioPraticaSanitaDTO } from '../models/dettaglioPratica.sanita.model';
import { LavoroPraticaSanita } from '../models/pratica.lavoro.sanita.model';


@Injectable({
  providedIn: 'root',
})
export class LavoriPraticheSanitaService{
  apiUrl: string = `${environment.apiUrl}`+"lavori-pratica-sanita";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    getById(idPratica:string, idlavoro:string): Observable<any> {
      let url = this.apiUrl+"/"+idPratica+"/lavori/"+idlavoro;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    getByPratica(idPratica:number): Observable<any> {
      let url = this.apiUrl+"/pratica/"+idPratica;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    crea(lavoroDaCreare:LavoroPraticaSanita): Observable<any> {
      const urlFinale = this.apiUrl;
      this.logger.info("*** crea lavoro @ "+ urlFinale);
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post( urlFinale, lavoroDaCreare, httpOptions)
    }

    modifica(lavoroDaModificare:LavoroPraticaSanita): Observable<any> {
      const urlFinale = this.apiUrl+"/"+lavoroDaModificare.id;
      this.logger.info("*** MODIFICA lavoro @"+ urlFinale);
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.put(urlFinale, lavoroDaModificare, httpOptions)
   
    }

    

    elimina(lavoroDaEliminare:LavoroPraticaSanita): Observable<any> {
      const urlFinale = this.apiUrl+"/"+lavoroDaEliminare.id;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.delete(urlFinale, httpOptions)
   
    }

   

   

   

    

    


/* 
   

   
    presidioPrendeInCarico(praticaDaPrendereInCarico:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaPrendereInCarico.id+"/in-carico-presidio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    

    generaDocs(praticaDaGenerareDocs:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaGenerareDocs.id+"/genera-docs"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }


    
    annulla(praticaDaAnnullare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaAnnullare.id+"/annulla"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }
   

    

   

    mandaInRework(praticaDaMandareInRework:PraticaScuola, nota: NotaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaMandareInRework.id+"/rework"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, nota, httpOptions)
    }

    inviaAlPresidio(praticaDaConsegnare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaConsegnare.id+"/invia-presidio"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaConsegnare, httpOptions)
   
    }

    

   

    confermaCaricamentoPlanimetria(praticaDaModificare:PraticaScuola, estensioneFile: String): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/planimetria";
      let fileDTO: FileCaricatoDTO = new FileCaricatoDTO();
        fileDTO.estensione = estensioneFile;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, fileDTO, httpOptions)
    }

    

    confermaCaricamentoNT9(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/nt9"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }
    confermaCaricamentoVerbale(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/verbale"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    confermaCaricamentoPacchetto(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/file/pacchetto"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    



    
    comunicaOrdineEsecuzione(praticaDaModificare:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare.id+"/ordine-esecuzione"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, praticaDaModificare, httpOptions)
    }

    completaPratica(idPratica:number): Observable<any> {
      const urlFinale = this.apiUrl+"/"+idPratica+"/completa";
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, httpOptions)
    }

    pubblicazionePratica(idPraticaFirmata:any): Observable<any> {
      const urlFinale = this.apiUrl+"/"+idPraticaFirmata+"/pubblicata"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
    }

    comunicaApprovazionePratica(praticaApprovata:PraticaScuola): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaApprovata.id+"/approvata"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
    }
    comunicaRespingimentoPratica(praticaRespinta:PraticaScuola, nota: NotaPraticaDTO): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaRespinta.id+"/respinta"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      this.logger.info(nota);
      return this.http.post(urlFinale, nota, httpOptions)
    }

    richiediCambioCentrale(praticaDaModificare:number, centrale:Centrale): Observable<any> {
      const urlFinale = this.apiUrl+"/"+praticaDaModificare+"/cambio-centrale"
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, centrale, httpOptions)
   
    }
    
    

  */
 

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}
