<form [formGroup]="centraleForm">

  <div class="grid formgrid p-fluid">
      <h4 class="font-medium text-900">Stai per chiedere agli amministratori di FLOWDY di aggiornare la centrale con le seguenti informazioni:</h4>

      <div class="field mb-1 col-6">
        <label for="indirizzo" class="font-medium text-900">Indirizzo</label>
        <input id="indirizzo" pInputText formControlName="indirizzo" placeholder="Indirizzo">
      </div>
      <div class="field mb-1 col-6">
        <label for="comune" class="font-medium text-900">Comune</label>
        <input id="comune" pInputText formControlName="comune" placeholder="comune">
      </div>

      <div class="field mb-1 col-6">
          <label for="latitudine" class="font-medium text-900">Latitudine</label>
          <p-inputNumber formControlName="latitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
        </div>
        <div class="field mb-1 col-6">
          <label for="longitudine" class="font-medium text-900">Longitudine</label>
          <p-inputNumber formControlName="longitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
        </div>
  </div>
      </form>

      <div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>
      
      <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
        <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
            styleClass="p-button-success"
            [disabled]="!centraleForm.valid"></p-button>
        <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
            styleClass="p-button-warning"></p-button>
      </div>
          
