import { HttpClient } from "@angular/common/http";
import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { map, Observable, Subject, switchMap, tap } from "rxjs";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { InputTextModule } from "primeng/inputtext";

import { ToastModule } from "primeng/toast";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";

import { Router } from "@angular/router";
import { Utente } from "src/app/models/utente.model";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { RuoloDipendente } from "src/app/models/utente.ruolo.model";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";

@Component({
  selector: "app-utenti",
  templateUrl: "./utenti.component.html",
  styleUrls: ["./utenti.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class UtentiComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  utenti: Utente[] = [];
  selectedUtente: Utente;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public isPresidioSF: boolean = false;

  dialogConfig;
  @ViewChild("utentiDT", { static: true }) utentiDT: any;
  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  displayModificaRuolo = false;
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    public userService: UserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private router: Router
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SUPERADMIN"
    ) {
      this.isSuperAdmin = true;
    } else if( localStorage.getItem("ruolo") && localStorage.getItem("ruolo") === "SMARTFIBER"){
      this.isPresidioSF = true;
    }
    else{
      this.isPresidioSF= true;
    }
    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadUtenti();
  }

  ngAfterViewInit() {}

  isAdministrator() {
    return (
      localStorage.getItem("ruolo") === "CONTABILE" ||
      localStorage.getItem("ruolo") === "ADMIN"
    );
  }

  loadUtenti() {
    this.isLoadingResults = true;
    this.utentiService
      .get()
      .pipe(
        map((utenti: Utente[]) => {
          let cleanUtentiList: Utente[] = [];
          utenti.forEach((utente: Utente) => {
            if (utente.ruolo.codice !== "SUPERADMIN") {
              cleanUtentiList.push(utente);
            }
          });
          return cleanUtentiList;
        })
      )
      .subscribe((res) => {
        this.logger.info(res);
        this.utenti = res;
        this.isLoadingResults = false;
      });
  }

  openCreaUtenteDialog() {
    this.ref = this.dialogService.open(DialogCreaUtente, {
      header: 'Crea Utente',
      width: this.isMobile?'90%':'40%',
      height: this.isMobile?'90%':'80%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });
  this.ref.onClose.subscribe(confirmNewUtente => {
   // console.log("AFTER CLOSED > "+ confirmNewTenant.tenantName);
    if(confirmNewUtente && confirmNewUtente.success){
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "Utente creato con successo!", life:10000
      });
      this.loadUtenti();
    }
  });
  }

  openModificaUtenteDialog(utente: Utente): void {
    this.ref = this.dialogService.open(DialogModificaUtente, {
      data: {
        utente: utente,
      },
      header: "Modifica Utente",
      width: this.isMobile ? "90%" : "40%",
      height: this.isMobile ? "60%" : "53%",
      //contentStyle: {"max-height": "500px"},
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirModificaUtente) => {
      //  console.log("AFTER CLOSED > "+ confirModificaUtente.success);
      if (confirModificaUtente && confirModificaUtente.success) {
        console.log("AFTER CLOSED ENTRO DOVE DOVREI.... > ");

        /*  this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Utente modificato!",
        });  */

        this.loadUtenti();
      }
    });
  }

  openModificaRuoloUtenteDialog(utente: Utente): void {
    this.logger.info("> openModificaRuoloUtenteDialog ");
    this.logger.info(utente);
    this.ref = this.dialogService.open(DialogModificaRuoloUtente, {
      data: {
        utente: utente,
      },
      header: "Modifica Ruolo Utente " + utente.nome.toUpperCase(),
      width: this.isMobile ? "70%" : "40%",
      height: this.isMobile ? "60%" : "50%",
      // contentStyle: {"max-height": "500px"},
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmModicaRuoloUtente) => {
      console.log("AFTER CLOSED > " + confirmModicaRuoloUtente.success);
      if (confirmModicaRuoloUtente && confirmModicaRuoloUtente.success) {
        this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Ruolo dell'utente aggiornato con successo!", life:10000
        });
        this.loadUtenti();
      }
    });
  }

  openModificaImpresaUtenteDialog(utente: Utente): void {
    
    this.ref = this.dialogService.open(DialogModificaAziendaUtente, {
      data: {
        utente: utente,
      },
      header: "Modifica Azienda Utente " + utente.nome.toUpperCase(),
      width: this.isMobile ? "70%" : "40%",
      height: this.isMobile ? "60%" : "50%",
      // contentStyle: {"max-height": "500px"},
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmModicaAziendUtente) => {
      console.log("AFTER CLOSED > " + confirmModicaAziendUtente.success);
      if (confirmModicaAziendUtente && confirmModicaAziendUtente.success) {
        this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Azienda dell'utente aggiornata con successo!", life:10000
        });
        this.loadUtenti();
      }
    });
  }

  openDeleteUtenteDialog(utente: Utente): void {
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell'utente ${utente.nome.toUpperCase()}?`,
      header: "Eliminazione Utente",
      icon: "pi pi-info-circle",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.utentiService.elimina(utente.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione utente", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Utente eliminato", life:10000
            });
            this.loadUtenti();
          },
        });
      },
      reject: () => {},
    });
  }
}

@Component({
  templateUrl: "./dialog-crea-utente.component.html",
  styleUrls: ["./utenti.component.css"],
})
export class DialogCreaUtente implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloDipendente[];
  protected imprese: Impresa[];
  protected filteredAziende: Impresa[];

  public nome: String = "";
  public email: String = "";
  public password: String = "";
  public ruolo: RuoloDipendente;
  public azienda: Impresa;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private impreseService: ImpreseService,
    private messageService: MessageService
  ) {
    this.ruoli = [
      //   { id: 1, chiave: 'SUPERADMIN', idAuth0:'rol_RhpgZAc4PYRng2pA', descrizione:'' },
      {
        id: 2,
        codice: "ADMIN",
        idAuth0: "rol_dS74S3N5ObwDRo4c",
        descrizione: "",
      },
      {
        id: 3,
        codice: "PRESIDIO",
        idAuth0: "rol_hQT59XaTwT9Gx9NK",
        descrizione: "",
      },
      {
        id: 4,
        codice: "SMARTFIBER",
        idAuth0: "rol_NB6QxKJuRs4IF24d",
        descrizione: ".",
      },
      {
        id: 5,
        codice: "OPERATORE_AZIENDA",
        idAuth0: "rol_2TGQnu7tbhEpofh7",
        descrizione:
          "Può accedere e modificare le pratiche assegnate all'azienda di appartenza.",
      },
      {
        id: 6,
        codice: "DIRIGENTE_FIRMA_VERBALI",
        idAuth0: "rol_43OwbLGiLtEjbIUL",
        descrizione:
          "Può firmare i verbali.",
      },
      {
        id: 7,
        codice: "DIRIGENTE_FIRMA_PACCHETTI",
        idAuth0: "rol_MUhb3KQE0mqor6Lq",
        descrizione:
          "Può firmare i pacchetti.",
      },
    ];
  }

  ngOnInit(): void {
    this.loadImprese();
  }

  loadImprese() {
    this.impreseService.get().subscribe((res) => {
      this.logger.info(res);
      this.imprese = res;
    });
  }

  filterAzienda(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.imprese.length; i++) {
        let comune = this.imprese[i];
        if (comune.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredAziende = filtered;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaCreare: Utente = new Utente();
      utenteDaCreare.nome = this.nome;
      utenteDaCreare.email = this.email;
      utenteDaCreare.password = this.password;
      utenteDaCreare.ruolo = this.ruolo;
      utenteDaCreare.azienda = this.azienda;

      this.utentiService.crea(utenteDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE UTENTE" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione utente!", life:10000
          });
        }
      );
    }
  }
}

@Component({
  templateUrl: "./dialog-modifica-utente.component.html",
  styleUrls: ["./utenti.component.css"],
})
export class DialogModificaUtente implements OnInit {
  @ViewChild("supportNgForm", { static: true }) supportNgForm: NgForm;

  protected _onDestroy = new Subject<void>();
  public utenteSelezionato: Utente;
  public nome: String = "";
  public modificaDipendenteForm: FormGroup;

  constructor(
    private logger: NGXLogger,
    private _formBuilder: FormBuilder,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.utenteSelezionato = this.config.data.utente;

    this.modificaDipendenteForm = this._formBuilder.group({
      nome: [this.utenteSelezionato.nome, [Validators.required]],
      email: [
        this.utenteSelezionato.email,
        [Validators.required, Validators.email],
      ],
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if (this.modificaDipendenteForm.valid) {
        let utenteDaModificare: Utente = new Utente();
        utenteDaModificare.id = this.utenteSelezionato.id;
        if (
          this.utenteSelezionato.nome !== this.modificaDipendenteForm.value.nome
        ) {
          utenteDaModificare.nome = this.modificaDipendenteForm.value.nome;
        }

        /*  if(this.utenteSelezionato.email!== this.modificaDipendenteForm.value.email){
              utenteDaModificare.email       = this.modificaDipendenteForm.value.email;
            } */

        this.utentiService.modifica(utenteDaModificare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di modifica utente", life:10000
            });
            this.dialogRef.close({ success: true });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Utente modificato", life:10000
            });
            this.dialogRef.close({ success: true });
          },
        });
      }
    }
  }
}

@Component({
  templateUrl: "./dialog-modifica-ruolo-utente.component.html",
  styleUrls: ["./utenti.component.css"],
})
export class DialogModificaRuoloUtente implements OnInit {
  @ViewChild("supportNgForm", { static: true }) supportNgForm: NgForm;

  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloDipendente[];
  public ruolo: RuoloDipendente;
  public modificaRuoloForm: FormGroup;
  public utenteSelezionato: Utente;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    private _formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService
  ) {
    this.ruoli = [
      //   { id: 1, chiave: 'SUPERADMIN', idAuth0:'rol_RhpgZAc4PYRng2pA', descrizione:'' },
      {
        id: 2,
        codice: "ADMIN",
        idAuth0: "rol_dS74S3N5ObwDRo4c",
        descrizione: "",
      },
      {
        id: 3,
        codice: "PRESIDIO",
        idAuth0: "rol_hQT59XaTwT9Gx9NK",
        descrizione: "",
      },
      {
        id: 4,
        codice: "SMARTFIBER",
        idAuth0: "rol_NB6QxKJuRs4IF24d",
        descrizione: ".",
      },
      {
        id: 5,
        codice: "OPERATORE_AZIENDA",
        idAuth0: "rol_2TGQnu7tbhEpofh7",
        descrizione:
          "Può accedere e modificare le pratiche assegnate all'azienda di appartenza.",
      },
      {
        id: 6,
        codice: "DIRIGENTE_FIRMA_VERBALI",
        idAuth0: "rol_43OwbLGiLtEjbIUL",
        descrizione:
          "Può firmare i verbali.",
      },
      {
        id: 7,
        codice: "DIRIGENTE_FIRMA_PACCHETTI",
        idAuth0: "rol_MUhb3KQE0mqor6Lq",
        descrizione:
          "Può firmare i pacchetti.",
      },
    ];
  }

  ngOnInit(): void {
    this.utenteSelezionato = this.config.data.utente;
    this.ruolo = this.utenteSelezionato.ruolo;
    this.modificaRuoloForm = this._formBuilder.group({
      ruolo: [this.utenteSelezionato.ruolo, [Validators.required]],
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaModificare: Utente = this.utenteSelezionato;
      utenteDaModificare.ruolo = this.modificaRuoloForm.value.ruolo;
      this.utentiService.modifica(utenteDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica del ruolo dell'utente!", life:10000
          });
        }
      );

      /*  */
    }
  }
}

@Component({
  templateUrl: "./dialog-modifica-azienda-utente.component.html",
  styleUrls: ["./utenti.component.css"],
})
export class DialogModificaAziendaUtente implements OnInit {
  @ViewChild("supportNgForm", { static: true }) supportNgForm: NgForm;

  protected _onDestroy = new Subject<void>();
  protected imprese: Impresa[];
  protected filteredAziende:Impresa[]=[];
  //public azienda:Impresa;
  public modificaImpresaForm: FormGroup;
  public utenteSelezionato: Utente;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    private _formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService,
    private impreseService: ImpreseService
  ) {}

  ngOnInit(): void {
    this.loadImprese();
    this.utenteSelezionato = this.config.data.utente;
    //this.azienda = this.utenteSelezionato.azienda;
    this.logger.info(this.utenteSelezionato);
    //this.logger.info(this.azienda );
    this.modificaImpresaForm = this._formBuilder.group({
      azienda: [this.utenteSelezionato.azienda, [Validators.required]],
    });
  }

  loadImprese() {
    this.impreseService.get().subscribe((res) => {
      this.logger.info(res);
      this.imprese = res;
    });
  }

  filterAzienda(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.imprese.length; i++) {
        let comune = this.imprese[i];
        if (comune.ragioneSociale.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredAziende = filtered;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaModificare: Utente = this.utenteSelezionato;
      utenteDaModificare.azienda = this.modificaImpresaForm.value.azienda;
      this.logger.info("STO MANDANDO QUESTO OGGETTO");
      this.logger.info(utenteDaModificare);
      this.utentiService.modifica(utenteDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica dell'azienda dell'utente!", life:10000
          });
        }
      );
    }
  }
}
