import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';
import { PraticaScuola } from '../models/pratica.scuola.model';
import { DettaglioPraticaScuolaDTO } from '../models/dettaglioPratica.scuola.model';
import { FileCaricatoDTO } from '../models/fileCaricato.model';
import { NotaReworkPratica } from '../models/pratica.nota.scuola.model';
import { NotaPraticaDTO } from '../models/pratica.notaDTO.scuola.model';
import { ModificaPraticaDTO } from '../models/modificaPratica.scuola.model';
import { Centrale } from '../models/centrale.models';
import { PraticaSanita } from '../models/pratica.sanita.model';
import { PraticaSanita_Firestore } from '../models/praticaSanitaFirestore.model';
import { DettaglioPraticaSanitaDTO } from '../models/dettaglioPratica.sanita.model';
import { PianoTecnicoSanita } from '../models/pianoTecnicoSanita.model';


@Injectable({
  providedIn: 'root',
})
export class PianoTecnicoSanitaService{
  apiUrl: string = `${environment.apiUrl}`+"piano-tecnico-sanita";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    getById(idPianoTecnico:string): Observable<any> {
      let url = this.apiUrl+"/"+idPianoTecnico;
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    getPraticheConsegnate(idPianoTecnico:any): Observable<any> {
      let url = this.apiUrl+"/"+idPianoTecnico+"/consegnate";
      
      const headers = {
       "Content-Type": "application/json"
      };
  
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }; 
  
      return this.http.get(url, requestOptions);
    }

    modifica(pianoTecnicoSanita:PianoTecnicoSanita): Observable<any> {
      const headers = {
        //  'Authorization': `Bearer ${token}`,
        };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.put(this.apiUrl+"/"+pianoTecnicoSanita.id, pianoTecnicoSanita, httpOptions);
    }
    crea(pianoTecnicoSanita:PianoTecnicoSanita): Observable<any> {
      const headers = {
        //  'Authorization': `Bearer ${token}`,
        };
      const httpOptions = {
        headers,
        reportProgress: true,
      };

      return this.http.post(this.apiUrl, pianoTecnicoSanita, httpOptions);
    }
  
    elimina(idPianoTecnico:number): Observable<any> {
      const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.delete(this.apiUrl+"/"+idPianoTecnico, httpOptions);
    }

    generaRET(idPianoTecnico:number): Observable<any> {
      const urlFinale = `${environment.apiUrl}`+"doc/ret-sanita/"+idPianoTecnico;
      const headers = {
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(urlFinale, null, httpOptions)
   
    }

    
}
