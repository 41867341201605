
    <form [formGroup]="modificaImpresaForm">
    <div class="grid formgrid p-fluid">

        <div class="field mb-4 col-12">
          <label for="ragioneSociale" class="font-medium text-900">Ragione Sociale</label>
          <input id="ragioneSociale" type="text" type="chiave" pInputText formControlName="ragioneSociale">
          <small class="p-error" *ngIf="modificaImpresaForm.get('ragioneSociale').hasError('required')">
            La ragione sociale è obbligatoria
          </small>
        </div>

    </div>
  </form>
    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
    <div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
       <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success" [disabled]="!modificaImpresaForm.valid"></p-button>
        <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning" ></p-button>
    </div>
