<div class="surface-card p-4 shadow-2 border-round mt-1 mr-1 ml-1 mb-1">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <!--  <button pButton type="button" icon="pi pi-download" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(51, 137, 213); color: white;" tooltipPosition="top" pTooltip="Scarica Report"
      (click)="esportaImprese()" [disabled]="isLoadingResults"></button>
     <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" 
      (click)="loadImprese()"></button>  -->
       <button *ngIf="(this.isAdmin || this.isSuperAdmin) && disableSingleActionButtons" pButton pRipple type="button" icon="pi pi-paperclip"
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(60, 175, 179, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Assegna pratiche selezionate" (click)="assegnaPraticheInBulk()" 
        [disabled]="!disableSingleActionButtons">
      </button> 

      <button *ngIf="(this.isSuperAdmin || this.isDirigenteFirmaVerbali || this.isDirigenteFirmaPacchetti)" pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(11, 77, 80, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Scarica tutti i documenti da firmare" (click)="scaricaPacchettiInBulk()" >
        <i class="material-symbols-outlined">
          system_update_alt
        </i>
      </button> 
      <button *ngIf="(this.isSuperAdmin || this.isDirigenteFirmaVerbali || this.isDirigenteFirmaPacchetti)" pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(90, 166, 170, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Carica tutti i documenti firmati" (click)="caricaPacchettiFirmatiInBulk()"
        [disabled]="isLoadingResults" 
        >
        <i class="material-symbols-outlined">
            drive_folder_upload
        </i>
      </button> 

    </div>

    <div class="flex justify-center">
      <div *ngIf="!filtroPratica">
        <span  class="text-xl font-medium text-2000" style="color: cornflowerblue">PRATICHE SANITA </span>
        <span *ngIf="filterIsSet && !filtroPT" class="text-xl font-medium text-2000" style="color: cornflowerblue">IN STATO</span>
        <span *ngIf="filterIsSet && !filtroPT" class="text-xl font-medium text-2000" style="color: rgb(59, 101, 7)">&nbsp;{{filtroStato}}</span>
      
      </div>
      <div *ngIf="filtroPratica && !filtroPT">
        <span class="text-xl font-medium text-2000" style="color: cornflowerblue">PRATICA </span><span *ngIf="filterIsSet" class="text-xl font-medium text-2000" style="color: rgb(10, 31, 71)"> {{filtroPratica}}</span>
      </div>
      <div *ngIf="filtroPT">
        <span class="text-xl font-medium text-2000" style="color: cornflowerblue">DEL PIANO TECNICO</span><span *ngIf="filterIsSet" class="text-xl font-medium text-2000" style="color: rgb(10, 31, 71)"> {{filtroPT}}</span>
      </div>
    
    </div>

    <div class="flex justify-end md:justify-end">
      <div class="flex justify-end md:justify-end">
        <button pButton pRipple type="button"  pTooltip="Aggiorna Pratiche" *ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF"
          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3" 
          style="width:49px; height: 49px; background-color: rgb(83, 213, 150); border-color:rgb(83, 213, 150);" (click)="openAggiornaPratiche()">
          <i class="material-symbols-outlined"> move  </i>
        </button>
        <button *ngIf="isAdmin || isSuperAdmin" pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(28, 116, 89); color: white;"  tooltipPosition="top" pTooltip="Nuova pratica bando 2"
          (click)="openCreaPraticaDialog()">
        </button>
    </div>
      <button *ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF" pButton type="button" icon="pi pi-upload"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(117, 189, 145); color: white;"
        tooltipPosition="top" pTooltip="Importa Pratiche SANITA" (click)="caricaPraticheSanita()"> </button>
    </div>
  </div>
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
          (input)="filtraPerNome($event)" />
      </span>
      <div class="mt-1 flex align-items-start flex-column md:flex-row">
        <span *ngIf="!filterIsSet "
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
          style="border-radius: 20px" (click)="menuStati.toggle($event)" pRipple>
          Stato Pratica<i class="ml-2 pi pi-angle-down text-700"></i>
        </span>

        <p-menu #menuStati [popup]="true" [model]="statiPratica"></p-menu>
        <span *ngIf="!filterIsSet "
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
          style="border-radius: 20px" (click)="menuTipiSede.toggle($event)" pRipple>
          Tipo Sede<i class="ml-2 pi pi-angle-down text-700"></i>
        </span>

        <p-menu #menuTipiSede [popup]="true" [model]="tipiSede"></p-menu>
        <span
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
          style="border-radius: 20px" (click)="menuRegioni.toggle($event)" pRipple>
          Regione <i class="ml-2 pi pi-angle-down text-700"></i>
        </span>
        <p-menu #menuRegioni [popup]="true" [model]="regioniPratica"></p-menu>
        <span *ngIf="!isImpresa"
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
          style="border-radius: 20px" (click)="menuImprese.toggle($event)" pRipple>
          Impresa <i class="ml-2 pi pi-angle-down text-700"></i>
        </span>
        <p-menu #menuImprese [popup]="true" [model]="imprese"></p-menu>
       
        <span *ngIf="!isImpresa" class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
        style="border-radius: 20px" (click)="applyHideClosedFilter()" >{{testoFiltroAnnullate}} Annullate</span>
      </div>
    </div>
    <div class="flex justify-start md:justify-end">
        <button *ngIf="filterIsSet" pButton pRipple type="button" icon="pi pi-filter-slash"
          class="py-2 px-3 md:mb-0 mr-0 md:mr-3 p-button-rounded p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
          (click)="reloadDataRemovingFilter()" [disabled]="isLoadingResults"></button>
    </div>
  </div>
  <div  fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    </p-confirmDialog>

    <p-table #praticheSanitaDT styleClass="p-datatable-sm" [value]="praticheAtFirestore" dataKey="id"
      [(selection)]="selectedPratiche" [selectionPageOnly]="true" [rowHover]="true" [rows]="15"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" 
      responsiveLayout="stack" [paginator]="true" currentPageReportTemplate="{first} - {last} di {totalRecords}"
      [filterDelay]="0"
      [globalFilterFields]="['id','codiceInfratel','pianoTecnico','denominazione','comune','indirizzo']">

      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="this.isAdmin || this.isSuperAdmin">
            <p-tableHeaderCheckbox (click)="onPraticaSelection()"></p-tableHeaderCheckbox>
          </th>
          
          <th class="text-center" style="font-size: 14px;">Azioni</th>     
          <th pSortableColumn="pianoTecnico" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Piano Tecnico
            </div>
          </th>     
          <th pSortableColumn="codiceInfratel" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Codice INFRATEL
              <p-sortIcon field="codiceInfratel"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="denominazione" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Denominazione stuttura
            </div>
          </th> 
          <th *ngIf="!isImpresa" pSortableColumn="azienda" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Impresa
            </div>
          </th>
          <th pSortableColumn="comune" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Comune
              <p-sortIcon field="comune"></p-sortIcon>
           </div>
          </th>
          <th pSortableColumn="regione" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Regione
             </div>
          </th>
          <th pSortableColumn="indirizzo" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Indirizzo
              <p-sortIcon field="indirizzo"></p-sortIcon>
             </div>
          </th>
          <th class="text-center" style="font-size: 13px;">
            Accesso
          </th>
          <th class="text-center" style="font-size: 13px;">
            Classif.
          </th>
          
      
          <th class="text-center" style="font-size: 13px;" *ngIf="!isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali && !isPresidioTIM">
            <div class="flex justify-content-between align-items-center">
              Backup
            </div>
          </th>
          <th *ngIf="!isImpresa && !isPresidioTIM && !isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Lavorabile
            </div>
          </th>
          <th class="text-center" style="font-size: 13px;">
            <div >
              Stato
            </div>
          </th>
         
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
          <td *ngIf="(this.isAdmin || this.isSuperAdmin)  ">
            <p-tableCheckbox [value]="row" (click)="onPraticaSelection(row)" [disabled]="!isDaAssegnare(row)"></p-tableCheckbox>
          </td>
          <td class="text-center">
            <button *ngIf=" showPulsante(row)" 
              pButton type="button" style="color:rgb(39, 128, 15)"
              icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" 
              (click)="openCaricaAzioniTieredPratica($event,row)">
            </button> 
          </td>
          <td> <span class="text-lowercase " style="font-size: 10px;" pTooltip="{{row.pianoTecnico}}" >{{row.pianoTecnico}} </span> </td>

          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.denominazione}}" >{{row.codiceInfratel}} </span> </td>
          <td > <span class="text-lowercase " style="font-size: 12px;" pTooltip="{{row.denominazione}}"  [ngClass]="getColocataFontClass(row)">{{row.denominazione}}</span> </td>
           <td *ngIf="!isImpresa"> <span class="text-lowercase " style="font-size: 10px;">{{row.azienda}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11px;">{{row.comune}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11px;">{{row.regione}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 10px;">{{row.indirizzo}}</span> </td>
         <td *ngIf="row.tipoSedeEffettiva" class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoSede}} ({{row.tipoSedeEffettiva}})</span> </td>
        <td class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoStrutturaSanitaria}}</span> </td>

       
          <td class="text-center" *ngIf="!isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali && !isPresidioTIM">
            <i class="pi" [ngClass]="{ 'text-blue-700 pi-check': row.backup, '': !row.backup }"></i>
          </td>
          <td *ngIf="!isImpresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti && !isPresidioTIM" class="text-center">
            <i class="pi"
              [ngClass]="{ 'text-green-500 pi-check-circle': row.lavorabile, 'text-red-500 pi-times-circle': !row.lavorabile }"></i>
          </td>
          <td class="text-center">
            <span [ngClass]="getStatoClass(row.stato)">{{row.stato}}</span> </td>

          
        </tr>
      </ng-template>
     
    </p-table>
    <p-menu #menuAzioniAzienda [popup]="true" [model]="menuAzioniAziendaItems"></p-menu>
    <p-tieredMenu #menuAzioniTiered [popup]="true" [model]="menuAzioniItems"></p-tieredMenu>

    <p-menu #menuScaricaFile [popup]="true" [model]="menuScaricaFileItems"></p-menu>
    <p-menu #menuCaricaFile [popup]="true" [model]="menuCaricaFileItems"></p-menu>
    <p-menu #menuRisultatoInvioInfratel [popup]="true" [model]="menuRisultatiInvioPraticaItems" ></p-menu>

  </div>
</div>

<p-toast position="top-center"></p-toast>