import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StatsService {
  public isDataUpdated:boolean   = false;
  public hideNotificationBadge:boolean   = true;
 
  constructor() {
   
  }


}
