import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";
import { environment } from "../../../../../environments/environment";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { AngularFirestore } from "@angular/fire/compat/firestore";

import { Subject } from "rxjs";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PraticaScuola_Firestore } from "src/app/models/praticaScuolaFirestore.model";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { PraticheScuolaService } from "src/app/services/pratiche.scuola.service";
import * as Leaflet from "leaflet";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Centrale } from "src/app/models/centrale.models";
import { CentraliService } from "src/app/services/centrali.service";
import { ModificaPraticaDTO } from "src/app/models/modificaPratica.scuola.model";

Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

@Component({
  selector: "app-cambio-codice-invio-bando2",
  templateUrl: "./dialog-aggiornamento-codiceInvio-pratica.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogModificaCodiceInvioPratica implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaScuola_Firestore;


  protected codiceInvioForm: FormGroup;

  protected ref: DynamicDialogRef;

  private collection_pratiche_scuola: string = environment.firebase.collection_pratiche_scuola;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _formBuilder: FormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    private praticheScuolaService: PraticheScuolaService,
  ) {
   
  }

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
    this.codiceInvioForm = this._formBuilder.group({
      codiceInvio: [this.pratica.codiceInvio, [Validators.required]],
     
    });
     }

 
  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

      

      let praticaDaModificare: ModificaPraticaDTO = new ModificaPraticaDTO();
      praticaDaModificare.codiceInvio         = this.codiceInvioForm.value.codiceInvio;
      praticaDaModificare.id  = this.pratica.id;

      this.logger.info(praticaDaModificare);
      this.praticheScuolaService
            .modificaCodiceInvio(praticaDaModificare)
            .subscribe({
              next: (v) => {},
              error: (e) => {
                this.messageService.add({
                  severity: "info",
                 summary: "Errore" , life:10000,
                  detail: "Errore in fase di MODIFICA del codice INVIO della pratica",
                });
                this.dialogRef.close({ success: false });
              },
              complete: () => {
                this.dialogRef.close({ success: true });
              
              },
            });
      

      
    }
  }
}
