<form [formGroup]="modificaCentraleForm">
  <div class="grid formgrid p-fluid">
      <div class="field mb-1 col-3">
          <label for="clli" class="font-medium text-900">CLLI</label>
          <input id="clli" type="text" pInputText formControlName="clli" placeholder="CLLI">
       
      </div>
      <div class="field mb-1 col-3">
          <label for="clliAttestazione" class="font-medium text-900">CLLI Attestazione</label>
          <input id="clliAttestazione" pInputText formControlName="clliAttestazione" placeholder="CLLI Attestazione">
      </div>
      <div class="field mb-1 col-6">
            <label for="denominazione"class="font-medium text-900">Denominazione</label>
            <input id="denominazione" pInputText formControlName="denominazione" placeholder="Denominazione">
        </div>
      <div class="surface-border border-top-1 opacity-50 mb-3 mt-3 col-12"></div>

      <div class="field mb-1 col-6">
        <label for="indirizzo" class="font-medium text-900">Indirizzo</label>
        <input id="indirizzo" pInputText formControlName="indirizzo" placeholder="Indirizzo">
    </div>
    
    <div class="field mb-1 col-6">
        <label for="comune" class="font-medium text-900">Comune</label>
        <p-autoComplete 
            formControlName="comune" 
            [suggestions]="filteredComuni" 
            (completeMethod)="filterComune($event)"
            field="denominazione" placeholder="Cerca un comune"
            ></p-autoComplete>

        <!-- <p-dropdown inputId="comune" [options]="comuni" optionLabel="denominazione" [filter]="true" filterBy="denominazione"
            [showClear]="true" placeholder="Comune" formControlName="comune" 
            [virtualScroll]="true" [virtualScrollItemSize]="10">
            <ng-template let-comune pTemplate="item">
                <div class="flex align-items-center">
                    <div>{{comune.denominazione}}</div>
                </div>
            </ng-template>
        </p-dropdown> -->
    </div>
    <div class="surface-border border-top-1 opacity-50 mb-3 mt-3 col-12"></div>

    <div class="field mb-1 col-6">
        <label for="latitudine" class="font-medium text-900">Latitudine</label>
        <p-inputNumber formControlName="latitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
      </div>
      <div class="field mb-1 col-6">
        <label for="longitudine" class="font-medium text-900">Longitudine</label>
        <p-inputNumber formControlName="longitudine" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" > </p-inputNumber>
      </div>
  </div>
  
 
</form>

<div class="surface-border border-top-1 opacity-50 mb-3 mt-2 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
  <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
      styleClass="p-button-success"
      [disabled]="!modificaCentraleForm.valid"></p-button>
  <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
      styleClass="p-button-warning"></p-button>
</div>
    
