import { ComuneDTO } from "./comune.model";

export class RichiestaAggiornamentoCentrale {
   
    clli:String;
    nuovaLongitudine:Number;
    nuovaLatitudine:Number;
    nuovoIndirizzo:String;
    impresa: String;

  }