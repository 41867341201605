import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
//import { VerticalAuthService } from './auth.service';
//import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    //private authService: VerticalAuthService, 
   // private userService: UserService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const allowedRoles = route.data.allowedRoles;
    
   // if (this.userService.getUser() && allowedRoles.includes(this.userService.getUser().ruolo.codice)) {
    if (localStorage.getItem("isLoggedIn") && allowedRoles.includes(localStorage.getItem("ruolo"))) {

      return true;
    }
    // Reindirizza l'utente alla pagina di accesso o alla pagina non autorizzata
    return this.router.parseUrl(''); 
  }
}
 