import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ChildActivationStart, NavigationStart, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  public isProdEnv:boolean = false;
  constructor(private authService: AuthService,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private translateService: TranslateService,    
    private logger: NGXLogger

    ) {}


  title = 'Flowdy';
 

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.translateNg();

    if(environment.name!=='DEV' && environment.name!=='TEST'){
      this.logger.info("APP IS SPINNING UP IN PROD ENV using API URL "+ environment.apiUrl + " & FIREBASE SCUOLA COLLECTION "+ environment.firebase.collection_pratiche_scuola + " @ "+environment.firebase.projectId);
      this.isProdEnv = true;
    }else{
      this.logger.info("APP IS SPINNING UP IN TEST/DEV ENV using API URL "+ environment.apiUrl + " & FIREBASE SCUOLA COLLECTION "+ environment.firebase.collection_pratiche_scuola + " @ "+environment.firebase.projectId);
    }

    const app = initializeApp(environment.firebase);

  } 


   translateNg(): void {
    this.translateService.setDefaultLang('it');
    this.translateService
      .get('primeng')
      .subscribe((res) => this.primengConfig.setTranslation(res));
  } 

}
