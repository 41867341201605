import { ComuneDTO } from "./comune.model";
import { Utente } from "./utente.model";

export class PianoTecnicoSanita {
    id: Number;

    descrizione:String;
    pdc:String;
    cig:String;

    comune:ComuneDTO;

    creato: Date;
    modificato: Date;

    completo:boolean;
    completato:Date;

  }
  