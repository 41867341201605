
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
  <p class="line-height-3 p-0 mt-1">
   Seleziona la foto del router o trascinala nell'area sottostante. Sono accettati i formati JPG, JPEG e PNG. Si consiglia il caricamento di immagini orientate <u>VERTICALMENTE</u>.
  </p>
  <h5>Dopo la generazione del PSI verificare il corretto orientamento dell'immagine. Se questa risultasse orientata in modo errato, utilizzare un tool come <a href="https://www.iloveimg.com/it/editor-di-foto" target="_blank">questo</a> per aprire l'immagine e, senza modificarla, salvarla. Una volta ricaricata l'immagine nel sistema, rigenerare la documentazione.</h5>
  <p-fileUpload 
  multiple="false"
  maxFileSize="15000000"
  chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onFileUpload($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [disabled]="isFileUploaded"
  (onRemove)="onFileRemove()"
  accept=".JPEG, .PNG, .jpg, .jpeg, .png"
  >
</p-fileUpload>

<div  class="flex upload-file-div-box box mt-2">

    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isFileUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>

</div>

