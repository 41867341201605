<div class="surface-card p-4 shadow-2 border-round mt-1 mr-1 ml-1 mb-1">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <!--  <button pButton type="button" icon="pi pi-download" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(51, 137, 213); color: white;" tooltipPosition="top" pTooltip="Scarica Report"
      (click)="esportaImprese()" [disabled]="isLoadingResults"></button>
     <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" 
      (click)="loadImprese()"></button>  -->
       <button *ngIf="(this.isAdmin || this.isSuperAdmin) && disableSingleActionButtons" pButton pRipple type="button" icon="pi pi-paperclip"
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(60, 175, 179, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Assegna pratiche selezionate" (click)="assegnaPraticheInBulk()" 
        [disabled]="!disableSingleActionButtons">
      </button> 

      <button *ngIf="(this.isSuperAdmin || this.isDirigenteFirmaVerbali || this.isDirigenteFirmaPacchetti)" pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(11, 77, 80, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Scarica tutti i documenti da firmare" (click)="scaricaPacchettiInBulk()" >
        <i class="material-symbols-outlined">
          system_update_alt
        </i>
      </button> 
     <!--  <button *ngIf="(this.isSuperAdmin || this.isDirigenteFirmaVerbali || this.isDirigenteFirmaPacchetti)" pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined"
        style="background-color: rgba(90, 166, 170, 0.9); color: white;" tooltipPosition="top"
        pTooltip="Carica tutti i documenti firmati" (click)="caricaPacchettiFirmatiInBulk()"
        [disabled]="isLoadingResults" 
        >
        <i class="material-symbols-outlined">
            drive_folder_upload
        </i>
      </button>  -->

    </div>

    <div class="flex justify-center">
      <span *ngIf="!filtroPratica" class="text-xl font-medium text-2000" style="color: cornflowerblue">PRATICHE SCUOLA DEL BANDO 2 &nbsp; </span><span *ngIf="filterIsSet" class="text-xl font-medium text-2000" style="color: cornflowerblue">CON STATO</span><span *ngIf="filterIsSet" class="text-xl font-medium text-2000" style="color: rgb(59, 101, 7)">&nbsp;{{filtroStato}}</span>
      <span *ngIf="filtroPratica" class="text-xl font-medium text-2000" style="color: cornflowerblue">PRATICA &nbsp; </span><span *ngIf="filterIsSet" class="text-xl font-medium text-2000" style="color: rgb(10, 31, 71)"> {{filtroPratica}}</span>
    
    </div>

    <div class="flex justify-end md:justify-end">
      <div class="flex justify-end md:justify-end">
        <button pButton pRipple type="button"  pTooltip="Aggiorna Pratiche" *ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF"
          class="inline-flex justify-content-center align-items-center text-xl text-white border-circle mb-1 mr-2 p-3" 
          style="width:49px; height: 49px; background-color: rgb(83, 213, 150); border-color:rgb(83, 213, 150);" (click)="openAggiornaPratiche()">
          <i class="material-symbols-outlined"> move  </i>
        </button>
        <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(28, 116, 89); color: white;"  tooltipPosition="top" pTooltip="Nuova pratica bando 2"
          (click)="openCreaPraticaDialog()">
        </button>
    </div>
      <button *ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF" pButton type="button" icon="pi pi-upload"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(117, 189, 145); color: white;"
        tooltipPosition="top" pTooltip="Importa Pratiche BANDO 2" (click)="caricaPraticheScuola()"> </button>
        <button *ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF || this.isPresidioTIM  || this.isImpresa" pButton type="button" icon="pi pi-download"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(148, 121, 206); color: white;"
        tooltipPosition="top" pTooltip="Esporta Pratiche BANDO 2" (click)="esportaPratiche()"> </button>
       <!--  <button ngIf="this.isAdmin || this.isSuperAdmin || this.isPresidioSF" pButton type="button" icon="pi pi-download" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(51, 137, 213); color: white;" tooltipPosition="top" pTooltip="Scarica Report"
        (click)="esportaPratiche()" [disabled]="isLoadingResults"></button>  -->
    </div>
   
  </div>
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
          (input)="filtraPerNome($event)" />
      </span>
      <div class="mt-1 flex align-items-start flex-column md:flex-row">
        <span *ngIf="!filterIsSet "
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
          style="border-radius: 20px;" (click)="menuStati.toggle($event)" pRipple>
          Stato Pratica<i class="ml-2 pi pi-angle-down text-700"></i>
        </span>

        <p-menu #menuStati [popup]="true" [model]="statiPratica" ></p-menu>
        <span *ngIf="!filterIsSet "
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
          style="border-radius: 20px" (click)="menuTipiSede.toggle($event)" pRipple>
          Tipo Sede<i class="ml-2 pi pi-angle-down text-700"></i>
        </span>

        <p-menu #menuTipiSede [popup]="true" [model]="tipiSede"></p-menu>
        <span
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
          style="border-radius: 20px" (click)="menuRegioni.toggle($event)" pRipple>
          Regione <i class="ml-2 pi pi-angle-down text-700"></i>
        </span>
        <p-menu #menuRegioni [popup]="true" [model]="regioniPratica"></p-menu>
        <span *ngIf="!isImpresa"
          class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
          style="border-radius: 20px" (click)="menuImprese.toggle($event)" pRipple>
          Impresa <i class="ml-2 pi pi-angle-down text-700"></i>
        </span>
        <p-menu #menuImprese [popup]="true" [model]="imprese"></p-menu>
       
        <span *ngIf="!isImpresa" class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
        style="border-radius: 20px" (click)="applyHideClosedFilter()" >{{testoFiltroAnnullate}} Annullate</span>
      </div>
    </div>
    <div class="flex justify-start md:justify-end">
        <button *ngIf="filterIsSet" pButton pRipple type="button" icon="pi pi-filter-slash"
          class="py-2 px-3 md:mb-0 mr-0 md:mr-3 p-button-rounded p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
          (click)="reloadDataRemovingFilter()" [disabled]="isLoadingResults"></button>
    </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    </p-confirmDialog>

    <p-table #praticheScuolaDT styleClass="p-datatable-sm" [value]="praticheAtFirestore" dataKey="id"
      [(selection)]="selectedPratiche" [selectionPageOnly]="true" [rowHover]="true" [rows]="15"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" 
      responsiveLayout="stack" [paginator]="true" currentPageReportTemplate="{first} - {last} di {totalRecords}"
      [filterDelay]="0"
      [globalFilterFields]="['id','codiceInfratel','codiceInvio','codiceMiur','denominazione','comune','indirizzo']">

      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="this.isAdmin || this.isSuperAdmin">
            <p-tableHeaderCheckbox (click)="onPraticaSelection()"></p-tableHeaderCheckbox>
          </th>
          
          <th style="width: 10px;" style="font-size: 12px;" >Azioni</th>     
          <th *ngIf="isImpresa || isPresidioSF " tyle="width: 8%; font-size: 13px;"> </th>     
          <th *ngIf="!isImpresa" pSortableColumn="codiceInfratel" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Codice INFRATEL
              <p-sortIcon field="codiceInfratel"></p-sortIcon>
            </div>
          </th>
          <th *ngIf="isPresidioSF || isPresidioTIM || isDirigenteFirmaVerbali || isDirigenteFirmaPacchetti" pSortableColumn="codiceInvio" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Nome INVIO
              <p-sortIcon field="codiceInvio"></p-sortIcon>
            </div>
          </th>
          <th *ngIf="isImpresa" pSortableColumn="codiceMiur" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Codice MIUR
            </div>
          </th>
          <th *ngIf="!isImpresa && !isPresidioTIM" pSortableColumn="azienda" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Impresa
            </div>
          </th>
          <th pSortableColumn="comune" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Comune
              <p-sortIcon field="comune"></p-sortIcon>
           </div>
          </th>
          <th pSortableColumn="regione" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Regione
             </div>
          </th>
          <th pSortableColumn="indirizzo" style="font-size: 12px;">
            <div class="flex justify-content-between align-items-center">
              Indirizzo
              <p-sortIcon field="indirizzo"></p-sortIcon>
             </div>
          </th>
          <th class="text-center" style="font-size: 12px;">
            Sede
          </th>
          
          <th class="text-center" style="font-size: 11px;">
            <div >
              Colocata
            </div>
          </th>
          <th class="text-center" style="font-size: 11px;" *ngIf="!isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali && !isPresidioTIM">
              Backup
          </th>
          <th *ngIf="!isImpresa && !isPresidioTIM && !isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali" style="font-size: 11px;">
              Lavorabile
          </th>
          <th pSortableColumn="stato" class="text-center" style="font-size: 12px;">
        
              Stato
          </th>
         
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
          <td *ngIf="(this.isAdmin || this.isSuperAdmin)  ">
            <p-tableCheckbox [value]="row" (click)="onPraticaSelection(row)" [disabled]="!isDaAssegnare(row)"></p-tableCheckbox>
          </td>
          <td class="text-center" style="width: 10px;">
            <button *ngIf=" showPulsante(row)" 
              pButton type="button" style="color:rgb(39, 128, 15)"
              icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" 
              (click)="openCaricaAzioniTieredPratica($event,row)">
            </button> 
          </td>
          <td *ngIf="this.isImpresa || isPresidioSF" class="text-center">
            <i *ngIf="showInfoFase3Complete(row)"       class="pi pi-server"    style="color:rgb(28, 60, 125);  margin-left: 5px;" tooltipPosition="top"  pTooltip="Info fase 3 complete"></i>
            <i *ngIf="showFotoRouterUploaded(row)"     class="pi pi-camera"    style="color:rgb(28, 60, 125);  margin-left: 5px;" tooltipPosition="top"  pTooltip="Foto Router Caricata"></i>
            <i *ngIf="showPacchettoNT9Uploaded(row)"   class="pi pi-building"  style="color:rgb(28, 60, 125);  margin-left: 5px;" tooltipPosition="top"  pTooltip="Pacchetto NT9 Caricato"></i>
            <i *ngIf="showPlanimetriaUploaded(row)"    class="pi pi-map"       style="color:rgb(28, 60, 125);  margin-left: 5px;" tooltipPosition="top"  pTooltip="Planimetria Caricata"></i>
           
            <i *ngIf="showDocs(row)" class="pi pi-file-pdf" style="color:rgb(39, 128, 15); margin-left: 5px; margin-right: 5px" tooltipPosition="top" pTooltip="Documenti creabili"></i>
            <i *ngIf="showDeliverable(row)" class="pi pi-send" style="color:rgb(39, 128, 15); " tooltipPosition="top" pTooltip="Consegnabile"></i>

           <!--  <button *ngIf=" showDocs(row)" 
              pButton type="button" style="color:rgb(39, 128, 15)"
              icon="pi pi-file-pdf" class="p-button-rounded p-button-text" 
              >
            </button>  -->
          <!--   <button *ngIf=" showPulsante(row)" 
              pButton type="button" style="color:rgb(39, 128, 15)"
              icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" 
              (click)="openCaricaAzioniTieredPratica($event,row)">
            </button>  -->
          </td>
        
         
          <td *ngIf="!isImpresa"> <span class="text-lowercase " style="font-size: 11.5px; font-weight: bold;" pTooltip="{{row.denominazione}}" >{{row.codiceInfratel}} </span> </td>
          <td *ngIf="isPresidioSF|| isPresidioTIM || isDirigenteFirmaVerbali || isDirigenteFirmaPacchetti"> <span class="text-lowercase "  style="font-size: 11.5px;">{{row.codiceInvio}}</span> </td>
          <td *ngIf="isImpresa"> <span class="text-lowercase" pTooltip="{{row.denominazione}}"  [ngClass]="getColocataFontClass(row)">{{row.codiceMiur}}</span> </td>
          <td *ngIf="!isImpresa && !isPresidioTIM"> <span class="text-lowercase " style="font-size: 11px;">{{row.azienda}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11.5px;">{{row.comune}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11.5px;">{{row.regione}}</span> </td>
          <td> <span class="text-lowercase " style="font-size: 11.5px;">{{row.indirizzo}}</span> </td>
          <td *ngIf="!row.tipoSedeEffettiva" class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoSede}}</span> </td>
          <td *ngIf="row.tipoSedeEffettiva" class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoSede}} ({{row.tipoSedeEffettiva}})</span> </td>

          
          <td class="text-center" >
            <ng-container *ngIf="row.colocata">
                <ng-container *ngIf="row.colocataRuolo === 'PRINCIPALE'; else secondaryIcon">
                  <i class="material-icons text-green-700" tooltipPosition="top" pTooltip="Scuola PRINCIPALE">done_outline</i>
              </ng-container>
              <ng-template #secondaryIcon>
                <i class="material-icons text-blue-700" tooltipPosition="top" pTooltip="Scuola riferita a {{getPrincipaleDiUnaColocata(row)}}">done_all</i>
              </ng-template>
            </ng-container>
            
          </td>
          <td class="text-center" *ngIf="!isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali && !isPresidioTIM">
            <i class="pi" [ngClass]="{ 'text-blue-700 pi-check': row.backup, '': !row.backup }"></i>
          </td>
          <td *ngIf="!isImpresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti && !isPresidioTIM" class="text-center">
            <i class="pi"
              [ngClass]="{ 'text-green-500 pi-check-circle': row.lavorabile, 'text-red-500 pi-times-circle': !row.lavorabile }"></i>
          </td>
          <td class="text-center">
            <span [ngClass]="getStatoClass(row.stato)">{{row.stato}}</span> </td>


         <!--  <td class="text-center">
             AZIONI IMPRESA 
             <button *ngIf="(isImpresa || isPresidioSF) && isDaPrendereInCarico(row)" pButton type="button" style="color:#547AA5;"
              icon="pi pi-file-import" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Prendi in carico" (click)="openPrendiInCarico(row)">
            </button> 
            <button *ngIf="(isImpresa || isPresidioSF) && isInCarico(row)" pButton type="button" style="color:#A2E277;"
              icon="pi pi-file-edit" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Inizia progettazione" (click)="openIniziaProgettazione(row)">
            </button>
            
            <button *ngIf="(isImpresa || isPresidioSF) && isInRework(row) " pButton type="button" style="color:#ff0404;"
              icon="pi pi-comment" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Note rework"
              (click)="openNoteReworkPratica(row)">
            </button>
            <button *ngIf="(isImpresa || isPresidioSF) && (isInRework(row) && hasReworkFile(row))" pButton type="button" style="color:#ff0404;"
              icon="pi pi-arrow-circle-down" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Scarica allegati rework"
              (click)="scaricaFileReworkPratica(row)">
            </button>

            <button *ngIf="(isImpresa || isPresidioSF) && (isInProgettazione(row) || isInRework(row))" pButton type="button"
              style="color:#128065;" icon="pi pi-server" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Inserisci informazioni fase 3" (click)="openInserisciDettagli(row)">
            </button>
            <button *ngIf="(isImpresa || isPresidioSF) && isInProgettazione(row)" pButton type="button" tooltipPosition="top"
              pTooltip="Apri NT9" class="p-button-rounded p-button-text" style="color:rgb(93, 70, 183);"
              icon="pi pi-map-marker" (click)="openGis2Web(row)"></button>

            <button *ngIf="(isImpresa || isPresidioSF) && (isInProgettazione(row) || isInRework(row))" pButton type="button" tooltipPosition="top"
              pTooltip="Carica File" class="p-button-rounded p-button-text" style="color:rgb(5, 78, 65);"
              icon="pi pi-cloud-upload" (click)="openCaricaFilePratica($event,row)"></button>
 
            <button *ngIf="(isImpresa || isPresidioSF) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE')  && areDocsCreatable(row) " pButton type="button"
              style="color:#7b9305;" icon="pi pi-eye" class="p-button-rounded p-button-text"
              tooltipPosition="top" pTooltip="Genera Docs" (click)="openPreviewDocs(row)">
            </button>

            <button *ngIf="(isImpresa || isPresidioSF) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && (isDeliverable(row) || isInRework(row))" pButton type="button"
              style="color:#0dc055;" icon="pi pi-check-circle" class="p-button-rounded p-button-text"
              tooltipPosition="top" pTooltip="Consegna" (click)="openConsegnaPratica(row)">
            </button>
            <button *ngIf="(isImpresa || isPresidioSF) &&  (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && ((isDelivered(row) && isImpresa) ||  isInCaricoPresidioSF(row))" pButton type="button" tooltipPosition="top"
              pTooltip="Annulla Consegna" class="p-button-rounded p-button-text" style="color:rgb(192, 132, 21);"
              icon="pi pi-undo" (click)="openAnnullaConsegnaPratica(row)"></button>
            
          <button *ngIf="(isImpresa || isPresidioSF) && (isDelivered(row) || isInCaricoPresidioSF(row))" pButton type="button" tooltipPosition="top"
              pTooltip="Scarica Doc" class="p-button-rounded p-button-text" style="color:rgb(125, 166, 121);"
              icon="pi pi-cloud-download" (click)="openScaricaDocumentazionePratica($event,row)"></button>

            // AZIONI PRESIDIO SF 
            <button *ngIf="isPresidioSF && (isDelivered(row) || isInCaricoPresidioSF(row))" pButton type="button" style="color:#1b0558;"
              icon="pi pi-pencil" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Modifica Informazioni" (click)="openInserisciDettagli(row)">
            </button>
            <button *ngIf="isPresidioSF && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && (isDelivered(row) || isInCaricoPresidioSF(row))" pButton type="button" style="color:#e76a04;"
              icon="pi pi-chevron-circle-left" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Manda in Rework" (click)="openMandaInRework(row)">
            </button>
            <button *ngIf="isPresidioSF && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && (isDelivered(row) || isInCaricoPresidioSF(row))" pButton type="button" style="color:#07791d;"
              icon="pi pi-chevron-circle-right" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Invia al Presidio TIM" (click)="openMandaAlPresidioTIM(row)">
            </button>
         
              <button *ngIf="(isAdmin || isSuperAdmin || isPresidioSF) && isRejected(row) && !disableSingleActionButtons" pButton type="button" style="color:rgb(15, 128, 109);"
              icon="pi pi-wrench" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Prendi in carico" (click)="openInCaricoPresidio(row)">
            </button>
             <button *ngIf="(isAdmin || isSuperAdmin || isPresidioSF) && isDaAssegnare(row) && !disableSingleActionButtons" pButton type="button" style="color:#F7D488;"
              icon="pi pi-paperclip" class="p-button-rounded p-button-text" tooltipPosition="top"
              pTooltip="Assegna ad impresa" (click)="openAssegnaAdImpresa(row)">
            </button>
            
           
            <button *ngIf="(isAdmin || isSuperAdmin || isPresidioSF) && isInCaricoPresidioSF(row) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && !disableSingleActionButtons" pButton type="button" style="color:#502419;"
              icon="pi pi-comment" class="p-button-rounded p-button-text" tooltipPosition="bottom"
              pTooltip="Note Respingimento" (click)="openNoteReworkPratica(row)">
            </button>
            <button *ngIf="(isAdmin || isSuperAdmin || isPresidioSF) && (isInCaricoPresidioSF(row) && hasRejectionFile(row)) && !disableSingleActionButtons" pButton type="button" style="color:#502419;"
            icon="pi pi-arrow-circle-down" class="p-button-rounded p-button-text" tooltipPosition="bottom"
            pTooltip="Scarica File Respingimento" (click)="scaricaFileRespingimentoPratica(row)">
          </button>
             
           
            
             AZIONI PRESIDIO TIM 
            <button *ngIf="(isSuperAdmin || isPresidioTIM) && isInCaricoPresidioTIM(row) && !disableSingleActionButtons" pButton type="button" style="color:#502419;"
              icon="pi pi-file-edit" class="p-button-rounded p-button-text" tooltipPosition="bottom"
              pTooltip="Completa" (click)="openCompletaPraticaPresidioTIM(row)">
            </button>
             <button *ngIf="(isSuperAdmin || isPresidioTIM) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && isSignable(row) && !disableSingleActionButtons" pButton type="button" style="color:#2c1966;"
              icon="pi pi-upload" class="p-button-rounded p-button-text" tooltipPosition="bottom"
              pTooltip="Carica pacchetto firmato" (click)="openCaricaPacchettoFirmato(row)">
            </button>
            
            
            <button *ngIf="(isSuperAdmin || isPresidioTIM) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && isSignable(row) && !disableSingleActionButtons" pButton type="button" style="color:#036e5c;"
              icon="pi pi-download" class="p-button-rounded p-button-text" tooltipPosition="bottom"
              pTooltip="Scarica pacchetto" (click)="scaricaPacchettoPratica(row)">
            </button>

           
            
            <button *ngIf="(isSuperAdmin || isPresidioTIM) && (!row.colocata || row.colocataRuolo ==='PRINCIPALE') && isSignable(row) && !disableSingleActionButtons" 
              pButton type="button" style="color:rgb(78, 114, 61);"
              icon="pi pi-inbox" class="p-button-rounded p-button-text" 
              tooltipPosition="top"  pTooltip="Risultato Invio" (click)="loadRisultatiInvioPratica($event,row)">
            </button>
            
            // AZIONI ADMIN + PRESIDIO SF? 
           
            <button *ngIf="(isAdmin || isSuperAdmin ) && isVoidable(row) && !disableSingleActionButtons" 
              pButton type="button" style="color:rgb(78, 114, 61);"
              icon="pi pi-pencil" class="p-button-rounded p-button-text" 
              tooltipPosition="top"  pTooltip="Modifica Info" (click)="openModificaPraticaDialog(row)">
            </button>
            <button *ngIf="(isAdmin || isSuperAdmin) && isVoidable(row) && !disableSingleActionButtons" 
              pButton type="button" style="color:rgb(242, 121, 7);"
              icon="pi pi-times" class="p-button-rounded p-button-text" 
              tooltipPosition="top"  pTooltip="Annulla" (click)="openAnnullaPratica(row)">
            </button>
            
          

          </td>-->
          <!-- <td>
            <button  pButton type="button" style="color:#BA783A;" icon="pi pi-history"
              class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Storico"
              [disabled]="disableSingleActionButtons || isDaAssegnare(row)" (click)="openStoricoPratica(row)">
            </button>
            <button *ngIf="hasCoordinates(row)" pButton type="button" style="color:#e5b5d7;" icon="pi pi-map"
              class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Posizione"
              (click)="openMappaConScuola(row)">
            </button>
          </td> -->
       <!--      <button *ngIf="hasCoordinates(row)" pButton type="button" style="color:#2a0104;" icon="pi pi-map"
              class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Posizione"
              (click)="openDisegnaPratica(row)">
            </button> -->
          
        </tr>
      </ng-template>
      <!--  <ng-template pTemplate="footer">
        <tr>
         <td class="text-left" style="font-size: 14px;">TOTALI:</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInRework}}</td>

         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInRework}}</td> 
         <td></td>
         
       </tr>
     
   </ng-template> -->
    </p-table>
    <p-menu #menuAzioniAzienda [popup]="true" [model]="menuAzioniAziendaItems"></p-menu>
    <p-tieredMenu #menuAzioniTiered [popup]="true" [model]="menuAzioniItems"></p-tieredMenu>

    <p-menu #menuScaricaFile [popup]="true" [model]="menuScaricaFileItems"></p-menu>
    <p-menu #menuCaricaFile [popup]="true" [model]="menuCaricaFileItems"></p-menu>
    <p-menu #menuRisultatoInvioInfratel [popup]="true" [model]="menuRisultatiInvioPraticaItems" ></p-menu>

  </div>
</div>

<p-toast position="top-center"></p-toast>