import {
  Component,
  HostBinding,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
  PrimeIcons,
} from "primeng/api";
import { environment } from "../../../../../environments/environment";
import { getStorage, ref, getBlob } from "firebase/storage";

import { FeatureCollection } from "geojson";
/* declare const L: any;
import * as Leaflet from "leaflet";

import "leaflet-draw";
import drawLocales from "leaflet-draw-locales"; */

import { GeojsonService } from "src/app/services/geoJson.service";
import { Subject } from "rxjs";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { GoogleStorageService } from "src/app/services/googleStorage.service";

@Component({
  selector: "app-disegna-scuola-bando2",
  templateUrl: "./dialog-disegna-scuola.component.html",
  styleUrls: ["./pratiche-scuola-bando2.component.css"],
})
export class DialogDisegnaScuola implements OnInit {

  protected _onDestroy = new Subject<void>();
 /*  public pratica: PraticaScuola_Firestore;
  public praticaCompleta: PraticaScuola;
  public map!: Leaflet.Map;
  public markers: Leaflet.Marker[] = [];
  public drawnItems: Leaflet.FeatureGroup = Leaflet.featureGroup();
  public drawHasChanged: boolean = false;
  protected linea:GeoJSONFeature;

  public options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"),
    ],
    zoom: 15,
    //center: { lat:45.037488186170464 , lng:7.419594509794877  },
  };

  public drawOptions = {
    position: "topright",
    draw: {
      marker: {
        icon: Leaflet.icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: "assets/leaflet/marker-icon.png",
          iconRetinaUrl: "assets/leaflet/marker-icon-2x.png",
          shadowUrl: "assets/leaflet/marker-shadow.png",
        }),
      },
    },
    edit: {
      featureGroup: this.drawnItems,
    },
  };
  protected drawLocal: any = {
    draw: {
      toolbar: {
        buttons: {
          polygon: "MESSAGGIO POLIGONO!",
        },
      },
    },
  };
 */
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService,
    private geojsonService: GeojsonService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.logger.info("DISEGNA MAPPA - ngOnInit");

   /*  this.pratica = this.config.data.pratica;
    this.praticaCompleta = this.config.data.praticaCompleta;
    this.zone = new NgZone({});
    //Recupero GEOJSON da storage
    this.initializeMap(); */
  }
  retrieveGeoJSONFromFirestore() {}

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      /* this.logger.info("DISEGNA MAPPA - CONFERMA");
      var finalGeoJson: FeatureCollection = {
        type: "FeatureCollection",
        features: [],
      };
      //finalGeoJson.type ="FeatureCollection";
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Path) {
          let path = layer as L.Path;
          //this.logger.info("> ABBIAMO UN LINESTRING ");
          /* let feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: path.getLatLngs()[0].map(coord => coord.toArray()),
            },
            properties: {
              // Proprietà aggiuntive, se necessario
            },
          }; 
          //this.logger.info("> PATH");
           // this.logger.info(path["feature"]);
          finalGeoJson.features.push(path["feature"]);
        } else if (layer instanceof L.Marker) {
          let marker = layer as L.Marker;

          //this.logger.info("> ABBIAMO UN MARKER ");
          //this.logger.info(marker.feature);
          finalGeoJson.features.push(marker.feature);
        }else{
            this.logger.info("ELEMENTO INDEFINITO");
            this.logger.info(layer);
        }
      });
      //finalGeoJson.features.push(this.linea);
      
      //this.logger.info(finalGeoJson);
       const geoJSONString = JSON.stringify(finalGeoJson);
      this.googleStorageService
        .uploadNTXToGoogleStorage(
          geoJSONString,
          this.pratica.codiceInfratel.valueOf()
        )
        .then((uploadRes) => {
          this.logger.info("---> ok caricamento file");
          // this.dialogRef.close({ success: true });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            life: 10000,
            detail: "Errore in fase di caricamento File!",
          });
        }); */
    } 
  }
  initializeMap(): void {
    this.logger.info("DISEGNA MAPPA - initializeMap");
    //drawLocales("it");
  }

  loadGeoJson(drawnFeatures: any) {
    /* this.logger.info("DISEGNA MAPPA - loadGeoJson");
    let coordinateAccesso: any;
    const self = this;
    const storage = getStorage();
    const pathReferenceRET = ref(
      storage,
      "pratiche/sanita/" + this.pratica.codiceInfratel + "/ntx/ntx.geojson"
    );

    getBlob(pathReferenceRET)
      .then((data) => {
        const blob = data as Blob;
        const reader = new FileReader();
        reader.onload = (event) => {
          const geoJSONData = JSON.parse(event.target?.result as string);
          const geoJSONLayer = L.geoJSON(geoJSONData, {
            onEachFeature: function (feature, layer) {
              //self.logger.info(">>> " + feature.properties.name);
              if (feature.properties.name === "Accesso") {
                coordinateAccesso = feature.geometry.coordinates;
                const posizionePuntoAccesso = {
                  lat: coordinateAccesso[1].valueOf(),
                  lng: coordinateAccesso[0].valueOf(),
                };
               
                layer.bindPopup("ACCESSO " + feature.properties.name);
                self.map.panTo(posizionePuntoAccesso);
                self.map.setZoom(17);
              } else if (feature.properties.name === "Centrale") {
                layer.bindPopup("CENTRALE " + feature.properties.name);
              
              } else if (feature.properties.name === "Linea") {
               
              }

              drawnFeatures.addLayer(layer);
            },
          }); 

          geoJSONLayer.addTo(this.map);
          
        };
        reader.readAsText(blob);
      })
      .catch((errore) => {
        this.logger.info(errore);
        this.messageService.add({
          severity: "info",
          summary: "Errore",
          detail: "Errore in fase di scaricamento del file GEOJSON",
          life: 10000,
        });
      }); */
  }

  
/*
  onMapReady($event: Leaflet.Map) {
     this.logger.info("DISEGNA MAPPA - onMapReady --");
    this.map = $event;
    const self = this;
    var drawnFeatures = new L.FeatureGroup();
    this.map.addControl(drawnFeatures);
    var customMarker = L.Icon.extend({
      options: {
        iconSize: new L.Point(25, 41),
        iconUrl: "/assets/leaflet/images/marker-icon-bando2.png",
      },
    });
    var drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnFeatures,
        remove: false,
      },
      draw: {
        polyline: {
          shapeOptions: {
            color: "#FFD639",
          },
          showLength: true,
          metric: true,
        },
        polygon: false,
        marker: {
          icon: new customMarker(),
        },
        rectangle: false,
        circle: false,
        circlemarker: false,
      },
    });

    this.map.addControl(drawControl);

    this.map.on(L.Draw.Event.CREATED, function (e) {
      this.logger.info("DISEGNA MAPPA - CREATED ");
      var type = e["layerType"];

      var layer = e["layer"];
      this.logger.info("DISEGNA MAPPA - CREATED LAYER " + layer);

      drawnFeatures.addLayer(layer);

      //this.drawnItems.addLayer(layer);
    });

    this.map.on("draw:edited", function (e) {
    self.logger.info("DISEGNA MAPPA - EDITING");

      //var type = e["type"];

      //var layer =
      var layers = e["layers"];
      self.logger.info("DISEGNA MAPPA - SONO STATI MODIFICATI LAYER");
      self.logger.info(layers["_layers"]);

      layers.eachLayer(function (layer) {
        const geoJSON = layer.toGeoJSON();
        //console.log(geoJSON);
         var feature:GeoJSONFeature = layer.feature;
          
          if(feature.geometry.type==="Point"){
            var nuoveCoordinate:Leaflet.LatLng = layer._latlng;
            //console.log(nuoveCoordinate);
          }else if(feature.geometry.type==="LineString"){
            var nuoveCoordinate2:Leaflet.LatLng[] = layer._latlngs;
            self.linea = feature;
           // console.log(nuoveCoordinate2);
          } 
      });
      self.zone.run(() => {
        self.drawHasChanged = true;
      });
      console.log("DISEGNA MAPPA - FINE EDITING");
    });

    this.loadGeoJson(drawnFeatures);
  }
 */
  mapClicked($event: any) {}
  markerOver(event: any, index: number) {
    const marker = event.target;
    marker.openPopup();
  }
  markerOut(event, index) {
    const marker = event.target;
    marker.closePopup();
  }
  generateMarker(data: any, index: number, isScuola: boolean) {
    /* this.logger.info("DISEGNA MAPPA - generateMarker --");

    let markerIconScuola = new Leaflet.Icon({
      iconUrl: "/assets/leaflet/images/marker-icon-bando2.png",
      //iconSize: [40, 25],
      iconSize: [25, 41],
    });
    let markerIconCentrale = new Leaflet.Icon({
      iconUrl: "/assets/leaflet/images/marker-icon-centrale.png",
      iconSize: [25, 41],
    });
    if (isScuola) {
      return (
        Leaflet.marker(data.position, {
          draggable: data.draggable,
          icon: markerIconScuola,
        })
          //.on("click", (event) => this.markerClicked(event, index))
          .on("mouseover", (event) => this.markerOver(event, index))
          .on("mouseout", (event) => this.markerOut(event, index))
          .on("dragend", (event) => this.markerDragEnd(event, index))
      );
    } else {
      return (
        Leaflet.marker(data.position, {
          draggable: data.draggable,
          icon: markerIconCentrale,
        })
          // .on("click", (event) => this.markerClicked(event, index))
          .on("mouseover", (event) => this.markerOver(event, index))
          .on("mouseout", (event) => this.markerOut(event, index))
          .on("dragend", (event) => this.markerDragEnd(event, index))
      );
    } */
  }
  markerClicked($event: any, index: number) {}

  markerDragEnd($event: any, index: number) {}
  initMarkers() {
    this.logger.info("DISEGNA MAPPA - initMarkers");
    /* 
    const initialMarkers = [
      {
        position: {
          lat: this.pratica.coordinateScuola.latitude.valueOf(),
          lng: this.pratica.coordinateScuola.longitude.valueOf(),
        },
        draggable: false,
      },
      {
        position: {
          lat: this.pratica.coordinateCentrale.latitude.valueOf(),
          lng: this.pratica.coordinateCentrale.longitude.valueOf(),
        },
        draggable: false,
      },
    ];
    const dataScuola = initialMarkers[0];
    const dataCentrale = initialMarkers[1];
   
    const markerScuola = this.generateMarker(dataScuola, 0, true);
    const markerCentrale = this.generateMarker(dataCentrale, 0, false);
    let popupScuola = "SCUOLA <b> "+ this.pratica.denominazione + "</b><br>" +
                      this.pratica.indirizzo + "<br>"+
                      "<b>" + this.pratica.comune + "</b><br>";
    
    let popupCentrale = "CENTRALE <b> "+ this.praticaCompleta.nomeCentrale + "</b><br>" +
                        this.praticaCompleta.indirizzoCentrale + "<br>"+
                        "<b>" + this.praticaCompleta.comuneCentrale + "</b><br>";
    
    
    markerScuola
      .addTo(this.map)
      .bindPopup(popupScuola);
      markerCentrale
      .addTo(this.map)
      .bindPopup(popupCentrale);
    this.map.panTo(dataScuola.position);
    this.markers.push(markerScuola);
    this.markers.push(markerCentrale);
    this.map.setZoom(15); */
  }
}

interface GeoJSONFeature {
  type: string;
  properties: {
    name: string;
  };
  geometry: {
    coordinates: [number, number][];
    type: string;
  };
  id: number;
}
