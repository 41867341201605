import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';
import { Centrale } from '../models/centrale.models';
import { RichiestaAggiornamentoCentrale } from '../models/richiestaAggiornamentoCentrale.models';


@Injectable({
  providedIn: 'root',
})
export class CentraliService{
  apiUrl: string = `${environment.apiUrl}`+"centrale";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  get(): Observable<any> {
    const headers = {
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  getByIdTIM(idTIM:String): Observable<any> {
    let urlFiltrateByBando = this.apiUrl +"?id-tim="+idTIM;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(urlFiltrateByBando, httpOptions);
  }

  richiediCambiamentoIndirizzo(id:string,richiestaAggiornamentoCentrale:RichiestaAggiornamentoCentrale): Observable<any> {
    const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/richiesta-aggiornamento", richiestaAggiornamentoCentrale, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('modificaCentrale', []))
    );
  }

  changeAddress(centrale:Centrale): Observable<any> {
    const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+centrale.id, centrale, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('modificaCentrale', []))
    );
  }
  modifica(centrale:Centrale): Observable<any> {
    const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+centrale.id, centrale, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('modificaCentrale', []))
    );
  }
  crea(centrale:Centrale): Observable<any> {
    const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    this.logger.info("---> ESEGUO CREAZIONE "+ this.apiUrl);
    this.logger.info(centrale);
    return this.http.post(this.apiUrl, centrale, httpOptions);
  }

  elimina(idCentrale:string): Observable<any> {
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idCentrale, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('eliminaCentrale', []))
    );
  }

  private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };

}