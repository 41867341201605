import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService } from "primeng/api";
import { environment } from '../../../environments/environment';


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { ImpreseService } from "src/app/services/imprese.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { Stats } from "src/app/models/stats.model";
import { onSnapshot } from "firebase/firestore";
import { CentraliService } from "src/app/services/centrali.service";
import { Centrale } from "src/app/models/centrale.models";
import { ComuniService } from "src/app/services/comuni.service";

declare const CSVBoxImporter: any;
@Component({
  selector: "app-comuni",
  templateUrl: "./comuni.component.html",
  styleUrls: ["./comuni.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class ComuniComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  comuni: Centrale[] = [];
  
  public isAdmin:boolean = false;
  public isSuperAdmin  :boolean = false;
   //CSV BOX
   private csvbox_licenseKey:string=environment.csvBox.secret_centrali;
   private csvbox_user:string=environment.csvBox.user;
   private csvbox_importer:any;
   dialogConfig;

  @ViewChild("comuniDT", { static: true }) centraliDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;
 
  constructor(
    private logger: NGXLogger,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private comuniService: ComuniService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService,
  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    }else if(localStorage.getItem("ruolo") && localStorage.getItem("ruolo")==='SUPERADMIN'){
      this.isSuperAdmin = true;
    }
    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadComuni();
  }

 

  ngAfterViewInit() {}

  


  

  filtraPerNome(event: any){
    this.centraliDT.filterGlobal(event.target.value, 'contains')
  }

 

  loadComuni() {
    this.isLoadingResults = true;
    this.comuniService.get().subscribe(comuni =>{
      this.comuni = comuni;
      this.isLoadingResults = false;
      this.logger.info("LOAD COMUNI RESTITUISCE: "+ comuni.length);
    });
   /*  const collectionRef =  this.firestore.collection(environment.firebase.collection_stats).ref;
    const query = collectionRef
      .where(
        "ragioneSociale",
        "!=",
       'Admin Fake Company'
      )
      .orderBy("ragioneSociale");
      onSnapshot(query, (qs) => {
        this.centrali = [];
        qs.forEach((doc) => {
          this.centrali.push(doc.data() as Stats);
        });
        this.calcolaTotali();
        this.isLoadingResults = false;
       
      }); */
      
 
  }

  
  

  
}
