<div class="py-5 px-6">
  <ul class="list-none p-0 m-0 flex flex-column md:flex-row">
      <li class="relative mr-0 md:mr-8 flex-auto">
        <div [ngClass]="isAssegnata ? 'border-2 border-orange-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
              <i [ngClass]="isAssegnata ? 'pi pi-link text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 text-orange-500' : 'pi pi-link text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'" ></i>
              <div> 
                  <div [ngClass]="isAssegnata ? 'text-orange-600':'text-900 font-medium mb-1'">Assegnata</div>
                  <span class="text-600 text-sm hidden md:block">{{dataAssegnazione | date:'dd/MM/yyyy'}}</span>
              </div>
          </div>
          <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <li class="relative mr-0 md:mr-8 flex-auto">
        <div [ngClass]="isInCarico ? 'border-2 border-yellow-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
          <i [ngClass]="isInCarico ? 'pi pi-user text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 text-yellow-600':'pi pi-user text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
          <div>
            <div [ngClass]="isInCarico ? 'text-yellow-600 font-medium mb-1':'text-900 font-medium mb-1'">In carico</div>
            <span class="text-600 text-sm hidden md:block">{{dataInizioInCarico | date:'dd/MM/yyyy'}}</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <li  *ngIf="isAnnullata" class="relative mr-0 md:mr-8 flex-auto">
        <div [ngClass]="isAnnullata ? 'border-2 border-gray-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
          <i [ngClass]="isAnnullata ? 'pi pi-times-circle text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 text-gray-600':'pi pi-times-circle text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
          <div>
            <div [ngClass]="isAnnullata ? 'text-gray-600 font-medium mb-1':'text-900 font-medium mb-1'">Annullata</div>
            <span class="text-600 text-sm hidden md:block">{{dataInizioInCarico | date:'dd/MM/yyyy'}}</span>
          </div>
        </div>
        <!-- <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div> -->
      </li>
      <li *ngIf="!isAnnullata" class="relative mr-0 md:mr-8 flex-auto"> 
        <div [ngClass]="isInProgettazione ? 'border-2 border-blue-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
          <i [ngClass]="isInProgettazione ? 'pi pi-cog text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3 text-blue-600':'pi pi-cog text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
              <div> 
                  <div [ngClass]="isInProgettazione ? 'text-blue-600 font-medium mb-1':'text-900  font-medium mb-1'">In progettazione</div>
                  <span class="text-600 text-sm hidden md:block">{{dataInizioProgettazione | date:'dd/MM/yyyy'}}</span>
              </div>
          </div>
          <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <li *ngIf="!isAnnullata" [ngClass]="!isInRework &&  !isRespinta && !isApprovata ? 'relative flex-auto':'relative mr-0 md:mr-8 flex-auto'" ><!--class="relative mr-0 md:mr-8 flex-auto"-->
         <div [ngClass]="isConsegnata ? 'border-2 border-green-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
            <i [ngClass]="isConsegnata ? 'pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3':'pi pi-check-circle text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
              <div>
                  <div [ngClass]="isConsegnata ? 'text-900 text-green-600 font-medium mb-1':'text-900 font-medium mb-1'">Consegnata</div>
                  <span class="text-600 text-sm hidden md:block">{{dataConsegna | date:'dd/MM/yyyy'}}</span>
              </div>
          </div>
          <div *ngIf="isInRework || isRespinta || isApprovata" class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <li *ngIf="isInRework" [ngClass]="!isInRework ? 'relative flex-auto':'relative mr-0 md:mr-8 flex-auto'" >
        <div [ngClass]="isInRework ? 'border-2 border-red-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
           <i [ngClass]="isInRework ? 'pi pi-directions-alt text-red-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3':'pi pi-directions-alt text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
             <div>
                 <div [ngClass]="isInRework ? 'text-900 text-red-600 font-medium mb-1':'text-900 font-medium mb-1'">In rework</div>
                 <span class="text-600 text-sm hidden md:block">{{dataConsegna | date:'dd/MM/yyyy'}}</span>
             </div>
         </div>
         <div *ngIf="isApprovata" class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
     </li>
     <li *ngIf="isApprovata" class="relative flex-auto">
        <div [ngClass]="isApprovata ? 'border-2 text-green-400 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
          <i [ngClass]="isApprovata ? 'pi pi-directions-alt text-green-400 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3':'pi pi-directions-alt text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
            <div>
                <div [ngClass]="isApprovata ? 'text-900 text-green-400 font-medium mb-1':'text-900 font-medium mb-1'">Approvata</div>
                <span class="text-600 text-sm hidden md:block">{{dataConsegna | date:'dd/MM/yyyy'}}</span>
            </div>
        </div>
      </li>
      <li *ngIf="isRespinta" class="relative flex-auto">
        <div [ngClass]="isRespinta ? 'border-2 text-red-800 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1' : 'border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1'">
          <i [ngClass]="isRespinta ? 'pi pi-times-circle text-red-800text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3':'pi pi-times-circle text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3'"></i>
            <div>
                <div [ngClass]="isRespinta ? 'text-900 text-red-800 font-medium mb-1':'text-900 font-medium mb-1'">Respinta</div>
                <span class="text-600 text-sm hidden md:block">{{dataConsegna | date:'dd/MM/yyyy'}}</span>
            </div>
        </div>
      </li>
  </ul>
</div>