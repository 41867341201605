
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
<!-- <h3><b>ATTENZIONE: l'operazione di chiusura della fase di disegno impone il caricamento del report prodotto con il tool NT9!</b></h3>

 -->  <p class="line-height-3 p-0 m-0 mb-1 mt-1">
   Seleziona file da caricare o trascinalo nell'area sottostante
  </p>
  <p-fileUpload 
  multiple="false"
  maxFileSize="15000000"
  chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onFileUpload($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [disabled]="isFileUploaded"
  (onRemove)="onFileRemove()"
  >
</p-fileUpload>

<div  class="flex upload-file-div-box mt-2">

    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isFileUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>

</div>

