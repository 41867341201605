<div class="grid mb-3 mt-1 ">
  <div class="field mt-2 mb-2 col-12">
    <label  class="font-medium text-900">Note</label>
    <textarea  id="note" rows="5" [autoResize]="true" [(ngModel)]="note" pInputTextarea style="width: 100%;"></textarea>
  </div>
  <div class="field mt-2 mb-2 col-12">
    <label for="fileComputoMetrico" class="font-medium text-900">Carica Checklist Infratel</label>
    <p-fileUpload name="fileComputoMetrico" 
        multiple="false"
        maxFileSize="15000000"
        chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
        [auto]="true"
        [customUpload]="true"
        (uploadHandler)="onNoteRespingimentoUpload($event)"
        [showUploadButton]="false"
        [showCancelButton]="false"
        (onRemove)="onNoteRespingimentoRemove()"
        [disabled]="isNoteRespingimentoFileUploaded"
        class="custom-red-button"
    >
    </p-fileUpload>
  </div>
  
</div>
<div class="flex gap-3 justify-content-end border-top-1 surface-border pt-5">
  <button pButton pRipple label="Chiudi" (click)="conferma(false)" class="p-button-text"></button>
  <button [disabled]="note.length == 0"  pButton pRipple label="Conferma" (click)="conferma(true)" class="p-button-success"></button>
</div>