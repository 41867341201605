import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
  PrimeIcons,
} from "primeng/api";
import { environment } from "../../../../environments/environment";
import { Menu } from "primeng/menu";

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { Impresa } from "src/app/models/impresa.model";
import { ImpreseService } from "src/app/services/imprese.service";
import {
  AngularFirestore,
} from "@angular/fire/compat/firestore";
import {
  onSnapshot,
  GeoPoint,
} from "firebase/firestore";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";

import { Observable, Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ExcelService } from "src/app/services/excelService.service";
import { Utente } from "src/app/models/utente.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { Location } from "@angular/common";
import * as Leaflet from "leaflet";
import { StoricoPraticheService } from "src/app/services/storicoPratiche.service";
import { NotaReworkPratica } from "src/app/models/pratica.nota.scuola.model";
import { NotePraticheService } from "src/app/services/notePratiche.service";
import { NotaPraticaDTO } from "src/app/models/pratica.notaDTO.scuola.model";
import { CsvboxLoaderService } from "src/app/services/csvbox-loader.service";
import * as JSZip from "jszip";
import { ModificaPraticaDTO } from "src/app/models/modificaPratica.scuola.model";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Tipologia } from "src/app/models/tipologia.model";
import { ComuniService } from "src/app/services/comuni.service";
import { ComuneDTO } from "src/app/models/comune.model";
import { NgxFileDropEntry } from "ngx-file-drop";
import { PraticaSanita_Firestore } from "src/app/models/praticaSanitaFirestore.model";
import { PraticheSanitaService } from "src/app/services/pratiche.sanita.service";
import { PraticaSanita } from "src/app/models/pratica.sanita.model";
import { DialogAssegnaPraticaSanita } from "./assegna-pratica-sanita";
import { PraticaScuola } from "src/app/models/pratica.scuola.model";
import { DialogInserisciInformazioniPraticaSanita } from "./inserisci-informazioni-pratica-sanita";
import { DialogStoricoPraticaSanita } from "./storico-pratica-sanita";
import { PianoTecnicoSanitaService } from "src/app/services/piano-tecnico-sanita.service";
import { DialogRichiediCambioCentralePraticaSanita } from "./cambio-centrale-sanita.component";


Leaflet.Icon.Default.imagePath = "assets/leaflet/images/";

declare const CSVBoxImporter: any;
@Component({
  selector: "app-pratiche-sanita",
  templateUrl: "./pratiche-sanita.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class PraticheSanitaComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  praticheAtFirestore: PraticaSanita_Firestore[] = [];
  selectedPraticaAtFirestore: PraticaSanita_Firestore;

  //PER MULTISELECT
  selectedPratiche!: PraticaSanita_Firestore[];
  disableSingleActionButtons: boolean = false;

  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public isPresidioSF: boolean = false;
  public isPresidioTIM: boolean = false;
  public isDirigenteFirmaVerbali: boolean = false;
  public isDirigenteFirmaPacchetti: boolean = false;
  protected hasPraticheDaFirmare:Boolean = false;

  public isImpresa: boolean = false;

  public numeroTotalePratiche: number;
  public imprese: MenuItem[] = [];

  mostraAnnullate: boolean = true;
  testoFiltroAnnullate:String='MOSTRA';

  //CSV BOX
  private csvbox_licenseKey:string=environment.csvBox.secret;
  private csvbox_licenseKey_importaPraticheSanita:string=environment.csvBox.secret_importaPraticheSanita;
  private csvbox_licenseKey_aggiornaPraticheSanita:string=environment.csvBox.secret_aggiornaPraticheSanita;
  private csvbox_importer:any;
  private csvbox_importer_aggiornaPratiche:any;

  private mappaCodiciScuoleColocate: Map<string, string>;
  private mappaCodiciScuolePrincipali: Map<string, string>;

  private collection_pratiche_sanita:string = environment.firebase.collection_pratiche_sanita;

  public statiPratica: MenuItem[] = [];
  public statoFiltroSelezionato: MenuItem;

  @ViewChild("menuAzioniAzienda", { static: true }) azioniAziendaMenu: Menu;
  protected menuAzioniAziendaItems!: MenuItem[];

  @ViewChild("menuAzioni", { static: true }) azioniMenu: Menu;
  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;
  protected menuAzioniItems!: MenuItem[];
  protected menuTieredAzioniItems!: MenuItem[];
  

  @ViewChild("menuScaricaFile", { static: true }) azioniScaricaDocMenu: Menu;
  protected menuScaricaFileItems!: MenuItem[];

  @ViewChild("menuCaricaFile", { static: true }) azioniCaricaFileMenu: Menu;
  protected menuCaricaFileItems!: MenuItem[];
  
  @ViewChild("menuRisultatoInvioInfratel", { static: true }) azioniRisultatoInvioInfratel: Menu;
  protected menuRisultatiInvioPraticaItems!: MenuItem[];

  @ViewChild("menuStati") menuStati!: Menu;
  @ViewChild("menuTipiSede") menuTipiSede!: Menu;
  @ViewChild("menuRegioni") menuRegioni!: Menu;

  @ViewChild("praticheSanitaDT", { static: true }) praticheSanitaDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  protected msgs: Message[] = [];
  protected ref: DynamicDialogRef;
  protected dialogConfig:any;

  protected filterIsSet:Boolean = false;
  protected filtroStato:String;
  protected filtroPratica:String;
  protected filtroPT:String;

  public regioniPratica: MenuItem[] = [
    {
      label: "ABRUZZO",
      command: (event) => {
        this.praticheSanitaDT.filter("ABRUZZO", "regione", "is");
      },
    },
    {
      label: "CALABRIA",
      command: (event) => {
        this.praticheSanitaDT.filter("CALABRIA", "regione", "is");
      },
    },
    {
      label: "CAMPANIA",
      command: (event) => {
        this.praticheSanitaDT.filter("CAMPANIA", "regione", "is");
      },
    },
    {
      label: "LAZIO",
      command: (event) => {
        this.praticheSanitaDT.filter("LAZIO", "regione", "is");
      },
    },
    {
      label: "LIGURIA",
      command: (event) => {
        this.praticheSanitaDT.filter("LIGURIA", "regione", "is");
      },
    },
    {
      label: "MARCHE",
      command: (event) => {
        this.praticheSanitaDT.filter("MARCHE", "regione", "is");
      },
    },
    {
      label: "MOLISE",
      command: (event) => {
        this.praticheSanitaDT.filter("MOLISE", "regione", "is");
      },
    },
    {
      label: "PIEMONTE",
      command: (event) => {
        this.praticheSanitaDT.filter("PIEMONTE", "regione", "is");
      },
    },
    {
      label: "PUGLIA",
      command: (event) => {
        this.praticheSanitaDT.filter("PIEMONTE", "regione", "is");
      },
    },
    {
      label: "TOSCANA",
      command: (event) => {
        this.praticheSanitaDT.filter("TOSCANA", "regione", "is");
      },
    },
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheSanitaDT.clear();
      },
    }
  ];

  public tipiSede: MenuItem[] = [
   
    {
      label: "A",
      command: (event) => {
        this.praticheSanitaDT.filter("A", "tipoSede", "is");
      },
    },
    {
      label: "B",
      command: (event) => {
        this.praticheSanitaDT.filter("B", "tipoSede", "is");
      },
    },
    {
      label: "B*",
      command: (event) => {
        this.praticheSanitaDT.filter("B*", "tipoSede", "is");
      },
    },
    {
      label: "C",
      command: (event) => {
        this.praticheSanitaDT.filter("C", "tipoSede", "is");
      },
    },
    {
      label: "D",
      command: (event) => {
        this.praticheSanitaDT.filter("D", "tipoSede", "is");
      },
    },
    {
      label: "COL",
      command: (event) => {
        this.praticheSanitaDT.filter("COL", "tipoSede", "is");
      },
    },
    {
      label: "TUTTE",
      command: (event) => {
        this.praticheSanitaDT.clear();
      },
    },
  ];

  constructor(
    private logger: NGXLogger,
    private googleStorageService: GoogleStorageService,
    private confirmationService: ConfirmationService,
    private praticheSanitaService: PraticheSanitaService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private excelService: ExcelService,
    private storicoPraticheService: StoricoPraticheService,
    private notePraticheService: NotePraticheService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private impreseService:ImpreseService,
    private csvboxLoaderService: CsvboxLoaderService,
    private pianoTecnicoSanitaService: PianoTecnicoSanitaService,

  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
    this.mappaCodiciScuoleColocate = new Map<string, string>();
    this.mappaCodiciScuolePrincipali = new Map<string, string>();
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }

  ngOnInit(): void {
    if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "ADMIN"
    ) {
      this.isAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SUPERADMIN"
    ) {
      this.isSuperAdmin = true;
    } else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "SMARTFIBER"
    ) {
      this.isPresidioSF = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "PRESIDIO"
    ) {
      this.isPresidioTIM = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "OPERATORE_AZIENDA"
    ) {
      this.isImpresa = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_VERBALI"
    ) {
      this.isDirigenteFirmaVerbali = true;
    }else if (
      localStorage.getItem("ruolo") &&
      localStorage.getItem("ruolo") === "DIRIGENTE_FIRMA_PACCHETTI"
    ) {
      this.isDirigenteFirmaPacchetti = true;
    }
    const urlParamStato   = this.activatedRoute.snapshot.paramMap.get('stato');
    const urlParamPratica = this.activatedRoute.snapshot.paramMap.get('pratica');
    const urlParamPianoTecnico = this.activatedRoute.snapshot.paramMap.get('pianoTecnico');
    if(urlParamStato){
      this.logger.info("> urlParamStato " + urlParamStato);

      this.filtroStato = urlParamStato;
      this.filterIsSet = true;
    }else if(urlParamPratica){
      this.logger.info("> urlParamPratica " + urlParamPratica);

      this.filtroPratica = urlParamPratica;
      this.filterIsSet = true;
    }else if(urlParamPianoTecnico){
       this.logger.info("> urlParamPianoTecnico " + urlParamPianoTecnico);
       this.filtroPT = urlParamPianoTecnico;
       this.filterIsSet = true;
       /*  this.pianoTecnicoSanitaService.getById(urlParamPianoTecnico).subscribe(res=>{
          this.filtroPT = res.descrizione;
          this.filterIsSet = true;
        }); */
       
     }else {

      this.filterIsSet = false;
    }
    //this.logger.info("> filterIsSet >>> "+ this.filterIsSet);
   // this.logger.info(">  this.filtroPratica >>> "+ this.filtroPratica);
    //this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF ) {
      this.loadImprese();
      if(this.filterIsSet){
        
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        //this.logger.info("NG ON INIT SENZA FILTRI");
        this.loadAllPraticheScuolaFromFirestore();
      }
    }else if(this.isPresidioTIM){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheSanitaPerAziendaFromFirestore();
    }

    this.loadMenuStatiPratica();

    this.loadCSVBox();
  }

  applyHideClosedFilter() {
   // this.logger.info("--> applyHideClosedFilter "+ this.mostraAnnullate + " " + this.testoFiltroAnnullate);
    if (this.mostraAnnullate) {
      this.testoFiltroAnnullate='NASCONDI ';
      this.mostraAnnullate = false;
      this.praticheSanitaDT.clear();
      
    } else {
      this.testoFiltroAnnullate='MOSTRA ';
      this.mostraAnnullate = true;
      this.praticheSanitaDT.filter("ANNULLATA", "stato", "isNot");
    }
  }

  loadMenuStatiPratica(){
    if(this.isAdmin || this.isSuperAdmin || this.isPresidioSF || this.isPresidioTIM){
      this.statiPratica.push({
        label: "CARICATA",
        command: (event) => {
          this.filtraPerStato("CARICATA");
        },
      },
      {
        label: "ANNULLATA",
        command: (event) => {
          this.filtraPerStato("ANNULLATA");
        },
      });
    }
    this.statiPratica.push({
      label: "ASSEGNATA",
      command: (event) => {
        this.filtraPerStato("ASSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO IMPRESA",
      command: (event) => {
        this.filtraPerStato("IN CARICO IMPRESA");
      },
    });
    this.statiPratica.push({
      label: "IN CARICO PRESIDIO",
      command: (event) => {
        this.filtraPerStato("IN CARICO PRESIDIO");
      },
    });
    this.statiPratica.push({
      label: "IN VERIFICA SMARTFIBER",
      command: (event) => {
        this.filtraPerStato("IN VERIFICA SMARTFIBER");
      },
    });
    this.statiPratica.push({
      label: "IN PROGETTAZIONE",
      command: (event) => {
        this.filtraPerStato("IN PROGETTAZIONE");
      },
    });
    this.statiPratica.push({
      label: "CONSEGNATA",
      command: (event) => {
        this.filtraPerStato("CONSEGNATA");
      },
    });
    this.statiPratica.push({
      label: "IN REWORK AZIENDA",
      command: (event) => {
        this.filtraPerStato("IN REWORK AZIENDA");
      },
    });
    this.statiPratica.push({
      label: "APPROVATA",
      command: (event) => {
        this.filtraPerStato("APPROVATA");
      },
    });
    this.statiPratica.push({
      label: "RESPINTA",
      command: (event) => {
        this.filtraPerStato("RESPINTA");
      },
    });
    this.statiPratica.push({
      label: "TUTTE",
      command: (event) => {
        this.praticheSanitaDT.clear();
      },
    });
   
  }

  loadCSVBox(){
    this.csvboxLoaderService.loadScript().then(() => {
      this.csvbox_importer = new CSVBoxImporter(this.csvbox_licenseKey_importaPraticheSanita, {
        // Configuration options
      }, (result: boolean, data: any) => {
        if (result) {
          this.logger.info("Sheet uploaded successfully");
          this.logger.info(data.row_success + " rows uploaded");
          this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche sanità importate con successo!', life:10000});
        } else {
          this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import delle pratiche', life:10000});
          // Custom code
        }
      });

    
      this.csvbox_importer.setUser({
        user_id: "default123"
      });
      this.csvbox_importer.language="it";

    });

    this.csvbox_importer_aggiornaPratiche = new CSVBoxImporter(this.csvbox_licenseKey_aggiornaPraticheSanita, {
      // Configuration options
    }, (result: boolean, data: any) => {
      if (result) {
        this.logger.info("Sheet uploaded successfully");
        this.logger.info(data.row_success + " rows uploaded");
        this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' pratiche MODIFICATE con successo!', life:10000});
      } else {
        this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di MODIFICA delle pratiche', life:10000});
        // Custom code
      }
    });

    this.csvbox_importer_aggiornaPratiche.setUser({
      user_id: "default123"
    });
    this.csvbox_importer_aggiornaPratiche.language="it"; 

 
  }

  loadImprese(){
    this.isLoadingResults = true;
    this.impreseService.getByBando("2").subscribe((res) => {
      this.isLoadingResults = false;

      res.forEach(impresa => {
        if(impresa.ragioneSociale!=='Admin Fake Company'){
          this.imprese.push({
            label: impresa.ragioneSociale,
            command: (event) => {
              this.praticheSanitaDT.filter(impresa.ragioneSociale, "azienda", "is");
            },
          });
        }
          
      });
      this.imprese.push({
        label: "TUTTE",
        command: (event) => {
          this.praticheSanitaDT.clear();
        },
      });
    }); 
  }
  filtraPerNome(event: any) {
    this.praticheSanitaDT.filterGlobal(event.target.value, 'contains');
  }

  filtraPerStato(stato: String) {
    this.praticheSanitaDT.filter(stato, "stato", "is");
  }

  getStatoClass(status: string): string {
    if (status === "CARICATA") {
      return "stato-pratica-caricata";
    }else if (status === "ASSEGNATA") {
      return "stato-pratica-assegnata";
    } else if (status === "IN PROGETTAZIONE") {
      return "stato-pratica-inprogettazione";
    } else if (status === "IN CARICO IMPRESA") {
      return "stato-pratica-incarico";
    } else if (status === "CONSEGNATA") {
      return "stato-pratica-consegnata";
    } else if (status === "IN REWORK AZIENDA") {
      return "stato-pratica-inrework";
    }else if (status === "IN VERIFICA SMARTFIBER") {
      return "stato-pratica-inrework-presidio";
    }else if (status === "IN FIRMA") {
      return "stato-pratica-inFirma";
    }else if (status === "IN FIRMA VERBALE") {
      return "stato-pratica-inFirmaVerbale";
    }else if (status === "VERBALE FIRMATO") {
      return "stato-pratica-verbaleFirmato";
    }else if (status === "IN FIRMA PACCHETTO") {
      return "stato-pratica-inFirmaPacchetto";
    }else if (status === "FIRMATA") {
      return "stato-pratica-firmata";
    }else if (status === "IN CARICO PRESIDIO") {
      return "stato-pratica-incarico-tim";
    }else if (status === "APPROVATA") {
      return "stato-pratica-approvata";
    }else if (status === "RESPINTA") {
      return "stato-pratica-respinta";
    }else if (status === "ANNULLATA") {
      return "stato-pratica-annullata";
    }else if (status === "PUBBLICATA") {
      return "stato-pratica-pubblicata";
    }
    
    return "";
  }

 
  ngAfterViewInit() {}

  retrieveColocata(codicePrincipale:string):string
  {
    this.logger.info("> retrieveColocata su totale di  " + this.mappaCodiciScuoleColocate.size);
    this.mappaCodiciScuolePrincipali.forEach(colocata => {
      this.logger.info(colocata);

    });
    return "";  
  }

  
  getPrincipaleDiUnaColocata(pratica: PraticaSanita_Firestore):string{
    /* if(this.isDirigenteFirmaPacchetti){
      return pratica.colocataScuolaPrincipale.valueOf();
    } */
   return this.mappaCodiciScuoleColocate.get(pratica.codiceInfratel.valueOf());  
  }

  getCodiceInfratelDellaColocataDiUnaPrincipale(pratica: PraticaSanita_Firestore):string{
    this.logger.info(this.mappaCodiciScuolePrincipali);
    return this.mappaCodiciScuolePrincipali.get(pratica.codiceInfratel.valueOf());  
   }

  getColocataDiUnaPrincipale(codiceInfratel: String):Observable<PraticaSanita_Firestore[]>{

    this.isLoadingResults = true;
    let praticaColocata:PraticaSanita_Firestore = null;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", codiceInfratel);
    const risultati: PraticaSanita_Firestore[] = [];
    query.get().then(qs=>{

      qs.forEach(doc =>{
        praticaColocata = doc.data() as PraticaSanita_Firestore;
        risultati.push(praticaColocata);
      });
      
      return risultati;
    });
    return new Observable<PraticaSanita_Firestore[]>;

    
  }

  getCodiceInfratelDiUnaPrincipale(codiceInfratel: String):String{
    this.logger.info("---> getCodiceInfratelDiUnaPrincipale "+codiceInfratel);

    this.isLoadingResults = true;
    let praticaColocata:PraticaSanita_Firestore = null;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", codiceInfratel);
    onSnapshot(query, (qs) => {
          this.isLoadingResults = false;
          if( qs.size==1){
            praticaColocata = qs.docs[0].data() as PraticaSanita_Firestore;
            return praticaColocata.codiceInfratel;
          }else{
            return null;
          }
    });

    return null;
  }

  loadAllPraticheScuolaFromFirestore() {
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
    //.where("bando", "==", "BANDO 2")
    //.where("stato", "!=", "ANNULLATA")
    .orderBy("stato");
    onSnapshot(query, (qs) => {
        this.praticheAtFirestore = [];
        this.isLoadingResults = false;
        //this.logger.info(  "--> RISULTATO LOAD PRATICHE (loadAllPraticheScuolaFromFirestore) > " + qs.docs.length );
        this.numeroTotalePratiche = qs.docs.length;
        qs.forEach((doc) => {
          let pratica = doc.data() as PraticaSanita_Firestore;
          this.praticheAtFirestore.push(pratica);
          /* if(pratica.colocata && pratica.colocataRuolo ==='SECONDARIA'){
            const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_sanita).doc(pratica.colocataScuolaPrincipale.valueOf());
            praticheScuolaRef.get().subscribe((doc) => {
              if (doc.exists) {
                const praticaPrincipale = doc.data() as PraticaSanita_Firestore;
                this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());
              } 
              
            }, (error) => {
              this.logger.info("Errore nel recupero del documento:", error);
            });
            
          }else if(pratica.colocata && pratica.colocataRuolo ==='PRINCIPALE'){
            
          } */
          
          
        });
        this.praticheSanitaDT.filter("ANNULLATA", "stato", "isNot");
        if(this.filterIsSet){
          if(this.filtroStato){
            this.logger.info(  "--> al risultato applico FILTRO PER STATO > " +this.filtroStato );

            this.praticheSanitaDT.filter(this.filtroStato, "stato", "is");
          }else if(this.filtroPratica){
            this.logger.info(  "--> al risultato applico FILTRO PER CODICE PRATICA > " +this.filtroPratica );

            //let codiceInfratel = this.getCodiceInfratelDiUnaPrincipale(this.filtroPratica);
            //this.logger.info(codiceInfratel);
            //this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "is");
            //this.praticheScuolaDT.filter(this.filtroPratica, "codiceInfratel", "contains");

          }
          
        }
    });
  }

  openAggiornaPratiche(){
    this.logger.info("*** AGGIORNA PRATICHE BANDO 2 *****");
    this.csvbox_importer_aggiornaPratiche.openModal();
  }

  loadAllPraticheSanitaFromFirestoreWithFilter() {
    this.isLoadingResults = true;
    if(this.filtroStato){
      this.logger.info("--> LOAD PRATICHE PER ADMIN CON FILTRO SU STATO > " + this.filtroStato);

      let filtroStato:String[] = [];
      if(this.filtroStato==="IN CARICO SF"){
        filtroStato = ["CONSEGNATA","IN VERIFICA SMARTFIBER","IN REWORK SMARTFIBER"];
      }else if(this.filtroStato==="IN LAVORAZIONE"){
        filtroStato = ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "IN REWORK AZIENDA"];
      }else if(this.filtroStato==="IN CARICO PRESIDIO"){
        //filtroStato = ["IN CARICO PRESIDIO","IN FIRMA","IN FIRMA VERBALE","IN FIRMA PACCHETTO", "FIRMATA", "PUBBLICATA"];
        filtroStato = ["IN CARICO PRESIDIO", "FIRMATA"/* , "PUBBLICATA" */];
      }else if(this.filtroStato==="IN FIRMA VERBALE"){
        filtroStato = ["IN FIRMA VERBALE"];
      }else if(this.filtroStato==="IN FIRMA PACCHETTO"){
        filtroStato = ["IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="VERBALE FIRMATO"){
        filtroStato = ["VERBALE FIRMATO"];
      }else if(this.filtroStato==="DA ASSEGNARE"){
        filtroStato = ["CARICATA"];
      }else if(this.filtroStato==="RESPINTA"){
        filtroStato = ["RESPINTA"];
      }else if(this.filtroStato==="FIRMATA"){
        filtroStato = ["FIRMATA"];
      }else if(this.filtroStato==="PACCHETTO FIRMATO"){
        filtroStato = ["PACCHETTO FIRMATO"];
      }else if(this.filtroStato==="APPROVATA"){
        filtroStato = ["APPROVATA"];
      }else if(this.filtroStato==="IN FIRMA"){
        filtroStato = ["IN FIRMA VERBALE","VERBALE FIRMATO","IN FIRMA PACCHETTO"];
      }else if(this.filtroStato==="PUBBLICATA"){
        filtroStato = ["PUBBLICATA"];
      }
      const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      const query = collectionRef
      .where("stato", "in" , filtroStato)
      .orderBy("stato");
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaSanita_Firestore;
            this.praticheAtFirestore.push(pratica);
          
            
          });
        
      });
    }else if(this.filtroPratica){

      const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      const query = collectionRef .where("codiceInfratel", "==",this.filtroPratica) ;
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaSanita_Firestore;
            this.praticheAtFirestore.push(pratica);
           
          });
      });
    }else if(this.filtroPT){
      this.logger.info("--> LOAD PRATICHE PER ADMIN CON FILTRO SU PIANO TECNICO > " + this.filtroPT);

      const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      const query = collectionRef .where("pianoTecnico", "==",this.filtroPT) ;
      onSnapshot(query, (qs) => {
          this.praticheAtFirestore = [];
          this.isLoadingResults = false;
          this.numeroTotalePratiche = qs.docs.length;
          qs.forEach((doc) => {
            let pratica = doc.data() as PraticaSanita_Firestore;
            this.praticheAtFirestore.push(pratica);
           
          });
      });
    }
    
  }

  openCreaPraticaDialog(){
     /*  this.ref = this.dialogService.open(DialogCreaPratica, {
        data: {
        },
        header: "Creazione Nuova Pratica ",
        width: this.isMobile ? "95%" : "85%",
        height: "85%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((infoUpdated) => {
        if (infoUpdated && infoUpdated.success) {
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Praticha creata con successo"
          });
        }
      }); */
  }

  checkIfPraticheDaFirmare(){
    this.logger.info(  "--> checkIfPraticheDaFirmare"  );
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
      .where("stato", "in", ["VERBALE FIRMATO"])
     // .where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
          if(qs.docs.length >0 ){
            this.hasPraticheDaFirmare = true;
          }
          this.hasPraticheDaFirmare = false;
    });
    
  }
  loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA PACCHETTI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
      .where("stato", "in", ["VERBALE FIRMATO","FIRMATA"])
      //.where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaSanita_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheSanitaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheSanitaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
    });
   // this.checkIfPraticheDaFirmare();
  }
  loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER DIRIGENTE FIRMA VERBALI"  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
      .where("stato", "in", ["IN FIRMA VERBALE","VERBALE FIRMATO"])
      //.where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaSanita_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheSanitaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheSanitaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
    });
  }

  loadPraticheScuolaPresidioTIMFromFirestore(){
    this.logger.info(  "--> LOAD PRATICHE PER PRESIDIO TIM "  );
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
      //.where("stato", "in", ["IN CARICO PRESIDIO", "IN FIRMA VERBALE","IN FIRMA PACCHETTO","FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
      .where("stato", "in", ["IN CARICO PRESIDIO", "FIRMATA","PUBBLICATA","APPROVATA","RESPINTA"])
      //.where("bando", "==", "BANDO 2")
      .orderBy("stato");
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        this.praticheAtFirestore.push(doc.data() as PraticaSanita_Firestore);
      });
      if(this.filterIsSet){
        if(this.filtroStato){
          this.praticheSanitaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.praticheSanitaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
      
    });
  }

  loadPraticheSanitaPerAziendaFromFirestore() {
    this.logger.info("** ** LOAD PRATICHE PER AZIENDA " + localStorage.getItem("idAzienda"));
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef
       .where(
        "idAzienda",
        "==",
        Number.parseInt(localStorage.getItem("idAzienda"))
      ) 
      .where("stato", "in", ["ASSEGNATA","IN CARICO IMPRESA","IN PROGETTAZIONE", "CONSEGNATA","IN REWORK AZIENDA","IN VERIFICA SMARTFIBER", "IN CARICO PRESIDIO","IN FIRMA VERBALE","IN FIRMA PACCHETTO","VERBALE FIRMATO", "PACCHETTO FIRMATO" ,"APPROVATA", "RESPINTA"])
      .where("lavorabile", "==", true)
      .orderBy("comune")
      .orderBy("codiceInfratel")
      .orderBy("stato") ;
      onSnapshot(query, (qs) => {
      this.praticheAtFirestore = [];
      this.isLoadingResults = false;
      //this.logger.info( "--> RISULTATO LOAD PRATICHE PER AZIENDA " + qs.docs.length  );
      this.numeroTotalePratiche = qs.docs.length;
      qs.forEach((doc) => {
        let pratica: PraticaSanita_Firestore = doc.data() as PraticaSanita_Firestore;
        this.praticheAtFirestore.push(pratica);
        /* if(pratica.colocata && pratica.colocataRuolo ==='SECONDARIA'){
          const praticheScuolaRef = this.firestore.collection(this.collection_pratiche_sanita).doc(pratica.colocataScuolaPrincipale.valueOf());
          praticheScuolaRef.get().subscribe((doc) => {
            if (doc.exists) {
              const praticaPrincipale = doc.data() as PraticaSanita_Firestore;
              this.mappaCodiciScuoleColocate.set(pratica.codiceInfratel.valueOf(),praticaPrincipale.codiceMiur.valueOf());
            } 
            
          }, (error) => {
            this.logger.info("Errore nel recupero del documento:", error);
          });
          
        } */
        
      });

      if(this.filterIsSet){
        if(this.filtroStato){
          this.logger.info("** ** FILTRO PER STATO " + this.filtroStato);
          this.praticheSanitaDT.filter(this.filtroStato, "stato", "is");
        }else if(this.filtroPratica){
          this.logger.info("** ** FILTRO PER PRATICA " + this.filtroPratica);

          this.praticheSanitaDT.filter(this.filtroPratica, "codiceInfratel", "is");
        }
      }
      
    });
  }

  reloadDataRemovingFilter(){
    this.location.replaceState('/pratiche-scuola-sanita');
    this.filterIsSet = false;
    this.filtroPratica = null;
    this.filtroStato = null;
    this.filtroPT = null;

    this.praticheSanitaDT.clear();
    if (this.isAdmin || this.isSuperAdmin || this.isPresidioSF) {
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadAllPraticheScuolaFromFirestore();
      }
    
    }else if(this.isPresidioTIM){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaPresidioTIMFromFirestore();
      }
     
    }else if(this.isDirigenteFirmaVerbali){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaVerbaliFromFirestore();
      }
    }else if(this.isDirigenteFirmaPacchetti){
      if(this.filterIsSet){
        this.loadAllPraticheSanitaFromFirestoreWithFilter();
      }else{
        this.loadPraticheScuolaDirigenteFirmaPacchettiFromFirestore();
      }
    }else {
      this.loadPraticheSanitaPerAziendaFromFirestore();
    }
  }

  calcolaTotali() {}

  openGis2Web(pratica: PraticaSanita_Firestore){
    this.logger.info("APRO GIS 2 WEB DELLA PRATICA "+pratica.codiceInfratel);
   // window.open("https://nt9convergo-viewer.maphosting.it/viewer/index.php/TimScuole/tim_"+pratica.codiceMiur.toLowerCase()+"#/map/ZOOMLAYER=Scuole&ZOOMFILTER=gid$$$EQ$$$1", '_blank');
    //window.open("https://nt9convergo-cloud.maphosting.it/", '_blank');
    
  }

  openNoteReworkPratica(pratica: PraticaSanita_Firestore) {
   /*  this.isLoadingResults = true;
    
    this.notePraticheService.getById(pratica.id.toString()).subscribe(note => {
      this.isLoadingResults = false;
      this.logger.info(note);
      if(note && note.length > 0){
        this.ref = this.dialogService.open(DialogNoteRework, {
          data: {
            pratica: pratica,
            note: note
          },
          header: "Note della pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "90%",
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
      }else{
        this.messageService.add({
          severity: "info",
          summary: "Attenzione!",
          detail: "La pratica non presenta NOTE",
        });
      }
    }); */

  }
  openMandaInRework(pratica: PraticaSanita_Firestore) {
    /* this.ref = this.dialogService.open(DialogInserisciNoteRework, {
      data: {
        pratica: pratica,
      },
      header: "Rework della pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "75%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((noteResult) => {
      if(noteResult && noteResult.success){
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica inviata all'impresa per un rework",
        });
      }
    }); */


  }
  openMandaAlPresidioTIM(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> openMandaAlPresidioTIM --> " + pratica.id);
    /* let messaggio = `Confermi di voler procedere con l'invio della pratica ${pratica.codiceInfratel} al PRESIDIO TIM?`;
    if(pratica.colocata){
      messaggio +=`<br>Trattandosi di pratica <b>colocata</b>, verranno inviate anche tutte le pratiche associate!`; 
    }
    this.confirmationService.confirm({
      message: messaggio,
      header: "Invia al PRESIDIO TIM",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaInviareAlPresidio: PraticaScuola = new PraticaScuola();
        praticaDaInviareAlPresidio.id = pratica.id;
        this.logger.info(praticaDaInviareAlPresidio);
        this.praticheSanitaService.inviaAlPresidio(praticaDaInviareAlPresidio).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di invio al presidio TIM della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica inviata con successo al presidio TIM",
            });
          },
        }); 
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); */
  }

  openStoricoPratica(pratica: PraticaSanita_Firestore) {
   this.isLoadingResults = true;
    this.storicoPraticheService.getStoricoPraticaSanitaById(pratica.id.toString()).subscribe(storicoPratica=>{
      this.isLoadingResults = false;
        if(storicoPratica && storicoPratica.length > 0){
          this.ref = this.dialogService.open(DialogStoricoPraticaSanita, {
            data: {
              pratica: pratica,
              storicoPratica: storicoPratica
            },
            header: "Storico della pratica "+pratica.codiceInfratel,
            width: this.isMobile ? "95%" : "90%",
            height: "35%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
        }else{
          this.messageService.add({
            severity: "info",
            summary: "Attenzione!",
            detail: "La pratica non presenta STORICO",
          });
        }
    }); 
  }

  

  onPraticaSelection(pratica: PraticaSanita_Firestore) {
    if (this.selectedPratiche && this.selectedPratiche.length > 1) {
      this.disableSingleActionButtons = true;
    } else {
      this.disableSingleActionButtons = false;
    }
  }
  isDaAssegnare(pratica: PraticaSanita_Firestore): boolean {
   
    if (pratica.stato === "CARICATA") {
      return true;
    }
    return false;
  }
  iAssegnata(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA") {
      return true;
    }
    return false;
  }
  isInCaricoPresidioTIM(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN CARICO PRESIDIO") {
      return true;
    }
    return false;
  }
  isDaPrendereInCarico(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA") {
      return true;
    }
    return false;
  }

  isDaProgettare(pratica:PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }


  isInCarico(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }

  isInProgettazione(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN PROGETTAZIONE") {
      return true;
    }
    return false;
  }

  isProgettata(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.progettata && pratica.progettata === true) {
      return true;
    }
    return false;
  }

  isDeliverable(pratica: PraticaSanita_Firestore): boolean {
    if (
      pratica.stato === "IN PROGETTAZIONE" &&  pratica.fotoRouterUploaded && pratica.progettata
    ) {
      return true;
    }
    return false;
  }

  areDocsCreatable(pratica: PraticaSanita_Firestore): boolean {
    if (
      (pratica.stato === "IN PROGETTAZIONE" || pratica.stato === "IN REWORK AZIENDA") && 
      pratica.fotoRouterUploaded &&
      pratica.infoCompleted
    ) {
      return true;
    }
    return false;
  }

  

  isDelivered(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "CONSEGNATA") {
      return true;
    }
    return false;
  }
  isInRework(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN REWORK AZIENDA") {
      return true;
    }
    return false;
  }

  isSignable(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN CARICO PRESIDIO" && pratica.ordineEsecuzione && pratica.computoMetricoUploaded ) {
      return true;
    }
    return false;
  }

  isSigned(pratica: PraticaSanita_Firestore): boolean {
    this.logger.info("> isSigned " + pratica.stato)
    if (pratica.stato === "FIRMATA" ) {
      return true;
    }
    return false;
  }

  isPublished(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "PUBBLICATA" ) {
      return true;
    }
    return false;
  }

  isApproved(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "APPROVATA") {
      return true;
    }
    return false;
  }
  isRejected(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "RESPINTA") {
      return true;
    }
    return false;
  }
  isInVerbalSignState(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN FIRMA VERBALE") {
      return true;
    }
    return false;
  }
  isInSigningPackageState(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO") {
      return true;
    }
    return false;
  }

  
  hasSignedVerbal(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "VERBALE FIRMATO") {
      return true;
    }
    return false;
  }
  hasSignedPackage(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "FIRMATA") {
      return true;
    }
    return false;
  }

  
  hasRejectionFile(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.noteRespingimentoFile &&  pratica.noteRespingimentoFile.length > 0) {
      return true;
    }
    return false;
  }
  hasReworkFile(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.noteReworkFile &&  pratica.noteReworkFile.length > 0) {
      return true;
    }
    return false;
  }
  
  isInCaricoPresidioSF(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "IN VERIFICA SMARTFIBER") {
      return true;
    }
    return false;
  }

  isVoidable(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.stato === "ASSEGNATA" ||  pratica.stato === "CARICATA" || pratica.stato === "IN CARICO IMPRESA") {
      return true;
    }
    return false;
  }

 

  
 

  hasCoordinates(pratica: PraticaSanita_Firestore): boolean {
    if (pratica.coordinateAccesso) {
      return true;
    }

    return false;
  }

  caricaPraticheSanita() {
    this.csvbox_importer.openModal();
  }

  loadAzioniScaricaFile() {
    this.menuScaricaFileItems = [
      {
        label: "RET",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("RET"),
      },
      {
        label: "PSI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",

        command: () => this.scaricaDocs("PSI"),
      },
      {
        label: "ALL.IRU",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("IRU"),
      },
      {
        label: "TUTTI",
        icon: PrimeIcons.FILE_WORD,
        styleClass: "RETstyle",
        command: () => this.scaricaDocs("ALL"),
      },
    ];
  }
  loadAzioniRisultatoInvioPratica(pratica:PraticaSanita_Firestore){
    this.menuRisultatiInvioPraticaItems = [
      {
        label: "APPROVATA",
        icon: PrimeIcons.CHECK,
        styleClass: "valid-menu-item",
        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
      },
      {
        label: "RESPINTA",
        icon: PrimeIcons.TIMES,
        styleClass: "reject-menu-item",

        command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
      },
    ];
  }


  openRisultatoAzioniConsegnaPratica(pratica:PraticaSanita_Firestore, isApprovata:boolean){
   /*  if(isApprovata){
      this.logger.info("--> PRATICA VALIDATA");
      this.isLoadingResults = true;
      this.praticheSanitaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.praticheSanitaService.comunicaApprovazionePratica(pratica).subscribe(res=>{
          this.isLoadingResults = false;
          this.messageService.add({
            severity: "success",
            summary: "" , life:10000,
            detail: "Pratica aggiornata con successo",
          });
        });
      });
      
    }else {
      this.logger.info("--> PRATICA RESPINTA");
      this.isLoadingResults = true;
      this.praticheSanitaService
      .getById(pratica.id.toString())
      .subscribe((pratica) => {
        this.ref = this.dialogService.open(DialogInserisciNoteRespingimentoPratica, {
          data: {
            pratica: pratica,
          },
          header: "Respingimento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica aggiornata con successo",
            });
          }
        });
        })
      
    } */

  }
  

  loadAzioni(event:any, pratica:PraticaSanita_Firestore) {
    this.menuAzioniItems = [];
    
    if(!this.isDirigenteFirmaVerbali && !this.isDirigenteFirmaPacchetti){
      this.menuAzioniItems.push(
        {
          label: "Storico",
          icon: PrimeIcons.HISTORY,
          style: { 'font-size': '13px'},
          command: () => this.openStoricoPratica(pratica),
        },
       {
          label: "Posizione",
          icon: PrimeIcons.MAP_MARKER,
          style: { 'font-size': '13px'},
          command: () => this.openMappaConAccesso(pratica),
        }  );
    }
   
    
    //IMPRESA ===================================================================================================================
    if(this.isImpresa ){ 
      if(this.isDaPrendereInCarico(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
      if(this.isDaProgettare(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Inizia Progettaz.",
            icon: PrimeIcons.FILE_EDIT,
            style: {  'font-weight': 'bold', 'font-size': '13.5px'},
            command: () => this.openIniziaProgettazione(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,
            style: { 'font-size': '14px'},
            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );
      }
      if(this.isInProgettazione(pratica) || this.isInRework(pratica)){
  
        this.menuAzioniItems.push(
          {
            label: "Cambio Centrale",
            icon: PrimeIcons.ENVELOPE,
            style: { 'font-size': '14px'},
            command: () => this.openRichiediModificaCentrale(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Inserisci Info",
            icon: PrimeIcons.SERVER,
            style: {  'font-size': '14px', 'font-weight': 'bold'},
            command: () => this.openInserisciDettagli(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Disegna",
            icon: "icona-disegna",
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            command: () => this.openDisegnaPratica(pratica),
          },
          );
       
        this.menuAzioniItems.push(
          {
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            items: [
              {
                label: "Foto Router",
                icon: PrimeIcons.CAMERA,
                style: { 'font-size': '14px'},
                command: () => this.openCaricaFotoRouter(pratica),
              } ,   
              {
                label: "Planimetria",
                icon: PrimeIcons.IMAGE,
                style: { 'font-size': '14px'},
                command: () => this.openCaricaPlanimetria(pratica),
              },
            ],
          }
        );
      }
     
      if((this.isDeliverable(pratica) || this.isInRework(pratica))){
        this.menuAzioniItems.push(
         
          {
            label: "Scarica Shape",
            icon: "icona-report",
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            command: () => this.openScaricaShapePratica(pratica),
          });
        this.menuAzioniItems.push(
          {
            label: "Consegna",
            icon: PrimeIcons.CHECK_CIRCLE,
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            command: () => this.openConsegnaPratica(pratica),
          },
        );
      }

  
      if(this.isDelivered(pratica)){
        this.menuAzioniItems.push(
         
          {
            label: "Scarica Shape",
            icon: "icona-report",
          //  style: {  'font-weight': 'bold'},
            command: () => this.openScaricaShapePratica(pratica),
          },

        
           {
            label: "Annulla Consegna",
            icon: PrimeIcons.BAN,
            style: {  'font-weight': 'bold', 'font-size': '14px'},
            command: () => this.openAnnullaConsegnaPratica(pratica),
          },

        );
      }

      if(this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica) && this.hasReworkFile(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica all. rework",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }
      //PRESIDIO SF ===================================================================================================================
    }else if(this.isPresidioSF){
      this.logger.info("**** isPresidioSF");
      if(this.isDaPrendereInCarico(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.FILE_IMPORT,
            style: {  'font-weight': 'bold'},
            command: () => this.openPrendiInCarico(pratica),
          },
        );
      }
      if((this.isDeliverable(pratica) || this.isInRework(pratica))){
        this.menuAzioniItems.push(
         
          {
            label: "Scarica Shape",
            icon: "icona-report",
            style: {  'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.openScaricaShapePratica(pratica),
          });
      }
      if(this.isInProgettazione(pratica)){
        this.menuAzioniItems.push(
         
          {
            label: "Scarica Shape",
            icon: "icona-report",
            style: {  'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.openScaricaShapePratica(pratica),
          },
          {
            label: "Info Fase 3",
            icon: PrimeIcons.SERVER,
            style: {   'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.openInserisciDettagli(pratica),
          });
      }
     
    /*   if(this.isDaProgettare(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Inizia Progettazione",
            icon: PrimeIcons.FILE_EDIT,
            style: {  'font-weight': 'bold'},
            command: () => this.openIniziaProgettazione(pratica),
          },
        );
      } */
      /* if(this.isInProgettazione(pratica) || this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Inserisci Info",
            icon: PrimeIcons.SERVER,
            command: () => this.openInserisciDettagli(pratica),
          },
        );
      } */
      /* if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.areDocsCreatable(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Genera Docs",
            icon: PrimeIcons.EYE,
            command: () => this.openPreviewDocs(pratica),
          },
        );
      } */

     
      if(this.isInRework(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Note Rework",
            icon: PrimeIcons.COMMENT,
            style: {   'font-size': '11px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInRework(pratica) && this.hasReworkFile(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica all. rework",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            style: {   'font-size': '11px'},
            command: () => this.scaricaFileReworkPratica(pratica),
          },
        );
      }

      /* if(this.isDelivered(pratica) || this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica Docs",
            icon: PrimeIcons.CLOUD_DOWNLOAD,
            items: [
              {
                label: "RET",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
        
                command: () => this.scaricaDocs("RET"),
              },
              {
                label: "PSI",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
        
                command: () => this.scaricaDocs("PSI"),
              },
              {
                label: "ALL.IRU",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                command: () => this.scaricaDocs("IRU"),
              },
              {
                label: "TUTTI",
                icon: PrimeIcons.FILE_WORD,
                styleClass: "RETstyle",
                command: () => this.scaricaDocs("ALL"),
              },
            ]
           // command: () => this.openScaricaDocumentazionePratica(pratica),
          },
        );
      } */

      if(this.isInCaricoPresidioSF(pratica) ){ //|| this.isDelivered(pratica)
        this.menuAzioniItems.push(
          {
            label: "Scarica Shape",
            icon: "icona-report",
            style: {  'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.openScaricaShapePratica(pratica),
          },
          {
            label: "Info Fase 3",
            icon: PrimeIcons.SERVER,
            style: {  'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.openInserisciDettagli(pratica),
          },
         /*  {
            label: "Genera RET",
            icon: PrimeIcons.FILE_WORD,
            style: {  'font-weight': 'bold', 'font-size': '13px'},
            command: () => this.generaRET(pratica),
            }, */
            {
              label: "Scarica Pacchetto",
              icon: PrimeIcons.DOWNLOAD,
              style: {  'font-weight': 'bold', 'font-size': '13px'},
              command: () => this.scaricaPacchettoPianoTecnico(pratica),
              },
        );
      }
     /*if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.isInCaricoPresidioSF(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Manda in Rework",
            icon: PrimeIcons.CHEVRON_CIRCLE_LEFT,
            command: () => this.openMandaInRework(pratica),
          },
        );
         this.menuAzioniItems.push(
          {
            label: "Invia al Presidio",
            style: {  'font-weight': 'bold'},
            icon: PrimeIcons.CHEVRON_CIRCLE_RIGHT,
            command: () => this.openMandaAlPresidioTIM(pratica),
          },
        ); 
      }*/


      if((this.isDelivered(pratica) || this.isRejected(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            style: {  'font-weight': 'bold'},
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },
        );
      }

      /* if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Assegna",
            icon: PrimeIcons.PAPERCLIP,
            command: () => this.openAssegnaAdImpresa(pratica),
          },
        );
      } */

      /* if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            style: {  'font-size': '12px'},
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      } */

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica File Respingimento",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            style: {  'font-size': '12px'},
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      if(this.isVoidable(pratica)){ 
        this.menuAzioniItems.push(
          {
            label: "Modifica",
            icon: PrimeIcons.PENCIL,
            command: () => this.openModificaPraticaDialog(pratica),
          },
        );
      }
        
     //PRESIDIO TIM ===================================================================================================================
    }else if(this.isPresidioTIM){
      if(this.isInCaricoPresidioTIM(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Completa Pratica",
            style: {  'font-weight': 'bold'},
            icon: PrimeIcons.FILE_EDIT,
            command: () => this.openCompletaPraticaPresidioTIM(pratica),
          },
        );
      }
      if( (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && (this.isSigned(pratica) || this.isPublished(pratica)) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica Pacchetto",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaPacchettoFirmato(pratica),
          });//,
        } 
        if((!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && this.isPublished(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            { 
                label: "Risultato Invio",
                style: {  'font-weight': 'bold'},
                icon: PrimeIcons.INBOX,
                items: [
                  {
                    label: "APPROVATA",
                    icon: PrimeIcons.CHECK,
                    styleClass: "valid-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, true),
                  },
                  {
                    label: "RESPINTA",
                    icon: PrimeIcons.TIMES,
                    styleClass: "reject-menu-item",
                    command: () => this.openRisultatoAzioniConsegnaPratica(pratica, false),
                  },
                ]
        
            });
        }
          /* ); */
      
      

    }else if(this.isAdmin || this.isSuperAdmin){
      this.menuAzioniItems.push(
        {
          label: "Modifica",
          icon: PrimeIcons.PENCIL,
          command: () => this.openModificaPraticaDialog(pratica),
        },
      );
      if(this.isVoidable(pratica)){
        if(this.isDaAssegnare(pratica) && !this.disableSingleActionButtons){
          this.menuAzioniItems.push(
            {
              label: "Assegna",
              icon: PrimeIcons.PAPERCLIP,
              style: {  'font-weight': 'bold'},
              command: () => this.openAssegnaAdImpresa(pratica),
            },
          );
        }
        this.menuAzioniItems.push(
          {
            label: "Annulla",
            icon: PrimeIcons.TIMES,
            style: {  'font-weight': 'bold'},
            command: () => this.openAnnullaPratica(pratica),
          },
        );
        this.menuAzioniItems.push(
          {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: {  'font-weight': 'bold'},
            command: () => this.openEliminaPratica(pratica),
          },
        );

      }
     
      if(this.isApproved(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaPacchettoPratica(pratica, false),
          },
        );
      }
      if(this.isInCaricoPresidioSF(pratica) && (!pratica.colocata || pratica.colocataRuolo ==='PRINCIPALE') && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Note Respingimento",
            icon: PrimeIcons.COMMENT,
            command: () => this.openNoteReworkPratica(pratica),
          },
        );
      }

      if(this.isInCaricoPresidioSF(pratica) && this.hasRejectionFile(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Scarica File Respingimento",
            icon: PrimeIcons.ARROW_CIRCLE_DOWN,
            command: () => this.scaricaFileRespingimentoPratica(pratica),
          },
        );
      }
      if(this.isRejected(pratica) && !this.disableSingleActionButtons){
        this.menuAzioniItems.push(
          {
            label: "Prendi in carico",
            icon: PrimeIcons.WRENCH,
            command: () => this.openInCaricoPresidio(pratica),
          },
        );
      }
    
    }else if(this.isDirigenteFirmaVerbali ){
      
      if(this.isInVerbalSignState(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Scarica",
            icon: PrimeIcons.DOWNLOAD,
            command: () => this.scaricaVerbale(pratica, false),
          },
          {
            label: "Carica Firmato",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, false),
          }
        );
      }
      if(this.hasSignedVerbal(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }else if (this.isDirigenteFirmaPacchetti){
      
      this.logger.info(">>> isDirigenteFirmaPacchetti ");
      if(this.isInSigningPackageState(pratica) && (!pratica.colocata || pratica.colocataRuolo=='PRINCIPALE')){
        this.menuAzioniItems.push(
          {
                label: "Scarica Pacchetto",
                icon: PrimeIcons.DOWNLOAD,
                command: () => this.scaricaPacchettoPratica(pratica, false),
              } ,
              {
                label: "Carica Firmato",
                icon: PrimeIcons.UPLOAD,
                command: () => this.caricaPacchettoFirmato(pratica, false),
              } ,
            
          
        );
      }
      if(this.hasSignedPackage(pratica)){
        this.menuAzioniItems.push(
          {
            label: "Ricarica",
            icon: PrimeIcons.UPLOAD,
            command: () => this.caricaVerbaleFirmato(pratica, true),
          },
        );
      }
    }
    
    
    /* 
    this.menuCaricaFileItems = [
      {
        label: "FOTO ROUTER",
        icon: PrimeIcons.CAMERA,
        command: () => this.openCaricaFotoRouter(pratica),
      },
      {
        label: "PLANIMETRIA",
        icon: PrimeIcons.IMAGE,
        command: () => this.openCaricaPlanimetria(pratica),
      },
      {
        label: "NT9",
        icon: PrimeIcons.BUILDING,
        command: () => this.openCaricaPacchettoNT9(pratica),
      }
    ]; */
  }

  loadAzioniCaricaFile(pratica:PraticaSanita_Firestore) {
    this.menuCaricaFileItems = [
      {
        label: "FOTO ROUTER",
        icon: PrimeIcons.CAMERA,
        command: () => this.openCaricaFotoRouter(pratica),
      },
      {
        label: "PLANIMETRIA",
        icon: PrimeIcons.IMAGE,
        command: () => this.openCaricaPlanimetria(pratica),
      },
      {
        label: "NT9",
        icon: PrimeIcons.BUILDING,
        command: () => this.openCaricaPacchettoNT9(pratica),
      }
    ];
  }

  showPulsante(pratica:PraticaSanita_Firestore):boolean{
    if(this.isDirigenteFirmaPacchetti && pratica.colocataRuolo==='SECONDARIA'){
      return false;
    }
    return true;
  }

  async scaricaDocs(action: string) {
   /*  const storage = getStorage();
    if (action === "RET") {
      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE'){
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }else{
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }
      
    } else if (action === "PSI") {
      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE'){
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }else{
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }
     
    } else if (action === "IRU") {

      const pathReferencePSI = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/ALLEGATO CONTRATTO IRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );

      const blobPSI = await getBlob(pathReferencePSI);
      saveAs(
        blobPSI,
        "ALLEGATO CONTRATTO IRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );
    } else if (action === "ALL") {
      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE'){
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }else{
        const pathReferenceRET = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx"
        );
  
        const blobRET = await getBlob(pathReferenceRET);
        saveAs(blobRET, this.selectedPraticaAtFirestore.codiceRegione+"_F_RET_01_0.docx");
      }

      if(!this.selectedPraticaAtFirestore.colocata || this.selectedPraticaAtFirestore.colocataRuolo ==='PRINCIPALE'){
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.codiceInfratel +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }else{
        const pathReferencePSI = ref(
          storage,
          "pratiche/scuola/" +
            this.selectedPraticaAtFirestore.colocataScuolaPrincipale +
            "/OUT/"+this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx"
        );
  
        const blobPSI = await getBlob(pathReferencePSI);
        saveAs(blobPSI, this.selectedPraticaAtFirestore.codiceRegione+"_F_PSI_02_0.docx");
      }

      const pathReferenceAllegatoIRU = ref(
        storage,
        "pratiche/scuola/" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          "/OUT/ALLEGATO CONTRATTO IRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );

      const blobAllegatoIRU = await getBlob(pathReferenceAllegatoIRU);
      saveAs(
        blobAllegatoIRU,
        "ALLEGATO CONTRATTO IRU_" +
          this.selectedPraticaAtFirestore.codiceInfratel +
          ".docx"
      );
    } */
  }
  openCaricaPacchettoFirmato(pratica:PraticaSanita_Firestore){
    this.logger.info("--carica pacchetto");
    /**
      1) APRIRE LA MODALE
      2) SE CARICAMENTO OK > MODIFICO DATO SU FIRESTORE E/O CHIAMO ENDPOINT
      3) CAMBIO LO STATO ?
    */
  }

  getColocataFontClass(pratica: PraticaSanita_Firestore): string {
    if(!pratica.colocata){
      return '';
    }else{
      if(pratica.colocataRuolo==='PRINCIPALE'){
        return 'colocata-principale-text';
      }
    }
    return 'colocata-secondaria-text';
  }
  
  getColocataClass(pratica: PraticaSanita_Firestore):string{
    if(!pratica.colocata){
      return '';
    }else{
      if(pratica.colocataRuolo==='PRINCIPALE'){
        return 'text-blu-900 pi-check';
      }
    }
    return 'text-green-700 pi-check';
    /*
    'text-green-700 pi-check': (row.colocata && row.colocataRuolo == 'PRINCIPALE'),
              'text-red-700 pi-check': (row.colocata && row.colocataRuolo == 'SECONDARIA'),
              '': !row.colocata
    */
     

  }
  openMandaInFirma(pratica: PraticaSanita_Firestore) {}

  async scaricaFileRespingimentoPratica(pratica: PraticaSanita_Firestore) {
    /* this.logger.info("---> SCARICO File RESPINGIMENTO DELLA PRATICA "+pratica.codiceInfratel);
    const storage = getStorage();
    const pathReferenceFileRespingimento                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteRespingimentoFile);
    const blob = await getBlob(pathReferenceFileRespingimento);
    saveAs(blob, pratica.noteRespingimentoFile); */
  }

  async scaricaVerbale(pratica: PraticaSanita_Firestore, isBulk:boolean) {
    /* this.logger.info("-scaricaVerbale "+ pratica.ha2Tratte);
    if(!isBulk){
      this.confirmationService.confirm({
        message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
        header: "ATTENZIONE!",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => {
          const storage = getStorage();
          const pathReferenceFileVerbale    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
         
          try {
            const blob = await getBlob(pathReferenceFileVerbale);
            saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");

            if(pratica.tipoSede==='D' && pratica.ha2Tratte){
              this.logger.info("-DOVREI PROVARE A SCARICARE ANCHE IL VERBALE NUOVE PROPRIETA  ");
              const pathReferenceFileVerbaleTratta2    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
              const blobTratta2 = await getBlob(pathReferenceFileVerbaleTratta2);
              saveAs(blobTratta2, "Contratto_Cessione_Proprietà_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
            }
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Verbale cessione IRU NON esistente",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
        },
        reject: () => {
        },
      });
    }else{
      const storage = getStorage();
          const pathReferenceFileVerbale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
          try {
            const blob = await getBlob(pathReferenceFileVerbale);
            saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
            if(pratica.tipoSede==='D' && pratica.ha2Tratte){
              const pathReferenceFileVerbaleTratta2    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_Proprietà_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
              const blobTratta2 = await getBlob(pathReferenceFileVerbaleTratta2);
              saveAs(blobTratta2, "Contratto_Cessione_Proprietà_Nuove_Infrastrutture_"+pratica.codiceInfratel+".pdf");
            }
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Verbale cessione IRU NON esistente",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
    }
     */
    

  }

  async scaricaShape(pratica: PraticaSanita_Firestore) {
    /* this.logger.info("---> SCARICO SHAPE DA FIRMARE DELLA PRATICA "+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length));
    const storage = getStorage();
    const pathReferencePacchetto                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
    try {
      const blob = await getBlob(pathReferencePacchetto);
      saveAs(blob, "AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip");
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        this.logger.info('Il file non esiste:', error.message);
        this.messageService.add({
          severity: "info",
         summary: "Errore" , life:10000,
          detail: "File nt9 NON esistente",
        });
      } else {
        // Gestisci altri tipi di errori
        this.logger.info('Errore sconosciuto:', error);
      }
    }
    */
    
    
  }
  async scaricaPacchetto(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> SCARICO PACCHETTO DA FIRMARE DELLA PRATICA "+pratica.codiceInfratel);
    /* this.confirmationService.confirm({
      message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
      header: "ATTENZIONE!",
      icon: "pi pi-exclamation-circle",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        const storage = getStorage();
        const pathReferencePacchetto                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/Contratto_Cessione_IRU_"+pratica.codiceInfratel+".pdf");
        try {
          const blob = await getBlob(pathReferencePacchetto);
          saveAs(blob, "Contratto_Cessione_IRU_"+pratica.codiceInvio+".pdf");
        }catch (error) {
          if (error.code === 'storage/object-not-found') {
            this.logger.info('Il file non esiste:', error.message);
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Verbale cessione IRU NON esistente",
            });
          } else {
            // Gestisci altri tipi di errori
            this.logger.info('Errore sconosciuto:', error);
          }
        }
        
      },
      reject: () => {
      },
    }); */

    

  }

  async scaricaFileReworkPratica(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> SCARICO File REWORK DELLA PRATICA "+pratica.codiceInfratel);
   /*  const storage = getStorage();
    const pathReferenceFileRework                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/"+pratica.noteReworkFile);
    const blob = await getBlob(pathReferenceFileRework);
    saveAs(blob, pratica.noteReworkFile); */
  }

  async scaricaPacchettoFirmato(pratica: PraticaSanita_Firestore){

   /*  if(!pratica.colocata){
      this.logger.info("---> scaricaPacchettoPratica FIRMATA non colocata");

      this.openScaricaPacchettoFirmatoPratica(pratica);
    }else{
      this.logger.info("---> scaricaPacchettoPratica FIRMATAcolocata");

      this.openScaricaPacchettoFirmatoPratica(pratica);
    } */
  }

  async scaricaPacchettoPratica(pratica: PraticaSanita_Firestore, isBulk:boolean){

    /* if(!pratica.colocata){
      this.openScaricaPacchettoPratica(pratica, isBulk);
    }else{
      this.openScaricaPacchettoPraticaColocata(pratica, isBulk);
    } */
  }

  async scaricaPreviewDocsPraticaColocata(pratica: PraticaSanita_Firestore){
    this.logger.info("---> SCARICO LA PREVIEW DEI DOCS DELLA PRATICA COLOCATA "+pratica.codiceInfratel + " con codiceInvio "+ pratica.codiceInvio);
    /* this.isLoadingResults = true;
    let praticaColocata:PraticaSanita_Firestore = null;
    const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
    onSnapshot(query, async (qs) => {
          this.logger.info(  "--> RISULTATO GET COLOCATA " + qs.docs.length );
          if( qs.size==1){
            praticaColocata = qs.docs[0].data() as PraticaSanita_Firestore;
            this.logger.info("---> HA SECONDARIA "+praticaColocata.codiceInfratel);
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_RET_01_0.docx");
            const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_PSI_02_0.docx");
            const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
            const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".docx" );
            
            let filesToDownload = [
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceAllegatoIRUPrincipale, 
                pathReferenceAllegatoIRUSecondaria, 
              ];
        
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
              const blob = await getBlob(pathRef);
               
              if (blob) {
                zip.file(pathRef.name, blob);
                
                downloadPromises.push(Promise.resolve());
                
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }  
            }
            
            Promise.all(downloadPromises)
            .then(() => {
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il File zip
                  this.isLoadingResults = false;
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
            this.isLoadingResults = false;
          }else{
            this.isLoadingResults = false;
          }
        
    }); */
  }

  async openScaricaPacchettoPraticaColocata(pratica: PraticaSanita_Firestore, isBulk:boolean) {
    this.logger.info("---> SCARICO TUTTO IL PACCHETTO DELLA PRATICA COLOCATA "+pratica.codiceInfratel + " con codiceInvio "+ pratica.codiceInvio);

    /* if(!isBulk){

    this.confirmationService.confirm({
      message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
      header: "ATTENZIONE!",
      icon: "pi pi-exclamation-circle",
      acceptLabel: "PROCEDI",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
          this.isLoadingResults = true;
          let praticaColocata:PraticaSanita_Firestore = null;
          const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
          const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
          onSnapshot(query, async (qs) => {
          
          this.logger.info(  "--> RISULTATO GET COLOCATA " + qs.docs.length );
          if( qs.size==1){
            praticaColocata = qs.docs[0].data() as PraticaSanita_Firestore;
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
            const pathReferenceCMT                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
   
            const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
            const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
            const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
            
            //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );

            
            let filesToDownload = [
                pathReferenceNT9Principale, 
                pathReferenceNT9Secondaria, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceCMT,
                pathReferenceAllegatoIRUPrincipale, 
                pathReferenceAllegatoIRUSecondaria, 
                pathReferenceVerbaleCessioneIRUPrincipale,
                pathReferenceVerbaleCessioneIRUSecondaria
              ];
        
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                
                  if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                    zip.file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file(pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                    zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file(praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
  
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                      zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                    zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }
                  
                 
                  downloadPromises.push(Promise.resolve());
                  
                } else {
                  this.logger.info(`Errore durante il download di ${pathRef.name}`);
                }  
              } catch (error) {
                this.isLoadingResults = false;
                if (error.code === 'storage/object-not-found') {
                  this.logger.info('Il file non esiste:', error.message);
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "Il file "+ error.message+" non esiste",
                  });
                } else {
                  // Gestisci altri tipi di errori
                  this.logger.info('Errore sconosciuto:', error);
                }
              }

            }
            
            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              // Crea il File zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
            this.isLoadingResults = false;
          }else{
            this.isLoadingResults = false;
          }
        
       });
        
      },
      reject: () => {
      },
    });
  }else{
    this.isLoadingResults = true;
          let praticaColocata:PraticaSanita_Firestore = null;
          const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
          const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel);
          onSnapshot(query, async (qs) => {
          
          this.logger.info(  "--> RISULTATO GET COLOCATA " + qs.docs.length );
          if( qs.size==1){
            praticaColocata = qs.docs[0].data() as PraticaSanita_Firestore;
            const storage = getStorage();
            const zip = new JSZip();
            const pathReferenceNT9Principale                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceNT9Secondaria                    = ref( storage, "pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip");
            const pathReferenceRET                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
            const pathReferencePSI                              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
            const pathReferenceCMT                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
   
            const pathReferenceAllegatoIRUPrincipale            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
            const pathReferenceAllegatoIRUSecondaria            = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf" );
            const pathReferenceVerbaleCessioneIRUPrincipale     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
            const pathReferenceVerbaleCessioneIRUSecondaria     = ref( storage, "pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m" );
            
            //const pathReferenceVerbaleCessioneIRU     = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".p7m" );

            
            let filesToDownload = [
                pathReferenceNT9Principale, 
                pathReferenceNT9Secondaria, 
                pathReferenceRET,
                pathReferencePSI,
                pathReferenceCMT,
                pathReferenceAllegatoIRUPrincipale, 
                pathReferenceAllegatoIRUSecondaria, 
                pathReferenceVerbaleCessioneIRUPrincipale,
                pathReferenceVerbaleCessioneIRUSecondaria
              ];
        
            const downloadPromises = [];
            for (const pathRef of filesToDownload) {
              this.logger.info(pathRef.name + " @ " + pathRef.fullPath);
              try {
                const blob = await getBlob(pathRef);
                if (blob) {
                
                  if(pathRef.name===pratica.codiceRegione+'_F_RET_01_0.pdf' || pathRef.name===pratica.codiceRegione+'_F_PSI_02_0.pdf' || pathRef.name===pratica.codiceRegione+"_F_CMT_03_0.xlsx"){
                    zip.file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip"){
                    zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file(pratica.codiceInfratel.substring(pratica.codiceInfratel.length-15,pratica.codiceInfratel.length)+".zip", blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" + praticaColocata.codiceInfratel +  "/IN/nt9.zip"){
                    zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_SHP_04_0").file(praticaColocata.codiceInfratel.substring(praticaColocata.codiceInfratel.length-15,praticaColocata.codiceInfratel.length)+".zip", blob);
  
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + praticaColocata.codiceInfratel + ".pdf"){
                      zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                  }else if( pathRef.fullPath ==="pratiche/scuola/" +  praticaColocata.codiceInfratel +"/OUT/Contratto_Cessione_IRU_" + praticaColocata.codiceInfratel + ".pdf.p7m"){
                    zip.folder(praticaColocata.codiceInfratel+"/"+praticaColocata.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }
                  
                 
                  downloadPromises.push(Promise.resolve());
                  
                } else {
                  this.logger.info(`Errore durante il download di ${pathRef.name}`);
                }  
              } catch (error) {
                this.isLoadingResults = false;
                if (error.code === 'storage/object-not-found') {
                  this.logger.info('Il file non esiste:', error.message);
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "Il file "+ error.message+" non esiste",
                  });
                } else {
                  // Gestisci altri tipi di errori
                  this.logger.info('Errore sconosciuto:', error);
                }
              }

            }
            
            Promise.all(downloadPromises)
            .then(() => {
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
              zip.folder(praticaColocata.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              // Crea il File zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
            this.isLoadingResults = false;
          }else{
            this.isLoadingResults = false;
          }
        
       });
  } */
    

  }

  async openScaricaPacchettoFirmatoPratica(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> SCARICO IL PACCHETTO FIRMATO DELLA PRATICA "+pratica.codiceInfratel);
    /* this.confirmationService.confirm({
      message: `Confermi di voler procedere con lo scaricamento del pacchetto della pratica ${pratica.codiceInfratel}? L'operazione indicherà che la pratica è stata inoltrata al cliente!`,
      header: "Scarica pacchetto firmato",
      icon: "pi pi-file-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: async () => {
        this.isLoadingResults = true;
        const storage = getStorage();
        const pathReferenceSignedZip = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/SIGNED/"+pratica.codiceInvio+".zip.p7m");
          
        let filesToDownload = [
          pathReferenceSignedZip,  
          ];
          
        for (const pathRef of filesToDownload) {
          try {
            const blob = await getBlob(pathRef);
            if (blob) {
              this.logger.info(pathRef.name); 
              saveAs(blob, pathRef.name); 
              this.praticheSanitaService.pubblicazionePratica(pratica.id).subscribe(res=>{
              });
              this.isLoadingResults = false;
            } else {
              this.logger.info(`Errore durante il download di ${pathRef.name}`);
            }
          }catch(error){
            this.isLoadingResults = false;
            if (error.code === 'storage/object-not-found') {
              this.logger.info('Il file non esiste:', error.message);
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Il file "+ error.message+" non esiste",
              });
            } else {
              // Gestisci altri tipi di errori
              this.logger.info('Errore sconosciuto:', error);
            }
          }
        }
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); */
         

  }

  async openScaricaPacchettoPratica(pratica: PraticaSanita_Firestore, isBulk:boolean) {
    this.logger.info("---> SCARICO TUTTO IL PACCHETTO DELLA PRATICA "+pratica.codiceInfratel);
    /* if(!isBulk){
      this.confirmationService.confirm({
        message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
        header: "ATTENZIONE!",
        icon: "pi pi-exclamation-circle",
        acceptLabel: "PROCEDI",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: async () => {
          this.isLoadingResults = true;
          const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceRET                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
          const pathReferencePSI                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
          const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
          const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
          const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
          const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
          let filesToDownload = [
              pathReferenceNT9, 
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceCMT,
              pathReferenceAllegatoIRU, 
              pathReferenceVerbaleCessioneIRU,
            // pathReferenceVerbaleCessioneProprieta
            ];
            


           if(pratica.tipoSede === 'D' && pratica.ha2Tratte){
              filesToDownload.push(pathReferenceVerbaleCessioneProprieta);
            } 

            this.logger.info(filesToDownload);

          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                this.logger.info(pathRef.name); 
                if(pathRef.name==='nt9.zip'){ 
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                  || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else {
                  zip.file(pathRef.name, blob);
                }
                
                downloadPromises.push(Promise.resolve());
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
  
          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            // Crea il File zip e avvia il download:
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error));
  
          this.isLoadingResults = false;
          
        },
        reject: () => {
        },
      });
    }else{
      this.isLoadingResults = true;
          const storage = getStorage();
          const zip = new JSZip();
          const pathReferenceNT9                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/IN/nt9.zip");
          const pathReferenceRET                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_RET_01_0.pdf");
          const pathReferencePSI                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_PSI_02_0.pdf");
          const pathReferenceCMT                      = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
          const pathReferenceAllegatoIRU              = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
          const pathReferenceVerbaleCessioneIRU       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );
          const pathReferenceVerbaleCessioneProprieta = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +   "/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
          
          let filesToDownload = [
              pathReferenceNT9, 
              pathReferenceRET,
              pathReferencePSI,
              pathReferenceCMT,
              pathReferenceAllegatoIRU, 
              pathReferenceVerbaleCessioneIRU,
            // pathReferenceVerbaleCessioneProprieta
            ];
              if(pratica.tipoSede === 'D'){
              filesToDownload.push(pathReferenceVerbaleCessioneProprieta);
            }  
          const downloadPromises = [];
          for (const pathRef of filesToDownload) {
            try {
              const blob = await getBlob(pathRef);
              if (blob) {
                this.logger.info(pathRef.name); 
                if(pathRef.name==='nt9.zip'){ 
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                  || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                  || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                  zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                }else {
                  zip.file(pathRef.name, blob);
                }
                
                downloadPromises.push(Promise.resolve());
              } else {
                this.logger.info(`Errore durante il download di ${pathRef.name}`);
              }
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
            }
          }
  
          Promise.all(downloadPromises)
          .then(() => {
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
            zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
            // Crea il File zip e avvia il download:
            zip.generateAsync({ type: 'blob' })
              .then((blob) => {
                saveAs(blob, pratica.codiceInvio+'.zip'); // Scarica il File zip
              })
              .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
          })
          .catch(error => this.logger.info('Errore durante il download dei File:', error));
  
          this.isLoadingResults = false;
    } */
    


    
    
  }

  openCaricaDocumentazionePratica(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> CARICO DOCUMENTAZIONE E SOVRASCRIVO");
  }
  async openScaricaDocumentazionePratica(
    event: any,
    pratica: PraticaSanita_Firestore
  ): Promise<void> {
    this.logger.info("---> SCARICO DOCUMENTAZIONE PRODOTTA");
    /* this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniScaricaFile();
    this.azioniScaricaDocMenu.toggle(event); */
  }

  async openCaricaFilePratica(  event: any, pratica: PraticaSanita_Firestore ): Promise<void> {
    this.logger.info("---> CARICO File PRATICA");
    /* this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniCaricaFile(pratica);
    this.azioniCaricaFileMenu.toggle(event); */
  }
  async openCaricaAzioniPratica(  event: any, pratica: PraticaSanita_Firestore ): Promise<void> {
    /* this.selectedPraticaAtFirestore = pratica;
    this.loadAzioni(event, pratica);
    this.azioniMenu.toggle(event); */
  }

  async openCaricaAzioniTieredPratica(  event: any, pratica: PraticaSanita_Firestore ): Promise<void> {
    this.selectedPraticaAtFirestore = pratica;
    this.loadAzioni(event, pratica);
    this.azioniMenuTiered.toggle(event);
  }

  

  async loadRisultatiInvioPratica(  event: any, pratica: PraticaSanita_Firestore ): Promise<void> {
    /* this.selectedPraticaAtFirestore = pratica;
    this.loadAzioniRisultatoInvioPratica(pratica);
    this.azioniRisultatoInvioInfratel.toggle(event);  */
  }


  openModificaInformazioniPratica(pratica: PraticaSanita_Firestore): void {
  }

  openCompletaPraticaPresidioTIM(pratica: PraticaSanita_Firestore): void {
    this.logger.info("---> presidio completa la pratica");
    this.logger.info(pratica);

    /* let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
    this.praticheSanitaService
      .getById(pratica.id.toString())
      .subscribe((praticaDaDB) => {
        praticaDaAggiornare = praticaDaDB;

        this.ref = this.dialogService.open(DialogCompletaPratica, {
          data: {
            pratica: praticaDaAggiornare,
            praticaFirestore: pratica,
          },
          header: "Completamento Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          if (infoUpdated && infoUpdated.success) {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Praticha completata con successo",
            });
          }
        });

      }); */

  }
  openModificaPraticaDialog(praticaFromFirestore: PraticaSanita_Firestore): void {
    /* let praticaDaAggiornare: PraticaScuola;
    let scuolaDaAggiornare: ScuolaDTO;
   
    this.praticheSanitaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.scuoleService
          .getById(praticaDaAggiornare.scuola.id.toString())
          .subscribe((scuola) => {
            scuolaDaAggiornare = scuola;
            this.ref = this.dialogService.open(DialogModificaPratica, {
              data: {
                pratica: praticaDaAggiornare,
                praticaFromFirestore: praticaFromFirestore,
                scuola: scuolaDaAggiornare,
              },
              header: "Dettagli Pratica "+pratica.codiceInfratel,
              width: this.isMobile ? "95%" : "85%",
              height: "85%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((infoUpdated) => {
              if (infoUpdated && infoUpdated.success) {
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: "Praticha modificata con successo",
                });
              }
            });
          });
      }); */
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }


  openInserisciDettagli(praticaFromFirestore: PraticaSanita_Firestore): void {
     let praticaDaAggiornare: PraticaSanita;
    
    //TODO: RECUPERO DA BE LA PRATICA CON TUTTE LE INFO!
    this.praticheSanitaService
      .getById(praticaFromFirestore.id.toString())
      .subscribe((pratica) => {
        praticaDaAggiornare = pratica;
        this.logger.info(pratica);
        this.ref = this.dialogService.open(DialogInserisciInformazioniPraticaSanita, {
          data: {
            pratica: praticaDaAggiornare,
            praticaFromFirestore: praticaFromFirestore,
            //scuola: scuolaDaAggiornare,
          },
          header: "Dettagli Pratica "+pratica.codiceInfratel,
          width: this.isMobile ? "95%" : "85%",
          height: "85%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((infoUpdated) => {
          this.logger.info("FINITO AGGIORNAMENTO DETTAGLI PRATICA");
          this.logger.info(infoUpdated);
          if (infoUpdated && infoUpdated.success) {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica aggiornata con successo",
            });
          }
        });
      }); 
    // =    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    //    praticaDaAggiornare.id = pratica.id;
  }

  openMappaConAccesso(pratica: PraticaSanita_Firestore) {
     this.praticheSanitaService
      .getById(pratica.id.toString())
      .subscribe((praticaCompleta) => {
          this.ref = this.dialogService.open(DialogPosizioneAccesso, {
            data: {
              pratica: pratica,
              praticaCompleta: praticaCompleta
            },
            header: "Posizione della STRUTTURA SANITARIA e della CENTRALE assegnata",
            width: this.isMobile ? "95%" : "70%",
            height: "75%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
    }); 
  }
  openEliminaPratica(pratica: PraticaSanita_Firestore){
     this.confirmationService.confirm({
      message: `Confermi di voler ELIMINARE la pratica ${pratica.codiceInfratel}?`,
      header: "ELIMINAZIONE",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaSanita = new PraticaSanita();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheSanitaService.elimina(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di eliminazione della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica Eliminata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 
  }

  

  scaricaPacchettiInBulk(){
    this.logger.info(  "-> scaricaPacchettiInBulk " );
   /*  let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
   
        const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
        const query = collectionRef
          //.where("bando", "==", "BANDO 2")
          .where("stato", "==", tipoFirma);
          query.get().then(qs => {
            if (qs.docs && qs.docs.length > 0) {
              this.confirmationService.confirm({
                message: `Non rinominare i file che si stanno scaricando durante il processo di firma!`,
                header: "ATTENZIONE!",
                icon: "pi pi-exclamation-circle",
                acceptLabel: "PROCEDI",
                acceptButtonStyleClass: "p-button-success",
                rejectLabel: "ANNULLA",
                rejectButtonStyleClass: "p-button-warning",
                accept: async () => {
                  this.isLoadingResults = true;
                  const promises: Promise<void>[] = [];
    
                  qs.docs.forEach(doc =>{
                    let pratica = doc.data() as PraticaSanita_Firestore;
                    this.logger.info(  "-> SCARICO FILE PER PRATICA " +pratica.codiceInfratel);
    
                    if(this.isDirigenteFirmaPacchetti){
                      if(!pratica.colocata || (pratica.colocata && pratica.colocataRuolo==='PRINCIPALE')){
                        promises.push(this.scaricaPacchettoPratica(pratica, true));
                      } 
                    }else{
                      promises.push(this.scaricaVerbale(pratica, true));
                    }
             
                    });
                    Promise.all(promises)
                    .then(() => {
                      this.logger.info(  "-> HA FINITO! " );
                      this.isLoadingResults = false;
                    })
                    .catch(error => {
                      // Gestisci eventuali errori
                      this.logger.info("Errore durante le chiamate asincrone:", error);
                      this.isLoadingResults = false; // Assicurati di impostare isLoadingResults a false anche in caso di errore
                    });
                    
                },
                reject: () => {
                },
              });
             
              
            }else{
              this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
            }
        }); */
        
     

    
    
  }

  caricaPacchettiFirmatiInBulk(){
    this.logger.info(  "-> caricaPacchettiInBulk " );
   /*  const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
    let tipoFirma = "VERBALE FIRMATO";
    if(this.isDirigenteFirmaVerbali){
      tipoFirma = "IN FIRMA VERBALE";
    }
    const query = collectionRef
      //.where("bando", "==", "BANDO 2")
      .where("stato", "==", tipoFirma);
      query.get().then(qs => {
        if (qs.docs && qs.docs.length > 0) {
          this.isLoadingResults = true;
          if(this.isDirigenteFirmaVerbali){
            //
            this.ref = this.dialogService.open(DialogCaricaVerbaliFirmati, {
              data: {
               
              },
              header: "Carica Verbali Firmati",
              width: "90%",
              height: "50%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              this.isLoadingResults = false;

              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " verbal* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
                  styleClass: 'custom-toast-pacchetti',
                });
              }
            });
          }else if(this.isDirigenteFirmaPacchetti) {
            this.ref = this.dialogService.open(DialogCaricaPacchettiFirmati, {
              data: {
               
              },
              header: "Carica "+this.isDirigenteFirmaVerbali?"Verbali":"Pacchetti"+" Firmati",
              width: "90%",
              height: "50%",
              baseZIndex: 10000,
              closable: true,
              draggable: false,
            });
            this.ref.onClose.subscribe((caricaPacchettiResult) => {
              this.isLoadingResults = false;
              if (caricaPacchettiResult && caricaPacchettiResult.success) {
                const messaggioSuccesso = caricaPacchettiResult.numeroPacchettiCaricati + " pacchett* caricat* con successo";
                let messaggio = caricaPacchettiResult.numeroPacchettiNonCaricati== 0? messaggioSuccesso: messaggioSuccesso+ ' e '+ caricaPacchettiResult.numeroPacchettiNonCaricati+ " non caricat*!";
                this.messageService.add({
                  severity: "success",
                  summary: "" , life:10000,
                  detail: messaggio,
            
                  styleClass: 'custom-toast-pacchetti',
                });
              }else{
                
              }
            });
          }
         
          
        }else{
          this.messageService.add({severity:'info', summary:'', detail: 'Nessuna pratica da firmare'});
        }
    }); */
    
    /**
     * USO ALTRO COMPONENTE
     */
  }
  assegnaPraticheInBulk() {
    this.logger.info(  "-> assegnaPraticheInBulk " + this.selectedPratiche.length );
    /* let praticheDaAssegnare:PraticaScuola[]=[];
    this.selectedPratiche.forEach(pratica =>{
      let praticaDaAssegnare: PraticaScuola = new PraticaScuola();
      praticaDaAssegnare.codiceInfratel = pratica.codiceInfratel;
      praticaDaAssegnare.id = pratica.id;
      praticheDaAssegnare.push(praticaDaAssegnare);
    });
    this.ref = this.dialogService.open(DialogAssegnaPratica, {
      data: {
        pratiche: praticheDaAssegnare,
      },
      header: "Assegna pratiche in bulk",
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratiche assegnata con successo",
        });
      }
    });
    this.selectedPratiche = []; */
  }

  async openScaricaShapePratica(pratica: PraticaSanita_Firestore) {
    this.isLoadingResults = true;
    this.praticheSanitaService.generaShape(pratica).subscribe(async res =>{
      const storage = getStorage();
      this.logger.info("DOVREI SCARICARE LO SHAPE DELLA PRATICA "+ pratica.codiceInfratel + " con codice accesso "+pratica.idAccessoInfratel.toUpperCase());
      const pathReferenceZipShape    = ref( storage, "pratiche/sanita/" + pratica.codiceInfratel +  "/OUT/"+pratica.idAccessoInfratel.toUpperCase()+".zip");
      const blob = await getBlob(pathReferenceZipShape);
      saveAs(blob, pratica.codiceInfratel.toUpperCase()+".zip"); 
      this.isLoadingResults = false;
      
    });
   
  }

  openDisegnaPratica(pratica: PraticaSanita_Firestore) {
    let urlViewer = "https://viewer2.maphosting.it/viewer/index.php/GeoHub-TimSanita/"+pratica.ntxSchemaName.toLowerCase()+"#/map/ZOOMLAYER=NODI&ZOOMFILTER=tipo_nodo$$$EQ$$$Accesso";
    urlViewer+="&tokenBE="+localStorage.getItem("token");
    urlViewer+="&idPratica="+pratica.id;

    //const urlViewer = "https://nt9convergo-viewer.maphosting.it/viewer/index.php/TimSanita/"+pratica.codiceInfratel+"#/map/ZOOMLAYER=NODI&ZOOMFILTER=gid$$$EQ$$$1";
    //const urlViewer = "https://nt9convergo-viewer.maphosting.it/viewer/index.php/TimSanita/I_TOS_123#/map/ZOOMLAYER=NODI&ZOOMFILTER=gid$$$EQ$$$1";
    window.open(urlViewer, '_blank');
   
  }


  openAssegnaAdImpresa(pratica: PraticaSanita_Firestore) {
     let praticaDaAggiornare: PraticaSanita = new PraticaSanita();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    this.ref = this.dialogService.open(DialogAssegnaPraticaSanita, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Assegna pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "40%",
      height: "40%",
      baseZIndex: 1,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnaResult) => {
      if (assegnaResult && assegnaResult.success) {
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Pratica assegnata con successo",
        });
      }
    }); 
  }

  openCaricaFotoRouter(pratica: PraticaSanita_Firestore): void {
    let praticaDaAggiornare: PraticaSanita = new PraticaSanita();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaFotoRouterSanita, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Fotografia del ROUTER della pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_sanita);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { fotoRouterUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo fotoRouterUploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO foto_router_uploaded SU FIRESTORE"
            );
          });
        this.praticheSanitaService
          .confermaCaricamentoFotoRouter(
            praticaDaAggiornare,
            uploadResult.fileExtension
          )
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO FOTO ROUTER");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Fotografia del router caricata con successo",
        });
      }
    }); 
  }

  openCaricaPlanimetria(pratica: PraticaSanita_Firestore): void {
    /* let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaPlanimetria, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Planimetria per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_sanita);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { planimetriaUploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log(
              "Campo foto_router_uploaded aggiornato con successo"
            );
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO foto_router_uploaded SU FIRESTORE"
            );
          });
        this.praticheSanitaService
          .confermaCaricamentoPlanimetria(praticaDaAggiornare, uploadResult.fileExtension)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PLANIMETRIA");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Planimetria caricata con successo",
        });
      }
    }); */
  }

  openCaricaPacchettoNT9(pratica: PraticaSanita_Firestore): void {
    /* let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;

    this.ref = this.dialogService.open(DialogCaricaNT9, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto NT9 per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        const collectionRef = this.firestore.collection(this.collection_pratiche_sanita);
        const documentRef = collectionRef.doc(pratica.codiceInfratel.valueOf());
        const updatedData = { nt9Uploaded: true };
        documentRef
          .update(updatedData)
          .then(() => {
            this.logger.log("Campo nt9_uploaded aggiornato con successo");
          })
          .catch((error) => {
            this.logger.error(
              "ERRORE AGGIORNATO CAMPO nt9_uploaded SU FIRESTORE"
            );
          });
        this.praticheSanitaService
          .confermaCaricamentoNT9(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO REPORT NT9");
          });
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Report NT9 caricato con successo",
        });
      }
    }); */
  }

  caricaShapeFirmato(pratica: PraticaSanita_Firestore): void {
    /* let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaNT9Firmato, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Shape Firmato",
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        
        
      
    }
    }); */
  }
  caricaPacchettoFirmato(pratica: PraticaSanita_Firestore, isRicarica:boolean): void {
    /* let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaPacchettoFirmato, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Pacchetto Firmato per pratica "+ pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        if(!isRicarica){
          this.praticheSanitaService
          .confermaCaricamentoPacchetto(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO PACCHETTO");
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pacchetto firmato caricato con successo",
            }); 
          });
        } 

      }
    
    }); */
  }

  caricaVerbaleFirmato(pratica: PraticaSanita_Firestore, isRicarica:boolean): void {
    /* let praticaDaAggiornare: PraticaScuola = new PraticaScuola();
    praticaDaAggiornare.codiceInfratel = pratica.codiceInfratel;
    praticaDaAggiornare.id = pratica.id;
    praticaDaAggiornare.codiceInvio = pratica.codiceInvio;
    this.ref = this.dialogService.open(DialogCaricaVerbaleFirmato, {
      data: {
        pratica: praticaDaAggiornare,
      },
      header: "Carica Verbale Firmato della Pratica "+pratica.codiceInfratel,
      width: this.isMobile ? "95%" : "70%",
      height: "55%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        if(!isRicarica){
          this.praticheSanitaService
          .confermaCaricamentoVerbale(praticaDaAggiornare)
          .subscribe((res) => {
            this.logger.info("AGGIORNATA PRATICA DOPO CARICAMENTO VERBALE");
          });
        }
       
        this.messageService.add({
          severity: "success",
          summary: "" , life:10000,
          detail: "Verbale caricato con successo",
        }); 
      }
    }); */
  }

  openVisualizzaDettagli(pratica: PraticaSanita_Firestore) {}
  openModificaDettagli(pratica: PraticaSanita_Firestore) {} 
  openAnnullaPratica(pratica: PraticaSanita_Firestore) {
    /* this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la pratica ${pratica.codiceInfratel}?`,
      header: "ANNULLAMENTO",
      icon: "pi pi-times",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaAnnullare: PraticaScuola = new PraticaScuola();
        praticaDaAnnullare.id = pratica.id;
        this.logger.info(praticaDaAnnullare);
        this.praticheSanitaService.annulla(praticaDaAnnullare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); */
  }

  openPreviewDocs(pratica: PraticaSanita_Firestore){
    /* this.confirmationService.confirm({
      message: `Confermi di voler procedere con la GENERAZIONE ed il DOWNLOAD dei documenti della pratica ${pratica.codiceInfratel}?`,
      header: "Generazione documenti",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheSanitaService.generaDocs(praticaDaConsegnare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di generazione dei docs della pratica",
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Generazione documentazione avvenuta, attendere il download!",
            });
            //scarica tutto!
            this.isLoadingResults = false;
            if(pratica.colocata){
              this.scaricaPreviewDocsPraticaColocata(pratica);
            }else {
              this.scaricaPreviewDocsPratica(pratica);
            }
            
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); */
  }

  async scaricaPreviewDocsPratica(pratica: PraticaSanita_Firestore) {
    this.logger.info("---> SCARICO LA PREVIEW DEI DOCS DELLA PRATICA "+pratica.codiceInfratel);
    /* const storage = getStorage();
    const zip = new JSZip();
    const pathReferenceRET                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_RET_01_0.docx");
    const pathReferencePSI                    = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione +"_F_PSI_02_0.docx");
    const pathReferenceAllegatoIRU            = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".docx" );
    let filesToDownload = [
        pathReferenceRET,
        pathReferencePSI,
        pathReferenceAllegatoIRU, 
      ];

    const downloadPromises = [];
    for (const pathRef of filesToDownload) {
      const blob = await getBlob(pathRef);
      if (blob) {
        this.logger.info(pathRef.name);
        zip.file(pathRef.name, blob);
        
        downloadPromises.push(Promise.resolve());
      } else {
        this.logger.info(`Errore durante il download di ${pathRef.name}`);
      }
    }
    Promise.all(downloadPromises)
    .then(() => {
      // Crea il File zip e avvia il download:
      zip.generateAsync({ type: 'blob' })
        .then((blob) => {
          saveAs(blob, pratica.codiceInfratel+'.zip'); // Scarica il File zip
          this.isLoadingResults = false;
        })
        .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
    })
    .catch(error => this.logger.info('Errore durante il download dei File:', error)); */
    
  }

  openConsegnaPratica(pratica: PraticaSanita_Firestore): void {
    /*  let messaggioColocata = "";
    if(pratica.colocata){
      const collectionRef = this.firestore.collection(this.collection_pratiche_sanita).ref;
      const query = collectionRef .where("colocataScuolaPrincipale", "==", pratica.codiceInfratel.valueOf());
      const risultati: PraticaSanita_Firestore[] = [];
      query.get().then(qs=>{
        let praticaColocata = qs.docs[0].data() as PraticaSanita_Firestore;
       
          messaggioColocata = "Confermi di voler procedere con la CONSEGNA  della pratica <b>" + pratica.codiceInfratel + "</b> e della sua colocata <b>" + praticaColocata.codiceInfratel +"</b>?"
          this.confirmationService.confirm({
            message: messaggioColocata,
            header: "Consegna",
            icon: "pi pi-File-import",
            acceptLabel: "CONFERMA",
            acceptButtonStyleClass: "p-button-success",
            rejectLabel: "ANNULLA",
            rejectButtonStyleClass: "p-button-warning",
            accept: () => {
              this.isLoadingResults = true;
              let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
              praticaDaConsegnare.id = pratica.id;
              this.praticheSanitaService.consegna(praticaDaConsegnare).subscribe({
                next: (v) => {},
                error: (e) => {
                  this.isLoadingResults = false;
                  this.messageService.add({
                    severity: "info",
                   summary: "Errore" , life:10000,
                    detail: "Errore in fase di consegna della pratica",
                  });
                },
                complete: () => {
                  this.isLoadingResults = false;
                  this.messageService.add({
                    severity: "success",
                    summary: "" , life:10000,
                    detail: "Pratica consegnata",
                  });
                },
              });
            },
            reject: () => {
              //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
            },
          });
        }
      )
      
    }else { */
      this.confirmationService.confirm({
        message: `Confermi di voler procedere con la CONSEGNA  della pratica <b>${pratica.codiceInfratel}</b>?`,
        header: "Consegna",
        icon: "pi pi-File-import",
        acceptLabel: "CONFERMA",
        acceptButtonStyleClass: "p-button-success",
        rejectLabel: "ANNULLA",
        rejectButtonStyleClass: "p-button-warning",
        accept: () => {
          this.isLoadingResults = true;
          let praticaDaConsegnare: PraticaSanita = new PraticaSanita();
          praticaDaConsegnare.id = pratica.id;
          this.praticheSanitaService.consegna(praticaDaConsegnare).subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di consegna della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica consegnata",
              });
            },
          });
        },
        reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        },
      });
   // } 
    
  }

  openAnnullaConsegnaPratica(pratica: PraticaSanita_Firestore): void {
    this.logger.info("---> ANNULLA CONSEGNA PRATICA");
     this.confirmationService.confirm({
      message: `Confermi di voler ANNULLARE la CONSEGNA  della pratica ${pratica.codiceInfratel}?`,
      header: "Consegna",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaConsegnare: PraticaScuola = new PraticaScuola();
        praticaDaConsegnare.id = pratica.id;
        this.logger.info(praticaDaConsegnare);
        this.praticheSanitaService.annullaConsegna(praticaDaConsegnare).subscribe({
          next: (v) => {},
          error: (e) => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "info",
             summary: "Errore" , life:10000,
              detail: "Errore in fase di annullamento della consegna della pratica",
            });
          },
          complete: () => {
            this.isLoadingResults = false;
            this.messageService.add({
              severity: "success",
              summary: "" , life:10000,
              detail: "Consegna della pratica annullata",
            });
          },
        });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 
  }

  async scaricaPacchettoPianoTecnico(pratica: PraticaSanita_Firestore): Promise<void> {
   
    //SCARICA RET
    //SCARICA TUTTI I PACCHETTI DELLE PRATICHE CONSEGNATE
    this.isLoadingResults = true;
          const storage = getStorage();
          const zip = new JSZip();
          const nomeRET="PC_"+ pratica.regione.slice(0,3).toUpperCase() +  "_V_005_001_RET_01.docx";
          const pathReferenceRET                       = ref( storage, "pratiche/sanita/RET/" + nomeRET);
        //  const pathReferenceCMT                       = ref( storage, "pratiche/scuola/" + pratica.codiceInfratel +  "/OUT/"+pratica.codiceRegione+"_F_CMT_03_0.xlsx");
       //   const pathReferenceAllegatoIRU               = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/ALLEGATO CONTRATTO IRU_" + pratica.codiceInfratel + ".pdf" );
       //   const pathReferenceVerbaleCessioneIRU        = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_IRU_" + pratica.codiceInfratel + ".pdf.p7m" );  
       //   const pathReferenceVerbaleCessioneProprieta  = ref( storage, "pratiche/scuola/" +  pratica.codiceInfratel +"/OUT/SIGNED/Contratto_Cessione_Proprieta_Nuove_Infrastrutture_" + pratica.codiceInfratel + ".pdf.p7m" );
          let filesToDownload = [
              pathReferenceRET,
             // pathReferenceCMT,
             // pathReferenceAllegatoIRU, 
             // pathReferenceVerbaleCessioneIRU,
             ];
            this.pianoTecnicoSanitaService.getPraticheConsegnate(pratica.pianoTecnicoID.valueOf()).subscribe(async praticheConsegnate =>{
              this.logger.info("IL PIANO HA  "+ praticheConsegnate.length + " PRATICHE CONSEGNATE!"); 

              praticheConsegnate.forEach(async praticaConsegnata => {

                const pathReferenceShapePraticaConsegnata    = ref( storage, "pratiche/sanita/"+praticaConsegnata.codiceInfratel+"/OUT/"+praticaConsegnata.strutturaSanitaria.codiceAccessoInfratel + ".zip");
                //filesToDownload.push(pathReferenceShapePraticaConsegnata);
                const blob = await getBlob(pathReferenceShapePraticaConsegnata);
                if (blob) {
                  zip.folder(pratica.pianoTecnico+"/"+praticaConsegnata.strutturaSanitaria.codiceAccessoInfratel).file(praticaConsegnata.strutturaSanitaria.codiceAccessoInfratel+".zip", blob);
                }
              });
              const downloadPromises = [];
               for (const pathRef of filesToDownload) {
                this.logger.info("DEVO SCARICARE IL FILE "+ pathRef.name); 

                try {
                  const blob = await getBlob(pathRef);
                  if (blob) {
                    this.logger.info("DEVO SCARICARE IL FILE "+ pathRef.name); 
                   /* if(pathRef.name==='nt9.zip'){ 
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_SHP_04_0").file("AB_"+pratica.codiceInfratel.substring(10,pratica.codiceInfratel.length)+".zip", blob);
                    }else if( pathRef.name ==='ALLEGATO CONTRATTO IRU_'+pratica.codiceInfratel+'.pdf' 
                      || pathRef.name ==='Contratto_Cessione_IRU_'+pratica.codiceInfratel+".pdf.p7m"
                      || pathRef.name ==='Contratto_Cessione_Proprieta_Nuove_Infrastrutture_'+pratica.codiceInfratel+".pdf.p7m"){
                      zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/contratto di cessione").file(pathRef.name, blob);
                    }else  */if(pathRef.name==='PC_MAR_V_005_001_RET_01.docx'){
                      zip.file(pratica.pianoTecnico+"/"+pathRef.name, blob);
                    }
                    
                    downloadPromises.push(Promise.resolve()); 
                  } else {
                    this.logger.info(`Errore durante il download di ${pathRef.name}`);
              } 
            }catch(error){
              this.isLoadingResults = false;
              if (error.code === 'storage/object-not-found') {
                this.logger.info('Il file non esiste:', error.message);
                this.messageService.add({
                  severity: "info",
                summary: "Errore" , life:10000,
                  detail: "Il file "+ error.message+" non esiste",
                });
              } else {
                // Gestisci altri tipi di errori
                this.logger.info('Errore sconosciuto:', error);
              }
             
            }
            Promise.all(downloadPromises)
            .then(() => {
           //   zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/documentazione fotografica");
           //   zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/istanze_autorizzazioni");
           //   zip.folder(pratica.codiceInfratel+"/"+pratica.codiceRegione+"_F_ANN_05_0/misure ottiche (eventuale)");
              // Crea il File zip e avvia il download:
              zip.generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, pratica.pianoTecnico+'.zip'); // Scarica il File zip
                })
                .catch(error => this.logger.info('Errore durante la creazione dello zip:', error));
            })
            .catch(error => this.logger.info('Errore durante il download dei File:', error));
     
            this.isLoadingResults = false;
          }

            });

           
  
         
    
  }
  generaRET(pratica: PraticaSanita_Firestore): void {
   
    this.confirmationService.confirm({
      message: `Confermi di voler generare la RET del piano tecnico ${pratica.pianoTecnico}?`,
      header: "Generazione RET",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaPrendereInCarico: PraticaSanita = new PraticaSanita();
        praticaDaPrendereInCarico.id = pratica.id;
        this.praticheSanitaService
          .generaRET(pratica.pianoTecnicoID.valueOf())
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di generazione RET",
              });
            },
            complete: async () => {
              //this.isLoadingResults = false;
              const storage = getStorage();
              const nomeRET="PC_"+ pratica.regione.slice(0,3).toUpperCase() +  "_V_005_001_RET_01.docx";
              this.logger.info("DOVREI SCARICARE LA RET DEL PIANO TECNICO "+ pratica.pianoTecnico);
              const pathReferenceZipShape    = ref( storage, "pratiche/sanita/RET/"+nomeRET);
              const blob = await getBlob(pathReferenceZipShape);
              saveAs(blob, nomeRET); 
              this.isLoadingResults = false;
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 

  }
  openInCaricoPresidio(pratica: PraticaSanita_Firestore): void {

     this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        let praticaDaPrendereInCarico: PraticaSanita = new PraticaSanita();
        praticaDaPrendereInCarico.id = pratica.id;
        this.logger.info(praticaDaPrendereInCarico);
        this.praticheSanitaService
          .presidioPrendeInCarico(praticaDaPrendereInCarico)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 
  }
  openPrendiInCarico(pratica: PraticaSanita_Firestore): void {
     this.confirmationService.confirm({
      message: `Confermi di voler prendere in carico la pratica ${pratica.codiceInfratel}?`,
      header: "Presa in carico",
      icon: "pi pi-File-import",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.isLoadingResults = true;
        this.logger.info(localStorage.getItem("idUtente"));
        let praticaDaModificare: PraticaSanita = new PraticaSanita();
        let utenteInCarico: Utente = new Utente();
        utenteInCarico.id = Number.parseInt(localStorage.getItem("idUtente"));
        praticaDaModificare.id = pratica.id;
        praticaDaModificare.utenteAssegnato = utenteInCarico;
        this.logger.info(praticaDaModificare);
        this.praticheSanitaService
          .prendiInCarico(praticaDaModificare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della pratica",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Pratica presa in carico",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 
  }

  openRichiediModificaCentrale(pratica: PraticaSanita_Firestore): void {
    
           this.ref = this.dialogService.open(DialogRichiediCambioCentralePraticaSanita, {
            data: {
              pratica: pratica,
             
            },
            header: "Richiesta cambio centrale ",
            width: this.isMobile ? "95%" : "85%",
            height: "50%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((changeRequested) => {
            if (changeRequested && changeRequested.success) {
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Richiesta inviata con successo",
              });
            }
          }); 
  
  }

  openIniziaProgettazione(pratica: PraticaSanita_Firestore): void {
    this.logger.info("--->  INIZIA PROGETTAZIONE PRATICA");
     this.confirmationService.confirm({
      message: `Confermi di voler iniziare la progettazione della pratica ${pratica.codiceInfratel}?`,
      header: "Inizio Progettazione",
      icon: "pi pi-file-edit",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.logger.info(localStorage.getItem("idUtente"));
        this.isLoadingResults = true;
        let praticaDaModificare: PraticaScuola = new PraticaScuola();
        this.logger.info(praticaDaModificare);

        praticaDaModificare.id = pratica.id;
        this.praticheSanitaService
          .iniziaProgettazione(pratica)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.messageService.add({
                severity: "info",
               summary: "Errore" , life:10000,
                detail: "Errore in fase di inizio progettazione della pratica",
              });
              this.isLoadingResults = false;
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Inizio progettazione avvenuto con successo",
              });
            },
          });
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
    }); 
   
  }

}

@Component({
  selector: "app-posizione-sanita",
  templateUrl: "./dialog-posizione-accesso.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogPosizioneAccesso implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaSanita_Firestore;
  public praticaCompleta: PraticaSanita;
  protected positionHasChanged:boolean = false;
  
  protected map!: Leaflet.Map;
  protected markers: Leaflet.Marker[] = [];
  protected options = {
    layers: [
      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }),
    ],
    zoom: 11,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica          = this.config.data.pratica;
    this.praticaCompleta  = this.config.data.praticaCompleta;
  }
  initMarkers() {
    const initialMarkers = [
      {
        position: {
          lat: this.pratica.coordinateAccesso.latitude.valueOf(),
          lng: this.pratica.coordinateAccesso.longitude.valueOf(),
        },
        draggable: false,
      },
    
    ];
    const dataAccesso = initialMarkers[0];
    const markerAccesso = this.generateMarker(dataAccesso, 0, true);
    let popupScuola = "ACCESSO <b> "+ this.pratica.denominazione + "</b><br>" +
                      this.pratica.indirizzo + "<br>"+
                      "<b>" + this.pratica.comune + "</b><br>";
    
 
    markerAccesso.addTo(this.map).bindPopup(popupScuola);
    this.markers.push(markerAccesso);

    if(this.pratica.coordinateCentrale && this.pratica.coordinateCentrale!=null){

      initialMarkers.push(  {
        position: {
          lat: this.pratica.coordinateCentrale.latitude.valueOf(),
          lng: this.pratica.coordinateCentrale.longitude.valueOf(),
        },
        draggable: false,
      },);
      const dataCentrale = initialMarkers[1];
      const markerCentrale = this.generateMarker(dataCentrale, 0, false);
      let popupCentrale = "CENTRALE <b> "+ this.praticaCompleta.centrale.denominazione + "</b><br>" +
      this.praticaCompleta.centrale.indirizzo + "<br>"+
      "<b>" + this.praticaCompleta.centrale.comune.denominazione + "</b><br>";
      markerCentrale.addTo(this.map).bindPopup(popupCentrale);
      this.markers.push(markerCentrale);
    }
    
    this.map.panTo(dataAccesso.position);

  }

  markerOver(event: any, index: number){
    const marker = event.target;
    marker.openPopup();
  }
  markerOut(event, index) {
    const marker = event.target;
    marker.closePopup();
  }
  generateMarker(data: any, index: number, isScuola:boolean) {
    let markerIconScuola = new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-sanita.png',
      //iconSize: [40, 25],
      iconSize: [25, 41],
    });
    let markerIconCentrale= new Leaflet.Icon({
      iconUrl: '/assets/leaflet/images/marker-icon-centrale.png',
    iconSize: [25, 41],
    });
    if(isScuola){
      return Leaflet.marker(data.position, { draggable: data.draggable, icon:markerIconScuola })
        //.on("click", (event) => this.markerClicked(event, index))
        .on("mouseover", (event) => this.markerOver(event, index))
        .on("mouseout", (event) => this.markerOut(event, index))
        .on("dragend", (event) => this.markerDragEnd(event, index));
    }else{
      return Leaflet.marker(data.position, { draggable: data.draggable, icon:markerIconCentrale })
     // .on("click", (event) => this.markerClicked(event, index))
      .on("mouseover", (event) => this.markerOver(event, index))
      .on("mouseout", (event) => this.markerOut(event, index))
      .on("dragend", (event) => this.markerDragEnd(event, index));
    }
    
  }
  markerClicked($event: any, index: number) {}

  markerDragEnd($event: any, index: number) {}
  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  mapClicked($event: any) {}
}


@Component({
  selector: "app-fotoRouter-sanita",
  templateUrl: "./dialog-carica-fotoRouter.component.html",
  styleUrls: ["./pratiche-sanita.component.scss"],
})
export class DialogCaricaFotoRouterSanita implements OnInit {
  protected _onDestroy = new Subject<void>();
  public pratica: PraticaSanita;
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private googleStorageService: GoogleStorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.pratica = this.config.data.pratica;
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      const fileExtension = this.selectedFile.name.substring(
        this.selectedFile.name.lastIndexOf(".") + 1
      );
      const fileName = this.pratica.codiceInfratel + "/IN/" + "router." + fileExtension;
      this.googleStorageService
        .uploadFileSanitaToGoogleStorage(this.selectedFile, fileName)
        .then((uploadRes) => {
          this.dialogRef.close({ success: true, fileExtension: fileExtension });
        })
        .catch((errore) => {
          this.messageService.add({
            severity: "info",
           summary: "Errore" , life:10000,
            detail: "Errore in fase di caricamento File!",
          });

          this.dialogRef.close({ success: false });
        });
    }
  }
}
