<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
      <div class="flex justify-start md:justify-start">
        <span class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(15, 168, 76)">PIANI TECNICI</span>
      </div>
      <div class="flex justify-end md:justify-end">
        <span class="p-input-icon-left mr-4">
          <i class="pi pi-search"></i>
          <input type="text" pInputText placeholder="Cerca" 
            (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
          </span>
        
        <button pButton type="button" icon="pi pi-cloud-upload" class="p-button-rounded p-button-outlined" style="background-color: rgb(24, 55, 44); color: white;"  tooltipPosition="top" pTooltip="Importa Piani Tecnici"
          (click)="importaPianiTecnici()" [disabled]="isLoadingResults">
        </button>
      
          <button pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Piano Tecnico"
            (click)="creaPianoTecnico()" [disabled]="isLoadingResults">
          </button>
        </div>
      </div>

   <!--  <div class="flex justify-center">
      <div >
        <span  class="text-xl font-medium text-2000" style="color: cornflowerblue"> &nbsp; </span>
      </div>
      
    </div> -->

  

  <!-- <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
          (input)="filtraPerNome($event)" />
      </span>
      
    </div>
    <div class="flex justify-start md:justify-end">
        <button *ngIf="filterIsSet" pButton pRipple type="button" icon="pi pi-filter-slash"
          class="py-2 px-3 md:mb-0 mr-0 md:mr-3 p-button-rounded p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
          (click)="reloadDataRemovingFilter()" [disabled]="isLoadingResults"></button>
    </div>
  </div> -->
  <div  fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    </p-confirmDialog>

    <p-table #pianiTecniciSanitaDT styleClass="p-datatable-sm" [value]="pianiTecniciSanitaAtFirestore" dataKey="id"
      [(selection)]="selectedPianoTecnico" [selectionPageOnly]="true" [rowHover]="true" [rows]="15"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[15,30,100]" [loading]="isLoadingResults" 
      responsiveLayout="stack" [paginator]="true" currentPageReportTemplate="{first} - {last} di {totalRecords}"
      [filterDelay]="0"
      [globalFilterFields]="['id','descrizione','cig','pdc','comune','regione','provincia']"
      [sortField]="'descrizione'" [sortOrder]="'1'">

      <ng-template pTemplate="header">
        <tr>
         
          <th class="text-center" style="font-size: 14px;">Azioni</th>     
          <th pSortableColumn="descrizione" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Piano Tecnico
              <p-sortIcon field="descrizione"></p-sortIcon>
            </div>
          </th>     
          <th  style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              CIG
            </div>
          </th>
          <th style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              PDC
            </div>
          </th> 
      
          <th style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Comune           
           </div>
          </th>
          <th style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Provincia           
           </div>
          </th>
          <th pSortableColumn="regione" style="font-size: 13px;">
            <div class="flex justify-content-between align-items-center">
              Regione
             </div>
          </th>
          <th style="font-size: 13px;" class="text-center">
            <div class="text-center">
              Completato
            </div>
          </th>
        
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
        
          <td class="text-center">
            <button *ngIf=" showPulsante(row)" 
              pButton type="button" style="color:rgb(39, 128, 15)"
              icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" 
              (click)="openCaricaAzioniPianoTecnico($event,row)">
            </button> 
          </td>
          <td> <span class="text-lowercase " style="font-size: 12px;" pTooltip="{{row.descrizione}}" >{{row.descrizione}} </span> </td>
          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.cig}}" >{{row.cig}} </span> </td>
          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.pdc}}" >{{row.pdc}} </span> </td>
          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.comune}}" >{{row.comune}} </span> </td>
          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.provincia}}" >{{row.provincia}} </span> </td>
          <td > <span class="text-lowercase" style="font-size: 11px;" pTooltip="{{row.regione}}" >{{row.regione}} </span> </td>
          <td  class="text-center"> 
            <i class="pi"
              [ngClass]="{ 'text-green-500 pi-check-circle': row.completo, 'text-red-500 pi-times-circle': !row.completo }"></i>
          </td>

       <!--    <td > <span class="text-lowercase " style="font-size: 12px;" pTooltip="{{row.denominazione}}"  [ngClass]="getColocataFontClass(row)">{{row.denominazione}}</span> </td>
           <td *ngIf="!isImpresa"> <span class="text-lowercase " style="font-size: 10px;">{{row.azienda}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11px;">{{row.comune}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 11px;">{{row.regione}}</span> </td>
          <td> <span class="text-lowercase" style="font-size: 10px;">{{row.indirizzo}}</span> </td>
         <td *ngIf="row.tipoSedeEffettiva" class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoSede}} ({{row.tipoSedeEffettiva}})</span> </td>
         <td class="text-center text-sm"> <span class="text-uppercase" style="font-weight: bold; font-size: 11px;">{{row.tipoStrutturaSanitaria}}</span> </td>

       --> 
        <!--   <td class="text-center" *ngIf="!isDirigenteFirmaPacchetti && !isDirigenteFirmaVerbali && !isPresidioTIM">
            <i class="pi" [ngClass]="{ 'text-blue-700 pi-check': row.backup, '': !row.backup }"></i>
          </td>
          <td *ngIf="!isImpresa && !isDirigenteFirmaVerbali && !isDirigenteFirmaPacchetti && !isPresidioTIM" class="text-center">
            <i class="pi"
              [ngClass]="{ 'text-green-500 pi-check-circle': row.lavorabile, 'text-red-500 pi-times-circle': !row.lavorabile }"></i>
          </td>
          <td class="text-center">
            <span [ngClass]="getStatoClass(row.stato)">{{row.stato}}</span> </td> -->

        </tr>
      </ng-template>
     
    </p-table>
    <p-menu #menuAzioni [popup]="true" [model]="menuAzioniItems"></p-menu>


  </div>
</div>

<p-toast position="top-center"></p-toast>