 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" 
    (click)="loadCentrali()" [disabled]="isLoadingResults"></button> 
  </div> 
  
  <div class="flex justify-center">
    <span class="text-xl font-medium text-2000" style="color: rgb(15, 168, 76)">CENTRALI</span>
  </div>
  
  <div class="flex justify-end md:justify-end">
    <button  pButton type="button" icon="pi pi-upload"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(117, 189, 145); color: white;"
        tooltipPosition="top" pTooltip="Importa Centrali" (click)="importaCentrali()" [disabled]="isLoadingResults" > </button>
        <button pButton type="button" icon="pi pi-download"
        class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(148, 121, 206); color: white;"
        tooltipPosition="top" pTooltip="Esporta Centrali" (click)="esportaCentrali()" [disabled]="isLoadingResults"> </button>
      <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuova Centrale"
      (click)="openCreaCentraleDialog()" [disabled]="isLoadingResults">
    </button>
    </div>
  </div>
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
          (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
      </span>
    <!--   <span *ngIf="!isLoadingResults"
        class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-none"
        style="border-radius: 20px" (click)="menuRegioni.toggle($event)" pRipple>
        Regione <i class="ml-2 pi pi-angle-down text-700" ></i>
      </span>
      <p-menu #menuRegioni [popup]="true" [model]="regioniCentrale" *ngIf="!isLoadingResults"></p-menu>
       -->
    </div>

  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table #centraliDT styleClass="p-datatable-sm" [value]="centrali" dataKey="id" [(selection)]="selectedCentrale"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['idTim','clli','clliAttestazione','denominazione','indirizzo','comune.denominazione']" 
      [sortField]="'denominazione'" [sortOrder]="'1'">
     
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="idTim">
            <div class="flex justify-content-between align-items-center">
              CODICE ACL
              <p-sortIcon field="idTim"></p-sortIcon>
              <p-columnFilter type="text" field="idTIM" display="menu" class="ml-auto"></p-columnFilter>
             </div>
          </th>
          <th pSortableColumn="clli">
            <div class="flex justify-content-between align-items-center">
              CLLI
              <p-sortIcon field="clli"></p-sortIcon>
<!--               <p-columnFilter type="text" field="idTIM" display="menu" class="ml-auto"></p-columnFilter>
 -->            </div>
          </th>
          <th pSortableColumn="clliAttestazione">
            <div class="flex justify-content-between align-items-center">
              CLLI Attestazione
              <p-sortIcon field="clliAttestazione"></p-sortIcon>
<!--               <p-columnFilter type="text" field="clliAttestazione" display="menu" class="ml-auto"></p-columnFilter>
 -->            </div>
          </th>
          <th pSortableColumn="denominazione">
            <div class="flex justify-content-between align-items-center">
              Denominazione
              <p-sortIcon field="denominazione"></p-sortIcon>
<!--               <p-columnFilter type="text" field="clliAttestazione" display="menu" class="ml-auto"></p-columnFilter>
 -->            </div>
          </th>
          <th pSortableColumn="indirizzo">
            <div class="flex justify-content-between align-items-center">
              Indirizzo
              <p-sortIcon field="indirizzo"></p-sortIcon>
<!--               <p-columnFilter type="text" field="indirizzo" display="menu" class="ml-auto"></p-columnFilter>
 -->            </div>
          </th>
          <th pSortableColumn="comune.denominazione">
            <div class="flex justify-content-between align-items-center">
              Comune
              <p-sortIcon field="comune.denominazione"></p-sortIcon>
<!--               <p-columnFilter type="text" field="comune.denominazione" display="menu" class="ml-auto"></p-columnFilter>
 -->            </div>
          </th>
         <!--  <th pSortableColumn="comune.regione.denominazione">
            <div class="flex justify-content-between align-items-center">
              Regione
              <p-sortIcon field="comune.regione.denominazione"></p-sortIcon>
            </div>
          </th> -->
        
      
         <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
          <td> <span class="text-lowercase">{{row.idTim}}</span>
          <td> <span class="text-lowercase">{{row.clli}}</span>
          <td> <span class="text-lowercase">{{row.clliAttestazione}}</span>
            <td> <span class="text-lowercase">{{row.denominazione}}</span>
          <td> <span class="text-lowercase">{{row.indirizzo}}</span>
          <td> <span class="text-lowercase">{{row.comune.denominazione}}</span>
            <!-- <td> <span class="text-lowercase">{{row.comune.regione.denominazione}}</span> -->
        
        
          <td class="text-center">
            <button *ngIf="hasCoordinates(row)" pButton type="button" style="color:#e5b5d7;" icon="pi pi-map"
              class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Posizione"
              (click)="openMappaConCentrale(row)">
            </button>
          
            <button pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" 
            tooltipPosition="top" pTooltip="Modifica Centrale"
              (click)="openModificaCentraleDialog(row)">
            </button>
        
            <button *ngIf="isAdmin || isSuperAdmin || isPresidioSF" pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-text" (click)="openDeleteCentraleDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Centrale">
            </button> 

          </td>
        </tr>
      </ng-template>
     <!--  <ng-template pTemplate="footer">
        <tr>
         <td class="text-left" style="font-size: 14px;">TOTALI:</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleScuoleInRework}}</td>

         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaAssegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInCarico}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaConsegnate}}</td>
         <td class="text-left" style="font-size: 14px;">{{numeroTotaleSanitaInRework}}</td> 
         <td></td>
         
       </tr>
     
   </ng-template> -->
    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
