import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Impresa } from '../models/impresa.model';


@Injectable({
  providedIn: 'root',
})
export class ImpreseService{
  apiUrl: string = `${environment.apiUrl}`+"azienda";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  get(): Observable<any> {
    //this.apiUrl =`${environment.apiUrl}`+"aziende";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  getByBando(bando:String): Observable<any> {
    let urlFiltrateByBando = this.apiUrl +"?bando="+bando;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(urlFiltrateByBando, httpOptions);
  }

  getBandi(idAzienda:String): Observable<any> {
    let urlFiltrateByBando = this.apiUrl +"/"+idAzienda+"/bandi";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(urlFiltrateByBando, httpOptions);
  }


  getById(idCliente:string): Observable<any> {
    //this.apiUrl =`${environment.apiUrl}`+"aziende/"+idCliente;
    
    const headers = {
     "Content-Type": "application/json"
    };

    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    }; 

    return this.http.get(this.apiUrl, requestOptions);
  }

  crea(impresa:Impresa): Observable<any> {
  
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, impresa, httpOptions)
   /*  .pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('creaCdc', []))) */
      ;
  }

  modifica(impresa:Impresa): Observable<any> {
    this.logger.info("---> STO MODIDICANDO IMPRESA CHIAMANDO " + this.apiUrl+"/"+impresa.id);
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+impresa.id, impresa, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('modificaImpresa', []))
    );
  }

  elimina(idImpresa:string): Observable<any> {
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idImpresa, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('eliminaImpresa', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}
