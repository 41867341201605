import { Component } from '@angular/core';

@Component({
  selector: 'app-test-environment-footer',
  templateUrl: './test-environment-footer.component.html',
  styleUrls: ['./test-environment-footer.component.scss']
})
export class TestEnvironmentFooterComponent {
  repeatedTextArray = new Array(10); // Array di lunghezza 10 per ripetere il testo 10 volte

}
