<!--    -->

<p-steps [model]="pagineInserisciInformazioni" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
<div class="d-flex">
  <ng-container [ngSwitch]="activeIndex">
    
    <ng-container *ngSwitchCase="1">
       <form [formGroup]="dettagliForm">
        <div class="grid formgrid p-fluid mt-2" >
          <div class="field mt-2 mb-2 col-2">
            <label for="annoAttivazione" class="font-medium text-900">ANNO ATTIVAZIONE</label>
           <!--  <input type="text" formControlName="annoAttivazione" placeholder="Anno Attivazione" pInputText> -->
           <input type="number" formControlName="annoAttivazione" placeholder="Anno Attivazione" pInputText maxlength="4" (input)="validateYearInput($event)">

          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="hostnameCPE" class="font-medium text-900">Hostname CPE</label>
            <input type="text" formControlName="hostnameCPE" placeholder="Hostname CPS" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="ipAddress" class="font-medium text-900">IP</label>
            <input type="text" formControlName="ipAddress" placeholder="Ip Address" pInputText>
          </div>
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>

          <div class="field mt-2 mb-2 md:col-6 col-12">
            <label for="localeTecnico" class="font-medium text-900">Locale Tecnico</label>
            <input type="text" formControlName="localeTecnico" placeholder="Locale Tecnico" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="piano" class="font-medium text-900">Piano</label>
            <input type="text" formControlName="piano" placeholder="Piano" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="stanza" class="font-medium text-900">Stanza</label>
            <input type="text" formControlName="stanza" placeholder="Stanza" pInputText>
          </div>
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
            <div class="field mt- mb-2 md:col-6 col-12">
              <label for="redattore" class="font-medium text-900">Redattore</label>
              <input type="text" formControlName="redattore" pInputText>
            </div>
            <div class="field mt- mb-2 md:col-6 col-12">
              <label for="verificatore" class="font-medium text-900">Verificatore</label>
              <input type="text" formControlName="verificatore" pInputText>
            </div>
      
        </div>
      </form> 
    </ng-container>
    <ng-container *ngSwitchCase="0">
       <form *ngIf="!this.isTipoAC; else tipiC_block" [formGroup]="identificazioneFibraFormB">
        <div  class="grid formgrid p-fluid mt-2" >
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="clliSedeA" class="font-medium text-900">CLLI Sede A</label>
            <input type="text" formControlName="clliSedeA" placeholder="Clli della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="tipologiaSedeA" class="font-medium text-900">Tipo Sede A</label>
            <input type="text" formControlName="tipologiaSedeA" placeholder="Tipo della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="descrizioneSedeA" class="font-medium text-900">Descrizione Sede A</label>
            <input type="text" formControlName="descrizioneSedeA" placeholder="Descrizione della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-6 col-12">
            <label for="attestazioneAlRipartitoreA" class="font-medium text-900">Attestazione Sede A</label>
            <input type="text" formControlName="attestazioneAlRipartitoreA" placeholder="Attestazione della SEDE A" pInputText>
          </div>
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="clliSedeZ" class="font-medium text-900">CLLI Sede Z</label>
            <input type="text" formControlName="clliSedeZ" placeholder="CLLI della SEDE Z" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="tipologiaSedeZ" class="font-medium text-900">Tipo Sede Z</label>
            <input type="text" formControlName="tipologiaSedeZ" placeholder="Tipo della SEDE Z" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="descrizioneSedeZ" class="font-medium text-900">Descrizione Sede Z</label>
            <input type="text" formControlName="descrizioneSedeZ" placeholder="Descrizione della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-6 col-12">
            <label for="attestazioneAlRipartitoreZ" class="font-medium text-900">Attestazione Sede Z</label>
            <input type="text" formControlName="attestazioneAlRipartitoreZ" placeholder="Attestazione della SEDE Z" pInputText>
          </div>
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <div class="field mt-2 mb-2 col-2">
            <label for="numeroTotaleCoppie" class="font-medium text-900">Numero Totale Coppie</label>
            <input type="number" formControlName="numeroTotaleCoppie" placeholder="0" min="0" pInputText>
          </div>
          <div class="field mt-2 mb-2 col-2">
            <label for="numeroCoppieInUso" class="font-medium text-900">Numero Coppie in uso</label>
            <input type="number" formControlName="numeroCoppieInUso" placeholder="0" min="0" pInputText>
          </div>
          <div class="field mt-2 mb-2 col-2">
            <label for="fibra" class="font-medium text-900">Fibra</label>
            <input type="number" formControlName="fibra" placeholder="0" min="0" pInputText>
          </div>
         <div *ngIf="isTipoB" class="field mt-2 mb-2 col-2">
            <label for="lunghezza" class="font-medium text-900">Lunghezza</label>
            <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezza" placeholder="Lunghezza della Fibra in metri" > </p-inputNumber>
          </div> 
          <div class="field mt-2 mb-2 col-2">
            <label for="backup" class="font-medium text-900 text-orange-600">BACKUP</label>
            <p-dropdown inputId="tipoBackup" formControlName="tipoBackup" placeholder="Backup" [options]="tipiBackup" optionLabel="codice"  [showClear]="true" >
              <ng-template let-tipoBackup pTemplate="item">
                <div class="flex align-items-center">
                    <div style="font-size: 12px;">{{tipoBackup.codice}}</div>
                </div>
            </ng-template>
            </p-dropdown>
          </div>
          <div *ngIf="isTipoBFromC" class="field mt-2 mb-2 col-3 ">
            <label for="isTipoBSharp" class="font-medium text-900">Sottotipo B*</label>
            <div class="flex align-items-center mt-2">
              <p-inputSwitch inputId="isTipoBSharp" formControlName="tipoBSharp"></p-inputSwitch>
            </div>
            
          </div> 
         <!--  <div *ngIf="isTipoB" class="surface-border border-top-1 opacity-99 mb-2 mt-1 col-12"></div> -->
          
         <!--  <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="backup" class="font-medium text-900">BACKUP</label>
            <p-dropdown inputId="tipoBackup" formControlName="backup" placeholder="Backup" [options]="tipiBackup" optionLabel="codice"  [showClear]="true" >
              <ng-template let-tipoBackup pTemplate="item">
                <div class="flex align-items-center">
                    <div>{{tipoBackup.codice}}</div>
                </div>
            </ng-template>
            </p-dropdown>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="wifi" class="font-medium text-900">WIFI</label>
            <div class="flex align-items-center mt-2">
              <p-inputSwitch inputId="wifi" formControlName="wifi"></p-inputSwitch>
            </div>
          </div> -->
          </div>
      </form> 
      <ng-template #tipiC_block>
        <form [formGroup]="identificazioneFibraFormAC">
        <div class="grid formgrid p-fluid mt-2" >
          <div class="field mt-2 mb-2 md:col-5 col-12">
            <label for="attestazioneAlRipartitoreA" class="font-medium text-900">Attestazione al ripartitore A</label>
            <input type="text" formControlName="attestazioneAlRipartitoreA" placeholder="Attestazione della SEDE A" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="fibra" class="font-medium text-900">Fibra</label>
            <input type="number" formControlName="fibra" placeholder="0" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-5 col-12">
            <label for="attestazioneAlRipartitoreZ" class="font-medium text-900">Attestazione al ripartitore Z</label>
            <input type="text" formControlName="attestazioneAlRipartitoreZ" placeholder="Attestazione della SEDE Z" pInputText>
          </div>
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
        <!--   <div class="field mt-2 mb-2 md:col-3 col-12">
            <label for="clliSedeZ" class="font-medium text-900">CLLI Centrale</label>
            <input type="text" formControlName="clliSedeZ" placeholder="Clli della CENTRALE" pInputText>
          </div> -->
          <div class="field mt-2 mb-2  col-1">
            <label for="idPCN" class="font-medium text-900">ID PCN</label>
            <input type="text" formControlName="idPCN" placeholder="ID PCN" pInputText>
          </div>
          <div class="field mt-2 mb-2 col-1">
            <label for="nomePCN" class="font-medium text-900">NOME PCN</label>
            <input type="text" formControlName="nomePCN" placeholder="NOME PCN" pInputText>
          </div>
          <div class="field mt-2 mb-2  col-3">
            <label for="indirizzoPCN" class="font-medium text-900">INDIRIZZO PCN</label>
            <input type="text" formControlName="indirizzoPCN" placeholder="INDIRIZZO PCN" pInputText>
          </div>
          <div class="field mt-2 mb-2 col-2">
            <label for="latitudinePCN" class="font-medium text-900">Latitudine</label>
            <p-inputNumber  formControlName="latitudinePCN"  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2  col-2">
            <label for="longitudinePCN" class="font-medium text-900">Longitudine</label>
            <p-inputNumber  formControlName="longitudinePCN"  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div  class="field mt-2 mb-2  col-2">
            <label for="lunghezza" class="font-medium text-900">Lunghezza</label>
            <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" formControlName="lunghezza" placeholder="Lunghezza in metri" > </p-inputNumber>
          </div> 
          <div class="field mt-2 mb-2 col-2">
            <label for="backup" class="font-medium text-900 text-orange-600">BACKUP</label>
            <p-dropdown inputId="tipoBackup" formControlName="tipoBackup" placeholder="Backup" [options]="tipiBackup" optionLabel="codice"  [showClear]="true" >
              <ng-template let-tipoBackup pTemplate="item">
                <div class="flex align-items-center">
                    <div style="font-size: 12px;">{{tipoBackup.codice}}</div>
                </div>
            </ng-template>
            </p-dropdown>
          </div>
           <!-- <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Scuola"
              (click)="openMappaScuola()">
            </button>
          </div>  -->
        </div>
      </form> 
      </ng-template>
      
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <form [formGroup]="localizzazioniForm">
       <div class="grid formgrid p-fluid mt-2">
          <div class="field mt-2 mb-2 md:col-4 col-12">
            <label for="indirizzoScuola" class="font-medium text-900">Indirizzo Scuola</label>
            <input type="text" formControlName="indirizzoScuola" pInputText>
          </div>
          <div class="field mt-2 mb-4 md:col-3 col-12">
            <label for="comuneScuola" class="font-medium text-900">Comune Scuola</label>
            <input type="text" formControlName="comuneScuola" placeholder="Comune" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="latitudineScuola" class="font-medium text-900">Latitudine Scuola</label>
            <p-inputNumber formControlName="latitudineScuola"  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="longitudineScuola" class="font-medium text-900">Longitudine Scuola</label>
            <p-inputNumber formControlName="longitudineScuola"  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
           <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#441035;" icon="pi pi-map"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Posizione Scuola"
              (click)="openMappaScuola()">
            </button>
          </div> 
          <div class="surface-border border-top-1 opacity-50 mb-1 mt-1 col-12"></div>
          <div class="field mt-2 mb-4 md:col-4 col-12">
            <label for="indirizzoCentrale" class="font-medium text-900">Indirizzo Centrale</label>
            <input type="text" formControlName="indirizzoCentrale" pInputText>
          </div>
          <div class="field mt-2 mb-4 md:col-3 col-12">
            <label for="comuneCentrale" class="font-medium text-900">Comune Centrale</label>
            <input type="text" formControlName="comuneCentrale" placeholder="Comune" pInputText>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="latitudineCentrale" class="font-medium text-900">Latitudine Centrale</label>
            <p-inputNumber formControlName="latitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="field mt-2 mb-2 md:col-2 col-12">
            <label for="longitudineCentrale" class="font-medium text-900">Longitudine Centrale</label>
            <p-inputNumber formControlName="longitudineCentrale" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" > </p-inputNumber>
          </div>
          <div class="md:col-1 col-12">
            <button  pButton type="button" style="color:#4b255f;" icon="pi pi-envelope"
              class="p-button-rounded p-button-text mt-5 mb-2" tooltipPosition="top" pTooltip="Richiedi Aggiornamento Dati Centrale"
              (click)="openRichiediAggiornamentoDatiCentrale()">
            </button>
          </div>  
        </div> 
      </form>
    </ng-container>
    

  </ng-container>
</div>
<div class="surface-border border-top-1 opacity-99 mb-2 mt-1 col-12"></div>
<div class="margin-bottom-1 d-flex justify-content-between">

  <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1"
    [disabled]="activeIndex === 0"></p-button>
  <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()"
    [disabled]="checkNextStep()"></p-button>


  <p-button *ngIf="activeIndex === pagineInserisciInformazioni.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)"></p-button>
  <!-- <p-button icon="pi pi-times" label="Annulla" class="p-button-text" styleClass="p-button-warning" (click)="conferma(false)"></p-button> -->
  <!--    <div class="d-flex"> </div>   <div class="justify-content-end"> </div> -->
</div>
